import {
  call,
  put,
  takeEvery,
  takeLeading,
  all,
  fork,
  takeLatest,
} from "redux-saga/effects"

// Login Redux States
import {
  GET_USERS,
  GET_USER_DETAILS,
  GET_ALL_USERS,
  PURGE_USERS,
  UPDATE_USER,
  CREATE_USER,
  ENABLE_USER,
} from "./actionTypes"
import {
  userApiError,
  postUserError,
  getUserSuccess,
  updateUserSuccess,
  purgeUserSuccess,
  postSuccess,
  getUserDetailSuccess,
  enableUserSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getClusterUserDetails,
  getAllClusterUsers,
  getClusterUsers,
  putClusterUser,
  postClusterUser,
  enableClusterUser,
  disableClusterUser,
} from "helpers/backend_helper"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import { UserCategory } from "enums/user-category"
const fireBaseBackend = getFirebaseBackend()

function* fetchUsers({ params }) {
  // const authUser = authHeader().AuthUser;
  // const institutionId = authUser?.userCategory == UserCategory.MFB
  //   ? authUser.institutionId
  //   : GetInstitution().remoteInstitutionId;
  try {
    let response = yield call(
      getClusterUsers,

      {... JSON.parse(params) }
    )

    yield put(getUserSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(userApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* fetchAllUsers() {
  const authUser = authHeader().AuthUser;
  const institutionId = authUser?.userCategory == UserCategory.MFB
    ? authUser.institutionId
    : GetInstitution().remoteInstitutionId;
  try {
    let response = yield call(
      getAllClusterUsers,

     institutionId
    )

    yield put(getUserSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(userApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* fetchUserDetail(payload) {
  try {
    let response = yield call(getClusterUserDetails, payload.id)

    yield put(getUserDetailSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(userApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* createUser({ payload: { user } }) {
  const authUser = authHeader().AuthUser
  try {
    user["category"] = authUser?.userCategory
    if (!user["hasApprovalRights"]) user["hasApprovalRights"] = false
    const response = yield call(postClusterUser, {
      ...JSON.parse(JSON.stringify(user)),
      institutionId:
        authUser?.userCategory == UserCategory.MFB
          ? authUser?.institutionId
          : GetInstitution().remoteInstitutionId,
    })
    yield put(postSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      postUserError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* updateUser({ payload: { user } }) {
  const authUser = authHeader().AuthUser
  try {
    const response = yield call(putClusterUser, {
      ...JSON.parse(JSON.stringify(user)),
      institutionId:
        authUser?.userCategory == UserCategory.MFB
          ? authUser?.institutionId
          : GetInstitution().remoteInstitutionId,
    })

    yield put(updateUserSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(userApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableUser(payload) {
  try {
    let toEnable = true
    let cta = enableClusterUser
    if (!payload.enable) {
      cta = disableClusterUser
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableUserSuccess(toEnable))
  } catch (error) {
    console.debug(error)
    yield put(userApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* doPurge() {
  yield put(purgeUserSuccess())
}

// export function* watchCreateUser() {
//   yield takeEvery(CREATE_USER, createUser)
// }
function* userSaga() {
  yield takeLeading(GET_USERS, fetchUsers)
  yield takeLeading(UPDATE_USER, updateUser)
  yield takeEvery(PURGE_USERS, doPurge)
  yield takeLeading(GET_USER_DETAILS, fetchUserDetail)
  yield takeLeading(CREATE_USER, createUser)
  yield takeLeading(ENABLE_USER, doEnableUser)
  yield takeLeading(GET_ALL_USERS, fetchAllUsers)
  // yield all([fork(watchCreateUser)])
}

export default userSaga
