import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterFileUploader from "components/Common/FileUploader"
import ClusterForm from "components/Common/Form"
import TerminalLogic from "../../batch-upload"
import ClusterCheckBox from "components/Common/CheckBox"
import withFormValidation from "components/HOCs/WithFormValidation"
import withDispose from "components/HOCs/WithDispose"
const BatchUploadTerminalsForm = props => {
  let {
    apiSuccess,
    apiError,
    isFormInvalid,
    institutions,
    errorList,
    myError,
    isUpdate,
    loading,
    editLoading,
    details,
    setErrorList,
    handleOnChange,
    handleValidSubmit,
  } = props
  let { MapToDropDown } = TerminalLogic

  useEffect(() => {
    handleOnChange("UseFirstLineAsHeader", true)
  }, [])
  return (
    <React.Fragment>
      <ClusterForm handleSubmit={handleValidSubmit}>
        <React.Fragment>
          <Card
            outline
            style={{ background: "transparent", borderColor: "#999999" }}
            className="border"
          >
            <CardBody>
              <Row className="mb-1">
                <Col lg={12} md={12} sm={12}>
                  <ClusterFileUploader
                    id="file-upload"
                    name="TheFileBase64"
                    onHandleChange={value => {
                      handleOnChange("TheFileBase64", value.Base64)
                      handleOnChange("FileName", value.FileName)
                      setErrorList({ ...errorList, file: "" })
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-4">
                <Col lg={12} md={12} sm={12}>
                  <ClusterCheckBox
                    checked={true}
                    onHandleChange={(name, value) =>
                      handleOnChange(name, value)
                    }
                    label={"Use First Line as Header"}
                    name={"UseFirstLineAsHeader"}
                    id={"useFirstLineAsHeader"}
                  ></ClusterCheckBox>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <FormGroup>
                    <Label>Institution</Label>
                    <ClusterDropdown
                      items={MapToDropDown(institutions)}
                      onChange={(name, value) => {
                        handleOnChange("InstitutionID", value)
                        setErrorList({ ...errorList, institution: "" })
                      }}
                    ></ClusterDropdown>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={editLoading}
              disabled={isFormInvalid}
              color={"primary"}
              addon={"bx bx-save"}
            >
              {"Validate"}
            </ClusterButton>
          </Col>
        </Row>
      </ClusterForm>
    </React.Fragment>
  )
}
BatchUploadTerminalsForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(
  withFormValidation(withDispose(BatchUploadTerminalsForm))
)
