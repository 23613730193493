import {
  GET_FEATURES,
  GET_FEATURE_DETAIL,
  GET_FEATURE_DETAIL_SUCCESS,
  GET_FEATURE_DETAIL_FAIL,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAIL,
  GET_FEATURES_BY_INSTITUTION,
  GET_INSTITUTION_FEATURES_SUCCESS,
  GET_ALL_FEATURES,
  CREATE_FEATURE,
  POST_FEATURE_SUCCESS,
  PURGE_FEATURES,
  PURGE_FEATURES_SUCCESS,
  POST_FEATURE_FAIL,
  UPDATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  UPDATE_FEATURE_FAIL,
  ENABLE_FEATURE,
  ENABLE_FEATURE_SUCCESS,

} from "./actionTypes"

export const getFeatures = (params) => ({
  type: GET_FEATURES,
  params
})
export const getFeaturesByInstitution = (institutionCode) => ({
  type: GET_FEATURES_BY_INSTITUTION,
  institutionCode
})
export const getFeaturesByInstitutionSuccess = features => ({
  type: GET_INSTITUTION_FEATURES_SUCCESS,
  payload: features,
})
export const getAllFeatures = () => ({
  type: GET_ALL_FEATURES
})
export const getFeaturesSuccess = features => ({
  type: GET_FEATURES_SUCCESS,
  payload: features,
})
export const getFeaturesFail = error => ({
  type: GET_FEATURES_FAIL,
  payload: error,
})
export const getFeatureDetail = id => ({
  type: GET_FEATURE_DETAIL,
  id,
})

export const getFeatureDetailSuccess = features => ({
  type: GET_FEATURE_DETAIL_SUCCESS,
  payload: features,
})

export const getFeatureDetailFail = error => ({
  type: GET_FEATURE_DETAIL_FAIL,
  payload: error,
})


export const createFeature = (feature) => {
  return {
    type: CREATE_FEATURE,
    payload: { feature },
  }
}

export const featureApiError = error => {
  return {
    type: POST_FEATURE_FAIL,
    payload: error,
  }
}

export const postFeatureSuccess = feature => {
  return {
    type: POST_FEATURE_SUCCESS,
    payload: feature,
  }
}


export const enableFeature = (id, enable) => {
  return {
    type: ENABLE_FEATURE,
    id, enable
  }
}

export const enableFeatureSuccess = feature => {
  return {
    type: ENABLE_FEATURE_SUCCESS,
    payload: feature,
  }
}

export const updateFeature = (feature) => {
  return {
    type: UPDATE_FEATURE,
    payload: { feature },
  }
}
export const updateFeatureSuccess = feature => {
  return {
    type: UPDATE_FEATURE_SUCCESS,
    payload: feature,
  }
}




export const purgeFeatures = () => {
  return {
    type: PURGE_FEATURES
  }
}

export const purgeFeatureSuccess = () => {
  return {
    type: PURGE_FEATURES_SUCCESS
  }
}