import { call, put, takeLeading } from "redux-saga/effects"

import {
  GET_FUNCTIONS,
  GET_FUNCTIONS_BY_USER,
  GET_FUNCTION_ACTIONS,
  GET_FUNCTION_MENU,
  GET_PERMISSIONS,
} from "./actionTypes"
import {
  getPermissionsSuccess,
  getPermissionsFail,
  getMenuSuccess,
  getActionsSuccess,
  getActionsFail,
  getFunctionsFail,
  getFunctionsSuccess,
  getFunctionsByUserSuccess,
  getFunctionsByUserFail,
} from "./actions"

import {
  getFunctions,
  getFunctionsActionsByUser,
  getFunctionsByUser,
  getFunctionsMenuByUser,
  getPermissions,
} from "helpers/backend_helper"
import authHeader from "helpers/authentication/auth-token-header"
import {
  CACHE_FUNCTIONS_FOR_USER_ITEMS,
  CACHE_MENU_ITEMS,
} from "helpers/variables"
import {
  GetInstitution,
} from "helpers/authentication/institution"

import routes from "./routes";
import { UserCategory } from "enums/user-category"
const format = "yyyy-MM-DD"

const { defaultRoute, fullRoute, remoteRoute } = routes;

function* fetchPermissions(params) {
  try {
    let response = yield call(getPermissions, 0)

    yield put(getPermissionsSuccess([defaultRoute, ...response]))
  } catch (error) {
    yield put(getPermissionsFail(error))
  }
}
function* fetchMenuItems({ institutionId, username, userCategory }) {
  try {
    const cacheName = CACHE_MENU_ITEMS
    if (username || authHeader()?.AuthUser) {
      const authUser = authHeader()?.AuthUser
      let instId = institutionId
      let uName =  authUser?.userName?? username
      let category = userCategory;
      if (localStorage.getItem(cacheName) && !instId && !uName) {
        let response = localStorage.getItem(cacheName)
        yield put(getMenuSuccess([...JSON.parse(response)]))
      } else {
        if (!instId) instId = authUser?.institutionId
        if (!category) category = authUser?.userCategory
        let response = yield call(
          getFunctionsMenuByUser,
          category == UserCategory.MFB
            ? instId
            : GetInstitution().remoteInstitutionId,
          uName
        )
        let routes = [...fullRoute, ...response]
        if (authHeader()?.AuthUser?.userCategory == UserCategory.ServiceProvider)
          routes.push(remoteRoute)
        if (response.length > 0) {
          localStorage.setItem(cacheName, JSON.stringify(routes))
        }
        yield put(getMenuSuccess(routes))
      }
    } else {
      yield put(getMenuSuccess([...fullRoute]))
    }
  } catch (error) {
    console.debug("Fetching Menu Error", error)
    yield put(getMenuSuccess([...fullRoute]))
  }
}

function* fetchFunctionItemsByUser() {
  try {
    const cacheName = CACHE_FUNCTIONS_FOR_USER_ITEMS
    const authUser = authHeader()?.AuthUser
    if (authHeader()?.AuthUser) {
      if (localStorage.getItem(cacheName)) {
        let response = localStorage.getItem(cacheName)
        yield put(getFunctionsByUserSuccess([...JSON.parse(response)]))
      } else {
        let response = yield call(
          getFunctionsByUser,
          authUser?.userCategory == UserCategory.MFB
            ? authUser?.institutionId
            : GetInstitution().remoteInstitutionId,
          authUser?.userName
        )
        if (response.length > 0) {
          localStorage.setItem(cacheName, JSON.stringify([...response]))
        }
        yield put(getFunctionsByUserSuccess([...response]))
      }
    }
  } catch (error) {
    console.debug("Fetching Function Items For User Error", error)
    yield put(
      getFunctionsByUserFail(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* fetchFunctionItems() {
  try {
    const authUser = authHeader()?.AuthUser
    let response = yield call(getFunctions, authUser?.userCategory)

    yield put(getFunctionsSuccess([...response]))

  } catch (error) {
    console.debug("Fetching Functions Error", error)
    yield put(
      getFunctionsFail(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* fetchActionItems() {
  try {
    const authUser = authHeader()?.AuthUser
    const institution = GetInstitution()
    let response = yield call(
      getFunctionsActionsByUser,
      authUser?.userCategory == UserCategory.MFB
        ? institution.id
        : institution.remoteInstitutionId,
      authUser?.userName
    )
    //  console.debug(response)
    //response = functions;
    yield put(getActionsSuccess([...response]))
  } catch (error) {
    console.debug("Fetching Actions Error", error)
    yield put(
      getActionsFail(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* permissionsSaga() {
  yield takeLeading(GET_PERMISSIONS, fetchPermissions)
  yield takeLeading(GET_FUNCTION_MENU, fetchMenuItems)
  yield takeLeading(GET_FUNCTIONS_BY_USER, fetchFunctionItemsByUser)
  yield takeLeading(GET_FUNCTION_ACTIONS, fetchActionItems)
  yield takeLeading(GET_FUNCTIONS, fetchFunctionItems)
}

export default permissionsSaga
