import React, {  useRef, useState } from "react"
import "assets/css/logo.css"
import ImageHelper from "helpers/image-helper"
import PropTypes from "prop-types"
import { FormGroup, InputGroup, Label } from "reactstrap"
import ClusterImageViewer from "./ImageViewer";
import { ImageSize } from "enums/image-size"

const ClusterImageUploader: React.FC<any> = props => {
  const {
    id,
    onHandleChange,
    name,
    src,
    type,
    label,
    accept = [".csv"],
    value,
    url
  } = props
  const fileRef = useRef(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [imageUrl, setUrl] = useState(url)
  const [logo, setLogo] = useState(false)
  const [minusClick, setMinusClick] = useState(false)
  const [show, setShow] = useState("logoOptionsHidden")
  const [base64Value,setBase64] = useState(value)

  const processImage = file => {
    setUrl(null)
    let reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = async function () {
      let processedImageInBase64 = (reader.result as string);
      const fileDTO ={
        FileName: file.name,
        Base64: processedImageInBase64.split(",")[1],
        File: file
      };
      onHandleChange(fileDTO)

      setBase64(processedImageInBase64)
    }
    reader.onerror = function (error) {
      console.error("Error: ", error)
    }
  }
   

  const removeLogo = () => {
    setUrl(null)
    setMinusClick(!minusClick)
    setUploadedFile(null)
  }
  return (
    <FormGroup>
      <InputGroup>
        {label && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label for={id}>{label}</Label>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
          className={imageUrl ? `mainSmall` : `mainBig`}
        >
          {!uploadedFile ? (
            <div className="logo-uploader ">
              <div className="mb-3 ">
                <h4 className="mb-1">
                  <b>Upload an Institution Logo</b>
                </h4>
                <small className="ml-1">
                  Only {accept.join(" | ")} files are allowed
                </small>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: 234.5,
              }}
            ></div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
          <ClusterImageViewer  url={imageUrl} size={ImageSize.lg} base64={base64Value} type={type}/>
            {!imageUrl ? (
              <div
                onClick={() => setLogo(!logo)}
                className={imageUrl ? `icon-active logo-upload` : `icon`}
              >
                <div>
                  <img
                    src={ImageHelper.plusIconLogo}
                    alt="add logo"
                    className="logo-upload"
                  />

                  <input
                    onChange={e => {
                      const file = fileRef.current.files[0]
                      if (file) {
                        setUploadedFile(file)
                        processImage(file)
                      }
                    }}
                    ref={fileRef}
                    type="file"
                    accept={accept.join(", ")}
                    id={id}
                    style={{
                      opacity: 0,
                      visibility: imageUrl ? "hidden" : "visible",
                      position: "absolute",
                      top: 0,
                      right: 7,
                      zIndex: 2,
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div
                onClick={() => setMinusClick(!minusClick)}
                className="minusIconBody"
              >
                <p className="minusIcon"></p>
              </div>
            )}
          </div>
        </div>
        <div className={imageUrl && minusClick ? `logoOptions` : show}>
          <div onClick={removeLogo} className="logoOpton">
            Remove Logo
          </div>
        </div>
      </InputGroup>
    </FormGroup>
  )
}

ClusterImageUploader.propTypes = {
  id: PropTypes.string,
  onHandleChange: PropTypes.func,
  name: PropTypes.string,
  accept: PropTypes.array,
}

export default ClusterImageUploader
