import { ClusterInstitutions } from "enums/institutions"
import moment from "moment"
import { FixMeLater } from "types/fix-me-later"
import { GetInstitution, Institution } from "./authentication/institution"
import { InstitutionsHelper } from "./institutions"
export const TRANSACTIONTYPENAMES = [
  { id: "BalanceEnquiry", value: "BalanceEnquiry", key: 1, flag: "BEQ" },
  { id: "MiniStatement", value: "MiniStatement", key: 2, flag: "MST" },
  { id: "Recharge", value: "Recharge", key: 3, flag: "ATP" },
  { id: "BillsPayment", value: "BillsPayment", key: 4, flag: "BPM" },
  { id: "CashIn", value: "CashIn", key: 5, flag: "DPS" },
  {
    id: "CashOut",
    value: "CashOut",
    key: 6,
    flag: "TWT",
    friendlyName: "Cash Out with Token",
  },
  { id: "PINChange", value: "PINChange", key: 7 },
  { id: "PINReset", value: "PINReset", key: 8 },
  { id: "Registration", value: "Registration", key: 9, flag: "ACC" },
  {
    id: "FundsTransferCommercialBank",
    value: "FundsTransferCommercialBank",
    key: 10,
    flag: "IFT",
    friendlyName: "Commercial Funds Transfer",
  },
  { id: "FundsTransferCashIn", value: "FundsTransferCashIn", key: 11 },
  { id: "FundsTransferCashOut", value: "FundsTransferCashOut", key: 12 },
  { id: "InwardsTransfer", value: "InwardsTransfer", key: 13 },
  { id: "AccountActivation", value: "AccountActivation", key: 14 },
  {
    id: "LocalFundsTransfer",
    value: "LocalFundsTransfer",
    key: 15,
    flag: "LFT",
  },
  { id: "SetDefaultAccount", value: "SetDefaultAccount", key: 16 },
  { id: "LoanRequest", value: "LoanRequest", key: 17 },
  { id: "CardLinking", value: "CardLinking", key: 18 },
  {
    id: "AgentToAgentFundsTransfer",
    value: "AgentToAgentFundsTransfer",
    key: 19,
  },
  { id: "KiaKiaToKiaKia", value: "KiaKiaToKiaKia", key: 20 },
  { id: "KiaKiaToSterlingBank", value: "KiaKiaToSterlingBank", key: 21 },
  { id: "KiaKiaToOtherBanks", value: "KiaKiaToOtherBanks", key: 22 },
  { id: "AccountLiquidation", value: "AccountLiquidation", key: 24 },
  { id: "BVNUpdate", value: "BVNUpdate", key: 25 },
  { id: "MFBToOtherBanks", value: "MFBToOtherBanks", key: 26 },
  { id: "BetaHealthRegistration", value: "BetaHealthRegistration", key: 27 },
  { id: "BetaHealthSubPayment", value: "BetaHealthSubPayment", key: 28 },
  {
    id: "POSAgentCashOut",
    value: "POSAgentCashOut",
    key: 29,
    flag: "CWT",
    friendlyName: "POS Cash Out",
  },
  {
    id: "CollectionPayment",
    value: "CollectionPayment",
    key: 32,
    flag: "COL",
    friendlyName: "Collections",
  },
  {
    id: "CollectionPaymentReference",
    value: "CollectionPaymentReference",
    key: 33,
  },
  { id: "OffshoreTransfer", value: "OffshoreTransfer", key: 34 },
  { id: "WalletTransfer", value: "WalletTransfer", key: 35 },
  {
    id: "AgentLoanRequest",
    value: "AgentLoanRequest",
    key: 37,
    flag: "AGL",
    friendlyName: "Agent Liquidity",
  },
  {
    id: "CrossBankTokenWithdrawal",
    value: "CrossBankTokenWithdrawal",
    key: 39,
    flag: "IBTW",
  },
  { id: "PayWithTransfer", value: "PayWithTransfer", key: 40, flag: "PWT" },
  { id: "CardTransfer", value: "CardTransfer", key: 41, flag: "CTR" },
]

export const GetTransactionTypeNames = () => {
  let base = [
    "Registration",
    "CashIn",
    "CashOut",
    "BalanceEnquiry",
    "FundsTransferCommercialBank",
    "MiniStatement",
    //"POSAgentCashOut",
    "PayWithTransfer",
    "CrossBankTokenWithdrawal",
    "BillsPayment",
    "Recharge",
    "LocalFundsTransfer",
    "CardTransfer",
  ]
  if (InstitutionsHelper.Validator.isSterling) {
    return TRANSACTIONTYPENAMES
  } else if (InstitutionsHelper.Validator.isPurpleMoney) {
    let purpleMoneyBase = [...base, "AgentLoanRequest"]
    return TRANSACTIONTYPENAMES.filter(t => purpleMoneyBase.includes(t.value))
  } else if (InstitutionsHelper.Validator.isExternal) {
    let polarisBase = [
      ...base,
      "CollectionPayment",
      //"CollectionPaymentReference",
    ]
    return TRANSACTIONTYPENAMES.filter(t => polarisBase.includes(t.value))
  } else {
    return TRANSACTIONTYPENAMES.filter(t => base.includes(t.value))
  }
}
export const GLOBAL = {
  title: "Almighty Access",
  description: "You have access to any and everything, We hail oh",
}
export const NETWORKMANAGER = {
  title: "Who is a Network Manager",
  description:
    "A network manager is in charge of managing the activities within a particular region of activity e.g Yaba",
}
export const AGENT = {
  title: "Agent Scope",
  description:
    "Roles created under agent scopes are only assignable to agents. Allowing functionalities specific to that agent",
}
// export const CLUSTERMANAGER = {
//   title: "Who is a Cluster Manager",
//   description:
//     "A cluster manager is in charge of managing the activities of a network or group of agents",
// }
export const CLUSTER = {
  title: "What is a cluster",
  description: "A cluster is a network or group of agents",
}
export const TRANSACTIONSTATUS = [
  { value: "Successful", key: "Successful", id: "Successful" },
  { value: "Pending", key: "Pending", id: "Pending" },
  { value: "Failed", key: "Failed", id: "Failed" },
]
export const TIMEBASIS = [
  { value: "1 hour", key: "1 hour" },
  { value: "3 hours", key: "3 hours" },
  { value: "6 hours", key: "6 hours" },
  { value: "12 hours", key: "12 hours" },
  { value: "18 hours", key: "18 hours" },
  { value: "24 hours", key: "24 hours" },
  { value: "7 days", key: "7 days" },
  { value: "30 days", key: "30 days" },
]
export const RECEIPTSTATUS = [
  { value: "All", key: 2, id: 2 },
  { value: "Approved", key: 0, id: 0 },
  { value: "Declined", key: 1, id: 1 },
]
export const BASESTATUS = [
  { value: "Active", key: 1, id: 1 },
  { value: "Inactive", key: 0, id: 0 },
]
export const BASESTATUSSTRING = [
  { value: "Active", key: 1, id: "Active" },
  { value: "Inactive", key: 0, id: "Inactive" },
]
export const PAYMENTSTATUS = [
  { value: "Unpaid", key: 0, id: 0 },
  { value: "Pending", key: 1, id: 1 },
  { value: "Successful", key: 2, id: 2 },
  { value: "Failed", key: 3, id: 3 },
]
export const COSTBEARER = [
  { value: "Agent", key: 0, id: 0 },
  { value: "Bank", key: 1, id: 1 },
  { value: "Customer", key: 2, id: 2 },
]
export const APPLICABILITY = [
  { value: "Always", key: 0, id: 0 },
  { value: "FirstTransactionOnly", key: 1, id: 1 },
]
export const COMMISSIONTYPE = [
  { value: "Fixed", key: 0, id: 0 },
  { value: "Range", key: 1, id: 1 },
]
export const FEETYPE = [
  { value: "Flat", key: 0, id: 0 },
  { value: "Percentage", key: 1, id: 1 },
]
export const PAYMENTMODE = [
  { value: "EndOfDay", key: 0, id: 0 },
  { value: "EndOfMonth", key: 1, id: 1 },
  { value: "Instant", key: 2, id: 2 },
]
export const SEARCHFIELDCONFIG = {
  label: "",
  type: "",
  isRequired: true,
  placeholder: "",
  id: "",
  name: "",
  defaultValue: "",
  data: [],
}
export const SCOPE = [
  { value: "Global", id: "Global", key: 0 },
  { value: "Cluster", id: "Cluster", key: 1 },
  { value: "Agent", id: "Agent", key: 3 },
  { value: "Network Manager", id: "NetworkManager", key: 2 },
]
export const BRANCHES = [
  { value: "Head Office", key: "Head Office", id: 2 },
  { value: "Yaba Branch", key: "Yaba Branch", id: 5 },
]

export const STATE = [
  { value: "Lagos", key: "Lagos", id: "Lagos" },
  { value: "Kaduna", key: "Kaduna", id: "Kaduna" },
]
export const ROLES = [
  { value: "Super Admin", key: 0, id: 1 },
  { value: "Polaris Cluster", key: 1, id: 2 },
  { value: "Polaris ANM", key: 2, id: 3 },
]
export const SEARCHOPTIONS = {
  range: {
    enabled: true, //always explicitly set enabled to true if you want it to always be true irrespective of page changes
    from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
    to: moment().endOf("day").format("YYYY-MM-DD"),
  },
  actions: {
    enabled: true,
    isReset: true,
    isSearch: true,
  },
  fields: [],
}
const date = (date, segment = "all") => {
  if (segment == "time") return moment(date).format("hh:mm:ss")
  if (segment == "day") return moment(date).format("MMMM Do, YYYY")
  return moment(date).format("MMMM Do, YYYY hh:mm:ss")
}
const currency = (amount: FixMeLater) => {
  if (!amount) return amount

  return (
    "₦" +
    parseFloat(amount)
      .toFixed(2)
      ?.replace(".00", "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  )
}
const percentage = decimal => {
  if (!decimal) return decimal
  return decimal + "%"
}
export const GENDER = [
  { id: "Female", value: "Female", key: 1 },
  { id: "Male", value: "Male", key: 0 },
]
export const AGENTTYPE = [
  { id: 0, value: "AgencyBanking", key: 0 },
  { id: 1, value: "GEEP", key: 1 },
]
export const REPORTSTATUS = [
  { id: 0, value: "Logged", key: 0 },
  { id: 1, value: "Processing", key: 1 },
  { id: 2, value: "Finished", key: 2 },
]
export const INTEGRATIONTYPE = [
  { id: 0, value: "BankOne", key: 0 },
  { id: 1, value: "ThirdParty", key: 1 },
]
export const ACCOUNTMODE = [
  { id: "CoreBanking", value: "CoreBanking", key: 0 },
  { id: "Wallet", value: "Wallet", key: 1 },
]
export const TERMINALMODE = [
  { id: "EPMS", value: "EPMS", key: 1 },
  { id: "POSVAS", value: "POSVAS", key: 2 },
]
export const ACCOUNTSTATUS = [
  { id: "Active", value: "Active", key: 1 },
  { id: "Inactive", value: "Inactive", key: 2 },
]
export const TERMINALTYPE = [
  { id: "TelpoPOS", value: "TelpoPOS", key: 0 },
  { id: "NexgoPOS", value: "NexgoPOS", key: 1 },
  { id: "MPOS", value: "MPOS", key: 2 },
  { id: "OtherPOS", value: "OtherPOS", key: 10 },
]
export const MOBILEACCOUNTSTATUS = [
  { id: "Active", value: "Active", key: 0 },
  { id: "InActive", value: "InActive", key: 1 },
  { id: "New", value: "New", key: 2 },
  { id: "PendingFundsTransfer", value: "Pending Funds Transfer", key: 3 },
  { id: "HotListed", value: "Hot Listed", key: 4 },
  { id: "AwaitingApproval", value: "Awaiting Approval", key: 5 },
]
export const CACHE_MENU_ITEMS = "menuItems"
export const CACHE_FUNCTIONS_FOR_USER_ITEMS = "functionItemsForUser"
export const DISPUTESTATUS = [
  { id: "NotProcessed", value: "Not Processed", key: 0 },
  { id: "Pending", value: "Pending", key: 1 },
  { id: "ResolvedApproved", value: "Resolved Approved", key: 2 },
  { id: "ResolvedReversed", value: "Resolved Reversed", key: 3 },
]

export const GETGENDER = value => {
  return GENDER.find(g => g.key == value)?.value
}
export const GETACCOUNTMODE = value => {
  return ACCOUNTMODE.find(g => g.key == value)?.value
}
export const GETAGENTTYPE = value => {
  return AGENTTYPE.find(g => g.key == value)?.value
}
export const GETSTATUS = value => {
  return value == "True"
}
export const GETACCOUNTSTATUS = value => {
  return ACCOUNTSTATUS.find(g => g.key == value)?.value
}
export const GETINTEGRATIONTYPE = value => {
  return INTEGRATIONTYPE.find(g => g.key == value)?.value
}
export const Format = {
  currency,
  percentage,
  date,
}

export const REPORTTYPE = [
  {
    value: "Agent Summary",
    key: "Agent Transaction Summary Report",
    id: "Agent Transaction Summary Report",
    roleName: "AGENT_SUMMARY_REPORT",
  },
  {
    value: "Volume Comparatives",
    key: "Agent Transaction Volume Comparatives Report",
    id: "Agent Transaction Volume Comparatives Report",
    roleName: "AGENT_VOLUME_COMPARATIVE_REPORT",
  },
  {
    value: "Card Transfer",
    key: "Card Transfer Transactions",
    id: "Card Transfer Transactions",
    roleName: "CARD_TRANSFER_REPORT",
  },
  {
    value: "Collections",
    key: "Collections Report",
    id: "Collections Report",
    roleName: "VIEW_COLLECTIONS",
  },
  {
    value: "Funds Transfer",
    key: "Funds Transfer Transactions",
    id: "Funds Transfer Transactions",
    roleName: "FUNDS_TRANSFER_REPORT",
  },
  {
    value: "POS Cashout",
    key: "Agent POS CashOut Transactions",
    id: "Agent POS CashOut Transactions",
    roleName: "CASHOUT_REPORT",
  },
  {
    value: "Pay With Transfer",
    key: "Pay With Transfer Transactions",
    id: "Pay With Transfer Transactions",
    roleName: "PAY_WITH_TRANSFER_REPORT",
  },
  {
    value: "Transaction",
    key: "Transactions Report",
    id: "Transactions Report",
    roleName: "TRANSACTION_REPORT",
  },
]
