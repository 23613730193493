import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//i18n
import { withTranslation } from "react-i18next"
import { reportPurge, getCollectionReports } from "store/actions"
import { GET_COLLECTION_REPORT, GET_TRANSACTIONREPORT } from "helpers/url_helper"
import CollectionLogic from "./collection"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import useSWR from "swr"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"

const CollectionList = props => {
  const {
    collections,
    onGetCollections,
    error,
    onPurge,
    collectionTotalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions,
  } = CollectionLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
    } = useReportData({ onGetData: onGetCollections, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period: 1, periodicity: Periodicity.Day })

  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
useEffect(() => {
  setSearchOptions(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
}, [institutions])

  useEffect(() => {
    populateRows()
  }, [collections])

  const populateRows = () => {
    let rws = []
    if (collections) {
      collections.forEach(collection => {
        let row = MapToRows(collection)

        rws.push(row)
      })
    }
    setRows(rws)
  }

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])
  return (
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: collectionTotalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_COLLECTION_REPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
  )
}

CollectionList.propTypes = {
  collections: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetCollections: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  collectionTotalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { collections, error, success, collectionTotalCount, loading }
}) => {
  return {
    collections,
    error,
    success,
    collectionTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCollections: query => dispatch(getCollectionReports(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CollectionList))))
