import { GetInstitution } from "helpers/authentication/institution"
import { GETGENDER, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Status", name: "status", isBadge: true, entityKeyName: "IsActive" },
  { title: "Name", name: "name", entityKeyName: "Name" },
  {
    title: "Account Number",
    name: "accountNumber",
    entityKeyName: "AccountNumber",
  },
  { title: "Phone Number", name: "phoneNumber", entityKeyName: "PhoneNumber" },
  { title: "Email", name: "email", entityKeyName: "Email" },
  { title: "Action", name: "action", isAction: true },
]

const ERRORLIST = {
    description: undefined,
    email: undefined,
    name: undefined,
    gender: undefined,
    phoneNumber: undefined,
    clusterId:undefined,
    username: undefined
}
const NOUSER_ERRORLIST = {
  ...ERRORLIST,
  userRoleId:undefined,
  gender:undefined,
  street:undefined,
  city:undefined,
  state:undefined
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Name",
      id: "s-name",
      defaultValue: "",
      name: "name",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToRows = (networkManager) => {
    return {
      id: networkManager.ID,
      name: networkManager.Name,
      description: networkManager.Description,
      institution: networkManager?.InstitutionName,
      cluster: networkManager.Cluster?.Name,
      email: networkManager.Email,
      status: {
        label: networkManager.IsActive ? "Active" : "Inactive",
        badgeClass: networkManager.IsActive ? "success" : "secondary",
      },
      phoneNumber: networkManager.PhoneNumber,
      accountNumber: networkManager.AccountNumber,
      gender: networkManager.Gender,
    }

}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Name: parameters["name"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

const MapToModalState = networkManagerDetail => {
  return [
    {
      title: "",
      items: [
        {
          id: "name",
          label: "Name",
          name: "name",
          value: networkManagerDetail.Name,
        },
        {
          id: "description",
          label: "Description",
          name: "description",
          value: networkManagerDetail.Description,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: networkManagerDetail.Code,
        },
        {
          id: "gender",
          label: "Gender",
          name: "gender",
          value: networkManagerDetail.Gender,
        },
        {
          id: "phoneNumber",
          label: "Phone Number",
          name: "phoneNumber",
          value: networkManagerDetail.PhoneNumber,
        },
        {
          id: "email",
          label: "Email",
          name: "email",
          value: networkManagerDetail.Email,
        },
        {
          id: "accountNumber",
          label: "Account Number",
          name: "accountNumber",
          value: networkManagerDetail.AccountNumber,
        },
      ],
    },
  ]
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
export default {
  HEADERS,
  ERRORLIST,
  NOUSER_ERRORLIST,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  MapToDropDown,
}
