import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_CATEGORIES,
  GET_CATEGORY_DETAIL,
  CATEGORY_PURGE,
  ENABLE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY,
  GET_ALL_CATEGORIES,
} from "./actionTypes"
import {
  getCategoriesSuccess,
  getCategoriesFail,
  getCategoryDetailSuccess,
  getCategoryDetailFail,
  categoryPurgeSuccess,
  updateCategorySuccess,
  postCategorySuccess,
  categoryApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCategories,
  getCategoryDetail,
  putCategory,
  postCategory,
  enableCategory,
  getAllCategories,
  disableCategory,
} from "helpers/backend_helper"
import { enableCategorySuccess } from "store/actions"
const format = "yyyy-MM-DD"
function* fetchCategories({ params }) {
  try {
    if (!params) {
      yield put(getCategoriesSuccess([]))
      return
    }
    const response = yield call(getCategories, JSON.parse(params))
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    console.debug("Fetching Categories Error", error)
    yield put(getCategoriesFail(error))
  }
}
function* fetchAllCategories() {
  try {
    const response = yield call(getAllCategories)
    yield put(getCategoriesSuccess(response))
  } catch (error) {
    console.debug("Fetching All Categories Error", error)
    yield put(getCategoriesFail(error))
  }
}
function* fetchCategoryDetail({ id }) {
  try {
    const response = yield call(getCategoryDetail, id)
    yield put(getCategoryDetailSuccess(response))
  } catch (error) {
    yield put(getCategoryDetailFail(error))
  }
}

function* createCategory({ payload: { category } }) {
  try {
    const response = yield call(postCategory, category)
    yield put(postCategorySuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      categoryApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* updateCategory({ payload: { category } }) {
  try {
    const response = yield call(putCategory, category)

    yield put(updateCategorySuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      categoryApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* doEnableCategory(payload) {
  try {
    let toEnable = true
    let cta = enableCategory
    if (!payload.enable) {
      cta = disableCategory
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableCategorySuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      categoryApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doPurge() {
  yield put(categoryPurgeSuccess())
}

function* categoriesSaga() {
  yield takeLeading(GET_CATEGORIES, fetchCategories)
  yield takeEvery(GET_CATEGORY_DETAIL, fetchCategoryDetail)
  yield takeEvery(CATEGORY_PURGE, doPurge)
  yield takeLeading(ENABLE_CATEGORY, doEnableCategory)
  yield takeLeading(UPDATE_CATEGORY, updateCategory)
  yield takeLeading(CREATE_CATEGORY, createCategory)
  yield takeLeading(GET_ALL_CATEGORIES, fetchAllCategories)
}

export default categoriesSaga
