import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Col, Container, FormGroup, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import {
    institutionPurge,
    getBins,
    getRoutes,
    configureBinRoutes,
    getBinRoutes,
    getBinRouteDetails,
} from "store/actions"
import RoutingLogic from "../routing"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import RoutingForm from "./form"
import BinRouteList from "../list";
const BinRoutes = props => {
    const {
        match,
        loading,
        success,
        onGetBins,
        onConfigureBinRoutes,
        onGetRoutes,
        bins,
        history,
        routes,
        binRoutes,
        editLoading,
        error
    } = props
    const { ERRORLIST, SpreadBinRoutes } = RoutingLogic
    const [errorList, setErrorList] = useState(ERRORLIST)
    const [binRoute, setBinRoute] = useState({});
    const categories = useSWR(`${GET_ALL_CATEGORIES}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    useEffect(() => {
        onGetRoutes();
        onGetBins();
    }, [])

    const handleOnChange = (key, value) => {
        let u = { ...binRoute }
        u[key] = value;
        setBinRoute(u)
    }
    const handleValidSubmit = values => {
        onConfigureBinRoutes([values])

    }



    return (
        <React.Fragment>
            <div className="page-content mb-4">
                <Container fluid>
                    <Breadcrumbs
                        title={"Cash Out Routing Configuration"}
                        image={clusterian}
                        breadcrumbItem={"BIN Routes"}
                    />

                    <RoutingForm
                        categories={(categories?.data as any)?.Data}
                        bins={bins}
                        binRoutes={SpreadBinRoutes(binRoutes, (categories?.data as any)?.Data)}
                        routes={routes}
                        loading={loading}
                        editLoading={editLoading}
                        success={success}
                        error={error}
                        history={history}
                        setErrorList={setErrorList}
                        handleOnChange={handleOnChange}
                        handleValidSubmit={handleValidSubmit}
                    />
                    <BinRouteList

                    />
                </Container>
            </div>
        </React.Fragment>
    )
}

BinRoutes.propTypes = {
    error: PropTypes.any,
    success: PropTypes.any,
    onConfigureBinRoutes: PropTypes.func,
    onPurge: PropTypes.func,
    onBack: PropTypes.func,
    routes: PropTypes.array,
    binRoutes: PropTypes.array,
    bins: PropTypes.array,
    editLoading: PropTypes.bool,
    loading: PropTypes.bool,
    onGetBinRoutes: PropTypes.func,
    onGetRoutes: PropTypes.func,
    onGetBins: PropTypes.func,
    t: PropTypes.any,
}

const mapStateToProps = ({
    routes: {
        routes,
        binRoutes,
        bins,
        error,
        success,
        totalCount,
        editLoading,
        loading
    }
}) => {
    return {
        routes,
        error,
        binRoutes,
        bins,
        totalCount,
        loading,
        success,
        editLoading
    }
}

const mapDispatchToProps = dispatch => ({
    onPurge: () => dispatch(institutionPurge()),
    onConfigureBinRoutes: binRoutes => dispatch(configureBinRoutes(binRoutes)),
    onGetBins: bin => dispatch(getBins(bin)),
    onGetRoutes: () => dispatch(getRoutes()),
    onGetBinRoutes: (param) => dispatch(getBinRoutes(param)),
    onGetBinRouteDetails: (param) => dispatch(getBinRouteDetails(param)),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(withRouter(withDispose(BinRoutes))))
