import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  mobileAccountPurge,
  createMobileAccount,
  getMobileAccountDetail,
} from "store/actions"
import MobileAccountDetails from "./list"
import withDispose from "components/HOCs/WithDispose"
import PhoneValidateForm from "./phone-validate-form"
import { getMobileAccountByPhoneNumber } from "store/mobile-account/actions"
const CreateMobileAccount = props => {
  const {
    onGetAccountByPhoneNumber,
    success,
    detailLoading,
    loading,
    error,
    mobileAccountDetail,
  } = props

  useEffect(() => {
    if (success && mobileAccountDetail) {
      setIsValidated(true)
    }
  }, [mobileAccountDetail])

  const [isValidated, setIsValidated] = useState()
  const [phoneNumber, setPhoneNumber] = useState()
  const handleOnPhoneChange = (key, value) => {
    setPhoneNumber(value)
  }
  const [phoneErrorList, setPhoneErrorList] = useState({
    phoneNumber: undefined,
  })
  const handleValidate = e => {
    e.preventDefault()
    // setIsValidated(true)
    onGetAccountByPhoneNumber(phoneNumber)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Mobile Account"}
            breadcrumbItem={"Modify A Particular Account"}
          />

          {!isValidated ? (
            <React.Fragment>
              <PhoneValidateForm
                success={success}
                error={error}
                loading={detailLoading}
                errorList={phoneErrorList}
                setErrorList={setPhoneErrorList}
                handleValidSubmit={handleValidate}
                handleOnChange={handleOnPhoneChange}
              />
            </React.Fragment>
          ) : (
            <MobileAccountDetails
              setIsValidated={setIsValidated}
              account={mobileAccountDetail}
            ></MobileAccountDetails>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

CreateMobileAccount.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateMobileAccount: PropTypes.func,
  onGetMobileAccountDetail: PropTypes.func,
  onBack: PropTypes.func,
  mobileAccountDetail: PropTypes.object,
  mobileAccounts: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  detailLoading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  mobileAccounts: {
    mobileAccounts,
    error,
    success,
    mobileAccountDetail,
    editLoading,
    totalCount,
    loading,
    detailLoading,
  },
}) => {
  return {
    mobileAccounts: [...mobileAccounts],
    error,
    success,
    mobileAccountDetail: mobileAccountDetail,
    totalCount,
    loading,
    detailLoading,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMobileAccountDetail: id => dispatch(getMobileAccountDetail(id)),
  onGetAccountByPhoneNumber: phoneNumber =>
    dispatch(getMobileAccountByPhoneNumber(phoneNumber)),
  onPurge: () => dispatch(mobileAccountPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(CreateMobileAccount))))
