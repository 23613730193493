import { Row, Col, Card, CardTitle, CardBody } from "reactstrap"
import { GetInstitution } from "helpers/authentication/institution"
import SummaryCard from "./card"
const HighlightTab:React.FC<any> = ({ trxType, endDateStr, startDateStr, color, code }) => {
  return (
    <Card
      className={" divider-top-" + color}
      style={{ paddingTop: "15px", paddingBottom: "0px", position: "relative" }}
    >
      <CardTitle
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          position: "relative",
        }}
      >
        <small>
          <b>{trxType.label}</b>
        </small>
      </CardTitle>
      <CardBody
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          position: "relative",
        }}
      >
        <Row>
          <Col lg={12} md={12} sm={12}>
            <SummaryCard
              title={"TRANSACTION VALUE"}
              trxType={trxType}
              subtitle="7 Days"
              field="trxVolTrend"
              yValueLabel="value"
              currency
              startDateStr={startDateStr}
              endDateStr={endDateStr}
              trxStatusFilter
              color={"light-" + color}
              code = {code}
            ></SummaryCard>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <SummaryCard
              title={"TRANSACTION COUNT"}
              trxType={trxType}
              subtitle="7 Days"
              field="trxCountTrend"
              yValueLabel="transactions"
              startDateStr={startDateStr}
              endDateStr={endDateStr}
              trxStatusFilter
              color={"light-" + color}
              code={code}
            ></SummaryCard>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <SummaryCard
              title={"ACTIVE AGENTS"}
              trxType={trxType}
              subtitle="7 Days"
              yValueLabel="agents"
              startDateStr={startDateStr}
              endDateStr={endDateStr}
              field="activeAgentsTrend"
              color={"light-" + color}
              code={code}
            ></SummaryCard>
          </Col>
          {GetInstitution().code == '*' && 
            <Col lg={12} md={12} sm={12}>
              <SummaryCard
                title={"ACTIVE INSTITUTIONS"}
                trxType={trxType}
                subtitle="7 Days"
                yValueLabel="agents"
                startDateStr={startDateStr}
                endDateStr={endDateStr}
                field="activeInstitutionsTrend"
                color={"light-" + color}
                code={code}
              ></SummaryCard>
            </Col>}
        </Row>
      </CardBody>
    </Card>
  )
}

export default HighlightTab
