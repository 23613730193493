import { defaultObj } from "helpers/utility"
import { Institution } from "pages/InstitutionManagement/institution"
import { ServiceConfiguration } from "pages/ServiceConfigurationManagement/create/form"
import {
  GET_INSTITUTION_DETAIL_SUCCESS,
  GET_INSTITUTION_DETAIL_FAIL,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAIL,
  GET_ALL_INSTITUTIONS,
  INSTITUTION_PURGE,
  ENABLE_INSTITUTION,
  ENABLE_INSTITUTION_SUCCESS,
  INSTITUTION_PURGE_SUCCESS,
  GET_INSTITUTIONS,
  POST_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION_SUCCESS,
  POST_INSTITUTION_FAIL,
  CONFIGURE_SERVICES,
  CONFIGURE_SERVICES_FAIL,
  CONFIGURE_SERVICES_SUCCESS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS,
} from "./actionTypes"
type InstitutionInitialState = {
  institutions: Institution[],
  institutionDetail?: Institution,
  serviceConfiguration?: ServiceConfiguration,
  error?: string,
  success: string,
  totalCount: number,
  loading: boolean,
  editLoading: boolean,
  statusChanged?: boolean,
}
const INIT_STATE: InstitutionInitialState = {
  institutions: [],
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const Institutions = (state = INIT_STATE, action): InstitutionInitialState => {
  switch (action.type) {
    case GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        institutions: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }

    case GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS:
      return {
        ...state,
        serviceConfiguration: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
      }

    case GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTION_SERVICE_CONFIGURATIONS:
      return {
        ...state,
        loading: true,
        serviceConfiguration:undefined,
        error: "",
        success: "",
      }
    case GET_INSTITUTIONS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_ALL_INSTITUTIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_INSTITUTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_INSTITUTION_DETAIL_SUCCESS:
      return {
        ...state,
        institutionDetail: action.payload?.Data,
        loading: false,
      }

    case GET_INSTITUTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_INSTITUTION_SUCCESS:
      return {
        ...state,
        institutionDetail: defaultObj(state.institutionDetail),
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_INSTITUTION:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case CREATE_INSTITUTION:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case POST_INSTITUTION_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case CONFIGURE_SERVICES:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case CONFIGURE_SERVICES_SUCCESS:
      return {
        ...state,
        serviceConfiguration: defaultObj(state.serviceConfiguration),
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }

    case CONFIGURE_SERVICES_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_INSTITUTION_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_INSTITUTION:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_INSTITUTION_SUCCESS:
      let detail = state.institutionDetail
      detail.IsActive = action.payload?.enable
      let institutions = state.institutions
      if (detail) {
        institutions.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        institutions,
        editLoading: false,
        loading: false,
        institutionDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case INSTITUTION_PURGE_SUCCESS:
      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Institutions
