import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label, Collapse } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import Loading from "components/Common/Loading"
import ClusterForm from "components/Common/Form"
import withFormValidation from "components/HOCs/WithFormValidation"
import { Formik } from "formik"
import { Utils } from "helpers/utility"
import { BINRoute, RoutingDetail } from "store/route/reducer"
import MyValidator from "./validator"
import Validator from "./validatorCardScheme"
import { EDIT_BIN_ROUTE } from "../permission"
import { RoutingBasis } from "enums/routing-basis"
import { CardPattern } from "enums/card-schems"
import RouteConfigForm from "./RoutingForm"

const RoutingForm = props => {
  const {
    editLoading,
    bins,
    loading,
    binRoutes,
    categories,
    routes,
    history,
    handleValidSubmit
  } = props
  const [routingBasis, setRoutingBasis] = useState<undefined | RoutingBasis>(undefined)
  const [cardScheme, setCardScheme] = useState<undefined | CardPattern>(undefined)
  const { RouteValidator, isValidBIN } = MyValidator
  const { RouteValidatorCardScheme } = Validator
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  useEffect(() => {
    if (isSubmitting && !editLoading && history) {
      setIsSubmitting(false);
      setRoutingBasis(undefined)
      setCardScheme(undefined)
    }

  }, [editLoading, isSubmitting])
  if (Utils.Object.isEmpty(routes)) {
    return <Loading>
      <p>Loading Routes...</p>
    </Loading>
  }
  const Initiate = (): BINRoute => {
    return {
      FilterValue: "",
      FilterBy: "",
      Routes: [{
        SwitchingRouteID: 0,
        MinimumAmount: 0,
        MaximumAmount: 0,
        AgentCategoryID: null
      }]
    }
  }

  const setRouteField = (setFieldValue, index: number, routes: RoutingDetail[], field: string, value) => {
    setFieldValue("Routes", routes.map((route, i) => {
      if (i == index) {
        route[field] = +(value);
      }
      return route
    }))
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={Initiate()}
        validate={routingBasis === RoutingBasis.CardBin ? RouteValidator(binRoutes) : RouteValidatorCardScheme(binRoutes)}
        onSubmit={(values, { resetForm }) => {

          handleValidSubmit({ ...values });
          setIsSubmitting(true);
          setTimeout(() => {
            resetForm({ values: Initiate() });
            setRoutingBasis(undefined)
          }, 2000)
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setErrors,
          setValues,
        }) => (
          <ClusterForm handleSubmit={handleSubmit}>

            <React.Fragment>
              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>

                    <Col lg={3} md={6} sm={12}>

                      <label>Select Routing Basis</label>

                      <ClusterDropdown
                        id={"FilterBy"}
                        name={"FilterBy"}
                        focus
                        required={true}
                        placeholder={"-Select-"}
                        items={Utils.Enums.toOptions(RoutingBasis)}
                        onChange={(name, value: RoutingBasis) => {
                          setRoutingBasis(value ?? undefined)
                          setFieldValue("FilterBy", RoutingBasis[value])
                        }}
                        validationError={errors.FilterBy}
                      ></ClusterDropdown>
                    </Col>

                    {routingBasis === RoutingBasis.CardBin && (
                      <Col lg={3} md={6} sm={12}>
                        <ClusterInput
                          defaultValue={values?.FilterValue}
                          focus
                          label={"Card BIN (First six digits of the card PAN)"}
                          id={"FilterValue"}
                          name={"FilterValue"}
                          value={values?.FilterValue}
                          type="number"
                          required={true}
                          validationError={errors.FilterValue}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></ClusterInput>
                      </Col>)}

                    {routingBasis === RoutingBasis.CardPattern && (
                      <Col lg={3} md={6} sm={12}>
                        <label>Select a Card Pattern</label>
                        <ClusterDropdown
                          id={"FilterValue"}
                          name={"FilterValue"}
                          focus
                          required={true}
                          placeholder={"-Select-"}
                          items={Utils.Enums.toOptions(CardPattern)}
                          onChange={(name, value: CardPattern) => {
                            setCardScheme(value ?? undefined)
                            setFieldValue("FilterValue", CardPattern[value])
                          }}
                          validationError={errors.FilterValue}
                        ></ClusterDropdown>
                      </Col>)}
                  </Row>
                  {values?.FilterValue && routingBasis === RoutingBasis.CardBin &&
                    <RouteConfigForm
                      values={values}
                      routes={props.routes}
                      errors={errors}
                      setRouteField={setRouteField}
                      handleBlur={handleBlur}
                      categories={props.categories}
                      setFieldValue={setFieldValue}
                      setErrors={setErrors}
                      isValid={isValid}
                    />
                  }

                  {values?.FilterValue && routingBasis === RoutingBasis.CardPattern &&
                    <RouteConfigForm
                      values={values}
                      routes={props.routes}
                      errors={errors}
                      setRouteField={setRouteField}
                      handleBlur={handleBlur}
                      categories={props.categories}
                      setFieldValue={setFieldValue}
                      setErrors={setErrors}
                      isValid={isValid}
                    />
                  }
                </CardBody>
              </Card>
            </React.Fragment>


            <ClusterButton
              permissions={[EDIT_BIN_ROUTE]}
              loading={editLoading}
              disabled={((editLoading || !isValid) && routingBasis === undefined)}
              color={"primary"}
              addon={"bx bx-save"}
              className={"btn-lg"}
            >
              {"Save"}
            </ClusterButton>
          </ClusterForm>
        )}
      </Formik>



    </React.Fragment>
  )
}
RoutingForm.propTypes = {
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  bins: PropTypes.array,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  routes: PropTypes.array,
  binRoutes: PropTypes.array,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(RoutingForm))
