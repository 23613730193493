import { CardTransferStatus } from "enums/card-transfer-status"
import { PayWithTransferStatus } from "enums/pay-with-transfer-status"
import {
  GET_RECEIPTS_FAIL,
  GET_RECEIPTS_SUCCESS,
  GET_RECEIPT_DETAIL_SUCCESS,
  GET_RECEIPT_DETAIL_FAIL,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_RECEIPTS,
  GET_CASHOUTREPORTS_SUCCESS,
  GET_CASHOUTREPORTS,
  REPORT_PURGE_SUCCESS,
  GET_CASHOUTREPORTS_FAIL,
  GET_FUNDSTRANSFERREPORTS_SUCCESS,
  GET_FUNDSTRANSFERREPORTS,
  GET_FUNDSTRANSFERREPORTS_FAIL,
  GET_DOWNLOAD_REPORTS_SUCCESS,
  GET_DOWNLOAD_REPORTS,
  GET_DOWNLOAD_REPORTS_FAIL,
  LOG_DOWNLOAD,
  LOG_DOWNLOAD_FAIL,
  LOG_DOWNLOAD_SUCCESS,
  GET_TRANSACTION_REPORTS_SUCCESS,
  GET_TRANSACTION_REPORTS,
  GET_TRANSACTION_REPORTS_FAIL,
  GET_COLLECTION_REPORTS_SUCCESS,
  GET_COLLECTION_REPORTS,
  GET_COLLECTION_REPORTS_FAIL,
  GET_SUMMARYREPORTS,
  GET_SUMMARYREPORTS_SUCCESS,
  GET_SUMMARYREPORTS_FAIL,
  GET_COMPARATIVESREPORTS,
  GET_COMPARATIVESREPORTS_SUCCESS,
  GET_COMPARATIVESREPORTS_FAIL,
  GET_PAYWITHTRANSFER_REPORTS,
  GET_PAYWITHTRANSFER_REPORTS_SUCCESS,
  GET_PAYWITHTRANSFER_REPORTS_FAIL,
  GET_CARDTRANSFER_REPORTS,
  GET_CARDTRANSFER_REPORTS_SUCCESS,
  GET_CARDTRANSFER_REPORTS_FAIL,
} from "./actionTypes"
export type PayWithTransferReport={
  VirtualAccount:string,
  AgentAccountNumber:string,
  Status:PayWithTransferStatus,
  Reference:string,
  AmountExpected:number,
  AmountPaid:number,
  Fee:number,
  DateCreated:Date,
  DateUpdated:Date,
  CustomerName:string,
  AgentName:string
}
export type CardTransferReport={
  TransferAmount: number,
  WithdrawalAmount: number,
  Fee: number,
  BeneficiaryName: string,
  BeneficiaryAccount: string,
  BeneficiaryBank: string,
  RetrievalReferenceNumber: string,
  STAN: string,
  MaskedPAN: string,
  AgentName:string,
  AgentPhoneNumber:string,
  Date: Date,
  TransferStatus:CardTransferStatus,
  WithdrawalStatus:CardTransferStatus,
  StatusDetails:string,
  ID: number
}
type ReportsInitState={
  receipts:any[],
  receiptTotalCount:number,
  receiptDetail: any,
  cashOutTransactions:any[],
  cashOutTotalCount:number,
  fundsTransferTransactions:any[],
  fundsTransferTotalCount:number,
  transactions:any[],
  transactionTotalCount:number,
  collections:any[],
  collectionTotalCount:number,
  payWithTransferTransactions:PayWithTransferReport[],
  payWithTransferTotalCount:number,
  cardTransferTransactions:CardTransferReport[],
  cardTransferTotalCount:number,
  downloads:any[],
  downloadsTotalCount:number,
  summary:any[],
  summaryTotalCount:number,
  comparatives:any[],
  comparativesTotalCount:number,
  editLoading: boolean,
  error: any,
  success: string,
  loading: boolean,
}
const INIT_STATE:ReportsInitState = {
  receipts: [],
  receiptTotalCount: 0,
  receiptDetail: {},
  cashOutTransactions: [],
  cashOutTotalCount: 0,
  fundsTransferTransactions: [],
  fundsTransferTotalCount: 0,
  transactions: [],
  transactionTotalCount: 0,
  collections: [],
  collectionTotalCount: 0,
  payWithTransferTransactions: [],
  payWithTransferTotalCount: 0,
  cardTransferTransactions: [],
  cardTransferTotalCount: 0,
  downloads: [],
  downloadsTotalCount: 0,
  summary: [],
  summaryTotalCount: 0,
  comparatives: [],
  comparativesTotalCount: 0,
  editLoading: false,
  error: {},
  success: undefined,
  loading: true,
}

const Receipts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_RECEIPTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_RECEIPTS_SUCCESS:
      return {
        ...state,
        receipts: action.payload?.Data?.Collection || [],
        receiptTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }

    case GET_RECEIPTS_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        receipts:[],
        receiptTotalCount:0,
        loading: false,
        success: undefined,
      }
    case GET_CASHOUTREPORTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_CASHOUTREPORTS_SUCCESS:
      return {
        ...state,
        cashOutTransactions: action.payload?.Data?.Collection || [],
        cashOutTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CASHOUTREPORTS_FAIL:
      return {
        ...state,
        cashOutTransactions:[],
        cashOutTotalCount:0,
        error: action.payload?.Message,
        loading: false,
        success: undefined,
      }
      case GET_PAYWITHTRANSFER_REPORTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_PAYWITHTRANSFER_REPORTS_SUCCESS:
      return {
        ...state,
        payWithTransferTransactions: action.payload?.Data?.Collection || [],
        payWithTransferTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_PAYWITHTRANSFER_REPORTS_FAIL:
      return {
        ...state,
        payWithTransferTransactions:[],
        payWithTransferTotalCount:0,
        error: action.payload?.Message,
        loading: false,
        success: undefined,
      }
      case GET_CARDTRANSFER_REPORTS:
        return {
          ...state,
          loading: true,
          error: undefined,
          success: undefined,
        }
      case GET_CARDTRANSFER_REPORTS_SUCCESS:
        return {
          ...state,
          cardTransferTransactions: action.payload?.Data?.Collection || [],
          cardTransferTotalCount: action.payload?.Data?.TotalCount || 0,
          loading: false,
        }
      case GET_CARDTRANSFER_REPORTS_FAIL:
        return {
          ...state,
          cardTransferTransactions:[],
          cardTransferTotalCount:0,
          error: action.payload?.Message,
          loading: false,
          success: undefined,
        }
      case GET_FUNDSTRANSFERREPORTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_FUNDSTRANSFERREPORTS_SUCCESS:
      return {
        ...state,
        fundsTransferTransactions: action.payload?.Data?.Collection || [],
        fundsTransferTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_FUNDSTRANSFERREPORTS_FAIL:
      return {
        ...state,
        fundsTransferTransactions:[],
        fundsTransferTotalCount:0,
        error: action.payload?.Message,
        loading: false,
        success: undefined,
      }
      case GET_SUMMARYREPORTS:
        return {
          ...state,
          loading: true,
          error: undefined,
          success: undefined,
        }
      case GET_SUMMARYREPORTS_SUCCESS:
        return {
          ...state,
          summary: action.payload?.Data?.Collection || [],
          summaryTotalCount: action.payload?.Data?.TotalCount || 0,
          loading: false,
        }
      case GET_SUMMARYREPORTS_FAIL:
        return {
          ...state,
          summary:[],
          summaryTotalCount:0,
          error: action.payload?.Message,
          loading: false,
          success: undefined,
        }
        case GET_COMPARATIVESREPORTS:
          return {
            ...state,
            loading: true,
            error: undefined,
            success: undefined,
          }
        case GET_COMPARATIVESREPORTS_SUCCESS:
          return {
            ...state,
            comparatives: action.payload?.Data?.Collection || [],
            comparativesTotalCount: action.payload?.Data?.TotalCount || 0,
            loading: false,
          }
        case GET_COMPARATIVESREPORTS_FAIL:
          return {
            ...state,
            comparatives:[],
            comparativesTotalCount:0,
            error: action.payload?.Message,
            loading: false,
            success: undefined,
          }
    case LOG_DOWNLOAD:
      return {
        ...state,
        loading: false,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
    case LOG_DOWNLOAD_SUCCESS:
      return {
        ...state,
        success: action.payload?.Message,
        loading: false,
        editLoading: false,
      }
    case LOG_DOWNLOAD_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        success: undefined,
      }
    case GET_DOWNLOAD_REPORTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_DOWNLOAD_REPORTS_SUCCESS:
      return {
        ...state,
        downloads: action.payload?.Data?.Collection || [],
        downloadsTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_DOWNLOAD_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        loading: false,
        success: undefined,
        downloads:[],
        downloadsTotalCount:0
      }
    case GET_TRANSACTION_REPORTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }

    case GET_TRANSACTION_REPORTS_SUCCESS:
      return {
        ...state,
        transactions: action.payload?.Data?.Collection || [],
        transactionTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
        error: undefined,
        success: "Report generated successfully"
      }
    case GET_TRANSACTION_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        loading: false,
        success: undefined,
        transactions:  [],
        transactionTotalCount:0,
      }
      case GET_COLLECTION_REPORTS:
        return {
          ...state,
          loading: true,
          error: undefined,
          success: undefined,
        }
  
      case GET_COLLECTION_REPORTS_SUCCESS:
        return {
          ...state,
          collections: action.payload?.Data?.Collection || [],
          collectionTotalCount: action.payload?.Data?.TotalCount || 0,
          loading: false,
          error: undefined,
          success: "Report generated successfully"
        }
      case GET_COLLECTION_REPORTS_FAIL:
        return {
          ...state,
          error: action.payload?.Message,
          loading: false,
          success: undefined,
          collections:  [],
          collectionTotalCount:0,
        }
    case GET_REPORTS_SUCCESS:
      let r = state.receipts
      if (r[`${action.trxType}`] != undefined) {
        r[`${action.trxType}`][`${action.field}`] = action.payload
      }
      return {
        ...state,
        receipts: r,
      }

    case GET_REPORTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_RECEIPT_DETAIL_SUCCESS:
      return {
        ...state,
        receiptDetail: action.payload,
      }

    case GET_RECEIPT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        success: undefined,
      }

    case REPORT_PURGE_SUCCESS:
      return INIT_STATE
    default:
      return state
  }
}

export default Receipts
