import { defaultObj } from "helpers/utility"
import {
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  GET_ALL_CATEGORIES,
  CATEGORY_PURGE,
  ENABLE_CATEGORY,
  ENABLE_CATEGORY_SUCCESS,
  CATEGORY_PURGE_SUCCESS,
  GET_CATEGORIES,
  POST_CATEGORY_SUCCESS,
  UPDATE_CATEGORY,
  CREATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  POST_CATEGORY_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  categories: [],
  categoryDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const AgentCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CATEGORIES:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_ALL_CATEGORIES:
      return {
        ...state,
        loading: true,
      }
    case GET_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CATEGORY_DETAIL_SUCCESS:
      return {
        ...state,
        categoryDetail: action.payload?.Data,
        // success: "Successful",
        loading: false,
      }

    case GET_CATEGORY_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_CATEGORY_SUCCESS:
      return {
        ...state,
        category: defaultObj(state.category), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_CATEGORY:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case CREATE_CATEGORY:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case POST_CATEGORY_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_CATEGORY_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_CATEGORY:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_CATEGORY_SUCCESS:
      let detail = state.categoryDetail
      detail.IsActive = action.payload?.enable
      let categories = state.categories
      if (detail) {
        categories.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        categories,
        editLoading: false,
        loading: false,
        categoryDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case CATEGORY_PURGE_SUCCESS:
      return INIT_STATE
    default:
      return state
  }
  return state
}

export default AgentCategories
