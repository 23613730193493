/* PERMISSIONS */
export const GET_PERMISSIONS = "GET_PERMISSIONS"
export const GET_PERMISSIONS_SUCCESS = "GET_PERMISSIONS_SUCCESS"
export const GET_PERMISSIONS_FAIL = "GET_PERMISSIONS_FAIL"

export const GET_FUNCTION_MENU:any = "GET_FUNCTION_MENU"
export const GET_FUNCTION_MENU_SUCCESS = "GET_FUNCTION_MENU_SUCCESS"
export const GET_FUNCTION_MENU_FAIL = "GET_FUNCTION_MENU_FAIL"

export const GET_FUNCTIONS = "GET_FUNCTIONS"
export const GET_FUNCTIONS_SUCCESS = "GET_FUNCTIONS_SUCCESS"
export const GET_FUNCTIONS_FAIL = "GET_FUNCTIONS_FAIL"

export const GET_FUNCTIONS_BY_USER = "GET_FUNCTIONS_BY_USER"
export const GET_FUNCTIONS_BY_USER_SUCCESS = "GET_FUNCTIONS_BY_USER_SUCCESS"
export const GET_FUNCTIONS_BY_USER_FAIL = "GET_FUNCTIONS_BY_USER_FAIL"

export const GET_FUNCTION_ACTIONS = "GET_FUNCTION_ACTIONS"
export const GET_FUNCTION_ACTIONS_SUCCESS = "GET_FUNCTION_ACTIONS_SUCCESS"
export const GET_FUNCTION_ACTIONS_FAIL = "GET_FUNCTION_ACTIONS_FAIL"
