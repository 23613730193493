import { IntegrationType } from "enums/integration-type"
import { SettlementMode } from "enums/settlement-mode"
import { defaultObj } from "helpers/utility"
import {
  GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS,
  GET_CASHOUT_INSTITUTION_DETAIL_FAIL,
  GET_CASHOUT_INSTITUTIONS_SUCCESS,
  GET_CASHOUT_INSTITUTIONS_FAIL,
  GET_ALL_CASHOUT_INSTITUTIONS,
  CASHOUT_INSTITUTION_PURGE,
  ENABLE_CASHOUT_INSTITUTION,
  ENABLE_CASHOUT_INSTITUTION_SUCCESS,
  CASHOUT_INSTITUTION_PURGE_SUCCESS,
  GET_CASHOUT_INSTITUTIONS,
  POST_CASHOUT_INSTITUTION_SUCCESS,
  UPDATE_CASHOUT_INSTITUTION,
  CREATE_CASHOUT_INSTITUTION,
  UPDATE_CASHOUT_INSTITUTION_SUCCESS,
  POST_CASHOUT_INSTITUTION_FAIL,
} from "./actionTypes"

export type CashOutInstitution = {
  AppzoneAccount?: string
  BankMode?: IntegrationType
  BankCode?: string
  BankOneInstitutionCode?: string
  ServiceCode?: string
  PostingEntryCode?: string
  Name?: string
  UseNewReferenceForRetrials: boolean
  MaxRetryCount?: number
  BatchPostingApiNotSupported: boolean
  BatchPostingUrl?: boolean
  IsActive?: boolean
  ID?: number
  SettlementMode: SettlementMode
  RID: string
  SettlementAccountID: number
  SettlementAccount: any
  WalletTopUpSupportedRouteIDs : any[]
}
type CashOutInstitutionInitialState = {
  cashOutInstitutions: CashOutInstitution[],
  cashOutInstitution?: CashOutInstitution,
  error?: string,
  success: string,
  totalCount: number,
  loading: boolean,
  editLoading: boolean,
  statusChanged?: boolean,
}
const INIT_STATE: CashOutInstitutionInitialState = {
  cashOutInstitutions: [],
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const CashOutInstitutions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASHOUT_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        cashOutInstitutions: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CASHOUT_INSTITUTIONS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_ALL_CASHOUT_INSTITUTIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_CASHOUT_INSTITUTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS:
      return {
        ...state,
        cashOutInstitution: action.payload?.Data,
        loading: false,
      }

    case GET_CASHOUT_INSTITUTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_CASHOUT_INSTITUTION_SUCCESS:
      return {
        ...state,
        cashOutInstitution: defaultObj(state.cashOutInstitution),
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_CASHOUT_INSTITUTION:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case CREATE_CASHOUT_INSTITUTION:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case POST_CASHOUT_INSTITUTION_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_CASHOUT_INSTITUTION_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_CASHOUT_INSTITUTION:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_CASHOUT_INSTITUTION_SUCCESS:
      let detail = state.cashOutInstitution
      detail.IsActive = action.payload?.enable
      let cashOutInstitutions = state.cashOutInstitutions
      if (detail) {
        cashOutInstitutions.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        cashOutInstitutions,
        editLoading: false,
        loading: false,
        cashOutInstitution: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case CASHOUT_INSTITUTION_PURGE_SUCCESS:
      return INIT_STATE
    default:
      return state
  }
  return state
}

export default CashOutInstitutions
