import { Utils } from "helpers/utility";
import { Validators } from "helpers/validators";
import { toast } from "react-toastify";
import { BINRoute, RoutingDetail } from "store/route/reducer";

const getRanges = (routes: RoutingDetail[]) => {
    let ranges: any[][] = []
    routes.forEach(route => {
        ranges.push([route.MinimumAmount, route.MaximumAmount, route.SwitchingRouteID, route.AgentCategoryID])
    })
    return ranges;
}
const rangeValidator = (routes: RoutingDetail[]) => {
    const ranges = getRanges(routes);
    return (index: number, testType: string) => {
        if (ranges.length == 1) return true;
        const route = routes[index];
        const value = testType == "max" ? +(route.MaximumAmount) : +(route.MinimumAmount);
        if (value == undefined) return false;
        const filteredRoutes = ranges.filter((range, i) => {
            const agentCategory = range[3];
            return i < index && route.AgentCategoryID == agentCategory;
        });
        return !(filteredRoutes.some((range, i) => {
            const min = range[0];
            const max = range[1];
            if (testType == "min") return value >= min && value <= max

            return (value >= min && value <= max) || (+(route.MinimumAmount) < min && value >= max);
        }))
    }
}

const RouteValidatorCardScheme = (binRoutes: any[]) => {


    return (values: BINRoute) => {
        const errors: any = {};
        const required = Validators.required("Please enter a value");

        const validateRange = rangeValidator(values.Routes);

        const switchingRoutes = () => {
            values.Routes.forEach((route, i) => {

                if (Utils.Object.isEmpty(errors.Routes[i])) errors.Routes[i] = { SwitchingRouteID: undefined }
                if (!route.SwitchingRouteID) errors.Routes[i].SwitchingRouteID = "Select a route";

            })
        }

        const filterBy = () => {
            [required].forEach(validator => {
                let error = validator(values.FilterBy)
                if (error) errors.FilterBy = error
            })
        }

        const filterValue = () => {
            [required].forEach(validator => {
                let error = validator(values.FilterValue)
                if (error) errors.FilterValue = error
            })
        }
        const minimumAmount = () => {
            values.Routes.forEach((route, i) => {
                if (Utils.Object.isEmpty(errors.Routes[i])) errors.Routes[i] = { MinimumAmount: undefined, MaximumAmount: undefined }
                let isAllowed = validateRange(i, "min");
                if (!isAllowed) {

                    errors.Routes[i].MinimumAmount = "Amount is not within a valid range, ensure amounts do not overlap";
                } else {
                    if (+(route.MinimumAmount) > +(route.MaximumAmount)) errors.Routes[i].MinimumAmount = "Minimum Amount must be less than the maximum amount"
                }
            })
        }
        const maximumAmount = () => {
            values.Routes.forEach((route, i) => {
                if (Utils.Object.isEmpty(errors.Routes[i])) errors.Routes[i] = { MinimumAmount: undefined, MaximumAmount: undefined }
                let isAllowed = validateRange(i, "max");
                if (!isAllowed) {
                    errors.Routes[i].MaximumAmount = "Amount is not within a valid range, ensure amounts do not overlap";
                } else {
                    if (+(route.MaximumAmount) < +(route.MinimumAmount)) errors.Routes[i].MaximumAmount = "Maximum Amount must be greater than the minimum amount"
                    if (+(route.MaximumAmount) == 0) errors.Routes[i].MaximumAmount = "Maximum Amount must be greater than zero"
                }
            })
        }
        const validate = () => {
            try {
                errors.Routes = {};
                switchingRoutes();
                minimumAmount();
                maximumAmount();
                filterBy();
                filterValue();
                if (!Utils.List.isEmpty(binRoutes)) {
                    const alreadyExists = binRoutes.some(route => {

                        if (route.BIN == values.FilterValue) {
                            return values.Routes.some(r => r.MaximumAmount == route.MaximumAmount && r.MinimumAmount == route.MinimumAmount && r.AgentCategoryID == route.AgentCategoryID)
                        }

                        return false;


                    })

                    if (alreadyExists) { errors.Exists = "The newly added route combination already exists"; toast.error(errors.Exists) };
                }
                let sanitizedRoutes = errors.Routes;
                if (!Utils.List.isEmpty(errors.Routes)) {
                    for (let key in errors.Routes) {
                        let r = errors.Routes[key];
                        if (!r.MinimumAmount && !r.MaximumAmount && !r.SwitchingRouteID) delete sanitizedRoutes[key];

                        errors.Routes = sanitizedRoutes;
                        if (Utils.Object.isEmpty(errors.Routes) && values.Routes.length > 0) delete (errors.Routes)

                    }

                }
                return errors;
            } catch (err) {
                console.log(err)
            }
        }
        return validate();
    }
}

export default { RouteValidatorCardScheme}