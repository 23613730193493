import { useState, useEffect } from "react"
import moment from "moment"
import { Periodicity } from "enums/periodicity"

/*
  A hook that abstracts logic common to report pages 
*/

type reportDataProps = {
  onGetData?: (query: string) => void
  HEADERS?: any[]
  SearchCriteria?: (p: any, maxSize: Number) => string,
  period? : Number,
  periodicity? : Periodicity
}

const useReportData = ({
  onGetData,
  HEADERS,
  SearchCriteria,
  period,
  periodicity
}: reportDataProps) => {
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([...HEADERS])
const defaultPeriod = 5
const defaultPeriodicity = Periodicity.year

period = period ?? defaultPeriod
periodicity = periodicity ?? defaultPeriodicity

const defaultStartDate = moment().subtract(
  period as moment.DurationInputArg1,
  periodicity as moment.unitOfTime.DurationConstructor
)
  const defaultEndDate = moment()
  const [range, setRange] = useState([defaultStartDate, defaultEndDate])
  const [startDate, endDate] = range
  const maxSize = 10
  const [pageNumber, setPageNumber] = useState(1)
  const [params, setParams] = useState<any>({
    fromDate: startDate,
    toDate: endDate,
    page: pageNumber,
  })

  function search(query) {
    if (onGetData) {
      onGetData(query)
    }
  }
  const handlePageChange = page => {
    setPageNumber(page)
    let p = { ...params, page }
    search(SearchCriteria(p, maxSize))
  }

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [params])

  const onAction = p => {
    if (p["page"]) {
      setPageNumber(+p["page"])
    } else {
      p["page"] = pageNumber
    }
    let prms = { fromDate: params["fromDate"], toDate: params["toDate"], ...p }
    setParams(prms)
    search(SearchCriteria(prms, maxSize))
  }

  return {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
    search,
    setPageNumber,
    setParams,
    startDate,
    endDate
  }
}

export default useReportData
