import PropTypes from "prop-types"
import React, { useState } from "react"
import moment from "moment"
import { Card, Col, Row, CardBody, FormGroup, Label, Input } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { TRANSACTIONSTATUS } from "helpers/variables"
const Search = props => {
  let { handleOnSearch, endDateStr, startDateStr } = props
  let [params, setParams] = useState({})
  const handleOnChange = (key, value) => {
    let p = params
    p[`${key}`] = value
    setParams(p)
    handleOnSearch(p)
  }

  return (
    <Row>
      <Col lg={12}>
        <Card className={"text-left"}>
          <CardBody>
            <Row>
              <Col lg={4}>
                <FormGroup>
                  <Label for="status">Status</Label>
                  <Row>
                    <ClusterDropdown
                      style={{ left: "25%", top: "60%" }}
                      defaultValue={"SUCCESSFUL"}
                      items={TRANSACTIONSTATUS}
                      onChange={(name, value) =>
                        handleOnChange("status", value)
                      }
                    ></ClusterDropdown>
                  </Row>
                </FormGroup>
              </Col>
              <Col lg={4} sm={6}>
                <FormGroup>
                  <Label for="fromDate">From Date</Label>
                  <Input
                    type="date"
                    defaultValue={moment(startDateStr).format("YYYY-MM-DD")}
                    id="fromDate"
                    onChange={e =>
                      handleOnChange(
                        "fromDate",
                        moment(e.target.value)
                          .startOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss")
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col lg={4} sm={6}>
                <FormGroup>
                  <Label for="toDate">To Date</Label>
                  <Input
                    type="date"
                    defaultValue={moment(endDateStr).format("YYYY-MM-DD")}
                    id="toDate"
                    onChange={e =>
                      handleOnChange(
                        "toDate",
                        moment(e.target.value)
                          .endOf("day")
                          .format("YYYY-MM-DDTHH:mm:ss")
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

Search.propTypes = {
  handleOnSearch: PropTypes.func,
  endDateStr: PropTypes.string,
  startDateStr: PropTypes.string,
}

export default Search
