import { AccountMode } from "enums/account-mode";
import { UserScope } from "enums/user-scope";
import { fetcher } from "helpers/api_helper";
import { InstitutionsHelper } from "helpers/institutions";
import { GET_LGAS, GET_ALL_NETWORK_MANAGERS, GET_PROVINCES, GEET_ALL_NETWORK_MANAGERS_, GET_STATES } from "helpers/url_helper";
import { Utils } from "helpers/utility";
import { Validators } from "helpers/validators";
import { useCallback, useMemo } from "react";
import useSWR from "swr";
import { CREATE_AGENT, MODIFY_AGENT_SENSITIVE_DETAILS } from "../permission";

const useAgent = (accountMode, clusterId, roles, isUpdate) => {


    const lgas = useSWR(GET_LGAS, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
    })

      const states = useSWR(GET_STATES, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
    })
    const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/${clusterId ?? 0}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    const networkManagers_ = useSWR(`${GEET_ALL_NETWORK_MANAGERS_}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    const provinces = useSWR(GET_PROVINCES, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
    })

    const lgaData = useMemo(() => {
        return (lgas.data as any)?.Data ?? []
    }, [lgas.data])

    const stateData = useMemo(() => {
        return (states.data as any)?.Data ?? []
    }, [states.data])

    const networkManagersData = useMemo(() => {
        return (networkManagers?.data as any)?.Data ?? [];
    }, [networkManagers.data])

      const networkManagersData_ = useMemo(() => {
        return (networkManagers_?.data as any)?.Data ?? [];
    }, [networkManagers_.data])

    const assignableRoles = useMemo(() => {
        if (!Utils.List.isEmpty(roles)) {
            return roles.filter(role => role.scope == UserScope.Agent).map(r => ({ id: r.id, key: r.id, value: r.name }))
        }
        return [];
    }, [roles])


    const SENSITIVE_PERMISSIONS = useMemo(() => {
        if (accountMode === AccountMode.Wallet && !isUpdate) return [CREATE_AGENT];
        return [MODIFY_AGENT_SENSITIVE_DETAILS];
    }, [accountMode])

    const validator = (values) => {

        let errors: any = {}
        const required = Validators.required("Please enter a value");
        const dropdownRequired = Validators.min(1, "Please select a value")
        const lga = () =>
            [
                required,
                dropdownRequired
            ].forEach(validator => {
                let error = validator(values.LGAID)
                if (error) errors.LGAID = error;
            });
        const category = () =>
            [
                required,
                dropdownRequired
            ].forEach(validator => {
                let error = validator(values.CategoryID)
                if (error) errors.CategoryID = error;
            });


        const cluster = () =>
            [
                required,
                dropdownRequired
            ].forEach(validator => {
                let error = validator(values.ClusterID)
                if (error) errors.ClusterID = error;
            });

        const province = () =>
            [
                required,
                dropdownRequired
            ].forEach(validator => {
                let error = validator(values.ProvinceID)
                if (error) errors.ProvinceID = error;
            });

        const gender = () =>
            [
                required,
            ].forEach(validator => {
                let error = validator(values.Gender)
                if (error) errors.Gender = error;
            });

        const incomeAccount = () =>
            [
                required,
                Validators.accountNumber([
                    "Account number should contain only numbers",
                    "Account number must be 10 digits",
                ])
            ].forEach(validator => {
                let error = validator(values.IncomeAccount)
                if (error) errors.IncomeAccount = error;
            });


        const commissionAccount = () =>
            [
                required,
                Validators.accountNumber([
                    "Account number should contain only numbers",
                    "Account number must be 10 digits",
                ])
            ].forEach(validator => {
                let error = validator(values.CommissionAccount)
                if (error) errors.CommissionAccount = error;
            });

        const accountNumber = () =>
            [
                required,
                Validators.accountNumber([
                    "Account number should contain only numbers",
                    "Account number must be 10 digits",
                ])
            ].forEach(validator => {
                let error = validator(values.AccountNumber)
                if (error) errors.AccountNumber = error;
            });

        const otherNames = () => [required, Validators.minLength(
            3,
            "Minimum number of characters is 3"
        )
        ].forEach(validator => {
            let error = validator(values.OtherNames)
            if (error) errors.OtherNames = error;
        });

        const lastName = () => [required,
            Validators.minLength(
                3,
                "Minimum number of characters is 3"
            )

        ].forEach(validator => {
            let error = validator(values.LastName)
            if (error) errors.LastName = error;
        });

        const email = () => [required, Validators.email(
            "Enter a valid email e.g abc@xyz.com"
        )
        ].forEach(validator => {
            let error = validator(values.Email)
            if (error) errors.Email = error;
        });

        const address = () => [required, Validators.maxLength(
            100,
            "Maximum number of characters is 100"
        ),
        Validators.minLength(
            4,
            "Minimum number of characters is 4"
        )
        ].forEach(validator => {
            let error = validator(values.AddressLocation)
            if (error) errors.AddressLocation = error;
        });


        const phoneNumber = () => [required, Validators.phone([
            "Phone number should contain only numbers",
            "Phone number must be 11 digits",
        ])
        ].forEach(validator => {
            let error = validator(values.PhoneNumber)
            if (error) errors.PhoneNumber = error;
        });

        const personalPhoneNumber = () => [required, Validators.phone([
            "Phone number should contain only numbers",
            "Phone number must be 11 digits",
        ])
        ].forEach(validator => {
            let error = validator(values.PersonalPhoneNumber)
            if (error) errors.PersonalPhoneNumber = error;
        });

        const superAgentPhoneNumber = () => [Validators.phone([
            "Phone number should contain only numbers",
            "Phone number must be 11 digits",
        ])
        ].forEach(validator => {
            if (values.SuperAgentPhoneNumber) {
                let error = validator(values.SuperAgentPhoneNumber)
                if (error) errors.SuperAgentPhoneNumber = error;
            }
        });

        const bvn = () => [required, Validators.phone([
            "Only numbers allowed",
            "Minimum number of characters is 11",
        ])
        ].forEach(validator => {
            let error = validator(`${values.BankVerificationNumber}`)
            if (error) errors.BankVerificationNumber = error;
        });

        const staffID = () => [Validators.length(
            15,
            "Staff ID must be 15 characters long"
        )
        ].forEach(validator => {
            let error = validator(values.StaffID)
            if (error) errors.StaffID = error;
        });

        const domiciliaryBank = () => [required, Validators.minLength(
            3,
            "Minimum of 3 characters"
        )
        ].forEach(validator => {
            let error = validator(values.DomiciliaryBank)
            if (error) errors.DomiciliaryBank = error;
        });

        const postingLimit = () => [required, Validators.amount()
        ].forEach(validator => {
            let error = validator(values.PostingLimit)
            if (error) errors.PostingLimit = error;
        });

        const cummulativeDepositLimit = () => [Validators.amount()
        ].forEach(validator => {
            let error = validator(values.CummulativeDepositLimit)
            if (error) errors.CummulativeDepositLimit = error;
        });

        const withdrawalLimit = () => [Validators.amount()
        ].forEach(validator => {
            let error = validator(values.WithdrawalLimit)
            if (error) errors.WithdrawalLimit = error;
        });

        const cummulativeWithdrawalLimit = () => [Validators.amount()
        ].forEach(validator => {
            let error = validator(values.CummulativeWithdrawalLimit)
            if (error) errors.CummulativeWithdrawalLimit = error;
        });

        const cardTransactionAmountLimit = () => [required, Validators.amount()
        ].forEach(validator => {
            let error = validator(values.CardTransactionAmountLimit)
            if (error) errors.CardTransactionAmountLimit = error;
        });

        const transferLimit = () => [required, Validators.amount()
        ].forEach(validator => {
            let error = validator(values.TransferLimit)
            if (error) errors.TransferLimit = error;
        });

       
        const validate = () => {
            try {
                postingLimit();
                cummulativeDepositLimit();
                withdrawalLimit();
                cummulativeWithdrawalLimit();
                cardTransactionAmountLimit();
                transferLimit();
                if (values.AccountMode !== AccountMode[AccountMode.Wallet]) {
                    incomeAccount();
                    commissionAccount();
                    accountNumber();
                }
                if ((accountMode === AccountMode.Wallet || InstitutionsHelper.Validator.isConsolidated) && !InstitutionsHelper.Validator.isAccessCongo) {
                    lga();
                } else if (InstitutionsHelper.Validator.isAccessCongo) {
                    province();
                }

                if (InstitutionsHelper.Validator.isAccessNg && values.AccountNumber) {
                    staffID();
                }
                otherNames();
                lastName();
                gender();
                category();
                cluster();
                lga();
                email();
                phoneNumber();
                personalPhoneNumber();
                superAgentPhoneNumber();
                address();
                bvn()
                domiciliaryBank();
                postingLimit();
                return errors;
            } catch (err) {
                console.log(err)
            }
        }

        return validate();

    }

    const corebankingAccountValidated = useMemo(() => {
        return accountMode === AccountMode.CoreBanking
    }, [accountMode])

    const prepopulated = useCallback((value) => {
        return corebankingAccountValidated && value != null && value != undefined;
    }, [corebankingAccountValidated])

    return {
        prepopulated, validator, SENSITIVE_PERMISSIONS, assignableRoles, lgas: lgaData, states : stateData, networkManagers: { isValidating: networkManagers.isValidating, data: networkManagersData },
        networkManagers_ : {data : networkManagersData_},
        provinces: (provinces.data as any)?.Data ?? [],
    }
}

export default useAgent;