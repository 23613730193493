import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert
} from "reactstrap"
import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import Loading from 'components/Common/Loading';
import { Validators } from 'helpers/validators';
import ClusterForm from 'components/Common/Form';
import ClusterLogic from '../cluster';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { Utils } from 'helpers/utility';
const ClustersForm = props => {
  let { apiSuccess, apiError, users, clusterManagers, errorList, myError, isUpdate, loading, editLoading, details, setErrorList, handleOnChange, handleValidSubmit } = props;
  let { MapToDropDown } = ClusterLogic;
  const [managers, setManagers] = useState([]);
  useEffect(() => {
    if (!Utils.List.isEmpty(clusterManagers)) setManagers(clusterManagers)
  }, [clusterManagers, users])

  if (!managers) return <Loading>Getting managers</Loading>
  if (isUpdate && Utils.Object.isEmpty(details)) return <Loading>Getting details</Loading>
  return <React.Fragment>
    <ClusterForm handleSubmit={handleValidSubmit}>
      {loading && isUpdate && (!details || Object.keys(details).length == 0) ?
        <Loading><p>Getting Cluster...</p></Loading> : <React.Fragment></React.Fragment>}
      <React.Fragment>


        <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
          <CardBody>
            <Row>

              <Col lg={4} md={4} sm={6}>

                <ClusterInput defaultValue={details?.Name} focus onError={(err) => setErrorList({ ...errorList, name: err })} label={"Name"} id={"name"} name={"Name"} validators={[Validators.minLength(3, "Minimum number of characters is 3"), Validators.maxLength(50, "Maximum number of characters is 50")]} type="text" required={true} onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
              </Col>

              <Col lg={4} md={4} sm={6}>

                <ClusterInput defaultValue={details?.Code} onError={(err) => setErrorList({ ...errorList, code: err })} label={"Code"} id={"code"} name={"Code"} validators={[Validators.minLength(3, "Minimum number of characters is 3")]} type="text" required={true} onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
              </Col>

              <Col lg={4} md={4}>
                <FormGroup >
                  <Label>Cluster Manager</Label>
                  {managers ? <ClusterDropdown
                    name={"ClusterManagerID"}
                    placeholder={"- Select a Cluster Manager -"}
                    defaultValue={details?.ClusterManager?.Name ?? "-Select-"}
                    items={MapToDropDown(managers.filter(c => c.IsActive))}
                    onChange={(name, value) => {
                      handleOnChange("ClusterManagerID", value);
                      setErrorList({ ...errorList, managerId: '' })
                    }} />
                    : <Alert color="info">No cluster managers available to assign</Alert>}

                </FormGroup>
              </Col>
            </Row>

          </CardBody>
        </Card>
      </React.Fragment>
      <Row style={{ paddingBottom: "50px" }}>
        <Col lg={12}>
          <ClusterButton loading={editLoading} disabled={myError != '' ? true : false} color={"primary"} addon={"bx bx-save"}>{isUpdate ? "Update" : "Save"}</ClusterButton>
        </Col>
      </Row>

    </ClusterForm>
  </React.Fragment>

}
ClustersForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(ClustersForm)))