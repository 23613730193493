import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col, Button, Alert } from "reactstrap"

// Pages Components
import Report from "../../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { enableUser, getUserDetail, getUsers, purgeUsers } from "store/actions"
import withDispose from "components/HOCs/WithDispose"

import UserModal from "./modal"
import UserLogic from "pages/UserManagement/Users/user"
import { GET_ALL_CLUSTER_USERS } from "helpers/url_helper"
import { toast } from "react-toastify"
import { Institution } from "helpers/authentication/institution"
import useReportData from "hooks/UseReportData"

const UserList = props => {
  const {
    user,
    users,
    onGetUsers,
    error,
    success,
    totalCount,
    statusChanged,
    onGetUserDetails,
    onEnableUser,
    editLoading,
    loading,
  } = props
  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = UserLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows 
  } = useReportData({ onGetData: onGetUsers, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [viewedUser, setViewedUser] = useState<any>()
  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate));

  useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      setViewedUser(user)
      setmodal(true)
    }
  }, [user])

  useEffect(() => {
    if (users) {
      populateRows()
    }
  }, [props])


  useEffect(() => {

    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  const populateRows = () => {
    let rws = []
    if (users.length > 0) {
      users.forEach(user => {
        let row = MapToRows(user)
          ; (row["action"] = (
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => onViewDetails(user.id)}
            >
              View Details
            </Button>
          )),
            rws.push(row)
      })
    }
    setRows(rws)
  }

  function onViewDetails(id) {
    onGetUserDetails(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Users"} breadcrumbItem={"View Users"} />
          {Institution.IsRemote() ? <Alert color="info">The users here only affect users on Remote accounts. To view Institution users, Login as the institution</Alert> : null}
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },

                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CLUSTER_USERS,
                  query: SearchCriteria(params, maxSize),
                  pathType: "auth"
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <UserModal
        isEnabled={viewedUser?.status === "Active"}
        editLoading={editLoading}
        onEnableUser={onEnableUser}
        isOpen={modal}
        viewedUser={viewedUser}
        setModal={setmodal}
      ></UserModal>
    </React.Fragment>
  )
}

UserList.propTypes = {
  users: PropTypes.array,
  roles: PropTypes.array,
  onGetUsers: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  onEnableUser: PropTypes.func,
}

const mapStateToProps = ({
  Users: { users, loading, success, user, editLoading, statusChanged, error, totalCount },
}) => {
  return {
    users,
    error,
    user,
    success,
    loading,
    editLoading,
    statusChanged,
    totalCount
  }
}

const mapDispatchToProps = dispatch => ({
  onGetUsers: (params) => dispatch(getUsers(params)),
  onGetUserDetails: id => dispatch(getUserDetail(id)),
  onEnableUser: (id, enable) => dispatch(enableUser(id, enable)),
  onPurge: () => dispatch(purgeUsers()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(UserList))))
