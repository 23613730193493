import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import { Row, Col, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import {
  loginUser,
  socialLogin,
  getMenu,
  purgeLoginUser,
  getFunctionsByUser,
} from "store/actions"

// import images
import logowhite from "../../assets/images/logo/light-wm/96x96.png"

import CarouselPage from "../AuthenticationInner/CarouselPage"
//Import config
import { Validators } from "helpers/validators"
import ClusterInput from "components/Common/Input/Input"
import ClusterButton from "components/Common/Button/Button"
import ClusterCheckBox from "components/Common/CheckBox"
import withDispose from "components/HOCs/WithDispose"
import RemoteModal from "../Remote/modal"
import authHeader, { setAuthUser } from "helpers/authentication/auth-token-header"
import { GetDefaultMenu, RemoteInstitution } from "helpers/authentication/institution"
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify"
import { compare } from "helpers/utility"
import { Formik } from "formik"
let loggedInUser = undefined
const Login = props => {
  // handleValidSubmit
  const { error } = props;
  const [errorList, setErrorList] = useState({
    institutionCode: "",
    username: undefined,
    password: undefined,
  })
  const [loginDetails, setLoginDetails] = useState({})
  const [myError, setMyError] = useState()
  const [loginType, setLoginType] = useState("default")
  const [anmChecked, setANMChecked] = useState(false)
  const { menus, loading } = props
  const [modal, setModal] = useState(false)
  const handleValidSubmit = loginDetails => {
    // event.preventDefault()
    const { userName, password, institutionCode }: any = loginDetails
    let loginAs = "user"
    if (!institutionCode) {
      loginAs = "impersonation"
    }

    if (loginDetails.isANM) {
      let err = Validators.email("Invalid Email Format")(userName)
      if (err) {
        toast.error("Email is required for ANMs, your username is incorrect")
        setMyError(undefined)
        return
      }
      if (!institutionCode) {
        toast.error(
          "Please enter an institution code to log in as an ANM/State Rep"
        )
        setMyError(undefined)
        return
      }
    }
    loginDetails["loginType"] =
      loginAs == "impersonation" ? 1 : anmChecked ? 0 : 2
    setLoginType(loginAs)
    props.loginUser(loginDetails, props.history)
  }

  useEffect(() => {
    if (props.error) {
      const err = Validators.extractError(props.error)

      toast.error(err)

    }
  }, [error])

  useEffect(() => {
    setMyError(Validators.validateForm(errorList))
  }, [errorList])

  useEffect(() => {
    let response = props.user
    if (response) {
      loggedInUser = props.user
      if (response.code == "00") {
        setAuthUser(JSON.stringify(response))
        props.getMenu(
          loggedInUser?.authUser?.institutionId,
          loggedInUser?.authUser?.userName,
          loggedInUser?.authUser?.userCategory
        )
        props.getFunctionsByUser()
      } else {
        toast.error(response?.message)
      }
    }
  }, [props.user])

  useEffect(() => {
    if (menus.length > 0 && authHeader()?.AuthUser) {

      props.history.push(GetDefaultMenu(menus));
    }
  }, [menus])

  const validate = (values) => {
    let errors: any = {}

    const required = Validators.required("Please enter a value");

    const password = () =>
      [required,
        Validators.password(

        )].forEach(validator => {
          let error = validator(values?.password)
          if (error) errors.password = error;
        });
    const institutionCode = () => [
      Validators.institutionCode([
        "Only number allowed",
        "Number of allowed digits is 6",
      ]),
    ].forEach(validator => {
      let error = validator(values?.institutionCode)
      if (error) errors.institutionCode = error;
    });

    const username = () => [
      required,
      Validators.minLength(
        6,
        "Minimum number of characters is 6"
      ),
    ].forEach(validator => {
      let error = validator(values?.userName)
      if (error) errors.userName = error;
    });

    const validate = () => {
      try {
        institutionCode();
        password();
        username();
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();
  }
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="no-gutters">
            <CarouselPage />

            <Col xl={3} style={{ background: "white" }}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column">
                    <div
                      className="mb-4 mb-md-5 col-lg-12 col-sm-4"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          right: "2.5%",
                          top: "2.5%",
                          maxWidth: "75px",
                        }}
                      >
                        <Link to="/" className="d-block auth-logo">
                          <img
                            src={logowhite}
                            alt=""
                            height="18"
                            className="auth-logo-dark"
                          />
                          <img
                            src={logowhite}
                            alt=""
                            height="60"
                            className="auth-logo-light"
                          />
                        </Link>
                      </div>
                    </div>
                    <div style={{ marginTop: "5vh" }}>
                      <div>
                        <h3
                          className="text-primary"
                          style={{ fontWeight: 800 }}
                        >
                          Welcome Back!
                        </h3>
                        <p className="text-muted">
                          Sign in to continue to Cluster.
                        </p>
                      </div>

                      <div className="mt-4">
                        <Formik
                          initialValues={{ institutionCode: undefined, isANM: false, userName: undefined, password: undefined }}
                          validate={validate}
                          onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => setSubmitting(false), 30000);
                            handleValidSubmit(values)
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            isValid,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                          }) => (
                            <form
                              onSubmit={handleSubmit}
                              autoComplete="off"
                              noValidate={true}
                              className="form-horizontal"
                            >
                              <ClusterInput
                                focus={true}
                                value={values.institutionCode}
                                defaultValue={values.institutionCode}
                                placeholder={"Enter institution code"}
                                label={"Institution Code"}
                                id={"institutionCode"}
                                name={"institutionCode"}
                                validationError={errors.institutionCode}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              ></ClusterInput>

                              <ClusterInput
                                autoComplete={"username"}
                                validationError={errors.userName}
                                placeholder={"Enter username/email"}
                                label={"Username/Email"}
                                id={"username"}
                                name={"userName"}
                                type="text"
                                required={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.userName}
                                defaultValue={values.userName}
                              ></ClusterInput>

                              <ClusterInput
                                autoComplete={"password"}
                                placeholder={"Enter password"}
                                label={"Password"}
                                id={"password"}
                                name={"password"}

                                type="password"
                                required={true}
                                validationError={errors.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                defaultValue={values.password}
                              >
                                <Link to="/forgot-password" className="text-muted">
                                  <div className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2">
                                    Forgot password?
                                  </div>
                                </Link>
                              </ClusterInput>

                              <ClusterCheckBox
                                onHandleChange={(name, value) =>
                                  setFieldValue("isANM", value)
                                }
                                label={"Log In as ANM/State Rep"}
                                name={"log-in-as-anm"}
                                id={"log-in-as-anm"}
                                checked={values.isANM}
                              ></ClusterCheckBox>

                              <div className="mt-3">
                                <ClusterButton
                                  withLoader={true}
                                  loading={props.loading}
                                  disabled={!isValid}
                                  color={"primary"}
                                  className={"full-width"}
                                >
                                  Log In
                                </ClusterButton>
                              </div>
                            </form>)}
                        </Formik>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Cluster.Africa Crafted with{" "}
                        <i className="mdi mdi-heart text-danger" /> by Appzone
                        Grid
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { error, user } = state.Login
  const { menus } = state.permissions
  return { error, user, loading: state.Login.loading, menus }
}

export default withRouter(
  connect(mapStateToProps, {
    loginUser,
    socialLogin,
    purgeLoginUser,
    getMenu,
    getFunctionsByUser,
  })(withDispose(Login))
)

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  purgeLoginUser: PropTypes.func,
  getMenu: PropTypes.func,
  user: PropTypes.object,
  socialLogin: PropTypes.func,
  menus: PropTypes.array,
  loading: PropTypes.bool,
  getFunctionsByUser: PropTypes.func,
}
