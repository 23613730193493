import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import withDispose from "components/HOCs/WithDispose"
import {
  FormGroup,
} from "reactstrap"
import { GuardedBaseInput } from "./Input";
import withInputValidation from "components/HOCs/WithInputValidation";
import withTags from "components/HOCs/WithTags";
import { Validators } from "helpers/validators";

const InputWithTags = withTags(GuardedBaseInput)
const ValidatedInputWithTags = withInputValidation(InputWithTags);
export const ClusterMultiInput = (props) => {
  const {
    onHandleChange,
    name,
    onChange
  } = props

  const [error, setError] = useState();
  const [value, setValue] = useState(Array.isArray(props.value) ? "" : props.value);
  const [tags, setTags] = useState(Array.isArray(props.value) ? props.value : [])
  const isRequired = useMemo(() => {
    return tags.length < 1;
  }, [tags])

  const handleClearValue = (index) => {
    const updatedValues = [...tags];
    updatedValues.splice(index, 1);
    setTags(updatedValues);
  };

  useEffect(() => {
    if (onHandleChange) onHandleChange(name, tags)
  }, [tags])

  const handleOnChange = (e) => {
    const newValue = e.target.value;
    const values = [...tags];
    setValue(newValue)
    if (newValue.includes(',') && !error) {
      values.push(newValue.slice(0, -1))
      setValue("")
      setTags(values)
    }
    if (onHandleChange) onHandleChange(name, tags)
    if (onChange) onChange(e);
  }
  return (
    <FormGroup>
      <ValidatedInputWithTags
        {...props}
        onChange={handleOnChange}
        tags={tags}
        onClear={handleClearValue}
        value={value}
        onError={(err) => setError(err)}
        required={isRequired}
      />

    </FormGroup>
  )
}
ClusterMultiInput.propTypes = {
  defaultValue: PropTypes.array
}
export default withDispose(ClusterMultiInput)