/* UNSETTLED_TRANSACTIONS */
export const GET_UNSETTLED_TRANSACTIONS:any = "GET_UNSETTLED_TRANSACTIONS"
export const GET_UNSETTLED_TRANSACTIONS_SUCCESS:any = "GET_UNSETTLED_TRANSACTIONS_SUCCESS"
export const GET_UNSETTLED_TRANSACTIONS_FAIL:any = "GET_UNSETTLED_TRANSACTIONS_FAIL"

export const GET_UNSETTLED_TRANSACTION_DETAIL:any = "GET_UNSETTLED_TRANSACTION_DETAIL"
export const GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS:any = "GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS"
export const GET_UNSETTLED_TRANSACTION_DETAIL_FAIL:any = "GET_UNSETTLED_TRANSACTION_DETAIL_FAIL"


export const RESOLVE_UNSETTLED_TRANSACTION:any = "RESOLVE_UNSETTLED_TRANSACTION"
export const RESOLVE_UNSETTLED_TRANSACTION_SUCCESS:any = "RESOLVE_UNSETTLED_TRANSACTION_SUCCESS"
export const RESOLVE_UNSETTLED_TRANSACTION_FAIL:any = "RESOLVE_UNSETTLED_TRANSACTION_FAIL"

export const BATCH_RESOLVE_UNSETTLED_TRANSACTION:any = "BATCH_RESOLVE_UNSETTLED_TRANSACTION"
export const BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS:any = "BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS"
export const BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL:any = "BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL"

export const PROCESS_UPLOAD_UNSETTLED_TRANSACTION:any = "PROCESS_UPLOAD_UNSETTLED_TRANSACTION"
export const PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS:any = "PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS"
export const PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL:any = "PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL"

export const PURGE_UNSETTLED_TRANSACTIONS:any = "PURGE_UNSETTLED_TRANSACTIONS"
export const PURGE_UNSETTLED_TRANSACTIONS_SUCCESS:any = "PURGE_UNSETTLED_TRANSACTIONS_SUCCESS"


export const VALIDATE_UNSETTLED_TRANSACTION:any = "VALIDATE_UNSETTLED_TRANSACTION";
export const VALIDATE_UNSETTLED_TRANSACTION_SUCCESS:any = "VALIDATE_UNSETTLED_TRANSACTION_SUCCESS";
export const VALIDATE_UNSETTLED_TRANSACTION_FAIL:any = "VALIDATE_UNSETTLED_TRANSACTION_FAIL";


export const DOWNLOAD_UNSETTLED_TRANSACTION:any = "DOWNLOAD_UNSETTLED_TRANSACTION";
export const DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS:any = "DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS";
export const DOWNLOAD_UNSETTLED_TRANSACTION_FAIL:any = "DOWNLOAD_UNSETTLED_TRANSACTION_FAIL";


export const DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT:any = "DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT";
export const DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS:any = "DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS";
export const DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL:any = "DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL";