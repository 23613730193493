import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import SummaryCard from "./card"
import withDispose from "components/HOCs/WithDispose"
const SummaryList = props => {
  const { trxType, amount } = props

  return (
    <Row>
      <Col lg={4} md={6} sm={6}>
        <SummaryCard
          title={"TRANSACTION VALUE"}
          trxType={trxType}
          subtitle="7 Days"
          field="trxVolTrend"
          yValueLabel="value"
          currency
          trxStatusFilter
          index={5}
        ></SummaryCard>
      </Col>
      <Col lg={4} md={6} sm={6}>
        <SummaryCard
          title={"TRANSACTION COUNT"}
          trxType={trxType}
          subtitle="7 Days"
          field="trxCountTrend"
          yValueLabel="transactions"
          trxStatusFilter
          index={4}
        ></SummaryCard>
      </Col>
      <Col lg={4} md={6} sm={6}>
        <SummaryCard
          title={"ACTIVE AGENTS"}
          trxType={trxType}
          subtitle="7 Days"
          yValueLabel="agents"
          field="activeAgentsTrend"
          index={3}
        ></SummaryCard>
      </Col>
      <Col lg={4} md={6} sm={6}>
        <SummaryCard
          title={"ACTIVE TERMINALS"}
          trxType={trxType}
          subtitle="7 Days"
          yValueLabel="terminals"
          field="activeTerminalsTrend"
          index={2}
        ></SummaryCard>
      </Col>
      <Col lg={4} md={6} sm={6}>
        <SummaryCard
          title={"COMMISSIONS EARNED"}
          trxType={trxType}
          subtitle="7 Days"
          field="commissionsEarnedTrend"
          yValueLabel="commission"
          currency
          index={1}
        ></SummaryCard>
      </Col>
    </Row>
  )
}

SummaryList.propTypes = {
  amount: PropTypes.number,
  trxType: PropTypes.string,
}
export default withDispose(SummaryList)
