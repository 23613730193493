import { defaultObj } from "helpers/utility"
import {
  GET_CLUSTERMANAGER_DETAIL_SUCCESS,
  GET_CLUSTERMANAGER_DETAIL_FAIL,
  GET_CLUSTERMANAGERS_SUCCESS,
  GET_CLUSTERMANAGERS_FAIL,
  CLUSTERMANAGER_PURGE,
  ENABLE_CLUSTERMANAGER,
  ENABLE_CLUSTERMANAGER_SUCCESS,
  CLUSTERMANAGER_PURGE_SUCCESS,
  GET_CLUSTERMANAGERS,
  POST_CLUSTERMANAGER_SUCCESS,
  UPDATE_CLUSTERMANAGER,
  CREATE_CLUSTERMANAGER,
  UPDATE_CLUSTERMANAGER_SUCCESS,
  POST_CLUSTERMANAGER_FAIL,
  GET_ALL_CLUSTERMANAGERS,
} from "./actionTypes"

const INIT_STATE = {
  clusterManagers: [],
  clusterManagerDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined, //this lets us know we can refresh the page
}

const AgentClusterManagers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLUSTERMANAGERS_SUCCESS:
      return {
        ...state,
        clusterManagers: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CLUSTERMANAGERS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_ALL_CLUSTERMANAGERS:
      return {
        ...state,
        loading: true,
      }
    case GET_CLUSTERMANAGERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CLUSTERMANAGER_DETAIL_SUCCESS:
      return {
        ...state,
        clusterManagerDetail: action.payload?.Data,
        loading: false,
      }

    case GET_CLUSTERMANAGER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_CLUSTERMANAGER_SUCCESS:
      return {
        ...state,
        clusterManager: defaultObj(state.clusterManager), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_CLUSTERMANAGER:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case CREATE_CLUSTERMANAGER:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case POST_CLUSTERMANAGER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_CLUSTERMANAGER_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_CLUSTERMANAGER:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_CLUSTERMANAGER_SUCCESS:
      let detail = state.clusterManagerDetail
      detail.IsActive = action.payload?.enable
      let clusterManagers = state.clusterManagers
      if (detail) {
        clusterManagers.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        clusterManagers,
        editLoading: false,
        loading: false,
        clusterManagerDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case CLUSTERMANAGER_PURGE_SUCCESS:
      return INIT_STATE
    // return {
    //   ...state,
    //   accountEnquiry: {},
    //   clusterManagerDetail:{},
    //   error:{},
    //   success:undefined
    // }

    default:
      return state
  }
  return state
}

export default AgentClusterManagers
