import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD,
} from "./actionTypes"

const initialState = {
  error: "",
  success: undefined,
  loading: false,
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state, loading: true }
      break
    case CHANGE_PASSWORD:
      state = { ...state, loading: true }
      break
    case PROFILE_SUCCESS:
      state = { ...state, loading: true, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, loading: false, error: action.payload }
      break
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, loading: true, error: action.payload }
      break
    case CHANGE_PASSWORD_SUCCESS:
      state = {
        ...state,
        loading: false,
        success:
          action.payload == true
            ? "Password Changed Successfully"
            : "Error changing password",
      }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
