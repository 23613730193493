import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_CASHOUT_INSTITUTIONS,
  GET_CASHOUT_INSTITUTION_DETAIL,
  CASHOUT_INSTITUTION_PURGE,
  ENABLE_CASHOUT_INSTITUTION,
  CREATE_CASHOUT_INSTITUTION,
  UPDATE_CASHOUT_INSTITUTION,
  GET_ALL_CASHOUT_INSTITUTIONS,
} from "./actionTypes"
import {
  getCashOutInstitutionsSuccess,
  getCashOutInstitutionsFail,
  getCashOutInstitutionDetailSuccess,
  getCashOutInstitutionDetailFail,
  cashOutInstitutionPurgeSuccess,
  updateCashOutInstitutionSuccess,
  postCashOutInstitutionSuccess,
  cashOutInstitutionApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCashOutInstitutions,
  getCashOutInstitutionDetail,
  putCashOutInstitution,
  postCashOutInstitution,
  enableCashOutInstitution,
  getAllCashOutInstitutions,
} from "helpers/backend_helper"
const format = "yyyy-MM-DD"
function* fetchCashOutInstitutions({ params }) {
  try {
    if (!params) {
      yield put(getCashOutInstitutionsSuccess([]))
      return
    }
    const response = yield call(getCashOutInstitutions, JSON.parse(params))
    yield put(getCashOutInstitutionsSuccess(response))
  } catch (error) {
    console.debug("Fetching CashOutInstitutions Error", error)
    yield put(getCashOutInstitutionsFail(error))
  }
}
function* fetchAllCashOutInstitutions() {
  try {
    const response = yield call(getAllCashOutInstitutions)
    yield put(getCashOutInstitutionsSuccess(response))
  } catch (error) {
    console.debug("Fetching All CashOutInstitutions Error", error)
    yield put(getCashOutInstitutionsFail(error))
  }
}
function* fetchCashOutInstitutionDetail({ id }) {
  try {
    const response = yield call(getCashOutInstitutionDetail, id)
    yield put(getCashOutInstitutionDetailSuccess(response))
  } catch (error) {
    yield put(getCashOutInstitutionDetailFail(error))
  }
}

function* createCashOutInstitution({ payload: { cashOutInstitution } }) {
  try {
    const response = yield call(postCashOutInstitution, cashOutInstitution)
    // const featureResponse = yield call(batchAssignFeatures, {FeatureIDs:cashOutInstitution.Features,CashOutInstitutionID: CashOutInstitution.ID})
    yield put(postCashOutInstitutionSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      cashOutInstitutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* updateCashOutInstitution({ payload: { cashOutInstitution } }) {
  try {

    if (!cashOutInstitution?.ID) return;
    const response = yield call(putCashOutInstitution, cashOutInstitution)

    yield put(updateCashOutInstitutionSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      cashOutInstitutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* doPurge() {
  yield put(cashOutInstitutionPurgeSuccess())
}

function* categoriesSaga() {
  yield takeLeading(GET_CASHOUT_INSTITUTIONS, fetchCashOutInstitutions)
  yield takeEvery(GET_CASHOUT_INSTITUTION_DETAIL, fetchCashOutInstitutionDetail)
  yield takeEvery(CASHOUT_INSTITUTION_PURGE, doPurge)
  yield takeLeading(UPDATE_CASHOUT_INSTITUTION, updateCashOutInstitution)
  yield takeLeading(CREATE_CASHOUT_INSTITUTION, createCashOutInstitution)
  yield takeLeading(GET_ALL_CASHOUT_INSTITUTIONS, fetchAllCashOutInstitutions)
}

export default categoriesSaga
