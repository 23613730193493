import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import { clusterManagerPurge, createClusterManager, getAgentDetail, getAllRoles, getAllUsers, getClusterManagerDetail, getRoles, getStates, getUserDetail, getUsers, updateClusterManager } from "store/actions"
import ClusterManagerForm from './form';
import ClusterManagerLogic from "../cluster-manager";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { CREATE_CLUSTER_MANAGER, EDIT_CLUSTER_MANAGER } from '../permission'
const CreateAgentCluster = props => {
  const { match,onGetRoles,onGetStates,states,statesLoading,onGetUser,onGetUsers,user,users,roles,roleLoading, onCreateClusterManager,onUpdateClusterManager, onGetClusterManagerDetail, success, editLoading, loading, error, clusterManagers, clusterManagerDetail } = props
  const { ERRORLIST, NOUSER_ERRORLIST } = ClusterManagerLogic;
  const [clusterManagerDetails, setClusterManagerDetails] = useState();
  const [errorList, setErrorList] = useState<any>();
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [clusterManager, setClusterManager] = useState({}); 
  const errorTracker = useMemo(()=>{
    const list= !user?.UserID || user?.UserID==0? NOUSER_ERRORLIST: ERRORLIST
    setErrorList(list);
    return list;
  }, [user?.UserID])
  useEffect(() => {
    getClusterManager()
    onGetRoles()
    
    onGetStates()
  }, [])

  useEffect(() => {
    getClusterManager()
  }, [match])

  const handleOnChange = (key, value) => {
    let u = clusterManager
    u[key] = value
    setClusterManager(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()
    if (isUpdate == false) {
      onCreateClusterManager(clusterManager)
    } else {
      onUpdateClusterManager({ ...clusterManagerDetail, ...clusterManager })
    }
  }

  const getClusterManager = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = clusterManagers.find(c => c.id == id)
      if (!u) {
        if (Object.keys(clusterManagerDetail).length == 0) {
          onGetClusterManagerDetail(id)
        } else {
          setClusterManagerDetails(clusterManagerDetail)
        }
        return
      }
      setClusterManagerDetails(u)
    } else {
      setIsUpdate(false)
    }
  }

  return (
    <GuardedPage permissions={[EDIT_CLUSTER_MANAGER,CREATE_CLUSTER_MANAGER]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Cluster Managers"}
              image={clusterian}
              breadcrumbItem={"Create Cluster Manager"}
            />

            <ClusterManagerForm
              redirectUrl={"/view-cluster-managers"}
              success={success}
              error={error}
              isUpdate={isUpdate}
              loading={loading}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              roles={roles??[]}
              roleLoading={roleLoading}
              details={clusterManagerDetail}
              onGetUser={onGetUser}
              onGetUsers={onGetUsers}
              users={users}
              user={user}
              states={states}
              statesLoading={statesLoading}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
            ></ClusterManagerForm>
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateAgentCluster.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateClusterManager: PropTypes.func,
  onGetClusterManagerDetail: PropTypes.func,
  onBack: PropTypes.func,
  clusterManagerDetail: PropTypes.object,
  clusterManagers: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
  onGetStates: PropTypes.func
}

const mapStateToProps = ({ Users:{users,user}, Roles,clusterManagers: { clusterManagers, error, success, clusterManagerDetail, editLoading, totalCount, loading } ,
  misc: { states, statesLoading },}) => {
  return {
    clusterManagers: [...clusterManagers],
    error,
    success,
    clusterManagerDetail,
    totalCount,
    roles:Roles.roles,
    roleLoading:Roles.loading,
    loading,
    editLoading,
    users,
    user,
    states,
    statesLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateClusterManager: (clusterManager) => dispatch(createClusterManager(clusterManager)),
  onGetClusterManagerDetail: (id) => dispatch(getClusterManagerDetail(id)),
  onUpdateClusterManager:(cluster)=> dispatch(updateClusterManager(cluster)),
  onPurge:()=>dispatch(clusterManagerPurge()),
  onGetRoles:()=>dispatch(getAllRoles()),
  onGetUser:(id)=>dispatch(getUserDetail(id)),
  onGetStates: () => dispatch(getStates()),
  onGetUsers:()=>dispatch(getAllUsers())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateAgentCluster))))
