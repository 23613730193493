import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_TERMINALS,
  GET_TERMINAL_DETAIL,
  ENABLE_TERMINAL,
  CREATE_TERMINAL,
  UPDATE_TERMINAL,
  GET_ALL_TERMINALS,
  GET_ASSIGNED_TERMINALS,
  GET_ASSIGNED_TERMINAL_DETAIL,
  SAVE_ASSIGNED_TERMINAL_CONFIGURATION,
  UPDATE_ASSIGNED_TERMINAL,
  GET_ALL_ASSIGNED_TERMINALS,
  ADD_ASSIGNED_TERMINAL,
  REMOVE_ASSIGNED_TERMINAL,
  PURGE_TERMINALS,
  PROCESS_UPLOAD_TERMINAL,
  BATCH_UPLOAD_TERMINAL,
  TERMINAL_ASSIGNMENT_REPORT,
} from "./actionTypes"
import {
  getTerminalsSuccess,
  getTerminalsFail,
  getTerminalDetailSuccess,
  getTerminalDetailFail,
  purgeTerminalSuccess,
  updateTerminalSuccess,
  postTerminalSuccess,
  terminalApiError,
  getAssignedTerminalsSuccess,
  getAssignedTerminalsFail,
  updateAssignedTerminalSuccess,
  postAssignedTerminalSuccess,
  addAssignedTerminalSuccess,
  removeAssignedTerminalSuccess 
} from "./actions"

//Include Both Helper File with needed methods
import {
  getTerminals,
  getTerminalDetail,
  putTerminal,
  postTerminal,
  enableTerminal,
  disableTerminal,
  getAllTerminals,
  putAssignedTerminal,
  removeAssignedTerminal,
  batchInsertAssignedTerminals,
  getAllAssignedTerminals,
  getAssignedTerminals,
  batchUploadTerminal,
  processUploadTerminal,
  getTerminalAssignmentReport
} from "helpers/backend_helper"
import { batchUploadTerminalSuccess, enableTerminalSuccess, processUploadTerminalSuccess, terminalAssignmentReportFail, terminalAssignmentReportSuccess } from "store/actions"
const format = "yyyy-MM-DD";

function* fetchTerminals({ params }) {
  try {
    const response = yield call(getTerminals, JSON.parse(params))
    // let response = terminals;
    yield put(getTerminalsSuccess(response))
  } catch (error) {
    console.debug("Fetching Terminals Error", error)
    yield put(getTerminalsFail(error))
  }
}
function* fetchAllTerminals() {
  try {
    const response = yield call(getAllTerminals)
    yield put(getTerminalsSuccess(response))
  } catch (error) {
    console.debug("Fetching Terminals Error", error)
    yield put(getTerminalsFail(error))
  }
}

function* fetchTerminalDetail({ id }) {
  try {
    const response = yield call(getTerminalDetail, id);
    // const response = {Message:"Retrieved Successfully", Data: terminals.Data.Collection.find(c=>c.ID==id)}
    yield put(getTerminalDetailSuccess(response))
  } catch (error) {
    yield put(getTerminalDetailFail(error))
  }
}


function* createTerminal({ payload: { terminal } }) {
  try {

    const response = yield call(postTerminal, { ...JSON.parse(JSON.stringify(terminal)) })
    yield put(postTerminalSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(terminalApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* updateTerminal({ payload: { terminal } }) {
  try {

    const response = yield call(putTerminal, { ...JSON.parse(JSON.stringify(terminal)) })

    yield put(updateTerminalSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(terminalApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* processUpload({ payload: { terminal } }) {
  try {

    const response = yield call(processUploadTerminal, { ...JSON.parse(JSON.stringify(terminal)) })

    yield put(processUploadTerminalSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(terminalApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* batchUpload({ payload: { terminal } }) {
  try {

    const response = yield call(batchUploadTerminal, terminal)

    yield put(batchUploadTerminalSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(terminalApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableTerminal(payload) {
  try {
    let toEnable = true;
    let cta = enableTerminal;
    if (!payload.enable) { cta = disableTerminal; toEnable = false }
    const response = yield call(cta, payload.id)

    yield put(enableTerminalSuccess({ ...response, enable: toEnable }))

  } catch (error) {
    console.debug(error)
    yield put(terminalApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchAssignedTerminals({ params }) {
  try {
    const response = yield call(getAssignedTerminals, params)
    yield put(getAssignedTerminalsSuccess(response))
  } catch (error) {
    console.debug("Fetching AssignedTerminals Error", error)
    yield put(getAssignedTerminalsFail(error))
  }
}

function* fetchAssignmentTerminalReports({ params }) {
  try {
    const response = yield call(getTerminalAssignmentReport, JSON.parse(params))
    yield put(terminalAssignmentReportSuccess(response))
  } catch (error) {
    console.debug("Fetching AssignedTerminals Error", error)
    yield put(terminalAssignmentReportFail(error))
  }
}
function* fetchAllAssignedTerminals() {
  try {
    const response = yield call(getAllAssignedTerminals)
    yield put(getAssignedTerminalsSuccess(response))
  } catch (error) {
    console.debug("Fetching All AssignedTerminals Error", error)
    yield put(getAssignedTerminalsFail(error))
  }
}


function* saveAssignedTerminal({ payload: { assignedTerminals } }) {
  try {
    if (assignedTerminals.length == 0) {
      yield put(
        terminalApiError({ Message: "No assignedTerminals to save" })
      )
    } else {
      const response = yield call(batchInsertAssignedTerminals, assignedTerminals)
      yield put(postAssignedTerminalSuccess(response))
    }
  } catch (error) {
    console.debug(error)
    yield put(
      terminalApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function sleep(milliseconds) {
  let start = new Date().getTime()
  for (let i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break
    }
  }
}
function* addAssignedTerminal({ payload }) {
  try {
    sleep(200)
    yield put(addAssignedTerminalSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      terminalApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doRemoveAssignedTerminal({ payload }) {
  try {
    // const response = yield call(removeAssignedTerminal, payload.ID)
    yield put(removeAssignedTerminalSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      terminalApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateAssignedTerminal({ payload: { assignedTerminal } }) {
  try {
    const response = yield call(putAssignedTerminal, assignedTerminal)

    yield put(
      updateAssignedTerminalSuccess({
        ...response,
        Data: assignedTerminal,
      })
    )
    // }
  } catch (error) {
    console.debug(error)
    yield put(
      terminalApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}


function* doPurge() {
  yield put(purgeTerminalSuccess())
}

function* terminalsSaga() {
  yield takeLeading(GET_TERMINALS, fetchTerminals)
  yield takeLeading(TERMINAL_ASSIGNMENT_REPORT,fetchAssignmentTerminalReports)
  yield takeLeading(GET_ALL_TERMINALS, fetchAllTerminals)
  yield takeEvery(GET_TERMINAL_DETAIL, fetchTerminalDetail)
  yield takeEvery(PURGE_TERMINALS, doPurge)
  yield takeLeading(ENABLE_TERMINAL, doEnableTerminal)
  yield takeLeading(UPDATE_TERMINAL, updateTerminal)
  yield takeLeading(CREATE_TERMINAL, createTerminal)
  yield takeLeading(PROCESS_UPLOAD_TERMINAL, processUpload)
  yield takeLeading(BATCH_UPLOAD_TERMINAL, batchUpload)

  yield takeLeading(GET_ASSIGNED_TERMINALS, fetchAssignedTerminals)
  yield takeLeading(GET_ALL_ASSIGNED_TERMINALS, fetchAllAssignedTerminals)

  yield takeLeading(UPDATE_ASSIGNED_TERMINAL, updateAssignedTerminal)
  yield takeLeading(SAVE_ASSIGNED_TERMINAL_CONFIGURATION, saveAssignedTerminal)
  yield takeLeading(ADD_ASSIGNED_TERMINAL, addAssignedTerminal)
  yield takeLeading(REMOVE_ASSIGNED_TERMINAL, doRemoveAssignedTerminal)
}

export default terminalsSaga
