import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Label, Input } from "reactstrap"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import { InputProps } from "types/props/input"

type CheckBoxProps = DisposeProps & InputProps & {
  checked: boolean,
  onError: any
}
const ClusterCheckBox: React.FC<CheckBoxProps> = props => {
  const {
    disabled,
    label,
    name,
    checked,
    id,
    className,
    onHandleChange,
    defaultValue
  } = props
  const [currentValue, setValue] = useState(defaultValue??checked ?? false)

  const handleOnChange = (name, e) => {
    if (onHandleChange) {
      onHandleChange(name, e.target.checked)

    }
    setValue(e.target.checked)
  }

  useEffect(() => {
    if(checked!=undefined) setValue(checked)
  }, [checked])



  return (
    <div className="custom-control custom-checkbox">
      <Input
        type="checkbox"
        className={`custom-control-input ${className}`}
        id={id}
        checked={currentValue}
        disabled={disabled}
        onChange={e => handleOnChange(name, e)}
      />
      <Label className="custom-control-label" for={id}>
        {" "}
        {label}
      </Label>
    </div>
  )
}

ClusterCheckBox.propTypes = {
  defaultValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.any,
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  focus: PropTypes.bool,
  value: PropTypes.any,
  color: PropTypes.any,
  onHandleChange: PropTypes.func,
  onError: PropTypes.func,
}

export default withDispose(ClusterCheckBox)
