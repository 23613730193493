import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from "react"
import { withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label
} from "reactstrap"
import ClusterInput from 'components/Common/Input/Input';
import ClusterButton from 'components/Common/Button/Button';
import ClusterFileUploader from "components/Common/FileUploader";
import ClusterForm from 'components/Common/Form';
import ClusterCheckBox from 'components/Common/CheckBox';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { Formik } from 'formik';
import { Validators } from 'helpers/validators';
const BatchUploadTerminalsForm = props => {
  let { editLoading, handleValidSubmit } = props;


  const initialValues = useMemo(() => {
    return {
      UseFirstLineAsHeader: true,
      TheFileBase64: undefined,
      TheReceiptBase64: undefined,
      ReceiptFileName: undefined,
      FileName: undefined,
      Description: undefined
    }
  }, [])

  const validator = (values) => {

    let errors: any = {}
    const required = Validators.required("Please enter a value");

    const receipt = () =>
      [
        required
      ].forEach(validator => {
        let error = validator(values?.TheReceiptBase64)
        if (error) errors.TheReceipt = error;
      });
    const transactions = () =>
      [
        required
      ].forEach(validator => {
        let error = validator(values?.TheFileBase64)
        if (error) errors.TheFile = error;
      });
    const description = () =>
      [
        required, Validators.minLength(
          3,
          "Minimum number of characters is 3"
        )
      ].forEach(validator => {
        let error = validator(values?.Description)
        if (error) errors.Description = error;
      });



    const validate = () => {
      try {
        description();
        transactions();
        receipt();
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();

  }
  return <Formik
    initialValues={initialValues}
    onSubmit={(values, { setSubmitting }) => {

      handleValidSubmit({ ...values })

    }}
    validate={validator}
  >
    {({
      values,
      touched,
      isValid,
      errors,
      setFieldError,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      isSubmitting,
    }) => (
      <ClusterForm handleSubmit={handleSubmit}>

        <React.Fragment>


          <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
            <CardBody>
              <Row className='mb-4'>
                <Col lg={12} md={12}>
                  <ClusterInput
                    focus
                    label={"Description"}
                    className="form-control"
                    defaultValue={values?.Description ?? ""}
                    id={"description"}
                    name={"Description"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.Description}
                    validationError={errors?.Description}
                  />

                </Col>
              </Row>
              <Row className="mb-1">

                <Col lg={12} md={12} sm={12}>
                  <ClusterFileUploader label="Transactions" id="file-upload" name="TheFileBase64" accept={[".csv"]} onHandleChange={(value) => {
                    setFieldValue("TheFileBase64", value.Base64);
                    setFieldValue("FileName", value.FileName);
                  }} />
                </Col>
              </Row>
              <Row className="mb-1">

                <Col lg={12} md={12} sm={12}>
                  <ClusterFileUploader label="Receipt" id="file-upload" name="TheReceiptBase64" accept={[".zip"]} onHandleChange={(value) => {

                    setFieldValue("TheReceiptBase64", value.Base64);
                    setFieldValue("ReceiptFileName", value.FileName);
                  }} />
                </Col>
              </Row>

              <Row className="mb-4">
                <Col lg={12} md={12} sm={12}>
                  <ClusterCheckBox
                    checked={true}
                    onHandleChange={(name, value) => setFieldValue("UseFirstLineAsHeader", value)}
                    label={"Use First Line as Header"}
                    name={"UseFirstLineAsHeader"}
                    id={"useFirstLineAsHeader"}
                  />
                </Col>
              </Row>

            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton loading={editLoading} disabled={!isValid} color={"primary"} addon={"bx bx-save"}>{"Validate"}</ClusterButton>
          </Col>
        </Row>

      </ClusterForm>
    )}
  </Formik>

}
BatchUploadTerminalsForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(BatchUploadTerminalsForm)))