import {
  GET_REPORTS,
  GET_RECEIPTS,
  GET_RECEIPTS_FAIL,
  GET_RECEIPTS_SUCCESS,
  GET_RECEIPT_DETAIL,
  GET_RECEIPT_DETAIL_FAIL,
  GET_RECEIPT_DETAIL_SUCCESS,
  GET_REPORTS_SUCCESS,
  GET_REPORTS_FAIL,
  GET_CASHOUTREPORTS,
  GET_CASHOUTREPORTS_SUCCESS,
  GET_CASHOUTREPORTS_FAIL,
  GET_FUNDSTRANSFERREPORTS,
  GET_FUNDSTRANSFERREPORTS_SUCCESS,
  GET_FUNDSTRANSFERREPORTS_FAIL,
  GET_SUMMARYREPORTS,
  GET_SUMMARYREPORTS_SUCCESS,
  GET_SUMMARYREPORTS_FAIL,
  GET_COMPARATIVESREPORTS,
  GET_COMPARATIVESREPORTS_SUCCESS,
  GET_COMPARATIVESREPORTS_FAIL,
  GET_TRANSACTION_REPORTS,
  GET_TRANSACTION_REPORTS_SUCCESS,
  GET_TRANSACTION_REPORTS_FAIL,
  GET_COLLECTION_REPORTS,
  GET_COLLECTION_REPORTS_SUCCESS,
  GET_COLLECTION_REPORTS_FAIL,
  REPORT_PURGE,
  REPORT_PURGE_SUCCESS,
  GET_DOWNLOAD_REPORTS,
  GET_DOWNLOAD_REPORTS_SUCCESS,
  GET_DOWNLOAD_REPORTS_FAIL,
  LOG_DOWNLOAD,
  LOG_DOWNLOAD_SUCCESS,
  LOG_DOWNLOAD_FAIL,
  DOWNLOAD_REPORT,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_FAIL,
  GET_PAYWITHTRANSFER_REPORTS_SUCCESS,
  GET_PAYWITHTRANSFER_REPORTS,
  GET_PAYWITHTRANSFER_REPORTS_FAIL,
  GET_CARDTRANSFER_REPORTS_SUCCESS,
  GET_CARDTRANSFER_REPORTS,
  GET_CARDTRANSFER_REPORTS_FAIL,
} from "./actionTypes"

export const getReports = (query, variables, deps, field, trxType) => ({
  type: GET_REPORTS,
  query,
  variables,
  deps,
  field,
  trxType,
})
export const getReportsSuccess = reports => ({
  type: GET_REPORTS_SUCCESS,
  payload: reports,
})
export const getReportsFail = error => ({
  type: GET_REPORTS_FAIL,
  payload: error,
})
export const getReceipts = params => ({
  type: GET_RECEIPTS,
  params,
})
export const reportPurge = () => ({
  type: REPORT_PURGE,
})
export const reportPurgeSuccess = () => ({
  type: REPORT_PURGE_SUCCESS,
})

export const getReceiptsSuccess = payload => ({
  type: GET_RECEIPTS_SUCCESS,
  payload,
})

export const getReceiptsFail = error => ({
  type: GET_RECEIPTS_FAIL,
  payload: error,
})

export const getRecieptDetail = invoiceId => ({
  type: GET_RECEIPT_DETAIL,
  invoiceId,
})

export const getRecieptDetailSuccess = receipts => ({
  type: GET_RECEIPT_DETAIL_SUCCESS,
  payload: receipts,
})

export const getRecieptDetailFail = error => ({
  type: GET_RECEIPT_DETAIL_FAIL,
  payload: error,
})

export const getCashOutReports = params => ({
  type: GET_CASHOUTREPORTS,
  params,
})

export const getCashOutReportsSuccess = transactions => ({
  type: GET_CASHOUTREPORTS_SUCCESS,
  payload: transactions,
})

export const getCashOutReportsFail = error => ({
  type: GET_CASHOUTREPORTS_FAIL,
  payload: error,
})
export const getFundsTransferReports = params => ({
  type: GET_FUNDSTRANSFERREPORTS,
  params,
})

export const getFundsTransferReportsSuccess = transactions => ({
  type: GET_FUNDSTRANSFERREPORTS_SUCCESS,
  payload: transactions,
})

export const getFundsTransferReportsFail = error => ({
  type: GET_FUNDSTRANSFERREPORTS_FAIL,
  payload: error,
})

export const getSummaryReports = params => ({
  type: GET_SUMMARYREPORTS,
  params,
})

export const getSummaryReportsSuccess = transactions => ({
  type: GET_SUMMARYREPORTS_SUCCESS,
  payload: transactions,
})

export const getSummaryReportsFail = error => ({
  type: GET_SUMMARYREPORTS_FAIL,
  payload: error,
})


export const getComparativesReports = params => ({
  type: GET_COMPARATIVESREPORTS,
  params,
})

export const getComparativesReportsSuccess = transactions => ({
  type: GET_COMPARATIVESREPORTS_SUCCESS,
  payload: transactions,
})

export const getComparativesReportsFail = error => ({
  type: GET_COMPARATIVESREPORTS_FAIL,
  payload: error,
})

export const getTransactionReports = params => ({
  type: GET_TRANSACTION_REPORTS,
  params,
})

export const getTransactionReportsSuccess = transactions => ({
  type: GET_TRANSACTION_REPORTS_SUCCESS,
  payload: transactions,
})

export const getTransactionReportsFail = error => ({
  type: GET_TRANSACTION_REPORTS_FAIL,
  payload: error,
})


export const getPayWithTransferReports = params => ({
  type: GET_PAYWITHTRANSFER_REPORTS,
  params,
})

export const getPayWithTransferReportsSuccess = transactions => ({
  type: GET_PAYWITHTRANSFER_REPORTS_SUCCESS,
  payload: transactions,
})

export const getPayWithTransferReportsFail = error => ({
  type: GET_PAYWITHTRANSFER_REPORTS_FAIL,
  payload: error,
})

export const getCardTransferReports = params => ({
  type: GET_CARDTRANSFER_REPORTS,
  params,
})

export const getCardTransferReportsSuccess = transactions => ({
  type: GET_CARDTRANSFER_REPORTS_SUCCESS,
  payload: transactions,
})

export const getCardTransferReportsFail = error => ({
  type: GET_CARDTRANSFER_REPORTS_FAIL,
  payload: error,
})

export const getCollectionReports = params => ({
  type: GET_COLLECTION_REPORTS,
  params,
})

export const getCollectionReportsSuccess = collections => ({
  type: GET_COLLECTION_REPORTS_SUCCESS,
  payload: collections,
})

export const getCollectionReportsFail = error => ({
  type: GET_COLLECTION_REPORTS_FAIL,
  payload: error,
})

export const getDownloadReports = params => ({
  type: GET_DOWNLOAD_REPORTS,
  params,
})

export const getDownloadReportsSuccess = transactions => ({
  type: GET_DOWNLOAD_REPORTS_SUCCESS,
  payload: transactions,
})

export const getDownloadReportsFail = error => ({
  type: GET_DOWNLOAD_REPORTS_FAIL,
  payload: error,
})

export const logForDownload = details => ({
  type: LOG_DOWNLOAD,
  payload: { details },
})

export const logForDownloadSuccess = payload => ({
  type: LOG_DOWNLOAD_SUCCESS,
  payload: payload,
})

export const logForDownloadFail = error => ({
  type: LOG_DOWNLOAD_FAIL,
  payload: error,
})

export const downloadReport = id => ({
  type: DOWNLOAD_REPORT,
  payload: { id },
})

export const downloadReportSuccess = payload => ({
  type: DOWNLOAD_REPORT_SUCCESS,
  payload: payload,
})

export const downloadReportFail = error => ({
  type: DOWNLOAD_REPORT_FAIL,
  payload: error,
})
