import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import ClusterCheckBox from "components/Common/CheckBox"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import { Icons } from "enums/icons"
import { InputProps } from "types/props/input"

type ToggleProps = DisposeProps & InputProps & {
  checked: boolean,
  icon?: Icons
}
const ClusterToggle: React.FC<ToggleProps> = props => {
  let { label, name, id, value, checked, defaultValue, disabled, icon, onChange } = props
  const [isChecked, setChecked] = useState(defaultValue ?? checked);

  const toggle = (name, checked) => {
    setChecked(checked);
    onChange(name, value);
  }

  useEffect(() => {
    setChecked(checked)
  }, [checked])


  const [alignmentStyle] = useState({ display: "flex", justifyContent: "left", alignItems: "center", cursor: "pointer" })
  return <Row
    className={`function-item ${isChecked ? "text-primary" : "text-dark"
      }`}
    style={alignmentStyle}
  >
    <ClusterCheckBox
      label={""}
      onHandleChange={toggle}
      name={name}
      checked={isChecked}
      id={`customcheck_${name?.toString().replace(" ", "_")}`}

      disabled={disabled}
    />
    <div className="ml-3" style={alignmentStyle}
    >
      {icon ?

        <i
          className={`bx ${icon} bx-xs mr-2`}
        />

        : null}

      <p className={`text-truncate font-size-14 m-0 ${isChecked ? "text-primary" : "text-dark"}`}>

        {label}
      </p>
    </div>
  </Row>

}

ClusterToggle.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.any,
  checked: PropTypes.bool,
  icon: PropTypes.any,
  name: PropTypes.any
}

export default withDispose(ClusterToggle)
