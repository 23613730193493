import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//i18n
import { withTranslation } from "react-i18next"
import { reportPurge, getCashOutReports, getFundsTransferReports } from "store/actions"
import {
  GET_ALL_CASHOUTREPORT,
  GET_ALL_CLUSTER_MANAGERS,
  GET_ALL_FUNDSTRANSFERREPORT,
  GET_CASHOUTREPORT,
} from "helpers/url_helper"
import FundsTransferLogic from "./fundtransfer"
import withDispose from "components/HOCs/WithDispose"
import "react-toastify/dist/ReactToastify.css"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"
import useSWR from "swr"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useInstitutionsData } from "hooks/useInstitutionData"
const FundsTransferList = props => {
  const {
    fundsTransferTransactions,
    onGetTransactions,
    error,
    onPurge,
    totalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions,
  } = FundsTransferLogic
  const { institutionsData, institutions } = useInstitutionsData()
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
    } = useReportData({ onGetData: onGetTransactions, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period: 1, periodicity: Periodicity.Day })

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )

  useEffect(() => {
    setSearchOptions(
      SearchOptions(params?.fromDate, params?.toDate, institutionsData)
    )
  }, [institutions])

  useEffect(() => {
    populateRows()
  }, [fundsTransferTransactions])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const populateRows = () => {
    let rws = []
    if (fundsTransferTransactions) {
      fundsTransferTransactions.forEach(transaction => {
        let row = MapToRows(transaction)

        rws.push(row)
      })
    }
    setRows(rws)
  }

  return (
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_FUNDSTRANSFERREPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
  )
}

FundsTransferList.propTypes = {
  fundsTransferTransactions: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetTransactions: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { fundsTransferTransactions, error, success, fundsTransferTotalCount, loading },
}) => {
  return {
    fundsTransferTransactions,
    error,
    success,
    totalCount: fundsTransferTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetTransactions: query => dispatch(getFundsTransferReports(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(FundsTransferList))))
