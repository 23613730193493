export const GET_BRANCHES = "GET_BRANCHES"
export const CREATE_BRANCH = "CREATE_BRANCH"
export const GET_BRANCH_SUCCESS = "GET_BRANCH_SUCCESS"
export const POST_BRANCH_SUCCESS = "POST_BRANCH_SUCCESS"
export const API_ERROR = "BRANCHES_API_ERROR"
export const GET_BRANCH_DETAILS = "GET_BRANCH_DETAILS"
export const GET_BRANCH_DETAILS_SUCCESS = "GET_BRANCH_DETAILS_SUCCESS"
export const GET_BRANCH_DETAILS_FAIL = "GET_BRANCH_DETAILS_FAIL"

export const UPDATE_BRANCH = "UPDATE_BRANCH"
export const UPDATE_BRANCH_SUCCESS = "UPDATE_BRANCH_SUCCESS"
export const UPDATE_BRANCH_FAIL = "UPDATE_BRANCH_FAIL"

export const PURGE_BRANCHES = "PURGE_BRANCHES"
export const PURGE_BRANCHES_SUCCESS = "PURGE_BRANCHES_SUCCESS"

export const ENABLE_BRANCH = "ENABLE_BRANCH"
export const ENABLE_BRANCH_SUCCESS = "ENABLE_BRANCH_SUCCESS"
export const ENABLE_BRANCH_FAIL = "ENABLE_BRANCH_FAIL"
