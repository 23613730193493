

import React, { useState, useEffect } from "react"

import { Col, Collapse, FormGroup, Label, Row } from "reactstrap"
import { Utils } from "helpers/utility"
import ClusterInput from "components/Common/Input/Input"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterCheckBox from "components/Common/CheckBox"
import { ConfigurationFormProps } from "."
import { BillsPaymentProvider } from "enums/billspayment-provider"


const BillPaymentForm: React.FC<ConfigurationFormProps> = props => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
    } = props
    const [provider, setProvider] = useState<any>(BillsPaymentProvider.ITEX);
    const [useDefaultITEXCredentials, setUseDefaultITEXCredentials] = useState(true);
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [transform, setTransform] = useState<string>();
    const [details, setDetails] = useState(values);

    useEffect(() => {
        setDetails(values)
        if (!Utils.Object.isEmpty(values)) {
            setUseDefaultITEXCredentials(values?.ITEX?.UseDefault)
        }
    }, [values])
    return <React.Fragment>
        <div
            className={`title p-2 accordion-header box-shadow ${isOpen ? "active" : ""}`}
            style={{ borderRadius: "5px" }}
            onClick={() => {
                setIsOpen(!isOpen)

                if (!isOpen) {
                    setTransform("rotateZ(90deg)")
                } else {
                    setTransform("rotateZ(0deg)")
                }
            }}
        ><Row>
                <Col lg={11} md={11} xs={11}>
                    <h3>Bills Payment</h3>
                </Col>
                <Col
                    lg={1} md={1} xs={1}
                    className="pl-3"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <i className={`bx bxs-right-arrow text-secondary`} style={{ transform: transform }} />

                </Col>
            </Row>
        </div>
        <Collapse className="p-4" isOpen={isOpen}>

            <Row>
                <Col lg={12} md={12} sm={12}>


                    <Row>

                        {Utils.Enums.toOptions(BillsPaymentProvider).length > 1 ? <Col lg={12} md={12} sm={12}>
                            <FormGroup>
                                <Label>Providers</Label>

                                <ClusterDropdown
                                    placeholder={"-Select a Provider-"}
                                    items={Utils.Enums.toOptions(BillsPaymentProvider)}
                                    name={"Provider"}
                                    onChange={(name, value) => {
                                        handleChange("Provider", BillsPaymentProvider[value]);
                                        setProvider(value);
                                    }}
                                ></ClusterDropdown>

                            </FormGroup>
                        </Col> :
                            <Col lg={3} md={3} sm={6} className={"mb-3"}>
                                <ClusterCheckBox
                                    checked={details?.ITEX?.UseDefault ?? useDefaultITEXCredentials}
                                    onHandleChange={(name, value) => { setUseDefaultITEXCredentials(value); handleChange("Provider", BillsPaymentProvider[BillsPaymentProvider.ITEX]); handleChange("ITEX.UseDefault", value); setProvider(BillsPaymentProvider.ITEX); }}
                                    label={"Use Default ITEX Wallet"}
                                    name={"Provider"}
                                    id={"provider"}
                                ></ClusterCheckBox>
                            </Col>
                        }
                    </Row>
                    {Utils.Enums.compare(BillsPaymentProvider, provider, BillsPaymentProvider.ITEX) && !useDefaultITEXCredentials ?
                        <React.Fragment>
                            <Row>

                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.Wallet}
                                        value={details?.ITEX?.Wallet}
                                        label={"ITEX Wallet"}
                                        id={"itexWallet"}
                                        name={"ITEX.Wallet"}
                                        type="text"
                                        required={true}
                                        validationError={errors?.ITEX?.Wallet}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>

                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.Identifier}
                                        value={details?.ITEX?.Identifier}
                                        label={"ITEX Identifier"}
                                        id={"itexIdentifier"}
                                        name={"ITEX.Identifier"}
                                        type="text"
                                        required={true}
                                        validationError={errors?.ITEX?.Identifier}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>

                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.Pin}
                                        value={details?.ITEX?.Pin}
                                        label={"ITEX Pin"}
                                        id={"itexPin"}
                                        name={"ITEX.Pin"}
                                        type="password"
                                        required={true}
                                        validationError={errors?.ITEX?.Pin}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>
                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.OrganisationCode}
                                        value={details?.ITEX?.OrganisationCode}
                                        label={"ITEX OrganisationCode"}
                                        id={"itexOrganisationCode"}
                                        name={"ITEX.OrganisationCode"}
                                        type="text"
                                        required={true}
                                        validationError={errors?.ITEX?.OrganisationCode}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>

                            </Row>

                            <Row>
                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.Username}
                                        value={details?.ITEX?.Username}
                                        label={"ITEX Username"}
                                        id={"itexUsername"}
                                        name={"ITEX.Username"}
                                        type="text"
                                        required={true}
                                        validationError={errors?.ITEX?.Username}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>
                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.APIKey}
                                        value={details?.ITEX?.APIKey}
                                        label={"ITEX APIKey"}
                                        id={"itexAPIKey"}
                                        name={"ITEX.APIKey"}
                                        type="password"
                                        required={true}
                                        validationError={errors?.ITEX?.APIKey}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>

                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.ITEX?.Password}
                                        value={details?.ITEX?.Password}
                                        label={"ITEX Password"}
                                        id={"itexPassword"}
                                        name={"ITEX.Password"}
                                        type="password"
                                        required={true}
                                        validationError={errors?.ITEX?.Password}
                                        onBlur={handleBlur}
                                        onHandleChange={handleChange}
                                    ></ClusterInput>
                                </Col>



                            </Row>
                        </React.Fragment>
                        : null}


                </Col>

            </Row>


        </Collapse>
    </React.Fragment>



}

export default BillPaymentForm