import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import AccordionHeader from "./header";
import AccordionBody from "./body";
import { AccordionOption } from "types/accordion-option";
import Loading from "components/Common/Loading";
import { Utils } from "helpers/utility";
import { UserType } from "enums/user-type";

type AccordionToggleProps = DisposeProps & {
  parent: AccordionOption,
  onChange: (groupName, itemName, itemValue, isChecked: boolean) => void
  children: AccordionOption[],
  loading: boolean,
  collapsed: boolean,
  watch?: any[]
}


const AccordionToggle: React.FC<AccordionToggleProps> = props => {
  const { parent, children, collapsed, watch, loading = false, onChange } = props
  const [header, setParent] = useState(parent);
  const [items, setChildren] = useState(children ?? []);
  const [open, setOpen] = useState(!collapsed ?? false);

  useEffect(() => {
    watchFn();
  }, [watch, children])

  useEffect(() => {
    if (children) {
      setChildren(children)
      watchFn();
    }
  }, [loading])

  const watchFn = () => {
    if (watch) {
      const affectedItems = Utils.List.isEmpty(items) ? children : items;
      setChildren(affectedItems.filter(f => !f.watch || watch.some(w => {
        return Utils.Enums.compare(UserType, w, f.watch)
      })));

    }
  }
  const alignChildrenWithParent = (children: AccordionOption[], parent: AccordionOption): AccordionOption[] => {
    const hasChildren = children && children.length > 0;
    const changedChildren = [];
    if (hasChildren) {
      children = children.map(child => {
        if (child.checked != parent.checked) {
          child.checked = parent.checked;
          onChange(parent.name, child.name, child.value, child.checked);
        }
        return child;
      })
    }
    return children;
  }
  const alignParentWithChildren = (parent: AccordionOption, children: AccordionOption[]): AccordionOption => {
    const shouldCheckParent = children.every(child => child.checked == true)
    parent.checked = shouldCheckParent;
    return parent;
  }
  const onSelect = (name, value) => {
    let parentItem = Object.assign({}, header);
    let childItems = Object.assign([], items);
    const isAParent = name == parentItem.name;
    const hasChildren = childItems && childItems.length > 0;
    if (isAParent) {
      parentItem.checked = (!parentItem.checked);
      childItems = alignChildrenWithParent(childItems, parentItem);
    }
    else {
      if (hasChildren) {
        childItems = childItems.map(child => {
          if (child.name == name) {
            child.checked = (!child.checked);
            onChange(parentItem.name, child.name, child.value, child.checked);
          }
          return child;
        })
        parentItem = alignParentWithChildren(parentItem, childItems);
      }
    }

    onChange(parentItem.name, parentItem.name, parentItem.value, parentItem.checked)
    setChildren(childItems)
    setParent(parentItem)
  }


  if (loading) {
    return <Loading />
  }
  return <div>
    <AccordionHeader
      id={header.id}
      checked={header.checked}
      value={header.value}
      disabled={header.disabled}
      label={header.label}
      isOpen={open}
      name={header.name}
      onCollapse={setOpen}
      onChange={onSelect}
      icon={header.icon}
    />

    <AccordionBody
      items={items}
      isOpen={open}
      onChange={onSelect}
    />
  </div>
}

AccordionToggle.propTypes = {
  parent: PropTypes.any,
  children: PropTypes.array,
  onChange: PropTypes.func
}

export default withDispose(AccordionToggle)




type Toggle = {
  totalItems: any[]
  selectedItems: any[]
  select: (value: any, enforceCheck?: boolean) => any
}
export const Toggler = (selectedItems: any[], totalItems: any[], comparativeKey?: string): Toggle => {
  return {
    selectedItems,
    totalItems,
    select: (value: any, enforceCheck?: boolean): any[] => {
      let items = Object.assign([], selectedItems);
      const itemInList: any[] = items.find(item => comparativeKey ? item[comparativeKey] == value : item == value);

      if ((enforceCheck === true && !Utils.Object.isEmpty(itemInList)) || (enforceCheck === false && Utils.Object.isEmpty(itemInList))) return selectedItems;

      if (enforceCheck === true) {
        items.push(comparativeKey ? totalItems.find(item => item[comparativeKey] == value) : value)
        return items;
      } else if (enforceCheck === false) {
        items = items.filter(item => comparativeKey ? item[comparativeKey] != value : item != value);
        return items;
      }


      if (Utils.Object.isEmpty(itemInList)) {
        items.push(comparativeKey ? totalItems.find(item => item[comparativeKey] == value) : value);
        return items;
      }

      items = items.filter(item => comparativeKey ? item[comparativeKey] != value : item != value);
      return items;

    }
  };
}
