import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Row, Col, Alert } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"
import TerminalLogic from "../../batch-upload"
import ClusterButton from "components/Common/Button/Button"
import { processUploadTerminal, purgeTerminals } from "store/actions"
import { toast } from "react-toastify"
import useReportData from "hooks/UseReportData"


const ProcessBatchUploadList = props => {
  const {
    terminals,
    summaryText,
    onProcessUpload,
    setErrorList,
    TheFileBase64,
    InstitutionID,
    UseFirstLineAsHeader,
    isValidList,
    error,
    success,
    saved,
    onPurge,
    totalCount,
    editLoading,
    loading,
  } = props

  const { HEADERS, MapToModalState,ERRORLIST, MapToRows, SearchCriteria, SearchOptions } = TerminalLogic;
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [searchOptions, setSearchOptions] = useState(SearchOptions())
  const [viewedCluster, setViewedManager] = useState()
  const [isEnabled, setIsEnabled] = useState(false)
  useEffect(() => {
    if (success) {
      toast.success(success)
      setErrorList(ERRORLIST)
    }
    if (error) toast.error(error)
  }, [error, success])

  useEffect(() => {
    populateRows()
  }, [terminals])

  useEffect(() => {
    if (saved) onPurge()
  }, [saved])

  const populateRows = () => {
    if (terminals) {
      let rws = []
      terminals.forEach(cluster => {
        let row = MapToRows(cluster)
        rws.push(row)
      })

      setRows(rws)
    }
  }

  const processUpload = e => {
    e.preventDefault()
    onProcessUpload({
      Terminals: terminals,
      TheFileBase64,
      UseFirstLineAsHeader,
      InstitutionID,
    })
  }
  const revalidate = e => {
    e.preventDefault()
    onPurge()
  }

  return (
    <React.Fragment>
      <Row>
        <Alert color={isValidList ? "success" : "danger"}>
          {isValidList
            ? "All entries where successfully validated, please proceed to upload"
            : `${summaryText}: Please review the erratic entries and revalidate`}
        </Alert>
      </Row>
      <Row>
        <Col lg="12">
          <Report
            tableData={{
              columns: columns,
              rows: rows,
              paginationOptions: {
                totalCount: totalCount,
                onPageChange: page => handlePageChange(page),
                pageNumber: pageNumber,
                maxSize: maxSize,
              },
              pageTitle: "Upload Validated Batch",
              isLoading: loading,
            }}
          />
        </Col>
      </Row>
      <ClusterButton
        className={"btn-lg"}
        buttonStyle={{
          width: "100%",
          marginTop: "10px",
          justifyContent: "center",
          height: "4em",
        }}
        color={isValidList ? "success" : "danger"}
        loading={editLoading}
        onHandleClick={isValidList ? processUpload : revalidate}
      >
        {isValidList ? "Upload" : "ReValidate"}
      </ClusterButton>
    </React.Fragment>
  )
}

ProcessBatchUploadList.propTypes = {
  terminals: PropTypes.array,
  clusterDetail: PropTypes.object,
  onGetClusters: PropTypes.func,
  onGetClusterDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  terminals: { editLoading, success, error, saved },
}) => {
  // console.debug(terminals, error, clusterDetail, totalCount, loading)
  return {
    editLoading,
    success,
    error,
    saved,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(purgeTerminals()),
  onProcessUpload: payload => dispatch(processUploadTerminal(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(ProcessBatchUploadList))))
