import { defaultObj } from "helpers/utility"
import {
  GET_CHARGEBACK_DETAIL_SUCCESS,
  GET_CHARGEBACK_DETAIL_FAIL,
  GET_CHARGEBACKS_SUCCESS,
  GET_CHARGEBACKS_FAIL,
  CHARGEBACK_PURGE,
  ENABLE_CHARGEBACK,
  ENABLE_CHARGEBACK_SUCCESS,
  CHARGEBACK_PURGE_SUCCESS,
  GET_CHARGEBACKS,
  POST_CHARGEBACK_SUCCESS,
  UPDATE_CHARGEBACK,
  CREATE_CHARGEBACK,
  UPDATE_CHARGEBACK_SUCCESS,
  POST_CHARGEBACK_FAIL,
  GET_ALL_CHARGEBACKS,
  UPDATE_CHARGEBACK_FAIL,
  REGENERATE_CHARGEBACK_TOKEN,
  REGENERATE_CHARGEBACK_TOKEN_SUCCESS,
  REGENERATE_CHARGEBACK_TOKEN_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  claims: [],
  claimDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  reverseLoading: false,
  approvedLoading: false,
  pendingLoading:false,
  tokenLoading:false,
  statusChanged: undefined,
}

const ChargeBacks = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CHARGEBACKS_SUCCESS:
      return {
        ...state,
        claims: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CHARGEBACKS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_CHARGEBACKS:
      return {
        ...state,
        loading: true,
      }
    case GET_CHARGEBACKS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CHARGEBACK_DETAIL_SUCCESS:
      return {
        ...state,
        claimDetail: action.payload?.Data,
        loading: false,
      }

    case GET_CHARGEBACK_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CHARGEBACK_PURGE_SUCCESS:
      return INIT_STATE
    case UPDATE_CHARGEBACK:
      console.debug(action.payload)
      state = {
        ...state,
        reverseLoading: action.payload.status == "ResolvedReversed",
        approvedLoading: action.payload.status == "ResolvedApproved",
        pendingLoading:action.payload.status == "Pending",
        error: "",
        success: "",
      }
      break
    case UPDATE_CHARGEBACK_SUCCESS:
      let detail = state.claimDetail
      detail.DisputeStatus = action.payload?.status
      return {
        ...state,
        editLoading: false,
        loading: false,
        reverseLoading: false,
        approvedLoading: false,
        tokenLoading:false,
        pendingLoading:false,
        claimDetail: detail,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    case UPDATE_CHARGEBACK_FAIL:
      return {
        ...state,
        error: action.payload?.Message??action.payload,
        editLoading: false,
        loading: false,
        reverseLoading: false,
        approvedLoading: false,
        pendingLoading:false,
        tokenLoading:false
      }

      case REGENERATE_CHARGEBACK_TOKEN:
        state = {
          ...state,
          reverseLoading: false,
          approvedLoading: false,
          pendingLoading:false,
          tokenLoading:true,
          error: undefined,
          success: undefined,
        }
        break
      case REGENERATE_CHARGEBACK_TOKEN_SUCCESS:
       
        return {
          ...state,
          editLoading: false,
          loading: false,
          reverseLoading: false,
          approvedLoading: false,
          tokenLoading:false,
          pendingLoading:false,
          error: "",
          statusChanged: true,
          success: action.payload?.Message,
        }
      case REGENERATE_CHARGEBACK_TOKEN_FAIL:
        return {
          ...state,
          error: action.payload?.Message??action.payload,
          editLoading: false,
          loading: false,
          reverseLoading: false,
          approvedLoading: false,
          pendingLoading:false,
          tokenLoading:false
        }
    default:
      return state
  }
  return state
}

export default ChargeBacks
