import React, { } from "react"

import { Row, Col, Card, CardBody, CardTitle, FormGroup, Label } from "reactstrap"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"
import { Institution, InstitutionSuperAdmin } from "../../institution"
import { Formik } from "formik"
import { PagedFormProps } from "components/Common/Form/PagedForm"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { Utils } from "helpers/utility"
import { Gender } from "enums/gender"
import ClusterTextArea from "components/Common/TextArea";
import Loading from "components/Common/Loading"
type InstitutionSuperAdminDetailsFormProps = PagedFormProps<InstitutionSuperAdmin> & {
  institution: Institution
  details: Institution
  states: any[],
  statesLoading: boolean
}
const InstitutionSuperAdminForm: React.FC<InstitutionSuperAdminDetailsFormProps> = props => {
  const {
    states,
    statesLoading,
    isUpdate,
    institution,
    onNext,
    onPrev
  } = props

  const Initiate = (details: Institution): InstitutionSuperAdmin => {
    return {
      LastName: details?.User?.LastName,
      FirstName: details?.User?.FirstName,
      Email: details?.User?.Email,
      Username: details?.User?.Username,
      OtherName: details?.User?.OtherName,
      Gender: details?.User?.Gender,
      PhoneNumber: details?.User?.PhoneNumber,
      Street: details?.User?.Street,
      City: details?.User?.City,
      State: details?.User?.State
    };
  }

  const validator = (values: InstitutionSuperAdmin): any => {
    const errors: any = {}
    const required = Validators.required("Please enter a value");
    const lastName = () => [
      required,
      Validators.minLength(
        3,
        "Minimum number of characters is 3"
      ),
    ].forEach(validator => {
      let error = validator(values?.LastName)
      if (error) errors.LastName = error;
    });

    const firstName = () => [
      required,
      Validators.minLength(
        3,
        "Minimum number of characters is 3"
      ),
    ].forEach(validator => {
      let error = validator(values?.FirstName)
      if (error) errors.FirstName = error;
    });

    const userName = () => [
      required,
      Validators.minLength(
        6,
        "Minimum number of characters is 6"
      ),
    ].forEach(validator => {
      let error = validator(values?.Username)
      if (error) errors.Username = error;
    });

    const gender = () => [
      required

    ].forEach(validator => {
      let error = validator(values?.Gender)
      if (error) errors.Gender = error;
    });

    const state = () => [
      required

    ].forEach(validator => {
      let error = validator(values?.State)
      if (error) errors.State = error;
    });
    const email = () => [
      required,
      Validators.email("Enter a valid email e.g abc@xyz.com")
    ].forEach(validator => {
      let error = validator(values?.Email)
      if (error) errors.Email = error;
    });

    const phoneNumber = () => [
      required,
      Validators.phone([
        "Phone number should contain only numbers",
        "Phone number must be 11 digits",
      ])
    ].forEach(validator => {
      let error = validator(values?.PhoneNumber)
      if (error) errors.PhoneNumber = error;
    });

    const street = () => [
      required,
      Validators.maxLength(
        100,
        "Maximum number of characters is 100"
      ),
      Validators.minLength(
        4,
        "Minimum number of characters is 4"
      )
    ].forEach(validator => {
      let error = validator(values?.Street)
      if (error) errors.Street = error;
    });

    const city = () => [
      required,
      Validators.minLength(3, "Invalid city")
    ].forEach(validator => {
      let error = validator(values?.City)
      if (error) errors.City = error;
    });



    const validate = () => {
      try {
        lastName();
        firstName();
        userName()
        gender()
        state()
        email()
        phoneNumber()
        street()
        city()
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();
  }
  return <Formik
    initialValues={Initiate(institution)}
    validate={validator}
    onSubmit={(values, { setSubmitting }) => {
      onNext({ ...values })
    }}
  >
    {({
      values,
      errors,
      touched,
      isValid,
      setFieldError,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      isSubmitting,
    }) => (<ClusterForm handleSubmit={handleSubmit}>

      <React.Fragment>
        <Card>
          <CardBody>
            <CardTitle>
              <b>Super Admin Details</b>
            </CardTitle>
            <React.Fragment>
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <p>Basic Data</p>
                  </Col>
                </Row>

                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={4} md={4}>
                        <ClusterInput

                          defaultValue={values?.LastName}
                          value={values?.LastName}
                          label={"Surname"}
                          id={"surname"}
                          name={"LastName"}
                          validationError={errors.LastName}
                          type="text"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.FirstName}

                          label={"First Name"}
                          id={"firstname"}
                          name={"FirstName"}
                          type="text"
                          required={true}
                          value={values.FirstName}
                          validationError={errors.FirstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.OtherName}
                          label={"Other Names"}
                          id={"othernames"}
                          name={"OtherName"}
                          type="text"
                          required={false}
                          value={values.OtherName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={4}>
                        <ClusterInput
                          defaultValue={values.Username}
                          label={"Username"}
                          id={"username"}
                          name={"Username"}

                          type="text"
                          required={true}
                          value={values.Username}
                          validationError={errors.Username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>

                      <Col lg={1} md={2}>
                        <FormGroup>
                          <Label>Gender</Label>
                          <Row>
                            <ClusterDropdown
                              style={{ left: "10%", top: "60%" }}
                              placeholder={"- Select -"}
                              name={"Gender"}
                              items={Utils.Enums.toOptions(Gender)}
                              onChange={(name, value) => {
                                setFieldValue("Gender", Gender[value])
                              }}
                              defaultValue={values.Gender}
                              value={values.Gender}
                              validationError={errors.Gender}
                              onBlur={handleBlur}
                              required={true}
                            ></ClusterDropdown>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </React.Fragment>

              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <p>Contact Me</p>
                  </Col>
                </Row>

                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.Email}

                          label={"Email"}
                          id={"email"}
                          name={"Email"}
                          type="email"
                          required={true}
                          value={values?.Email}
                          validationError={errors.Email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.PhoneNumber}

                          label={"Phone Number"}
                          id={"phonenumber"}
                          name={"PhoneNumber"}
                          validationError={errors?.PhoneNumber}
                          type="tel"
                          required={true}
                          value={values?.PhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>

                    </Row>
                    <Row>
                      <Col lg={4} md={4}>

                        <ClusterTextArea
                          defaultValue={values?.Street}
                          required={true}
                          label={"Street Address"}
                          id={"street"}
                          name={"Street"}
                          validationError={errors.Street}
                          value={values?.Street}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.City}
                          label={"City"}
                          id={"city"}
                          name={"City"}
                          validationError={errors.City}
                          type="text"
                          required={true}
                          value={values?.City}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        ></ClusterInput>
                      </Col>
                      <Col lg={4} md={4}>
                        <FormGroup>
                          <Label>State</Label>
                          {statesLoading ? (
                            <Loading></Loading>
                          ) : (
                            <ClusterDropdown
                              placeholder={"- Enter a state - "}
                              defaultValue={
                                values?.State
                              }
                              items={states ?? []}
                              name={"State"}
                              onChange={(name, value) => {
                                setFieldValue("State", value)
                              }}
                              value={values.State}
                              validationError={errors.State}
                              onBlur={handleBlur}
                              required={true}
                            ></ClusterDropdown>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </React.Fragment>

              <React.Fragment>

              </React.Fragment>

            </React.Fragment>

          </CardBody>
        </Card>
      </React.Fragment>
      <Row style={{ paddingBottom: "50px" }}>
        <Col lg={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ClusterButton
            style={{ justifySelf: "right" }}
            color={"light"}
            addon={"bx bxs-left-arrow"}
            onHandleClick={() => onPrev()}
            type={"button"}
          >
            Prev
          </ClusterButton>
          <ClusterButton
            style={{ justifySelf: "right" }}
            color={"primary"}
            addon={"bx bx-right-arrow"}
            disabled={(isUpdate && !touched) ? false : (!isValid || !touched)}
          >
            Next
          </ClusterButton>
        </Col>
      </Row>


    </ClusterForm>)}

  </Formik>
}


export default InstitutionSuperAdminForm;