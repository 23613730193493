import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  getClusterManagerDetail,
  getClusterManagers,
  clusterManagerPurge,
  enableClusterManager,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import withDispose from "components/HOCs/WithDispose"
import ClusterManagerLogic from "../cluster-manager"
import { EDIT_CLUSTER_MANAGER, ENABLE_CLUSTER_MANAGERS } from "../permission"
import useReportData from "hooks/UseReportData"

import {
  GET_ALL_CLUSTER_MANAGERS,
  GET_CLUSTER_MANAGERS,
} from "helpers/url_helper"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import { useInstitutionsData } from "hooks/useInstitutionData"

const AgentClusterList = props => {
  const {
    clusterManagers,
    onGetClusterManagers,
    error,
    success,
    onGetClusterManagerDetail,
    clusterManagerDetail,
    onPurge,
    totalCount,
    editLoading,
    statusChanged,
    loading,
    onEnableClusterManager,
  } = props
  const { HEADERS, MapToModalState, MapToRows, SearchCriteria, SearchOptions } =
    ClusterManagerLogic
  const {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
  } = useReportData({
    onGetData: onGetClusterManagers,
    HEADERS: HEADERS,
    SearchCriteria: SearchCriteria,
  })
    const { institutionsData, institutions } = useInstitutionsData()
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  const [viewedClusterManager, setViewedManager] = useState(null)
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    populateRows()
  }, [clusterManagers])

  useEffect(() => {
    if (clusterManagerDetail && Object.keys(clusterManagerDetail).length > 0) {
      setmodal(true)
      setViewedManager(clusterManagerDetail)
      setModalData(MapToModalState(clusterManagerDetail))
    }
  }, [clusterManagerDetail])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  const populateRows = () => {
    if (clusterManagers) {
      let rws = []
      clusterManagers.forEach(clusterManager => {
        let row = MapToRows(clusterManager)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(clusterManager.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }
  function getIsEnabled() {
    let enabled = false
    if (clusterManagerDetail?.IsActive || statusChanged == true) enabled = true
    setIsEnabled(enabled)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetClusterManagerDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Cluster Manager"}
            breadcrumbItem={"View Agent Cluster Managers"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CLUSTER_MANAGERS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={viewedClusterManager?.IsActive ? "success" : "secondary"}
          status={viewedClusterManager?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Cluster Manager Details"}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_CLUSTER_MANAGERS]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableClusterManager(
                viewedClusterManager?.ID,
                !viewedClusterManager?.IsActive
              )
            }
            color="secondary"
          >
            {viewedClusterManager?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-cluster-manager/" + viewedClusterManager?.ID}>
            <ClusterButton
              permissions={[EDIT_CLUSTER_MANAGER]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

AgentClusterList.propTypes = {
  clusterManagers: PropTypes.array,
  clusterManagerDetail: PropTypes.object,
  onGetClusterManagers: PropTypes.func,
  onGetClusterManagerDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  clusterManagers: {
    clusterManagers,
    error,
    success,
    clusterManagerDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  return {
    clusterManagers: [...clusterManagers],
    error,
    clusterManagerDetail: clusterManagerDetail,
    totalCount,
    success,
    loading,
    statusChanged,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetClusterManagers: query => dispatch(getClusterManagers(query)),
  onGetClusterManagerDetail: id => dispatch(getClusterManagerDetail(id)),
  onPurge: () => dispatch(clusterManagerPurge()),

  onEnableClusterManager: (id, enable) =>
    dispatch(enableClusterManager(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(AgentClusterList))))
