import Loading from "components/Common/Loading";
import { fetcher } from "helpers/api_helper";
import { GET_SETTLEMENT_COMMERCIAL_BANKS } from "helpers/url_helper";
import { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Row, Col } from "reactstrap";
import useSWR from "swr";
import SettlementAccountForm from "pages/SettlementManagement/account/form";
import withDispose from "components/HOCs/WithDispose";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getSettlementAccount, saveSettlementAccount } from "store/actions";
import { SettlementAccountType } from "enums/settlement-account-type";

const AccountSetupModal = (props) => {
    const {isOpen, toggle, onSaveAccount, onGetAccount,  success, editLoading, loading, error } = props

    const [errorList, setErrorList] = useState({});
    const [isRotating, setIsRotating] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    useEffect(() => {
      onGetAccount()
    }, [])
  
  
    const commercialBanks = useSWR(GET_SETTLEMENT_COMMERCIAL_BANKS, fetcher, {
      revalidateOnFocus: true,
      revalidateOnReconnect: false
    })
   
    const handleValidSubmit = (account) => {
     
        onSaveAccount(account)
      
    }
  
   
  
    return <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      toggle={() => {
        toggle();
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          Add Settlement Account
        </ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md={12}>
            <SettlementAccountForm
            success={success}
            error={error}
            isUpdate={isUpdate}
            loading={loading}
            errorList={errorList}
            isRotating={isRotating}
            editLoading={editLoading}
            commercialBanks={(commercialBanks?.data as any)?.Data}
            type={SettlementAccountType.Corebanking}
            setErrorList={setErrorList}
            setIsRotating={setIsRotating}
            handleValidSubmit={handleValidSubmit}
          />
            </Col>
          </Row>
        </ModalBody>
  
      </div>
    </Modal>
  }

  
  const mapStateToProps = ({ settlements: { account, error, success, editLoading, totalCount, loading } }) => {
    return {
      error,
      success,
      account,
      totalCount,
      loading,
      editLoading
    }
  }
  
  const mapDispatchToProps = dispatch => ({
    onGetAccount: () => dispatch(getSettlementAccount(SettlementAccountType.Corebanking)),
    onSaveAccount: (settlement) => dispatch(saveSettlementAccount(settlement)),
  })
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(withRouter(withDispose(AccountSetupModal))))
  