import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  getCaseLogDetail,
  getCaseLogs,
  caseLogPurge,
  enableCaseLog,
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import CaseLogLogic from "../case-log"
import { GET_ALL_CASELOGS } from "helpers/url_helper"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

type ViewCaselogData = {
  IsActive : Boolean
}

const CaseLogList = props => {
  const {
    caseLogs,
    onGetCaseLogs,
    error,
    onGetCaseLogDetail,
    caseLogDetail,
    onPurge,
    totalCount,
    editLoading,
    statusChanged,
    loading,
    onEnableCaseLog,
  } = props
  const {
    HEADERS,
    MapToModalState,
    MapToRows,
    SearchCriteria,
    SearchOptions,
  } = CaseLogLogic
  const {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
    search
  } = useReportData({
    onGetData: onGetCaseLogs,
    HEADERS: HEADERS,
    SearchCriteria: SearchCriteria,
  })
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  
  const [viewedCaseLog, setViewedLog] = useState<ViewCaselogData>()
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    populateRows()
  }, [caseLogs])

  useEffect(() => {
    if (caseLogDetail && Object.keys(caseLogDetail).length > 0) {
      setmodal(true)
      setViewedLog(caseLogDetail)
      setModalData(MapToModalState(caseLogDetail))
    }
  }, [caseLogDetail])

  useEffect(() => {
    getIsEnabled()
  }, [statusChanged, viewedCaseLog])
  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [statusChanged])

  const populateRows = () => {
    if (caseLogs) {
      let rws = []
      caseLogs.forEach(caseLog => {
        let row = MapToRows(caseLog)
        row["action"] = (
          <Link to={"/view-caselog/" + caseLog.ID}>
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
            >
              View Details
            </Button>
          </Link>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function getIsEnabled() {
    let enabled = false
    if (viewedCaseLog?.IsActive || statusChanged == true) enabled = true
    setIsEnabled(enabled)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetCaseLogDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Case Logs"} breadcrumbItem={"View CaseLogs"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CASELOGS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CaseLogList.propTypes = {
  caseLogs: PropTypes.array,
  caseLogDetail: PropTypes.object,
  onGetCaseLogs: PropTypes.func,
  onGetCaseLogDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  caseLogs: {
    caseLogs,
    error,
    caseLogDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  return {
    caseLogs: [...caseLogs],
    error,
    caseLogDetail: caseLogDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCaseLogs: query => dispatch(getCaseLogs(query)),
  onGetCaseLogDetail: id => dispatch(getCaseLogDetail(id)),
  onPurge: () => dispatch(caseLogPurge()),

  onEnableCaseLog: (id, enable) => dispatch(enableCaseLog(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CaseLogList))))
