import { Validators } from "helpers/validators";
import { AgentCategory } from "../category";

const AgentCategoryValidator = (values:AgentCategory) => {
    const errors:any ={};
    const required = Validators.required("Please enter a value");

    const name = () =>
        [required,
            Validators.minLength(
                3,
                "Minimum number of characters is 3"
            ),
            Validators.maxLength(
                50,
                "Maximum number of characters is 50"
            ),].forEach(validator => {
                let error = validator(values?.Name)
                if (error) errors.Name = error;
            });

    const description = () => [required,
        Validators.maxLength(
            100,
            "Maximum number of characters is 100"
        ),
        Validators.minLength(
            4,
            "Minimum number of characters is 4"
        )
    ].forEach(validator => {
        let error = validator(values?.Description)
        if (error) errors.Description = error;
    })
    const validate= () => {
        try {
            name(); 
            description(); 
            return errors;
        } catch (err) {
            console.log(err)
        }
    }
    return validate();
}
export default AgentCategoryValidator