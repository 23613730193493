import { GET_STATES_FAIL, GET_STATES_SUCCESS, GET_STATES } from "./actionTypes"

const INIT_STATE = {
  states: [],
  error: {},
  loading: false,
  statesLoading: true,
}

const Misc = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STATES:
      return {
        ...state,
        statesLoading: true,
      }
    case GET_STATES_SUCCESS:
      return {
        ...state,
        states: action.payload?.Data.map(d => {
          return { id: d.Name, value: d.Name, key: d.Name }
        }),
        statesLoading: false,
      }

    case GET_STATES_FAIL:
      return {
        ...state,
        error: action.payload,
        statesLoading: false,
      }

    default:
      return state
  }
}

export default Misc
