import React, { useCallback, useMemo, useState } from "react"
import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"
import { Utils } from "helpers/utility"
import { AccountMode } from "enums/account-mode"
import { Formik } from "formik"
import { ACCOUNT_VALIDATION } from "helpers/url_helper"
import { toast } from "react-toastify"
import { AgentSection } from "enums/agent-section"
import { fetcher } from "helpers/api_helper"
const AccountModeForm = props => {
  const { agent, isUpdate, next, setFormValues, agents } = props
  const [account, setAccount] = useState<any>({
    data: {},
    accountNumber: "",
    exists: false,
    isValidating: false,
    error: {},
  })
  const [accountValidated, setAccountValidated] = useState(false)
  const [accountMode, setAccountMode] = useState<undefined | AccountMode>()
  const detailsFromAccount = useMemo(() => {
    if (!Utils.Object.isEmpty(account?.data) && account?.data?.Agent === null) {
      if (account.exists) {
        let details = Object.assign({}, agent)
        let detail = account.data
        details.AccountNumber = account.accountNumber
        details.Email = detail.Email
        details.LastName = detail.LastName
        details.OtherNames = detail.FirstName
        details.BankVerificationNumber = detail.BankVerificationNumber
        details.PhoneNumber = (detail.PhoneNo ?? "").trim()
        details.DateOfBirth = detail.DateOfBirth
        details.Gender = detail.Gender
        details.AddressLocation = detail.Address
        setAccountValidated(true)
        return details
      }
    }
  }, [agent, account])
  const handleAccountValidation = useCallback(
    accountNumber => {
      setAccountValidated(false)
      setAccount({
        data: {},
        accountNumber,
        error: {},
        exists: false,
        isValidating: true,
      })
      fetcher(ACCOUNT_VALIDATION + "/" + accountNumber)
        .then(c => {
          const thisAccount = (c as any).Data
          setAccount({
            accountNumber,
            data: thisAccount,
            exists: !Utils.Object.isEmpty(thisAccount),
            isValidating: false,
          })
        })
        .catch(err => {
          setAccount({ ...account, error: err })
          toast.error(err?.data?.Message)
        })
    },
    [accountValidated]
  )
  const handlePrepopulation = values => {
    setFormValues({
      ...values,
      ParentAgentID: account?.data?.Agent?.ID,
      AgentType: !Utils.Object.isEmpty(account?.data?.Agent)
        ? AgentSection[AgentSection.SubAgent]
        : AgentSection[AgentSection.Agent],
    })
    next()
  }
  const initiate = agent => {
    return {
      AccountNumber: agent?.AccountNumber,
      AccountMode: agent?.AccountMode,
      IsWallet: agent?.AccountMode === AccountMode[AccountMode.Wallet],
    }
  }

  const validator = values => {
    let errors: any = {}
    const required = Validators.required("Please enter a value")
    const accountNumber = () =>
      [
        required,
        Validators.accountNumber([
          "Account number should contain only numbers",
          "Account number must be 10 digits",
        ]),
      ].forEach(validator => {
        let error = validator(values.AccountNumber)
        if (error) errors.AccountNumber = error
      })

    const accountMode = () =>
      [required].forEach(validator => {
        let error = validator(values.AccountMode)
        if (error) errors.AccountMode = error
      })

    const agentType = () =>
      [required].forEach(validator => {
        let error = validator(values.AgentType)
        if (error) errors.AgentType = error
      })

    const validate = () => {
      try {
        accountMode()
        if (values.AccountMode === AccountMode[AccountMode.CoreBanking]) {
          accountNumber()
        }
        return errors
      } catch (err) {
        console.log(err)
      }
    }
    return validate()
  }
  return (
    <Formik
      initialValues={initiate(agent)}
      onSubmit={(values, { setSubmitting }) => {
        values.IsWallet =
          values?.AccountMode === AccountMode[AccountMode.Wallet]
        const formValues = { ...values, ...detailsFromAccount }
        handlePrepopulation(formValues)
      }}
      validate={validator}
    >
      {({
        values,
        touched,
        isValid,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        status,
      }) => (
        <ClusterForm handleSubmit={handleSubmit}>
          <React.Fragment>
            <Card
              outline
              style={{ background: "transparent", borderColor: "#999999" }}
              className="border"
            >
              <CardBody>
                <Row style={{ paddingBottom: 20 }}>
                  <Col lg={12} md={12}>
                    {account?.exists === true &&
                      account?.data?.Agent === null &&
                      !agents.some(agent => agent.IncomeAccount === account?.accountNumber) ?
                      (
                        <Alert style={{ textAlign: "center" }} color="green">
                          Proceed to create an Agent
                        </Alert>
                      ) : null}

                    {!Utils.Object.isEmpty(account?.data?.Agent) &&
                      (
                        <Alert style={{ textAlign: "center" }} color="green">
                          Only a sub-agent can be created with this account number
                        </Alert>
                      )}
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} md={4}>
                    {isUpdate === true ? (
                      <ClusterInput
                        defaultValue={values?.AccountMode}
                        disabled={true}
                        label={"Account Mode"}
                        id={"Mode"}
                        name="AccountMode"
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.AccountMode}
                        validationError={errors?.AccountMode}
                      />
                    ) : (
                      <FormGroup>
                        <Label>Account Mode</Label>

                        <ClusterDropdown
                          placeholder={"- Select a mode - "}
                          defaultValue={values?.AccountMode}
                          required={true}
                          id={"AccountMode"}
                          name={"AccountMode"}
                          items={Utils.Enums.toOptions(AccountMode)}
                          onChange={(name, value: AccountMode) => {
                            setAccountMode(value ?? undefined)
                            if (value === AccountMode.Wallet) {
                              setAccountValidated(true)
                            } else if (value === AccountMode.CoreBanking) {
                              setAccount({
                                data: {},
                                accountNumber: "",
                                exists: false,
                                isValidating: false,
                                error: {},
                              })
                              setAccountValidated(false)
                            }
                            setFieldValue("AccountMode", AccountMode[value])
                          }}
                          validationError={errors?.AccountMode}
                        />
                      </FormGroup>
                    )}
                  </Col>
                  {values?.AccountMode ===
                    AccountMode[AccountMode.CoreBanking] && (
                      <Col lg={3} md={4}>
                        <ClusterInput
                          defaultValue={values?.AccountNumber}
                          disabled={
                            !Utils.Object.isEmpty(account?.data) ? true : false
                          }
                          label={"Account Number"}
                          id={"accountNumber"}
                          name={"AccountNumber"}
                          required={true}
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.AccountNumber}
                          validationError={errors?.AccountNumber}
                        />
                        <ClusterButton
                          className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                          loading={account.isValidating}
                          onHandleClick={e => {
                            e.preventDefault()
                            handleAccountValidation(values?.AccountNumber)
                          }}
                          disabled={false}
                        >
                          Validate
                        </ClusterButton>
                      </Col>
                    )}
                </Row>

                <Row style={{ paddingBottom: "50px" }}>
                  <Col lg={12}>
                    <ClusterButton
                      disabled={
                        !touched
                          ? true
                          : !isValid ||
                          (accountMode === AccountMode.CoreBanking &&
                            account?.exists !== true)
                      }
                      color={"primary"}
                      addon={"bx bx-arrow-right"}
                    >
                      Next
                    </ClusterButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        </ClusterForm>
      )}
    </Formik>
  )
}

export default AccountModeForm
