import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
import { Utils } from "helpers/utility"
import { GetInstitution } from "helpers/authentication/institution"

const HEADERS = [
  { title: "Date Logged", name: "dateLogged", entityKeyName: "DateLogged" },
  {
    title: "Agent Category",
    name: "agentCategory",
    entityKeyName: "AgentCategory.Name",
  },
  { title: "Agent Phone Number", name: "agentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
  {
    title: "Header",
    name: "header",
    entityKeyName: "Header",
  },
  {
    title: "Subject",
    name: "subject",
    entityKeyName: "Message",
  },
  { title: "Seen", name: "seen", entityKeyName: "ReadCount" },
  {
    title: "Last Read",
    name: "lastRead",
    entityKeyName: "LastReadTime",
  },
  { title: "Logged By", name: "loggedBy", entityKeyName: "LoggedBy" },
  {
    title: "Type",
    name: "type",
    entityKeyName: "Type",
  },
  { title: "", name: "View Details", isAction: true }
]


export enum NotificationType {
    AgentCategory = "AgentCategory",
    General  = "General",
    Specific = "Specific"
}


const SearchOptions = (startDate, endDate, agentCategories, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "dropdown",
      label: "Notification Type",
      id: "s-notificationType",
      defaultValue: "- Select -",
      name: "Type",
      data: Utils.Enums.toOptions(NotificationType),
      width: 2,
    },
    {
      type: "dropdown",
      label: "Agent Category",
      id: "s-agentCategory",
      defaultValue: "- Select -",
      name: "agentCategoryID",
      data: MapToDropDown(agentCategories),
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}

const MapToRows = notifications => {
  const lastReadTime = notifications.LastReadTime == null? "" : Format.date(notifications.LastReadTime)
  return {
    id: notifications.ID,
    dateLogged: Format.date(notifications.DateLogged),
    agentCategory: notifications.AgentCategory?.Name,
    agentPhoneNumber: notifications.AgentPhoneNumber,
    header: notifications.Header,
    institution: notifications.InstitutionName,
    subject: notifications.Message,
    seen: notifications.ReadCount,
    lastRead: lastReadTime,
    type: notifications.Type,
    loggedBy: notifications.LoggedBy,
  }
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}

const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    Type: params["Type"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    AgentCategoryID: params["agentCategoryID"],
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}
export default {
    HEADERS, SearchOptions, MapToDropDown, MapToRows, SearchCriteria  }


