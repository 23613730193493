import React, { useState, useEffect, useMemo, useCallback } from "react"
import PropTypes from "prop-types"
import {
  TabContent,
  TabPane,
  Collapse,
  NavLink,
  NavItem,
  CardText,
  Nav,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Container,
} from "reactstrap"

import ReactApexChart from "react-apexcharts"
import NoData from "./NoData"
import { formatter } from "helpers/utility"
const defaultConfig = {
  //  chart:{
  //    toolbar: "true",
  //    zoom: {
  //     enabled: true,
  //     type: 'x',
  //     resetIcon: {
  //         offsetX: -10,
  //         offsetY: 0,
  //         fillColor: '#fff',
  //         strokeColor: '#37474F'
  //     },
  //     selection: {
  //         background: '#90CAF9',
  //         border: '#0D47A1'
  //     }
  // }
  //  },
  chart: {
    toolbar: {
      show: true,
      offsetX: 0,
      offsetY: 0,
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        // reset: true | '<img src="/static/icons/reset.png" width="20">',
        customIcons: [],
      },
      export: {
        csv: {
          filename: undefined,
          columnDelimiter: ",",
          headerCategory: "category",
          headerValue: "value",
          dateFormatter(timestamp) {
            return new Date(timestamp).toDateString()
          },
        },
      },
      autoSelected: "zoom",
    },
  },
  stroke: {
    curve: "smooth",
    width: 3,
  },
  dataLabels: {
    enabled: false, //!1,
  },
}
const splineAreaConfig = {
  ...defaultConfig,
  // chart: {
  // toolbar: true,
  //   dropShadow: {
  //     enabled: !0,
  //     color: "#000",
  //     top: 18,
  //     left: 7,
  //     blur: 8,
  //     opacity: 0.2,
  //   },
  // },
}

const barChartConfig = {
  ...defaultConfig,

  fill: {
    opacity: 1,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "45%",
      endingShape: "rounded",
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
}

const ClusterChart = props => {
  let { data, options, currency, height = 300, type, color } = props
  if (color == undefined) color = ["#556ee6"]
  if (data == undefined) data = { yAxis: [], xAxis: {} }
  let series = []

  let { yAxis, xAxis } = data
  series = yAxis
  switch (type) {
    case "bar":
      options = {
        ...barChartConfig,
        colors: [...color],

        xaxis: {
          type: xAxis.type,
          categories: xAxis.data,
        },
        tooltip: {
          x: {
            format: xAxis.format,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatter(value, currency, true)
                .toString()
                .replace(".00", "")
            },
          },
        },
      }
      break
    case "donut":
      series = []
      series = xAxis
      options = {
        ...defaultConfig,

        labels: yAxis,
        colors: ["#f46a6a", "#34c38f", "#556ee6", "#ee4266", "#ff7d0097"],
        legend: { show: !1 },
        plotOptions: {
          pie: {
            donut: {
              size: "70%",
            },
          },
        },
      }
      break
    default:
      type = "area"
      options = {
        ...splineAreaConfig,
        colors: [...color],

        xaxis: {
          type: xAxis.type,
          categories: xAxis.data,
        },
        tooltip: {
          x: {
            format: xAxis.format,
          },
        },
        yaxis: {
          labels: {
            formatter: function (value) {
              return formatter(value, currency, true)
                .toString()
                .replace(".00", "")
            },
          },
        },
      }
      break
  }
  if (type !== "donut") {
    return series.every(serie => {
      return serie.data.length > 0
    }) ? (
      <ReactApexChart
        series={series}
        options={options}
        type={type}
        height={height}
      />
    ) : (
      <NoData></NoData>
    )
  } else {
    return series.length > 0 ? (
      <React.Fragment>
        <ReactApexChart
          series={series}
          options={options}
          type={type}
          height={height}
        />
        <div className="text-center text-muted">
          <Row>
            {yAxis.map((label, key) => {
              return (
                <Col xs="4" key={`_donutcard_${key}`}>
                  <div className="mt-4">
                    <p className="mb-2 text-truncate">
                      <i
                        style={{ color: `${options.colors[key]}` }}
                        className="mdi mdi-circle mr-1"
                      />{" "}
                      {label}
                    </p>
                    <h5>{formatter(series[key], currency, false)}</h5>
                  </div>
                </Col>
              )
            })}
          </Row>
        </div>
      </React.Fragment>
    ) : (
      <NoData></NoData>
    )
  }
}

ClusterChart.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  options: PropTypes.object,
  color: PropTypes.array,
  height: PropTypes.number,
}
export default ClusterChart
