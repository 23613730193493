import React, { useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Alert,
} from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import ClusterButton from "components/Common/Button/Button"
import { ACCOUNT_VALIDATION } from "helpers/url_helper"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import withDispose from "components/HOCs/WithDispose"
import { Utils } from "helpers/utility"
import AgentLogic from "../agent"
import { fetcher } from "helpers/api_helper"
import { toast } from "react-toastify"
import { unlinkParentAgent } from "store/actions"

//i18n
import { withTranslation } from "react-i18next"

const UnlinkParentAgentModal = props => {
  const {
    open,
    onChangeModal,
    onUnlinkParentAgent,
    unlinkAgent,
    agent,
    success,
    error,
    loading,
  } = props
  const [isOpen, setOpen] = useState(open)
  const [accountValidated, setAccountValidated] = useState(false)
  const [account, setAccount] = useState({
    data: {},
    accountNumber: "",
    exists: false,
    isValidating: false,
    error: {},
  })
  const [unlinkAgentValues, setUnlinkAgentValues] = useState({
    id: unlinkAgent?.id,
    accountNumber: "",
  })
  const handleAccountValidation = useCallback(
    accountNumber => {
      setAccountValidated(false)
      setAccount({
        data: {},
        accountNumber,
        error: {},
        exists: false,
        isValidating: true,
      })
      
      fetcher(ACCOUNT_VALIDATION + "/" + accountNumber)
        .then(c => {
          const thisAccount = c.Data
          setAccount({
            accountNumber,
            data: thisAccount,
            exists: !Utils.Object.isEmpty(thisAccount),
            isValidating: false,
          })
        })
        .catch(err => {
          setAccount({ ...account, error: err })
          toast.error(err?.data?.Message)
        })
    },
    [accountValidated]
  )
  const changeOpen = status => {
    setOpen(status)
    onChangeModal(status)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])

  const runUnlinkParentAgent = () => {
    try {
      onUnlinkParentAgent(unlinkAgentValues)
      toast.success("Parent agent unlinked successfully")
    } catch (error) {
      toast.error("Parent agent could not be unlinked")
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        changeOpen(!isOpen)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            changeOpen(!isOpen)
          }}
        >
          Unlink Parent Agent
        </ModalHeader>
        <ModalBody>
          <React.Fragment>
            <Card
              outline
              style={{
                background: "transparent",
                borderColor: "#999999",
              }}
              className="border"
            >
              <CardBody>
                <Row style={{ paddingBottom: 20 }}>
                  <Col lg={12} md={12}>
                    {!Utils.Object.isEmpty(account?.data) &&
                    account?.data?.Agent !== null ? (
                      <Alert color="green">
                        Account Number is already being used by an agent
                      </Alert>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <ClusterInput
                      defaultValue={agent?.LastName + " " + agent?.OtherNames}
                      disabled
                      label={"Name"}
                      id={"m-name"}
                      type="text"
                      required={true}
                    ></ClusterInput>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup>
                      <ClusterInput
                        disabled={false}
                        value={unlinkAgentValues.accountNumber}
                        label={"Account Number"}
                        id={"accountNumber"}
                        name={"AccountNumber"}
                        placeholder="Enter account number"
                        required={true}
                        type="text"
                        onChange={e => {
                          setUnlinkAgentValues({
                            ...unlinkAgentValues,
                            accountNumber: e.target.value,
                          })
                        }}
                      />
                    </FormGroup>
                    {unlinkAgentValues.accountNumber.length === 10 && (
                      <ClusterButton
                        className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                        loading={account.isValidating}
                        onHandleClick={e => {
                          e.preventDefault()
                          handleAccountValidation(
                            unlinkAgentValues?.accountNumber
                          )
                        }}
                        disabled={
                          unlinkAgentValues?.accountNumber.length !== 10
                        }
                      >
                        Validate
                      </ClusterButton>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
          <Row style={{ paddingBottom: "50px" }}>
            <Col lg={12}>
              {!Utils.Object.isEmpty(account?.data) &&
              account?.data?.Agent === null ? (
                <Button
                  loading={loading}
                  color={"primary"}
                  addon={"bx bx-save"}
                  onClick={runUnlinkParentAgent}
                >
                  Unlink Parent Agent
                </Button>
              ) : null}
            </Col>
          </Row>
        </ModalBody>
      </div>
    </Modal>
  )
}

UnlinkParentAgentModal.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onChangeModal: PropTypes.func,
  onUnlinkParentAgent: PropTypes.func,
  agent: PropTypes.object,
}
const mapStateToProps = ({
  agents: {
    agents,
    error,
    success,
    agentDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    resetLimitLoading,
    deviceResetLoading,
    linkLoading,
    upgradeLoading,
  },
}) => {
  return {
    agents,
    error,
    success,
    agentDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    resetLimitLoading,
    deviceResetLoading,
    linkLoading,
    upgradeLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onUnlinkParentAgent: payload => dispatch(unlinkParentAgent(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(UnlinkParentAgentModal))))
