import { Format, SEARCHOPTIONS } from "helpers/variables"
import { saveFile } from "helpers/download_util/file_download"
import { post } from "helpers/api_helper"
import moment from "moment"
import { toast } from "react-toastify"
import * as url from "helpers/url_helper"
import { GetInstitution } from "helpers/authentication/institution"
const HEADERS = [
  {
    title: "  Invoice Type",
    name: "invoiceType",
    entityKeyName: "InvoiceType",
  },
  { title: "Amount", name: "amount", entityKeyName: "Amount" },
  {
    title: "Debit Account",
    name: "debitAccount",
    entityKeyName: "DebitAccount",
  },
  {
    title: "Credit Account",
    name: "creditAccount",
    entityKeyName: "CreditAccount",
  },
  { title: "Narration", name: "narration", entityKeyName: "Narration" },
  {
    title: "Requested By",
    name: "requestedBy",
    entityKeyName: "RequestedByUser",
  },
  { title: "Date Logged", name: "dateLogged", entityKeyName: "DateLogged" },
  { title: "", name: "download", isAction: true },
  { title: "", name: "approve", isAction: true },
  { title: "", name: "reject", isAction: true },
]

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    ApprovalStatus: "pending",
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToRows = debitrequests => {
  return {
    invoiceType: debitrequests.InvoiceType,
    amount: Format.currency(debitrequests.Amount),
    debitAccount: debitrequests.DebitAccount,
    institution: debitrequests?.InstitutionName,
    creditAccount: debitrequests.CreditAccount,
    narration: debitrequests.Narration,
    requestedBy: debitrequests.RequestedByUser,
    dateLogged: Format.date(debitrequests.DateLogged),
  }
}

const loadData = (id, filename) =>
  new Promise(async (resolve, reject) => {
    try {
      let blob = await post(
        `${url.DOWNLOAD_INVOICE}/${id}`,
        {},
        {
          headers: {
            "Content-Type": "application/octet-stream",
          },
          responseType: "blob",
        },
        true
      )
      saveFile(blob, filename)
    } catch (d) {
      if (d?.status == 400)
        toast.success("The download might take a little while")
      else
        toast.error("Sorry we seems to have experienced a technical difficulty")
    }
  })

export default {
  SearchCriteria,
  HEADERS,
  SearchOptions,
  MapToRows,
  MapToDropDown,
  loadData,
}
