import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_COMMISSION_STRUCTURES,
  GET_COMMISSION_STRUCTURE_DETAIL,
  COMMISSION_STRUCTURE_PURGE,
  ENABLE_COMMISSION_STRUCTURE,
  SAVE_STRUCTURE_CONFIGURATION,
  UPDATE_COMMISSION_STRUCTURE,
  GET_ALL_COMMISSION_STRUCTURES,
  GET_COMMISSION_STRUCTURES_BY_CATEGORY,
  ADD_COMMISSION_STRUCTURE,
  REMOVE_COMMISSION_STRUCTURE,
} from "./actionTypes"
import {
  getCommissionStructuresSuccess,
  getCommissionStructuresFail,
  getCommissionStructureDetailSuccess,
  getCommissionStructureDetailFail,
  commissionStructurePurgeSuccess,
  updateCommissionStructureSuccess,
  postCommissionStructureSuccess,
  commissionStructureApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCommissionStructures,
  getCommissionStructuresByCategory,
  getCommissionStructureDetail,
  putCommissionStructure,
  enableCommissionStructure,
  disableCommissionStructure,
  getAllCommissionStructures,
  batchInsertStructures,
  removeCommissionStructure,
} from "helpers/backend_helper"
import {
  addCommissionStructureSuccess,
  removeCommissionStructureSuccess,
} from "store/actions"
const format = "yyyy-MM-DD"
function* fetchCommissionStructures({ params }) {
  try {
    const response = yield call(getCommissionStructures, JSON.parse(params))
    yield put(getCommissionStructuresSuccess(response))
  } catch (error) {
    console.debug("Fetching CommissionStructures Error", error)
    yield put(getCommissionStructuresFail(error))
  }
}
function* fetchCommissionStructuresByCategory({ categoryId }) {
  try {
    const response = yield call(getCommissionStructuresByCategory, categoryId)
    yield put(getCommissionStructuresSuccess(response))
  } catch (error) {
    console.debug("Fetching CommissionStructures Error", error)
    yield put(getCommissionStructuresFail(error))
  }
}
function* fetchAllCommissionStructures() {
  try {
    const response = yield call(getAllCommissionStructures)
    yield put(getCommissionStructuresSuccess(response))
  } catch (error) {
    console.debug("Fetching All CommissionStructures Error", error)
    yield put(getCommissionStructuresFail(error))
  }
}
function* fetchCommissionStructureDetail({ id }) {
  try {
    const response = yield call(getCommissionStructureDetail, id)
    yield put(getCommissionStructureDetailSuccess(response))
  } catch (error) {
    yield put(getCommissionStructureDetailFail(error))
  }
}

function* saveCommissionStructure({ payload: { structures } }) {
  try {
    if (structures.length == 0) {
      yield put(
        commissionStructureApiError({ Message: "No structures to save" })
      )
    } else {
      const response = yield call(batchInsertStructures, structures)
      yield put(postCommissionStructureSuccess(response))
    }
  } catch (error) {
    console.debug(error)
    yield put(
      commissionStructureApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function sleep(milliseconds) {
  let start = new Date().getTime()
  for (let i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break
    }
  }
}
function* addCommissionStructure({ payload }) {
  try {
    sleep(200)
    yield put(addCommissionStructureSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      commissionStructureApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doRemoveCommissionStructure({ payload }) {
  try {
    const response = yield call(removeCommissionStructure, payload.ID)
    yield put(removeCommissionStructureSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      commissionStructureApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateCommissionStructure({ payload: { commissionStructure } }) {
  try {
    let anmShare = commissionStructure["ANMShare"] ?? 0
    let bankShare = commissionStructure["BankShare"] ?? 0
    let agentShare = commissionStructure["AgentShare"] ?? 0
    let serviceProviderShare = commissionStructure["ServiceProviderShare"] ?? 0
    let total = 0
    if (commissionStructure["FeeType"] == "Percentage") total = 100
    if (commissionStructure["FeeType"] == "Fixed")
      total = commissionStructure["FlatAmount"] ?? 0

    // if ((anmShare + bankShare + agentShare + serviceProviderShare) != total) {
    //   sleep(5000)
    //   yield put(commissionStructureApiError({ Message: `ANMShare, BankShare, AgentShare, ServiceProviderShare must equal ${commissionStructure["FeeType"] == "Percentage" ? "Percentage" : "Flat Amount"}: ${total}` }))
    //   // return
    // } else {

    const response = yield call(putCommissionStructure, commissionStructure)

    yield put(
      updateCommissionStructureSuccess({
        ...response,
        Data: commissionStructure,
      })
    )
    // }
  } catch (error) {
    console.debug(error)
    yield put(
      commissionStructureApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doEnableCommissionStructure(payload) {
  try {
    let toEnable = true
    let cta = enableCommissionStructure
    if (!payload.enable) {
      cta = disableCommissionStructure
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableCommissionStructureSuccess(toEnable))
  } catch (error) {
    console.debug(error)
    yield put(
      commissionStructureApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doPurge() {
  yield put(commissionStructurePurgeSuccess())
}

function* commissionStructuresSaga() {
  yield takeLeading(GET_COMMISSION_STRUCTURES, fetchCommissionStructures)
  yield takeLeading(GET_ALL_COMMISSION_STRUCTURES, fetchAllCommissionStructures)
  yield takeEvery(
    GET_COMMISSION_STRUCTURE_DETAIL,
    fetchCommissionStructureDetail
  )
  yield takeEvery(COMMISSION_STRUCTURE_PURGE, doPurge)
  yield takeLeading(ENABLE_COMMISSION_STRUCTURE, doEnableCommissionStructure)
  yield takeLeading(UPDATE_COMMISSION_STRUCTURE, updateCommissionStructure)
  yield takeLeading(SAVE_STRUCTURE_CONFIGURATION, saveCommissionStructure)
  yield takeLeading(
    GET_COMMISSION_STRUCTURES_BY_CATEGORY,
    fetchCommissionStructuresByCategory
  )
  yield takeLeading(ADD_COMMISSION_STRUCTURE, addCommissionStructure)
  yield takeLeading(REMOVE_COMMISSION_STRUCTURE, doRemoveCommissionStructure)
}

export default commissionStructuresSaga
