import React, { useEffect, useState } from "react"
import "assets/css/logo.css"
import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import ClusterForm from "components/Common/Form"
import ClusterCheckBox from "components/Common/CheckBox"
import ClusterTextArea from "components/Common/TextArea"
import { Institution, InstitutionBasicDetails } from "../../institution"
import { Validators } from "helpers/validators"
import { Formik } from "formik"
import { Utils } from "helpers/utility"
import { IntegrationType } from "enums/integration-type"
import { PagedFormProps } from "components/Common/Form/PagedForm"
import Loading from "components/Common/Loading"
import ClusterImageUploader from "components/Common/ImageUploader"
import { getInstitutionDetail } from "helpers/backend_helper"

type InstitutionBasicDetailsFormProps =
  PagedFormProps<InstitutionBasicDetails> & {
    institution?: Institution
  }
const InstitutionBasicDetailsForm: React.FC<
  InstitutionBasicDetailsFormProps
> = props => {
  const { isUpdate, institution, onNext, onPrev } = props

  const Initiate = (details: Institution): InstitutionBasicDetails => {
    return {
      Name: details?.Name,
      BaseURI: details?.BaseURI,
      Code: details?.Code,
      TokenLifeSpan: details?.TokenLifeSpan,
      TerminalPrefix: details?.TerminalPrefix,
      Address: details?.Address,
      IntegrationType: details?.IntegrationType,
      ServiceProviderAccountNumber: details?.ServiceProviderAccountNumber,
      AntiMoneyLaunderingLimit: details?.AntiMoneyLaunderingLimit,
      WalletAccountEnabled: details?.WalletAccountEnabled ?? false,
      LogoBase64: details?.LogoBase64,
      Logo: details?.Logo
    }
  }
  const [isBankOneIntegrated, setIsBankOneIntegrated] = useState<
    undefined | boolean
  >()

  useEffect(() => {
    if (!Utils.Object.isEmpty(institution))
      setIsBankOneIntegrated(
        Utils.Object.isEmpty(institution)
          ? undefined
          : Utils.Enums.compare(
            IntegrationType,
            institution?.IntegrationType,
            IntegrationType.BankOne
          )
      )
  }, [institution])

  const validator = (values: InstitutionBasicDetails): any => {
    const errors: any = {}
    const required = Validators.required("Please enter a value")

    const name = () =>
      [
        required,
        Validators.minLength(3, "Minimum number of characters is 3"),
        Validators.maxLength(50, "Maximum number of characters is 50"),
      ].forEach(validator => {
        let error = validator(values?.Name)
        if (error) errors.Name = error
      })

    const url = () =>
      [required, Validators.url()].forEach(validator => {
        let error = validator(values?.BaseURI)
        if (error) errors.BaseURI = error
      })

    const institutionCode = () =>
      [
        required,
        Validators.institutionCode([
          "Only numbers are allowed",
          "Number of characters must be 6",
        ]),
      ].forEach(validator => {
        let error = validator(values?.Code)
        if (error) errors.Code = error
      })

    const tokenLifeSpan = () =>
      [
        Validators.max(60, "Maximum of 60 minutes for expiration of token"),
      ].forEach(validator => {
        let error = validator(+(values?.TokenLifeSpan ?? "0"))
        if (error) errors.TokenLifeSpan = error
      })

    const terminalPrefix = () =>
      [Validators.length(4, "Number of characters must be 4")].forEach(
        validator => {
          let error = validator(values?.TerminalPrefix)
          if (error) errors.TerminalPrefix = error
        }
      )

    const address = () =>
      [
        required,
        Validators.maxLength(100, "Maximum number of characters is 100"),
        Validators.minLength(4, "Minimum number of characters is 4"),
      ].forEach(validator => {
        let error = validator(values?.Address)
        if (error) errors.Address = error
      })

    const appzoneAccount = () =>
      [
        required,
        Validators.accountNumber([
          "Only numbers are allowed",
          "Number of characters must be 10",
        ]),
      ].forEach(validator => {
        let error = validator(values?.ServiceProviderAccountNumber)
        if (error) errors.ServiceProviderAccountNumber = error
      })

    const integrationType = () =>
      [required].forEach(validator => {
        let error = validator(values?.IntegrationType)
        if (error) errors.IntegrationType = error
      })


    const validate = () => {
      try {
        name()
        if (isBankOneIntegrated == true) institutionCode()
        else url()
        tokenLifeSpan()
        terminalPrefix()
        address()
        appzoneAccount()
        integrationType()
        return errors
      } catch (err) {
        console.log(err)
      }
    }
    return validate()
  }

  if (Utils.Object.isEmpty(institution) && isUpdate) {
    return <Loading>Getting Basic Details...</Loading>
  }
  return (
    <React.Fragment>
      <Alert color="info">
        Configurations done on this page are not affected by the institution you
        are currently remoting into
      </Alert>

      <Formik
        initialValues={Initiate(institution)}
        validate={validator}
        onSubmit={(values, { setSubmitting }) => {
          onNext({ ...values })
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          setFieldError,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <ClusterForm handleSubmit={handleSubmit}>
            <React.Fragment>
              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      style={{
                        display: "flex",
                        paddingLeft: "40%",
                      }}
                      className="logoBody"
                    >
                      <ClusterImageUploader
                        id="logoBase64"
                        value={values.LogoBase64}
                        url={values.Logo}
                        name="LogoBase64"
                        accept={[".png, .jpg, .jpeg"]}
                        onHandleChange={value => {
                          setFieldValue("LogoBase64", value.Base64)
                          setFieldValue("Logo", !value.Base64 ? values.Logo : null)
                          setFieldValue("LogoFileName", value.FileName)
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterInput
                        defaultValue={values?.Name}
                        focus
                        value={values?.Name}
                        label={"Name"}
                        id={"name"}
                        name={"Name"}
                        type="text"
                        required={true}
                        validationError={errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterInput>
                    </Col>

                    {isBankOneIntegrated ===
                      undefined ? null : isBankOneIntegrated ? (
                        <Col lg={4} md={4} sm={6}>
                          <ClusterInput
                            defaultValue={values?.Code}
                            value={values?.Code}
                            label={"Code"}
                            id={"code"}
                            name={"Code"}
                            type="text"
                            required={true}
                            validationError={errors.Code}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          ></ClusterInput>
                        </Col>
                      ) : (
                      <Col lg={4} md={4} sm={6}>
                        <ClusterInput
                          defaultValue={values?.BaseURI}
                          value={values?.BaseURI}
                          disabled={isUpdate}
                          label={"Base URI"}
                          id={"baseURI"}
                          name={"BaseURI"}
                          type="url"
                          required={true}
                          validationError={errors.BaseURI}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        ></ClusterInput>
                      </Col>
                    )}

                    <Col lg={4} md={4} sm={6}>
                      <ClusterInput
                        defaultValue={values?.TokenLifeSpan}
                        values={values?.TokenLifeSpan}
                        label={"Token Life Span (mins)"}
                        id={"tokenLifeSpan"}
                        name={"TokenLifeSpan"}
                        type="number"
                        required={true}
                        validationError={errors.TokenLifeSpan}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterInput>
                    </Col>

                    <Col lg={4} md={4} sm={6}>
                      <ClusterInput
                        defaultValue={values?.TerminalPrefix}
                        focus
                        label={"Terminal Prefix"}
                        id={"terminalPrefix"}
                        name={"TerminalPrefix"}
                        type="text"
                        value={values?.TerminalPrefix}
                        validationError={errors.TerminalPrefix}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterTextArea
                        label={"Address"}
                        id={"address"}
                        name={"Address"}
                        value={values?.Address}
                        defaultValue={values?.Address ?? ""}
                        type="text"
                        required={true}
                        validationError={errors.Address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterTextArea>
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                      <FormGroup>
                        <Label>Integration Mode</Label>

                        <ClusterDropdown
                          defaultValue={values?.IntegrationType}
                          value={values?.IntegrationType}
                          placeholder={"-Select-"}
                          items={Utils.Enums.toOptions(IntegrationType)}
                          name={"IntegrationType"}
                          onChange={(name, value) => {
                            setFieldValue(
                              "IntegrationType",
                              IntegrationType[value]
                            )
                            setIsBankOneIntegrated(
                              Utils.Enums.compare(
                                IntegrationType,
                                value,
                                IntegrationType.BankOne
                              )
                            )
                          }}
                          onBlur={handleBlur}
                          required={true}
                        ></ClusterDropdown>
                      </FormGroup>
                    </Col>

                    <Col lg={4} md={4} sm={6}>
                      <ClusterInput
                        defaultValue={values?.ServiceProviderAccountNumber}
                        value={values?.ServiceProviderAccountNumber}
                        label={"Appzone Account"}
                        id={"appzoneAccount"}
                        name={"ServiceProviderAccountNumber"}
                        required={true}
                        type="text"
                        validationError={errors.ServiceProviderAccountNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterInput
                        defaultValue={values?.AntiMoneyLaunderingLimit}
                        value={values?.AntiMoneyLaunderingLimit}
                        label={"Anti Money Laundering Limit"}
                        id={"antiMoneyLaunderingLimit"}
                        name={"AntiMoneyLaunderingLimit"}
                        onChange={handleChange}
                        type="number"
                        validationError={errors.AntiMoneyLaunderingLimit}
                        onBlur={handleBlur}
                      ></ClusterInput>
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterCheckBox
                        checked={values?.WalletAccountEnabled ?? false}
                        onHandleChange={(name, value) => {
                          setFieldValue("WalletAccountEnabled", value)
                        }}
                        label={"Wallet Account Enabled"}
                        name={"WalletAccountEnabled"}
                        id={"walletAccountEnabled"}
                      ></ClusterCheckBox>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
            <Row style={{ paddingBottom: "50px" }}>
              <Col
                lg={12}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >


                <ClusterButton
                  style={{ justifySelf: "right" }}
                  color={"primary"}
                  addon={"bx bx-right-arrow"}
                  disabled={isUpdate && !touched ? false : !isValid || !touched}
                >
                  Next
                </ClusterButton>
              </Col>
            </Row>
          </ClusterForm>
        )}
      </Formik>
    </React.Fragment>
  )
}

export default InstitutionBasicDetailsForm
