import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  getNetworkManagers,
  getNetworkManagerDetail,
  enableNetworkManager,
  networkManagerPurge,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import withDispose from "components/HOCs/WithDispose"
import NetworkManagerLogic from "../network-manager"
import { EDIT_NETWORK_MANAGER, ENABLE_NETWORK_MANAGER } from "../permission"
import { GET_NETWORK_MANAGERDETAILS } from "helpers/url_helper"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

type NetworkManagerData = {
  ID: Number,
  Code: String,
  Description: String,
  Email: String,
  Gender: String,
  IsActive: Boolean,
  Name: String,
  PhoneNumber: String,
  UserID: String,
  UserName: String,
  Clusters: any[],
  AccountNumber: String
}

const ANMList = props => {
  const {
    networkManagers,
    onGetNetworkManagers,
    error,
    success,
    onGetNetworkManagerDetail,
    networkManagerDetail,
    onPurge,
    totalCount,
    loading,
    onEnableNetworkManager,
    editLoading,
    statusChanged,
  } = props

  const { HEADERS, MapToModalState, MapToRows, SearchCriteria, SearchOptions } =
    NetworkManagerLogic
  const {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
  } = useReportData({
    onGetData: onGetNetworkManagers,
    HEADERS: HEADERS,
    SearchCriteria: SearchCriteria,
  })
  const { institutionsData, institutions } = useInstitutionsData()
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate,params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  
  const [viewedNetworkManager, setViewedNetworkManager] = useState<NetworkManagerData>()

  useEffect(() => {
    populateRows()
  }, [networkManagers])

  useEffect(() => {
    if (networkManagerDetail && Object.keys(networkManagerDetail).length > 0) {
      setmodal(true)
      setViewedNetworkManager(networkManagerDetail)
      setModalData(MapToModalState(networkManagerDetail))
    }
  }, [networkManagerDetail])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  const populateRows = () => {
    let rws = []
    if (networkManagers.length > 0) {
      networkManagers.forEach(networkManager => {
        let row = MapToRows(networkManager)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(networkManager.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetNetworkManagerDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Network Manager"}
            breadcrumbItem={"View Agent Network Managers"}
          />
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_NETWORK_MANAGERDETAILS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={networkManagerDetail.IsActive ? "success" : "secondary"}
          status={networkManagerDetail.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Network Manager Details"}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_NETWORK_MANAGER]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableNetworkManager(
                viewedNetworkManager?.ID,
                !networkManagerDetail.IsActive
              )
            }
            color="secondary"
          >
            {networkManagerDetail.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-anm/" + viewedNetworkManager?.ID}>
            <ClusterButton
              permissions={[EDIT_NETWORK_MANAGER]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>

          <ClusterButton
            type="button"
            color="secondary"
            onHandleClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </ClusterButton>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

ANMList.propTypes = {
  networkManagers: PropTypes.array,
  networkManagerDetail: PropTypes.object,
  onGetNetworkManagers: PropTypes.func,
  onGetNetworkManagerDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  networkManagers: {
    networkManagers,
    error,
    success,
    networkManagerDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  return {
    networkManagers: [...networkManagers],
    error,
    success,
    networkManagerDetail: networkManagerDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetNetworkManagers: query => dispatch(getNetworkManagers(query)),
  onGetNetworkManagerDetail: id => dispatch(getNetworkManagerDetail(id)),
  onPurge: () => dispatch(networkManagerPurge()),
  onEnableNetworkManager: (id, enable) =>
    dispatch(enableNetworkManager(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ANMList))))
