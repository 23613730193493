import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  chargeBackPurge,
  getSubscriptionPlanDetail,
  getSubscriptions,
  subscriptionPlanPurge,
  updateSubscription,
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import SubscriptionLogic from "../volume-covenant"
import { fetcher } from "helpers/api_helper"
import useSWR from "swr"
import ClusterModal from "components/Common/Modals/Modal"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import { GET_ALL_SUBSCRIPTIONS } from "store/subscriptions/actionTypes"
import ClusterDataTable from "components/Common/DataTable"
import { GET_SUBSCRIPTIONS } from "helpers/url_helper"

const SubscriptionHistoryList = props => {
  const {
    subscriptions,
    onGetSubscriptions,
    error,
    success,
    totalCount,
    statusChanged,
    loading,
  } = props
  const {
    HISTORYHEADERS,
    MILESTONEHEADERS,
    MapMilestonesToRows,
    MapHistoryToRows,
    SearchCriteria,
    SearchOptions,
  } = SubscriptionLogic
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [rows, setRows] = useState([])
  const [viewedMilestones, setViewedMilestones] = useState([]);
  const [columns, setColumns] = useState([...HISTORYHEADERS])
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  const maxSize = 10
  const [pageNumber, setPageNumber] = useState(1)
  const [params, setParams] = useState({
    fromDate: startDateStr,
    toDate: endDateStr,
    page: pageNumber,
  })

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate)
  )


  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])


  useEffect(() => {
    populateRows()
  }, [subscriptions, props])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [params])


  //preload
  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [])

  useEffect(() => {
    search(SearchCriteria(params, maxSize))
  }, [statusChanged])

  const populateRows = () => {
    if (subscriptions) {
      let rws = []
      subscriptions.forEach(subscription => {
        let row = MapHistoryToRows(subscription)
        row["action"] = (
          <ClusterButton
            type="button"
            color="primary"
            className="btn-rounded waves-effect waves-light btn-sm"
            onHandleClick={() => onViewDetails(subscription.Milestones)}
          >
            View Milestones
          </ClusterButton>
        )
        rws.push(row)
      })
      setRows(rws)
    }
  }
  const handlePageChange = page => {
    setPageNumber(page)
    let p = { ...params, page }
    search(SearchCriteria(p, maxSize))
  }

  function search(query) {
    onGetSubscriptions(query)
  }

  const onAction = p => {
    if (p["page"]) {
      setPageNumber(+p["page"])
    } else {
      p["page"] = pageNumber
    }
    let prms = { fromDate: params["fromDate"], toDate: params["toDate"], ...p }
    setParams(prms)
    search(SearchCriteria(prms, maxSize))
  }
  function onViewDetails(milestones) {
    setmodal(true)
    setViewedMilestones(milestones)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Subscriptions"}
            breadcrumbItem={"View Subscriptions"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_SUBSCRIPTIONS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          open={modal}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Milestones"}
          append={[
            <MilestonesReport
              key={1}
              columns={MILESTONEHEADERS}
              rows={
                !viewedMilestones || viewedMilestones.length == 0
                  ? []
                  : viewedMilestones?.map(option => MapMilestonesToRows(option))

              }
            />,
          ]}
        >

          <ClusterButton
            type="button"
            color="secondary"
            onHandleClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </ClusterButton>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}
const MilestonesReport = props => {
  if (props.rows.length > 0) {
    return (
      <ClusterDataTable
        columns={props.columns}
        loading={false}
        rows={props.rows}
      ></ClusterDataTable>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}
SubscriptionHistoryList.propTypes = {
  subscriptions: PropTypes.array,
  onGetSubscriptions: PropTypes.func,
  onGetSubscriptionDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  subscriptions: {
    subscriptions,
    error,
    success,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  return {
    subscriptions,
    error,
    success,
    totalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetSubscriptions: query => dispatch(getSubscriptions(query)),
  onPurge: () => dispatch(subscriptionPlanPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(SubscriptionHistoryList))))
