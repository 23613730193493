import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, Collapse } from "reactstrap"
import ClusterCheckBox from "components/Common/CheckBox"
import withDispose from "components/HOCs/WithDispose"

const FunctionItem = props => {
  let { data, onUpdateSelection } = props
  const [myData, setData] = useState(data)
  const [children, setChildren] = useState(data?.children)
  const [transform, setTransform] = useState("rotateZ(0deg)")
  const onSelect = title => {
    if (title === "") {
      data.isChecked = !data.isChecked
      if (data.children.length > 0) {
        data.children = data.children.map(child => {
          child.isChecked = data.isChecked
          onUpdateSelection(child.id, child.isChecked, false)
          return child
        })
        if (
          data.children.every(child => {
            return child.isChecked == false
          })
        )
          data.isChecked = false
      }
    } else {
      if (data.children.length > 0) {
        let childrenCount = data.children.length
        data.children = data.children.map(child => {
          if (child.title === title) {
            child.isChecked = !child.isChecked
            onUpdateSelection(child.id, child.isChecked, false)
          }
          return child
        })
        if (
          data.children.every(child => {
            return child.isChecked == false
          })
        )
          data.isChecked = false
        if (
          data.children.every(child => {
            return child.isChecked == true
          })
        )
          data.isChecked = true
      }
    }
    onUpdateSelection(
      data.id,
      data.children.some(child => {
        return child.isChecked == true
      }),
      true
    )
    setChildren(data.children)
    setData(data)
  }

  const [col, setcol] = useState(false)
  let k = Math.random()
  useEffect(() => {
    setData(data)
    setChildren(data?.children)
  }, [data])
  return data ? (
    <li>
      <div
        className="title"
        onClick={() => {
          setcol(!col)
          if (col == false) {
            setTransform("rotateZ(90deg)")
          } else {
            setTransform("rotateZ(0deg)")
          }
        }}
      >
        <Row>
          <Col
            lg={1}
            md={1}
            xs={1}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div style={{ transform: transform }}>
              <i className="bx bx-right-arrow text-secondary" />
            </div>
          </Col>

          <Col lg={1} md={1} xs={1}>
            <ClusterCheckBox
              onHandleChange={(name, value) => onSelect("")}
              label={""}
              name={"parent"}
              id={`customcheck_${k}`}
              checked={myData.isChecked ? myData.isChecked : false}
              disabled={
                props.disabled ? props.disabled : !myData.canAdd ? true : false
              }
            ></ClusterCheckBox>
          </Col>
          <Col lg={1} md={1} xs={1}>
            <div>
              <i className="bx bxs-folder bx-xs text-warning" />
            </div>
          </Col>

          <Col lg={9} md={9} xs={9}>
            <h5 className="text-truncate font-size-14 mb-1">
              <Link to="#" className="text-dark">
                {myData.title}
              </Link>
            </h5>
          </Col>
        </Row>
      </div>
      {/* </CardHeader> */}
      <Collapse isOpen={col}>
        {children.map((child, key) => {
          return (
            <Row
              className={`function-item ${
                child.isChecked ? "text-primary" : ""
              }`}
              key={`functionitem_${key}`}
            >
              <Col lg={1} md={1}></Col>
              <Col lg={1} md={1} xs={1}></Col>

              <Col lg={1} md={1} xs={1}>
                <ClusterCheckBox
                  onHandleChange={(name, value) => onSelect(child.title)}
                  label={""}
                  name={child.title}
                  id={`customcheck_${child.title.toString().replace(" ", "_")}`}
                  checked={child.isChecked}
                  disabled={
                    props.disabled
                      ? props.disabled
                      : !child.canAdd
                      ? true
                      : false
                  }
                ></ClusterCheckBox>
              </Col>
              <Col lg={1} md={1} xs={1}>
                <div>
                  <i
                    className={`bx ${
                      child.icon ? "bx-" + child.icon : "bxs-file"
                    } bx-xs `}
                  />
                </div>
              </Col>

              <Col lg={8} md={8} xs={8}>
                <h5 className="text-truncate font-size-14 mb-1">
                  <Link
                    to="#"
                    className={`${
                      child.isChecked ? "text-primary" : "text-dark"
                    }`}
                  >
                    {child.title}
                  </Link>
                </h5>
              </Col>
            </Row>
          )
        })}
      </Collapse>{" "}
      {/* </Card> */}
    </li>
  ) : (
    <React.Fragment></React.Fragment>
  )
}

FunctionItem.propTypes = {
  data: PropTypes.object,
  onUpdateSelection: PropTypes.func,
  disabled: PropTypes.bool,
}

export default withDispose(FunctionItem)
