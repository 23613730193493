import React, { useState, useEffect, useMemo } from "react"

import {
  ENABLE_MOBILEACCOUNTS,
  GENERATE_ACTIVATION_CODE,
  GENERATE_VERIFICATION_CODE,
  HOTLIST,
  RESET_PIN,
  RESET_PIN_TRIES,
} from "./permission"
import ClusterButton from "components/Common/Button/Button"
const Actions = props => {
  const {
    account,
    onHotList,
    onResetPin,
    onResetPinTries,
    onResetPasswordTries,
    onResetPassword,
    onEnableMobileAccount,
    onActivation,
    onVerification,
    editLoading,
    isEnabled,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    hotListLoading,
    verificationLoading,
    activationLoading,
  } = props
  return (
    <React.Fragment>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <ClusterButton
          loading={resetPinLoading}
          permissions={[RESET_PIN]}
          type="button"
          outline
          onHandleClick={() => onResetPin(account?.ID)}
          color="secondary"
        >
          Reset Pin
        </ClusterButton>
      </div>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <ClusterButton
          loading={resetPinTriesLoading}
          permissions={[RESET_PIN_TRIES]}
          type="button"
          outline
          onHandleClick={() => onResetPinTries(account?.ID)}
          color="secondary"
        >
          Reset Pin Tries
        </ClusterButton>
      </div>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <ClusterButton
          loading={resetPasswordLoading}
          permissions={[RESET_PIN]}
          type="button"
          outline
          onHandleClick={() => onResetPassword(account?.ID)}
          color="secondary"
        >
          Reset Login Pin
        </ClusterButton>
      </div>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <ClusterButton
          loading={resetPasswordTriesLoading}
          permissions={[RESET_PIN_TRIES]}
          type="button"
          outline
          onHandleClick={() => onResetPasswordTries(account?.ID)}
          color="secondary"
        >
          Reset Login Pin Tries
        </ClusterButton>
      </div>
      <div style={{ display: "inline-block", marginRight: "10px" }}>
        <ClusterButton
          loading={editLoading}
          permissions={[ENABLE_MOBILEACCOUNTS]}
          type="button"
          outline
          onHandleClick={() =>
            onEnableMobileAccount(account?.ID, !account?.IsActive)
          }
          color="secondary"
        >
          {account?.IsActive ? "Disable" : "Enable"}
        </ClusterButton>
      </div>
      {account?.MobileAccountStatus != "HotListed" ? (
        <React.Fragment>
          <div style={{ display: "inline-block", marginRight: "10px" }}>
            <ClusterButton
              loading={verificationLoading}
              permissions={[GENERATE_VERIFICATION_CODE]}
              type="button"
              outline
              onHandleClick={() => onVerification(account?.ID)}
              color="warning"
            >
              Generate Verification Code
            </ClusterButton>
          </div>
          {account?.MobileAccountStatus == "New" && account?.Verified ? (
            <div style={{ display: "inline-block", marginRight: "10px" }}>
              <ClusterButton
                loading={activationLoading}
                permissions={[GENERATE_ACTIVATION_CODE]}
                type="button"
                outline
                onHandleClick={() => onActivation(account?.ID)}
                color="success"
              >
                Send Activation Code
              </ClusterButton>
            </div>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </React.Fragment>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Actions
