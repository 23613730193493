export const ENABLE_AGENTS = "ENABLE_AGENTS"
export const RESET_AGENT_LIMIT = "RESET_AGENT_LIMIT"
export const GENERATE_POS_ACCOUNT = "GENERATE_POS_ACCOUNT"
export const DEVICE_RESET = "DEVICE_RESET"
export const UPGRADE_TO_SUPER_AGENT = "UPGRADE_TO_SUPER_AGENT"
export const LINK_TO_POS = "LINK_TO_POS"
export const VIEW_AGENTS = "VIEW_AGENTS"
export const EDIT_AGENT = "EDIT_AGENT"
export const CREATE_AGENT = "CREATE_AGENT"
export const VIEW_AGENT_DETAILS = "VIEW_AGENT_DETAILS"
export const DELETE_AGENT = "DELETE_AGENT"
export const MODIFY_AGENT_SENSITIVE_DETAILS = "MODIFY_AGENT_SENSITIVE_DETAILS"
