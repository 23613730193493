
import React, { useEffect, useMemo, useState } from "react"

import { Col, Collapse, FormGroup, Label, Row } from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import ClusterCheckBox from "components/Common/CheckBox"
import { CashOutInstitution } from "store/cashout-institution/reducer"
import { ConfigurationFormProps } from "."
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { Utils } from "helpers/utility"
import { SettlementMode } from "enums/settlement-mode"
import { Link } from "react-router-dom"
import InstitutionLogic from "../../institution"
import AccountSetupModal from "./account-form"
import Loading from "components/Common/Loading"
import { SettlementAccountType } from "enums/settlement-account-type"
import { fetcher } from "helpers/api_helper"
import { GET_ROUTES, GET_SETTLEMENT_ACCOUNTS } from "helpers/url_helper"
import useSWR from "swr"
import { DropdownType } from "enums/dropdown-type"

type CashOutServiceFormProps = ConfigurationFormProps & {
    values: CashOutInstitution
}
const CashOutServiceForm: React.FC<CashOutServiceFormProps> = props => {
    const {
        values,
        errors,
        handleChange,
        institutionCode,
        account
    } = props

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggle = () => setIsModalOpen(!isModalOpen);
    const [transform, setTransform] = useState<string>();
    const [details, setDetails] = useState(values);
    const { MapToDropDown } = InstitutionLogic
    const accounts = useSWR(`${GET_SETTLEMENT_ACCOUNTS}/${institutionCode}?type=${Utils.Enums.toString(SettlementAccountType, SettlementAccountType.Corebanking)}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
    })
    const routes = useSWR(`${GET_ROUTES}`, fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false
    })

    useEffect(() => {
        setDetails(values);
    }, [values])

    const accountData = useMemo(() => {
        const accts = (accounts.data as any)?.Data ?? [];
        if (!Utils.Object.isEmpty(account) && !accts.some(a => {
            return a.AccountNumber == account.AccountNumber && a.BankCode == account.BankCode && a.InstitutionCode == a.InstitutionCode
        })) {
            accts.push(account);
            return accts;
        }
        return accts;
    }, [account, accounts.data, institutionCode])

    return <React.Fragment> <div

        className={`title p-2 accordion-header box-shadow ${isOpen ? "active" : ""}`}
        style={{ borderRadius: "5px" }}
        onClick={() => {
            setIsOpen(!isOpen)

            if (!isOpen) {
                setTransform("rotateZ(90deg)")
            } else {
                setTransform("rotateZ(0deg)")
            }
        }}
    >
        <Row>
            <Col lg={11} md={11} xs={11}>
                <h3>Card Cash Out</h3>
            </Col>
            <Col
                lg={1} md={1} xs={1}
                className="pl-3"
                style={{ display: "flex", alignItems: "center" }}
            >
                <i className={`bx bxs-right-arrow text-secondary`} style={{ transform: transform }} />

            </Col>
        </Row>
    </div>
        <Collapse className="p-4" isOpen={isOpen}>
            <React.Fragment>

                <div className="bg-danger" style={{ position: "absolute", top: "2.5%", right: "2%", color: "white", width: "20px", height: "20px", borderRadius: "2px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <i className="bx bx-x" />
                </div>
                <Row className="mb-3 mt-3">
                    <Col lg={12} md={12} sm={12}>
                        <b><h5>Settlement</h5></b>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} md={12} sm={12}>

                        <React.Fragment>

                            <Row>
                                <Col lg={3} md={3} sm={6}>
                                    <FormGroup>
                                        <Label>Settlement Mode</Label>

                                        <ClusterDropdown
                                            id={"settlementMode"}
                                            name={"SettlementMode"}
                                            placeholder={"-Select-"}
                                            defaultValue={details?.SettlementMode}
                                            value={details?.SettlementMode}
                                            items={Utils.Enums.toOptions(SettlementMode)}
                                            required
                                            onChange={(name, value) => {
                                                handleChange("SettlementMode", SettlementMode[value])
                                            }}
                                        />

                                    </FormGroup>
                                </Col>
                                {details?.SettlementMode == 'Wallet' ?
                                    <Col lg={3} md={3} sm={6}>
                                        <FormGroup>
                                            <Label>Routes</Label>

                                            {!routes.isValidating ?
                                                <ClusterDropdown type={DropdownType.MultiSelect}
                                                    placeholder={"-Select a Route-"}
                                                    items={MapToDropDown((routes?.data as any)?.Data)}
                                                    defaultValue={details?.WalletTopUpSupportedRoutes}
                                                    onChange={(name, value) => {
                                                        handleChange("WalletTopUpSupportedRouteIDs", value.split(",").map(Number));
                                                    }}
                                                    name={'WalletTopUpSupportedRouteIDs'}
                                                    validationError={errors?.WalletTopUpSupportedRouteIDs}
                                                    required
                                                    ></ClusterDropdown>
                                                : <Loading></Loading>
                                            }

                                        </FormGroup>
                                    </Col > : <></>
                                }
                                <Col lg={3} md={3} sm={6}>
                                    <ClusterInput
                                        defaultValue={details?.MaxRetryCount}
                                        value={details?.MaxRetryCount}
                                        label={"Maximum retries"}
                                        id={"maxRetryCount"}
                                        name={"MaxRetryCount"}
                                        type="number"
                                        min={1}
                                        required={true}
                                        onHandleChange={handleChange}
                                        validationError={errors?.MaxRetryCount}
                                    />
                                </Col>
                                <Col lg={3} md={3} sm={6}>
                                    <Col lg={12} md={12} sm={12} className="m-2">
                                        <ClusterCheckBox
                                            checked={details?.UseNewReferenceForRetrials ?? false}
                                            onHandleChange={handleChange}
                                            label={"Use new reference for retrials"}
                                            name={"UseNewReferenceForRetrials"}
                                            id={"useNewReferenceForRetrials"}
                                        />
                                    </Col>

                                    <Col lg={12} md={12} sm={12} className="m-2">

                                        <ClusterCheckBox
                                            checked={!details?.BatchPostingApiNotSupported ?? false}
                                            onHandleChange={(name, value) => { handleChange("BatchPostingApiNotSupported", !value) }}
                                            label={"Allow Batch Posting"}
                                            name={"BatchPostingApiNotSupported"}
                                            id={"batchPostingApiNotSupported"}
                                        />
                                    </Col>
                                </Col>
                            </Row>
                        </React.Fragment>


                    </Col>

                </Row>

                <Row className="mb-3 mt-3">
                    <Col lg={12} md={12} sm={12}>
                        <b><h5>Switching</h5></b>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3} md={3} sm={6}>
                        <ClusterInput
                            defaultValue={details?.RID}
                            value={details?.RID}
                            label={"Receiving Institution ID"}
                            id={"RID"}
                            name={"RID"}
                            type="number"
                            onHandleChange={handleChange}
                            validationError={errors?.RID}
                        />
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <FormGroup>

                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Label>Lead Settlement Account</Label>
                                <div id="lead-settlement-account" onClick={() => toggle()} style={{ display: "flex", alignItems: "center", padding: 0, border: 0 }} className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2" >

                                    <i className="bx bx-plus" style={{ marginRight: "5px" }}></i>
                                    Add Account

                                </div>

                            </div>
                            {accounts.isValidating ? <Loading /> : <ClusterDropdown
                                id={"settlementAccount"}
                                name={"SettlementAccountID"}
                                placeholder={"-Select-"}
                                defaultValue={details?.SettlementAccount ? `${details?.SettlementAccount?.BankName} ${details?.SettlementAccount?.AccountNumber}` : ""}
                                value={details?.SettlementAccount ? `${details?.SettlementAccount?.BankName} ${details?.SettlementAccount?.AccountNumber}` : ""}
                                items={accountData.map(item => ({ id: item.ID, key: item.ID, value: `${item.BankName} ${item.AccountNumber}`, name: `${item.BankName} ${item.AccountNumber}` }))}
                                onChange={(name, value) => {
                                    handleChange("SettlementAccountID", value || null)
                                }}
                                validationError={errors?.SettlementAccountID}
                            />}

                        </FormGroup>
                    </Col>

                    <Col lg={3} md={3} sm={6}>
                        <div style={{ width: "100%", display: "flex", justifyContent: "right", alignItems: "bottom" }}>
                            <Link to="/routing-configuration" style={{ display: "flex", alignItems: "center", padding: 0, border: 0 }} className="btn btn-outline-primary waves-effect waves-light mb-2" >

                                <i className="bx bxs-wrench" style={{ marginRight: "5px" }}></i>
                                Configure Bin Routes

                            </Link>
                        </div>
                    </Col>

                </Row>

            </React.Fragment>
        </Collapse >

        <AccountSetupModal isOpen={isModalOpen} toggle={toggle} />
    </React.Fragment >

}


export default CashOutServiceForm