import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, Card } from "reactstrap"
import ClusterCheckBox from "components/Common/CheckBox"
import withDispose from "components/HOCs/WithDispose"
const AccordionHeader = props => {

    let { label, name, id, value, checked, onChange, onCollapse, isOpen, disabled, icon } = props
    const [isChecked, setChecked] = useState(checked)
    const [transform, setTransform] = useState("rotateZ(0deg)")


    const [open, setIsOpen] = useState(isOpen ?? false)
    const [alignmentStyle] = useState({ display: "flex", justifyContent: "left", alignItems: "center", cursor: "pointer" })

    useEffect(() => {
        setChecked(checked)
    }, [checked])
    const toggle=(name,isChecked)=>{
        setChecked(isChecked)
        onChange(name, value);
    }
    return <div

        className={`title pt-2 pb-2 accordion-header ${open ? "active" : ""}`}
        style={{ borderRadius: "5px" }}
        onClick={() => {
            setIsOpen(!open)
            onCollapse(!open)
            if (!open) {
                setTransform("rotateZ(90deg)")
            } else {
                setTransform("rotateZ(0deg)")
            }
        }}
    >
        <Row>
            <Col
                lg={1} md={1} xs={1}
                className="pl-3"
                style={{ display: "flex", alignItems: "center" }}
            >
                <i className={`bx bxs-right-arrow ${!open ? "text-secondary" : "text-primary"}`} style={{ transform: transform }} />

            </Col>

            <Col lg={11} md={11} xs={11}>
                <div style={alignmentStyle}>

                    {/* <ClusterCheckBox
                        onHandleChange={(name,checked) => { toggle(name,checked); }}
                        label={""}
                        name={name}
                        id={`customcheck_${id}`}
                        checked={isChecked}
                        disabled={disabled}
                    /> */}
                    {icon ? <i className={`bx ${icon} bx-xs mr-2 ml-3 `} /> : null}

                    <h5 className={`text-truncate font-size-14 mb-0 `}>
                        <b>{label}</b>
                    </h5>
                </div>
            </Col>
        </Row>

    </div>

}

AccordionHeader.propTypes = {
    data: PropTypes.object,
    onUpdateSelection: PropTypes.func,
    disabled: PropTypes.bool,
}

export default withDispose(AccordionHeader)
