import ClusterStorage from "helpers/storage-helper";
import moment from "moment";

const startDateKey = "Dashboard-StartDate";
const endDateKey = "Dashboard-EndDate";
const dashboardActiveTabTKey = "Dashboard-Active-Tab"
const DashboardHelper = {

    getStartDate: () => (ClusterStorage.getItem(startDateKey) ?? moment().local().startOf("day").format("YYYY-MM-DDTHH:mm:ss")),
    getEndDate: () => (ClusterStorage.getItem(endDateKey) ?? moment().local().endOf("day").format("YYYY-MM-DDTHH:mm:ss")),
    setStartDate: ((date) => ClusterStorage.setItem(startDateKey, date)),
    setEndDate: ((date) => ClusterStorage.setItem(endDateKey, date)),
    reset: () => {

    },
    setActiveTab: (tab) => ClusterStorage.setItem(dashboardActiveTabTKey, tab),
    getActiveTab: () => (ClusterStorage.getItem(dashboardActiveTabTKey) ?? "Today")
}
export default DashboardHelper;