import {
  GET_BRANCHES,
  API_ERROR,
  GET_BRANCH_SUCCESS,
  POST_BRANCH_SUCCESS,
  CREATE_BRANCH,
  GET_BRANCH_DETAILS_SUCCESS,
  GET_BRANCH_DETAILS,
  PURGE_BRANCHES_SUCCESS,
  UPDATE_BRANCH_SUCCESS,
  UPDATE_BRANCH,
  ENABLE_BRANCH_SUCCESS,
  ENABLE_BRANCH,
} from "./actionTypes"
import { defaultObj } from "helpers/utility"
const initialState = {
  error: "",
  branches: [],
  branch: {},
  loading: true,
  editLoading: false,
  success: undefined,
  statusChanged: undefined,
}

const Branches = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANCHES:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_BRANCH:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case UPDATE_BRANCH:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case UPDATE_BRANCH_SUCCESS:
      state = {
        ...state,
        success: action.payload?.message,
        editLoading: false,
        error: "",
      }
      break
    case GET_BRANCH_SUCCESS:
      return {
        ...state,
        branches: [...action.payload],
        loading: false,
        error: "",
      }
    case GET_BRANCH_DETAILS:
      state = {
        ...state,
        loading: true,
      }
      break
    case GET_BRANCH_DETAILS_SUCCESS:
      return {
        ...state,
        branch: action.payload,
        loading: false,
        error: "",
      }
    case POST_BRANCH_SUCCESS:
      return {
        ...state,
        branch: defaultObj(state.branch), //action.payload,
        error: "",
        success: action.payload?.message,
        editLoading: false,
      }
    case PURGE_BRANCHES_SUCCESS:
      return {
        ...state,
        branch: {},
        error: "",
        success: undefined,
        loading: true,
      }
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break
    case ENABLE_BRANCH:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case ENABLE_BRANCH_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        statusChanged: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}
export default Branches
