/* SUBSCRIPTION_PLANS */
export const GET_SUBSCRIPTION_PLANS = "GET_SUBSCRIPTION_PLANS"
export const GET_ALL_SUBSCRIPTION_PLANS = "GET_ALL_SUBSCRIPTION_PLANS"
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const GET_ALL_SUBSCRIPTIONS = "GET_ALL_SUBSCRIPTIONS"
export const GET_SUBSCRIPTIONS_SUCCESS =
  "GET_SUBSCRIPTIONS_SUCCESS"
export const GET_SUBSCRIPTIONS_FAIL = "GET_SUBSCRIPTIONS_FAIL"
export const GET_SUBSCRIPTION_PLANS_BY_CATEGORY =
  "GET_SUBSCRIPTION_PLANS_BY_CATEGORY"
export const GET_SUBSCRIPTION_PLANS_SUCCESS =
  "GET_SUBSCRIPTION_PLANS_SUCCESS"
export const GET_SUBSCRIPTION_PLANS_FAIL = "GET_SUBSCRIPTION_PLANS_FAIL"

export const GET_SUBSCRIPTION_PLAN_DETAIL = "GET_SUBSCRIPTION_PLAN_DETAIL"
export const GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS =
  "GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS"
export const GET_SUBSCRIPTION_PLAN_DETAIL_FAIL =
  "GET_SUBSCRIPTION_PLAN_DETAIL_FAIL"

export const SAVE_SUBSCRIPTION_PLAN = "SAVE_SUBSCRIPTION_PLAN"
export const ADD_SUBSCRIPTION_PLAN_OPTION = "ADD_SUBSCRIPTION_PLAN_OPTION"
export const ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS =
  "ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS"

export const REMOVE_SUBSCRIPTION_PLAN_OPTION = "REMOVE_SUBSCRIPTION_PLAN_OPTION"
export const REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS=
  "REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS"

export const POST_SUBSCRIPTION_PLAN_SUCCESS =
  "POST_SUBSCRIPTION_PLAN_SUCCESS"
export const POST_SUBSCRIPTION_PLAN_FAIL = "POST_SUBSCRIPTION_PLAN_FAIL"

export const SUBSCRIPTION_PLAN_PURGE = "SUBSCRIPTION_PLAN_PURGE"
export const SUBSCRIPTION_PLAN_PURGE_SUCCESS =
  "SUBSCRIPTION_PLAN_PURGE_SUCCESS"

export const UPDATE_SUBSCRIPTION_PLAN = "UPDATE_SUBSCRIPTION_PLAN"
export const UPDATE_SUBSCRIPTION_PLAN_SUCCESS =
  "UPDATE_SUBSCRIPTION_PLAN_SUCCESS"
export const UPDATE_SUBSCRIPTION_PLAN_FAIL =
  "UPDATE_SUBSCRIPTION_PLAN_FAIL"

export const ENABLE_SUBSCRIPTION_PLAN = "ENABLE_SUBSCRIPTION_PLAN"
export const ENABLE_SUBSCRIPTION_PLAN_SUCCESS =
  "ENABLE_SUBSCRIPTION_PLAN_SUCCESS"
