import PropTypes from "prop-types"
import React from "react"
import composedWithGuard from "components/HOCs/WithGuard"
import ClusterPage from "components/Common/Page"

const GuardedPage:React.FC<any> = (props) => {
  const { children, ...otherProps } = props
  return <ClusterPage {...otherProps}> {children}</ClusterPage>
}

GuardedPage.propTypes = {
  permissions: PropTypes.array,
  children:PropTypes.any
}
export default composedWithGuard(GuardedPage)
