import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { TabPane, Row, Container, Col, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"

import ComparativeCard from "./card"
import withDispose from "components/HOCs/WithDispose"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Search from "../search"
import { useDashboardView } from "hooks/useDashboardView"
const COMPARE = [
  {
    label: "Agent Network Manager",
    tabs: [
      { id: 0, label: "Volume", type: "topPerformingVolComparativesByAnm" },
      { id: 1, label: "Count", type: "topPerformingCountComparativesByAnm" },
    ],
    color:"pink"
  }, {
    label: "State",
    tabs: [
      { id: 0, label: "Volume", type: "topPerformingVolComparativesByState" },
      { id: 1, label: "Count", type: "topPerformingCountComparativesByState" },
    ],
    color:"green"
  },
  {
    label: "Cluster",
    tabs: [
      { id: 0, label: "Volume", type: "topPerformingVolComparativesByCluster" },
      { id: 1, label: "Count", type: "topPerformingCountComparativesByCluster" },
    ],
    color:"orange"
  },
  {
    label: "Agent Category",
    tabs: [
      { id: 0, label: "Volume", type: "topPerformingVolComparativesByCategory" },
      { id: 1, label: "Count", type: "topPerformingCountComparativesByCategory" },
    ],
    color:"purple"
  },
]
const Comparatives = () => {
  
  const {data} = useDashboardView();

  let [startDate, setStartDateStr] = useState()
  let [endDate, setEndDateStr] = useState()
 
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ position: "relative" }}>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Comparatives  (Top 10)"}
            noCrumb={true}
          />
          <Search
            startDateStr={startDate}
            endDateStr={endDate}
            setStartDateStr={setStartDateStr}
            setEndDateStr={setEndDateStr}
          ></Search>
          <Row>
            {COMPARE.map((c, key) => (
              <Col lg={6} md={12} sm={12} key={`COMP_${key}`}>
                <Card
                  className={" divider-top-" + c.color}
                  style={{ paddingTop: "15px", paddingBottom: "0px", position: "relative" }}
                >
                  <CardSubtitle
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      position: "relative",
                    }}
                  >
                    {/* <small> */}
                      <b>{c.label}</b>
                    {/* </small> */}
                  </CardSubtitle>
                  <CardBody>
                    <ComparativeTabContent
                  startDateStr={startDate}
                  endDateStr={endDate}
                      trxType={data[key].type}
                      field={c.tabs[0].type}
                      color={["#50d8d7"]}
                    ></ComparativeTabContent>
                  </CardBody>
                </Card>
            
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

function ComparativeTabContent({
  trxType,
  color,
  field,
  currency,
  type,
  height,
  endDateStr, startDateStr,
}) {
  const handleOnChange = value => { }

  return (
    <React.Fragment>
      <ComparativeCard
        trxType={trxType}
        field={field}
        startDateStr={startDateStr}
        endDateStr={endDateStr}
        yValueLabel="value"
        currency={currency}
        trxStatusFilter
        color={color}
        type={type}
        height={height}
      ></ComparativeCard>
    </React.Fragment>
  )
}

Comparatives.propTypes = {
  trxType: PropTypes.string,
}
export default withDispose(Comparatives)




