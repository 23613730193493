import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_COMMISSIONS,
  COMMISSION_PURGE,
  GET_ALL_COMMISSIONS,
} from "./actionTypes"
import {
  getCommissionsSuccess,
  getCommissionsFail,
  commissionPurgeSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getCommissions, getAllCommissions } from "helpers/backend_helper"
const format = "yyyy-MM-DD"
function* fetchCommissions({ params }) {
  try {
    const response = yield call(getCommissions, JSON.parse(params))
    yield put(getCommissionsSuccess(response))
  } catch (error) {
    console.debug("Fetching Commissions Error", error)
    yield put(getCommissionsFail(error))
  }
}
function* fetchAllCommissions() {
  try {
    const response = yield call(getAllCommissions)
    yield put(getCommissionsSuccess(response))
  } catch (error) {
    console.debug("Fetching All Commissions Error", error)
    yield put(getCommissionsFail(error))
  }
}
function* doPurge() {
  yield put(commissionPurgeSuccess())
}

function* commissionsSaga() {
  yield takeLeading(GET_COMMISSIONS, fetchCommissions)
  yield takeLeading(GET_ALL_COMMISSIONS, fetchAllCommissions)
  yield takeEvery(COMMISSION_PURGE, doPurge)
}

export default commissionsSaga
