import React from "react"
import { Formik } from "formik";
import ClusterForm from "components/Common/Form";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import AccordionToggle, { Toggler } from "components/Common/Accordion/Toggle"
import ClusterButton from "components/Common/Button/Button";
import { Icons } from "enums/icons";
import InstitutionLogic, { Institution } from "../../institution"
import Loading from "components/Common/Loading";
import withFormValidation from "components/HOCs/WithFormValidation";
import { Utils } from "helpers/utility";

const InstitutionFeaturesForm = (props) => {

    const {
        isUpdate,
        institution,
        onNext,
        onPrev,
        loading,
        features,
        featuresLoading,
        editLoading
    } = props

    const { MapFeatures } = InstitutionLogic
    if (Utils.List.isEmpty(features)) return <Loading>Getting features</Loading>
    return <React.Fragment>

        <Formik
            initialValues={institution}
            validate={(values) => { }}
            onSubmit={(values, { setSubmitting }) => {
                onNext(values);
            }}
        >
            {({
                values,
                errors,
                touched,
                isValid,
                setFieldError,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                isSubmitting,
            }) => (<ClusterForm handleSubmit={handleSubmit}>

                <React.Fragment>

                    <Card style={{ paddingTop: "15px", paddingBottom: "0px", position: "relative" }}
                    >

                        <CardBody>
                            <CardTitle>
                                Cluster Services
                            </CardTitle>
                            <AccordionToggle
                                collapsed={false}
                                parent={{ id: 0, name: "feature", label: "Services", value: 0, checked: (!features || (features && features.length == 0) || !institution) ? false : features?.length == institution?.Features?.length, disabled: true, icon: Icons.Customize }}
                                children={MapFeatures(features, institution, false)}
                                loading={loading || featuresLoading}
                                onChange={(groupName, itemName, itemValue, isChecked: boolean) => {
                                    const isParent = groupName == itemName;
                                    const toggler = Toggler(values.Features ?? [], features, "ID")
                                    if (!isParent) {
                                        const selectedItems = toggler.select(itemValue)
                                        setFieldValue("Features", [...selectedItems]);
                                        setFieldValue("FeatureIds", [...selectedItems.map(feature => feature.ID)]);

                                    }
                                }}
                            />
                        </CardBody>
                    </Card>

                </React.Fragment>




                <Row style={{ paddingBottom: "50px" }}>
                    <Col lg={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <ClusterButton
                            style={{ justifySelf: "right" }}
                            color={"light"}
                            addon={"bx bxs-left-arrow"}
                            onHandleClick={() => onPrev()}
                            type={"button"}
                        >
                            Prev
                        </ClusterButton>
                        <ClusterButton
                            loading={editLoading || isSubmitting}
                            color={"primary"}
                            addon={"bx bx-save"}
                            style={{ justifySelf: "right" }}
                            disabled={(isUpdate && !touched) ? false : (!isValid || !touched)}
                        >
                            {isUpdate ? "Update" : "Save"}
                        </ClusterButton>
                    </Col>
                </Row>

            </ClusterForm>)}
        </Formik>
    </React.Fragment>

}


export default withFormValidation(InstitutionFeaturesForm);
