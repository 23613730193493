import React from "react"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import ClusterButton from "../Button/Button"

export const IdleTimeOutModal = ({
  showModal,
  handleStay,
  handleLogout,
  loading,
  remainingTime,
}) => {
  return (
    <Modal isOpen={showModal} toggle={() => handleStay}>
      <ModalHeader>
        <div>You Have Been Idle!</div>
      </ModalHeader>
      <ModalBody>You Will Get Timed Out. You want to stay?</ModalBody>
      <ModalFooter>
        <ClusterButton color="danger" onHandleClick={handleLogout}>
          Logout
        </ClusterButton>
        <ClusterButton
          color="primary"
          loading={loading}
          onHandleClick={handleStay}
        >
          Stay
        </ClusterButton>
      </ModalFooter>
    </Modal>
  )
}
