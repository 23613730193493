export {}
export enum ReportType {
  AgentSummary = "Agent Transaction Summary Report",
  AgentVolume = "Agent Transaction Volume Comparatives Report",
  CardTransfer = "Card Transfer Transactions",
  Collections = "Collections Report",
  FundsTransfer = "Funds Transfer Transactions",
  POSCashOut = "Agent POS CashOut Transactions",
  PayWithTransfer = "Pay With Transfer Transactions",
  Transactions = "Transactions Report",
}
