import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Row, Col, Alert } from "reactstrap"

import Report from "./report"

import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"
import TerminalLogic from "../../unsettled-transaction"
import ClusterButton from "components/Common/Button/Button"
import { processUploadTerminal, processUploadUnsettledTransaction, purgeTerminals, purgeUnsettledTransactions } from "store/actions"
import { toast } from "react-toastify"
import { Utils } from "helpers/utility"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"



const ProcessUnsettledTransactionsList = props => {
  const {
    unsettledTransactions,
    summaryText,
    onProcessUpload,
    setErrorList,
    unsettledTransactionUpload,
    isValidList,
    error,
    success,
    saved,
    onPurge,
    totalCount,
    editLoading,
    loading,
    history
  } = props

  const { HEADERS, MapToRows, SearchOptions, SearchCriteria } = TerminalLogic;
  const { institutionsData, institutions } = useInstitutionsData()
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, params, setRows
  } = useReportData({ HEADERS: HEADERS, SearchCriteria : SearchCriteria })
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  
  useEffect(() => {

    if (success) {
      toast.success(success);
      if (history && isSubmitting) history.push("/approve-unsettled-transactions");
      setIsSubmitting(false)
    }
    if (error) toast.error(error)
  }, [error, success])

  useEffect(() => {
    populateRows()
  }, [unsettledTransactions])

  useEffect(() => {
    if (saved) onPurge();
  }, [saved])

  const populateRows = () => {
    if (!Utils.List.isEmpty(unsettledTransactions)) {
      let rws = []
      unsettledTransactions.forEach(transactions => {
        let row = MapToRows(transactions);
        rws.push(row)
      })

      setRows(rws)
    }
  }

  const processUpload = (e) => {
    e.preventDefault();
    onProcessUpload({ ...unsettledTransactionUpload, UnsettledTransactions: unsettledTransactions })
    setIsSubmitting(true);
  }
  const revalidate = (e) => {
    e.preventDefault();
    onPurge()
  }


  return (
    <React.Fragment>
      <Row>
        <Alert color={isValidList ? "success" : "danger"}>
          {isValidList ? "All entries where successfully validated, please proceed to upload" : `${summaryText}: Please review the erratic entries and revalidate`}
        </Alert>
      </Row>
      <Row>
        <Col lg="12">
          <Report
            tableData={{
              columns: columns,
              rows: rows,
              paginationOptions: {
                totalCount: totalCount,
                onPageChange: page => handlePageChange(page),
                pageNumber: pageNumber,
                maxSize: maxSize,
              },
              pageTitle: "Upload Validated Batch",
              isLoading: loading,
            }}

          />
        </Col>
      </Row>
      <ClusterButton
        className={"btn-lg"}
        buttonStyle={{
          width: "100%",
          marginTop: "10px",
          justifyContent: "center",
          height: "4em",
        }}
        color={isValidList ? "success" : "danger"}
        loading={editLoading}
        onHandleClick={isValidList ? processUpload : revalidate}

      >
        {isValidList ? "Upload" : "ReValidate"}
      </ClusterButton>


    </React.Fragment>
  )
}

ProcessUnsettledTransactionsList.propTypes = {
  unsettledTransactions: PropTypes.array,
  unsettledTransactionUpload: PropTypes.object,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  unsettledTransactions: { editLoading, success, error, saved },
}) => {
  return {
    editLoading,
    success,
    error,
    saved
  }
}

const mapDispatchToProps = dispatch => ({

  onPurge: () => dispatch(purgeUnsettledTransactions()),
  onProcessUpload: (payload) => dispatch(processUploadUnsettledTransaction(payload))

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ProcessUnsettledTransactionsList))))
