import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Button,
  Col,
  Container, Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images

//i18n
import { withTranslation } from "react-i18next"
import { settlementPurge, configureOTPReceiver, getWalletSettlementConfiguration } from "store/actions"
import ApproverForm from './form';
import AccountLogic from "../settlement";
import withDispose from 'components/HOCs/WithDispose';
import useSWR from 'swr'
import { GET_ALL_CLUSTER_USERS } from 'helpers/url_helper'
import { authFetcher } from 'helpers/api_helper'
import moment from 'moment'
import { Institution } from 'helpers/authentication/institution'
import { UserScope } from 'enums/user-scope'
import { Utils } from 'helpers/utility'
import Loading from 'components/Common/Loading'
const SettlementApprovalConfiguration = props => {
  const { match, onConfigureApprover,onGetApprover,wallet, success, editLoading, loading, error, approver } = props

  const [errorList, setErrorList] = useState({});
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);


  const users = useSWR(`${GET_ALL_CLUSTER_USERS}?institutionId=${Institution.getID()}`, authFetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })

  const handleValidSubmit = (approver) => {

    onConfigureApprover(approver)

  }


  useEffect(() => {
    onGetApprover();
  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Approval"}
            breadcrumbItem={"Configure OTP Receiver"}
          />

          <ApproverForm
            success={success}
            error={error}
            isUpdate={isUpdate}
            loading={loading}
            errorList={errorList}
            isRotating={isRotating}
            editLoading={editLoading}
            users={(((users?.data) ?? []) as any[]).filter(user => user.userRole?.scope != UserScope.Agent)}
            wallet={wallet}
            setErrorList={setErrorList}
            setIsRotating={setIsRotating}
            handleValidSubmit={handleValidSubmit}
          />


        </Container>
      </div>
    </React.Fragment>
  )
}



SettlementApprovalConfiguration.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onConfigureApprover: PropTypes.func,
  onGetApprover: PropTypes.func,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({ settlements: { accounts, error, success, wallet, editLoading, totalCount, loading } }) => {
  return {
    accounts,
    error,
    success,
    wallet,
    totalCount,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onConfigureApprover: (settlement) => dispatch(configureOTPReceiver(settlement)),
  onGetApprover: () =>dispatch(getWalletSettlementConfiguration(null)),

  onPurge: () => dispatch(settlementPurge())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(SettlementApprovalConfiguration))))




