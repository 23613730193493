import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  commissionStructurePurge,
  saveCommissionStructures,
  getCommissionStructuresByCategory
} from "store/actions"
import CommissionStructureList from "./list/index"
import CreateCommissionStructure from "./create/index"
import withDispose from "components/HOCs/WithDispose"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import { get } from "helpers/api_helper"
import CommissionStructureLogic from "../commission-structure"
import useSWR from "swr"
import {
  Card,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  Container,
} from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import Loading from "components/Common/Loading"
import ClusterButton from "components/Common/Button/Button"
import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { EDIT_COMMISSIONSTRUCTURE } from "../permission"

const fetcher = url => {
  return get(url, {}, true)
}
const CommissionStructure = props => {
  const { structuresToModify, onSaveConfiguration, editLoading, success, isStructureConfigured } = props
  const { MapToDropDown } = CommissionStructureLogic
  const [categoryId, setCategoryId] = useState()
  const [reloadStructure, setReloadStructure] = useState<Boolean>(isStructureConfigured === 1)
  const [category, setCategory] = useState()
  const data = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })

  const categoryData = useMemo(() => {
    return (data?.data as any)?.Data ?? []
  }, [data?.data])

  const onHandleSubmit = e => {
    e.preventDefault()
    setCategory(categoryId)
  }
  const saveConfiguration = (e) => {
    e.preventDefault()
    onSaveConfiguration(structuresToModify)
  }

  useEffect(() => {
    isStructureConfigured === 1 ? setReloadStructure(true) : setReloadStructure(false)
  }, [success])

  return (
    <React.Fragment>
      <div className="page-content">
        {!category ? (
          <React.Fragment>
            <Container fluid>
              <Breadcrumbs
                title={"Commission Structure"}
                breadcrumbItem={"Select an Agent Category"}
              />

              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={5} md={6} sm={12}>
                      <FormGroup>
                        <Label>Agent Categories</Label>
                        {data ? (
                          <ClusterDropdown
                            placeholder={"- Select a category - "}
                            items={MapToDropDown(categoryData)}
                            onChange={(name, value) => {
                              setCategoryId(value)
                            }}
                            name="Agent Category"
                          ></ClusterDropdown>
                        ) : (
                          <Loading></Loading>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ClusterButton
                        onHandleClick={onHandleSubmit}
                        color={"primary"}
                        disabled={categoryId ? false : true}
                      >
                        Next
                      </ClusterButton>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <CreateCommissionStructure
              setCategory={setCategory}
              categoryName={categoryData?.find(c => c.ID == categoryId)?.Name}
              categoryId={categoryId}

            ></CreateCommissionStructure>
            {reloadStructure && (
              <CommissionStructureList
                categoryName={categoryData.find(c => c.ID === categoryId)?.Name}
                categoryId={categoryId}
              />
            )}
            <ClusterButton

              permissions={[EDIT_COMMISSIONSTRUCTURE]}
              className={"btn-lg"}
              buttonStyle={{
                width: "100%",
                marginTop: "10px",
                justifyContent: "center",
                height: "4em",
              }}
              color={"success"}
              loading={editLoading}
              onHandleClick={saveConfiguration}
              disabled={
                structuresToModify && structuresToModify.length > 0
                  ? false
                  : true
              }
            >
              Save Configuration
            </ClusterButton>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

CommissionStructure.propTypes = {
  error: PropTypes.any,
  success: PropTypes.string,
  onSaveConfiguration: PropTypes.func,
  onPurge: PropTypes.func,
  structuresToModify: PropTypes.array,
  isStructureConfigured: PropTypes.number
}

const mapStateToProps = ({
  commissionStructures: {
    editLoading,
    commissionStructures,
    structuresToModify,
    error,
    success,
    isStructureConfigured
  },
}) => {
  return {
    commissionStructures,
    structuresToModify,
    error,
    success,
    editLoading,
    isStructureConfigured
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(commissionStructurePurge()),
  onSaveConfiguration: payload => dispatch(saveCommissionStructures(payload)),
  onGetCommissionStructures: categoryId =>
    dispatch(getCommissionStructuresByCategory(categoryId))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CommissionStructure))))
