import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Container, Row, Col
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { processUploadUnsettledTransaction, purgeTerminals, validateUnsettledTransaction } from "store/actions"
import UploadUnsettledTransactionsForm from './form';
import ProcessBatchUploadList from "./view";
import TerminalLogic from "../../TerminalManagement/batch-upload";
import Instructions from "components/Common/Instruction";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { BATCH_UPLOAD_TERMINAL, EDIT_TERMINAL } from '../../TerminalManagement/permission'
import useSWR from 'swr'
import { fetcher } from 'helpers/api_helper'
import { GET_ALL_INSTITUTIONS } from 'helpers/url_helper'
const UploadUnsettledTransactions = props => {
  const { success, editLoading, loading, error, onPurge, onValidateUpload, processResult } = props
  const { ERRORLIST } = TerminalLogic;
  const [errorList, setErrorList] = useState(ERRORLIST);
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const institutions = useSWR(GET_ALL_INSTITUTIONS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [upload, setUpload] = useState<any>();
  const handleValidSubmit = (unsettledTransactionUpload) => {
    setUpload(unsettledTransactionUpload)
    onValidateUpload(unsettledTransactionUpload)
  }
  const instructions = [
    { position: "First", label: "Terminal ID" },
    { position: "Second", label: "Amount (Naira)" },
    { position: "Third", label: "RRN" },
    { position: "Fourth", label: "Date (YYYYMMDD)" },
    { position: "Fifth", label: "Account" },
    { position: "Sixth", label: "Agent Phone number" },
    { position: "Seventh", label: "Agent Name" },
    { position: "Eight", label: "Transaction Type" },
    { position: "Ninth", label: "Institution Code" },
    { position: "Tenth", label: "POS Type (GAC/Appzone)" },
    { position: "Eleventh", label: "Transaction Date" },
    { position: "Twelfth", label: "Masked PAN" },
    { position: "Thirteenth", label: "STAN" },
    { position: "Fourteenth", label: "Route (NIBSS/Kimono/ISO/Zone)" }
  ];
  return (
    <GuardedPage permissions={[BATCH_UPLOAD_TERMINAL]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Batch Terminal Upload"}
              breadcrumbItem={"Upload"}
            />

            {!processResult ? <Row>
              <Col lg={6} md={6} sm={12}>
                <Instructions description="The uploaded file must be .csv and ensure it is formatted with no spaces. There must be a maximum of 10 records. 
(Ensure ZIP file contains images of the receipts for the corresponding records)" instructions={instructions} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <UploadUnsettledTransactionsForm
                  success={success}
                  error={error}
                  isUpdate={isUpdate}
                  loading={loading}
                  institutions={(institutions?.data as any)?.Data}
                  errorList={errorList}
                  isRotating={isRotating}
                  editLoading={editLoading}
                  setErrorList={setErrorList}
                  setIsRotating={setIsRotating}
                  handleValidSubmit={handleValidSubmit}
                ></UploadUnsettledTransactionsForm>
              </Col>
            </Row> :
              <ProcessBatchUploadList
                setErrorList={setErrorList}
                unsettledTransactions={processResult?.UnsettledTransactions}
                totalCount={processResult?.TotalCount}
                isValidList={processResult?.IsValidList}
                summaryText={processResult?.SummaryText}
                unsettledTransactionUpload={upload}
              />
            }
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>)
}



UploadUnsettledTransactions.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onBatchUpload: PropTypes.func,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({ unsettledTransactions: { processResult, error, success, editLoading, totalCount, loading } }) => {
  return {
    error,
    success,
    processResult,
    totalCount,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onValidateUpload: (data) => dispatch(validateUnsettledTransaction(data)),
  onBatchUpload: (data) => dispatch(processUploadUnsettledTransaction(data)),
  onPurge: () => dispatch(purgeTerminals())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(UploadUnsettledTransactions))))








