import PropTypes from "prop-types"
import React, { useState, useEffect, useCallback, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col, Card, CardBody, Button, Modal, ModalHeader, ModalBody, CardImg } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { getSettlementWithdrawals, withdrawSettlement, sendWithdrawalOTP, settlementPurge, getWalletBalance, getSettlementAccount, getWalletAccountStatementReport } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import WithdrawalLogic from "../settlement"
import { EXPORT_WALLET_ACCOUNT_STATEMENT_REPORT, GET_WALLET_BALANCE } from "helpers/url_helper"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import { Utils } from "helpers/utility"
import { Icons } from "enums/icons"
import PaymentDetailsModal from "./payment-details"
import ConfirmOTPModal from "./confirm-otp"
import { SETTLEMENT_WITHDRAWAL } from "../permission"
import useSWR from "swr"
import Loading from 'components/Common/Loading'
import { Format } from "helpers/variables"
import { fetcher } from "helpers/api_helper"
import DetailsRowItem from "components/Common/DetailsRowItem"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"
import WalletBalanceCard from "./wallet-balance-card"
import { WithdrawalStages } from "enums/withdrawal-stages"


const WalletAccountStatement = props => {
  const {
    walletAccountStatement,
    totalWalletTopUps,
    onGetWalletAccountReport,
    onSettlementWithdrawal,
    otpSending,
    onGetAccounts,
    accounts,
    onSendOTP,
    error,
    success,
    totalCount,
    statusChanged,
    loading,
    editLoading
  } = props
  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = WithdrawalLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, params, setRows, onAction
  } = useReportData({ onGetData: onGetWalletAccountReport,  HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [viewedReport, setViewedReport] = useState(null)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [withdrawalDetails, setWithdrawalDetails] = useState<any>({})

  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate, institutionsData))

  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

  const { data, isValidating } = useSWR(GET_WALLET_BALANCE, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  })

  const [balance, setBalance] = useState({
    wallet: '--',
    pendingSettlement: '--',
    totalWalletTopUp: '--'
  });


  useEffect(() => {
    const balanceData = (data as any)?.Data
    setBalance({
      ...balance, 
      totalWalletTopUp: totalWalletTopUps == null ? "--" : Utils.Format.currency(totalWalletTopUps),
      pendingSettlement: balanceData?.PendingBalance == null ? "--" : Utils.Format.currency(balanceData?.PendingBalance),
      wallet: balanceData?.WithdrawableBalance == null ? "--" : Utils.Format.currency(balanceData?.WithdrawableBalance)
    })
  }, [data,totalWalletTopUps])

  useEffect(() => {
    populateRows()
  }, [walletAccountStatement])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize);
    search(query)
  }, [params])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize);
    search(query)
  }, [statusChanged])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])

  const populateRows = () => {
    if (walletAccountStatement) {
      let rws = []
      walletAccountStatement.forEach(wallet => {
        let row = MapToRows(wallet);
        row["View Details"] = (
          <Button
            outline
            type="button"
            color="primary"
            size="sm"
            className="btn-outline waves-effect waves-light"
            onClick={() => {
              setIsModalOpen(true)
              setModalLoading(true)
              setTimeout(() => {
                setViewedReport(wallet)
                setModalLoading(false)
              }, 1000)
            }}
          >
            View Details
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function search(query) {
    onGetWalletAccountReport(query)
    onGetAccounts()
  }

  const next = (type: WithdrawalStages, formValues?: any, resetForm?: any) => {
    switch (type) {
      case WithdrawalStages.PaymentDetails:
        setShowWithdrawModal(false)
        setShowPaymentDetailsModal(!showPaymentDetailsModal)
        break;
      case WithdrawalStages.ConfirmOTP:
        onSendOTP(formValues)
        setWithdrawalDetails({ ...withdrawalDetails, PaymentDetails: formValues })
        setShowPaymentDetailsModal(false)
        setShowWithdrawModal(!showWithdrawModal);
        break;
      case WithdrawalStages.Withdraw:
        setWithdrawalDetails({ ...withdrawalDetails, ...formValues })


        const withdrawal = {
          Amount: withdrawalDetails.PaymentDetails?.Amount,
          OTP: formValues?.OTP,
          CreditAccountID: withdrawalDetails.PaymentDetails?.CreditAccountID
        }
        onSettlementWithdrawal(withdrawal)
        break;

      default:
        setShowPaymentDetailsModal(false)
        setShowWithdrawModal(false);
        break;
    }
  }

  const resendOTP = () => {
    onSendOTP({ ...withdrawalDetails.PaymentDetails })
  }

  const getDailyWithdrawalCount = useCallback((): number => {
    return (walletAccountStatement ?? []).filter(withdrawal => Utils.Date.isToday(withdrawal.DateUpdated) && withdrawal.Status == 'Successful').length;
  }, [walletAccountStatement])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Settlements"} breadcrumbItem={"Wallet Transaction Report"} />

          <Row className="mb-3">
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.wallet} next={next} isWalletBalance={true} cardTitle="Wallet Balance" />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.pendingSettlement} next={next} isWalletBalance={false} cardTitle="Pending Settlement" />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.totalWalletTopUp} next={next} isWalletBalance={false} cardTitle="Total Wallet Top Up" />
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{ url: EXPORT_WALLET_ACCOUNT_STATEMENT_REPORT, query: SearchCriteria(params, maxSize) }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {showPaymentDetailsModal ? (
        <PaymentDetailsModal balance={(data as any)?.Data?.WithdrawableBalance ?? 0} withdrawalCount={getDailyWithdrawalCount()} details={withdrawalDetails?.PaymentDetails} handleValidSubmit={(values) => next(WithdrawalStages.ConfirmOTP, values)} accounts={(accounts ?? []).map(account => ({ ID: account.ID, BankCode: account.BankCode, BankName: account.BankName, AccountNumber: account.AccountNumber, Name: account.AccountName, key: account.ID }))} toggleOpen={setShowPaymentDetailsModal} isOpen={showPaymentDetailsModal} />
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {showWithdrawModal ? (
        <ConfirmOTPModal resendOTP={resendOTP} isSubmitting={editLoading} handleValidSubmit={(values) => next(WithdrawalStages.Withdraw, values)} toggleOpen={setShowWithdrawModal} isOpen={showWithdrawModal} >
          <ClusterButton type={"button"} color={"secondary"} onHandleClick={() => next(WithdrawalStages.PaymentDetails)}>
            Back
          </ClusterButton>
        </ConfirmOTPModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {isModalOpen ? (
        <Modal
          isOpen={isModalOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={() => {
            setIsModalOpen(false)
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setIsModalOpen(false)
              }}
            ><h4 className='font-weight-bold'>
                Details
              </h4>
            </ModalHeader>
            {modalLoading ?
              (<ModalBody>
                <Loading></Loading>
              </ModalBody>
              ) :
              (<ModalBody>
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardBody className="pt-2 ">

                        <DetailsRowItem
                          LHTitle="Date Logged"
                          LHContent={Format.date(viewedReport?.DateLogged)}
                          RHTitle={"Payment Reference"}
                          RHContent={viewedReport?.TransactionReference}
                        />

                        <DetailsRowItem
                          LHTitle="Amount"
                          LHContent={Format.currency(viewedReport?.Amount)}
                          RHTitle={"Amount Settled"}
                          RHContent={Format.currency(viewedReport?.AmountSettledToWallet)}
                        />

                        <DetailsRowItem
                          LHTitle="Transaction Type"
                          LHContent={viewedReport?.TransactionType}
                          RHTitle={"Transaction Mode"}
                          RHContent={viewedReport?.TransactionMode}
                        />
                        {viewedReport?.TransactionMode === 'Top Up' ?
                          <DetailsRowItem
                            LHTitle="Balance before"
                            LHContent={viewedReport?.BalanceBefore == 0 ? '--' : Format.currency(viewedReport?.BalanceBefore)}
                            RHTitle={"Balance After"}
                            RHContent={viewedReport?.BalanceAfter == 0 ? '--' : Format.currency(viewedReport?.BalanceAfter)}
                          /> : <DetailsRowItem
                            LHTitle="Balance before"
                            LHContent={Format.currency(viewedReport?.BalanceBefore)}
                            RHTitle={"Balance After"}
                            RHContent={Format.currency(viewedReport?.BalanceAfter)}
                          />}

                        {viewedReport?.TransactionMode === 'Withdrawal' ? (
                          <div>
                            <DetailsRowItem
                              LHTitle="Bank Name"
                              LHContent={viewedReport?.BankName == null ? '--' : viewedReport?.BankName}
                              RHTitle={"Account Number"}
                              RHContent={viewedReport?.AccountNumber == null ? '--' : viewedReport?.AccountNumber}
                            />
                            <DetailsRowItem
                              LHTitle="Withdrawal Mode"
                              LHContent={viewedReport?.WithdrawalMode == null ? '--' : viewedReport?.WithdrawalMode}
                              RHTitle={"Status"}
                              RHContent={viewedReport?.Status}
                            />
                          </div>
                        )
                          : <></>}

                        {viewedReport?.TransactionMode === 'Top Up' ?
                          <DetailsRowItem
                            LHTitle="Status"
                            LHContent={viewedReport?.Status}
                            RHTitle='Transaction Route'
                            RHContent={viewedReport?.Route}
                          /> : <></>}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
              )}
          </div>
        </Modal>) : (<></>)}

    </React.Fragment>
  )
}

WalletAccountStatement.propTypes = {
  walletAccountStatement: PropTypes.array,
  balance: PropTypes.any,
  onGetWithdrawals: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  settlements: { walletAccountStatementReport, totalWalletTopUps, account, balance, error, success, totalCount, otpSending, loading, statusChanged, editLoading },
}) => {
  return {
    totalWalletTopUps,
    walletAccountStatement: [...walletAccountStatementReport],
    error,
    accounts: [account],
    balance,
    totalCount,
    success,
    loading, statusChanged, editLoading,
    otpSending
  }
}

const mapDispatchToProps = dispatch => ({
  onGetWalletAccountReport: query => dispatch(getWalletAccountStatementReport(query)),
  onSendOTP: otp => dispatch(sendWithdrawalOTP(otp)),
  onGetAccounts: () => dispatch(getSettlementAccount()),
  onSettlementWithdrawal: withdrawal => dispatch(withdrawSettlement(withdrawal)),
  onPurge: () => dispatch(settlementPurge()),
  onGetBalance: () => dispatch(getWalletBalance()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withDispose(WalletAccountStatement)))
