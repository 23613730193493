//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

export const POST_CLUSTER_LOGIN = "/api/v1/auth/login"
export const REFRESH_SESSION = "/api/v1/auth/refresh-session"
export const POST_CLUSTER_LOGOUT = "/api/v1/auth/logout"
export const POST_CLUSTER_REGISTER = "/api/v1/user/create"
export const POST_CLUSTER_PASSWORD_FORGET = "/api/v1/auth/resetpassword"
export const POST_CLUSTER_CHANGE_PASSWORD = "/api/v1/User/changepassword"

//USER
export const GET_CLUSTER_USERS = "/api/v1/user/search"
export const GET_ALL_CLUSTER_USERS = "/api/v1/user"
export const GET_CLUSTER_USERDETAILS = "/api/v1/user"
export const POST_CLUSTER_USER = "/api/v1/user"
export const PUT_CLUSTER_USER = "/api/v1/user"
export const ENABLE_CLUSTER_USER = "/api/v1/user/enable"
export const DISABLE_CLUSTER_USER = "/api/v1/user/disable"

//BRANCH
export const GET_CLUSTER_BRANCHES = "/api/v1/branch"
export const GET_CLUSTER_BRANCHDETAILS = "/api/v1/branch"
export const POST_CLUSTER_BRANCH = "/api/v1/branch/create"
export const PUT_CLUSTER_BRANCH = "/api/v1/branch"
export const ENABLE_CLUSTER_BRANCH = "/api/v1/branch/enable"
export const DISABLE_CLUSTER_BRANCH = "/api/v1/branch/disable"

//ROLES
export const GET_CLUSTER_ROLES = "/api/v1/roles/search"
export const GET_ALL_CLUSTER_ROLES = "/api/v1/roles/getAll"
export const POST_CLUSTER_ROLE = "/api/v1/roles/create"
export const PUT_CLUSTER_ROLE = "/api/v1/roles"
export const GET_CLUSTER_ROLE_DETAILS = "/api/v1/Roles"
export const ENABLE_CLUSTER_ROLE = "/api/v1/roles/enable"
export const DISABLE_CLUSTER_ROLE = "/api/v1/roles/disable"
//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//MENU
export const GET_FUNCTION_ITEMS = "/api/v1/Function/GetAll"
export const GET_FUNCTION_ITEMS_PER_USER =
  "/api/v1/Function/GetFunctionsByUsername"
export const GET_FUNCTION_MENU_ITEMS_PER_USER =
  "/api/v1/Function/GetMenuByUsername"
export const GET_FUNCTION_ACTION_ITEMS_PER_USER =
  "/api/v1/Function/GetActionsByUsername"

//PERMISSION

export const GET_PERMISSIONS = "/api/v1/Permission/GetAll"
export const GET_PERMISSION_PER_USER = "/api/v1/Permission/GetByUser"
//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//AGENT
export const GET_CLUSTER_AGENTS = "/api/agents/search"
export const GET_ALL_AGENTS = "/api/agents"
export const GET_AGENT_LOAN_REPORT = "/api/agents/searchloanreport"
export const GET_CLUSTER_AGENT = "/api/agents"
export const POST_CLUSTER_AGENT = "/api/agents"
export const ACCOUNT_VALIDATION = "/api/Accounts/GetByAccountNumber"
export const ACTIVATE_CLUSTER_AGENT = "/api/agents/activate"
export const PUT_AGENT = "/api/agents"
export const RESET_AGENT_DEVICE = "/api/agents/devicereset"
export const RESET_AGENT_LIMIT = "/api/agents/resetlimit"
export const UPGRADE_TO_SUPERAGENT = "/api/agents/superagentupgrade"
export const TERMINAL_ACTIVATION = "/api/agents/terminalactivation"
export const TERMINAL_DEACTIVATION = "/api/agents/terminaldeactivation"
export const UNLINK_PARENT_AGENT = "/api/agents/unlink"
export const GENERATE_POS_ACCOUNTS = "/api/agents"

//LGA
export const GET_LGAS = "/api/lgas"
export const GET_PROVINCES = "/api/provinces"

//UNSETTLED TRANSACTIONS
export const VALIDATE_UNSETTLED_TRANSACTIONS =
  "/api/UnsettledTransactions/Validate"
export const UPLOAD_UNSETTLED_TRANSACTIONS =
  "/api/UnsettledTransactions/ProcessUpload"
export const GET_UNSETTLED_TRANSACTIONS = "/api/UnsettledTransactions/Search"
export const GET_UNSETTLED_TRANSACTIONS_DETAILS =
  "/api/UnsettledTransactions/Details"
export const DOWNLOAD_UNSETTLED_TRANSACTIONS_RECEIPTS =
  "/api/UnsettledTransactions/DownloadReceipts"
export const DOWNLOAD_UNSETTLED_TRANSACTIONS =
  "/api/UnsettledTransactions/DownloadTransactions"
export const RESOLVE_UNSETTLED_TRANSACTIONS =
  "/api/UnsettledTransactions/Resolve"
export const BATCH_RESOLVE_UNSETTLED_TRANSACTIONS =
  "/api/UnsettledTransactions/BatchResolve"

//CLUSTER MANAGER
export const GET_CLUSTER_MANAGERS = "/api/agentclustermanagers/search"
export const GET_ALL_CLUSTER_MANAGERS = "/api/agentclustermanagers"
export const GET_CLUSTER_MANAGERDETAILS = "/api/agentclustermanagers"
export const POST_CLUSTER_MANAGER = "/api/agentclustermanagers"
export const PUT_CLUSTER_MANAGER = "/api/agentclustermanagers"
export const ENABLE_CLUSTER_MANAGER = "/api/agentclustermanagers/activate"
export const DISABLE_CLUSTER_MANAGER = "/api/agentclustermanagers/activate"

// NOTIFICATION 
export const GET_NOTIFICATION = "/api/Notifications/Search"
export const EXPORT_NOTIFICATION_REPORT = "/api/Notifications"
export const POST_NOTIFICATION = "/api/Notifications"
export const GET_NOTIFICATION_DETAILS = "/api/Notifications"

//CLUSTER
export const GET_CLUSTERS = "/api/clusters/search"
export const GET_ALL_CLUSTERS = "/api/clusters"
export const GET_CLUSTERDETAILS = "/api/clusters"
export const POST_CLUSTER = "/api/clusters"
export const PUT_CLUSTER = "/api/clusters"
export const ENABLE_CLUSTER = "/api/clusters/activate"
export const DISABLE_CLUSTER = "/api/clusters/activate"

// DIRECT DEBIT
export const GET_DEBIT_REQUESTS = "/api/debitrequests/search"
export const GET_PENDING_DEBIT_REQUESTS = "/api/debitrequests/getpendingdebits"
export const GET_DEBIT_REQUESTS_DETAIL = "/api/debitrequests"
export const POST_DEBIT_REQUESTS = "/api/debitrequests"
export const PATCH_DEBIT_REQUESTS_APPROVAL = "/api/debitrequests/approval"
export const DOWNLOAD_INVOICE = "/api/debitrequests/downloadinvoice"

//TERMINAL
export const GET_TERMINALS = "/api/terminals/search"
export const GET_UNASSIGNED_TERMINALS = "/api/Terminals/GetUnassigned"
export const GET_ALL_TERMINALS = "/api/terminals"
export const GET_TERMINALDETAILS = "/api/terminals/GetByTerminalID"
export const POST_TERMINAL = "/api/terminals"
export const PUT_TERMINAL = "/api/terminals"
export const ENABLE_TERMINAL = "/api/terminals/activate"
export const DISABLE_TERMINAL = "/api/terminals/activate"
export const TERMINAL_ASSIGNMENT_REPORT = "/api/POSTerminal/Report"
export const TERMINAL_ASSIGNMENT_EXPORT = "/api/POSTerminal"

export const GET_ASSIGNED_TERMINAL_DETAILS = "/api/terminals"
export const GET_ASSIGNED_TERMINALS = "/api/terminals/GetAssigned"
export const GET_ALL_ASSIGNED_TERMINALS = "/api/terminals/GetAssigned"
export const PUT_ASSIGNED_TERMINAL = "/api/terminals/EditAssigned"
export const POST_ASSIGNED_TERMINAL = "/api/terminals/BatchAssign"
export const BATCH_UPLOAD_TERMINAL = "/api/Terminals/BatchUpload"
export const PROCESS_UPLOAD_TERMINAL = "/api/terminals/ProcessUpload"
export const REMOVE_ASSIGNED_TERMINAL = "/api/terminals/remove"

//FEATURE
export const GET_FEATURES = "/api/features/search"
export const GET_ALL_FEATURES = "/api/features"
export const GET_FEATUREDETAILS = "/api/features"
export const GET_FEATURE_BY_INSTITUTION = "/api/features/getbycode"
export const POST_FEATURE = "/api/features"
export const BATCH_ASSIGN_FEATURES = "/api/features/batchassign"
export const PUT_FEATURE = "/api/features"
export const ENABLE_FEATURE = "/api/features/activate"
export const DISABLE_FEATURE = "/api/features/activate"

//AUDIT_TRAIL
export const GET_AUDIT_TRAILS = "/api/audittrails/search"
export const GET_ALL_AUDIT_TRAILS = "/api/audittrails"
export const GET_AUDIT_TRAILDETAILS = "/api/audittrails"
export const POST_AUDIT_TRAIL = "/api/audittrails"
export const PUT_AUDIT_TRAIL = "/api/audittrails"
export const ENABLE_AUDIT_TRAIL = "/api/audittrails/activate"
export const DISABLE_AUDIT_TRAIL = "/api/audittrails/activate"

//CASELOG
export const GET_CASELOGS = "/api/caselogs/search"
export const GET_ALL_CASELOGS = "/api/caselogs"
export const GET_CASELOGDETAILS = "/api/caselogs"
export const GET_CASELOGMESSAGES = "/api/caselogs/getmessages"
export const SEND_CASELOGMESSAGE = "/api/caselogs/messages"
export const RESOLVE_CASE = "/api/caselogs/resolve"
export const MARK_AS_READ = "/api/caselogs/markasread"
export const PUT_CASELOG = "/api/caselogs"
export const ENABLE_CASELOG = "/api/caselogs/activate"
export const DISABLE_CASELOG = "/api/caselogs/activate"

//CHARGEBACK
export const GET_CHARGEBACKS = "/api/chargebacks/search"
export const GET_ALL_CHARGEBACKS = "/api/chargebacks"
export const GET_CHARGEBACKDETAILS = "/api/chargebacks"
export const UPDATE_CHARGEBACK = "/api/chargebacks/updatestatus"
export const REGENERATE_CHARGEBACK_TOKEN = "api/chargebacks/regeneratetoken"
//COMMISSION
export const GET_COMMISSIONS = "/api/agentcommissionreport/search"
export const GET_ALL_COMMISSIONS = "/api/agentcommissions"
export const POST_COMMISSION = "/api/agentcommissions"
export const PUT_COMMISSION = "/api/agentcommissions"
export const ENABLE_COMMISSION = "/api/agentcommissions/activate"
export const DISABLE_COMMISSION = "/api/agentcommissions/activate"

export const GET_SUBSCRIPTION_PLAN_DETAILS =
  "/api/Subscription/GetSubscriptionPlanByID"
export const GET_SUBSCRIPTION_PLANS = "/api/Subscription/search"
export const GET_SUBSCRIPTIONS = "/api/Subscription/GetSubscriptionHistory"
export const GET_SUBSCRIPTION_PLANS_BY_CATEGORY =
  "/api/Subscription/GetActiveSubscriptionPlansByAgentCategory"
export const GET_ALL_SUBSCRIPTION_PLANS = "/api/Subscription"
export const POST_SUBSCRIPTION_PLAN = "/api/Subscription/AddSubscriptionPlan"
export const PUT_SUBSCRIPTION_PLAN = "/api/Subscription"
export const REMOVE_SUBSCRIPTION_PLAN = "/api/Subscription/remove"
export const ENABLE_SUBSCRIPTION_PLAN = "/api/Subscription/activate"
export const DISABLE_SUBSCRIPTION_PLAN =
  "/api/Subscription/DisableSubscriptionPlan"

export const GET_COMMISSIONSTRUCTURE_DETAILS = "/api/agentcommissionstructures"
export const GET_COMMISSIONSTRUCTURES = "/api/agentcommissionstructures/search"
export const GET_COMMISSIONSTRUCTURES_BY_CATEGORY =
  "/api/agentcommissionstructures/getbycategory"
export const GET_ALL_COMMISSIONSTRUCTURES = "/api/agentcommissionstructures"
export const POST_COMMISSION_STRUCTURE =
  "/api/AgentCommissionStructures/BatchUpsert"
export const PUT_COMMISSION_STRUCTURE = "/api/agentcommissionstructures"
export const REMOVE_COMMISSION_STRUCTURE =
  "/api/agentcommissionstructures/remove"
export const ENABLE_COMMISSION_STRUCTURE =
  "/api/agentcommissionstructures/activate"
export const DISABLE_COMMISSION_STRUCTURE =
  "/api/agentcommissionstructures/activate"

export const GET_MOBILEACCOUNT_DETAILS = "/api/mobileaccounts"
export const GET_MOBILEACCOUNTS = "/api/mobileaccounts/search"
export const GET_MOBILEACCOUNT_BY_PHONENUMBER =
  "/api/mobileaccounts/getbyphonenumber"
export const GET_ALL_MOBILEACCOUNTS = "/api/mobileaccounts"
export const POST_MOBILEACCOUNT = "/api/mobileaccounts"
export const PUT_MOBILEACCOUNT = "/api/mobileaccounts"
export const ENABLE_MOBILEACCOUNT = "/api/mobileaccounts/activate"
export const DISABLE_MOBILEACCOUNT = "/api/mobileaccounts/activate"

export const GET_MOBILEWALLET_DETAILS = "/api/mobilewallets"
export const GET_MOBILEWALLETS = "/api/mobilewallets/search"
export const GET_ALL_MOBILEWALLETS = "/api/mobilewallets"
export const RESET_PIN = "/api/mobileaccounts/resetpin"
export const ACTIVATION_CODE = "/api/mobileaccounts/sendactivationcode"
export const RESET_PIN_TRIES = "/api/mobileaccounts/resetpintries"
export const RESET_PASSWORD = "/api/mobileaccounts/resetloginpassword"
export const RESET_PASSWORD_TRIES =
  "/api/mobileaccounts/resetloginpasswordtries"
export const HOTLIST = "/api/mobileaccounts/hotlist"
export const GENERATE_VERIFICATION_CODE =
  "/api/mobileaccounts/generateverificationcode"

//NETWORK MANAGER
export const GET_NETWORK_MANAGERS = "/api/agentnetworkmanagers/search"
export const GEET_ALL_NETWORK_MANAGERS_ = "/api/agentnetworkmanagers"
export const GET_ALL_NETWORK_MANAGERS = "/api/agentnetworkmanagers/getbycluster"
export const GET_NETWORK_MANAGERDETAILS = "/api/agentnetworkmanagers"
export const POST_NETWORK_MANAGER = "/api/agentnetworkmanagers"
export const PUT_NETWORK_MANAGER = "/api/agentnetworkmanagers"
export const ENABLE_NETWORK_MANAGER = "/api/agentnetworkmanagers/activate"
export const DISABLE_NETWORK_MANAGER = "/api/agentnetworkmanagers/activate"

//SETTLEMENT
export const GET_SETTLEMENT_WITHDRAWALS = "/api/settlements/search"
export const GET_SETTLEMENT_ACCOUNTS = "/api/settlements/account"
export const GET_ALL_SETTLEMENT_WITHDRAWALS = "/api/settlements"
export const GET_WALLET_BALANCE = "/api/settlements/getwalletbalance"
export const GET_SETTLEMENT_WALLET = "/api/settlements/getwallet"
export const SEND_WITHDRAWAL_OTP = "/api/settlements/sendwithdrawalotp"
export const SETTLEMENT_WITHDRAWAL = "/api/settlements/withdraw"
export const GET_SETTLEMENT_COMMERCIAL_BANKS =
  "/api/Settlements/GetCommercialBanks"
export const ACCOUNT_ENQUIRY = "/api/Settlements/accountenquiry"
export const CREATE_SETTLEMENT_ACCOUNT = "/api/Settlements/Account"
export const CONFIGURE_OTP_RECEIVER =
  "/api/Settlements/OTPReceiverConfiguration"
export const GET_WALLET_SETTLEMENT_CONFIGURATION =
  "/api/Settlements/WalletSettlementConfig/Search"
export const WALLET_SETTLEMENT_CONFIGURATION =
  "/api/Settlements/WalletSettlementConfig"
export const ACTIVATE_SETTLEMENT_ACCOUNT = "/api/Settlements/Account/Activate"
export const GET_WALLET_TOP_UP_REPORT = "/api/Reports/WalletTopUp/Search"
export const EXPORT_WALLET_TOP_UP_REPORT = "/api/Reports/WalletTopUp"
export const GET_WALLET_ACCOUNT_STATEMENT_REPORT =
  "/api/Reports/WalletAccountStatement/Search"
export const EXPORT_WALLET_ACCOUNT_STATEMENT_REPORT =
  "api/Reports/WalletAccountStatement"
//AFFILIATES
export const GET_AFFILIATES = "/api/affiliates/search"
export const GET_ALL_AFFILIATES = "/api/affiliates"
export const GET_AFFILIATEDETAILS = "/api/affiliates"
export const POST_AFFILIATE = "/api/affiliates"
export const PUT_AFFILIATE = "/api/affiliates"
export const ENABLE_AFFILIATE = "/api/affiliates/activate"
export const DISABLE_AFFILIATE = "/api/affiliates/activate"

//CATEGORY
export const GET_CATEGORY_FEATURES = "/api/agentcategories/getfeatures"
export const GET_CATEGORIES = "/api/agentcategories/search"
export const GET_ALL_CATEGORIES = "/api/agentcategories"
export const GET_CATEGORYDETAILS = "/api/agentcategories"
export const POST_CATEGORY = "/api/agentcategories"
export const PUT_CATEGORY = "/api/agentcategories"
export const ENABLE_CATEGORY = "/api/agentcategories/activate"
export const DISABLE_CATEGORY = "/api/agentcategories/activate"

//ROUTES
export const GET_BINS = "/api/Routes/Bins"
export const GET_ROUTES = "/api/Routes"
export const GET_BINROUTES = "/api/Routes/BINRoutes/Search"
export const GET_BINROUTE_DETAILS = "/api/Routes/RouteDetails/Search"
export const CONFIGURE_ROUTES = "/api/Routes/Configure"
export const REMOVE_BINROUTE_DETAIL = "api/Routes/CardRoutes/Remove"

//INSTITUTION
export const GET_INSTITUTIONS = "/api/institutions/search"
export const GET_ALL_INSTITUTIONS = "/api/institutions"
export const GET_INSTITUTIONDETAILS = "/api/institutions"
export const POST_INSTITUTION = "/api/institutions"
export const CONFIGURE_SERVICES = "/api/institutions/configureservices"
export const GET_INSTITUTION_SERVICE_CONFIGURATION =
  "/api/institutions/getserviceconfiguration"
export const PUT_INSTITUTION = "/api/institutions"
export const ENABLE_INSTITUTION = "/api/institutions/activate"
export const DISABLE_INSTITUTION = "/api/institutions/activate"

//CASHOUT INSTITUTION
export const GET_CASHOUT_INSTITUTIONS = "/api/cashoutinstitutions/search"
export const GET_ALL_CASHOUT_INSTITUTIONS = "/api/cashoutinstitutions"
export const GET_CASHOUT_INSTITUTIONDETAILS = "/api/cashoutinstitutions"
export const POST_CASHOUT_INSTITUTION = "/api/cashoutinstitutions"
export const PUT_CASHOUT_INSTITUTION = "/api/cashoutinstitutions"
export const ENABLE_CASHOUT_INSTITUTION = "/api/cashoutinstitutions/activate"
export const DISABLE_CASHOUT_INSTITUTION = "/api/cashoutinstitutions/activate"

//STATES
export const GET_STATES = "/api/NigerianStates"

export const GET_ALL_CASHOUTREPORT = "/api/reports/getcashouttransactionsreport"
export const GET_CASHOUTREPORT =
  "/api/reports/getcashouttransactionsreport/search"
export const GET_ALL_FUNDSTRANSFERREPORT =
  "/api/Reports/FundsTransferTransactionsReport"
export const GET_FUNDSTRANSFERREPORT =
  "/api/Reports/FundsTransferTransactionsReport"
export const GET_PAYWITHTRANSFERREPORT = "/api/Reports/PayWithTransfer/search"
export const EXPORT_PAYWITHTRANSFERREPORT = "/api/Reports/PayWithTransfer"
export const GET_CARDTRANSFERREPORT = "/api/Reports/CardTransfer/search"
export const EXPORT_CARDTRANSFERREPORT = "/api/Reports/CardTransfer"
export const GET_TRANSACTIONREPORT = "/api/reports/gettransactionsreport"
export const GET_COLLECTION_REPORT = "/api/reports/collectionsreport"
export const GET_RECEIPTREPORT = "/api/reports/transactionreceipts"
export const GET_REPORTDOWNLOADTYPES = "/api/reportdownloads/reporttypes"
export const LOG_FOR_DOWNLOAD = "/api/reportdownloads"
export const GET_DOWNLOADS = "/api/reportdownloads/search"
export const GET_TRANSACTION_SUMMARY_REPORT =
  "/api/Reports/AgentTransactionSummaryReport"
export const GET_VOLUME_COMPARATIVES_REPORT =
  "/api/Reports/AgentTransactionVolumeComparatives"
//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"

//RECEIPTS
