import {
  BATCH_RESOLVE_UNSETTLED_TRANSACTION,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
  DOWNLOAD_UNSETTLED_TRANSACTION,
  DOWNLOAD_UNSETTLED_TRANSACTION_FAIL,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS,
  DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS,
  GET_UNSETTLED_TRANSACTIONS,
  GET_UNSETTLED_TRANSACTIONS_FAIL,
  GET_UNSETTLED_TRANSACTIONS_SUCCESS,
  GET_UNSETTLED_TRANSACTION_DETAIL,
  GET_UNSETTLED_TRANSACTION_DETAIL_FAIL,
  GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS,
  PURGE_UNSETTLED_TRANSACTIONS_SUCCESS,
  RESOLVE_UNSETTLED_TRANSACTION,
  RESOLVE_UNSETTLED_TRANSACTION_FAIL,
  RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
  VALIDATE_UNSETTLED_TRANSACTION,
  VALIDATE_UNSETTLED_TRANSACTION_FAIL,
  VALIDATE_UNSETTLED_TRANSACTION_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  unsettledTransactions: [],
  unsettledTransactionApproval: [],
  unsettledTransactionApprovals: [],
  downloadedTransactions: {},
  downloadedReceipts: {},
  processResult: undefined,
  error: {},
  success: undefined,
  saved: false,
  totalCount: 0,
  loading: true,
  editLoading: false,
  resolveLoading: false,
  batchResolveLoading: false,
  statusChanged: undefined
}

const UnsettledTransactions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_UNSETTLED_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        unsettledTransactionApprovals: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
        editLoading:false
      }
    case GET_UNSETTLED_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_UNSETTLED_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        success: undefined,
        loading: false,
        editLoading:false
      }

    case GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS:
      return {
        ...state,
        unsettledTransactionApproval: action.payload?.Data,
        loading: false,
        editLoading:false
      }

    case GET_UNSETTLED_TRANSACTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading:false
      }
    case GET_UNSETTLED_TRANSACTION_DETAIL:
      state = {
        ...state,
        loading: true,
        error: "",
        success: "",
        unsettledTransactionApproval: []
      }
      break
    case RESOLVE_UNSETTLED_TRANSACTION:
      state = {
        ...state,
        resolveLoading: true,
        error: "",
        success: "",
      }
      break
    case RESOLVE_UNSETTLED_TRANSACTION_SUCCESS:

      state= {
        ...state,
        resolveLoading: false,
        loading: false,
        unsettledTransactionApproval: state.unsettledTransactionApproval.map(trx => {
          if (action.payload?.approve) trx.IsApproved = "Approved"
          else trx.IsApproved = "Rejected"
          return trx;
        }),
        unsettledTransactionApprovals: state.unsettledTransactionApprovals.map(trx => {
          if (trx.ID == action.payload?.batchID) {
            if (action.payload?.approve) {
              if (!trx.TransactionsApproved.includes(action.payload?.transactionID)) {
                if (trx.TransactionsApproved) trx.TransactionsApproved += ","
                trx.TransactionsApproved += `${action.payload?.transactionID}`
                trx.AmountApproved += 1;
              }
            }
            else {
              if (!trx.TransactionsRejected.includes(action.payload?.transactionID)) {
                if (trx.TransactionsRejected) trx.TransactionsRejected += ","
                trx.TransactionsRejected += `${action.payload?.transactionID}`
                trx.AmountRejected += 1;
              }
            }
          }
          if((trx.AmountApproved+trx.AmountRejected)>= trx.Total) trx.IsTreated = true;
          return trx;
        }),
        error: "",
        statusChanged: action.payload?.enable,
        success: action.payload?.Message
      }
      return state;
    case RESOLVE_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, resolveLoading: false }
      break
    case BATCH_RESOLVE_UNSETTLED_TRANSACTION:
      return {
        ...state,
        batchResolveLoading: true,
        error: undefined,
        success: undefined
      }

    case BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS:
      return {
        ...state,
        batchResolveLoading: false,
        loading: false,
        unsettledTransactionApproval: state.unsettledTransactionApproval.map(trx=>{
          if(trx.IsApproved=='Pending'){
            trx.IsApproved = action.payload?.approve?'Approved':'Rejected';
          }
          return trx;
        }),
        unsettledTransactionApprovals: state.unsettledTransactionApprovals.map(trx => {
          if (trx.ID == action.payload?.batchID) {
              trx.IsTreated = true;
              if(action.payload?.approve) trx.AmountApproved+= (trx.Total - trx.AmountRejected)
              else trx.AmountRejected += (trx.Total - trx.AmountApproved)
          }
          return trx;
        }),
        error: "",
        statusChanged: action.payload?.approve,
        success: action.payload?.Message
      }
    case BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload?.Message, success: undefined, loading: false, batchResolveLoading: false }
      break
    case VALIDATE_UNSETTLED_TRANSACTION:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined
      }
      break;

    case VALIDATE_UNSETTLED_TRANSACTION_SUCCESS:
      state = {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
        processResult: action.payload?.Data
      }
      break;
    case VALIDATE_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, editLoading: false, processResult: undefined }
      break
    case PROCESS_UPLOAD_UNSETTLED_TRANSACTION:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined
      }
      break
    case PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: undefined,
        success: action.payload?.Message
      }
    case PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload?.Message, success: undefined, loading: false, editLoading: false }
      break
    case DOWNLOAD_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, editLoading: false }
      break

    case DOWNLOAD_UNSETTLED_TRANSACTION:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined
      }
      break
    case DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        downloadedTransactions: action.payload?.Data,
        success: action.payload?.Message
      }
    case DOWNLOAD_UNSETTLED_TRANSACTION_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, editLoading: false }
      break

    case DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined
      }
      break
    case DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        downloadedReceipts: action.payload?.Data,
        success: action.payload?.Message
      }
    case DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, editLoading: false }
      break

    case PURGE_UNSETTLED_TRANSACTIONS_SUCCESS:

      return INIT_STATE
    default:
      return state
  }
  return state
}

export default UnsettledTransactions
