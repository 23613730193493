import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { Link, withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import Loading from "components/Common/Loading"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"

import ClusterTextArea from "components/Common/TextArea"
import withFormValidation from "components/HOCs/WithFormValidation"
import withDispose from "components/HOCs/WithDispose"
import { UserScope } from "enums/user-scope"
import { Utils } from "helpers/utility"
import { GENDER } from "helpers/variables"
const ClusterManagerForm = props => {
  let { statesLoading, states, isFormInvalid, roles, roleLoading, errorList, myError, isUpdate, loading, editLoading, details, setErrorList, handleOnChange, handleValidSubmit } = props;



  const assignableRoles = useMemo(() => {
    return roles.filter(role => role.scope == UserScope.Cluster).map(r => ({ id: r.id, key: r.id, value: r.name }));
  }, [roles])

  return <React.Fragment>
    <ClusterForm handleSubmit={handleValidSubmit}>
      {loading && isUpdate && (!details || Object.keys(details).length == 0) ?
        <Loading><p>Getting Cluster Manager...</p></Loading> :
        <React.Fragment>
          <React.Fragment>
            <React.Fragment>
              <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
                <CardBody>
                  <Row>

                    <Col lg={4} md={4} sm={6}>

                      <ClusterInput defaultValue={details?.Name} onError={(err) => setErrorList({ ...errorList, name: err })} label={"Name"} id={"name"} name={"Name"} validators={[Validators.minLength(3, "Minimum number of characters is 3"), Validators.maxLength(50, "Maximum number of characters is 50")]} type="text" required={true} onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
                    </Col>
                    <Col lg={4} md={4} sm={6}>

                      <ClusterInput defaultValue={details?.Email} onError={(err) => setErrorList({ ...errorList, email: err })} label={"Email"} id={"email"} name={"Email"} validators={[Validators.email("Enter a valid email e.g abc@xyz.com")]} type="text" required={true} onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
                    </Col>
                    <Col lg={4} md={4} sm={6}>

                      <ClusterInput defaultValue={details?.PhoneNumber} onError={(err) => setErrorList({ ...errorList, phoneNumber: err })} label={"Phone Number"} id={"phoneNumber"} name={"PhoneNumber"} validators={[Validators.phone(["Phone number should contain only numbers", "Phone number must be 11 digits"])]} type="text" required={true} onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
                    </Col>
                  </Row>
                  <Row>

                    <Col lg={4} md={4} sm={6}>

                      <ClusterInput defaultValue={details?.AccountNumber} focus onError={(err) => setErrorList({ ...errorList, accountNumber: err })} label={"Account Number"} id={"accountNumber"} name={"AccountNumber"} validators={[Validators.accountNumber(["Account numbers should contain only numbers", "Account numbers must be 10 digits"])]} type="text" onHandleChange={(key, value) => handleOnChange(key, value)}></ClusterInput>
                    </Col>

                  </Row>
                  {!details.UserID && <React.Fragment>
                    <Row className="mb-4 mt-4">
                      <h4>User Details</h4>
                    </Row>
                    <Row>

                      <Col lg={4} md={4}>
                        <FormGroup >
                          <Label>User Role</Label>
                          {roleLoading ? <Loading /> : (Utils.List.isEmpty(assignableRoles)) ? <Alert color="danger">No User Roles available for the cluster scope. Create a user role with a cluster scope to proceed</Alert> : <ClusterDropdown name={"UserRoleID"} placeholder={"- Select a role - "} defaultValue={""} items={assignableRoles} onChange={(name, value) => { handleOnChange("UserRoleID", value); setErrorList({ ...errorList, userRoleId: "" }) }}></ClusterDropdown>}

                        </FormGroup>
                      </Col>

                      <Col lg={3} md={4}>
                        <ClusterInput
                          onError={err =>
                            setErrorList({ ...errorList, username: err })
                          }
                          label={"Username"}
                          id={"username"}
                          name={"Username"}
                          validators={[
                            Validators.minLength(
                              6,
                              "Minimum number of characters is 6"
                            ),
                          ]}
                          type="text"
                          required={true}
                          onHandleChange={(key, value) =>
                            handleOnChange(key, value)
                          }
                        ></ClusterInput>
                      </Col>
                      <Col lg={1} md={2}>
                        <FormGroup>
                          <Label>Gender</Label>
                          <Row>
                            <ClusterDropdown
                              style={{ left: "10%", top: "60%" }}
                              defaultValue={details.gender}
                              value={details.gender}
                              placeholder={"- Select -"}
                              items={GENDER}
                              name={"Gender"}
                              onChange={(name, value) => {
                                handleOnChange("Gender", value)
                                setErrorList({ ...errorList, gender: "" })
                              }}
                              required={true}
                            ></ClusterDropdown>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} md={4}>
                        <ClusterTextArea
                          label={"Street Address"}
                          className="form-control"
                          defaultValue={details.address?.street ?? ""}
                          onError={err => setErrorList({ ...errorList, street: "" })}
                          onHandleChange={handleOnChange}
                          id={"street"}
                          name={"Address"}
                          validators={[
                            Validators.maxLength(
                              100,
                              "Maximum number of characters is 100"
                            ),
                            Validators.minLength(
                              4,
                              "Minimum number of characters is 4"
                            ),
                          ]}
                        />

                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={details.address?.city}
                          onError={err => setErrorList({ ...errorList, city: err })}
                          label={"City"}
                          id={"city"}
                          name={"City"}
                          validators={[Validators.minLength(3, "Invalid city")]}
                          type="text"
                          required={true}
                          onHandleChange={(key, value) =>
                            handleOnChange(key, value)
                          }
                        ></ClusterInput>
                      </Col>
                      <Col lg={4} md={4}>
                        <FormGroup>
                          <Label>State</Label>
                          {statesLoading ? (
                            <Loading></Loading>
                          ) : (
                            <ClusterDropdown
                              defaultValue={
                                details.address?.state
                              }
                              name={"state"}
                              placeholder={"- Select a state -"}
                              items={states}
                              onChange={(name, value) => {
                                handleOnChange("State", value)
                                setErrorList({ ...errorList, state: "" })
                              }}
                            ></ClusterDropdown>
                          )}
                        </FormGroup>
                      </Col>
                    </Row></React.Fragment>}
                </CardBody>
              </Card>
            </React.Fragment>
          </React.Fragment>


        </React.Fragment>}

      <Row style={{ paddingBottom: "50px" }}>
        <Col lg={12}>
          <ClusterButton loading={editLoading} disabled={isFormInvalid} color={"primary"} addon={"bx bx-save"}>{isUpdate ? "Update" : "Save"}</ClusterButton>
        </Col>
      </Row>
    </ClusterForm>
  </React.Fragment>
}
ClusterManagerForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(withDispose(ClusterManagerForm)))
