import {
  GET_ROLES,
  API_ERROR,
  GET_SUCCESS,
  POST_SUCCESS,
  CREATE_ROLE,
  GET_ROLE_DETAILS_SUCCESS,
  GET_ROLE_DETAILS,
  PURGE_USER_ROLES_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE,
  ENABLE_USER_ROLE_SUCCESS,
  ENABLE_USER_ROLE,
  GET_ROLE_SUCCESS,
} from "./actionTypes"
import { defaultObj } from "helpers/utility"
const initialState = {
  error: "",
  roles: [],
  role: {},
  loading: true,
  editLoading: false,
  success: undefined,
  statusChanged: undefined,
}

const Roles = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROLES:
      state = {
        ...state,
        loading: true,
      }
      break
    case CREATE_ROLE:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
      break
    case UPDATE_USER_ROLE:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
      break
    case UPDATE_USER_ROLE_SUCCESS:
      state = {
        ...state,
        success: action.payload?.message,
        editLoading: false,
        error: "",
      }
      break
    case GET_ROLE_SUCCESS:
      return {
        ...state,
        roles: action.payload?.collection||action.payload,
        totalCount: action.payload?.totalCount,
        loading: false,
        editLoading: false,
        error: "",
      }
    case GET_ROLE_DETAILS:
      state = {
        ...state,
        loading: false,
        editLoading: true,
      }
      break
    case GET_ROLE_DETAILS_SUCCESS:
      return {
        ...state,
        role: action.payload,
        loading: false,
        error: "",
        editLoading: false,
      }
    case POST_SUCCESS:
      return {
        ...state,
        role: defaultObj(state.role), //action.payload,
        error: "",
        success: action.payload?.message,
        editLoading: false,
      }
    case PURGE_USER_ROLES_SUCCESS:
      return {
        ...state,
        role: {},
        roles: [],
        error: undefined,
        success: undefined,
        loading: true,
      }
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break
    case ENABLE_USER_ROLE:
      state = {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
      }
      break
    case ENABLE_USER_ROLE_SUCCESS:
      let detail = state.role
      let roles = state.roles

      if (detail) {
        detail.status = action.payload ? "Active" : "Inactive"
        roles.map(c => {
          if (c.id == detail.id) {
            c.status = detail.status
          }
          return c
        })
      }
      return {
        ...state,
        roles,
        role: detail,
        editLoading: false,
        loading: false,
        error:"",
        success:action.payload?"Role activated successfully":"Role deactivated successfully",
        statusChanged: !state.statusChanged,
      }
    default:
      state = { ...state }
      break
  }
  return state
}
export default Roles
