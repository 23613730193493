import PropTypes from "prop-types"
import React, { useState } from "react"
// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// action
import { getMenu, getFunctionsByUser } from "../../store/actions"

// import images
import withDispose from "components/HOCs/WithDispose"
import RemoteModal from "./modal"

const RemotePage = props => {
  const [modal, setModal] = useState(true)
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <RemoteModal isOpen={modal} setModal={setModal} history={props.history}></RemoteModal>
    </React.Fragment>
  )
}

RemotePage.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  getMenu: PropTypes.func,
  menuLoading: PropTypes.bool,
  menus: PropTypes.array,
  getFunctionsByUser: PropTypes.func,
}

const mapStatetoProps = state => {
  const { menus } = state.permissions
  return { menus }
}

export default withRouter(
  connect(mapStatetoProps, { getMenu, getFunctionsByUser })(
    withDispose(RemotePage)
  )
)
