import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  enableCategory,
  getCategoryDetail,
  getCategories,
  categoryPurge,
  getFeaturesByInstitution,
  getInstitutionDetail,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import CategoryLogic from "../category"
import { EDIT_CATEGORY, ENABLE_CATEGORIES } from "../permission"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import { Institution } from "helpers/authentication/institution"
import { Icons } from "enums/icons"
import AccordionToggle from "components/Common/Accordion/Toggle";
import ClusterButton from "components/Common/Button/Button"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

const AgentCategoryList = props => {
  const {
    MapToModalState,
    MapToRows,
    SearchCriteria,
    SearchOptions,
    HEADERS,
    MapFeatures
  } = CategoryLogic
  const {
    categories,
    onGetCategories,
    error,
    success,
    onGetCategoryDetail,
    categoryDetail,
    institutionFeatures,
    onPurge,
    totalCount,
    loading,
    onEnableCategory,
    statusChanged,
    onGetInstitution,
    institution,
    onGetInstitutionFeatures,
    editLoading,
  } = props
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetCategories, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const {institutionsData, institutions} = useInstitutionsData()

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [isEnabled, setIsEnabled] = useState(false)


  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )

  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

  const [viewedCategory, setViewedCategory] = useState<any>()
  useEffect(() => {
    onGetInstitution(Institution.getID());
  }, [])
  useEffect(() => {
    populateRows()
  }, [categories])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    if (categoryDetail && Object.keys(categoryDetail).length > 0) {
      setmodal(true)
      setViewedCategory(categoryDetail)
      setModalData(MapToModalState(categoryDetail))
    }
  }, [categoryDetail])

  const populateRows = () => {
    if (categories) {
      let rws = []
      categories.forEach(category => {
        let row = MapToRows(category)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(category.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetCategoryDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Category"}
            breadcrumbItem={"View Agent Categories"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CATEGORIES,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={viewedCategory?.IsActive ? "success" : "secondary"}
          status={viewedCategory?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Category Details"}
          append={[
            <FeatureList
              key={1}
              items={MapFeatures(viewedCategory?.Features ?? institution?.Features, null, true, false)}
              loading={loading}
            />
          ]}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_CATEGORIES]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableCategory(viewedCategory?.ID, !viewedCategory?.IsActive)
            }
            color="secondary"
          >
            {viewedCategory?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-category/" + viewedCategory?.ID}>
            <ClusterButton
              permissions={[EDIT_CATEGORY]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

AgentCategoryList.propTypes = {
  categories: PropTypes.array,
  categoryDetail: PropTypes.object,
  onGetCategories: PropTypes.func,
  onGetCategoryDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  categories: {
    categories,
    error,
    categoryDetail,
    success,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  },
  features,
  institutions: {
    institutionDetail
  }
}) => {
  return {
    categories: [...categories],
    error,
    categoryDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    institutionFeatures: features.institutionFeatures,
    success,
    institution: institutionDetail
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCategories: query => dispatch(getCategories(query)),
  onGetCategoryDetail: id => dispatch(getCategoryDetail(id)),
  onPurge: () => dispatch(categoryPurge()),
  onEnableCategory: (id, enable) => dispatch(enableCategory(id, enable)),
  onGetInstitution: (id) => dispatch(getInstitutionDetail(id)),
  onGetInstitutionFeatures: (institutionCode) => dispatch(getFeaturesByInstitution(institutionCode))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(AgentCategoryList))))


const FeatureList = ({ items, loading }) => {
  if (items.length > 0) {
    return (
      <AccordionToggle
        parent={{ id: 0, name: "feature", label: "Features", value: 0, checked: true, disabled: true, icon: Icons.Customize }}
        children={items}
        loading={loading}
      />
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}