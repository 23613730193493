import {
  GET_ROLES,
  GET_ALL_ROLES,
  API_ERROR,
  POST_SUCCESS,
  CREATE_ROLE,
  GET_ROLE_DETAILS,
  GET_ROLE_DETAILS_SUCCESS,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_SUCCESS,
  PURGE_USER_ROLES,
  PURGE_USER_ROLES_SUCCESS,
  ENABLE_USER_ROLE,
  ENABLE_USER_ROLE_SUCCESS,
  GET_ROLE_SUCCESS,
} from "./actionTypes"

export const getRoles = (params) => {
  return {
    type: GET_ROLES,
    params
  }
}
export const getAllRoles = () => {
  return {
    type: GET_ALL_ROLES
  }
}
export const getRoleDetail = id => {
  return {
    type: GET_ROLE_DETAILS,
    id,
  }
}

export const getRoleSuccess = roles => {
  return {
    type: GET_ROLE_SUCCESS,
    payload: roles,
  }
}

export const createRole = role => {
  return {
    type: CREATE_ROLE,
    payload: { role },
  }
}

export const postRoleSuccess = role => {
  return {
    type: POST_SUCCESS,
    payload: role,
  }
}

export const updateRole = role => {
  return {
    type: UPDATE_USER_ROLE,
    payload: { role },
  }
}
export const updateRoleSuccess = role => {
  return {
    type: UPDATE_USER_ROLE_SUCCESS,
    payload: role,
  }
}
export const getRoleDetailSuccess = role => {
  return {
    type: GET_ROLE_DETAILS_SUCCESS,
    payload: role,
  }
}
export const roleApiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const purgeRole = () => {
  return {
    type: PURGE_USER_ROLES,
  }
}

export const purgeRoleSuccess = () => {
  return {
    type: PURGE_USER_ROLES_SUCCESS,
  }
}

export const enableRole = (id, enable) => {
  return {
    type: ENABLE_USER_ROLE,
    id,
    enable,
  }
}
export const enableRoleSuccess = role => {
  return {
    type: ENABLE_USER_ROLE_SUCCESS,
    payload: role,
  }
}
