import React from "react"
import PropTypes from "prop-types"

export type FormProps = {
  isUpdate:boolean
}
const ClusterForm:React.FC<any> = props => {
  const { children, handleSubmit, ...others } = props
  return (
    <form autoComplete="off" onSubmit={e => {handleSubmit(e)}}>
      {children}
    </form>
  )
}

ClusterForm.propTypes = {
  handleSubmit: PropTypes.func,
  formRef:PropTypes.any,
  children:PropTypes.any
}

export default ClusterForm
