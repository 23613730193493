/* AUDIT_TRAILS */
export const GET_AUDIT_TRAILS = "GET_AUDIT_TRAILS"
export const GET_ALL_AUDIT_TRAILS = "GET_ALL_AUDIT_TRAILS"
export const GET_AUDIT_TRAILS_SUCCESS = "GET_AUDIT_TRAILS_SUCCESS"
export const GET_AUDIT_TRAILS_FAIL = "GET_AUDIT_TRAILS_FAIL"

export const GET_AUDIT_TRAIL_DETAIL = "GET_AUDIT_TRAIL_DETAIL"
export const GET_AUDIT_TRAIL_DETAIL_SUCCESS = "GET_AUDIT_TRAIL_DETAIL_SUCCESS"
export const GET_AUDIT_TRAIL_DETAIL_FAIL = "GET_AUDIT_TRAIL_DETAIL_FAIL"


export const CREATE_AUDIT_TRAIL= "CREATE_AUDIT_TRAIL";
export const POST_AUDIT_TRAIL_SUCCESS = "POST_AUDIT_TRAIL_SUCCESS"
export const POST_AUDIT_TRAIL_FAIL = "POST_AUDIT_TRAIL_FAIL"

export const AUDIT_TRAIL_PURGE = "AUDIT_TRAIL_PURGE"
export const AUDIT_TRAIL_PURGE_SUCCESS = "AUDIT_TRAIL_PURGE_SUCCESS"


export const UPDATE_AUDIT_TRAIL= "UPDATE_AUDIT_TRAIL";
export const UPDATE_AUDIT_TRAIL_SUCCESS= "UPDATE_AUDIT_TRAIL_SUCCESS";
export const UPDATE_AUDIT_TRAIL_FAIL= "UPDATE_AUDIT_TRAIL_FAIL";

export const ENABLE_AUDIT_TRAIL= "ENABLE_AUDIT_TRAIL";
export const ENABLE_AUDIT_TRAIL_SUCCESS = "ENABLE_AUDIT_TRAIL_SUCCESS"