import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"
// images
//Import Breadcrumb

//i18n
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterForm from "components/Common/Form"
import TerminalAssignmentServiceProviderLogic from "../../terminal-assignment-serviceprovider"
import withFormValidation from "components/HOCs/WithFormValidation"
import withMinimize from "components/HOCs/WithMinimize"
import Loading from "components/Common/Loading"
const TerminalAssignmentServiceProviderForm = props => {
  let {
    errorList,
    myError,
    editLoading,
    setErrorList,
    handleOnChange,
    handleValidSubmit,
    affiliates,
    terminals,
  } = props

  const ref = useRef(null)
  const [affiliate, setAffiliate] = useState("- Select -")
  const [terminal, setTerminal] = useState("")
  const { MapToDropDown } = TerminalAssignmentServiceProviderLogic

  useEffect(() => {
    handleOnChange("Affiliate", null)
    handleOnChange("Terminal", null)
  }, [])

  const onSubmit = e => {
    handleValidSubmit(e)
    ref.current.reset()
  }

  return (
    <React.Fragment>
      <ClusterForm formRef={ref} handleSubmit={onSubmit}>
        <React.Fragment>
          <React.Fragment>
            <Card
              outline
              style={{ background: "transparent", borderColor: "#999999" }}
              className="border"
            >
              <CardBody>
                <Row>
                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label>Affiliate</Label>
                      {!!affiliates ? (
                        <ClusterDropdown
                          useValue={false}
                          defaultValue={affiliate}
                          items={MapToDropDown(affiliates)}
                          onChange={(name, value) => {
                            handleOnChange(
                              "Affiliate",
                              affiliates.find(t => t.ID == value)
                            )
                            setErrorList({ ...errorList, affiliate: "" })
                          }}
                        ></ClusterDropdown>
                      ) : (
                        <Loading />
                      )}
                    </FormGroup>
                  </Col>

                  <Col lg={6} md={6}>
                    <FormGroup>
                      <Label>Terminal</Label>
                      {!!terminals ? (
                        <ClusterDropdown
                          useValue={false}
                          placeholder={"- Select -"}
                          items={MapToDropDown(terminals)}
                          onChange={(name, value) => {
                            handleOnChange(
                              "Terminal",
                              terminals.find(t => t.ID == value)
                            )
                            setErrorList({ ...errorList, terminal: "" })
                          }}
                        ></ClusterDropdown>
                      ) : (
                        <Loading />
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row style={{ paddingBottom: "0px" }}>
                  <Col
                    lg={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <ClusterButton
                      className="btn-sm"
                      loading={editLoading}
                      disabled={myError != "" ? true : false}
                      color={"primary"}
                      addon={"bx bx-save"}
                    >
                      Add
                    </ClusterButton>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        </React.Fragment>
      </ClusterForm>
    </React.Fragment>
  )
}
TerminalAssignmentServiceProviderForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  agentDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(
  withFormValidation(withMinimize(TerminalAssignmentServiceProviderForm))
)
