import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_INSTITUTIONS,
  GET_INSTITUTION_DETAIL,
  INSTITUTION_PURGE,
  ENABLE_INSTITUTION,
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
  GET_ALL_INSTITUTIONS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS,
  CONFIGURE_SERVICES,
} from "./actionTypes"
import {
  getInstitutionsSuccess,
  getInstitutionsFail,
  getInstitutionDetailSuccess,
  getInstitutionDetailFail,
  institutionPurgeSuccess,
  updateInstitutionSuccess,
  postInstitutionSuccess,
  institutionApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getInstitutions,
  getInstitutionDetail,
  putInstitution,
  postInstitution,
  enableInstitution,
  getAllInstitutions,
  disableInstitution,
  batchAssignFeatures,
  getInstitutionServiceConfiguration,
  configureInstitutionServices
} from "helpers/backend_helper"
import { Institution } from "helpers/authentication/institution"
import { configureInstitutionServicesSuccess, enableInstitutionSuccess, getInstitutionServiceConfigurationFail, getInstitutionServiceConfigurationSuccess } from "store/actions"
const format = "yyyy-MM-DD"
function* fetchInstitutions({ params }) {
  try {
    if (!params) {
      yield put(getInstitutionsSuccess([]))
      return
    }
    const response = yield call(getInstitutions, JSON.parse(params))
    yield put(getInstitutionsSuccess(response))
  } catch (error) {
    console.debug("Fetching Institutions Error", error)
    yield put(getInstitutionsFail(error))
  }
}
function* fetchAllInstitutions() {
  try {
    const response = yield call(getAllInstitutions)
    yield put(getInstitutionsSuccess(response))
  } catch (error) {
    console.debug("Fetching All Institutions Error", error)
    yield put(getInstitutionsFail(error))
  }
}
function* fetchInstitutionDetail({ id }) {
  try {
    const response = yield call(getInstitutionDetail, id)
    yield put(getInstitutionDetailSuccess(response))
  } catch (error) {
    yield put(getInstitutionDetailFail(error))
  }
}

function* fetchInstitutionServiceConfiguration({ institutionCode }) {
  try {
    const response = yield call(getInstitutionServiceConfiguration, institutionCode)
    yield put(getInstitutionServiceConfigurationSuccess(response))
  } catch (error) {
    yield put(getInstitutionServiceConfigurationFail(error))
  }
}

function* createInstitution({ payload: { institution } }) {
  try {
    const response = yield call(postInstitution, institution);
     yield put(postInstitutionSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      institutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* configureServices({ payload: { services } }) {
  try {
    const response = yield call(configureInstitutionServices, services);
    yield put(configureInstitutionServicesSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      institutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* updateInstitution({ payload: { institution } }) {
  try {

    if (!institution?.ID) return;
    const response = yield call(putInstitution, institution)

    yield put(updateInstitutionSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      institutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* doEnableInstitution(payload) {
  try {
    let toEnable = true
    let cta = enableInstitution
    if (!payload.enable) {
      cta = disableInstitution
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableInstitutionSuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      institutionApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doPurge() {
  yield put(institutionPurgeSuccess())
}

function* categoriesSaga() {
  yield takeLeading(CONFIGURE_SERVICES,configureServices)
  yield takeLeading(GET_INSTITUTION_SERVICE_CONFIGURATIONS, fetchInstitutionServiceConfiguration)
  yield takeLeading(GET_INSTITUTIONS, fetchInstitutions)
  yield takeEvery(GET_INSTITUTION_DETAIL, fetchInstitutionDetail)
  yield takeEvery(INSTITUTION_PURGE, doPurge)
  yield takeLeading(ENABLE_INSTITUTION, doEnableInstitution)
  yield takeLeading(UPDATE_INSTITUTION, updateInstitution)
  yield takeLeading(CREATE_INSTITUTION, createInstitution)
  yield takeLeading(GET_ALL_INSTITUTIONS, fetchAllInstitutions)
}

export default categoriesSaga
