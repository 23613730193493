import { defaultObj } from "helpers/utility"
import {
  GET_COMMISSION_DETAIL_SUCCESS,
  GET_COMMISSION_DETAIL_FAIL,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FAIL,
  COMMISSION_PURGE,
  ENABLE_COMMISSION,
  ENABLE_COMMISSION_SUCCESS,
  COMMISSION_PURGE_SUCCESS,
  GET_COMMISSIONS,
  POST_COMMISSION_SUCCESS,
  UPDATE_COMMISSION,
  CREATE_COMMISSION,
  UPDATE_COMMISSION_SUCCESS,
  POST_COMMISSION_FAIL,
  GET_ALL_COMMISSIONS,
} from "./actionTypes"

const INIT_STATE = {
  commissions: [],
  commissionDetail: {},
  commissionStructures: [],
  commissionStructureDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const AgentCommissions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_COMMISSIONS_SUCCESS:
      return {
        ...state,
        commissions: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_COMMISSIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_COMMISSIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COMMISSION_DETAIL_SUCCESS:
      return {
        ...state,
        commissionDetail: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
      }

    case GET_COMMISSION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_COMMISSION_SUCCESS:
      return {
        ...state,
        commission: defaultObj(state.commission), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_COMMISSION:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case CREATE_COMMISSION:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case POST_COMMISSION_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_COMMISSION_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_COMMISSION:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case ENABLE_COMMISSION_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        statusChanged: action.payload,
      }
    case COMMISSION_PURGE_SUCCESS:
      console.debug("PURGING")
      return INIT_STATE
    // return {
    //   ...state,
    //   accountEnquiry: {},
    //   commissionDetail:{},
    //   error:{},
    //   success:undefined
    // }

    default:
      return state
  }
  return state
}

export default AgentCommissions
