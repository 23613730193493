import { Container, Row, Col } from "reactstrap"
import Breadcrumb from "components/Common/Breadcrumb"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { useEffect, useMemo, useState } from "react"
import { ReportType } from "enums/report-type"
import { REPORTTYPE } from "helpers/variables"
import AgentSummaryReport from "./agent-summary-report"
import AgentVolumeComparatives from "./agent-volume-comparatives"
import CardtransferReport from "./cardtransfer-report"
import CardCashoutReport from "./card-cashout-report"
import CollectionReport from "./collection-report"
import FundtransferReport from "./fundtransfer-report"
import PaywithtransferReport from "./paywithtransfer-report"
import TransactionReport from "./transaction-report"
import { connect } from "react-redux"
import { withTranslation } from "react-i18next"
import { getMenu } from "store/actions"
import { Validators } from "helpers/validators"
import ClusterInformation from "components/Common/Information/Information"

const Reports = (props) => {
    const { menus, onGetPermissions } = props
    const activeReportTypes = useMemo(() => {
        const reportRoutes = menus?.find(item => item?.parent == 'Reports')?.children
        if (reportRoutes instanceof Array) {
            return reportRoutes.map(item => item?.name)
        }
    }, [menus])

    const reportItems = useMemo(() => {
        return REPORTTYPE.filter(report => activeReportTypes?.includes(report.value))
    }, [activeReportTypes])

    const [selectedReportType, setSelectedReportType] = useState<String>(reportItems.length > 0 ? reportItems[0].key : '')

    const renderContent = () => {
        switch (selectedReportType) {
            case ReportType.AgentSummary:
                return <AgentSummaryReport />
            case ReportType.AgentVolume:
                return <AgentVolumeComparatives />
            case ReportType.CardTransfer:
                return <CardtransferReport />
            case ReportType.POSCashOut:
                return <CardCashoutReport />
            case ReportType.Collections:
                return <CollectionReport />
            case ReportType.FundsTransfer:
                return <FundtransferReport />
            case ReportType.PayWithTransfer:
                return <PaywithtransferReport />
            case ReportType.Transactions:
                return <TransactionReport />
        }
    }

    useEffect(() => {
        renderContent()
    }, [selectedReportType])

    return <>
        <div className="page-content">
            <Container fluid>
                <Breadcrumb
                    title={"Report"}
                    breadcrumbItem={selectedReportType}
                />
                <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Col lg={3} md={6} sm={12}>
                        <ClusterDropdown
                            placeholder={"   Switch Report Type  "}
                            items={reportItems}
                            name={"reportType"}
                            onChange={(name, value) => {
                                setSelectedReportType(value)
                            }}
                        />
                    </Col>
                </Row>
                {reportItems.length > 0 ? renderContent() : 
                <ClusterInformation title='Access Denied' 
                description='You do not have this function selected for your user role' 
                className='w-50'
                />}
            </Container>
        </div>
    </>
}


const mapStateToProps = ({ permissions, error }) => {
    const err = Validators.extractError(permissions.error)
    return {
        menus: permissions.menus,
        error: err,
    }
}

const mapDispatchToProps = dispatch => ({
    onGetPermissions: () => dispatch(getMenu()),
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(Reports))