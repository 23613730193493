import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"
// images
import cluster from "../../../../assets/images/icons/cluster.png"
import networkmanagers from "../../../../assets/images/icons/network.png"
import agent from "../../../../assets/images/icons/clusterian.png"
import almighty from "../../../../assets/images/icons/saturn.png"
//Import Breadcrumb

//i18n
import {
  AGENT,
  CLUSTER,
  GLOBAL,
  NETWORKMANAGER,
  SCOPE,
} from "helpers/variables"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import FunctionList from "../function-Item"
import { autoGenerateCode, toTitleCase, Utils } from "helpers/utility"
import Loading from "components/Common/Loading"
import { Validators } from "helpers/validators"
import ClusterInformation from "components/Common/Information/Information"
import ClusterForm from "components/Common/Form"
import withFormValidation from "components/HOCs/WithFormValidation"
import {
  GET_ALL_CLUSTER_MANAGERS,
  GET_ALL_NETWORK_MANAGERS,
} from "helpers/url_helper"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { UserScope } from "enums/user-scope"
import { DropdownType } from "enums/dropdown-type"
const RoleForm = props => {
  let {
    id,
    errorList,
    myError,
    scope,
    scopeItems,
    isUpdate,
    loading,
    isRotating,
    roleLoading,
    scopeLoading,
    roleDetails,
    setErrorList,
    onUpdateSelection,
    handleOnChange,
    setIsRotating,
    handleValidSubmit,
  } = props

  const [code, setCode] = useState("")
  const [theScope, setScope] = useState(scope)

  const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [scopeItem, setScopeItem] = useState<any>()
  const [theScopeItems, setScopeItems] = useState(scopeItems)
  const safeScope = (role) => {
    return safeReplace(role?.scope);
  }
  const safeReplace = (value) => {
    return value?.replace(" ", "");
  }

  useEffect(() => {
    if (roleDetails && roleDetails.scopeItemId) {
      handleOnChange("scope", safeScope(roleDetails))
      setScope(safeScope(roleDetails))
      if (roleDetails.scope == UserScope.Cluster) {
        setScopeItem(
          (clusterManagers.data as any)?.Data.find(c => c.ID == roleDetails.scopeItemId)
            ?.Name
        )
      } else if (safeScope(roleDetails) == UserScope.NetworkManager) {
        setScopeItem(
          (networkManagers.data as any)?.Data.find(c => c.ID == roleDetails.scopeItemId)
            ?.Name
        )
      }
      setScope(safeScope(roleDetails))
    }
  }, [roleDetails, clusterManagers, networkManagers])

  useEffect(() => {
    setScopeItems(scopeItems)
  }, [props])

  useEffect(() => {
    setScope(safeReplace(scope))
  }, [scope])

  if (isUpdate && Utils.Object.isEmpty(roleDetails))
    return <Loading>
      <p>Getting Role ...</p>
    </Loading>

  return (
    <ClusterForm handleSubmit={handleValidSubmit}>

      <React.Fragment>
        <Row>
          <Col lg={6} md={12}>
            <Card
              style={{
                background: "transparent",
                borderColor: "transparent",
              }}
            >
              <CardBody>
                <Row>
                  <Col lg={12} md={6}>
                    <ClusterInput
                      defaultValue={roleDetails?.name}
                      onError={err =>
                        setErrorList({ ...errorList, name: err })
                      }
                      label={"Name"}
                      id={"name"}
                      name={"name"}
                      validators={[
                        Validators.minLength(
                          3,
                          "Minimum number of characters is 3"
                        ),
                      ]}
                      type="text"
                      required={true}
                      onHandleChange={(key, value) =>
                        handleOnChange(key, value)
                      }
                    ></ClusterInput>
                  </Col>
                  <Col lg={12} md={6}>
                    <ClusterInput
                      defaultValue={roleDetails?.description}
                      onError={err =>
                        setErrorList({ ...errorList, description: err })
                      }
                      label={"Description"}
                      id={"description"}
                      name={"description"}
                      validators={[
                        Validators.minLength(
                          3,
                          "Minimum number of characters is 3"
                        ),
                      ]}
                      type="text"
                      required={true}
                      onHandleChange={(key, value) =>
                        handleOnChange(key, value)
                      }
                    ></ClusterInput>
                  </Col>
                  <Col lg={12} md={6}>
                    <ClusterInput
                      onError={err =>
                        setErrorList({ ...errorList, code: err })
                      }
                      value={roleDetails?.code || code}
                      disabled
                      label={"Code"}
                      id={"code"}
                      name={"code"}
                      validators={[
                        Validators.minLength(
                          6,
                          "Minimum number of characters is 6"
                        ),
                      ]}
                      type="text"
                      required={true}
                      onHandleChange={(key, value) =>
                        handleOnChange(key, code)
                      }
                    >
                      <button
                        style={{
                          display: isUpdate ? "none" : "flex",
                          alignItems: "center",
                          padding: 0,
                          border: 0,
                        }}
                        className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                        onClick={e => {
                          e.preventDefault()
                          setIsRotating(true)
                          setTimeout(() => {
                            const cd = autoGenerateCode("USRL", 10)
                            setCode(cd)
                            handleOnChange("code", cd)
                            setErrorList({ ...errorList, code: "" })
                            setIsRotating(false)
                          }, 500)
                        }}
                      >
                        <i
                          className={`bx bx-revision ${isRotating ? "rotating_fast" : ""
                            }`}
                          style={{ marginRight: "5px" }}
                        ></i>{" "}
                        Auto Generate
                      </button>
                    </ClusterInput>
                  </Col>
                  <Col lg={12} md={6}>
                    <FormGroup>
                      <Label>Scope</Label>
                      <ClusterDropdown
                        defaultValue={
                          isUpdate ? roleDetails?.scope : "- Enter a scope - "
                        }
                        type={DropdownType.TypeAhead}
                        items={SCOPE}
                        useValue={false}
                        name="scope"
                        onChange={(name, value) => {
                          handleOnChange("scope", safeReplace(value))
                          setScopeItem("")
                          let list = { ...errorList, scope: "" }
                          if (value != UserScope.Global) {
                            list = {
                              ...errorList,
                              scope: "",
                            }
                          }
                          setErrorList(list)
                        }}
                      ></ClusterDropdown>
                    </FormGroup>
                  </Col>
                  {theScope === "*" || theScope === UserScope.Global ? (
                    theScope === UserScope.Global ? (
                      <ClusterInformation
                        image={almighty}
                        title={GLOBAL.title}
                        description={GLOBAL.description}
                      ></ClusterInformation>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )
                  ) : (
                    <React.Fragment>
                      <Col lg={12} md={6}>
                        <FormGroup>
                          {theScope == UserScope.Cluster ? (
                            <ClusterInformation
                              image={cluster}
                              title={CLUSTER.title}
                              description={CLUSTER.description}
                            ></ClusterInformation>
                          ) : (
                            theScope == UserScope.Agent ?
                              <ClusterInformation
                                image={agent}
                                title={AGENT.title}
                                description={AGENT.description}
                              ></ClusterInformation> :
                              <ClusterInformation
                                image={networkmanagers}
                                title={NETWORKMANAGER.title}
                                description={NETWORKMANAGER.description}
                              ></ClusterInformation>
                          )}

                        </FormGroup>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col lg={6} md={12} sm={12}>
            <FunctionList
              items={roleDetails?.permission?.functionItems}
              id={id}
              onHandleSelectionUpdate={onUpdateSelection}
              scope={safeReplace(theScope)}
            ></FunctionList>
          </Col>
        </Row>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={loading}
              disabled={myError != "" ? true : false}
              color={"primary"}
              addon={"bx bx-save"}
            >
              {isUpdate ? "Update" : "Save"}
            </ClusterButton>
          </Col>
        </Row>
      </React.Fragment>

    </ClusterForm>
  )
}
RoleForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  scope: PropTypes.string,
  scopeItems: PropTypes.array,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  roleLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  roleDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(RoleForm))
