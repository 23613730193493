import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { Card, Row, Col, CardBody, CardSubtitle } from "reactstrap"

//i18n
import { formatter, toTitleCase } from "helpers/utility"
import { graphqlQuery } from "helpers/api_helper"
import { useGraphql } from "hooks/useGraphql"
import Loading from "components/Common/Loading"
import withDispose from "components/HOCs/WithDispose"
import { GetInstitution } from "helpers/authentication/institution"
const SummaryCard: React.FC<any> = props => {
  const { title, field, trxType, currency, startDateStr, endDateStr, color, code } = props
  const [trxStatus, setTrxStatus] = useState("SUCCESSFUL")

  const institutionCode = GetInstitution().code
  const query = useMemo(() => {
    if (trxType.type === 'POSAGENTCASHOUT') {
      return `
      {
        analytics {
          ${field}(
            filter: {
              trxType: ${trxType.type}
              startDate: "${moment(startDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
              endDate: "${moment(endDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
              institutionCode: "${code}"
            }
          ) {
            cumulative
          }
        }
      }
      `
    }
    return `
    {
      analytics {
        ${field}(
          filter: {
            trxType: ${trxType.type}
            trxStatus: ${trxStatus}
            startDate: "${moment(startDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
            endDate: "${moment(endDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
            institutionCode: "${code}"
          }
        ) {
          cumulative
        }
      }
    }
    `
  }, [trxType, field, endDateStr, startDateStr, trxStatus, code])

  let [data, { loading }, setData, setLoading]: any = useGraphql(query, {}, [query])
 
  const format = value => {
    return formatter(value, currency ? true : false)
  }
  const getValue = data => {
    return data?.analytics[field]?.cumulative
  }

  const value = useMemo(() => {
    if (data) {
      return format(getValue(data))
    }
  }, [data])

  const reload=()=> {
    graphqlQuery(query, {}, [query]).then(response => {
      setData(response);
    })
  }
 
  useEffect(() => {
    let componentMounted = true;
    if (componentMounted) {

      setInterval(reload, 300000)
    }
    return () => {
      componentMounted = false;
    }
  }, [])

  return (
    <Card

      color={color}
      style={{ paddingTop: "0px", paddingBottom: "0px", position: "relative" }}
    >
      <CardBody
        style={{
          paddingTop: "10px",
          paddingBottom: "5px",
          position: "relative",
        }}
      >
        {!loading ? (
          <React.Fragment>
            <CardSubtitle style={{ marginBottom: "10px" }}>
              <small>{toTitleCase(title)}</small>
            </CardSubtitle>
            <Row>
              <Col lg={12}>
                <h5>{value}</h5>
              </Col>
            </Row>

          </React.Fragment>
        ) : (
          <Loading></Loading>
        )}
      </CardBody>
    </Card>
  )
}
SummaryCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.any,
  error: PropTypes.any,
}

export default withDispose(SummaryCard)
