import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_FEATURES,
  GET_FEATURE_DETAIL,
  ENABLE_FEATURE,
  CREATE_FEATURE,
  UPDATE_FEATURE,
  GET_FEATURES_BY_INSTITUTION,
  GET_ALL_FEATURES,
  PURGE_FEATURES
} from "./actionTypes"
import {
  getFeaturesSuccess,
  getFeaturesFail,
  getFeatureDetailSuccess,
  getFeatureDetailFail,
  purgeFeatureSuccess,
  updateFeatureSuccess,
  postFeatureSuccess,
  featureApiError,
  enableFeatureSuccess,
  getFeaturesByInstitutionSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {
  getFeatures,
  getFeatureDetail,
  putFeature,
  postFeature,
  enableFeature,
  disableFeature,
  getAllFeatures,
  getFeatureByInstitution
} from "helpers/backend_helper"
import institution from "pages/InstitutionManagement/institution"
const format = "yyyy-MM-DD";

function* fetchFeatures({ params }) {
  try {
    const response = yield call(getFeatures, JSON.parse(params))
    // let response = features;
    yield put(getFeaturesSuccess(response))
  } catch (error) {
    console.debug("Fetching Features Error", error)
    yield put(getFeaturesFail(error))
  }
}
function* fetchFeaturesByInstitution({ institutionCode }) {
  try {
    const response = yield call(getFeatureByInstitution, institutionCode)
    // let response = features;
    yield put(getFeaturesByInstitutionSuccess(response))
  } catch (error) {
    console.debug("Fetching Features Error", error)
    yield put(getFeaturesFail(error))
  }
}
function* fetchAllFeatures() {
  try {
    const response = yield call(getAllFeatures)
    yield put(getFeaturesSuccess(response))
  } catch (error) {
    console.debug("Fetching Features Error", error)
    yield put(getFeaturesFail(error))
  }
}

function* fetchFeatureDetail({ id }) {
  try {
    const response = yield call(getFeatureDetail, id);
    // const response = {Message:"Retrieved Successfully", Data: features.Data.Collection.find(c=>c.ID==id)}
    yield put(getFeatureDetailSuccess(response))
  } catch (error) {
    yield put(getFeatureDetailFail(error))
  }
}


function* createFeature({ payload: { feature } }) {
  try {

    const response = yield call(postFeature, { ...JSON.parse(JSON.stringify(feature)) })
    yield put(postFeatureSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(featureApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* updateFeature({ payload: { feature } }) {
  try {

    const response = yield call(putFeature, { ...JSON.parse(JSON.stringify(feature)) })

    yield put(updateFeatureSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(featureApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}



function* doEnableFeature(payload) {
  try {
    let toEnable = true;
    let cta = enableFeature;
    if (!payload.enable) { cta = disableFeature; toEnable = false }
    const response = yield call(cta, payload.id)

    yield put(enableFeatureSuccess({ ...response, enable: toEnable }))

  } catch (error) {
    console.debug(error)
    yield put(featureApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doPurge() {
  yield put(purgeFeatureSuccess())
}

function* featuresSaga() {
  yield takeLeading(GET_FEATURES, fetchFeatures)
   yield takeLeading(GET_FEATURE_DETAIL, fetchFeatureDetail)
   yield takeLeading(GET_FEATURES_BY_INSTITUTION,fetchFeaturesByInstitution)
  yield takeLeading(GET_ALL_FEATURES, fetchAllFeatures)
  yield takeEvery(PURGE_FEATURES, doPurge)
  yield takeLeading(ENABLE_FEATURE, doEnableFeature)
}

export default featuresSaga
