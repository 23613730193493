import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { saveAssignedTerminals, purgeTerminals } from "store/actions"
import TerminalAssignmentList from "./list/index"
import AssignTerminal from "./create/index"
import withDispose from "components/HOCs/WithDispose"
import { fetcher, get } from "helpers/api_helper"
import TerminalAssignmentLogic from "../terminal-assignment"
import ClusterButton from "components/Common/Button/Button"
import { GET_ALL_AFFILIATES } from "helpers/url_helper"
import useSWR from "swr"
import { GetInstitution } from "helpers/authentication/institution"

const TerminalAssignment = props => {
  const {
    terminalsToModify,
    onSaveConfiguration,
    editLoading,
    saved,
    onPurge,
  } = props
  const { MapToDropDown } = TerminalAssignmentLogic

  useEffect(() => {
    if (saved) {
      window.location.reload(false)
    }
  }, [saved])
  const saveConfiguration = () => {
    const assignedTerminals = terminalsToModify.map(t => ({
      AgentID: t.Agent.ID,
      TerminalID: t.Terminal.ID,
    }))

    onSaveConfiguration(assignedTerminals)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <AssignTerminal></AssignTerminal>
          <TerminalAssignmentList></TerminalAssignmentList>
          <ClusterButton
            className={"btn-lg"}
            buttonStyle={{
              width: "100%",
              marginTop: "10px",
              justifyContent: "center",
              height: "4em",
            }}
            color={"success"}
            loading={editLoading}
            onHandleClick={saveConfiguration}
            disabled={
              terminalsToModify && terminalsToModify.length > 0 ? false : true
            }
          >
            Save
          </ClusterButton>
        </React.Fragment>
      </div>
    </React.Fragment>
  )
}

TerminalAssignment.propTypes = {
  error: PropTypes.any,
  success: PropTypes.string,
  onSaveConfiguration: PropTypes.func,
  onPurge: PropTypes.func,
  terminalsToModify: PropTypes.array,
}

const mapStateToProps = ({
  terminals: {
    editLoading,
    assignedTerminals,
    terminalsToModify,
    error,
    success,
    saved,
  },
}) => {
  return {
    terminalsToModify,
    error,
    success,
    editLoading,
    saved,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(purgeTerminals()),
  onSaveConfiguration: payload => dispatch(saveAssignedTerminals(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(TerminalAssignment))))
