const userRoute = {
  parent: "Users",
  icon: "bx-user-circle",
  children: [
    { path: "/view-users", url: "/view-users", name: "View Users" },
    { path: "/create-user/:id", url: "/create-user/0", name: "Create User" },
  ],
}

const userRoleRoute = {
  parent: "User Roles",
  icon: "bx-cog",
  children: [
    {
      path: "/view-user-roles",
      url: "/view-user-roles",
      name: "View User Roles",
    },
    {
      path: "/create-user-role/:id",
      url: "/create-user-role/0",
      name: "Create a role",
    },
  ],
}
const agentRoute = {
  parent: "Agents",
  icon: "bx-user",
  children: [
    { path: "/view-agents", url: "/view-agents", name: "View Agents" },
    {
      path: "/create-agent/:id",
      url: "/create-agent/0",
      name: "Create an Agent",
    },

    { path: "/agent-overdrafts", url: "/agent-overdrafts", name: "Loans" },
  ],
}
const mobileaccountRoute = {
  parent: "Mobile Accounts",
  icon: "bx-money",
  children: [
    {
      path: "/view-mobile-accounts",
      url: "/view-mobile-accounts",
      name: "View Mobile Accounts",
    },
    { path: "/modify-account", url: "/modify-account", name: "Modify Account" },
  ],
}
const commissionRoute = {
  parent: "Commissions",
  icon: "bx-wallet",
  children: [
    {
      path: "/view-commissions",
      url: "/view-commissions",
      name: "View Commissions",
    },
    {
      path: "/commission-structure",
      url: "/commission-structure",
      name: "Create Structure",
    },
  ],
}
const caselogRoute = {
  parent: "Cases",
  icon: "bx-clipboard", //tag
  children: [
    { path: "/view-caselogs", url: "/view-caselogs", name: "View Case Logs" },
  ],
}
const chargeBackRoute = {
  parent: "Charge Backs",
  icon: "bx-credit-card", //bx-highlight
  children: [
    { path: "/chargeback-claims", url: "/chargeback-claims", name: "Claims" },
    { path: "/receipts", url: "/receipts", name: "Receipts" },
  ],
}
const defaultRoute = {
  parent: "",
  icon: undefined,
  children: [{ path: "/", url: "/", name: "" }],
  default: true,
}
const profileRoute = {
  parent: "",
  icon: "bx-medal",
  children: [
    { path: "/profile", url: "/profile", name: "Profile" },
    {
      path: "/view-caselog/:id",
      url: "/view-caselog/0",
      name: "Case Log Details",
    },
  ],
}
const logoutRoute = {
  parent: "",
  icon: undefined,
  children: [{ path: "/logout", url: "/logout", name: "" }],
  default: false,
}
const remoteRoute = {
  parent: "Remote",
  icon: "bx-globe",
  children: [
    { path: "/remote", url: "/remote", name: "Remote To Institution" },
  ],
}
const clusterRoute = {
  parent: "Clusters",
  icon: "bx-grid",
  children: [
    { path: "/view-clusters", url: "/view-clusters", name: "View Clusters" },
    {
      path: "/create-cluster/:id",
      url: "/create-cluster/0",
      name: "Create Cluster",
    },
  ],
}
const terminalRoute = {
  parent: "Terminals",
  icon: "bx-grid",
  children: [
    { path: "/view-terminals", url: "/view-terminals", name: "View Terminals" },
    {
      path: "/create-terminal/:id",
      url: "/create-terminal/0",
      name: "Create Terminal",
    },
    {
      path: "/terminal-assignment",
      url: "/terminal-assignment",
      name: "Assignment",
    },
    {
      path: "/terminal-assignment-service-provider",
      url: "/terminal-assignment-service-provider",
      name: "Assignment Service Provider",
    },
    {
      path: "/terminal-batch-upload",
      url: "/terminal-batch-upload",
      name: "Batch Upload",
    },
    {
      path: "/terminal-assignment-report",
      url: "/terminal-assignment-report",
      name: "Assignment Report",
    },
  ],
}
const subscriptionPlanRoute = {
  parent: "Subscriptions",
  icon: "bx-grid",
  children: [
    {
      path: "/view-subscription-plans",
      url: "/view-subscription-plans",
      name: "View Subscriptions",
    },
    {
      path: "/create-subscriptionPlan",
      url: "/create-subscriptionPlan",
      name: "Create Plan",
    },
    { path: "/view-subscriptions", url: "/view-subscriptions", name: "Report" },
  ],
}
const audittrailRoute = {
  parent: "Audit Trail",
  icon: "bx-grid",
  children: [
    {
      path: "/view-audit-trail",
      url: "/view-audit-trail",
      name: "View Audit Trail",
    },
  ],
}

const agentCategoryRoute = {
  parent: "Categories",
  icon: "bx-grid-alt",
  children: [
    {
      path: "/view-categories",
      url: "/view-categories",
      name: "View Categories",
    },
    {
      path: "/create-category/:id",
      url: "/create-category/0",
      name: "Create Category",
    },
  ],
}
const agentClusterRoute = {
  parent: "Cluster Manager",
  icon: "bx-command",
  children: [
    {
      path: "/view-cluster-managers",
      url: "/view-cluster-managers",
      name: "View Cluster Manager",
    },
    {
      path: "/create-cluster-manager/:id",
      url: "/create-cluster-manager/0",
      name: "Create Cluster Manager",
    },
  ],
}
const agentNetworkRoute = {
  parent: "Network Managers",
  icon: "bx-selection",
  children: [
    { path: "/view-anms", url: "/view-anms", name: "View ANMs" },
    { path: "/create-anm/:id", url: "/create-anm/0", name: "Create ANM" },
  ],
}
const dashboardRoute = {
  parent: "Dashboards",
  icon: "bx-grid-alt",
  children: [
    {
      path: "/performance-highlights",
      url: "/performance-highlights",
      name: "Performance Highlights",
    },
    { path: "/comparatives", url: "/comparatives", name: "Comparatives" },
    {
      path: "/service-quality",
      url: "/service-quality",
      name: "Service Quality",
    },
    { path: "/agent-tracker", url: "/agent-tracker", name: "Agent Tracker" },
  ],
}
const directDebitRoute = {
  parent: "Direct Debit",
  icon: "bx bx-dollar-circle",
  children: [
    {
      path: "/view-direct-debit-requests",
      name: "View Debit Requests",
      url: "/view-direct-debit-requests",
    },
    {
      path: "/upload-direct-debit",
      name: "Upload",
      url: "/upload-direct-debit",
    },
  ],
}

const notificationRoute = {
  parent: "Notification",
  icon: "bx bx-dollar-circle",
  children: [
    {
      path: "/view-notifications-history",
      name: "Notification History",
      url: "/view-notifications-history",
    },
    {
      path: "/create-notification",
      name: "Create Notification",
      url: "/create-notification",
    },
  ],
}

const reportRoute = {
  parent: "Reports",
  icon: "bx-spreadsheet",
  children: [
    { path: "/cashout-report", url: "/cashout-report", name: "Cash Out" },
    {
      path: "/fundstransfer-report",
      url: "/fundstransfer-report",
      name: "Funds Transfer",
    },
    {
      path: "/transaction-report",
      url: "/transaction-report",
      name: "Transaction",
    },
    {
      path: "/agent-summary",
      url: "/agent-summary",
      name: "Agent Summary",
    },
    {
      path: "/agent-volume-comparative",
      url: "/agent-volume-comparative",
      name: "Volume Comparatives",
    },
    {
      path: "/collection-report",
      url: "/collection-report",
      name: "Collections",
    },
    {
      path: "/pay-with-transfer-report",
      url: "/pay-with-transfer-report",
      name: "Pay With Transfer",
    },
  ],
}
const downloadRoute = {
  parent: "Downloads",
  icon: "bx-download",
  children: [
    { path: "/log-report", url: "/log-report", name: "Log" },
    { path: "/download-report", url: "/download-report", name: "Download" },
  ],
}
const configurationRoute = {
  parent: "Configuration",
  icon: "bx-download",
  children: [
    {
      path: "/routing-configuration",
      url: "/routing-configuration",
      name: "Routing Configuration",
    },
  ],
}
const institutionRoute = {
  parent: "Configuration",
  icon: "bx-download",
  children: [
    {
      path: "/service-configuration",
      url: "/service-configuration",
      name: "Service Configuration",
    },
  ],
}
const settlementRoute = {
  parent: "Settlement",
  icon: "bx-wallet",
  children: [
    {
      path: "/settlement-account",
      url: "/settlement-account",
      name: "Accounts",
    },
    {
      path: "/settlement-withdrawal",
      url: "/settlement-withdrawal",
      name: "Withdrawal",
    },
    {
      path: "/settlement-approval-configuration",
      url: "/settlement-approval-configuration",
      name: "Approver",
    },
    {
      path: "/settlement-configuration",
      url: "/settlement-configuration",
      name: "Configuration",
    },
    {
      path: "/wallet-top-ups",
      url:  "/wallet-top-ups",
      name: "Transactions",
    },
    
  ],
}

export default {
  defaultRoute,
  fullRoute: [defaultRoute, profileRoute, logoutRoute],
  remoteRoute
}
