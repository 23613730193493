/* CATEGORIES */
export const GET_CATEGORIES = "GET_CATEGORIES"
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS"
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL"

export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL"
export const GET_CATEGORY_DETAIL_SUCCESS = "GET_CATEGORY_DETAIL_SUCCESS"
export const GET_CATEGORY_DETAIL_FAIL = "GET_CATEGORY_DETAIL_FAIL"

export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES"
export const CREATE_CATEGORY = "CREATE_CATEGORY"
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS"
export const POST_CATEGORY_FAIL = "POST_CATEGORY_FAIL"

export const CATEGORY_PURGE = "CATEGORY_PURGE"
export const CATEGORY_PURGE_SUCCESS = "CATEGORY_PURGE_SUCCESS"

export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS"
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL"

export const ENABLE_CATEGORY = "ENABLE_CATEGORY"
export const ENABLE_CATEGORY_SUCCESS = "ENABLE_CATEGORY_SUCCESS"
