import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import moment from "moment"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Badge,
} from "reactstrap"

// Pages Components
import Report from "./report"

//i18n
import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"

import {
  mobileAccountPurge,
  getMobileAccounts,
  getMobileAccountByPhoneNumber,
  resetPin,
  resetPinTries,
  hotlist,
  generateVerificationCode,
  enableMobileAccount,
  sendActivationCode,
  resetPassword,
  resetPasswordTries,
} from "store/actions"

import MobileAccountLogic from "../../mobileaccount"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ClusterButton from "components/Common/Button/Button"
import Actions from "pages/MobileAccountManagement/actions"
import { MOBILEACCOUNT_PURGE } from "store/mobile-account/actionTypes"

const ModifyAccountDetails = props => {
  const {
    setIsValidated,
    mobileAccounts,
    account,
    statusChanged,
    onPurge,
    error,
    success,
    mobileAccountDetail,
    editLoading,
    onGetMobileAccountDetail,
    onEnableMobileAccount,
    onResetPin,
    onResetPinTries,
    onResetPassword,
    onResetPasswordTries,
    onHotList,
    onVerification,
    onActivation,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    hotListLoading,
    verificationLoading,
    activationLoading,
  } = props
  const {
    MapToRowsBankAccount,
    BANKACCOUNTHEADERS,
    GetAccountStatus,
    GetAccountStatusBadge,
    DateActivated,
  } = MobileAccountLogic

  const [rows, setRows] = useState([])
  const [viewedAccount, setViewedAccount] = useState([])
  const [columns, setColumns] = useState([...BANKACCOUNTHEADERS])
  const [isEnabled, setIsEnabled] = useState(false)
  useEffect(() => {
    populateRows()
  }, [mobileAccounts])

  useEffect(() => {
    if (account && Object.keys(account).length > 0) {
      setViewedAccount(account)
    }
  }, [account])

  const getIsEnabled = () => {
    let enabled = false
    if (mobileAccountDetail?.IsActive || statusChanged == true) enabled = true
    setIsEnabled(enabled)
  }
  useEffect(() => {
    getIsEnabled()
    setViewedAccount(mobileAccountDetail)
  }, [statusChanged, mobileAccountDetail])
  useEffect(() => {
    if (success) toast.success(success)
  }, [statusChanged])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])
  const populateRows = () => {
    if (account.BankAccounts) {
      let rws = []
      account.BankAccounts.forEach(acc => {
        let row = MapToRowsBankAccount(acc)
        rws.push(row)
      })

      setRows(rws)
    }
  }
  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Mobile Wallet Details"></CardHeader>
        <CardBody>
          <Row>
            <Col lg={3}>
              <p>Last Name</p>
              <p>
                <b>{viewedAccount.LastName}</b>
              </p>
            </Col>
            <Col lg={3}>
              <p>Other Names</p>
              <p>
                <b>{viewedAccount.OtherNames}</b>
              </p>
            </Col>
            <Col lg={3}>
              <p>Status</p>
              <div>
                <Badge
                  color={GetAccountStatusBadge(
                    viewedAccount.MobileAccountStatus
                  )}
                >
                  {viewedAccount.MobileAccountStatus}
                </Badge>
              </div>
            </Col>
            <Col lg={3}>
              <p>Mobile Phone</p>
              <p>
                <b>{viewedAccount.MobilePhone}</b>
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <p>Opened By</p>
              <p>
                <b>{viewedAccount.OpenedBy}</b>
              </p>
            </Col>
            <Col lg={3}>
              <p>Date Activated</p>
              <p>
                <b>{DateActivated(viewedAccount.DateActivated)}</b>
              </p>
            </Col>
            <Col lg={3}>
              <p>Date Created</p>
              <p>
                <b>{viewedAccount.DateCreated}</b>
              </p>
            </Col>
          </Row>
        </CardBody>

        <CardHeader title="Customer Details"></CardHeader>
        <CardBody>
          <Row>
            <Col lg={3}>
              <p>Last Name</p>
              <p>
                <b>{viewedAccount.LastName}</b>
              </p>
            </Col>
            <Col lg={3}>
              <p>Other Names</p>
              <p>
                <b>{viewedAccount.OtherNames}</b>
              </p>
            </Col>
          </Row>
        </CardBody>

        <CardHeader title="Bank Accounts"></CardHeader>
        <CardBody>
          <Row>
            <Col>
              <h5>
                <b>Bank Accounts</b>
              </h5>
            </Col>
          </Row>
          <Report
            columns={columns}
            rows={
              rows?.map(acc => {
                return {
                  ...acc,
                  gender: viewedAccount?.TheGender,
                  product: viewedAccount?.ProductName,
                }
              }) || []
            }
          />
        </CardBody>
        <CardFooter>
          <Actions
            account={viewedAccount}
            editLoading={editLoading}
            onGetMobileAccountDetail={onGetMobileAccountDetail}
            onEnableMobileAccount={onEnableMobileAccount}
            onResetPin={onResetPin}
            onResetPinTries={onResetPinTries}
            onResetPassword={onResetPassword}
            onResetPasswordTries={onResetPasswordTries}
            onHotList={onHotList}
            onVerification={onVerification}
            onActivation={onActivation}
            resetPinLoading={resetPinLoading}
            resetPinTriesLoading={resetPinTriesLoading}
            resetPasswordLoading={resetPasswordLoading}
            resetPasswordTriesLoading={resetPasswordTriesLoading}
            hotListLoading={hotListLoading}
            verificationLoading={verificationLoading}
            activationLoading={activationLoading}
            isEnabled={isEnabled}
          />
          <div style={{ display: "inline-block", marginRight: "10px" }}>
            <ClusterButton
              type="button"
              color="secondary"
              onHandleClick={() => {
                // setmodal(!modal)
                // console.debug(onPurge)
                // if(onPurge) onPurge()
                mobileAccountPurge({ type: MOBILEACCOUNT_PURGE })
                setIsValidated(false)
              }}
            >
              Back
            </ClusterButton>
          </div>
        </CardFooter>
      </Card>
    </React.Fragment>
  )
}

ModifyAccountDetails.propTypes = {
  mobileAccounts: PropTypes.array,
  mobileAccountDetail: PropTypes.object,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  onEnableMobileAccount: PropTypes.func,
  onResetPin: PropTypes.func,
  onResetPinTries: PropTypes.func,
  onResetPassword: PropTypes.func,
  onResetPasswordTries: PropTypes.func,
  onHotList: PropTypes.func,
  onVerification: PropTypes.func,
  onActivation: PropTypes.func,
}

const mapStateToProps = ({
  mobileAccounts: {
    mobileAccounts,
    error,
    success,
    mobileAccountDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    hotListLoading,
    verificationLoading,
    activationLoading,
  },
}) => {
  // console.debug(mobileAccounts, error, mobileAccountDetail, totalCount, loading)
  return {
    mobileAccounts: [...mobileAccounts],
    error,
    success,
    mobileAccountDetail: mobileAccountDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    hotListLoading,
    verificationLoading,
    activationLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(mobileAccountPurge()),
  onGetMobileAccountDetail: phoneNumber =>
    dispatch(getMobileAccountByPhoneNumber(phoneNumber)),
  onEnableMobileAccount: (id, enable) =>
    dispatch(enableMobileAccount(id, enable)),
  onResetPin: id => dispatch(resetPin(id)),
  onResetPinTries: id => dispatch(resetPinTries(id)),
  onResetPassword: id => dispatch(resetPassword(id)),
  onResetPasswordTries: id => dispatch(resetPasswordTries(id)),
  onHotList: id => dispatch(hotlist(id)),
  onVerification: id => dispatch(generateVerificationCode(id)),
  onActivation: id => dispatch(sendActivationCode(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(ModifyAccountDetails))))
