import { defaultObj } from "helpers/utility"
import {
  GET_MOBILEACCOUNT_DETAIL_SUCCESS,
  GET_MOBILEACCOUNT_DETAIL_FAIL,
  GET_MOBILEACCOUNTS_SUCCESS,
  GET_MOBILEACCOUNTS_FAIL,
  MOBILEACCOUNT_PURGE,
  ENABLE_MOBILEACCOUNT,
  ENABLE_MOBILEACCOUNT_SUCCESS,
  MOBILEACCOUNT_PURGE_SUCCESS,
  GET_MOBILEACCOUNTS,
  POST_MOBILEACCOUNT_SUCCESS,
  UPDATE_MOBILEACCOUNT,
  CREATE_MOBILEACCOUNT,
  UPDATE_MOBILEACCOUNT_SUCCESS,
  POST_MOBILEACCOUNT_FAIL,
  GET_ALL_MOBILEACCOUNTS,
  GET_MOBILEACCOUNT_BY_PHONE,
  GET_MOBILEWALLET_DETAIL_FAIL,
  GET_MOBILEWALLET_DETAIL_SUCCESS,
  GET_MOBILEWALLET_DETAIL,
  GET_MOBILEWALLETS_FAIL,
  GET_MOBILEWALLETS_SUCCESS,
  GET_ALL_MOBILEWALLETS,
  GET_MOBILEWALLETS,
  RESET_PIN,
  RESET_PIN_FAIL,
  RESET_PIN_SUCCESS,
  RESET_PIN_TRIES,
  RESET_PIN_TRIES_FAIL,
  RESET_PIN_TRIES_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TRIES,
  RESET_PASSWORD_TRIES_FAIL,
  RESET_PASSWORD_TRIES_SUCCESS,
  HOTLIST,
  HOTLIST_FAIL,
  HOTLIST_SUCCESS,
  GENERATE_VERIFICATION_CODE,
  GENERATE_VERIFICATION_CODE_FAIL,
  GENERATE_VERIFICATION_CODE_SUCCESS,
  GET_MOBILEACCOUNT_DETAIL,
  SEND_ACTIVATION_CODE,
  SEND_ACTIVATION_CODE_FAIL,
  SEND_ACTIVATION_CODE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  mobileAccounts: [],
  mobileAccountDetail: {},
  mobileWallets: [],
  mobileWalletDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  resetPinLoading: false,
  resetPinTriesLoading: false,
  resetPasswordLoading: false,
  resetPasswordTriesLoading: false,
  hotListLoading: false,
  verificationLoading: false,
  statusChanged: undefined,
  detailLoading: false,
}

const MobileAccounts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MOBILEACCOUNTS_SUCCESS:
      return {
        ...state,
        mobileAccounts:
          action.payload?.Data?.Collection || action.payload?.Data || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_MOBILEACCOUNTS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_MOBILEACCOUNT_DETAIL:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_MOBILEACCOUNTS:
      return {
        ...state,
        loading: true,
      }
    case GET_MOBILEACCOUNTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_MOBILEACCOUNT_DETAIL_SUCCESS:
      return {
        ...state,
        mobileAccountDetail: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
        editLoading: false,
        detailLoading: false,
      }

    case GET_MOBILEACCOUNT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        success:undefined,
        loading: false,
        detailLoading:false,
        editLoading: false,
      }
    case GET_MOBILEACCOUNT_BY_PHONE:
      return {
        ...state,
        detailLoading: true,
      }
    case GET_MOBILEWALLETS_SUCCESS:
      return {
        ...state,
        mobileWallets: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_MOBILEWALLETS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }

    case GET_ALL_MOBILEWALLETS:
      return {
        ...state,
        loading: true,
      }
    case GET_MOBILEWALLETS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_MOBILEWALLET_DETAIL_SUCCESS:
      return {
        ...state,
        mobileWalletDetail: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
      }

    case GET_MOBILEWALLET_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case POST_MOBILEACCOUNT_SUCCESS:
      return {
        ...state,
        mobileAccount: defaultObj(state.mobileAccount), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_MOBILEACCOUNT:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case CREATE_MOBILEACCOUNT:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }

    case POST_MOBILEACCOUNT_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break

    case UPDATE_MOBILEACCOUNT_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_MOBILEACCOUNT:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
      break
    case ENABLE_MOBILEACCOUNT_SUCCESS:
      let detail = state.mobileAccountDetail
      detail.IsActive = action.payload?.enable
      let mobileAccounts = state.mobileAccounts
      if (detail) {
        mobileAccounts.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        mobileAccounts,
        editLoading: false,
        loading: false,
        error: "",
        mobileAccountDetail: detail,
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case MOBILEACCOUNT_PURGE_SUCCESS:
      return INIT_STATE

    case RESET_PIN:
      return {
        ...state,
        resetPinLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case RESET_PIN_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case RESET_PIN_SUCCESS:
      return {
        ...state,
        resetPinLoading: false,
        loading: false,
        error: "",
        success: action.payload?.Message,
        statusChanged: true,
      }

      case RESET_PASSWORD:
        return {
          ...state,
          resetPasswordLoading: true,
          error: undefined,
          success: undefined,
          statusChanged: false,
        }
      case RESET_PASSWORD_FAIL:
        state = {
          ...state,
          error: action.payload?.Message,
          loading: false,
          editLoading: false,
          resetPasswordLoading: false,
          resetPasswordTriesLoading: false,
          hotListLoading: false,
          verificationLoading: false,
        }
        break
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          resetPasswordLoading: false,
          loading: false,
          error: "",
          success: action.payload?.Message,
          statusChanged: true,
        }

    case SEND_ACTIVATION_CODE:
      return {
        ...state,
        activationLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case SEND_ACTIVATION_CODE_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case SEND_ACTIVATION_CODE_SUCCESS:
      return {
        ...state,
        activationLoading: false,
        loading: false,
        error: "",
        success: "Activation Code sent successfully",
      }
    case RESET_PIN_TRIES:
      return {
        ...state,
        resetPinTriesLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case RESET_PIN_TRIES_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case RESET_PIN_TRIES_SUCCESS:
      return {
        ...state,
        resetPinTriesLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }

      

      case RESET_PASSWORD_TRIES:
      return {
        ...state,
        resetPasswordTriesLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case RESET_PASSWORD_TRIES_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPasswordLoading: false,
        resetPasswordTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case RESET_PASSWORD_TRIES_SUCCESS:
      return {
        ...state,
        resetPasswordTriesLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }


    case HOTLIST:
      return {
        ...state,
        hotListLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case HOTLIST_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case HOTLIST_SUCCESS:
      let hotListDetail = state.mobileAccountDetail
      hotListDetail.MobileAccountStatus = "HotListed"
      return {
        ...state,
        hotListLoading: false,
        loading: false,
        error: "",
        mobileAccountDetail: hotListDetail,
        statusChanged: true,
        success: action.payload?.Message,
      }
    case GENERATE_VERIFICATION_CODE:
      return {
        ...state,
        verificationLoading: true,
        error: undefined,
        success: undefined,
        statusChanged: false,
      }
    case GENERATE_VERIFICATION_CODE_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetPinLoading: false,
        resetPinTriesLoading: false,
        hotListLoading: false,
        verificationLoading: false,
      }
      break
    case GENERATE_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        verificationLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    default:
      return state
  }
  return state
}

export default MobileAccounts
