import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  chargeBackPurge,
  getChargeBackDetail,
  getChargeBacks,
  regenerateChargeBackToken,
  updateChargeBack,
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import ChargeBackLogic from "../charge-back"
import { GET_ALL_CATEGORIES, GET_ALL_CHARGEBACKS } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import useSWR from "swr"
import ClusterModal from "components/Common/Modals/Modal"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import useReportData from "hooks/UseReportData"
import { GENERATE_TOKEN } from "../permission"
import { useInstitutionsData } from "hooks/useInstitutionData"

type ViewedClaim = {
  DisputeStatus : String,
  ID : Number,
  CashOutDate : any
}

const ChargeBackList = props => {
  const {
    claims,
    onGetClaims,
    error,
    success,
    claimDetail,
    onGetClaimDetail,
    totalCount,
    statusChanged,
    onStatusUpdate,
    onRegenerate,
    pendingLoading,
    tokenLoading,
    approvedLoading,
    reverseLoading,
    loading,
  } = props
  const {
    HEADERS,
    MapToModalState,
    MapToRows,
    SearchCriteria,
    SearchOptions,
    GetDisputeStatus,
  } = ChargeBackLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ onGetData: onGetClaims, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const categories = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })

  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, (categories?.data as any)?.Data, institutionsData)
  )

  const [viewedClaim, setViewedClaim] = useState<ViewedClaim>()

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    setSearchOptions(
      SearchOptions(params?.fromDate, params?.toDate, (categories?.data as any)?.Data, institutionsData)
    )
  }, [categories])

  useEffect(() => {
    populateRows()
  }, [claims, props])

  useEffect(() => {
    if (claimDetail && Object.keys(claimDetail).length > 0) {
      setmodal(true)
      setViewedClaim(claimDetail)
      setModalData(MapToModalState(claimDetail))
    }
  }, [claimDetail])

  useEffect(() => {
    search(SearchCriteria(params, maxSize))
  }, [statusChanged])

  const populateRows = () => {
    if (claims) {
      let rws = []
      claims.forEach(claim => {
        let row = MapToRows(claim)
        row["action"] = (
          <ClusterButton
            type="button"
            color="primary"
            className="btn-rounded waves-effect waves-light btn-sm"
            onHandleClick={() => onViewDetails(claim.ID)}
          >
            View Details
          </ClusterButton>
        )
        rws.push(row)
      })
      setRows(rws)
    }
  }

  // function getIsEnabled() {
  //   let enabled = false
  //   if (viewedChargeBack?.IsActive || statusChanged == true) enabled = true
  //   setIsEnabled(enabled)
  // }

  function onViewDetails(id) {
    setmodal(true)
    onGetClaimDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"ChargeBacks"}
            breadcrumbItem={"View ChargeBacks"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CHARGEBACKS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={GetDisputeStatus(viewedClaim?.DisputeStatus)}
          status={viewedClaim?.DisputeStatus}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Charge Back Details"}
        >
          {viewedClaim?.DisputeStatus == "NotProcessed" ? (
            <ClusterButton
              loading={pendingLoading}
              type="button"
              outline
              onHandleClick={() => onStatusUpdate(viewedClaim?.ID, "Pending")}
              color="success"
            >
              Update Status
            </ClusterButton>
          ) : null}
          {viewedClaim?.DisputeStatus == "Pending" ? (
            <React.Fragment>
              <ClusterButton
                loading={approvedLoading}
                type="button"
                outline
                onHandleClick={() =>
                  onStatusUpdate(viewedClaim?.ID, "ResolvedApproved")
                }
                color="success"
              >
                Resolved Approved
              </ClusterButton>

              <ClusterButton
                loading={reverseLoading}
                type="button"
                outline
                onHandleClick={() =>
                  onStatusUpdate(viewedClaim?.ID, "ResolvedReversed")
                }
                color="info"
              >
                Resolved Reversed
              </ClusterButton>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {viewedClaim?.DisputeStatus == "ResolvedApproved" &&
          !viewedClaim?.CashOutDate ? (
            <ClusterButton
              permissions={[GENERATE_TOKEN]}
              loading={tokenLoading}
              type="button"
              outline
              onHandleClick={() => onRegenerate(viewedClaim?.ID)}
              color="warning"
            >
              Regenerate Token
            </ClusterButton>
          ) : null}
          <ClusterButton
            type="button"
            color="secondary"
            onHandleClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </ClusterButton>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

ChargeBackList.propTypes = {
  claims: PropTypes.array,
  claimDetail: PropTypes.object,
  onGetClaims: PropTypes.func,
  onGetClaimDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  chargeBacks: {
    claims,
    error,
    success,
    claimDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    approvedLoading,
    reverseLoading,
    pendingLoading,
    tokenLoading,
  },
}) => {
  return {
    claims: [...claims],
    error,
    success,
    claimDetail: claimDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    approvedLoading,
    pendingLoading,
    tokenLoading,
    reverseLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetClaims: query => dispatch(getChargeBacks(query)),
  onGetClaimDetail: id => dispatch(getChargeBackDetail(id)),
  onPurge: () => dispatch(chargeBackPurge()),
  onStatusUpdate: (id, status) => dispatch(updateChargeBack(id, status)),
  onRegenerate: id => dispatch(regenerateChargeBackToken(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ChargeBackList))))
