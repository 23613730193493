import React, { useState, useEffect } from "react"
import withFormValidation from "components/HOCs/WithFormValidation"
import ClusterForm from "components/Common/Form"
import ClusterInput from "components/Common/Input/Input"
import ClusterButton from "components/Common/Button/Button"
import { CardBody, Card, Row, Col } from "reactstrap"
import { Validators } from "helpers/validators"

const PhoneNumberValidateForm = props => {
  let {
    errorList,
    apiSuccess,
    apiError,
    myError,
    loading,
    setErrorList,
    handleOnChange,
    handleValidSubmit,
  } = props
  return (
    <ClusterForm handleSubmit={handleValidSubmit}>
      <React.Fragment>
        <Card
          outline
          style={{ background: "transparent", borderColor: "#999999" }}
          className="border"
        >
          <CardBody>
            <Row>
              <Col lg={3} md={4}>
                <ClusterInput
                  focus
                  onError={err =>
                    setErrorList({ ...errorList, phoneNumber: err })
                  }
                  label={"Enter the phone number of the account"}
                  id={"phoneNumber"}
                  name={"phoneNumber"}
                  validators={[
                    Validators.phone([
                      "Only numbers allowed",
                      "Must be 11 digits",
                    ]),
                  ]}
                  type="number"
                  required={true}
                  onHandleChange={(key, value) => handleOnChange(key, value)}
                ></ClusterInput>
              </Col>
            </Row>
            <Row style={{ paddingBottom: "50px" }}>
              <Col lg={12}>
                <ClusterButton
                  loading={loading}
                  disabled={myError != "" ? true : false}
                  color={"primary"}
                  addon={"bx bx-save"}
                >
                  Next
                </ClusterButton>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </React.Fragment>
    </ClusterForm>
  )
}

export default withFormValidation(PhoneNumberValidateForm)
