import { call, put, takeEvery, takeLeading } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CASHOUTREPORTS,
  GET_COMPARATIVESREPORTS,
  GET_DOWNLOAD_REPORTS,
  GET_FUNDSTRANSFERREPORTS,
  GET_RECEIPTS,
  GET_REPORTS,
  GET_SUMMARYREPORTS,
  GET_TRANSACTION_REPORTS,
  GET_COLLECTION_REPORTS,
  LOG_DOWNLOAD,
  REPORT_PURGE,
  GET_PAYWITHTRANSFER_REPORTS,
  GET_CARDTRANSFER_REPORTS,
} from "./actionTypes"
import {
  getReceiptsSuccess,
  getReceiptsFail,
  getReportsSuccess,
  getReportsFail,
  getCashOutReportsFail,
  getCashOutReportsSuccess,
  getTransactionReportsFail,
  getTransactionReportsSuccess,
  getCollectionReportsFail,
  getCollectionReportsSuccess,
  reportPurgeSuccess,
  logForDownloadFail,
  logForDownloadSuccess,
  getDownloadReportsFail,
  getDownloadReportsSuccess, getComparativesReportsFail, getComparativesReportsSuccess, getFundsTransferReportsFail, getFundsTransferReportsSuccess, getPayWithTransferReportsFail, getSummaryReportsFail, getSummaryReportsSuccess,
  getPayWithTransferReportsSuccess,
  getCardTransferReportsSuccess,
  getCardTransferReportsFail
} from "./actions"

//Include Both Helper File with needed methods
import {
  getReceipts,
  getReports,
  getCashOutReport,
  getTransactionReport,
  getCollectionReport,
  logForDownload,
  getDownloadedReports,
  getTransactionSummaryReport,
  getVolumeComparativesReport,
  getFundsTransferReport,
  getPayWithTransferReport,
  getCardTransferReport
} from "helpers/backend_helper"

function* fetchReceipts({ params }) {
  try {
    const response = yield call(getReceipts, JSON.parse(params))
    yield put(getReceiptsSuccess(response))
  } catch (error) {
    console.debug("Fetching Receipts Error", error)
    yield put(getReceiptsFail(error.data))
  }
}
function* fetchReports({ query, variables, deps, field, trxType }) {
  try {
    // console.debug("Fetching")
    const response = yield call(getReports, query, variables, deps)

    let d = {}
    d[`${field}`] = response
    yield put(getReportsSuccess(d))
  } catch (error) {
    console.debug(error)
    yield put(getReportsFail(error))
  }
}
function* fetchCashOutTransactions({ params }) {
  try {
    const response = yield call(getCashOutReport, JSON.parse(params))
    yield put(getCashOutReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Cash Out Transactions Error", error)
    yield put(getCashOutReportsFail(error.data))
  }
}
function* fetchFundsTransferTransactions({ params }) {
  try {
    const response = yield call(getFundsTransferReport, JSON.parse(params))
    yield put(getFundsTransferReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Cash Out Transactions Error", error)
    yield put(getFundsTransferReportsFail(error.data))
  }
}
function* fetchDownloadedReport({ params }) {
  try {
    const response = yield call(getDownloadedReports, JSON.parse(params))
    yield put(getDownloadReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Downloaded Reports Error", error)
    yield put(getDownloadReportsFail(error.data))
  }
}
function* fetchTransactions({ params }) {
  try {
    const response = yield call(getTransactionReport, JSON.parse(params))
    yield put(getTransactionReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Transactions Error", error)
    yield put(getTransactionReportsFail(error.data))
  }
}
function* fetchPayWithTransferTransactions({ params }) {
  try {
    const response = yield call(getPayWithTransferReport, JSON.parse(params))
    yield put(getPayWithTransferReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Transactions Error", error)
    yield put(getPayWithTransferReportsFail(error.data))
  }
}
function* fetchCardTransferTransactions({ params }) {
  try {
    const response = yield call(getCardTransferReport, JSON.parse(params))
    yield put(getCardTransferReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Transactions Error", error)
    yield put(getCardTransferReportsFail(error.data))
  }
}
function* fetchCollections({ params }) {
  try {
    const response = yield call(getCollectionReport, JSON.parse(params))
    yield put(getCollectionReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Collections Error", error)
    yield put(getCollectionReportsFail(error.data))
  }
}
function* fetchTransactionSummary({ params }) {
  try {
    const response = yield call(getTransactionSummaryReport, JSON.parse(params))
    yield put(getSummaryReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Transaction Summary Error", error)
    yield put(getSummaryReportsFail(error.data))
  }
}
function* fetchVolumeComparatives({ params }) {
  try {
    const response = yield call(getVolumeComparativesReport, JSON.parse(params))
    yield put(getComparativesReportsSuccess(response))
  } catch (error) {
    console.debug("Fetching Volume Comparatives Error", error)
    yield put(getComparativesReportsFail(error?.data))
  }
}
function* doPurge() {
  yield put(reportPurgeSuccess())
}
function* doLogForDownload({ payload: { details } }) {
  try {
    const response = yield call(logForDownload, {
      ...JSON.parse(JSON.stringify(details)),
    })
    yield put(logForDownloadSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      logForDownloadFail(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* reportsSaga() {
  yield takeLeading(GET_RECEIPTS, fetchReceipts)
  yield takeLeading(GET_REPORTS, fetchReports)
  yield takeLeading(GET_CASHOUTREPORTS, fetchCashOutTransactions)
  yield takeLeading(GET_FUNDSTRANSFERREPORTS,fetchFundsTransferTransactions)
  yield takeLeading(REPORT_PURGE, doPurge)
  yield takeLeading(GET_TRANSACTION_REPORTS, fetchTransactions)
  yield takeLeading(GET_COLLECTION_REPORTS, fetchCollections)
  yield takeLeading(GET_SUMMARYREPORTS, fetchTransactionSummary)
  yield takeLeading(GET_COMPARATIVESREPORTS, fetchVolumeComparatives)
  yield takeLeading(LOG_DOWNLOAD, doLogForDownload)
  yield takeLeading(GET_DOWNLOAD_REPORTS, fetchDownloadedReport)
  yield takeLeading(GET_PAYWITHTRANSFER_REPORTS,fetchPayWithTransferTransactions)
  yield takeLeading(GET_CARDTRANSFER_REPORTS,fetchCardTransferTransactions)
}

export default reportsSaga
