import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"

import {
  GET_SETTLEMENT_WITHDRAWALS,
  SEND_WITHDRAWAL_OTP,
  SETTLEMENT_WITHDRAWAL,
  SETTLEMENT_PURGE,
  SETTLEMENT_WALLET_BALANCE,
  ADD_SETTLEMENT_ACCOUNT,
  GET_SETTLEMENT_ACCOUNTS,
  ACTIVATE_SETTLEMENT_ACCOUNT,
  CONFIGURE_OTP_RECEIVER,
  GET_WALLET_SETTLEMENT_CONFIGURATION,
  WALLET_SETTLEMENT_CONFIGURATION,
  GET_WALLET_TOP_UP_REPORT,
  GET_WALLET_ACCOUNT_STATEMENT_REPORT
} from "./actionTypes"
import {
  getSettlementWithdrawalsSuccess,
  getSettlementWithdrawalsFail,
  getWalletBalanceSuccess,
  getWalletBalanceApiError,
  withdrawSettlementSuccess,
  withdrawSettlementApiError,
  settlementPurgeSuccess,
  sendWithdrawalOTPSuccess,
  sendWithdrawalOTPFail,
  getSettlementAccountSuccess,
  getSettlementAccountFail,
  getWalletTopUpReportSuccess,
  getWalletTopUpReportFail,
  getWalletAccountStatementReport,
  getWalletAccountStatementReportSuccess,
  getWalletAccountStatementReportFail
} from "./actions"

import {
  createSettlementAccount,
  disableSettlementAccount,
  getSettlementAccounts,
  getSettlementWithdrawals,
  getWallet,
  getWalletBalance,
  getWalletSettlementConfig,
  otpReceiver,
  putSettlementAccount,
  sendWithdrawalOTP,
  settlementWithdrawal,
  walletSettlementConfig,
  getWalletTopUpReport,
  getWalletAccountStatement,
} from "helpers/backend_helper"
import {
  saveSettlementAccountApiError,
  saveSettlementAccountSuccess,
  configureOTPReceiverApiError,
  configureOTPReceiverSuccess,
  getWalletSettlementConfigurationApiError,
  getWalletSettlementConfigurationSuccess,
  removeSettlementAccountSuccess,
  walletSettlementConfigurationFail,
  walletSettlementConfigurationSuccess,
} from "store/actions"
function* fetchWalletAccountStatementReport({ params }) {
  try {
    const response = yield call(
      getWalletAccountStatement,
      JSON.parse(params)
    )
    yield put(getWalletAccountStatementReportSuccess(response))
  } catch (error) {
    console.debug("Fetching Settlement Withdrawals Error", error)
    yield put(getWalletAccountStatementReportFail(error))
  }
}
function* fetchAccounts({accountType}) {
  try {
    const response = yield call(getSettlementAccounts, accountType)
    yield put(getSettlementAccountSuccess(response))
  } catch (error) {
    console.debug("Fetching Settlement Accounts Error", error)
    yield put(getSettlementAccountFail(error))
  }
}
function* fetchWalletSettlementConfiguration({ params }) {
  try {
    const response = yield call(getWalletSettlementConfig, JSON.parse(params))
    yield put(getWalletSettlementConfigurationSuccess(response))
  } catch (error) {
    console.debug("Fetching Settlement Accounts Error", error)
    yield put(getWalletSettlementConfigurationApiError(error))
  }
}

function* fetchWallet() {
  try {
    const response = yield call(getWallet)
    yield put(getWalletSettlementConfigurationSuccess(response))
  } catch (error) {
    console.debug("Fetching Settlement Accounts Error", error)
    yield put(getWalletSettlementConfigurationApiError(error))
  }
}


function* configureWalletSettlement({ payload: { config } }) {
  try {

    const response = yield call(walletSettlementConfig, { ...JSON.parse(JSON.stringify(config)) })
    yield put(walletSettlementConfigurationSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(walletSettlementConfigurationFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* getBalance() {
  try {
    const response = yield call(getWalletBalance)
    yield put(getWalletBalanceSuccess(response))
  } catch (error) {
    console.debug("Fetching Clusters Error", error)
    yield put(getWalletBalanceApiError(error))
  }
}

function* sendOTP({ payload: { otp } }) {
  try {
    const response = yield call(sendWithdrawalOTP, otp);
    yield put(sendWithdrawalOTPSuccess(response))
  } catch (error) {
    yield put(sendWithdrawalOTPFail(error))
  }
}


function* withdraw({ payload: { withdrawal } }) {
  try {

    const response = yield call(settlementWithdrawal, { ...JSON.parse(JSON.stringify(withdrawal)) })
    if (response.Data) yield put(withdrawSettlementSuccess(response))
    else yield put(withdrawSettlementApiError(response))

  } catch (error) {
    console.debug(error)
    yield put(withdrawSettlementApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* saveSettlementAccount({ payload: { account } }) {
  try {

    const response = yield call((account?.ID > 0) ? putSettlementAccount : createSettlementAccount, { ...JSON.parse(JSON.stringify(account)) })
    yield put(saveSettlementAccountSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(saveSettlementAccountApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* configureOTPReceiver({ payload: { approver } }) {
  try {

    const response = yield call(otpReceiver, { ...JSON.parse(JSON.stringify(approver)) })
    yield put(configureOTPReceiverSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(configureOTPReceiverApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* disableAccount(payload) {
  try {
    const response = yield call(disableSettlementAccount, payload.id)

    yield put(removeSettlementAccountSuccess({ ...response, enable: false, id: payload.id }))

  } catch (error) {
    console.debug(error)
    yield put(withdrawSettlementApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* doPurge() {
  yield put(settlementPurgeSuccess())
}

function* fetchWalletTopUpReport({params}){
  try {
    const response = yield call(getWalletTopUpReport, JSON.parse(params))
    yield put(getWalletTopUpReportSuccess(response))
  }
  catch (error){
    console.debug("Fetching Wallet Report Error", error)
    yield put(getWalletTopUpReportFail(error))
  }
}

function* settlementsSaga() {
  yield takeLeading(
    GET_WALLET_ACCOUNT_STATEMENT_REPORT,
    fetchWalletAccountStatementReport
  )
  yield takeLeading(GET_WALLET_SETTLEMENT_CONFIGURATION, fetchWallet)
  yield takeLeading(WALLET_SETTLEMENT_CONFIGURATION, configureWalletSettlement)
  yield takeLeading(GET_SETTLEMENT_ACCOUNTS, fetchAccounts)
  yield takeEvery(SETTLEMENT_WALLET_BALANCE, getBalance)
  yield takeEvery(SETTLEMENT_PURGE, doPurge)
  yield takeLeading(SEND_WITHDRAWAL_OTP, sendOTP)
  yield takeLeading(SETTLEMENT_WITHDRAWAL, withdraw)
  yield takeLeading(ACTIVATE_SETTLEMENT_ACCOUNT, disableAccount)
  yield takeLeading(ADD_SETTLEMENT_ACCOUNT, saveSettlementAccount)
  yield takeLeading(CONFIGURE_OTP_RECEIVER, configureOTPReceiver)
  yield takeLeading(GET_WALLET_TOP_UP_REPORT, fetchWalletTopUpReport)
}

export default settlementsSaga
