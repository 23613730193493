import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap"

import ClusterInput from "components/Common/Input/Input"

import clusterian from "../../../../assets/images/icons/clusterian.png"
import ClusterCheckBox from "components/Common/CheckBox"
import { EDIT_USER, ENABLE_USERS } from "../permissions"
import ClusterButton from "components/Common/Button/Button"
import Loading from "components/Common/Loading"
const UserModal = props => {
  let {
    isOpen,
    viewedUser,
    setModal,
    onEnableUser,
    editLoading,
    isEnabled,
  } = props
  const toggleOpen = () => {
    setModal(!isOpen)
  }

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (viewedUser) {
      setTimeout(() => setLoading(false), 1000)
    }
  }, [viewedUser])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      toggle={() => {
        toggleOpen()
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggleOpen()
          }}
        >
          User Details
          {viewedUser ? (
            <Badge
              style={{ marginLeft: "10px" }}
              className={
                "font-size-12 badge-soft-" + isEnabled
                  ? "success"
                  : "secondary"
              }
              color={isEnabled == true ? "success" : "secondary"}
              pill
            >
              {isEnabled ? "Active" : "Inactive"}
            </Badge>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ModalHeader>
        {loading ? (
          <ModalBody>
            {" "}
            <Loading></Loading>
          </ModalBody>
        ) : (
          <React.Fragment>
            {" "}
            <ModalBody>
              {/* <Row>
                <Col lg={12}>
                  <div
                    style={{ width: "15%", minWidth: "30px", margin: "auto" }}
                  >
                    <img src={clusterian} />
                  </div>
                </Col>
              </Row> */}
              {viewedUser ? (
                <React.Fragment>
                  <React.Fragment>
                    <Row>
                      <Col lg={12}>
                        <p>Basic Data</p>
                      </Col>
                    </Row>

                    <Card
                      outline
                      style={{
                        background: "transparent",
                        borderColor: "#999999",
                      }}
                      className="border"
                    >
                      <CardBody>
                        <Row>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={viewedUser.lastName}
                              disabled
                              label={"Surname"}
                              id={"surname"}
                              name={"surname"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={viewedUser.firstName}
                              disabled
                              label={"First Name"}
                              id={"firstname"}
                              name={"firstname"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={viewedUser.otherName}
                              disabled
                              label={"Other Names"}
                              id={"othernames"}
                              name={"othernames"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={viewedUser.username}
                              disabled
                              label={"Username"}
                              id={"username"}
                              name={"username"}
                              type="text"
                            ></ClusterInput>
                          </Col>

                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={viewedUser.gender}
                              disabled
                              label={"Gender"}
                              id={"gender"}
                              name={"gender"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>

                  <React.Fragment>
                    <Row>
                      <Col lg={12}>
                        <p>Contact Me</p>
                      </Col>
                    </Row>

                    <Card
                      outline
                      style={{
                        background: "transparent",
                        borderColor: "#999999",
                      }}
                      className="border"
                    >
                      <CardBody>
                        <Row>
                          <Col lg={12} md={12}>
                            <ClusterInput
                              defaultValue={viewedUser.email}
                              disabled
                              label={"Email"}
                              id={"email"}
                              name={"email"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={12} md={12}>
                            <ClusterInput
                              defaultValue={viewedUser.phoneNumber}
                              disabled
                              label={"Phone Number"}
                              id={"phoneNumber"}
                              name={"phoneNumber"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          {/* <Col lg={12} md={12}>
                            <ClusterInput
                              defaultValue={viewedUser.branch?.name}
                              disabled
                              label={"Branch"}
                              id={"branch"}
                              name={"branch"}
                              type="text"
                            ></ClusterInput>
                          </Col> */}
                        </Row>
                        <Row>
                          <Col lg={12} md={12}>
                            <ClusterInput
                              defaultValue={viewedUser.address?.street}
                              disabled
                              label={"Address"}
                              id={"address"}
                              name={"address"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={viewedUser.address?.city}
                              disabled
                              label={"City"}
                              id={"city"}
                              name={"city"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={viewedUser.address?.state}
                              disabled
                              label={"State"}
                              id={"state"}
                              name={"state"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>

                  <React.Fragment>
                    <Row>
                      <Col lg={12}>
                        <p>Permissions & Others</p>
                      </Col>
                    </Row>

                    <Card
                      outline
                      style={{
                        background: "transparent",
                        borderColor: "#999999",
                      }}
                      className="border"
                    >
                      <CardBody>
                        <Row>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={viewedUser.staffId}
                              disabled
                              label={"Staff Id"}
                              id={"staffId"}
                              name={"staffId"}
                              type="text"
                            ></ClusterInput>
                          </Col>

                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={viewedUser.userRole?.name}
                              disabled
                              label={"Role"}
                              id={"role"}
                              name={"role"}
                              type="text"
                            ></ClusterInput>
                          </Col>

                          <Col
                            lg={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                            }}
                          >
                            <ClusterCheckBox
                              label={" Has Approval Rights"}
                              name={"hasApproval"}
                              id={"hasApproval"}
                              checked={viewedUser.hasApprovalRights}
                              disabled
                            ></ClusterCheckBox>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </ModalBody>
            <ModalFooter>
              <ClusterButton
                loading={editLoading}
                permissions={[ENABLE_USERS]}
                type="button"
                outline
                onHandleClick={() => onEnableUser(viewedUser?.id, !(viewedUser?.status == "Active"))}
                color="secondary"
              >
                {viewedUser?.status == "Active" ? "Disable" : "Enable"}
              </ClusterButton>
              <Link to={"/create-user/" + viewedUser?.id}>
                <ClusterButton
                  permissions={[EDIT_USER]}
                  type="button"
                  color="primary"
                >
                  Edit
                </ClusterButton>
              </Link>
              <ClusterButton
                type="button"
                color="secondary"
                onHandleClick={() => {
                  toggleOpen()
                }}
              >
                Close
              </ClusterButton>
            </ModalFooter>
          </React.Fragment>
        )}
      </div>
    </Modal>
  )
}
UserModal.propTypes = {
  isOpen: PropTypes.bool,
  viewedUser: PropTypes.object,
  setModal: PropTypes.func,
  onEnableUser: PropTypes.func,
  editLoading: PropTypes.bool,
  isEnabled: PropTypes.bool,
}
export default withRouter(UserModal)
