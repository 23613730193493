import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { logForDownload } from "store/actions"
import LogForDownloadForm from "./form"
import LogForDownloadLogic from "../download"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
const fetcher = url => {
  return get(url, {}, true)
}
import { get } from "helpers/api_helper"
import {
  GET_ALL_NETWORK_MANAGERS,
  GET_REPORTDOWNLOADTYPES,
} from "helpers/url_helper"
const LogForDownload = props => {
  const { onLogForDownload, success, editLoading, error } = props
  const { ERRORLIST } = LogForDownloadLogic
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [details, setLogForDownload] = useState({}) //data from form
  const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const reportTypes = useSWR(GET_REPORTDOWNLOADTYPES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })

  const handleOnChange = (key, value) => {
    let u = details
    u[key] = value
    setLogForDownload(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()

    onLogForDownload(details)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Downloads"}
            breadcrumbItem={"Log For Download"}
          />

          <LogForDownloadForm
            redirectUrl={"/download-report"}
            success={success}
            reportTypes={(reportTypes?.data as any)?.Data}
            networkManagers={(networkManagers?.data  as any)?.Data}
            error={error}
            errorList={errorList}
            editLoading={editLoading}
            setErrorList={setErrorList}
            handleOnChange={handleOnChange}
            handleValidSubmit={handleValidSubmit}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

LogForDownload.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onLogForDownload: PropTypes.func,
  onGetLogForDownloadDetail: PropTypes.func,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onGetAllCategories: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({
  reports: { error, success, totalCount, editLoading, loading },
}) => {
  return {
    error,
    totalCount,
    loading,
    success,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onLogForDownload: details => dispatch(logForDownload(details)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(LogForDownload))))
