import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert
} from "reactstrap"
import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import Loading from 'components/Common/Loading';
import { Validators } from 'helpers/validators';
import ClusterForm from 'components/Common/Form';
import TerminalLogic from '../terminal';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { TERMINALMODE, TERMINALTYPE } from 'helpers/variables';
import { Formik } from 'formik';
import { TerminalType } from 'enums/terminal-type';
import { Utils } from 'helpers/utility';
import { TerminalMode } from 'enums/terminal-mode';
import { useInstitutionsData } from 'hooks/useInstitutionData';
import { GetInstitution } from 'helpers/authentication/institution';

type Terminal = {
  ID: number,
  IsActive: boolean,
  TerminalID: string,
  SerialNumber: string,
  Mode: TerminalMode,
  Type: TerminalType,
  InstitutionCode : String,
  MerchantPercentage: number
}
const TerminalsForm = props => {
  let { apiSuccess, apiError, isFormInvalid, errorList, myError, isUpdate, loading, editLoading, details, setErrorList, handleOnChange, handleValidSubmit } = props;

  const Initiate = (terminal): Terminal => {
    const term = {
      TerminalID: terminal?.TerminalID,
      SerialNumber: terminal?.SerialNumber,
      Mode: terminal?.Mode ?? TerminalMode[TerminalMode.POSVAS],
      Type: terminal?.Type ?? TerminalType.TelpoPOS,
      InstitutionCode : terminal?.InstitutionCode,
      MerchantPercentage: terminal?.MerchantPercentage ?? 0,
      ID: terminal?.ID ?? 0,
      IsActive: terminal?.IsActive ?? false
    }
    return term
  }


  const validate = (values: Terminal) => {
    const errors: any = {};
    const required = Validators.required("Please enter a value");

    const terminalID = () =>
      [required,
        Validators.length(8, "Number of allowed characters is 8")].forEach(validator => {
          if (values?.TerminalID) {
            let error = validator(values?.TerminalID)
            if (error) errors.TerminalID = error;
          } else delete errors.TerminalID
        });


    const merchantPercentage = () => [
      Validators.percentage(),
    ].forEach(validator => {
      if (values?.MerchantPercentage) {
        let error = validator(values?.MerchantPercentage)
        if (error) errors.MerchantPercentage = error;
      } else delete errors.MerchantPercentage
    })

    const validate = () => {
      try {
        terminalID();
        merchantPercentage();
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();
  }
  const { institutionsData, institutions } = useInstitutionsData()
  const { MapToDropDown } = TerminalLogic
  if (loading && isUpdate && Utils.Object.isEmpty(details)) return <Loading><p>Getting Terminal...</p></Loading>
  
  return <React.Fragment>

    <Formik
      initialValues={Initiate(details)}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        setTimeout(() => setSubmitting(false), 30000);
        handleValidSubmit(values)
      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (
        <ClusterForm handleSubmit={handleSubmit}>

          <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
            <CardBody>
              <Row>

                <Col lg={4} md={4} sm={6}>

                  <ClusterInput
                    defaultValue={values?.TerminalID}
                    label={"Terminal ID"}
                    id={"terminalID"}
                    name={"TerminalID"}
                    type="text"
                    required={true}
                    value={values?.TerminalID}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={errors.TerminalID}
                  />
                </Col>

                <Col lg={4} md={4} sm={6}>

                  <ClusterInput
                    defaultValue={values?.SerialNumber || details?.SerialNumber}
                    label={"Serial Number"}
                    id={"serialNumber"}
                    name={"SerialNumber"}
                    required={true}
                    type="text"
                    value={values?.SerialNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={errors.SerialNumber}
                  />
                </Col>
                <Col lg={4} md={4} sm={6}>

                  <FormGroup >
                    <Label>Mode</Label>
                    <ClusterDropdown
                      name="Mode"
                      defaultValue={values?.Mode || details?.Mode}
                      value={values?.Mode}
                      placeholder={"-Select-"}
                      items={Utils.Enums.toOptions(TerminalMode)}
                      useValue={true}
                      onChange={(name, value) => { setFieldValue("Mode", TerminalMode[value]); }} />

                  </FormGroup>
                </Col>
              </Row>
              <Row>

                <Col lg={4} md={4} sm={6}>

                  <FormGroup >
                    <Label>Type</Label>
                    <ClusterDropdown
                      name="Type"
                      defaultValue={details?.Type || values?.Type}
                      placeholder={"-Select-"}
                      items={Utils.Enums.toOptions(TerminalType)}
                      useValue={true}
                      value={values?.Type}
                      onChange={(name, value) => {
                        setFieldValue("Type", value);
                      }} />

                  </FormGroup>
                </Col>

                <Col lg={4} md={4} sm={6}>

                  <ClusterInput
                    defaultValue={values?.MerchantPercentage || details?.MerchantPercentage}
                    label={"Merchant Service Charge(%)"}
                    id={"merchantPercentage"}
                    name={"MerchantPercentage"}
                    type="number"
                    value={values?.MerchantPercentage}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    validationError={errors.MerchantPercentage}
                  ></ClusterInput>
                </Col>

                {GetInstitution().code == '*' && 
                <Col lg={4} md={4} sm={6}>
                  <Label>Institution</Label>
                  <ClusterDropdown
                    defaultValue={isUpdate ? institutionsData?.filter(inst => inst.ID == details?.InstitutionCode)[0]?.Name : ''}
                    name={"InstitutionCode"}
                    items={MapToDropDown(institutionsData)}
                    onChange={(name, value) => {
                      setFieldValue("InstitutionCode", value)
                      }}
                  ></ClusterDropdown>
                </Col>}

              </Row>
            </CardBody>
          </Card>

          <Row style={{ paddingBottom: "50px" }}>
            <Col lg={12}>
              <ClusterButton
                loading={editLoading}
                disabled={(!touched) ? true : (editLoading || !isValid)}
                color={"primary"} addon={"bx bx-save"}>
                {!Utils.Object.isEmpty(details) ? "Update" : "Save"}
              </ClusterButton>
            </Col>
          </Row>

        </ClusterForm>)}
    </Formik>
  </React.Fragment>

}
TerminalsForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(TerminalsForm)))