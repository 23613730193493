import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Container, Row, Col } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { getCommissions, commissionPurge } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import CommissionLogic from "../commission"
import { GET_ALL_COMMISSIONS } from "helpers/url_helper"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

const CommissionsList = props => {
  const { commissions, onGetCommissions, totalCount, loading } = props
  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = CommissionLogic
  const {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
  } = useReportData({
    onGetData: onGetCommissions,
    HEADERS: HEADERS,
    SearchCriteria: SearchCriteria,
  })

  const { institutionsData, institutions } = useInstitutionsData()
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  
  useEffect(() => {
    populateRows()
  }, [commissions])

  const populateRows = () => {
    if (commissions) {
      let rws = []
      commissions.forEach(commission => {
        let row = MapToRows(commission)
        rws.push(row)
      })

      setRows(rws)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Commission"}
            breadcrumbItem={"Commission Report"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_COMMISSIONS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

CommissionsList.propTypes = {
  commissions: PropTypes.array,
  onGetCommissions: PropTypes.func,
  onGetCommissionDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  commissions: {
    commissions,
    error,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  return {
    commissions: [...commissions],
    error,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCommissions: query => dispatch(getCommissions(query)),
  onPurge: () => dispatch(commissionPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CommissionsList))))
