
import React, { useState } from "react"
import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"

import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import { Utils } from 'helpers/utility';
import Loading from 'components/Common/Loading';
import ClusterForm from 'components/Common/Form';
import { UserScope } from "enums/user-scope"
import { DropdownType } from "enums/dropdown-type"
import ClusterInformation from "components/Common/Information/Information"
import { useScope } from "pages/UserManagement/Users/create/useUser"
import { Formik } from "formik"
import { Link } from "react-router-dom";

const UserPermissionsForm = props => {
    const { userDetails, roles, roleLoading, isUpdate, setPrepopulatedValues, setIsPrepopulated, isPrepopulated, prepopulatedValues } = props;
    const [userRoleId, setUserRoleId] = useState(userDetails?.userRole?.id);
    const {
        scope:
        {
            scope,
            setScope,
            setViewScopes,
            scopeMetaData,
            canHaveScopeItems,
            scopeItemLabel,
            items: {
                scopeItemLoading,
                scopeItems,
            }
        },
        filteredRoles,
        user,
        validator,
        defaultValue,
        initialValues,
        toArray
    } = useScope(userDetails, prepopulatedValues, roles, userRoleId)


    const valueValidator = (values) => {

        return (key) => {
            if (userDetails.viewScopeIds === values.viewScopeIds.toString()) return false;
            const value = values[key];
            return value != null && value != undefined && value != ''
        }
    }
    const handlePrepopulation = (values) => {
        let formValues = values;
        if (scope === UserScope.NetworkManager) {
            formValues = { ...values, ...user }
            if (isUpdate) {
                const isValueValid = valueValidator(formValues)
                const lastName = !isValueValid("lastName") ? userDetails.firstName ?? "" : formValues.lastName;
                const firstName = !isValueValid("firstName") ? userDetails.lastName ?? "" : formValues.firstName
                const username = !isValueValid("username") ? userDetails.username : formValues.username;
                const email = !isValueValid("email") ? userDetails.email ?? "" : formValues.email;
                const phoneNumber = !isValueValid("phoneNumber") ? userDetails.phoneNumber ?? "" : formValues.phoneNumber;
                const gender = !isValueValid("gender") ? userDetails.gender ?? "" : formValues.gender;
                formValues = { ...formValues, firstName, lastName, otherName: "", phoneNumber, email, username, gender };

            }

        }
        setPrepopulatedValues(formValues);
        setIsPrepopulated(true);
    }

    return <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {

            handlePrepopulation({ ...values })

        }}
        validate={validator}
    >
        {({
            values,
            touched,
            isValid,
            errors,
            handleSubmit,
            setFieldValue,
        }) => (<ClusterForm handleSubmit={handleSubmit}>

            {!values?.scope && !isPrepopulated ? <React.Fragment>
                <Row className="mt-4 mb-4">
                    <Col lg={12} md={12} sm={12}>
                        <h3>Whose data can this user see? (Select one option)</h3>
                    </Col>
                </Row>
                <Row className="mt-4 mb-4">
                    {scopeMetaData.map((d, i) => (<Col lg={4} md={6} sm={12} xs={12} key={`information_scope_${i}`}>
                        <ClusterInformation
                            title={d.title}
                            description={d.description}
                            className={"expand-on-hover"}
                            onClick={() => { setScope(d.scope); setFieldValue("scope", d.scope); }}
                        />
                    </Col>))}
                </Row>
            </React.Fragment> : <React.Fragment>

                <React.Fragment>
                    <Row>
                        <Col lg={12}>
                            <p>Permissions</p>
                        </Col>
                    </Row>

                    <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
                        <CardBody>

                            <Row>
                                <Col lg={4} md={4} sm={6}>
                                    <FormGroup>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <Label for="scope">
                                                Scope
                                            </Label>
                                        </div>
                                        <ClusterDropdown
                                            id={"scope"}
                                            name={"scope"}
                                            disabled={isPrepopulated}
                                            placeholder={"- Select scope - "}
                                            defaultValue={values?.scope}
                                            value={values?.scope}
                                            items={Utils.Enums.toOptions(UserScope).filter(i => i.id != UserScope.Agent)}
                                            onChange={(name, value) => { setFieldValue("scope", value); setScope(value) }}
                                            required={true}
                                            validationError={errors?.scope}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col lg={4} md={4}>
                                    <FormGroup>
                                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                            <Label for="userrole">
                                                Roles for {Utils.Strings.camelPad(values?.scope)}
                                            </Label>
                                            <Link to="/create-user-role/0" style={{ display: "flex", alignItems: "center", padding: 0, border: 0 }} className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2" >

                                                <i className="bx bx-plus" style={{ marginRight: "5px" }}></i>
                                                Create User Role

                                            </Link>

                                        </div>
                                        {roleLoading ? <Loading></Loading> :
                                            <ClusterDropdown
                                                id="userRoleId"
                                                name={"userRoleId"}
                                                placeholder={"- Enter a role - "}
                                                defaultValue={filteredRoles.find(role => role.id == values?.userRoleId)?.name}
                                                value={filteredRoles.find(role => role.id == values?.userRoleId)?.name}
                                                items={filteredRoles.map(r => ({ value: r.name, id: r.id, key: r.key }))}
                                                required={true}
                                                onChange={(name, value) => { setFieldValue("userRoleId", value); setUserRoleId(value) }}
                                                validationError={errors?.userRoleId}
                                                disabled={isPrepopulated}
                                            />
                                        }

                                    </FormGroup>
                                </Col>




                                {canHaveScopeItems ?
                                    <Col lg={4} md={4}>
                                        <FormGroup>
                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                                <Label for="scope-item">
                                                    {scopeItemLabel}
                                                </Label>


                                            </div>
                                            {scopeItemLoading ? <Loading></Loading> :
                                                !Utils.List.isEmpty(scopeItems) ? <ClusterDropdown

                                                    name={"viewScopeIds"}
                                                    type={values?.scope == UserScope.Cluster ? DropdownType.MultiSelect : DropdownType.TypeAhead}
                                                    placeholder={"- Enter a " + scopeItemLabel + " - "}
                                                    defaultValue={defaultValue}
                                                    value={defaultValue}
                                                    items={scopeItems.map(r => ({ value: r.Name, id: r.ID, key: r.ID }))}
                                                    onChange={(name, value) => { setFieldValue("viewScopeIds", value); setViewScopes(toArray(value)); }}
                                                    required={canHaveScopeItems}
                                                    validationError={errors?.viewScopeIds}
                                                    clearOnOptionsChange={!isUpdate}
                                                    disabled={isPrepopulated}
                                                /> :
                                                    <Alert color="danger">No {scopeItemLabel} available</Alert>}

                                        </FormGroup>
                                    </Col> : null}

                            </Row>
                            {!isPrepopulated && <Row style={{ paddingBottom: "50px" }}>
                                <Col lg={12}>
                                    <ClusterButton
                                        disabled={!touched ? true : !isValid}
                                        color={"primary"}
                                        addon={"bx bx-arrow-right"}
                                    >
                                        Next
                                    </ClusterButton>
                                </Col>
                            </Row>}
                        </CardBody>
                    </Card>
                </React.Fragment>
            </React.Fragment>}

        </ClusterForm>)}
    </Formik>
}

export default UserPermissionsForm