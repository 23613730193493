import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_MOBILEACCOUNTS,
  GET_MOBILEACCOUNT_DETAIL,
  MOBILEACCOUNT_PURGE,
  ENABLE_MOBILEACCOUNT,
  CREATE_MOBILEACCOUNT,
  UPDATE_MOBILEACCOUNT,
  GET_ALL_MOBILEACCOUNTS,
  GET_MOBILEACCOUNT_BY_PHONE,
  GET_MOBILEWALLET_DETAIL,
  GET_ALL_MOBILEWALLETS,
  GET_MOBILEWALLETS,
  RESET_PIN,
  RESET_PIN_TRIES,
  RESET_PASSWORD,
  RESET_PASSWORD_TRIES,
  HOTLIST,
  GENERATE_VERIFICATION_CODE,
  SEND_ACTIVATION_CODE,
} from "./actionTypes"
import {
  getMobileAccountsSuccess,
  getMobileAccountsFail,
  getMobileAccountDetailSuccess,
  getMobileAccountDetailFail,
  mobileAccountPurgeSuccess,
  updateMobileAccountSuccess,
  postMobileAccountSuccess,
  mobileAccountApiError,
  getMobileWalletsSuccess,
  getMobileWalletsFail,
  getMobileWalletDetailSuccess,
  getMobileWalletDetailFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getMobileAccounts,
  getMobileAccountDetail,
  putMobileAccount,
  postMobileAccount,
  enableMobileAccount,
  disableMobileAccount,
  getAllMobileAccounts,
  getAllMobileWallets,
  getMobileWallets,
  getMobileWalletDetail,
  getMobileAccountByPhoneNumber,
  generateVerificationCode,
  hotlist,
  resetPin,
  resetPinTries,
  sendActivationCode,
  resetPassword,
  resetPasswordTries,
} from "helpers/backend_helper"
import {
  enableMobileAccountSuccess,
  generateVerificationCodeError,
  generateVerificationCodeSuccess,
  hotlistError,
  hotlistSuccess,
  resetPinError,
  resetPinSuccess,
  resetPinTriesError,
  resetPinTriesSuccess,
  resetPasswordError,
  resetPasswordSuccess,
  resetPasswordTriesError,
  resetPasswordTriesSuccess,
  sendActivationCodeError,
  sendActivationCodeSuccess,
} from "store/actions"
const format = "yyyy-MM-DD"
function* fetchMobileAccounts({ params }) {
  try {
    const response = yield call(getMobileAccounts, JSON.parse(params))
    yield put(getMobileAccountsSuccess(response))
  } catch (error) {
    console.debug("Fetching MobileAccounts Error", error)
    yield put(getMobileAccountsFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}
function* fetchAllMobileAccounts() {
  try {
    const response = yield call(getAllMobileAccounts)
    yield put(getMobileAccountsSuccess(response))
  } catch (error) {
    console.debug("Fetching MobileAccounts Error", error)
    yield put(getMobileAccountsFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchMobileAccountDetail({ id }) {
  try {
    const response = yield call(getMobileAccountDetail, id)
    yield put(getMobileAccountDetailSuccess(response))
  } catch (error) {
    yield put(getMobileAccountDetailFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}
function* fetchMobileAccountByPhoneNumber({ phoneNumber }) {
  try {
    const response = yield call(getMobileAccountByPhoneNumber, phoneNumber)
    yield put(getMobileAccountDetailSuccess(response))
  } catch (error) {
    yield put(getMobileAccountDetailFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchMobileWallets({ params }) {
  try {
    const response = yield call(getMobileWallets, JSON.parse(params))
    yield put(getMobileWalletsSuccess(response))
  } catch (error) {
    console.debug("Fetching MobileWallets Error", error)
    yield put(getMobileWalletsFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}
function* fetchAllMobileWallets() {
  try {
    const response = yield call(getAllMobileWallets)
    yield put(getMobileWalletsSuccess(response))
  } catch (error) {
    console.debug("Fetching MobileWallets Error", error)
    yield put(getMobileWalletsFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchMobileWalletDetail({ id }) {
  try {
    const response = yield call(getMobileWalletDetail, id)
    yield put(getMobileWalletDetailSuccess(response))
  } catch (error) {
    yield put(getMobileWalletDetailFail(error?.data?? "Sorry an error occurred, Kindly retry"))
  }
}

function* createMobileAccount({ payload: { mobileAccount } }) {
  try {
    const response = yield call(postMobileAccount, {
      ...JSON.parse(JSON.stringify(mobileAccount)),
    })
    yield put(postMobileAccountSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      mobileAccountApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateMobileAccount({ payload: { mobileAccount } }) {
  try {
    const response = yield call(putMobileAccount, {
      ...JSON.parse(JSON.stringify(mobileAccount)),
    })

    yield put(updateMobileAccountSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      mobileAccountApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doEnableMobileAccount(payload) {
  try {
    let toEnable = true
    let cta = enableMobileAccount
    if (!payload.enable) {
      cta = disableMobileAccount
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableMobileAccountSuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      mobileAccountApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doPurge() {
  yield put(mobileAccountPurgeSuccess())
}

function* doResetPin({ payload: { id } }) {
  try {
    const response = yield call(resetPin, id)

    yield put(resetPinSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resetPinError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doResetPinTries({ payload: { id } }) {
  try {
    const response = yield call(resetPinTries, id)

    yield put(resetPinTriesSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resetPinTriesError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}


function* doResetPassword({ payload: { id } }) {
  try {
    const response = yield call(resetPassword, id)

    yield put(resetPasswordSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resetPasswordError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doResetPasswordTries({ payload: { id } }) {
  try {
    const response = yield call(resetPasswordTries, id)

    yield put(resetPasswordTriesSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resetPasswordTriesError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* doHotlist({ id }) {
  try {
    const response = yield call(hotlist, id)

    yield put(hotlistSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      hotlistError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doVerification({ id }) {
  try {
    const response = yield call(generateVerificationCode, id)

    yield put(generateVerificationCodeSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      generateVerificationCodeError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doActivation({ payload: { id } }) {
  try {
    const response = yield call(sendActivationCode, id)

    yield put(sendActivationCodeSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      sendActivationCodeError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* mobileAccountsSaga() {
  yield takeLeading(GET_MOBILEACCOUNTS, fetchMobileAccounts)
  yield takeLeading(GET_ALL_MOBILEACCOUNTS, fetchAllMobileAccounts)
  yield takeEvery(GET_MOBILEACCOUNT_DETAIL, fetchMobileAccountDetail)
  yield takeLeading(GET_MOBILEWALLETS, fetchMobileWallets)
  yield takeLeading(GET_ALL_MOBILEWALLETS, fetchAllMobileWallets)
  yield takeEvery(GET_MOBILEWALLET_DETAIL, fetchMobileWalletDetail)
  yield takeEvery(GET_MOBILEACCOUNT_BY_PHONE, fetchMobileAccountByPhoneNumber)
  yield takeEvery(MOBILEACCOUNT_PURGE, doPurge)
  yield takeLeading(ENABLE_MOBILEACCOUNT, doEnableMobileAccount)
  yield takeLeading(UPDATE_MOBILEACCOUNT, updateMobileAccount)
  yield takeLeading(CREATE_MOBILEACCOUNT, createMobileAccount)
  yield takeLeading(RESET_PIN, doResetPin)
  yield takeLeading(RESET_PIN_TRIES, doResetPinTries)
  yield takeLeading(RESET_PASSWORD, doResetPassword)
  yield takeLeading(RESET_PASSWORD_TRIES, doResetPasswordTries)
  yield takeLeading(HOTLIST, doHotlist)
  yield takeLeading(GENERATE_VERIFICATION_CODE, doVerification)
  yield takeLeading(SEND_ACTIVATION_CODE, doActivation)
}

export default mobileAccountsSaga
