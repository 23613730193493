import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  TabPane,
  Row,
  Col,
  Container,
  Card,
  CardTitle,
  CardBody,
  CardSubtitle,
} from "reactstrap"

import ServiceQualityCard from "./card"
import withDispose from "components/HOCs/WithDispose"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Search from "../search"
import { useDashboardView } from "hooks/useDashboardView"
import { GetInstitution } from "helpers/authentication/institution"
const tabs = [
  { id: 0, label: "Volume", type: "trxVolTrend" },
  { id: 1, label: "Count", type: "trxCountTrend" },
]
const ServiceQualityList = () => {

  const { data } = useDashboardView();
  let [startDate, setStartDateStr] = useState()
  let [endDate, setEndDateStr] = useState()
  const [code, setCode] = useState(GetInstitution().code)


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ position: "relative" }}>
          <Breadcrumbs
            title={"Dashboard"}
            breadcrumbItem={"Service Quality"}
            noCrumb={true}
          />
          <Search
            startDateStr={startDate}
            endDateStr={endDate}
            setStartDateStr={setStartDateStr}
            setEndDateStr={setEndDateStr}
            setCode={setCode}
            code={code}
          ></Search>

          <Row>
            {data.map((types, key) => (
              <Col key={`TRXTYPE_${key}`} lg={3} md={6} sm={6}>
                <Card
                  className={" divider-top-" + types.color}
                  style={{
                    paddingTop: "15px",
                    paddingBottom: "0px",
                    position: "relative",
                  }}
                >
                  <CardTitle
                    style={{
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      position: "relative",
                    }}
                  >
                    <small>
                      <b> {types.label}</b>
                    </small>
                  </CardTitle>
                  <CardBody style={{ paddingTop: "0" }}>
                    <ServiceQualityTabContent
                      startDateStr={startDate}
                      endDateStr={endDate}
                      trxType={types.type}
                      field={tabs[1].type}
                      color={types.color}
                      code={code == '*' ? '' : code}
                    ></ServiceQualityTabContent>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

function ServiceQualityTabContent({
  trxType,
  color,
  field,
  currency,
  startDateStr,
  endDateStr,
  code
}) {
  return (
    <React.Fragment>
      <ServiceQualityCard
        trxType={trxType}
        subtitle="7 Days"
        field={field}
        yValueLabel="value"
        currency={currency}
        trxStatusFilter
        color={color}
        startDateStr={startDateStr}
        endDateStr={endDateStr}
        code = {code}
      ></ServiceQualityCard>
    </React.Fragment>
  )
}

ServiceQualityList.propTypes = {
  trxType: PropTypes.string,
}
export default withDispose(ServiceQualityList)
