import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SearchSection from "components/Common/Search/SearchSection"
import ClusterDataTable from "components/Common/DataTable"

const Report = props => {
  const { searchData, tableData, csv } = props
  const { columns, rows, paginationOptions, pageTitle, isLoading } = tableData
  const { pageNumber, totalCount, maxSize, onPageChange } = paginationOptions
  const [loading, setLoading] = useState(isLoading)
  const [data, setData] = useState(rows)

  useEffect(() => {
    setData(rows)
    setLoading(isLoading)
  }, [rows, isLoading])

  const setPageNumber = page => {
    setLoading(true)
    onPageChange(page)
  }
  return (
    <React.Fragment>
      <ClusterDataTable
        csv={csv}
        columns={columns}
        loading={loading}
        rows={data}
        paginationOptions={{
          maxSize: maxSize,
          totalCount: totalCount,
          pageNumber: pageNumber,
        }}
        onPageChange={page => setPageNumber(page)}
      ></ClusterDataTable>
    </React.Fragment>
  )
}

Report.propTypes = {
  tableData: PropTypes.object,
  searchData: PropTypes.object,
}
export default Report
