import { GetInstitution } from "helpers/authentication/institution";
import { BASESTATUS, Format, SEARCHOPTIONS } from "helpers/variables";

const HEADERS = [
    { title: "Activation Date", name: "activationDate", entityKeyName: "ActivationDate" },
    { title: "Deactivation Date", name: "deactivationDate", entityKeyName: "DeactivationDate" },
    { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
    { title: "Transaction Volume", name: "transactionVolume", entityKeyName: "TransactionVolume" },
    { title: "Agent Phone Number", name: "agentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
    { title: "Agent Account Number", name: "agentAccountNumber", entityKeyName: "AgentAccountNumber" },
    { title: "Treated By", name: "treatedBy", entityKeyName: "TreatedBy" },
    { title: "Cluster Manager", name: "clusterManager", entityKeyName: "ClusterManager" },
    { title: "Agent Location", name: "agentLocation", entityKeyName: "AgentLocation" },

]

const currentDate = new Date()
const formattedDate = currentDate.toISOString().slice(0, 10)

const SearchOptions = (startDate, endDate, institutions) => {

    let options = Object.assign({}, SEARCHOPTIONS);
    options.range.enabled = false;
    options.fields = [
      {
        type: "date",
        label: "Activated From",
        id: "s-activationFrom",
        defaultValue: "2010-01-01",
        name: "activationFrom",
      },
      {
        type: "date",
        label: "Activated To",
        id: "s-activationTo",
        defaultValue: formattedDate,
        name: "activationTo",
      },
      {
        type: "date",
        label: "Deactivated From",
        id: "s-deactivationFrom",
        defaultValue: "2010-01-01",
        name: "deactivationFrom",
      },
      {
        type: "date",
        label: "Deactivated To",
        id: "s-deactivationTo",
        defaultValue: formattedDate,
        name: "deactivationTo",
      },
      {
        type: "text",
        label: "Terminal ID",
        id: "s-terminalID",
        defaultValue: "",
        name: "terminalID",
      },
      {
        type: "text",
        label: "Agent Phone Number",
        id: "s-agentPhoneNumber",
        defaultValue: "",
        name: "agentPhoneNumber",
      },
      {
        type: "text",
        label: "Agent Account Number",
        id: "s-agentAccountNumber",
        defaultValue: "",
        name: "agentAccountNumber",
      },
      {
        type: "dropdown",
        label: "All Institutions",
        id: "institutionCode",
        defaultValue: "- Select -",
        data: MapToDropDown(institutions),
        name: "institutionCode",
        width: 2,
        isRemote: GetInstitution().code == "*",
      },
    ]
    return options
}
const MapToDropDown = (data) => {
    if (data instanceof Array) {
        return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
    }
    return [];
}
const MapToRows = (terminal) => {
    return {
      id: terminal.ID,
      activationDate:
        Format.date(terminal.ActivationDate) ?? "Not yet activated",
      institution: terminal?.InstitutionName,
      deactivationDate:
        Format.date(terminal.DeactivationDate) ?? "Not yet de-activated",
      terminalID: terminal.TerminalID,
      transactionVolume: Format.currency(terminal.TransactionVolume),
      agentPhoneNumber: terminal.AgentPhoneNumber,
      agentAccountNumber: terminal.AgentAccountNumber,
      treatedBy: terminal.TreatedBy,
      clusterManager: terminal.ClusterManager,
      agentLocation: terminal.AgentLocation,
    }

}

const SearchCriteria = (parameters, maxSize) => {
    return JSON.stringify({
      ActivationFromDate: parameters["activationFrom"]
        ? parameters["activationFrom"]
        : "2010-01-01",
      ActivationToDate: parameters["activationTo"]
        ? parameters["activationTo"]
        : formattedDate,
      DeactivationFromDate: parameters["deactivationFrom"]
        ? parameters["deactivationFrom"]
        : "2010-01-01",
      DeactivationToDate: parameters["deactivationTo"]
        ? parameters["deactivationTo"]
        : formattedDate,
      InstitutionCode:
        parameters["institutionCode"] != "*"
          ? parameters["institutionCode"]
          : "",
      TerminalID: parameters["terminalID"],
      AgentPhoneNumber: parameters["agentPhoneNumber"],
      AgentAccountNumber: parameters["agentAccountNumber"],
      StartIndex: (parameters["page"] - 1) * maxSize,
      MaxSize: maxSize,
    })

}

const UpdateErrorList = (ERRORLIST) => {
    let list = ERRORLIST
    for (let key in list) {
        list[key] = ""
    }
    return list
}
export default {
    HEADERS, UpdateErrorList, SearchOptions, MapToRows, SearchCriteria, MapToDropDown
}