import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { createRole, getRoleDetail, purgeRole, updateRole } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import RoleForm from "./form"
import {
  CACHE_FUNCTIONS_FOR_USER_ITEMS,
  CACHE_MENU_ITEMS,
} from "helpers/variables"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import GuardedPage from "components/HOCs/GuardedPage"
import { CREATE_USER_ROLE, EDIT_USER_ROLE } from "../permission"
import { UserScope } from "enums/user-scope"
const CreateUserRole = props => {
  const {
    error,
    success,
    onCreateRole,
    loading,
    match,
    history,
    roleLoading,
    onGetRoleDetail,
    roles,
    onUpdateRole,
    onPurge,
  } = props
  const [errorList, setErrorList] = useState({
    name: undefined,
    description: undefined,
    code: undefined,
    scope: undefined,
  })
  const institutionCode = GetInstitution().code
  const [scopeLoading, setScopeLoading] = useState(false)
  const [scope, setScope] = useState("*")
  const [code, setCode] = useState("")
  const [isRotating, setIsRotating] = useState(false)
  const [scopeItems, setScopeItems] = useState([])
  const [roleToCreate, setRole] = useState({})
  const [isUpdate, setIsUpdate] = useState(false)
  const [selectedFunctions, setSelectedFunctions] = useState([])
  const [roleDetails, setRoleDetails] = useState<any>()
  const getRole = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = roles.find(c => c.id == id)
      if (!u) {
        if (Object.keys(props.role).length == 0) {
          onGetRoleDetail(id)
        } else {
          setRoleDetails(props.role)
        }
        return
      }
      setRoleDetails(u)
    } else {
      setIsUpdate(false)
    }
  }

  useEffect(() => {
    getRole()
  }, [])
  useEffect(() => {
    if (success && isUpdate) {
      //Ensure to refresh the users current role permissions
      if (authHeader().AuthUser.userRoleId == +match.params.id) {
        localStorage.removeItem(CACHE_MENU_ITEMS)
        localStorage.removeItem(CACHE_FUNCTIONS_FOR_USER_ITEMS)
      }
    }
  }, [success])
  useEffect(() => {
    setScopeLoading(false)
  }, [errorList])

  useEffect(() => {
    getRole()
  }, [match])

  useEffect(() => {
    if (Object.keys(props.role).length > 0) {
      setScope(props.role.scope)
      setRoleDetails(props.role)
    } else {
      if (isUpdate || success) setRoleDetails(undefined)
    }
  }, [props.role])

  const refreshErrorList = () => {
    if (isUpdate) {
      let e = errorList
      Object.keys(e).map(o => {
        if (!e[o]) e[o] = ""
        return o
      })
      setErrorList(e)
    }
  }
  const handleOnChange = (key, value) => {
    switch (key) {
      case "scope":
        switch (value) {
          case UserScope.Cluster:
            setScope(UserScope.Cluster)
            break
          case UserScope.NetworkManager:
            setScope(UserScope.NetworkManager)
            break
          case UserScope.Agent:
            setScope(UserScope.Agent)
            break
          default:
            setScope(UserScope.Global)
            break
        }
        break
      default:
        break
    }

    refreshErrorList()

    let u = roleToCreate
    u[key] = value
    setRole(u)
  }

  const handleValidSubmit = e => {
    e.preventDefault()

    if (isUpdate) {
      let create:any = { ...roleDetails, ...roleToCreate }
      create["id"] = props.match.params.id
      for (let key in create) {
        if (create[key] instanceof Object || create[key] instanceof Array) {
          delete create[key]
        }
      }
      create["assignedFunctionItems"] = selectedFunctions
      onUpdateRole(create)
      return
    }

    roleToCreate["assignedFunctionItems"] = selectedFunctions
    onCreateRole(roleToCreate)
  }
  const onUpdateSelection = selections => {
    setSelectedFunctions(selections)
  }

  return (
    <GuardedPage permissions={[EDIT_USER_ROLE, CREATE_USER_ROLE]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"User Roles"}
              breadcrumbItem={isUpdate ? "Update A Role" : "Create A Role"}

            />

            <RoleForm
              id={match.params.id}
              redirectUrl={"/view-user-roles"}
              success={success}
              error={error}
              scope={scope}
              scopeItems={scopeItems}
              isUpdate={isUpdate}
              loading={loading}
              errorList={errorList}
              isRotating={isRotating}
              roleLoading={roleLoading}
              scopeLoading={scopeLoading}
              roleDetails={roleDetails}
              setErrorList={setErrorList}
              onUpdateSelection={onUpdateSelection}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
              onPurge={onPurge}
            ></RoleForm>
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateUserRole.propTypes = {
  role: PropTypes.object,
  roles: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.string,
  onCreateRole: PropTypes.func,
  onGetFunctions: PropTypes.func,
  functionItems: PropTypes.array,
  functionItemsForUser: PropTypes.array,
  functionsLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onGetRoleDetail: PropTypes.func,
  onUpdateRole: PropTypes.func,
  onPurge: PropTypes.func,
  roleLoading: PropTypes.bool,
}

const mapStateToProps = ({
  Roles: { role, roles, error, editLoading, success, loading },
  permissions: { functions, functionsForUser, functionsLoading },
}) => {
  return {
    role,
    roles,
    error: error,
    functionItemsForUser: functionsForUser,
    functionItems: functions,
    functionsLoading: functionsLoading,
    loading: editLoading,
    roleLoading: loading,
    success,
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateRole: data => dispatch(createRole(data)),
  onGetRoleDetail: id => dispatch(getRoleDetail(id)),
  onUpdateRole: role => dispatch(updateRole(role)),
  onPurge: () => dispatch(purgeRole()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateUserRole))))
