import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../../ReportManagement/report"
import * as crypto from "crypto"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { reportPurge, getDownloadReports, downloadReport } from "store/actions"
import { GET_REPORTDOWNLOADTYPES } from "helpers/url_helper"
import DownloadLogic from "../download"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
import { fetcher, get, getBlob, post } from "helpers/api_helper"
import { toast } from "react-toastify"
const DownloadList = props => {
  const {
    downloads,
    onGetDownloadReports,
    onDownloadReport,
    onPurge,
    totalCount,
    loading,
  } = props
  const { MapToRows, SearchCriteria, HEADERS, SearchOptions } = DownloadLogic
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([...HEADERS])
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  const maxSize = 10
  const [pageNumber, setPageNumber] = useState(1)
  const [params, setParams] = useState({
    dateRequested: moment(),//.format("YYYY-MM-DD"),
    page: pageNumber,
  })

  const reportTypes = useSWR(GET_REPORTDOWNLOADTYPES, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, reportTypes?.data?.Data)
  )
  useEffect(() => {
    setSearchOptions(
      SearchOptions(params?.fromDate, params?.toDate, reportTypes?.data?.Data)
    )
  }, [reportTypes])

  useEffect(() => {
    populateRows()
  }, [downloads])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [params])

  // useEffect(() => {
  //   search(SearchCriteria(params, maxSize))
  // }, [])

  const populateRows = () => {
    let rws = []
    if (downloads) {
      downloads.forEach(transaction => {
        let row = MapToRows(transaction)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            disabled={transaction?.ReportStatus != "Finished"}
            className="btn-rounded waves-effect waves-light"
            onClick={() => onDownload(transaction.ID)}
          >
            Download File
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }
  const handlePageChange = page => {
    setPageNumber(page)
    let p = { ...params, page }
    search(SearchCriteria(p, maxSize))
  }
  const onDownload = id => {
    loadData(id).then()
  }

  const loadData = id =>
    new Promise(async (resolve, reject) => {
      try {
        let contentType
        let headerLine
        toast.info("Download Started ...")
        getBlob("/api/ReportDownloads/Download?id=" + id)
          .then(async response => {
            if (response.ok) {
              contentType = response.headers.get("content-type")
              headerLine = response.headers.get("content-disposition")
              return response.blob()
            } else {
              let error = await response.text()
              throw new Error(JSON.parse(error)?.Message)
            }
          })
          .then(blob => {
            if (!headerLine) throw new Error("Download Failed")

            let startFileNameIndex = headerLine.indexOf('"') + 1
            let endFileNameIndex = headerLine.lastIndexOf('"')
            let filename = headerLine.substring(
              startFileNameIndex,
              endFileNameIndex
            )
            saveFile(blob, filename)
            toast.success("Download Complete")
          })
          .catch(err => {
            toast.error(err.message ?? "Download Failed")
          })
      } catch (err) {
        console.error(err)
        toast.error(err.data?.Message ?? "Download Failed")
      }
    })
  const saveFile = (blob, filename) => {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
    } else {
      const a = document.createElement("a")
      document.body.appendChild(a)
      const url = window.URL.createObjectURL(blob)
      a.href = url
      a.download = filename
      a.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      }, 0)
    }
  }

  function search(query) {
    onGetDownloadReports(query)
  }

  const onAction = p => {
    if (p["page"]) {
      setPageNumber(+p["page"])
    } else {
      p["page"] = pageNumber
    }
    let prms = { fromDate: params["fromDate"], toDate: params["toDate"], ...p }
  
    setParams(prms)
    search(SearchCriteria(prms, maxSize))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Report"} breadcrumbItem={"Downloads"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                // csv={{ url: GET_DOWNLOADS, query: SearchCriteria(params, maxSize) }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DownloadList.propTypes = {
  downloads: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetDownloadReports: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { downloads, error, success, downloadsTotalCount, loading },
}) => {
  // console.debug(downloads, error, success, downloadsTotalCount, loading )
  return {
    downloads,
    error,
    success,
    totalCount: downloadsTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDownloadReports: query => dispatch(getDownloadReports(query)),
  onDownloadReport: id => dispatch(downloadReport(id)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(DownloadList))))
