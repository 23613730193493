import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { reportPurge, getReceipts } from "store/actions"
import { GET_RECEIPTREPORT } from "helpers/url_helper"
import ReceiptLogic from "./receipt"
import withDispose from "components/HOCs/WithDispose"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"
import useSWR from "swr"
import { BaseApis, get } from "helpers/api_helper"
import { useInstitutionsData } from "hooks/useInstitutionData"

const ReceiptList = props => {
  const {
    receipts,
    onGetReceipts,
    error,
    onPurge,
    receiptTotalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions,
    MapToModalState,
  } = ReceiptLogic
  const { institutionsData, institutions } = useInstitutionsData()
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
    } = useReportData({ onGetData: onGetReceipts, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period: 1, periodicity: Periodicity.Day })

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

  useEffect(() => {
    populateRows()
  }, [receipts])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  async function printReceipt(transaction) {
    try {
      toast.info("Generating receipt")
      let id = transaction.ID
      const blob = await get(`/api/receipts/raw?id=${id}`,{responseType: 'blob'},false, BaseApis.Receipt).then(
        (response:any) => {
          if (!response) {
            throw new Error("Network response was not ok")
          }
          return new Blob([response], { type: 'application/pdf' });
        }
      )

      window.open(
        URL.createObjectURL(blob),
        `Receipt ${id}`,
        "width=500,height=600,left=100,top=100"
      )
      toast.success("Receipt generated")
    } catch (e) {
      toast.error(`Could not generate receipt. Reason: (${e.message})`)
    }

    // message.destroy()
  }
  const populateRows = () => {
    let rws = []
    if (receipts) {
      receipts.forEach(receipt => {
        let row = MapToRows(receipt)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => printReceipt(receipt)}
          >
            Print Receipt
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Report"} breadcrumbItem={"Receipts Report"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: receiptTotalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_RECEIPTREPORT,
                  query: SearchCriteria(params, maxSize)
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ReceiptList.propTypes = {
  receipts: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetReceipts: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  receiptTotalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { receipts, error, success, receiptTotalCount, loading },
}) => {
  return {
    receipts,
    error,
    success,
    receiptTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetReceipts: query => dispatch(getReceipts(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ReceiptList))))
