import { call, put, takeEvery, takeLeading } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CASELOGS,
  GET_CASELOG_DETAIL,
  CASELOG_PURGE,
  UPDATE_CASELOG,
  GET_ALL_CASELOGS,
  GET_CASELOG_MESSAGES,
  SEND_CASEMESSAGE,
  RESOLVE_CASE,
  MARK_AS_READ,
} from "./actionTypes"
import {
  getCaseLogsSuccess,
  getCaseLogsFail,
  getCaseLogDetailSuccess,
  getCaseLogDetailFail,
  getCaseLogMessagesSuccess,
  getCaseLogMessagesFail,
  caseLogPurgeSuccess,
  updateCaseLogSuccess,
  caseLogApiError,
} from "./actions"
import {
  getAllCaselogs,
  getCaselogDetail,
  getCaselogs,
  getCaselogMessages,
  sendMessage,
  resolveCase,
  markAsRead,
} from "helpers/backend_helper"
import {
  markAsReadFail,
  markAsReadSuccess,
  resolveCaseError,
  resolveCaseSuccess,
  sendMessageFail,
  sendMessageSuccess,
} from "store/actions"

const format = "yyyy-MM-DD"
function* fetchCaseLogs({ params }) {
  try {
    const response = yield call(getCaselogs, JSON.parse(params))
    yield put(getCaseLogsSuccess(response))
  } catch (error) {
    console.debug("Fetching CaseLogs Error", error)
    yield put(getCaseLogsFail(error))
  }
}
function* fetchAllCaseLogs() {
  try {
    const response = yield call(getAllCaselogs)
    yield put(getCaseLogsSuccess(response))
  } catch (error) {
    console.debug("Fetching CaseLogs Error", error)
    yield put(getCaseLogsFail(error))
  }
}

function* fetchCaseLogDetail({ id }) {
  try {
    const response = yield call(getCaselogDetail, id)
    yield put(getCaseLogDetailSuccess(response))
  } catch (error) {
    yield put(getCaseLogDetailFail(error))
  }
}

function* fetchCaseLogMessages({ id }) {
  try {
    const response = yield call(getCaselogMessages, id)
    yield put(getCaseLogMessagesSuccess(response))
  } catch (error) {
    yield put(getCaseLogMessagesFail(error))
  }
}

function* doResolveCase({ payload: { id } }) {
  try {
    const response = yield call(resolveCase, id)
    yield put(resolveCaseSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resolveCaseError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* markMessagesAsRead({ payload }) {
  try {
    const response = yield call(markAsRead, payload)
    yield put(markAsReadSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      markAsReadFail(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doSendMessage({ payload }) {
  try {
    const response = yield call(sendMessage, payload)
    yield put(sendMessageSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      sendMessageFail(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* updateCaseLog({ payload: { caseLog } }) {
  try {
    const response = yield call(putCaseLog, {
      ...JSON.parse(JSON.stringify(caseLog)),
    })

    yield put(updateCaseLogSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      caseLogApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* doPurge() {
  yield put(caseLogPurgeSuccess())
}

function* caseLogsSaga() {
  yield takeLeading(GET_CASELOGS, fetchCaseLogs)
  yield takeLeading(GET_ALL_CASELOGS, fetchAllCaseLogs)
  yield takeEvery(GET_CASELOG_DETAIL, fetchCaseLogDetail)
  yield takeEvery(CASELOG_PURGE, doPurge)
  yield takeLeading(UPDATE_CASELOG, updateCaseLog)
  yield takeLeading(RESOLVE_CASE, doResolveCase)
  yield takeLeading(GET_CASELOG_MESSAGES, fetchCaseLogMessages)
  yield takeLeading(SEND_CASEMESSAGE, doSendMessage)
  yield takeLeading(MARK_AS_READ, markMessagesAsRead)
}

export default caseLogsSaga
