import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//i18n
import { withTranslation } from "react-i18next"
import { getCardTransferReports, reportPurge } from "store/actions"
import CardTransferLogic from "./cardtransfer"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"
import { EXPORT_CARDTRANSFERREPORT} from "helpers/url_helper"
import useSWR from "swr"
import { useInstitutionsData } from "hooks/useInstitutionData"

const CardTransferList = props => {
  const {
    cardTransferTransactions,
    onGetTransactions,
    error,
    onPurge,
    totalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions
  } = CardTransferLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetTransactions, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period: 1, periodicity: Periodicity.Day })

  const { institutionsData, institutions } = useInstitutionsData()
  
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  
  useEffect(() => {
    populateRows()
  }, [cardTransferTransactions])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const populateRows = () => {
    let rws = []
    if (cardTransferTransactions) {
      cardTransferTransactions.forEach(transaction => {
        let row = MapToRows(transaction)

        rws.push(row)
      })
    }
    setRows(rws)
  }

  return (
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: EXPORT_CARDTRANSFERREPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
  )
}

CardTransferList.propTypes = {
  cardTransferTransactions: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetTransactions: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { cardTransferTransactions, error, success, cardTransferTotalCount, loading },
}) => {
  return {
    cardTransferTransactions,
    error,
    success,
    totalCount: cardTransferTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetTransactions: query => dispatch(getCardTransferReports(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CardTransferList))))
