import { UserCategory } from "enums/user-category"
import ClusterStorage from "helpers/storage-helper";
import { Utils } from "helpers/utility";
const AUTH_USER = "authUser";
const isEmpty = (obj) => {
  return !obj || Object.keys(obj).length == 0
}

export const setAuthUser = (authUser) => {
  ClusterStorage.setItem(AUTH_USER, authUser);
}
export const getAuthUser = () => {
  const user = ClusterStorage.getItem(AUTH_USER);


  if (user) {
    try {
      JSON.parse(user);
    } catch (err) {
      ClusterStorage.removeItem(AUTH_USER);
      window.location.href = "/logout"

    }
  }
  return user;
}
const authHeader = () => {
  const obj = JSON.parse(getAuthUser())

  if (!isEmpty(obj)) {
    return {
      AnalyticsAccessToken: obj.analyticsAccessToken,
      RefreshToken: obj.refreshToken,
      AuthUser: obj.authUser,
      isRemote: obj.authUser?.userCategory == UserCategory.ServiceProvider,
      AccessToken: { exp: obj.accessTokenExpirationDate },
    }
  } else {
    return {}
  }
}
export default authHeader

