import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import userSaga from "./auth/users/saga"
import roleSaga from "./auth/roles/saga"
import LayoutSaga from "./layout/saga"
import agentsSaga from "./agent/saga"
import reportsSaga from "./reports/saga"
import permissionsSaga from "./permission/saga"
import branchSaga from "./branch/saga"
import clusterManagerSaga from './agent-cluster-manager/saga';
import clusterSaga from './cluster/saga';
import networkManagerSaga from './agent-network-manager/saga';
import categoriesSaga from './agent-category/saga';
import miscSaga from "./misc/saga"
import mobileAccountSaga from "./mobile-account/saga"
import caselogSaga from "./caselog/saga"
import agentCommissionSaga from "./agent-commission/saga"
import agentCommissionStructureSaga from "./agent-commission-structure/saga"
import chargeBacksSaga from "./chargeback/saga"
import institutionsSaga from "./institution/saga"
import cashOutInstitutionsSaga from "./cashout-institution/saga"
import terminalSaga from "./terminal/saga"
import featureSaga from "./features/saga"
import subscriptionSaga from "./subscriptions/saga";
import auditTrailSaga from "./audit-trail/saga";
import settlementSaga from "./settlement/saga";
import routingSaga from "./route/saga";
import unsettledTransactionSaga from "./unsettled-transactions/saga";
import debitRequestsSaga from "./direct-debit/saga"
import notificationSaga from "./notification/saga"
export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    fork(AuthSaga),
    ProfileSaga(),
    fork(userSaga),
    fork(roleSaga),
    ForgetSaga(),
    LayoutSaga(),
    fork(reportsSaga),
    fork(agentsSaga),
    fork(branchSaga),
    fork(permissionsSaga),
    fork(clusterManagerSaga),
    fork(networkManagerSaga),
    fork(categoriesSaga),
    fork(miscSaga),
    fork(agentCommissionSaga),
    fork(agentCommissionStructureSaga),
    fork(mobileAccountSaga),
    fork(caselogSaga),
    fork(chargeBacksSaga),
    fork(clusterSaga),
    fork(institutionsSaga),
    fork(terminalSaga),
    fork(subscriptionSaga),
    fork(auditTrailSaga),
    fork(featureSaga),
    fork(cashOutInstitutionsSaga),
    fork(settlementSaga),
    fork(routingSaga),
    fork(unsettledTransactionSaga),
    fork(debitRequestsSaga),
    fork(notificationSaga)
  ])
}
