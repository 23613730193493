import React, { useEffect, useState } from "react"
import { Row, Col, FormGroup, Label, Collapse } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Utils } from "helpers/utility"
import Loading from "components/Common/Loading"
import RoutingLogic from "../routing"
const { MapToDropDown } = RoutingLogic;

const RouteConfigForm = (props) => {
  const {
    values, routes, errors, setRouteField, handleBlur, categories, setFieldValue, setErrors, isValid
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [transform, setTransform] = useState<string>();
  return (
    <React.Fragment>
      <div
        className={`title p-2 accordion-header box-shadow mt-2 ${isOpen ? "active" : ""}`}
        style={{ borderRadius: "5px" }}
        onClick={() => {
          setIsOpen(!isOpen)
          if (!isOpen) {
            setTransform("rotateZ(90deg)")
          } else {
            setTransform("rotateZ(0deg)")
          }
        }}>

        <Row>
          <Col lg={11} md={11} xs={11}>
            <h5><b>Routing Details</b></h5>
          </Col>
          <Col
            lg={1} md={1} xs={1}
            className="pl-3"
            style={{ display: "flex", alignItems: "center" }}>
            <i className={`bx bxs-right-arrow text-secondary`} style={{ transform: transform }} />
          </Col>
        </Row>
      </div>
      <Collapse className="p-4" isOpen={isOpen}>
        <Row>
          {values.Routes.map((routeDetail, i) => (
            <React.Fragment key={`detail_${i}`}>
              <Col lg={3} md={3} sm={6}>
                <FormGroup>
                  <Label>Switching Route</Label>
                  {!routes ? (
                    <Loading></Loading>
                  ) : (
                    <ClusterDropdown
                      id={"SwitchingRouteID" + i}
                      name={"SwitchingRouteID"}
                      defaultValue={
                        routes.find(c => c.ID == routeDetail?.SwitchingRouteID)?.Name}
                      focus
                      placeholder={"-Select-"}
                      value={routes.find(c => c.ID == routeDetail?.SwitchingRouteID)?.Name}
                      validationError={((errors.Routes as any[]) ?? [])[i]?.SwitchingRouteID}
                      items={MapToDropDown(routes)}
                      required={true}
                      onChange={(name, value) => {
                        setRouteField(setFieldValue, i, values.Routes, "SwitchingRouteID", value)
                      }}
                    ></ClusterDropdown>
                  )}
                </FormGroup>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <ClusterInput
                  label={"Minimum Amount"}
                  id={"MinimumAmount" + i}
                  name={"MinimumAmount"}
                  value={routeDetail?.MinimumAmount || 0}
                  min={0}
                  max={values.Routes[i]?.MaximumAmount || 0}
                  type="number"
                  required={true}
                  validationError={((errors.Routes as any[]) ?? [])[i]?.MinimumAmount}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setRouteField(setFieldValue, i, values.Routes, "MinimumAmount", e.target.value);
                    if (values.Routes[i].MinimumAmount >= values.Routes[i].MaximumAmount) setRouteField(setFieldValue, i, values.Routes, "MaximumAmount", +(e.target.value) + 1);
                  }

                  }
                ></ClusterInput>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <ClusterInput
                  label={"Maximum Amount"}
                  id={"MaximumAmount" + i}
                  name={"MaximumAmount"}
                  value={routeDetail?.MaximumAmount || 0}
                  min={(values.Routes[i]?.MinimumAmount || 0) + 1}
                  max={10000000000}
                  type="number"
                  required={true}
                  validationError={((errors.Routes as any[]) ?? [])[i]?.MaximumAmount}
                  onBlur={handleBlur}
                  onChange={(e) => setRouteField(setFieldValue, i, values.Routes, "MaximumAmount", e.target.value)}
                ></ClusterInput>
              </Col>
              <Col lg={3} md={3} sm={6}>
                <FormGroup>
                  <Label>Agent Category</Label>
                  {!categories ? (
                    <Loading></Loading>
                  ) : (
                    <ClusterDropdown
                      id={"AgentCategoryID" + i}
                      name={"AgentCategoryID"}
                      defaultValue={
                        categories.find(c => c.ID == routeDetail?.AgentCategoryID)
                          ?.Name}
                      placeholder={"-Select-"}
                      value={categories.find(c => c.ID == routeDetail?.AgentCategoryID)
                        ?.Name}
                      items={MapToDropDown(categories)}
                      onChange={(name, value) => {
                        setRouteField(setFieldValue, i, values.Routes, "AgentCategoryID", value)
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
              {values.Routes.length > 1 ? <Col lg={12} md={12} sm={12}>
                <ClusterButton

                  type={"button"}
                  color={"secondary"}
                  addon={"bx bx-minus btn-round"}
                  className={"btn-sm btn-outline mb-3"}
                  outline
                  onHandleClick={(e) => {
                    const routes = Object.assign([], values.Routes);
                    const err = { ...errors };
                    routes.splice(i, 1);
                    if (!Utils.Object.isEmpty(errors["Routes"])) {
                      delete (err["Routes"] as any)[i];
                      setErrors(err);
                    }
                    setFieldValue("Routes", routes)
                  }}
                >
                  {"Remove"}
                </ClusterButton>
              </Col> : null}
            </React.Fragment>))}
        </Row>
      </Collapse>
      <Row style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
        <ClusterButton
          disabled={!isValid}
          color={"primary"}
          addon={"bx bx-plus"}
          className={"btn-sm btn-outline"}
          outline
          onHandleClick={(e) => {
            let routes = [...values.Routes];
            routes.push({ SwitchingRouteID: 0, MinimumAmount: 0, MaximumAmount: 0 })
            setFieldValue("Routes", routes)
          }}
        >
          {"Add"}
        </ClusterButton>
      </Row>
    </React.Fragment>
  )
}

export default RouteConfigForm