import React, { } from "react"

import { Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"
import { Institution, InstitutionWalletDetails } from "../../institution"
import { Formik } from "formik"
import { PagedFormProps } from "components/Common/Form/PagedForm"

type InstitutionWalletDetailsFormProps = PagedFormProps<InstitutionWalletDetails> & {
  institution: Institution
  details: Institution
}
const InstitutionWalletDetailsForm: React.FC<InstitutionWalletDetailsFormProps> = props => {
  const {
    institution,
    isUpdate,
    onNext,
    onPrev
  } = props

  const Initiate = (details: any): InstitutionWalletDetails => {
    return {
      WalletCentralAccount: details?.WalletCentralAccount,
      WalletThirdPartyMirrorAccount: details?.WalletThirdPartyMirrorAccount,
      WalletAccountOfficerCode: details?.WalletAccountOfficerCode,
      WalletBaseURL: details?.WalletBaseURL,
      WalletProductCode: details?.WalletProductCode
    };
  }

  const validator = (values: InstitutionWalletDetails): any => {
    const errors: any = {}
    const required = Validators.required("Please enter a value");

    const account = () => [
      required,
      Validators.accountNumber(
        ["Only numbers are allowed",
          "Number of characters must be 10"]
      )
    ].forEach(validator => {
      const error = validator(values?.WalletCentralAccount)
      if (error) errors.WalletCentralAccount = error;
    });

    const mirrorAccount = () => [
      required,
      Validators.accountNumber(
        ["Only numbers are allowed",
          "Number of characters must be 10"]
      )
    ].forEach(validator => {
      const error = validator(values?.WalletThirdPartyMirrorAccount)
      if (error) errors.WalletThirdPartyMirrorAccount = error;
    });
    const code = () => [required, Validators.numbers()].forEach(validator => {
      const error = validator(values?.WalletProductCode)
      if (error) errors.WalletProductCode = error;
    });
    const url = () => [required, Validators.url()].forEach(validator => {
      const error = validator(values?.WalletBaseURL)
      if (error) errors.WalletBaseURL = error;
    });

    const validate = () => {
      try {
        account();
        mirrorAccount();
        url();
        code();
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();
  }
  return <Formik
    initialValues={Initiate(institution)}
    validate={validator}
    onSubmit={(values, { setSubmitting }) => {
      onNext({ ...values })
    }}
  >
    {({
      values,
      errors,
      touched,
      isValid,
      setFieldError,
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      isSubmitting,
    }) => (<ClusterForm handleSubmit={handleSubmit}>

      <React.Fragment>

        <Card
          outline
          style={{ background: "transparent", borderColor: "#999999" }}
          className="border"
        >

          <CardBody>
            <CardTitle>
              <b>Wallet Information</b>
            </CardTitle>
            <Row>
              <Col lg={4} md={4} sm={6}>
                <ClusterInput
                  defaultValue={values?.WalletCentralAccount}
                  value={values?.WalletCentralAccount}
                  label={"Wallet Central Account"}
                  id={"walletCentralAccount"}
                  name={"WalletCentralAccount"}

                  type="text"
                  required={true}
                  validationError={errors.WalletCentralAccount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></ClusterInput>
              </Col>
              <Col lg={4} md={4} sm={6}>
                <ClusterInput
                  defaultValue={values?.WalletThirdPartyMirrorAccount}
                  value={values?.WalletThirdPartyMirrorAccount}
                  label={"Mirror Central Account"}
                  id={"mirrorCentralAccount"}
                  name={"WalletThirdPartyMirrorAccount"}

                  validationError={errors.WalletThirdPartyMirrorAccount}
                  type="text"
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                ></ClusterInput>
              </Col>
              <Col lg={4} md={4} sm={6}>
                <ClusterInput
                  defaultValue={values?.WalletBaseURL}

                  label={"Wallet Base Url"}
                  id={"walletBaseUrl"}
                  name={"WalletBaseURL"}

                  type="url"
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.WalletBaseURL}
                  validationError={errors.WalletBaseURL}
                ></ClusterInput>
              </Col>


            </Row>
            <Row>
              <Col lg={4} md={4} sm={6}>
                <ClusterInput
                  defaultValue={values?.WalletProductCode}

                  label={"Wallet Product Code"}
                  id={"walletProductCode"}
                  name={"WalletProductCode"}

                  type="text"
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.WalletProductCode}
                  validationError={errors.WalletProductCode}
                ></ClusterInput>
              </Col>
              <Col lg={4} md={4} sm={6}>

                <ClusterInput
                  defaultValue={values?.WalletAccountOfficerCode}
                  value={values?.WalletAccountOfficerCode}
                  label={"Wallet Account Code"}
                  id={"walletAccountCode"}
                  name={"WalletAccountOfficerCode"}

                  type="text"
                  required={true}
                  onBlur={handleBlur}
                  onChange={handleChange}
                ></ClusterInput>
              </Col>


            </Row>

          </CardBody>
        </Card>
      </React.Fragment>
      <Row style={{ paddingBottom: "50px" }}>
        <Col lg={12} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ClusterButton
            style={{ justifySelf: "right" }}
            color={"light"}
            addon={"bx bxs-left-arrow"}
            onHandleClick={() => onPrev()}
            type={"button"}
          >
            Prev
          </ClusterButton>
          <ClusterButton
            style={{ justifySelf: "right" }}
            color={"primary"}
            addon={"bx bx-right-arrow"}
            disabled={(isUpdate && !touched) ? false : (!isValid || !touched)}
          >
            Next
          </ClusterButton>
        </Col>
      </Row>


    </ClusterForm>)}

  </Formik>
}


export default InstitutionWalletDetailsForm;