import * as actionTypes from "./actionTypes";

const INIT_STATE = {
    notifications : [],
    notification : {},
    error : {},
    success : null,
    totalCount : 0,
    loading : false,
    statusChanged : false
}

const Notifications = (state = INIT_STATE, action) => {
    switch (action.type){
        case actionTypes.GET_NOTIFICATIONS:
            return {
                ...state,
                loading : true,
                error : null,
                success : null
            }
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                notifications : action.payload?.Data?.Collection || [],
                totalCount : action.payload?.Data?.TotalCount || 0,
                loading : false
            }
        case actionTypes.GET_NOTIFICATIONS_FAIL:
            return {
                ...state,
                loading : false,
                success : null,
                error : action.payload
            }
        case actionTypes.GET_NOTIFICATION_DETAILS:
            return {
                ...state,
                error : null,
                success : null
            }
        case actionTypes.GET_NOTIFICATION_DETAILS_SUCCESS:
            return {
                ...state,
                notification : action.payload?.Data
            }
        case actionTypes.GET_NOTIFICATION_DETAILS_FAIL:
            return {
                ...state,
                error : action.payload
            }
        case actionTypes.POST_NOTIFICATION:
            return {
                ...state,
                loading : true,
                error : null,
                success : null
            }
        case actionTypes.POST_NOTIFICATION_SUCCESS:
            return {
                ...state,
                loading : false,
                success : action.payload,
                error : null
            }
        case actionTypes.POST_NOTIFICATION_FAIL:
            return {
                ...state,
                loading : false,
                success : null,
                error : action.payload
            }
        default:
            return state
    }
    return state
}

export default Notifications