import React, { } from "react"
import { GENDER } from "helpers/variables"
import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"

import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import Loading from 'components/Common/Loading';
import ClusterTextArea from "components/Common/TextArea"
import ClusterForm from 'components/Common/Form';
import withFormValidation from 'components/HOCs/WithFormValidation';
import { useUser } from "pages/UserManagement/Users/create/useUser"
import { Formik } from "formik"
import UserPermissionsForm from "./user-permission-form"
const UserDetailsForm = props => {

    let { states, statesLoading, isUpdate, editLoading, userDetails, handleValidSubmit, prepopulatedValues, setIsPrepopulated, isPrepopulated } = props;
    const {
        validator, initialValues
    } = useUser(userDetails, prepopulatedValues)
    return <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {

            handleValidSubmit({ ...values })

        }}
        validate={validator}
    >
        {({
            values,
            touched,
            isValid,
            errors,
            setFieldError,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
        }) => (<ClusterForm handleSubmit={handleSubmit}>


            <UserPermissionsForm {...props} isPrepopulated={isPrepopulated} prepopulatedValues={prepopulatedValues} />
            <React.Fragment>
                <Row>
                    <Col lg={12}>
                        <div style={{ display: "flex", justifyContent: "right", alignItems: "center", cursor: "pointer" }}>
                            <p color="danger" onClick={() => {
                                setIsPrepopulated(!isPrepopulated);
                            }}><u><b>Change Permissions</b></u></p>
                        </div>
                    </Col>
                </Row>

                <React.Fragment>
                    <Row>
                        <Col lg={12}>
                            <p>Basic Data</p>
                        </Col>
                    </Row>

                    <Card
                        outline
                        style={{ background: "transparent", borderColor: "#999999" }}
                        className="border"
                    >
                        <CardBody>
                            <Row>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        focus
                                        defaultValue={values?.lastName}

                                        label={"Surname"}
                                        id={"surname"}
                                        name={"lastName"}
                                        type="text"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.lastName}
                                        validationError={errors?.lastName}
                                    />
                                </Col>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.firstName}

                                        label={"First Name"}
                                        id={"firstname"}
                                        name={"firstName"}
                                        type="text"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.firstName}
                                        validationError={errors?.firstName}
                                    />
                                </Col>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.otherName}
                                        label={"Other Names"}
                                        id={"othernames"}
                                        name={"otherName"}
                                        type="text"
                                        required={false}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.otherName}
                                        validationError={errors?.otherName}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={3} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.username}
                                        label={"Username"}
                                        id={"username"}
                                        name={"username"}
                                        type="text"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.username}
                                        validationError={errors?.username}
                                    />
                                </Col>

                                <Col lg={1} md={2}>
                                    <FormGroup>
                                        <Label>Gender</Label>
                                        <Row>
                                            <ClusterDropdown
                                                style={{ left: "10%", top: "60%" }}
                                                defaultValue={values?.gender}
                                                value={values?.gender}
                                                placeholder={"- Select -"}
                                                items={GENDER}
                                                name={"gender"}
                                                onChange={(name, value) => {
                                                    setFieldValue("gender", value)
                                                }}
                                                required={true}
                                                validationError={errors?.gender}
                                            ></ClusterDropdown>
                                        </Row>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </React.Fragment>

                <React.Fragment>
                    <Row>
                        <Col lg={12}>
                            <p>Contact Me</p>
                        </Col>
                    </Row>

                    <Card
                        outline
                        style={{ background: "transparent", borderColor: "#999999" }}
                        className="border"
                    >
                        <CardBody>
                            <Row>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.email}
                                        label={"Email"}
                                        id={"email"}
                                        name={"email"}
                                        type="email"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.email}
                                        validationError={errors?.email}
                                    />
                                </Col>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.phoneNumber}
                                        label={"Phone Number"}
                                        id={"phonenumber"}
                                        name={"phoneNumber"}
                                        type="tel"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.phoneNumber}
                                        validationError={errors?.phoneNumber}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col lg={4} md={4}>
                                    <ClusterTextArea
                                        label={"Street Address"}
                                        className="form-control"
                                        defaultValue={values?.address ?? ""}
                                        id={"street"}
                                        name={"address"}
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.address}
                                        validationError={errors?.address}
                                    />

                                </Col>
                                <Col lg={4} md={4}>
                                    <ClusterInput
                                        defaultValue={values?.city}
                                        label={"City"}
                                        id={"city"}
                                        name={"city"}
                                        type="text"
                                        required={true}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values?.city}
                                        validationError={errors?.city}
                                    />
                                </Col>
                                <Col lg={4} md={4}>
                                    <FormGroup>
                                        <Label>State</Label>
                                        {statesLoading ? (
                                            <Loading></Loading>
                                        ) : (
                                            <ClusterDropdown
                                                defaultValue={
                                                    values?.state
                                                }
                                                value={
                                                    values?.state
                                                }
                                                name={"state"}
                                                placeholder={"- Select a state -"}
                                                items={states}
                                                onChange={(name, value) => {
                                                    setFieldValue("state", value)
                                                }}
                                                required={true}
                                                validationError={errors?.state}
                                            />
                                        )}
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </React.Fragment>


                <Row style={{ paddingBottom: "50px" }}>
                    <Col lg={2} md={3}>
                        <ClusterButton
                            loading={editLoading}
                            disabled={!touched ? true : (editLoading || !isValid)}
                            color={"primary"}
                            addon={"bx bx-save"}
                        >
                            {isUpdate ? "Update" : "Save"}
                        </ClusterButton>
                    </Col>
                </Row>
            </React.Fragment>

        </ClusterForm>)}
    </Formik>

}


export default withFormValidation(UserDetailsForm)