export enum ClusterInstitutions {
    Polaris,
    PurpleMoney,
    Access,
    Sterling,
    AccessCongo,
    Halo,
    Heritage,
    Grooming,
    Oche,
    TCF,
    RemoteHome
}
export { }