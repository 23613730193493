import { GetInstitution } from "helpers/authentication/institution"
import { BASESTATUS, Format, SEARCHOPTIONS } from "helpers/variables"

const HEADERS = [
  // { title: "Name", name: "name", entityKeyName: "Name" },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  {
    title: "Serial Number",
    name: "serialNumber",
    entityKeyName: "SerialNumber",
  },
  { title: "Mode", name: "mode", entityKeyName: "Mode" },
  { title: "Agent Code", name: "agentCode", entityKeyName: "AgentCode" },
  {
    title: "Merchant Percentage",
    name: "merchantPercentage",
    entityKeyName: "MerchantPercentage",
  },
  { title: "Status", name: "status", isBadge: true, entityKeyName: "IsActive" },
  { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
  name: "",
  terminalID: undefined,
  serialNumber: undefined,
  mode: undefined,
  agentCode: "",
  type: undefined,
  merchantPercentage: undefined,
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Terminal ID",
      id: "s-terminalID",
      defaultValue: "",
      name: "terminalID",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "s-status",
      defaultValue: "- Select -",
      data: BASESTATUS,
      name: "status",
      width: 3,
    },
    {
      type: "text",
      label: "Agent Code",
      id: "s-agentCode",
      defaultValue: "",
      name: "agentCode",
    },
    {
      type: "text",
      label: "Serial Number",
      id: "s-serialNumber",
      defaultValue: "",
      name: "serialNumber",
    },
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapToRows = terminal => {
  return {
    id: terminal.ID,
    terminalID: terminal.TerminalID,
    serialNumber: terminal.SerialNumber,
    name: terminal.Name,
    status: {
      label: terminal.IsActive ? "Active" : "Inactive",
      badgeClass: terminal.IsActive ? "success" : "secondary",
    },
    mode: terminal.Mode,
    agentCode: terminal.AgentCode ?? "Un-Assigned",
    merchantPercentage: Format.percentage(terminal.MerchantPercentage),
    institution: terminal.InstitutionName,
  }
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Name: parameters["name"],
    SerialNumber: parameters["serialNumber"],
    TerminalID: parameters["terminalID"],
    AgentCode: parameters["agentCode"],
    Status: parameters["status"],
    AgentPhoneNumber: parameters["agentPhoneNumber"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

const MapToModalState = (terminalDetail) => {
  const modalData = [
    {
      title: "",
      items: [
        {
          id: "terminal",
          label: "Terminal",
          name: "terminalId",
          value: terminalDetail.TerminalID,
        },
        {
          id: "agentCode",
          label: "Agent Code",
          name: "agentCode",
          value: terminalDetail.AgentCode ?? "Un-Assigned",
        },
        {
          id: "serialNumber",
          label: "Serial Number",
          name: "serialNumber",
          value: terminalDetail.SerialNumber,
        },
        {
          id: "mode",
          label: "Mode",
          name: "mode",
          value: terminalDetail.Mode,
        },
        {
          id: "type",
          label: "Type",
          name: "type",
          value: terminalDetail.Type,
        },
        {
          id: "merchantPercentage",
          label: "Merchant Percentage",
          name: "merchantPercentage",
          value: terminalDetail.MerchantPercentage,
        }
      ],
    },
  ]
  return modalData
}

export default {
  HEADERS,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  ERRORLIST,
  MapToDropDown,
}
