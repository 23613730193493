import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Container
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png";

//i18n
import { withTranslation } from "react-i18next"
import { clusterPurge, createCluster, getAgentDetail, getAllUsers, getClusterDetail, getUsers, updateCluster } from "store/actions"
import ClustersForm from './form';
import ClusterLogic from "../cluster";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { CREATE_CLUSTER, EDIT_CLUSTER } from '../permission'
import useSWR from 'swr'
import { GET_ALL_CLUSTER_MANAGERS } from 'helpers/url_helper'
import { fetcher } from 'helpers/api_helper'
const CreateAgentCluster = props => {
  const { match, onCreateCluster,onUpdateCluster,onGetUsers,users, onGetClusterDetail, success, editLoading, loading, error, clusters, clusterDetail } = props
  const { ERRORLIST } = ClusterLogic;
  const [clusterDetails, setClusterDetails] = useState();
  const [errorList, setErrorList] = useState(ERRORLIST);
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [cluster, setCluster] = useState({}); //data from form
  // const [error, setError] = useState();
  useEffect(() => {
    getCluster()
    onGetUsers()

  }, [])

  useEffect(() => {
    getCluster()
  }, [match])

  const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })
  const handleOnChange = (key, value) => {

    let u = cluster;
    u[key] = value;
    setCluster(u);
  }
  const handleValidSubmit = (e) => {
    e.preventDefault();
    if (isUpdate==false) {

      onCreateCluster(cluster)
    } else {
      let clusterToUpdate ={ ...clusterDetail, ClusterManagerID:clusterDetail.ClusterManager?.ID, ...cluster };
      for (let key in clusterToUpdate) {
        if (
          clusterToUpdate[key] instanceof Object ||
          clusterToUpdate[key] instanceof Array
        ) {
          delete clusterToUpdate[key]
        }
      }
      onUpdateCluster(clusterToUpdate);
    }
  }

  const getCluster = () => {
    const id = match.params.id;
    if (id && id > 0) {
      setIsUpdate(true);
      let u = clusters.find(c => c.id == id);
      if (!u) { if (Object.keys(clusterDetail).length == 0) { onGetClusterDetail(id); } else { setClusterDetails(clusterDetail) } return; }
      setClusterDetails(u);
    } else {
      setIsUpdate(false)
    }
  }


  return (
    <GuardedPage permissions={[EDIT_CLUSTER,CREATE_CLUSTER]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Cluster"}
              image={clusterian}
              breadcrumbItem={"Create Cluster"}
            />

            <ClustersForm
              redirectUrl={"/view-clusters"}
              success={success}
              error={error}
              isUpdate={isUpdate}
              loading={loading}
              users={users}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              details={clusterDetail}
              clusterManagers={(clusterManagers?.data as any)?.Data}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
            ></ClustersForm>

          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>)
}



CreateAgentCluster.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateCluster: PropTypes.func,
  onGetClusterDetail: PropTypes.func,
  onBack: PropTypes.func,
  clusterDetail: PropTypes.object,
  clusters: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({Users:{users}, clusters: { clusters, error, success, clusterDetail, editLoading, totalCount, loading } }) => {
  return {
    clusters: [...clusters],
    error,
    success,
    clusterDetail,
    totalCount,
    users,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateCluster: (cluster) => dispatch(createCluster(cluster)),
  onGetClusterDetail: (id) => dispatch(getClusterDetail(id)),
  onUpdateCluster:(cluster)=> dispatch(updateCluster(cluster)),
  onPurge:()=>dispatch(clusterPurge()),
  onGetUsers:()=>dispatch(getAllUsers())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateAgentCluster))))








