import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import {
  getAllAgents,
  accountValidation,
  agentPurge,
  createAgent,
  getAgentDetail,
  getAllCategories,
  getAllClusterManagers,
  getAllNetworkManagers,
  getAllRoles,
  getRoles,
  updateAgent,
} from "store/actions"
import AgentForm from "./form"
import AgentLogic from "../agent"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import {
  ACCOUNT_VALIDATION,
  GET_ALL_AGENTS,
  GET_ALL_CATEGORIES,
  GET_ALL_CLUSTERS,
} from "helpers/url_helper"
import { toast } from "react-toastify"
import GuardedPage from "components/HOCs/GuardedPage"
import { CREATE_AGENT, EDIT_AGENT } from "../permission"
import { Utils } from "helpers/utility"
const CreateAgent = props => {
  let {
    match,
    onCreateAgent,
    onGetRoles,
    onUpdateAgent,
    onGetAgentDetail,
    editLoading,
    loading,
    agents,
    roles,
    agentDetail,
  } = props
  const [agentDetails, setAgentDetails] = useState(agentDetail)
  const [isRotating, setIsRotating] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  const categories = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const categoriesData = useMemo(() => {
    return (categories.data as any)?.Data ?? []
  }, [categories.data])

  useEffect(() => {
    if (Utils.Object.isEmpty(agentDetails)) {
      setAgentDetails(agentDetail)
    }
  }, [agentDetail])

  const agentsData_ = useSWR(`${GET_ALL_AGENTS}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

    const agentsData = useMemo(() => {
        return (agentsData_.data as any)?.Data ?? []
    }, [agentsData_.data])


  useEffect(() => {})

  const clusters = useSWR(GET_ALL_CLUSTERS, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  const clustersData = useMemo(() => {
    return (clusters.data as any)?.Data ?? []
  }, [clusters.data])

  useEffect(() => {
    getAgent()
    onGetRoles()
  }, [])

  useEffect(() => {
    getAgent()
  }, [match])

  const handleValidSubmit = values => {
    let valuesToUpsert = { ...values }
    if (isUpdate) {
      valuesToUpsert = {
        ...agentDetails,
        LGAID: agentDetails.LGA?.ID,
        NetworkManagerID: agentDetails.NetworkManager?.ID,
        LocationID: agentDetails.Location?.ID,
        ClusterManagerID: agentDetails.ClusterManager?.ID,
        CategoryID: agentDetails.Category?.ID,
        ...values,
      }
    }
    for (let key in valuesToUpsert) {
      if (
        valuesToUpsert[key] instanceof Object ||
        valuesToUpsert[key] instanceof Array
      ) {
        delete valuesToUpsert[key]
      }
    }
    if (values.TheAgentType && agentDetails.Type != values.TheAgentType) {
      valuesToUpsert.TheAgentType = values.TheAgentType
    }
    if (isUpdate) onUpdateAgent(valuesToUpsert)
    else onCreateAgent(valuesToUpsert)
  }

  const getAgent = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = agents.find(c => c.id == id)
      if (!u) {
        if (Object.keys(agentDetail).length == 0) {
          onGetAgentDetail(id)
        } else {
          if (Utils.Object.isEmpty(agentDetails)) {
            setAgentDetails(agentDetail)
          }
        }
        return
      }
      setAgentDetails(u)
    } else {
      setIsUpdate(false)
    }
  }

  return (
    <GuardedPage permissions={[CREATE_AGENT, EDIT_AGENT]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Agents"}
              image={clusterian}
              breadcrumbItem={"Create Agent"}
            />

            <AgentForm
              redirectUrl={"/view-agents"}
              success={props.success}
              error={props.error}
              isUpdate={isUpdate}
              loading={loading}
              isRotating={isRotating}
              roles={roles}
              editLoading={editLoading}
              agent={agentDetails}
              agents = {agentsData}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
              categories={categoriesData}
              categoriesLoading={categories?.isValidating}
              clusters={clustersData}
              clustersLoading={clusters.isValidating}
            />
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateAgent.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateAgent: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onBack: PropTypes.func,
  agentDetail: PropTypes.object,
  agents: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  agents: {
    agents,
    error,
    accountValidationLoading,
    success,
    agentDetail,
    editLoading,
    totalCount,
    loading,
  },
  Roles: { roles },
  networkManagers: { networkManagers },
  clusterManagers: { clusterManagers },
  categories: { categories },
}) => {
  return {
    agents: [...agents],
    error,
    success,
    agentDetail,
    totalCount,
    loading,
    accountValidationLoading,
    editLoading,
    roles,
    clusterManagers,
    networkManagers,
    categories,
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateAgent: agent => dispatch(createAgent(agent)),
  onGetAgentDetail: id => dispatch(getAgentDetail(id)),
  onGetCategories: () => dispatch(getAllCategories()),
  onGetNetworkManagers: () => dispatch(getAllNetworkManagers()),
  onGetClusterManagers: () => dispatch(getAllClusterManagers()),
  onGetRoles: () => dispatch(getAllRoles()),
  onValidateAccount: accountNumber =>
    dispatch(accountValidation(accountNumber)),
  onPurge: () => dispatch(agentPurge()),
  onUpdateAgent: agent => dispatch(updateAgent(agent))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateAgent))))
