import React, { useState, useEffect, useRef, useMemo } from "react"
import PropTypes from "prop-types"

import {
  Col,
  Row,
  Input,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Badge,
} from "reactstrap"
import { compare, Utils } from "helpers/utility"
import { SortingType } from "enums/sorting-type"
import { DropdownOptions } from "types/dropdown-options"
import { InputProps } from "types/props/input"
import { DropdownType } from "enums/dropdown-type"
import { useHasPropsChanged, usePrevious } from "hooks/usePrevious"
import { Validators } from "helpers/validators"


type DropdownProps = InputProps & {
  type?: DropdownType
  useValue?: boolean
  items: DropdownOptions[],
  allowDuplicates?: boolean
  clearOnOptionsChange?: boolean
}
const ClusterDropdown: React.FC<DropdownProps> = props => {
  let {
    useValue,
    defaultValue,
    disabled = false,
    items,
    onChange,
    className,
    name,
    type = DropdownType.TypeAhead,
    style,
    id,
    placeholder,
    onBlur,
    validationError,
    required,
    allowDuplicates = false,
    value,
    clearOnOptionsChange = false
  } = props

  const inputDisabled = useMemo(() => {
    return Utils.Boolean.Convert(disabled);
  }, [disabled])

  const uniqueItems = useMemo(() => {
    const options =  !allowDuplicates ? (items ?? []).filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i) : items;
    return options.sort(compare("name"))
  }, [items])

  switch (type) {
    case DropdownType.Select:
      return <ClusterSelectDropdown
        name={name}
        useValue={useValue}
        defaultValue={defaultValue}
        disabled={inputDisabled}
        items={uniqueItems}
        onChange={onChange}
        className={className}
        style={style}
        id={id}
        placeholder={placeholder}
        onBlur={onBlur}
        validationError={validationError}
        required={required}
      />
    case DropdownType.MultiSelect:
      return <ClusterMultiSelectDropdown
        name={name}
        useValue={useValue}
        defaultValue={defaultValue}
        disabled={inputDisabled}
        items={uniqueItems}
        onChange={onChange}
        className={className}
        style={style}
        id={id}
        placeholder={placeholder}
        onBlur={onBlur}
        validationError={validationError}
        required={required}
        value={value}
        clearOnOptionsChange={clearOnOptionsChange}
      />
    default:
      return <ClusterTypeAheadDropdown
        name={name}
        useValue={useValue}
        defaultValue={defaultValue}
        disabled={inputDisabled}
        items={uniqueItems}
        onChange={onChange}
        className={className}
        style={style}
        id={id}
        placeholder={placeholder}
        onBlur={onBlur}
        validationError={validationError}
        value={value}
        required={required}
      />

  }
}

ClusterDropdown.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.array,
  defaultValue: PropTypes.any,
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.any,
  style: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  useValue: PropTypes.bool,
}
export default ClusterDropdown


const ClusterTypeAheadDropdown: React.FC<DropdownProps> = props => {
  type TypeAheadData = {
    text: string,
    suggestions: DropdownOptions[]
  }
  const {
    useValue,
    defaultValue,
    disabled,
    items,
    name,
    onChange,
    className,
    style,
    id,
    placeholder,
    value,
    onBlur,
    required
  } = props
  let {
    validationError} =props;
  const [data, setData] = useState<TypeAheadData>({ text: "", suggestions: [] })
  const [transform, setTransform] = useState("rotateZ(90deg)")
  const [isDroppedDown, setIsDroppedDown] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [current, setCurrent] = useState<string>(defaultValue ?? data.text ?? "")
  const { ref } = useOutOfComponent(() => onRenderOptions(false));
  const previousValue = usePrevious(value);
  const previousItems = usePrevious(items);
  const error = useMemo(()=>{
    if(required===true) {
      const errorMessage = Validators.required("Please enter a value")(current);
      if(errorMessage) return errorMessage;
    }
    return validationError;
  },[current, validationError,required])
  useEffect(() => {
    setData({ ...data, text: defaultValue })
    setCurrent(defaultValue ?? "")
  }, [])

  

  useEffect(() => {
    if (onChange && Utils.hasChanged(previousValue, value)===true) {
      const node = id ? document.querySelector(`input#${id}`) as any : document.querySelector(`input[name=${name}]`) as any;
      Utils.triggerInputChange(node, value)
      setCurrent(value)
      setData({ ...data, text: value })
      const item = items.filter(c => c.value == value || (c.name!=undefined && c.name == value))[0];
      if (item) onChange(name, useValue ? item.value : item.id ?? item.value)
    }

  }, [value, id])


  useEffect(() => {
    if (Utils.hasChanged(previousItems, items)===true && !Utils.List.isEmpty(previousItems)) {
      setData({ ...data, text: "" })
      setCurrent("")
    }
  }, [items])

  const onInput = e => {
    let suggestions: DropdownOptions[] = []
    const value = e.target.value
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, `i`);
      suggestions = items.filter((v: DropdownOptions) => regex.test(v.value) || regex.test(v.name))
    }
    else if (isDroppedDown) suggestions = items;

    setData({ suggestions, text: value })

    setCurrent(value ?? "")
  }

  const onRenderOptions = (shouldDropDown) => {
    if (shouldDropDown) {
      renderSuggestions();
      setTransform("rotateZ(180deg)")
    }
    else setTransform("rotateZ(90deg)");
    setIsDroppedDown(shouldDropDown);
    setData({ ...data, suggestions: shouldDropDown ? items : [] });
    setIsDisabled(!shouldDropDown);
  }

  const renderSuggestions = () => {
    const { suggestions } = data
    if (suggestions.length === 0 && !isDroppedDown) return null
    return (
      <ul
        className="dropdown-menu show"
        style={{ maxHeight: "150px", overflowY: "auto" }}
      >

        {suggestions.length == 0 ? <li className="dropdown-item"><p color={"secondary"}>No data available</p></li> : Utils.List.sort(suggestions, "name", SortingType.Asc).map((item: DropdownOptions, key) => (
          <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            key={"dropdown-item-" + item.value + "_" + key}
            onClick={e => suggestionSelected(item.key, item)}
          >
            {Utils.Strings.camelPad(item.name) ?? Utils.Strings.camelPad(item.value)}
          </li>
        ))}
      </ul>
    )
  }
  const suggestionSelected = (key, item: DropdownOptions) => {
    const label = item.name ?? item.value;
    const value = useValue ? item.value : item.id ?? item.value;
    onChange(name, value)
    setData({ text: label, suggestions: [] })
    setCurrent(label)
    onRenderOptions(!isDroppedDown)
    setIsDisabled(true);
  }
  const clear = () => {

    onChange(name, undefined)
    setData({ ...data, text: "" })
    setCurrent("")
    onRenderOptions(!isDroppedDown)
  }
  return (
    <React.Fragment>
      <div ref={ref}>
        <div
          style={{ position: "relative" }} 
          onClick={(e) => {
             if (!disabled && isDisabled) { 
            onRenderOptions(!isDroppedDown) }
          }}>
          <Input
            type="text"
            className={`form-control mb-2 mr-sm-3 ${className}`}
            id={id}
            placeholder={placeholder}
            value={Utils.Strings.camelPad(current)}
            name={name}
            required={required}
            style={{ ...style, cursor: isDisabled ? "pointer" : "text" }}
            autoFocus={isDroppedDown}
            onChange={onInput}
            onBlur={onBlur}
          />
          {disabled ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              {current && isDroppedDown && <div
                onClick={clear}
                className={"dropdown-icon"}
                style={{
                  transform: transform,
                  fontWeight: "bolder",
                  fontSize: "2em"
                }}
              >
                <i className="bx bx-x"></i>
              </div>
              }
              {((current && !isDroppedDown) || !current) && <div
                onClick={onRenderOptions}
                className={"dropdown-icon"}
                style={{
                  transform: transform,
                }}
              >
                <i className="bx bxs-up-arrow"></i>
              </div>}
            </React.Fragment>
          )}
        </div>
        <Row>
          <Col lg={12}>{renderSuggestions()}</Col>
        </Row>
      </div>

      {error ? (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {error}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )


}
const ClusterMultiSelectDropdown: React.FC<DropdownProps> = props => {
  type TypeAheadData = {
    text: string,
    suggestions: DropdownOptions[]
  }
  const {
    useValue,
    defaultValue,
    disabled,
    items,
    name,
    onChange,
    className,
    style,
    value,
    id,
    placeholder,
    validationError,
    onBlur,
    required,
    clearOnOptionsChange
  } = props
  const [data, setData] = useState<TypeAheadData>({ text: "", suggestions: [] })
  const [transform, setTransform] = useState("rotateZ(90deg)")
  const [isDroppedDown, setIsDroppedDown] = useState(false)
  const [isDisabled, setIsDisabled] = useState(true)
  const [current, setCurrent] = useState<string>(data.text ?? "")
  const { ref } = useOutOfComponent(() => onRenderOptions(false));
  const [selectedItems, setSelectedItems] = useState({});
  const previousItems = usePrevious(items);
  useEffect(() => {
    let items = {}
    const inputDefaultValue = defaultValue || value;
    if (!Utils.List.isEmpty(inputDefaultValue) && Utils.Object.isEmpty(selectedItems)) {
      inputDefaultValue.forEach(element => {
        if(element.value){
          items[element.key] = element.value;
        }
        else{
        items[element.ID] = element.Name;
        }
      });
      setSelectedItems(items)
    }
  }, [defaultValue, value])

  useEffect(() => {

    if (Utils.hasChanged(previousItems, items) && !Utils.Object.isEmpty(previousItems) && clearOnOptionsChange) {
      setSelectedItems({})
      setData({ ...data, text: "" })
      setCurrent("")
    }
  }, [items])

  const onInput = e => {
    let suggestions: DropdownOptions[] = []
    const value = e.target.value
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, `i`);
      suggestions = items.filter((v: DropdownOptions) => regex.test(v.value) || regex.test(v.name))
    }
    else if (isDroppedDown) suggestions = items;

    setData({ suggestions, text: value })

    setCurrent(value ?? "")
  }

  const onRenderOptions = (shouldDropDown) => {
    if (shouldDropDown) {
      renderSuggestions();
      setTransform("rotateZ(180deg)")
    }
    else setTransform("rotateZ(90deg)");
    setIsDroppedDown(shouldDropDown);
    setData({ ...data, suggestions: shouldDropDown ? items : [] });
    setIsDisabled(!shouldDropDown);
  }

  const renderSuggestions = () => {
    const { suggestions } = data
    if (suggestions.length === 0 && !isDroppedDown) return null
    return (
      <ul
        className="dropdown-menu show"
        style={{ maxHeight: "150px", overflowY: "auto" }}
      >

        {suggestions.length == 0 ? <li className="dropdown-item"><p color={"secondary"}>No data available</p></li> : Utils.List.sort(suggestions, "name", SortingType.Asc).map((item: DropdownOptions, key) => (
          <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            key={"dropdown-item-" + item.value + "_" + key}
            onClick={e => suggestionSelected(item.key, item)}
          >
            {Utils.Strings.camelPad(item.name) ?? Utils.Strings.camelPad(item.value)}
          </li>
        ))}
      </ul>
    )
  }
  const suggestionSelected = (key, item: DropdownOptions) => {
    const label = item.name ?? item.value;
    const value = useValue ? item.value : item.id ?? item.value;
    let items = { ...selectedItems }
    items[value] = label;
    setSelectedItems(items);
    setData({ text: label, suggestions: [] })
    setCurrent(label)
    onRenderOptions(!isDroppedDown)
    setIsDisabled(true);
  }

  const removeSelectedItem = (key) => {
    let items = Object.assign({}, selectedItems);
    delete items[key];
    setSelectedItems(items);
    setCurrent("")
  }

  const getListOfSelectedItems = (items) => {

    let i = [];
    for (let key in items) {
      i.push({ key, value: items[key] })
    }
    onChange(name, i.map(s => s.key).join(','));
    return i.sort(compare("value"));
  }
  const listOfSelectedItems = useMemo(() => {

    let items = getListOfSelectedItems(selectedItems)
    return items.sort(compare("value"));
  }, [selectedItems])

  const clear = () => {
    onChange(name, "")
    setCurrent("")
    setSelectedItems({});
    onRenderOptions(!isDroppedDown)
  }
  return (
    <React.Fragment>
      <div ref={ref}>
        <div
          style={{ position: "relative" }} 
          onClick={(e) => { 
            if (!disabled && isDisabled) { 
              onRenderOptions(!isDroppedDown); }
              }}>
          <Input
            type="text"
            className={`form-control mb-2 mr-sm-3 ${className}`}
            id={id}
            placeholder={placeholder}
            value={Utils.Strings.camelPad(current)}
            name={name}
            required={required}
            style={{ ...style, cursor: isDisabled ? "pointer" : "text" }}
            autoFocus={isDroppedDown}
            onChange={onInput}
            onBlur={onBlur}
          />
          {disabled ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              {current && isDroppedDown && <div
                onClick={clear}
                className={"dropdown-icon"}
                style={{
                  transform: transform,
                  fontWeight: "bolder",
                  fontSize: "2em"
                }}
              >
                <i className="bx bx-x"></i>
              </div>
              }
              {((current && !isDroppedDown) || !current) && <div
                onClick={onRenderOptions}
                className={"dropdown-icon"}
                style={{
                  transform: transform,
                }}
              >
                <i className="bx bxs-up-arrow"></i>
              </div>}
            </React.Fragment>
          )}
        </div>
        <Row>
          <Col lg={12}>{renderSuggestions()}</Col>
        </Row>
        <div>
          {listOfSelectedItems.map((item, i) => (
            <Badge key={`multi-select-${name}-${i}`} className="mr-1 mb-1">
              <div className="d-flex" style={{ justifyContent: "space-between", alignItems: "center" }}>
                <div className="mr-1">{item.value}</div>
                {!disabled&&<div className="p-1" onClick={() => removeSelectedItem(item.key)} style={{ background: "rgba(255,255,255,0.1)", cursor: "pointer" }}>
                  <i className="bx bx-x"></i>
                </div>}
              </div>
            </Badge>))}
        </div>
      </div>

      {validationError? (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {validationError}
        </div>
      ): <></>}
    </React.Fragment>
  )

}
const ClusterSelectDropdown: React.FC<DropdownProps> = props => {
  const {
    useValue,
    defaultValue,
    disabled = false,
    items,
    name,
    onChange,
    className,
    style,
    id,
    placeholder,
    validationError,
    onBlur
  } = props

  const [singlebtn, setSinglebtn] = useState(false)
  const [current, setCurrent] = useState(defaultValue ?? "")
  const [myColor, setMyColor] = useState("secondary")
  const toggleCurrent = (value, key) => {
    setCurrent(value)
    onChange(name, value)
    setMyColor("success")
  }
  useEffect(() => {
    setCurrent(defaultValue)
  }, [defaultValue])

  return (
    <React.Fragment>
      <Dropdown
        isOpen={singlebtn}
        toggle={() => setSinglebtn(!singlebtn)}
        id={id}
        placeholder={placeholder}
        style={props.style}
        disabled={disabled}
        onBlur={onBlur}
      >
        <DropdownToggle
          color={myColor}
          outline={true}
          className={` ${className} `}
          caret
          style={style}
        >
          {current}
          <i className="mdi mdi-chevron-down" />
        </DropdownToggle>
        <DropdownMenu>
          {items.length == 0 ? <DropdownItem color="secondary">No data available</DropdownItem> : Utils.List.sort(items, "value", SortingType.Asc).map((item: DropdownOptions, key) => {
            return (
              <DropdownItem
                key={"dropdown-item-" + item.value + "_" + key}
                onClick={() => toggleCurrent(item.value, (useValue ? item.value : item.id) ?? item.value)}
              >
                {item.value}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </Dropdown>
      {validationError ? (
        <div className="invalid-feedback" style={{ display: "block" }}>
          {validationError}
        </div>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )


}


const useOutOfComponent = (callback) => {
  const [isOutOfComponent, setIsOutOfComponent] = useState<boolean>(false);
  const ref = useRef<any>();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current?.contains(event.target)) {
      setIsOutOfComponent(true);
      callback();
    } else {
      setIsOutOfComponent(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isOutOfComponent, setIsOutOfComponent };
}