import PropTypes from "prop-types"
import React, { useEffect, useState, useMemo } from "react"
import moment from "moment"
import { NavLink, NavItem, Nav, Card, CardBody, Col } from "reactstrap"
import classnames from "classnames"
import ClusterButton from "components/Common/Button/Button"
import "./search.scss"
import DashboardHelper from "./dashboard"
import withDispose from "components/HOCs/WithDispose"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import Loading from "components/Common/Loading"
import { GetInstitution } from "helpers/authentication/institution"
import { DropdownType } from "enums/dropdown-type"
import { useInstitutionsData } from "hooks/useInstitutionData"

const DATEFILTER = [
  {
    label: "Today",
    start: moment().local().startOf("day"),
    end: moment().local().endOf("day"),
  },
  {
    label: "Yesterday",
    start: moment().local().startOf("day").subtract(1, "day"),
    end: moment().local().startOf("day").subtract(1, "millisecond"),
  },
  {
    label: "This Week",
    start: moment().local().startOf("week"),
    end: moment().local().endOf("week"),
  },
  {
    label: "This Month",
    start: moment().local().startOf("month"),
    end: moment().local().endOf("month"),
  },
  { label: "Last 7 Days", start: moment().local().subtract(7, "days"), end: moment().local() },
  {
    label: "Last 30 Days",
    start: moment().local().subtract(30, "days"),
    end: moment().local(),
  },
]
const Search: React.FC<any> = props => {
  let {
    endDateStr,
    startDateStr,
    setStartDateStr,
    setEndDateStr,
    setCode,
    code
  } = props

  let [startDate, setStartDate] = useState()
  let [endDate, setEndDate] = useState()
  let [institutionCode, setInstitutionCode] = useState(code)
  const [activeTab, setActiveTab] = useState(DashboardHelper.getActiveTab())
  useEffect(() => {
    setStartDate(startDateStr)
    setEndDate(endDateStr)
  }, [startDateStr, endDateStr])

  useEffect(() => {
    setStartDateStr(DashboardHelper.getStartDate())
    setEndDateStr(DashboardHelper.getEndDate())
  }, [])


  const handleOnChange = (start, end) => {
    setStartDate(start)
    setEndDate(end)

  }
  const cacheDate = (from, to) => {
    setStartDate(from)
    setEndDate(to)
    DashboardHelper.setStartDate(from)
    DashboardHelper.setEndDate(to)
  }
  const toggleTab = tab => {

    if (activeTab !== tab.label) {
      setActiveTab(tab.label)
      DashboardHelper.setActiveTab(tab.label);
      const from = tab.start.format("YYYY-MM-DDTHH:mm:ss");
      const to = tab.end.format("YYYY-MM-DDTHH:mm:ss");
      setStartDateStr(from)
      setEndDateStr(to)
      cacheDate(from, to)
    }
  }
  const MapToDropDown = data => {
    if (data instanceof Array) {
      return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
    }
    return []
  }

  const handleSearch = () => {
    const from = moment(startDate).local().startOf("day").format("YYYY-MM-DDTHH:mm:ss");
    const to = moment(endDate).local().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
    setStartDateStr(from)
    setEndDateStr(to)
    cacheDate(from, to)
    setCode(institutionCode)
    setActiveTab("")
    DashboardHelper.setActiveTab("");
  }
  const [minimize, setMinimize] = useState(true)

  const { institutionsData, institutions } = useInstitutionsData()
  if (minimize) {
    return (
      <div
        onClick={e => {
          e.preventDefault()
          setMinimize(!minimize)
        }}
        className={"bg-pink action action-right"}
        style={{ transform: "translateY(-10px)" }}
      >
        <i className="bx bx-search" />
      </div>
    )
  } else {
    return (
      <div className={"bg-light side-nav"}>
        <div
          onClick={e => {
            e.preventDefault()
            setMinimize(!minimize)
          }}
          className={"bg-pink  action action-right"}
        >
          <i className="bx bx-x" />
        </div>
        <div className={"side-nav-container"}>
          <div
            style={{ height: "100%", display: "flex", flexDirection: "column" }}
          >
            <Card>
              <CardBody style={{ padding: "2.5%" }}>
                <Nav
                  pills
                  className="bg-light rounded"
                  style={{ height: "max-content" }}
                >
                  {DATEFILTER.map((filter, key) => (
                    <NavItem key={`nav-item_${key}`}>
                      <NavLink
                        className={classnames({
                          active: activeTab === filter.label,
                        })}
                        onClick={() => {
                          toggleTab(filter)
                        }}
                      >
                        <small>{filter.label}</small>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </CardBody>
            </Card>

            <div style={{ width: "100%", marginTop: "20px" }}>
              <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                  <label className="input-group-text">Start Date</label>
                </div>
                <input
                  type="date"
                  value={moment(startDate).format("YYYY-MM-DD")}
                  onChange={e => handleOnChange(e.target.value, endDate)}
                  className="form-control"
                />
              </div>
              <div
                className="input-group input-group-sm"
                style={{ marginTop: "10px" }}
              >
                <div className="input-group-prepend">
                  <label className="input-group-text">To Date</label>
                </div>
                <input
                  type="date"
                  value={moment(endDate).format("YYYY-MM-DD")}
                  onChange={e => handleOnChange(startDate, e.target.value)}
                  className="form-control"
                />
              </div>
              {GetInstitution().code == '*' ? <div
                className="input-group input-group-sm"
                style={{ marginTop: "10px" }}
              >
                <div className="input-group-prepend" style={{ height: '30px' }}>
                  <label className="input-group-text">Institutions</label>
                </div>
                {institutionsData ?
                  <Col style={{marginLeft:'-12px'}}>
                    <ClusterDropdown type={DropdownType.MultiSelect}
                      style={{ 'height': '30px', 'width': '100%' }}
                      placeholder={"-Select an Institution-"}
                      name="InstitutionCode"
                      items={MapToDropDown(institutionsData)}
                      defaultValue={institutionsData?.filter(data => data.Name != 'Impersonation')?.filter(data => institutionCode.split(',').includes(data.ID))}
                      onChange={(name, value) => {
                        setInstitutionCode(value)
                      }}
                    />  </Col> : <Col className="pl-4"><Loading /></Col>}

              </div> : <></>}

              <ClusterButton
                onHandleClick={() => handleSearch()}
                className="btn-sm"
                color="primary"
                type="submit"
              >
                Search
              </ClusterButton>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  handleOnSearch: PropTypes.func,
  endDateStr: PropTypes.string,
  startDateStr: PropTypes.string,
  active: PropTypes.string
}

export default withDispose(Search)
