import React, { useState, useEffect, useCallback } from "react"
import { Row, Container, Col, Card, CardBody, CardTitle } from "reactstrap"

import withDispose from "components/HOCs/WithDispose"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import HighlightTab from "./highlight"
import Search from "../search"
import { GetInstitution } from "helpers/authentication/institution"
import { useDashboardView } from "hooks/useDashboardView"
import { ActiveRates } from "./rates/activeRates"
import { GET_WALLET_BALANCE, GET_INSTITUTION_SERVICE_CONFIGURATION, GET_WALLET_ACCOUNT_STATEMENT_REPORT } from "helpers/url_helper"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { Utils } from "helpers/utility"
import { WithdrawalStages } from "enums/withdrawal-stages"
import { toast } from "react-toastify"
import WalletBalanceCard from "pages/SettlementManagement/wallet/wallet-balance-card"
import ConfirmOTPModal from "pages/SettlementManagement/wallet/confirm-otp"
import { ClusterButton } from "components/Common/Button/Button"
import PaymentDetailsModal from "pages/SettlementManagement/wallet/payment-details"
import { withdrawSettlement, sendWithdrawalOTP, settlementPurge, getWalletBalance, getSettlementAccount, getWalletAccountStatementReport } from "store/actions"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"


const PerformanceHighlights = props => {
  const { trxType, 
    onSendOTP, 
    totalWalletTopUps,
    onSettlementWithdrawal, 
    walletAccountStatement, 
    onGetWalletAccountReport,
    editLoading, 
    onGetAccounts,
    accounts,
    success,
    error
  } = props
  const { data } = useDashboardView();
  const ActiveAgentRateElement = withDispose(ActiveRates);
  const ActiveInstitutionRateElement = withDispose(ActiveRates)
  let [startDate, setStartDateStr] = useState()
  let [endDate, setEndDateStr] = useState()
  const [code, setCode] = useState(GetInstitution().code)

  const [showPaymentDetailsModal, setShowPaymentDetailsModal] = useState(false)
  const [showWithdrawModal, setShowWithdrawModal] = useState(false)
  const [withdrawalDetails, setWithdrawalDetails] = useState<any>({})
  const [instServiceConfig, setInstServiceConfig] = useState<any>() 

  const response = useSWR(GET_WALLET_BALANCE, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: true
  })

  const resp = useSWR(
    code !== '*' ? `${GET_INSTITUTION_SERVICE_CONFIGURATION}/${code}` : null,
    fetcher,
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    }
  )

  const [balance, setBalance] = useState({
    wallet: '--',
    pendingSettlement: '--',
    totalWalletTopUp: '--'
  })

  const getTotalWalletBal = (startDate, endDate) => {
    onGetWalletAccountReport(JSON.stringify({ "FromDate": startDate, "ToDate": endDate, "StartIndex": 0, "MaxSize": 10 }))
  }

  useEffect(() => {
    getTotalWalletBal(startDate,endDate)
  }, [startDate, endDate])

  useEffect(() => {
    onGetAccounts()
  }, [])

  useEffect(() => {
    const balanceData = (response.data as any)?.Data
    setBalance({
      ...balance, 
      totalWalletTopUp: totalWalletTopUps == null ? "--" : Utils.Format.currency(totalWalletTopUps),
      pendingSettlement: balanceData?.PendingBalance == null ? "--" : Utils.Format.currency(balanceData?.PendingBalance),
      wallet: balanceData?.WithdrawableBalance == null ? "--" : Utils.Format.currency(balanceData?.WithdrawableBalance)
    })
  }, [response.data, totalWalletTopUps])

  useEffect(() => {
    const serviceConfigData = (resp.data as any)?.Data
    setInstServiceConfig(serviceConfigData)
  }, [resp.data])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])

  const next = (type: WithdrawalStages, formValues?: any, resetForm?: any) => {
    switch (type) {
      case WithdrawalStages.PaymentDetails:
        setShowWithdrawModal(false)
        setShowPaymentDetailsModal(!showPaymentDetailsModal)
        break;
      case WithdrawalStages.ConfirmOTP:
        onSendOTP(formValues)
        setWithdrawalDetails({ ...withdrawalDetails, PaymentDetails: formValues })
        setShowPaymentDetailsModal(false)
        setShowWithdrawModal(!showWithdrawModal);
        break;
      case WithdrawalStages.Withdraw:
        setWithdrawalDetails({ ...withdrawalDetails, ...formValues })


        const withdrawal = {
          Amount: withdrawalDetails.PaymentDetails?.Amount,
          OTP: formValues?.OTP,
          CreditAccountID: withdrawalDetails.PaymentDetails?.CreditAccountID
        }
        onSettlementWithdrawal(withdrawal)
        break;

      default:
        setShowPaymentDetailsModal(false)
        setShowWithdrawModal(false);
        break;
    }
  }

  const resendOTP = () => {
    onSendOTP({ ...withdrawalDetails.PaymentDetails })
  }

  const getDailyWithdrawalCount = useCallback((): number => {
    return (walletAccountStatement ?? []).filter(withdrawal => Utils.Date.isToday(withdrawal.DateUpdated) && withdrawal.Status == 'Successful').length;
  }, [walletAccountStatement])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid style={{ position: "relative" }}>
          <Breadcrumbs
            title={"Transaction Summary"}
            breadcrumbItem={"Performance Highlights"}
            noCrumb={true}
          />
          <Search
            startDateStr={startDate}
            endDateStr={endDate}
            setStartDateStr={setStartDateStr}
            setEndDateStr={setEndDateStr}
            setCode={setCode}
            code={code}
          ></Search>

          {instServiceConfig?.CashOutSettlement?.SettlementMode == "Wallet" && 
          <Row className="mb-3">
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.wallet} next={next} isWalletBalance={true} cardTitle="Wallet Balance" />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.pendingSettlement} next={next} isWalletBalance={false} cardTitle="Pending Settlement" />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <WalletBalanceCard balance={balance.totalWalletTopUp} next={next} isWalletBalance={false} cardTitle="Total Wallet Top Up" shouldShowReportBtn={true}/>
            </Col>
          </Row>}

          <Row>
            {data.map((types, key) => (
              <Col lg="3" key={"highlight_" + key}>
                <HighlightTab
                  startDateStr={startDate}
                  endDateStr={endDate}
                  trxType={types}
                  color={types.color}
                  code={code == '*' ? '' : code}
                ></HighlightTab>
              </Col>
            ))}
            <Col key={`TRXTYPE_ACTIVEAGENT`} lg={3} md={6} sm={6}>
              <ActiveAgentRateElement
                startDateStr={startDate}
                endDateStr={endDate}
                field='combinedActiveAgentsTrend'
                title='Agents'
                code={code == '*' ? '' : code} />
            </Col>
            {GetInstitution().code == '*' &&
              <Col key={`TRXTYPE_ACTIVEINSTITUTIONS`} lg={3} md={6} sm={6}>
                <ActiveInstitutionRateElement
                  startDateStr={startDate}
                  endDateStr={endDate}
                  field="activeInstitutionsTrend"
                  title='Institutions'
                  code={code == '*' ? '' : code}
                />
              </Col>}

          </Row>
        </Container>
      </div>

      {showPaymentDetailsModal ? (
        <PaymentDetailsModal balance={(response.data as any)?.Data?.WithdrawableBalance ?? 0} withdrawalCount={getDailyWithdrawalCount()} details={withdrawalDetails?.PaymentDetails} handleValidSubmit={(values) => next(WithdrawalStages.ConfirmOTP, values)} accounts={(accounts ?? []).map(account => ({ ID: account.ID, BankCode: account.BankCode, BankName: account.BankName, AccountNumber: account.AccountNumber, Name: account.AccountName, key: account.ID }))} toggleOpen={setShowPaymentDetailsModal} isOpen={showPaymentDetailsModal} />
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {showWithdrawModal ? (
        <ConfirmOTPModal resendOTP={resendOTP} isSubmitting={editLoading} handleValidSubmit={(values) => next(WithdrawalStages.Withdraw, values)} toggleOpen={setShowWithdrawModal} isOpen={showWithdrawModal} >
          <ClusterButton type={"button"} color={"secondary"} onHandleClick={() => next(WithdrawalStages.PaymentDetails)}>
            Back
          </ClusterButton>
        </ConfirmOTPModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}

    </React.Fragment>
  )
}

const mapStateToProps = ({
  settlements: { walletAccountStatementReport, account, balance, error, success, totalCount, otpSending, loading, statusChanged, editLoading, totalWalletTopUps },
}) => {
  return {
    walletAccountStatement: [...walletAccountStatementReport],
    error,
    accounts: [account],
    balance,
    totalCount,
    success,
    loading, statusChanged, editLoading,
    otpSending,
    totalWalletTopUps
  }
}

const mapDispatchToProps = dispatch => ({
  onGetWalletAccountReport: query => dispatch(getWalletAccountStatementReport(query)),
  onSendOTP: otp => dispatch(sendWithdrawalOTP(otp)),
  onSettlementWithdrawal: withdrawal => dispatch(withdrawSettlement(withdrawal)),
  onPurge: () => dispatch(settlementPurge()),
  onGetAccounts: () => dispatch(getSettlementAccount()),
  onGetBalance: () => dispatch(getWalletBalance()),
})

PerformanceHighlights.propTypes = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withDispose(PerformanceHighlights)))

