import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_STATES } from "./actionTypes"
import { getStatesSuccess, getStatesFail } from "./actions"

//Include Both Helper File with needed methods
import { getStates } from "helpers/backend_helper"

function* fetchStates() {
  try {
    const response = yield call(getStates)
    yield put(getStatesSuccess(response))
  } catch (error) {
    console.debug("Fetching States Error", error)
    yield put(getStatesFail(error))
  }
}
function* miscSaga() {
  yield takeEvery(GET_STATES, fetchStates)
}

export default miscSaga
