/* SETTLEMENTS */
export const GET_SETTLEMENT_WITHDRAWALS:any = "GET_SETTLEMENT_WITHDRAWALS"
export const GET_SETTLEMENT_WITHDRAWALS_SUCCESS = "GET_SETTLEMENT_WITHDRAWALS_SUCCESS"
export const GET_SETTLEMENT_WITHDRAWALS_FAIL = "GET_SETTLEMENT_WITHDRAWALS_FAIL"


export const GET_SETTLEMENT_ACCOUNTS:any = "GET_SETTLEMENT_ACCOUNTS"
export const GET_SETTLEMENT_ACCOUNTS_SUCCESS = "GET_SETTLEMENT_ACCOUNTS_SUCCESS"
export const GET_SETTLEMENT_ACCOUNTS_FAIL = "GET_SETTLEMENT_ACCOUNTS_FAIL"

export const SETTLEMENT_WITHDRAWAL:any= "SETTLEMENT_WITHDRAWAL";
export const SETTLEMENT_WITHDRAWAL_SUCCESS = "SETTLEMENT_WITHDRAWAL_SUCCESS"
export const SETTLEMENT_WITHDRAWAL_FAIL = "SETTLEMENT_WITHDRAWAL_FAIL"

export const GET_WALLET_ACCOUNT_STATEMENT_REPORT: any =
  "GET_WALLET_ACCOUNT_STATEMENT_REPORT"
export const GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS =
  "GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS"
export const GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL =
  "GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL"

export const SETTLEMENT_WALLET_BALANCE= "SETTLEMENT_WALLET_BALANCE";
export const SETTLEMENT_WALLET_BALANCE_SUCCESS = "SETTLEMENT_WALLET_BALANCE_SUCCESS"
export const SETTLEMENT_WALLET_BALANCE_FAIL = "SETTLEMENT_WALLET_BALANCE_FAIL"

export const SETTLEMENT_PURGE = "SETTLEMENT_PURGE"
export const SETTLEMENT_PURGE_SUCCESS = "SETTLEMENT_PURGE_SUCCESS"


export const SEND_WITHDRAWAL_OTP:any= "SEND_WITHDRAWAL_OTP";
export const SEND_WITHDRAWAL_OTP_SUCCESS= "SEND_WITHDRAWAL_OTP_SUCCESS";
export const SEND_WITHDRAWAL_OTP_FAIL= "SEND_WITHDRAWAL_OTP_FAIL";


export const ADD_SETTLEMENT_ACCOUNT:any= "ADD_SETTLEMENT_ACCOUNT";
export const ADD_SETTLEMENT_ACCOUNT_SUCCESS= "ADD_SETTLEMENT_ACCOUNT_SUCCESS";
export const ADD_SETTLEMENT_ACCOUNT_FAIL= "ADD_SETTLEMENT_ACCOUNT_FAIL";


export const ACTIVATE_SETTLEMENT_ACCOUNT:any= "ACTIVATE_SETTLEMENT_ACCOUNT";
export const ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS= "ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS";
export const ACTIVATE_SETTLEMENT_ACCOUNT_FAIL= "ACTIVATE_SETTLEMENT_ACCOUNT_FAIL";



export const CONFIGURE_OTP_RECEIVER:any= "CONFIGURE_OTP_RECEIVER";
export const CONFIGURE_OTP_RECEIVER_SUCCESS= "CONFIGURE_OTP_RECEIVER_SUCCESS";
export const CONFIGURE_OTP_RECEIVER_FAIL= "CONFIGURE_OTP_RECEIVER_FAIL";


export const GET_WALLET_SETTLEMENT_CONFIGURATION:any= "GET_WALLET_SETTLEMENT_CONFIGURATION";
export const GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS= "GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS";
export const GET_WALLET_SETTLEMENT_CONFIGURATION_FAIL= "GET_WALLET_SETTLEMENT_CONFIGURATION_FAIL";


export const WALLET_SETTLEMENT_CONFIGURATION:any= "WALLET_SETTLEMENT_CONFIGURATION";
export const WALLET_SETTLEMENT_CONFIGURATION_SUCCESS= "WALLET_SETTLEMENT_CONFIGURATION_SUCCESS";
export const WALLET_SETTLEMENT_CONFIGURATION_FAIL= "WALLET_SETTLEMENT_CONFIGURATION_FAIL";

export const GET_WALLET_TOP_UP_REPORT:any = "GET_WALLET_TOP_UP_REPORT"
export const GET_WALLET_TOP_UP_REPORT_SUCCESS = "GET_WALLET_TOP_UP_REPORT_SUCCESS"
export const GET_WALLET_TOP_UP_REPORT_FAIL = "GET_WALLET_TOP_UP_REPORT_FAIL"

export const EXPORT_WALLET_TOP_UP_REPORT:any = "EXPORT_WALLET_TOP_UP_REPORT"
export const EXPORT_WALLET_TOP_UP_REPORT_SUCCESS = "EXPORT_WALLET_TOP_UP_REPORT_SUCCESS"
export const EXPORT_WALLET_TOP_UP_REPORT_FAIL = "EXPORT_WALLET_TOP_UP_REPORT_FAIL"
