import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import ReactMapGL, { Marker, Popup } from "react-map-gl"
// import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"
import { connect } from "react-redux"
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import Loading from "../Loading"
import { divide, map } from "lodash"
import markerImg from "../../../assets/images/users/user-x.svg"
import googleMapStyles from "./map-style"
import withDispose from "components/HOCs/WithDispose"
import "./map.scss"
const LoadingContainer = () => <Loading></Loading>

const ClusterMap = props => {
  let { markers, children } = props

  const [activeMarker, setActiveMarker] = useState({})
  const [selectedPlace, setSelectedPlace] = useState<any>()
  const [showingInfoWindow, setShowingInfoWindow] = useState(true)
  const [viewport, setViewPort] = useState({
    width: "100%",
    height: "100vh",
    zoom: 10,
    latitude: markers[0]?.location?.lat || 0,
    longitude: markers[0]?.location?.lng || 0,
  })

  useEffect(() => {
    if (markers.length > 0) {
      setViewPort({
        ...viewport,
        latitude: markers[0]?.location?.lat,
        longitude: markers[0]?.location?.lng,
      })
    }
  }, [markers])
  const onMarkerClick = (props, marker) => {
    setActiveMarker(marker)
    setSelectedPlace(props)
    setShowingInfoWindow(true)
  }

  const onClick = (props, marker) => {
    setActiveMarker(marker)
    setSelectedPlace(props)
    setShowingInfoWindow(true)
  }

  const onInfoWindowClose = () => {
    setActiveMarker(null)
    setShowingInfoWindow(false)
  }

  const onMapClicked = () => {
    if (showingInfoWindow) {
      setActiveMarker(null)
      setShowingInfoWindow(false)
    }
  }
  return (
    <div>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={
          "pk.eyJ1IjoidG9iZW9jdCIsImEiOiJja3ExdDlpanEwaHl0MndwOGk2dnJuZXBzIn0.EMaJ4M70Wwd_-XAVO0ddVA"
        }
        onViewportChange={viewport => setViewPort(viewport)}
        mapStyle="mapbox://styles/tobeoct/ckq1vb5vt47oh17o57p4cgy5a"
      >
        {markers
          ? markers.map((m, key) => {
              return (
                <Marker
                  key={"marker_" + key}
                  latitude={m.location.lat}
                  longitude={m.location.lng}
                >
                  <div
                    className="marker pulse"
                    onClick={e => {
                      e.preventDefault()
                      setSelectedPlace(m)
                    }}
                  >
                    {/* <img src={markerImg}/> */}
                    <div className="img"></div>
                  </div>
                </Marker>
              )
            })
          : null}

        {selectedPlace ? (
          <Popup
            onClose={e => {
              setSelectedPlace("")
            }}
            longitude={selectedPlace.location.lng}
            latitude={selectedPlace.location.lat}
          >
            {selectedPlace.children}
          </Popup>
        ) : null}
      </ReactMapGL>
    </div>
  )
  // return (
  //       <div
  //       id="gmaps-markers"
  //       className="gmaps"
  //       style={{ position: "relative", height:"110vh"}}
  //       >
  //       <Map
  //           google={props.google}
  //           style={{ width: "100%", height: "100%" }}
  //           zoom={15}
  //           onClick={(event)=>onMapClicked(event)}

  //       >
  //         {markers? markers.map((m,key)=>{
  //          return <Marker
  //          key={"marker_"+key}
  //           title={m.title}
  //           name={m.name}
  //           id="marker"
  //           position={m.location}
  //           icon={{ url: markerImg, scaledSize: new google.maps.Size(64, 64) }}
  //           onClick={(props,e)=>onMarkerClick(props,e)}
  //           animation={window.google.ma080ps.Animation.DROP}
  //           >

  //           </Marker>

  //         }):<React.Fragment></React.Fragment> }
  //         <InfoWindow
  //         marker={activeMarker}
  //         onClose={()=>onInfoWindowClose()}
  //         visible={showingInfoWindow}
  //       >
  //         <div>
  //           <h4>{selectedPlace.name}</h4>
  //         </div>
  //       </InfoWindow>

  //       </Map>
  //       </div>
  // )
}

// ClusterMap.defaultProps = googleMapStyles;
// ClusterMap.propTypes = {
//     google: PropTypes.object
//   }

// export default connect(
//   null,
//   {}
// )(
//   GoogleApiWrapper({
//     apiKey: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
//     LoadingContainer: LoadingContainer,
//     v: "3",
//   })(ClusterMap)
// )

export default withDispose(ClusterMap)
// const ClusterMarker = props => {
//   return (
//     <div>
//       <div className="pin bounce" title={name} />
//     </div>
//   )
// }
