export const GET_USERS = "GET_USERS"
export const GET_ALL_USERS="GET_ALL_USERS"
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS"
export const API_ERROR = "USERS_API_ERROR"

export const CREATE_USER = "CREATE_USER"
export const POST_SUCCESS = "POST_SUCCESS"
export const POST_USER_ERROR = "POST_USER_ERROR"

export const ENABLE_USER = "ENABLE_USER"
export const ENABLE_USER_SUCCESS = "ENABLE_USER_SUCCESS"

export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_SUCCESS = "GETUSER_DETAILS_SUCCESS"
export const USER_DETAILS_ERROR = "USER_DETAILS_ERROR"
export const GET_ACTIVEUSER = "GET_ACTIVE_USER"

export const UPDATE_USER = "UPDATE_USER"
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS"
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL"

export const PURGE_USERS = "PURGE_USERS"
export const PURGE_USERS_SUCCESS = "PURGE_USERS_SUCCESS"
