
const ClusterStorage = {
    getItem: (key) => {

        const item = localStorage.getItem(key);
        if (!item) return null;
        return JSON.parse(item);
    },
    contains: (key) => !(localStorage.getItem(key)),
    setItem: (key, value) => {
        if (!value) return;
        localStorage.setItem(key, JSON.stringify(value));
    },
    removeItem: (key) => localStorage.removeItem(key),
    clear: () => localStorage.clear()
}

export default ClusterStorage;