import { defaultObj } from "helpers/utility"
import {
  GET_AGENT_DETAIL,
  GET_AGENT_DETAIL_SUCCESS,
  GET_AGENT_DETAIL_FAIL,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAIL,
  AGENT_PURGE,
  ENABLE_AGENT,
  ENABLE_AGENT_SUCCESS,
  AGENT_PURGE_SUCCESS,
  GET_AGENTS,
  POST_AGENT_SUCCESS,
  UPDATE_AGENT,
  CREATE_AGENT,
  UPDATE_AGENT_SUCCESS,
  POST_AGENT_FAIL,
  GET_ALL_AGENTS,
  DEVICE_RESET,
  DEVICE_RESET_FAIL,
  DEVICE_RESET_SUCCESS,
  ACCOUNT_VALIDATION,
  ACCOUNT_VALIDATION_FAIL,
  ACCOUNT_VALIDATION_SUCCESS,
  RESET_LIMIT,
  RESET_LIMIT_FAIL,
  RESET_LIMIT_SUCCESS,
  UPGRADE_TO_SUPERAGENT,
  UPGRADE_TO_SUPERAGENT_FAIL,
  UPGRADE_TO_SUPERAGENT_SUCCESS,
  LINK_TO_POSTERMINAL,
  LINK_TO_POSTERMINAL_FAIL,
  LINK_TO_POSTERMINAL_SUCCESS,
  UPDATE_AGENT_FAIL,
  GET_AGENT_LOANS,
  GET_AGENT_LOANS_SUCCESS,
  UNLINK_FROM_SUPERAGENT,
  UNLINK_FROM_SUPERAGENT_FAIL,
  UNLINK_FROM_SUPERAGENT_SUCCESS,
  GENERATE_POS_ACCOUNTS,
  GENERATE_POS_ACCOUNTS_FAILURE,
  GENERATE_POS_ACCOUNTS_SUCCESS,
  UPDATE_POS_ACCOUNTS,
  UPDATE_POS_ACCOUNTS_SUCCESS,
  UPDATE_POS_ACCOUNTS_FAILURE,
} from "./actionTypes"

const INIT_STATE = {
  agents: [],
  agentLoans: [],
  agentDetail: {},
  accountDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  modalLoading : false,
  editLoading: false,
  deviceResetLoading: false,
  accountValidationLoading: false,
  resetLimitLoading: false,
  unlinkParentLoading: false,
  upgradeLoading: false,
  linkLoading: false,
  statusChanged: undefined,
  createPOSAccountLoading : false,
  updatePOSAccountLoading : false
}

const Agents = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }

    case GET_AGENTS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_AGENT_LOANS:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: undefined,
      }
    case GET_AGENT_LOANS_SUCCESS:
      return {
        ...state,
        agentLoans: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_ALL_AGENTS:
      return {
        ...state,
        loading: true,
      }
    case GET_AGENTS_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        loading: false,
      }

    case GET_AGENT_DETAIL: 
    return {
      ...state,
      modalLoading : true,
    }

    case GET_AGENT_DETAIL_SUCCESS:
      return {
        ...state,
        agentDetail: action.payload?.Data,
        modalLoading: false,
        loading : false
      }

    case GET_AGENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload?.Message,
        modalLoading: false,
        loading : false,
        success: "",
      }
    case POST_AGENT_SUCCESS:
      return {
        ...state,
        agent: defaultObj(state.agent), //action.payload,
        error: undefined,
        success: action.payload?.Message,
        editLoading: false,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
      }
    case UPDATE_AGENT:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
      break
    case CREATE_AGENT:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
        loading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
      }

    case POST_AGENT_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break

    case UPDATE_AGENT_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_AGENT:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
      break
    case ENABLE_AGENT_SUCCESS:
      let detail = state.agentDetail
      detail.IsActive = action.payload?.enable
      let agents = state.agents
      if (detail) {
        agents.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        agents,
        editLoading: false,
        loading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        agentDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case AGENT_PURGE_SUCCESS:
      return INIT_STATE
    case DEVICE_RESET:
      return {
        ...state,
        deviceResetLoading: true,
        error: undefined,
        success: undefined,
      }
    case DEVICE_RESET_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break
    case DEVICE_RESET_SUCCESS:
      return {
        ...state,
        deviceResetLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    case ACCOUNT_VALIDATION:
      return {
        ...state,
        accountValidationLoading: true,
        error: undefined,
        success: undefined,
      }
    case ACCOUNT_VALIDATION_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        accountValidationLoading: false,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break
    case ACCOUNT_VALIDATION_SUCCESS:
      return state
      let accountDetail = action.payload?.Data
      let agentDetail = state.agentDetail
      if (accountDetail) {
        agentDetail.Email = accountDetail.Email
        agentDetail.LastName = accountDetail.LastName
        agentDetail.OtherNames = accountDetail.FirstName
        agentDetail.BankVerificationNumber =
          accountDetail.BankVerificationNumber
        agentDetail.PhoneNumber = accountDetail.PhoneNo
        agentDetail.DateOfBirth = accountDetail.DateOfBirth
        agentDetail.Gender = accountDetail.Gender
      }
      return {
        ...state,
        accountValidationLoading: false,
        agentDetail: agentDetail,
        accountDetail: accountDetail,
        loading: false,
        error: "",
        success: action.payload?.Message,
      }
    case RESET_LIMIT:
      return {
        ...state,
        resetLimitLoading: true,
        error: undefined,
        success: undefined,
      }
    case RESET_LIMIT_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break
    case RESET_LIMIT_SUCCESS:
      return {
        ...state,
        resetLimitLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    case UPGRADE_TO_SUPERAGENT:
      return {
        ...state,
        upgradeLoading: true,
        error: undefined,
        success: undefined,
      }
    case UPGRADE_TO_SUPERAGENT_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break
    case UPGRADE_TO_SUPERAGENT_SUCCESS:
      let sa = state.agentDetail
      let sas = state.agents
      let sp = action.payload.payload
      sa.IsSuperAgent = true
      sas.map(s => {
        if (s.ID == sp.id) s.IsSuperAgent = true
        return s
      })

      return {
        ...state,
        agents: sas,
        agentDetail: sa,
        upgradeLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    case LINK_TO_POSTERMINAL:
      return {
        ...state,
        linkLoading: true,
        error: undefined,
        success: undefined,
      }
    case LINK_TO_POSTERMINAL_FAIL:
      state = {
        ...state,
        error: action.payload.Message,
        loading: false,
        editLoading: false,
        resetLimitLoading: false,
        deviceResetLoading: false,
        linkLoading: false,
        upgradeLoading: false,
        success: "",
      }
      break
    case LINK_TO_POSTERMINAL_SUCCESS:
      let a = state.agentDetail
      let as = state.agents
      let p = action.payload.payload
      if (!p.link) {
        a.TerminalID = null
        as.map(s => {
          if (s.ID == p.id) s.TerminalID = null
          return s
        })
      } else {
        a.TerminalID = p.terminalID
        as.map(s => {
          if (s.ID == p.id) s.TerminalID = p.terminalID
          return s
        })
      }

      return {
        ...state,
        agents: as,
        agentDetail: a,
        linkLoading: false,
        loading: false,
        error: "",
        statusChanged: true,
        success: action.payload?.Message,
      }
    case UNLINK_FROM_SUPERAGENT:
      return {
        ...state,
        linkLoading: false,
        unlinkParentLoading: true,
        error: undefined,
        loading: true,
      }
    case UNLINK_FROM_SUPERAGENT_FAIL:
      return {
        ...state,
        unlinkParentLoading: true,
        error: action.payload,
        success: null,
        loading: false,
      }
    case UNLINK_FROM_SUPERAGENT_SUCCESS:
      return {
        ...state,
        unlinkParentLoading: true,
        error: null,
        success: action.payload,
        loading: false,
      }
    case GENERATE_POS_ACCOUNTS:
      return {
        ...state,
        createPOSAccountLoading : true,
        error : null,
        success : null
      }
    case GENERATE_POS_ACCOUNTS_FAILURE:
      return {
        ...state,
        createPOSAccountLoading : false,
        error : action.payload,
        success : null
      }
    case GENERATE_POS_ACCOUNTS_SUCCESS:
      return {
        ...state,
        createPOSAccountLoading : false,
        success : action.payload,
        error : null
      }
    default:
      return state
  }
  return state
}

export default Agents
