import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Container
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"


//i18n
import { withTranslation } from "react-i18next"
import { purgeTerminals, createTerminal, getAgentDetail, getAllUsers, getTerminalDetail, getUsers, updateTerminal } from "store/actions"
import TerminalsForm from './form';
import TerminalLogic from "../terminal";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { CREATE_TERMINAL, EDIT_TERMINAL } from '../permission'
import useSWR from 'swr'
import { fetcher } from 'helpers/api_helper'
const CreateTerminal = props => {
  const { match, onCreateTerminal,onUpdateTerminal,onGetUsers,users, onGetTerminalDetail, success, editLoading, loading, error, terminals, terminalDetail } = props
  const { ERRORLIST } = TerminalLogic;
  const [terminalDetails, setTerminalDetails] = useState({});
  const [errorList, setErrorList] = useState(ERRORLIST);
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [terminal, setTerminal] = useState({}); //data from form
  // const [error, setError] = useState();
  useEffect(() => {
    getTerminal()
    onGetUsers()

  }, [])

  useEffect(() => {
    getTerminal()
  }, [match])


  const handleOnChange = (key, value) => {

    let u = terminal;
    u[key] = value;
    setTerminal(u);
  }
  const handleValidSubmit = (terminal) => {
    if (isUpdate==false) {
      onCreateTerminal(terminal)
    } else {
      let terminalToUpdate =
          { ...terminalDetail,
             ...terminal,
            ID : terminalDetail?.ID,  
        };
   
      onUpdateTerminal(terminalToUpdate);
    }
  }

  const getTerminal = () => {
    const id = match.params.id.toString();
    if (id && id !== "0") {
      setIsUpdate(true)
      let u = terminals.find(c => c.TerminalID == id);
      if (!u) { 
        if (Object.keys(terminalDetail).length == 0) 
          { onGetTerminalDetail(id); } 
          else 
             { setTerminalDetails(terminalDetail) }

            return }
      setTerminalDetails(u)
    } else {
      setIsUpdate(false)
    }
  }


  return (
    <GuardedPage permissions={[EDIT_TERMINAL,CREATE_TERMINAL]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Terminal"}
              breadcrumbItem={isUpdate?"Update Terminal":"Create Terminal"}
            />

            <TerminalsForm
              redirectUrl={"/view-terminals"}
              success={success}
              error={error}
              isUpdate={isUpdate}
              loading={loading}
              users={users}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              details={terminalDetail}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
            ></TerminalsForm>

          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>)
}



CreateTerminal.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateTerminal: PropTypes.func,
  onGetTerminalDetail: PropTypes.func,
  onBack: PropTypes.func,
  terminalDetail: PropTypes.object,
  terminals: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({Users:{users}, terminals: { terminals, error, success, terminalDetail, editLoading, totalCount, loading } }) => {
  return {
    terminals: [...terminals],
    error,
    success,
    terminalDetail,
    totalCount,
    users,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateTerminal: (terminal) => dispatch(createTerminal(terminal)),
  onGetTerminalDetail: (id) => dispatch(getTerminalDetail(id)),
  onUpdateTerminal:(terminal)=> dispatch(updateTerminal(terminal)),
  onPurge:()=>dispatch(purgeTerminals()),
  onGetUsers:()=>dispatch(getAllUsers())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateTerminal))))








