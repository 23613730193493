import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import { createNetworkManager, getAgentDetail, getAllRoles, getAllUsers, getNetworkManagerDetail, getRoles, getStates, getUserDetail, getUsers, updateNetworkManager } from "store/actions"
import NetworkManagerForm from './form';
import NetworkManagerLogic from "../network-manager";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { CREATE_NETWORK_MANAGER, EDIT_NETWORK_MANAGER } from '../permission'
import useSWR from 'swr'
import { GET_ALL_CLUSTERS } from 'helpers/url_helper'
import { fetcher } from 'helpers/api_helper'
const CreateANM = props => {
  const { match, onGetRoles, onGetUser, onGetStates, states, statesLoading, onGetUsers, user, users, roles, roleLoading, onCreateNetworkManager, onUpdateNetworkManager, loading, success, onGetNetworkManagerDetail, editLoading, error, networkManagers, networkManagerDetail } = props
  const { ERRORLIST, NOUSER_ERRORLIST } = NetworkManagerLogic;
  const [networkManagerDetails, setNetworkManagerDetails] = useState();
  const [errorList, setErrorList] = useState(ERRORLIST);
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [networkManager, setNetworkManager] = useState({}); //data from form
  const clusters = useSWR(GET_ALL_CLUSTERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })

  const errorTracker = useMemo(() => {
    const list = !user?.UserID || user?.UserID == 0 ? NOUSER_ERRORLIST : ERRORLIST
    setErrorList(list);
    return list;
  }, [user?.UserID])

  useEffect(() => {
    getNetworkManager()
    onGetRoles()
    onGetStates()
  }, [])

  useEffect(() => {
    getNetworkManager()
  }, [match])
  const handleOnChange = (key, value) => {
    let u = networkManager
    u[key] = value
    setNetworkManager(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()

    if (!isUpdate) {
      onCreateNetworkManager(networkManager)
    } else {
      let anmToUpdate = {
        ...networkManagerDetail,
         ...networkManager,
      }
      
      if (networkManager['ClusterIDs'][0] !== 0){
      anmToUpdate["ClusterIDs"] = networkManager['ClusterIDs']
      }
      else{
       anmToUpdate['ClusterIDs'] = []
      }
      
      onUpdateNetworkManager(anmToUpdate)
    }
  }

  const getNetworkManager = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = networkManagers.find(c => c.ID == id)
      if (!u) {
        if (Object.keys(networkManagerDetail).length == 0) {
          onGetNetworkManagerDetail(id)
        } else {
          setNetworkManagerDetails(networkManagerDetail)
        }
        return
      }
      setNetworkManagerDetails(u)
    } else {
      setIsUpdate(false)
    }
  }

  return (
    <GuardedPage permissions={[EDIT_NETWORK_MANAGER, CREATE_NETWORK_MANAGER]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Network Managers"}
              image={clusterian}
              breadcrumbItem={"Create Network Manager"}
            />

            <NetworkManagerForm
              redirectUrl={"/view-anms"}
              success={success}
              error={error}
              roles={roles}
              roleLoading={roleLoading}
              isUpdate={isUpdate}
              loading={loading}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              details={networkManagerDetail}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              onGetUser={onGetUser}
              onGetUsers={onGetUsers}
              users={users}
              user={user}
              handleValidSubmit={handleValidSubmit}
              states={states}
              statesLoading={statesLoading}
              clusters={(clusters.data as any)?.Data}
            ></NetworkManagerForm>
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateANM.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateNetworkManager: PropTypes.func,
  onGetNetworkManagerDetail: PropTypes.func,
  onBack: PropTypes.func,
  networkManagerDetail: PropTypes.object,
  networkManagers: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  Users: { users, user }, Roles, networkManagers: { networkManagers, error, success, networkManagerDetail, totalCount, editLoading, loading },
  misc: { states, statesLoading } }) => {
  return {
    networkManagers: [...networkManagers],
    error,
    networkManagerDetail,
    totalCount,
    loading,
    roles: Roles.roles,
    roleLoading: Roles.loading,
    success,
    users,
    user,
    editLoading,
    states, statesLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateNetworkManager: (networkManager) => dispatch(createNetworkManager(networkManager)),
  onGetNetworkManagerDetail: (id) => dispatch(getNetworkManagerDetail(id)),
  onUpdateNetworkManager: (details) => dispatch(updateNetworkManager(details)),
  onGetRoles: () => dispatch(getAllRoles()),
  onGetUser: (id) => dispatch(getUserDetail(id)),
  onGetStates: () => dispatch(getStates()),
  onGetUsers: () => dispatch(getAllUsers())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateANM))))
