import Loading from "components/Common/Loading";
import ClusterInput from "components/Common/Input/Input"
import ClusterForm from "components/Common/Form"
import { title } from "process";
import React, { useRef, useState } from "react";
import { ModalHeader, Badge, ModalBody, Row, Col, Card, Modal, CardBody, ModalFooter, FormGroup, Label, Alert } from "reactstrap";
import { Formik } from "formik";
import ClusterButton from "components/Common/Button/Button";
import ClusterDropdown from "components/Common/DropDown/Dropdown";
import { Validators } from "helpers/validators";
import { Symbols } from "constants/symbols";
import { Format } from "helpers/variables";
let reset;
const PaymentDetailsModal = (props) => {
    const { isOpen, toggleOpen, handleValidSubmit, balance, withdrawalCount, accounts, details } = props;
    const [fee, setFee] = useState(50);
    const [minimumAmount, setMinimumAmount] = useState(100)
    const validate = (values) => {
        let errors: any = {}
        const required = Validators.required("Please enter a value");

        const amount = () => [
            required,
            Validators.min(minimumAmount, `Minimum withdrawable amount is ${Symbols.NAIRA}${minimumAmount}`),
            Validators.max(balance, `Maximum withdrawable amount is ${Symbols.NAIRA}${balance}`)
        ].forEach(validator => {
            let error = validator(values?.Amount)
            if (error) errors.Amount = error;
        });

        const account = () => [
            required,
            Validators.min(1, `Please select an account`)
        ].forEach(validator => {
            let error = validator(values?.CreditAccountID)
            if (error) errors.CreditAccountID = error;
        });



        const validate = () => {
            try {
                account();
                amount();
                return errors;
            } catch (err) {
                console.log(err)
            }
        }
        return validate();
    }

    return <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        toggle={() => {
            toggleOpen(!isOpen)
        }}
    >
        <div className="modal-content">
            <ModalHeader
                toggle={() => {
                    toggleOpen(!isOpen)
                }}
            >
                Payment Details

            </ModalHeader>

            <React.Fragment>
                <ModalBody>
                    <Formik
                        initialValues={{ Amount: details?.Amount, CreditAccountID: details?.CreditAccountID }}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            handleValidSubmit(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isValid,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            resetForm,
                            isSubmitting,
                        }) => (
                            <ClusterForm handleSubmit={handleSubmit}>
                                {values.Amount >= fee ?
                                    <Card
                                        color={values.Amount <= balance ? "light-green" : "light-orange"}
                                        style={{ paddingTop: "0px", paddingBottom: "0px", position: "relative" }}
                                    >
                                        <CardBody
                                            style={{
                                                paddingTop: "10px",
                                                paddingBottom: "5px",
                                                position: "relative",
                                            }}
                                        >
                                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <div>
                                                    <small>Your settlement account would receive</small>
                                                    <h1>{Format.currency(values.Amount - fee)}</h1>
                                                </div>
                                                <small><b>Transaction Fee is {Format.currency(fee)}</b></small>
                                            </div>
                                        </CardBody>
                                    </Card> : null}
                                <Row>
                                    <Col
                                        className="animate__animated animate__fadeInUp"
                                        lg={12}
                                        md={12}
                                        sm={12}
                                    >
                                        <ClusterInput
                                            defaultValue={values.Amount}
                                            label={"Amount"}
                                            id={"amount"}
                                            name={"Amount"}
                                            type="number"
                                            required={true}
                                            // disabled={withdrawalCount >= 1}
                                            value={values.Amount}
                                            validationError={errors.Amount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></ClusterInput>
                                    </Col>

                                    <Col lg={12} md={12} sm={12}>
                                        <FormGroup>
                                            <Label>Account</Label>

                                            <ClusterDropdown
                                                id={"creditAccountID"}
                                                name={"CreditAccountID"}
                                                // disabled={withdrawalCount >= 1}
                                                defaultValue={
                                                    !accounts.find(a => a.ID == values?.CreditAccountID) ? "" : `${accounts.find(a => a.ID == values?.CreditAccountID)?.BankName} - ${accounts.find(a => a.ID == values?.CreditAccountID)?.AccountNumber} - ${accounts.find(a => a.ID == values?.CreditAccountID)?.Name}`
                                                }
                                                placeholder={"-Select-"}
                                                value={accounts.find(a => a.ID == values?.CreditAccountID)?.AccountName}
                                                items={accounts.map(account => ({ id: account.ID, value: account.ID, name: `${account.BankName} - ${account.AccountNumber} - ${account.Name}`, key: account.ID }))}
                                                onChange={(name, value) => {
                                                    setFieldValue("CreditAccountID", value)
                                                }}
                                            ></ClusterDropdown>

                                        </FormGroup>
                                    </Col>
                                </Row>

                                <ClusterButton
                                    loading={isSubmitting}
                                    disabled={touched ? !isValid : true}
                                    color={"primary"}
                                    addon={"bx bx-send"}
                                >
                                    Send Token
                                </ClusterButton>
                            </ClusterForm>)}
                    </Formik>
                </ModalBody>
                <ModalFooter className="animate__animated animate__fadeInUp">
                    {props.children}
                </ModalFooter>
            </React.Fragment>

        </div>
    </Modal>

}

export default PaymentDetailsModal;