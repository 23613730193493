import { IntegrationType } from "enums/integration-type"
import { UserCategory } from "enums/user-category"
import { compare } from "helpers/utility"
import authHeader from "./auth-token-header"

export const GetInstitution = () => {
  let authUser = authHeader().AuthUser ?? {
    institutionId: 1,
    institutionName: "TestMFB",
    institutionCode: "000000",
  }
  return {
    id: authUser.institutionId,
    remoteInstitutionId: RemoteInstitution(),
    name: authUser.institutionName,
    code: authUser.institutionCode,
  }
}
export const GetDefaultMenu = (menus) => {
  const sortedMenus = menus.filter(m => m.parent).sort(compare("parent"));

  const defaultMenu = sortedMenus[0];
  if (!defaultMenu) return "/";
  const hasDashboardMenu = sortedMenus.filter(menu => menu.parent.includes("Dashboard")).length > 0;
  return hasDashboardMenu ? "/" : defaultMenu.children[0].url
}
export const RemoteInstitution = () => {
  return +(process.env.REACT_APP_IMPERSONATION_ID ?? "1")
}

export const IsTestInstitution = () => {
  return GetInstitution().code === (process.env.REACT_APP_TEST_MFB ?? "000000")
}

export class AuthUser {
  user: any
  InstitutionID: any
  InstitutionName: any
  InstitutionCode: any
  constructor() {
    this.user = authHeader().AuthUser
    this.InstitutionID = this.user.institutionId
    this.InstitutionName = this.user.institutionName
    this.InstitutionCode = this.user.institutionCode
  }
}

export class Institution {

  static IsRemote=()=>{
    return authHeader().AuthUser?.userCategory == UserCategory.ServiceProvider
  }


  static getID = () => {
    return authHeader().AuthUser?.institutionId;
  }
  static getIsRemote = () => {
    return authHeader().AuthUser?.userCategory == UserCategory.ServiceProvider;
  }
  static ID = Institution.getID();
  static Name = authHeader().AuthUser?.institutionName
  static Code = authHeader().AuthUser?.institutionCode
  static RemoteInstitutionID = (+(process.env.REACT_APP_IMPERSONATION_ID ?? "1"))
  static IsTest = this.Code === (process.env.REACT_APP_TEST_MFB ?? "000000")
  static IntegrationType: IntegrationType
}
