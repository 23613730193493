import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"
export type DisposeProps= {
  onPurge?:any
}
function withDispose<T extends DisposeProps>(BaseComponent:React.ComponentType<T>){
  return (props) => {
    const [didMount, setDidMount] = useState(false)
    const { onPurge, children, ...otherProps } = props
    useEffect(() => {
      setDidMount(true)
      return () => {
        if (onPurge) {
          onPurge()
        }
        setDidMount(false)
      }
    }, [])
  
    if (!didMount) {
      return null
    }
    return <BaseComponent onPurge={onPurge}  {...otherProps as T}>{children}</BaseComponent>
  }
}

withDispose.propTypes = {
  onPurge: PropTypes.func,
  children: PropTypes.bool,
  
}
export default withDispose
