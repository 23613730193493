import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_AGENTS,
  GET_AGENT_DETAIL,
  AGENT_PURGE,
  ENABLE_AGENT,
  CREATE_AGENT,
  UPDATE_AGENT,
  GET_ALL_AGENTS,
  DEVICE_RESET,
  RESET_LIMIT,
  UPGRADE_TO_SUPERAGENT,
  LINK_TO_POSTERMINAL,
  ACCOUNT_VALIDATION,
  GET_AGENT_LOANS,
  UNLINK_FROM_SUPERAGENT,
  GENERATE_POS_ACCOUNTS,
  UPDATE_POS_ACCOUNTS,
} from "./actionTypes"
import {
  getAgentsSuccess,
  getAgentsFail,
  getAgentDetailSuccess,
  getAgentDetailFail,
  agentPurgeSuccess,
  updateAgentSuccess,
  postAgentSuccess,
  agentApiError,
  deviceResetSuccess,
  deviceResetError,
  resetLimitSuccess,
  resetLimitError,
  upgradeToSuperAgentSuccess,
  upgradeToSuperAgentError,
  linkToPOSTerminalSuccess,
  linkToPOSTerminalError,
  unlinkParentAgentSuccess,
  unlinkParentAgentFail,
  createPOSAccountsSuccess,
  createPOSAccountsFailure,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAgents,
  getAgentDetail,
  putAgent,
  postAgent,
  enableAgent,
  disableAgent,
  getAllAgents,
  upgradeToSuperAgent,
  resetLimit,
  deviceReset,
  terminalActivation,
  terminalDeactivation,
  accountValidation,
  getAgentLoans,
  putUnlinkParentAgent,
  generatePOSAccounts,
} from "helpers/backend_helper"
import {
  accountValidationError,
  accountValidationSuccess,
  enableAgentSuccess,
  getAgentLoansSuccess,
} from "store/actions"
import { toast } from "react-toastify"
const format = "yyyy-MM-DD"
function* fetchAgents({ params }) {
  try {
    const response = yield call(getAgents, JSON.parse(params))
    yield put(getAgentsSuccess(response))
  } catch (error) {
    console.debug("Fetching Agents Error", error)
    yield put(getAgentsFail(error))
  }
}
function* fetchAgentLoans({ params }) {
  try {
    const response = yield call(getAgentLoans, JSON.parse(params))
    yield put(getAgentLoansSuccess(response))
  } catch (error) {
    console.debug("Fetching Agents Error", error)
    yield put(getAgentsFail(error))
  }
}
function* fetchAllAgents() {
  try {
    const response = yield call(getAllAgents)
    yield put(getAgentsSuccess(response))
  } catch (error) {
    console.debug("Fetching Agents Error", error)
    yield put(getAgentsFail(error))
  }
}

function* fetchAgentDetail({ id }) {
  try {
    const response = yield call(getAgentDetail, id)
    yield put(getAgentDetailSuccess(response))
  } catch (error) {
    yield put(getAgentDetailFail(error))
  }
}

function* doAgentAccountValidation({ payload: { accountNumber } }) {
  try {
    // const response = yield call(accountValidation, accountNumber)
    yield put(accountValidationSuccess(accountNumber))
  } catch (error) {
    console.debug(error)
    yield put(
      accountValidationError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* createAgent({ payload: { agent } }) {
  try {
    const response = yield call(postAgent, {
      ...JSON.parse(JSON.stringify(agent)),
    })
    yield put(postAgentSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      agentApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* updateAgent({ payload: { agent } }) {
  try {
    const response = yield call(putAgent, {
      ...JSON.parse(JSON.stringify(agent)),
    })

    yield put(updateAgentSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      agentApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}

function* unlinkParentAgent({ payload: { agent } }) {
  try {
    const response = yield call(putUnlinkParentAgent, {
      ...JSON.parse(JSON.stringify(agent)),
    })

    yield put(unlinkParentAgentSuccess(response))
  } catch (error) {
    console.debug("Unlink parent agent error", error)
    yield put(unlinkParentAgentFail(error))
  }
}

function* doEnableAgent(payload) {
  try {
    let toEnable = true
    let cta = enableAgent
    if (!payload.enable) {
      cta = disableAgent
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableAgentSuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      agentApiError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doPurge() {
  yield put(agentPurgeSuccess())
}
function* doDeviceReset({ payload: { id } }) {
  try {
    const response = yield call(deviceReset, id)

    yield put(deviceResetSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      deviceResetError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doResetLimit({ payload: { id } }) {
  try {
    const response = yield call(resetLimit, id)

    yield put(resetLimitSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      resetLimitError(error?.data ?? "Sorry an error occurred, Kindly retry")
    )
  }
}
function* doUpgradeToSuperAgent({ payload }) {
  try {
    const response = yield call(upgradeToSuperAgent, payload)

    yield put(upgradeToSuperAgentSuccess({ ...response, payload }))
  } catch (error) {
    console.debug(error)
    yield put(
      upgradeToSuperAgentError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doLinkToPOSTerminal({ payload }) {
  try {
    let action = terminalActivation
    if (!payload.link) action = terminalDeactivation
    const response = yield call(action, payload)

    yield put(linkToPOSTerminalSuccess({ ...response, payload }))
  } catch (error) {
    console.debug(error)
    yield put(
      linkToPOSTerminalError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doCreatePOSAccounts({payload, id}){
  try {
    const response = yield call(generatePOSAccounts, payload, id)
    yield put(createPOSAccountsSuccess(response))
    toast.success(response.Message)
  }
  catch (error){
    yield put(createPOSAccountsFailure(error?.data ?? "Sorry an error occurred, Kindly retry"))
    toast.error(error?.data ?? "Sorry an error occurred, Kindly retry")
  }
}

function* agentsSaga() {
  yield takeLeading(GET_AGENTS, fetchAgents)
  yield takeLeading(GET_ALL_AGENTS, fetchAllAgents)
  yield takeLeading(GET_AGENT_LOANS, fetchAgentLoans)
  yield takeEvery(GET_AGENT_DETAIL, fetchAgentDetail)
  yield takeEvery(AGENT_PURGE, doPurge)
  yield takeLeading(ENABLE_AGENT, doEnableAgent)
  yield takeLeading(UPDATE_AGENT, updateAgent)
  yield takeLeading(CREATE_AGENT, createAgent)
  yield takeLeading(DEVICE_RESET, doDeviceReset)
  yield takeLeading(RESET_LIMIT, doResetLimit)
  yield takeLeading(UPGRADE_TO_SUPERAGENT, doUpgradeToSuperAgent)
  yield takeLeading(ACCOUNT_VALIDATION, doAgentAccountValidation)
  yield takeLeading(LINK_TO_POSTERMINAL, doLinkToPOSTerminal)
  yield takeLeading(UNLINK_FROM_SUPERAGENT, unlinkParentAgent)
  yield takeLeading(GENERATE_POS_ACCOUNTS, doCreatePOSAccounts)
}

export default agentsSaga
