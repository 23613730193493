import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLAN_DETAIL,
  GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
  GET_SUBSCRIPTION_PLAN_DETAIL_FAIL,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL,
  SAVE_SUBSCRIPTION_PLAN,
  POST_SUBSCRIPTION_PLAN_SUCCESS,
  SUBSCRIPTION_PLAN_PURGE,
  SUBSCRIPTION_PLAN_PURGE_SUCCESS,
  POST_SUBSCRIPTION_PLAN_FAIL,
  GET_ALL_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_BY_CATEGORY,
  ADD_SUBSCRIPTION_PLAN_OPTION,
  REMOVE_SUBSCRIPTION_PLAN_OPTION,
  ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
  REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN,
  ENABLE_SUBSCRIPTION_PLAN,
  ENABLE_SUBSCRIPTION_PLAN_SUCCESS,
  GET_SUBSCRIPTIONS,
  GET_ALL_SUBSCRIPTIONS,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL
} from "./actionTypes"

export const getSubscriptionPlans = params => ({
  type: GET_SUBSCRIPTION_PLANS,
  params,
})
export const getAllSubscriptionPlans = () => ({
  type: GET_ALL_SUBSCRIPTION_PLANS,
})
export const getSubscriptions = params => ({
  type: GET_SUBSCRIPTIONS,
  params,
})
export const getAllSubscriptions = () => ({
  type: GET_ALL_SUBSCRIPTIONS,
})
export const getSubscriptionsSuccess = subscriptionPlans => ({
  type: GET_SUBSCRIPTIONS_SUCCESS,
  payload: subscriptionPlans,
})
export const getSubscriptionsFail = error => ({
  type: GET_SUBSCRIPTIONS_FAIL,
  payload: error,
})
export const getSubscriptionPlansByCategory = categoryId => ({
  type: GET_SUBSCRIPTION_PLANS_BY_CATEGORY,
  categoryId,
})
export const getSubscriptionPlansSuccess = subscriptionPlans => ({
  type: GET_SUBSCRIPTION_PLANS_SUCCESS,
  payload: subscriptionPlans,
})
export const getSubscriptionPlansFail = error => ({
  type: GET_SUBSCRIPTION_PLANS_FAIL,
  payload: error,
})
export const getSubscriptionPlanDetail = id => ({
  type: GET_SUBSCRIPTION_PLAN_DETAIL,
  id,
})

export const getSubscriptionPlanDetailSuccess = subscriptionPlans => ({
  type: GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
  payload: subscriptionPlans,
})

export const getSubscriptionPlanDetailFail = error => ({
  type: GET_SUBSCRIPTION_PLAN_DETAIL_FAIL,
  payload: error,
})

export const saveSubscriptionPlans = subscriptionPlan => {
  return {
    type: SAVE_SUBSCRIPTION_PLAN,
    payload: { subscriptionPlan },
  }
}

export const addSubscriptionPlanOption = subscriptionPlanOption => {
  return {
    type: ADD_SUBSCRIPTION_PLAN_OPTION,
    payload: subscriptionPlanOption,
  }
}
export const addSubscriptionOptionSuccess = subscriptionPlanOption => ({
  type: ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
  payload: subscriptionPlanOption,
})
export const removeSubscriptionOption = subscriptionPlanOption => {
  return {
    type: REMOVE_SUBSCRIPTION_PLAN_OPTION,
    payload: subscriptionPlanOption,
  }
}
export const removeSubscriptionOptionSuccess = subscriptionPlanOption => ({
  type: REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
  payload: subscriptionPlanOption,
})
export const subscriptionPlanApiError = error => {
  return {
    type: POST_SUBSCRIPTION_PLAN_FAIL,
    payload: error,
  }
}

export const postSubscriptionSuccess = subscriptionPlan => {
  return {
    type: POST_SUBSCRIPTION_PLAN_SUCCESS,
    payload: subscriptionPlan,
  }
}

export const subscriptionPlanPurge = () => ({
  type: SUBSCRIPTION_PLAN_PURGE,
})

export const subscriptionPlanPurgeSuccess = () => ({
  type: SUBSCRIPTION_PLAN_PURGE_SUCCESS,
})

export const enableSubscription = (id, enable) => {
  return {
    type: ENABLE_SUBSCRIPTION_PLAN,
    id,
    enable,
  }
}

export const enableSubscriptionSuccess = subscriptionPlan => {
  return {
    type: ENABLE_SUBSCRIPTION_PLAN_SUCCESS,
    payload: subscriptionPlan,
  }
}

export const updateSubscription = subscriptionPlan => {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN,
    payload: { subscriptionPlan },
  }
}
export const updateSubscriptionSuccess = subscriptionPlan => {
  return {
    type: UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
    payload: subscriptionPlan,
  }
}
export const purgeSubscriptions = () => {
  return {
    type: PURGE_SUBSCRIPTION_PLANS,
  }
}

export const purgeSubscriptionSuccess = () => {
  return {
    type: PURGE_SUBSCRIPTION_PLANS_SUCCESS,
  }
}
