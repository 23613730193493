import { defaultObj } from "helpers/utility"
import {
  GET_NETWORKMANAGER_DETAIL_SUCCESS,
  GET_NETWORKMANAGER_DETAIL_FAIL,
  GET_NETWORKMANAGERS_SUCCESS,
  GET_NETWORKMANAGERS_FAIL,
  NETWORKMANAGER_PURGE,
  ENABLE_NETWORKMANAGER,
  ENABLE_NETWORKMANAGER_SUCCESS,
  NETWORKMANAGER_PURGE_SUCCESS,
  GET_NETWORKMANAGERS,
  POST_NETWORKMANAGER_SUCCESS,
  UPDATE_NETWORKMANAGER,
  CREATE_NETWORKMANAGER,
  UPDATE_NETWORKMANAGER_SUCCESS,
  POST_NETWORKMANAGER_FAIL,
  GET_ALL_NETWORKMANAGERS,
} from "./actionTypes"

const INIT_STATE = {
  networkManagers: [],
  networkManagerDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const AgentNetworkManagers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_NETWORKMANAGERS_SUCCESS:
      return {
        ...state,
        networkManagers: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_NETWORKMANAGERS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_NETWORKMANAGERS:
      return {
        ...state,
        loading: true,
      }
    case GET_NETWORKMANAGERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_NETWORKMANAGER_DETAIL_SUCCESS:
      return {
        ...state,
        networkManagerDetail: action.payload?.Data,
        loading: false,
      }

    case GET_NETWORKMANAGER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_NETWORKMANAGER_SUCCESS:
      return {
        ...state,
        networkManager: defaultObj(state.networkManager), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_NETWORKMANAGER:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case CREATE_NETWORKMANAGER:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case POST_NETWORKMANAGER_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case UPDATE_NETWORKMANAGER_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_NETWORKMANAGER:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case ENABLE_NETWORKMANAGER_SUCCESS:
      let detail = state.networkManagerDetail
      detail.IsActive = action.payload?.enable
      let networkManagers = state.networkManagers
      if (detail) {
        networkManagers.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          }
          return c
        })
      }
      return {
        ...state,
        networkManagers,
        editLoading: false,
        loading: false,
        networkManagerDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case NETWORKMANAGER_PURGE_SUCCESS:
      return INIT_STATE
    // return {
    //   ...state,
    //   accountEnquiry: {},
    //   networkManagerDetail:{},
    //   error:{},
    //   success:undefined
    // }

    default:
      return state
  }
  return state
}

export default AgentNetworkManagers
