import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import { addAssignedTerminal } from "store/actions"
import TerminalAssignmentServiceProviderForm from "./form"
import TerminalAssignmentServiceProviderLogic from "../../terminal-assignment-serviceprovider"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
import { GET_ALL_AFFILIATES, GET_UNASSIGNED_TERMINALS } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
const AssignTerminal = props => {
  const {
    onAddAssignedTerminal,
    success,
    editLoading,
    loading,
    error,
  } = props
  const { ERRORLIST } = TerminalAssignmentServiceProviderLogic
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [isRotating, setIsRotating] = useState(false)
  const [terminal, setTerminal] = useState({}) //data from form

  const handleOnChange = (key, value) => {
    let u = terminal
    u[key] = value
    setTerminal(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()
    onAddAssignedTerminal({ ...terminal })
  }
  const affiliates = useSWR(GET_ALL_AFFILIATES, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const unassignedTerminals = useSWR(`${GET_UNASSIGNED_TERMINALS}/true`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs
          title={"Terminal Assignment"}
          breadcrumbItem={"Assign"}
         
        />

     
        <TerminalAssignmentServiceProviderForm
          affiliates={affiliates?.data?.Data}
          terminals={unassignedTerminals?.data?.Data}
          success={success}
          error={error}
          loading={loading}
          errorList={errorList}
          editLoading={editLoading}
          setErrorList={setErrorList}
          handleOnChange={handleOnChange}
          handleValidSubmit={handleValidSubmit}
        ></TerminalAssignmentServiceProviderForm>
      </Container>
    </React.Fragment>
  )
}

AssignTerminal.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onAddAssignedTerminal: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onBack: PropTypes.func,
  agentDetail: PropTypes.object,
  terminals: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  terminals: {
    assignedTerminals,
    error,
    success,
    agentDetail,
    editLoading,
    totalCount,
    loading,
  }
}) => {
  return {
    assignedTerminals,
    error,
    success,
    agentDetail: agentDetail,
    totalCount,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onAddAssignedTerminal: terminal =>
    dispatch(addAssignedTerminal(terminal)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withDispose(AssignTerminal)))
