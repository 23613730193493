/* DIRECT DEBIT */
export const POST_DEBIT_REQUESTS: any = "POST_DEBIT_REQUESTS"
export const POST_DEBIT_REQUESTS_SUCCESS = "POST_DEBIT_REQUESTS_SUCCESS"
export const POST_DEBIT_REQUESTS_FAIL = "POST_DEBIT_REQUESTS_FAIL"
export const PATCH_DEBIT_REQUESTS_APPROVAL: any =
  "PATCH_DEBIT_REQUESTS_APPROVAL"
export const PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS =
  "PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS"
export const PATCH_DEBIT_REQUESTS_APPROVAL_FAIL =
  "PATCH_DEBIT_REQUESTS_APPROVAL_FAIL"
export const GET_DEBIT_REQUESTS: any = "GET_DEBIT_REQUESTS"
export const GET_DEBIT_REQUESTS_DETAIL: any = "GET_DEBIT_REQUESTS_DETAIL"
export const GET_DEBIT_REQUESTS_SUCCESS = "GET_DEBIT_REQUESTS_SUCCESS"
export const GET_DEBIT_REQUESTS_FAIL = "GET_DEBIT_REQUESTS_FAIL"
export const GET_DEBIT_REQUESTS_DETAIL_SUCCESS =
  "GET_DEBIT_REQUESTS_DETAIL_SUCCESS"
export const GET_DEBIT_REQUESTS_DETAIL_FAIL = "GET_DEBIT_REQUESTS_DETAIL_FAIL"
