import React, { useState, useEffect } from 'react'
import { Col, FormGroup, Label, Row, Modal, ModalBody } from 'reactstrap'
import moment from 'moment'
import { Formik } from 'formik'
import { DisposeProps } from 'components/HOCs/WithDispose'
import { SEARCHOPTIONS } from 'helpers/variables'
import ClusterButton from 'components/Common/Button/Button'
import { Utils } from 'helpers/utility'
import Loading from 'components/Common/Loading'
import ClusterDropdown from 'components/Common/DropDown/Dropdown'
import ClusterCheckBox from "../CheckBox"
import ClusterInput from "components/Common/Input/Input"

const SearchSectionWithModal: React.FC<DisposeProps & any> = props => {
    let { title, handleOnSearch, options, rowClass } = props
    if (options == undefined) options = SEARCHOPTIONS
    const [myOptions, setOptions] = useState(options)
    const [fields, setFields] = useState(options?.fields)
    const [myRange, setRange] = useState(options?.range)
    const [dateSearchEnabled, setSearchEnabled] = useState<Boolean>(true)
    const [fromDate, setFromDate] = useState(myRange?.from)
    const [toDate, setToDate] = useState(myRange?.to)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [defaultFromDate, setDefaultFromDate] = useState<string>()
    const [defaultToDate, setDefaultToDate] = useState<string>()
    const [searchFromDate, setSearchFromDate] = useState(fromDate)
    const [searchToDate, setSearchToDate] = useState(toDate)

    useEffect(() => {
        if (props.options) {
            setOptions(props.options)
            setFields(props.options.fields)
            setRange(props.options.range)
            setSearchEnabled(props.options.range.enabled)
        }
    }, [props])

    useEffect(() => {
        if (props?.options?.range?.enabled) {
            setDefaultFromDate(moment(props.options.range.from).format("YYYY-MM-DD"))
            setDefaultToDate(moment(props.options.range.to).format("YYYY-MM-DD"))
        }
    }, [])

    useEffect(() => {
        if(searchFromDate || searchToDate){
        search({fromDate:searchFromDate,toDate:searchToDate})
        }
    }, [searchFromDate, searchToDate])


    const search = criteria => {
        if (fields.find(field => field.name == "dateRequested") && !criteria["dateRequested"]) {
            criteria["dateRequested"] = fields.find(field => field.name == "dateRequested").defaultValue;
        }
        criteria["page"] = 1
        return handleOnSearch(criteria)
    }
    const reset = (resetForm: any) => {
        let opts = Object.assign({}, SEARCHOPTIONS)
        opts.fields.map(field => {
            switch (field.type) {
                case "dropdown":
                    break
                default:
                    field.defaultValue = ""
                    break
            }
            return field
        })
        let searchCriteria = {}
        fields.forEach(field => {
            delete searchCriteria[field.name];
        })
        setFields([])
        searchCriteria = { ...searchCriteria, fromDate: defaultFromDate, toDate: defaultToDate }
        setFromDate(moment(defaultFromDate).format("YYYY-MM-DD"))
        setToDate(moment(defaultToDate).format("YYYY-MM-DD"))
        search(searchCriteria);
        resetForm()
    }

    return (
        <>
            <Formik
                initialValues={{ fromDate: moment(fromDate).format("YYYY-MM-DD"), toDate: moment(toDate).format("YYYY-MM-DD") }}
                validate={() => { }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => setSubmitting(false), 30000);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    isValid,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    resetForm,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Row className='pb-2'>
                            {dateSearchEnabled ? (
                                <React.Fragment>
                                    <Col lg={2} sm={3}>
                                        <FormGroup>

                                            <ClusterInput
                                                label={'From Date'}
                                                type="date"
                                                value={moment(values.fromDate).format("YYYY-MM-DD")}
                                                name={"fromDate"}
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    setFieldValue(
                                                        "fromDate",
                                                        moment(e.target.value)
                                                            .startOf("day")
                                                            .format("YYYY-MM-DDTHH:mm:ss")
                                                    )
                                                    setSearchFromDate(moment(e.target.value).format("YYYY-MM-DD"))
                                                }} />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={2} sm={3}>
                                        <FormGroup>
                                            <ClusterInput 
                                                label='To Date'
                                                type="date"
                                                value={moment(values.toDate).format("YYYY-MM-DD")}
                                                id="toDate"
                                                name="toDate"
                                                onBlur={handleBlur}
                                                onChange={e => {
                                                    setFieldValue(
                                                        "toDate",
                                                        moment(e.target.value)
                                                            .endOf("day")
                                                            .format("YYYY-MM-DDTHH:mm:ss")
                                                    )
                                                    setSearchToDate(moment(e.target.value).format("YYYY-MM-DD"))
                                                }}
                                            />
                                            </FormGroup>
                                    </Col>
                                </React.Fragment>
                            ) : (
                                <React.Fragment></React.Fragment>
                            )}

                            <Col lg={2} sm={3} className='mb-3'>
                              <Label></Label>
                                    <ClusterButton
                                        size={'md'}
                                        className="mb-4"
                                        addon={'bx bx-filter'}
                                        color='secondary'
                                        onHandleClick={() => setShowFilterModal(true)}
                                        outline
                                    >
                                        Filters
                                    </ClusterButton>
                            </Col>
                        </Row>

                        {showFilterModal &&
                            <Modal
                                isOpen={showFilterModal}
                                role="dialog"
                                autoFocus={true}
                                centered={true}
                                className="exampleModal"
                                toggle={() => {
                                    setShowFilterModal(false)
                                }}
                            >

                                <ModalBody>
                                    <Col md={12} sm={12} lg={12} className='pt-2' >
                                        {!Utils.List.isEmpty(fields) && fields.slice(0, fields.length - 1).map((field, key) =>
                                        (<SearchField
                                            key={`search_field_${key}`}
                                            type={field.type}
                                            name={field.name}
                                            id={field.id}
                                            placeholder={field.placeholder}
                                            label={field.label}
                                            options={field.data}
                                            defaultValue={field.data?.find(value => value.key == values[field.name])?.name ?? values[field.name]}
                                            width={12}
                                            validators={field.validators}
                                            handleChange={setFieldValue}
                                            value={values[field.name]}
                                        />))}

                                        {fields.slice(-1).map((field, key) =>
                                            field.label == 'All Institutions' ?
                                                field.isRemote &&
                                                (<SearchField
                                                    key={`search_field_${key}`}
                                                    type={field.type}
                                                    name={field.name}
                                                    id={field.id}
                                                    placeholder={field.placeholder}
                                                    label={field.label}
                                                    options={field.data}
                                                    defaultValue={field.data?.find(value => value.key == values[field.name])?.name ?? values[field.name]}
                                                    width={12}
                                                    validators={field.validators}
                                                    handleChange={setFieldValue}
                                                    value={values[field.name]}
                                                    isRemote={field.isRemote}
                                                />) : <SearchField
                                                    key={`search_field_${key}`}
                                                    type={field.type}
                                                    name={field.name}
                                                    id={field.id}
                                                    placeholder={field.placeholder}
                                                    label={field.label}
                                                    options={field.data}
                                                    defaultValue={field.data?.find(value => value.key == values[field.name])?.name ?? values[field.name]}
                                                    width={12}
                                                    validators={field.validators}
                                                    handleChange={setFieldValue}
                                                    value={values[field.name]} />)}
                                    </Col>
                                      
                                        {myOptions.actions.enabled ? (
                                        <Row style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '10px', alignContent: 'space-between' }}>
                                                <Col lg={3} md={3} sm={3} className=''>
                                                    <ClusterButton
                                                        className="btn btn-primary waves-effect waves-light mb-2 animate__animated animate__fadeInUp"
                                                        onHandleClick={() => {
                                                            search(values)
                                                            setShowFilterModal(false)
                                                        }}
                                                        disabled={!isValid}
                                                        type={"submit"}
                                                        addon={'bx bx-search'}
                                                        addonType={'prepend'}
                                                    >
                                                        Apply
                                                    </ClusterButton>
                                                </Col>
                                                <Col lg={3} md={3} sm={3}>
                                                    <ClusterButton
                                                        type={"button"}
                                                        className="btn btn-dark waves-effect waves-light mb-2 animate__animated animate__fadeInUp"
                                                        onHandleClick={e => {
                                                            e.preventDefault()
                                                            reset(resetForm)
                                                            setShowFilterModal(false)
                                                        }}
                                                        addon={'bx bx-reset'}
                                                        addonType={'prepend'}
                                                    >
                                                        Reset
                                                    </ClusterButton>
                                                </Col>

                                                </Row>
                                        ) : (
                                           <></>
                                        )}
                                      
                                    

                                </ModalBody>
                            </Modal>}

                    </form>)}
            </Formik>
        </>
    )
}

export default SearchSectionWithModal

const SearchField = (props) => {
    const { name, value, type, width, id, label, options, handleChange, placeholder, defaultValue, validators, isRemote } = props;
    switch (type) {
        case "dropdown":
            return (
                <Col lg={width || 1} md={12}>
                    <FormGroup>
                        {label != undefined ? (
                            <Label for={id}>{label}</Label>
                        ) : (
                            <React.Fragment></React.Fragment>
                        )}
                        {!options ? (
                            <Loading></Loading>
                        ) : (
                            <ClusterDropdown
                                name={name}
                                placeholder={placeholder}
                                items={options}
                                 defaultValue={defaultValue}
                                onChange={(key, value) => handleChange(name, value)}
                            ></ClusterDropdown>
                        )}
                    </FormGroup>
                </Col>
            )
        case "checkbox":
            return (
                <Col
                    style={{ display: "flex", alignItems: "center" }}
                    lg={width || 1}
                    md={3}
                >
                    <FormGroup style={{ display: "flex", alignItems: "center" }}>
                        <ClusterCheckBox
                            onHandleChange={(name, value) => handleChange(name, value)}
                            label={label}
                            name={name}
                            id={id}
                            checked={!defaultValue ? false : true}
                        ></ClusterCheckBox>
                    </FormGroup>
                </Col>
            )
        default:
            return (
                <Col lg={width || 2} md={12} >

                    <ClusterInput
                        defaultValue={defaultValue}
                        onError={err => {
                        }}
                        label={label}
                        id={id}
                        name={name}
                        value={value}
                        validators={validators}
                        type={type}
                        onHandleChange={handleChange}
                        isRemote={false}
                    />
                </Col>
            )
    }
}
