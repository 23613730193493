import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
import { Utils } from "helpers/utility"
import { GetInstitution } from "helpers/authentication/institution"

const HEADERS = [
    { title: "Date", name: "date", entityKeyName: "DateCreated" },
    {title : "Transaction Amount", name : "transactionAmount",
     entityKeyName : "TransactionAmount"},
     {title : "Amount Settled To Wallet", name : "amountSettledToWallet",
     entityKeyName : "AmountSettledToWallet"},
     {title : "Agent Name", name : "agentName",
     entityKeyName : "AgentName"},
     {title : "Transaction Type", name : "transactionType",
     entityKeyName : "TransactionType"},
     {title : "Transaction Reference", name : "rrn",
     entityKeyName : "RRN"},
]

export enum TransactionType {
  POSAgentCashOut = "POSAgentCashOut",
  CardTransfer = "CardTransfer"
}

const SearchOptions = (startDate, endDate, institutions) => {
    let options = Object.assign({}, SEARCHOPTIONS)
    options.range.enabled = true
    options.range.from = moment(startDate).format("YYYY-MM-DD")
    options.range.to = moment(endDate).format("YYYY-MM-DD")
    options.fields = [
      {
        type: "text",
        label: "Agent Name",
        id: "s-agentName",
        defaultValue: "",
        name: "agentName",
      },
      {
        type: "text",
        label: "Transaction Reference",
        id: "s-rrn",
        defaultValue: "",
        name: "rrn",
      },
      {
        type: "dropdown",
        label: "Transaction Type",
        id: "s-transactionType",
        defaultValue: "- Select -",
        name: "Type",
        data: Utils.Enums.toOptions(TransactionType),
        width: 2,
      },
      {
        type: "dropdown",
        label: "All Institutions",
        id: "institutionCode",
        defaultValue: "- Select -",
        data: MapToDropDown(institutions),
        name: "institutionCode",
        width: 2,
        isRemote: GetInstitution().code == "*",
      },
    ]
    return options
  }

  const MapToRows = transactions => {
    return {
      date: Format.date(transactions.DateCreated),
      institution: transactions.InstitutionName,
      transactionAmount: Format.currency(transactions.TransactionAmount),
      amountSettledToWallet: Format.currency(
        transactions.AmountSettledToWallet
      ),
      agentName: transactions.AgentName,
      transactionType: transactions.TransactionType,
      rrn: transactions.RRN,
    }
  }

  const MapToDropDown = data => {
    if (data instanceof Array) {
      return data.map(item => ({
        id: item.ID,
        key: item.ID,
        value: item.Name,
        name: item.Name,
      }))
    }
    return []
  }

  const SearchCriteria = (params, maxSize) => {
    return JSON.stringify({
      Type: params["Type"],
      AgentName: params["agentName"],
      InstitutionCode:
        params["institutionCode"] != "*"
          ? params["institutionCode"]
          : "",
      RRN: params["rrn"],
      FromDate: moment(params["fromDate"])
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      ToDate: moment(params["toDate"])
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      StartIndex: (params["page"] - 1) * maxSize,
      MaxSize: maxSize,
    })
  }

  export default {
    HEADERS, SearchOptions, MapToDropDown, MapToRows, SearchCriteria }
