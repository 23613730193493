import { SEARCHOPTIONS, Format } from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  { title: "Account Number", name: "accountNumber", entityKeyName: "AccountNumber" },
  { title: "Requested Amount", name: "amount", entityKeyName: "Amount" },
  { title: "Qualified Amount", name: "qualifiedAmount", entityKeyName: "EligibleAmount" },
  { title: "Fee", name: "fee", entityKeyName: "Fee" },
  { title: "Disbursement Date", name: "disbursementDate", entityKeyName: "LastUpdateTime" },
  { title: "Repayment Date", name: "repaymentDate", entityKeyName: "RepaymentDate" }

]

const SearchOptions = (startDate, endDate) => {

  let options = Object.assign({}, SEARCHOPTIONS);
  options.range.enabled = true;
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    }
  ]
  return options
}
const MapToRows = loan => {
  return {
    id: loan.ID,
    agentName: loan.AgentName,
    terminalID: loan.TerminalID,
    accountNumber: loan.AccountNumber,
    amount: Format.currency(loan.Amount),
    qualifiedAmount: Format.currency(loan.EligibleAmount),
    loanAccountNumber: loan.LoanAccountNumber,
    fee: Format.currency(loan.Fee),
    disbursementDate: loan.LastUpdateTime,
    repaymentDate: loan.RepaymentDate
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentPhoneNumber: params["agentPhoneNumber"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}

export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
}
