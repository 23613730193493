const required = errorMessage => {
  return e => {
    let value = getValue(e)
    if (value===undefined || value===null || value==="") return errorMessage
    return undefined
  }
}

const minLength = (minNumber, errorMessage) => {
  return e => {
    let value = getValue(e)
    if (value && value.length < minNumber) return errorMessage
    return undefined
  }
}

const password = () => {
  return minLength(8, "Minimum number of characters is 8");
}

const length = (number, errorMessage) => {
  return e => {
    let value = getValue(e)
    if (value && value.length != number) return errorMessage
    return undefined
  }
}
const email = errorMessage => {
  return e => {
    let value = getValue(e)
    const emailPattern = /(.+)@(.+){2,}\.(.+){2,}/
    if (!emailPattern.test(value)) {
      return errorMessage
    }
    return undefined
  }
}
const getValue = e => {
  let value
  if (!e) return e;
  if (!e.target) {
    value = e
  }
  else {
    value = e.target.value
  }
  return value
}
const phone = ([errorFeedback1, errorFeedback2]) => {
  return e => {
    let pattern = /^[0-9]+$/
    let value = getValue(e)
    if (!pattern.test(value)) return errorFeedback1
    if (value && value.length != 11) return errorFeedback2
    return undefined
  }
}
const institutionCode = ([errorFeedback1, errorFeedback2]) => {
  return e => {
    let pattern = /^[0-9]+$/
    let value = getValue(e)
    if (!value) return undefined
    if (!value.toString().startsWith("C") && !pattern.test(value)) return errorFeedback1
    if (value && value.length != 6) return errorFeedback2
    return undefined
  }
}
const accountNumber = ([errorFeedback1, errorFeedback2]) => {
  return e => {
    let pattern = /^[0-9]+$/
    let value = getValue(e)
    if (!value) return undefined
    if (!pattern.test(value)) return errorFeedback1
    const isGL = value.length ==8;
    if(isGL) return undefined;
    if (value && value.length != 10) return errorFeedback2
    return undefined
  }
}
const customAccountNumber = ([maxLength, errorFeedback1, errorFeedback2]) => {
  return e => {
    let pattern = /^[0-9]+$/
    let value = getValue(e)
    if (!value) return undefined
    if (!pattern.test(value)) return errorFeedback1
    const isGL = value.length ==8;
    if(isGL) return undefined;
    if (value && (value.length > maxLength || value.length < 10)) return errorFeedback2
    return undefined
  }
}
const maxLength = (minNumber, errorMessage) => {
  return e => {
    let value = getValue(e)
    if (value && value.length > minNumber) return errorMessage
    return undefined
  }
}

const range = (minNumber, maxNumber) => {
  return e => {
    let error = min(minNumber, `Number must be greater than ${minNumber}`)(e);
    if (error) return error;
    error = max(maxNumber, `Number must be less than ${maxNumber}`)(e);
    if (error) return error;
    return undefined;
  }
}
const min = (minNumber:number, errorMessage) => {
  return e => {
    let value = getValue(e)
    if (value < minNumber) return errorMessage
    return undefined
  }
}
const max = (maxNumber, errorMessage) => {
  return e => {
    let value = getValue(e)
    if (value > maxNumber) return errorMessage
    return undefined
  }
}

const percentage = () => {
  return e => {
    let value = getValue(e)
    if(!value) return undefined;
    if (value < 0 || value > 100) return "Percentages must be between 0 and 100"
    return numbers()(e);
  }
}
const numbers = () => {
  return e => {
    let value = getValue(e)
    let pattern = /^[0-9]+([.][0-9]+)?$/
    if (!pattern.test(value)) return "Only numbers are allowed"
    return undefined
  }
}
const amount = () => {
  return e => {
    let value = getValue(e)
    if(!value) return undefined;
    if (value < 0) return "Value must be greater than 0"
    return numbers()(e);
  }
}

const url = () => {
  return e => {
    const value = getValue(e);
    let url;
    try {
      url = new URL(value);
    } catch (_) {
      return "Invalid url. Format: https://example.com?type=example";
    }

    if (url.protocol === "http:" || url.protocol === "https:") return undefined;
    return "Invalid url";

  }
}
const validateForm = errors => {
  if (errors) {
    const result = Object.keys(errors).find(f => errors[f] != "")
    if (result && result.length > 0) {
      return errors[result]
    } else {
      return ""
    }
  }
}

const extractError = error => {
  if (typeof error === "string") {
    return error
  } else {
    if (error?.response) {
      return error?.response?.data
    } else {
      return error?.Message
    }
  }
}


export const Validators = {
  minLength,
  maxLength,
  required,
  email,
  phone,
  max,
  min,
  range,
  extractError,
  validateForm,
  institutionCode,
  accountNumber,
  customAccountNumber,
  percentage,
  amount,
  url,
  numbers,
  length,
  password

}
