import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { getFunctions } from "store/actions"

import { Row, Col, Card, CardBody, FormGroup } from "reactstrap"
import { withTranslation } from "react-i18next"
import Loading from "components/Common/Loading"
import FunctionItem from "./item"
import withDispose from "components/HOCs/WithDispose"
import NoData from "components/Common/NoData"

//Functions list is split into two components - Default & Others by scope
//Each Scope has a default list of functions that are configured from the server. This is to allow for easier groupings and activation of functions
//A function can either be a route access e.g /view-agent or an action/permission e.g ENABLEUSER
const FunctionList = props => {
  const {
    error,
    id,
    onGetFunctions,
    items,
    generalFunctionItems,
    loading,
    scope,
    type,
    onHandleSelectionUpdate,
    disabled,
  } = props
  const [myScope, setScope] = useState(scope.replace(" ",""))
  const [selectedFunctions, setSelectedFunctions] = useState([])
  const [otherFunctions, setMoreFunctions] = useState([])
  const [defaultFunctions, setDefaultFunctions] = useState([])
  const [functionsForRole, setFunctionsForRole] = useState([])
  const updateSelectedFunctions = (id, add, isParent=false) => {
    let selected = selectedFunctions
    if (isParent) {
      if (selected.find(s => s == id) && add) return
      if (!add) {
        selected.splice(selected.indexOf(id), 1)
      } else {
        selected.push(id)
      }
      setSelectedFunctions(selected)
      return
    } else {
      if (add) {
        if (!selected.find(s => s == id)) {
          selected.push(id)
          setSelectedFunctions(selected)
          if (onHandleSelectionUpdate) {
            onHandleSelectionUpdate(selected)
          }
        }
      } else {
        let found = selected.find(s => s == id)
        if (found) selected.splice(selected.indexOf(id), 1)
        if (onHandleSelectionUpdate) {
          onHandleSelectionUpdate(selected)
        }
        setSelectedFunctions(selected)
      }
    }
  }

  useEffect(() => {
    onGetFunctions()
    if (items && !type) setFunctionsForRole(items)
    if (id) {
      extractIdsFromFunctionItems()
    }
  }, [id, items])

  useEffect(() => {
    let filteredItems = !generalFunctionItems ? [] : generalFunctionItems.filter(item => {

      return item.children.some(c => (c.scopes?.includes(scope.replace(" ","")) || c.scopes === '*'))
    })

    setScope(scope.replace(" ",""))
    if (id && id > 0) {
      populateFunctions(filteredItems, true)
    } else {
      populateFunctions(!type ? filteredItems : items)
    }
  }, [generalFunctionItems, scope])

  const extractIdsFromFunctionItems = () => {
    let i = []

    items?.forEach(item => {
      if (item.id && item.id > 0) {
        i = [...i, item.id]
      }
      item.children?.forEach(child => {
        if (child.id && child.id > 0) {
          i = [...i, child.id]
        }
      })
    })

    setSelectedFunctions(i)
  }
  const populateFunctions = (theItems, isUpdate = false) => {
    let defaultItems = []
    let otherItems = []
    if (theItems && theItems.length > 0) {
      theItems
        .map(i => {
          if (!i.parent || i.parent == "") i.parent = "Others"
          return i
        })
        .forEach(f => {
          //Extract all children that have a scope equals to selected scope
          //if its all children: add all to default
          //else move the remaining children to the nameless scope but with the same parent name and add to more
          const defaultChildren = f.children?.filter(
            c => (c.scopes?.includes(scope) || c.scopes === "*")
          )

          const moreChildren = scope==="Global"? f.children?.filter(
            c => (!c.scopes?.includes(scope) && c.scopes !== "*" )
          ):[]
          let userFunc = isUpdate
            ? functionsForRole?.find(u => u.parent == f.parent)
            : undefined
          if (defaultChildren && defaultChildren.length > 0) {
            let model = {
              id: 0,
              scope: "*",
              title: "",
              canAdd: false,
              isChecked: true,
              children: [],
            }
            model.id = f.id
            model.scope = f.scope
            model.title = f.parent
            model.canAdd = true

            let dChildList = []
            defaultChildren.forEach(child => {
              let d = {
                id: child.id,
                title: child.name,
                canAdd: model.canAdd,
                isChecked: isChecked(
                  userFunc,
                  f.isDefault,
                  isUpdate,
                  child.name,
                  false
                ),
                command: child.command,
                scope: child.scopes,
              }
              updateSelectedFunctions(child.id, d.isChecked)
              if (d.isChecked) updateSelectedFunctions(f.id, true) //if any child is checked ensure to include the parent
              dChildList.push(d)
            })
            model.children = dChildList
            model.isChecked = dChildList.every(c => c.isChecked)
            defaultItems.push(model)
          }
          if (moreChildren && moreChildren.length > 0) {
            let model = {
              scope: "*",
              title: "",
              canAdd: false,
              isChecked: true,
              children: [],
              id:0
            }
            model.scope = f.scope
            model.id = f.id
            model.title = f.parent
            model.canAdd = true
            let mChildList = []

            moreChildren.forEach(child => {
              let m = {
                id: child.id,
                title: child.name,
                canAdd: true,
                isChecked: isChecked(
                  userFunc,
                  false,
                  isUpdate,
                  child.name,
                  false
                ),
                command: child.command,
                scope: child.scopes,
              }
              mChildList.push(m)
              if (id) updateSelectedFunctions(child.id, m.isChecked)
              if (m.isChecked) updateSelectedFunctions(f.id, true) //if any child is checked ensure to include the parent
            })
            model.children = mChildList
            model.isChecked = mChildList.every(c => c.isChecked)
            otherItems.push(model)
          }
        })
    }
    setDefaultFunctions([...defaultItems])
    setMoreFunctions([...otherItems])
  }

  const isChecked = (userFunc, isDefault, isUpdate, name, isParent) => {
    if (isUpdate) {
      let isChecked = false
      if (userFunc) {
        if (!isParent) {
          isChecked = userFunc.children?.find(c => c.name == name)
            ? true
            : false
          return isChecked
        } else {
          return userFunc.parent === name
        }
      }
    } else {
      if (isDefault) return type || isDefault
      if (!isDefault) return type || false
    }
  }
  return (
    <React.Fragment>
      <Row style={{ width: "100%" }}>
        <Col lg={12} md={12} xs={12} style={{ marginBottom: "20px" }}>
          <Card
            outline
            style={{ background: "transparent" }}
            color="primary"
            className="border"
          >
            <CardBody>
              <FormGroup>
                <Row>
                  <Col lg={12} md={6} xs={12}>
                    Functions for {scope === "*" ? "All" : scope}
                  </Col>
                </Row>

                <div className="mt-4 functions">
                  <ul className="accordion">
                    {loading ? (
                      <Loading></Loading>
                    ) : !defaultFunctions || defaultFunctions.length == 0 ? (
                      <NoData></NoData>
                    ) : (
                      defaultFunctions.map((item, key) => (
                        <FunctionItem
                          disabled={type ? true : false}
                          onUpdateSelection={updateSelectedFunctions}
                          key={`_functionitem_${key}`}
                          data={item}
                        ></FunctionItem>
                      ))
                    )}
                  </ul>
                </div>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
        {otherFunctions.length > 0 ? (
          <Col lg={12} md={12} xs={12}>
            <Card
              outline
              style={{ background: "transparent" }}
              color="primary"
              className="border"
            >
              <CardBody>
                <FormGroup>
                  <Row>
                    <Col lg={12}>More Functions</Col>
                  </Row>
                  <div className="mt-4 functions">
                    <ul className="accordion">
                      {loading ? (
                        <Loading></Loading>
                      ) : !otherFunctions || otherFunctions.length == 0 ? (
                        <NoData></NoData>
                      ) : (
                        otherFunctions.map((item, key) => (
                          <FunctionItem
                            disabled={type ? true : false}
                            onUpdateSelection={updateSelectedFunctions}
                            data={item}
                            key={`_function_${key}`}
                          ></FunctionItem>
                        ))
                      )}
                    </ul>
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </Col>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Row>
    </React.Fragment>
  )
}

FunctionList.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.any,
  onGetFunctions: PropTypes.func,
  generalFunctionItems: PropTypes.array,
  items: PropTypes.array,
  loading: PropTypes.bool,
  scope: PropTypes.string,
  onHandleSelectionUpdate: PropTypes.func,
  disabled: PropTypes.bool,
}

const mapStateToProps = ({
  permissions: { functions, functionsLoading, error },
}) => {
  return {
    error: error,
    generalFunctionItems: functions,
    loading: functionsLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetFunctions: () => dispatch(getFunctions()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withDispose(FunctionList)))
