import React, { useEffect, useMemo, useState } from "react"

import { Col, Collapse, FormGroup, Label, Row } from "reactstrap"
import { ClusterInput } from "components/Common/Input/Input"
import { ConfigurationFormProps } from "."
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { Utils } from "helpers/utility"
import { PayWithTransferMode } from "enums/pay-with-transfer-mode"

const PayWithTransferForm: React.FC<ConfigurationFormProps> = props => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
    } = props
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [transform, setTransform] = useState<string>();
    const [mode, setMode] = useState('')

    const details = useMemo(()=>values, [values]);
    return <React.Fragment>
        <div
            className={`title p-2 accordion-header box-shadow ${isOpen ? "active" : ""}`}
            style={{ borderRadius: "5px" }}
            onClick={() => {
                setIsOpen(!isOpen)

                if (!isOpen) {
                    setTransform("rotateZ(90deg)")
                } else {
                    setTransform("rotateZ(0deg)")
                }
            }}
        ><Row>
                <Col lg={11} md={11} xs={11}>
                    <h3>Pay With Transfer</h3>
                </Col>
                <Col
                    lg={1} md={1} xs={1}
                    className="pl-3"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <i className={`bx bxs-right-arrow text-secondary`} style={{ transform: transform }} />

                </Col>
            </Row>
        </div>
        <Collapse className="p-4" isOpen={isOpen}>
            <Row>
                <Col lg={3} md={4} sm={12}>
                    <FormGroup>
                        <Label>Pay With Transfer Mode</Label>

                        <ClusterDropdown
                            id={"Mode"}
                            name={"Mode"}
                            placeholder={"-Select-"}
                            defaultValue={details?.Mode}
                            value={details?.Mode}
                            items={Utils.Enums.toOptions(PayWithTransferMode)}
                            required
                            onChange={(name, value) => {
                                handleChange("Mode", value)
                                setMode(value)

                            
                            }}
                        validationError={errors?.Mode}
                        />

                    </FormGroup>
                </Col>
                {mode == PayWithTransferMode.Bank &&
                    <Col lg={3} md={4} sm={12}>
                        <ClusterInput
                            value={details?.ProductCode}
                            label={"Product Code"}
                            id={"ProductCode"}
                            required={true}
                            name="ProductCode"
                            type="text"
                            onHandleChange={(name, value) => {
                                handleChange("ProductCode", value)
                            }}
                            onBlur={handleBlur}
                            validationError={errors?.ProductCode}
                        />
                    </Col>
                }
                <Col lg={3} md={4} sm={12}>
                    <ClusterInput
                        defaultValue={details?.MaximumNumberOfAccounts}
                        value={details?.MaximumNumberOfAccounts}
                        label={"Maximum Number of Acoounts"}
                        id={"MaximumNumberOfAccounts"}
                        name={"MaximumNumberOfAccounts"}
                        type="number"
                        min={1}
                        max={10}
                        required={true}
                        onHandleChange={(name, value) => {
                            handleChange("MaximumNumberOfAccounts", Number(value))
                        }}
                        validationError={errors?.MaximumNumberOfAccounts}
                    />
                </Col>
            </Row>

        </Collapse>

    </React.Fragment>
}


export default PayWithTransferForm