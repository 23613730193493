import { GetInstitution } from "helpers/authentication/institution"
import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Agent Name", name: "AgentName", entityKeyName: "AgentName" },
  { title: "Agent Code", name: "AgentCode", entityKeyName: "AgentCode" },
  {
    title: "Agent Phone Number",
    name: "AgentPhoneNumber",
    entityKeyName: "AgentPhoneNumber",
  },
  {
    title: "Cashout Count",
    name: "CashoutCount",
    entityKeyName: "CashoutCount",
  },
  { title: "Cashout Vol", name: "CashoutVol", entityKeyName: "CashoutVol" },
  { title: "CashIn Count", name: "CashInCount", entityKeyName: "CashInCount" },
  { title: "CashIn Vol", name: "CashInVol", entityKeyName: "CashInVol" },
  {
    title: "Interbank FT Count",
    name: "InterbankFTCount",
    entityKeyName: "InterbankFTCount",
  },
  {
    title: "Interbank FT Vol",
    name: "InterbankFTVol",
    entityKeyName: "InterbankFTVol",
  },
  {
    title: "Token Withdrawal Count",
    name: "TokenWithdrawalCount",
    entityKeyName: "TokenWithdrawalCount",
  },
  {
    title: "Token Withdrawal Vol",
    name: "TokenWithdrawalVol",
    entityKeyName: "TokenWithdrawalVol",
  },
  {
    title: "Intrabank FT Count",
    name: "IntrabankFTCount",
    entityKeyName: "IntrabankFTCount",
  },
  {
    title: "Intrabank FT Vol",
    name: "IntrabankFTVol",
    entityKeyName: "IntrabankFTVol",
  },
  {
    title: "Bills Payment Count",
    name: "BillsPaymentCount",
    entityKeyName: "BillsPaymentCount",
  },
  {
    title: "Bills Payment Vol",
    name: "BillsPaymentVol",
    entityKeyName: "BillsPaymentVol",
  },
]

const SearchOptions = (
  startDate,
  endDate,
  clusters,
  states,
  networkManagers,
  institutions
) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Name",
      id: "s-agentName",
      defaultValue: "",
      name: "agentName",
    },
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    // {
    //   type: "number",
    //   label: "BenchmarkPrecedingDays",
    //   id: "benchmarkPrecedingDays",
    //   defaultValue: "",
    //   name: "benchmarkPrecedingDays",
    // },
    {
      type: "dropdown",
      label: "Cluster",
      id: "clusterId",
      defaultValue: "- Select -",
      data: MapToDropDown(clusters),
      name: "clusterId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "State",
      id: "stateId",
      defaultValue: "- Select -",
      data: MapToDropDown(states),
      name: "stateId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "Network Manager",
      id: "anmId",
      defaultValue: "- Select -",
      data: MapToDropDown(networkManagers),
      name: "anmId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  return options
}
const MapToRows = report => {
  return {
    AgentName: report.AgentName,
    AgentCode: report.AgentCode,
    AgentPhoneNumber: report.AgentPhoneNumber,
    // AgentCategoryID:report.AgentCategoryID,
    // ClusterID:report.ClusterID,
    // ANMID:report.ANMID,
    CashoutCount: report.CashoutCount,
    CashoutVol: Format.currency(report.CashoutVol),

    // CashoutVolChange: {
    //   label: Format.currency(report.CashoutVolChange),
    //   badgeClass: report.CashoutVolChange > 0
    //     ? "success"
    //     : report.CashoutVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // CashoutPercentChange: {
    //   label: Format.percentage(report.CashoutPercentChange),
    //   badgeClass: report.CashoutVolChange > 0
    //     ? "success"
    //     : report.CashoutVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },

    CashInCount: report.CashInCount,
    CashInVol: Format.currency(report.CashInVol),

    // CashInVolChange: {
    //   label: Format.currency(report.CashInVolChange),
    //   badgeClass: report.CashInVolChange > 0
    //     ? "success"
    //     : report.CashInVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // CashInPercentChange: {
    //   label: Format.percentage(report.CashInPercentChange),
    //   badgeClass: report.CashInVolChange > 0
    //     ? "success"
    //     : report.CashInVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },

    InterbankFTCount: report.InterbankFTCount,
    InterbankFTVol: Format.currency(report.InterbankFTVol),

    // InterbankFTVolChange: {
    //   label: Format.currency(report.InterbankFTVolChange),
    //   badgeClass: report.InterbankFTVolChange > 0
    //     ? "success"
    //     : report.InterbankFTVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // InterbankFTPercentChange: {
    //   label: Format.percentage(report.InterbankFTPercentChange),
    //   badgeClass: report.InterbankFTVolChange > 0
    //     ? "success"
    //     : report.InterbankFTVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },

    TokenWithdrawalCount: report.TokenWithdrawalCount,
    TokenWithdrawalVol: Format.currency(report.TokenWithdrawalVol),

    // TokenWithdrawalVolChange: {
    //   label: Format.currency(report.TokenWithdrawalVolChange),
    //   badgeClass: report.TokenWithdrawalVolChange > 0
    //     ? "success"
    //     : report.TokenWithdrawalVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // TokenWithdrawalPercentChange: {
    //   label: Format.percentage(report.TokenWithdrawalPercentChange),
    //   badgeClass: report.TokenWithdrawalVolChange > 0
    //     ? "success"
    //     : report.TokenWithdrawalVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },

    IntrabankFTCount: report.IntrabankFTCount,
    IntrabankFTVol: Format.currency(report.IntrabankFTVol),
    // IntrabankFTVolChange: {
    //   label: Format.currency(report.IntrabankFTVolChange),
    //   badgeClass: report.IntrabankFTVolChange > 0
    //     ? "success"
    //     : report.IntrabankFTVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // IntrabankFTPercentChange: {
    //   label: Format.percentage(report.IntrabankFTPercentChange),
    //   badgeClass: report.IntrabankFTVolChange > 0
    //     ? "success"
    //     : report.IntrabankFTVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },

    BillsPaymentCount: report.BillsPaymentCount,
    institution: report.InstitutionName,
    BillsPaymentVol: Format.currency(report.BillsPaymentVol),
    // BillsPaymentVolChange: {
    //   label: Format.currency(report.BillsPaymentVolChange),
    //   badgeClass: report.BillsPaymentVolChange > 0
    //     ? "success"
    //     : report.BillsPaymentVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
    // BillsPaymentPercentChange: {
    //   label: Format.percentage(report.BillsPaymentPercentChange),
    //   badgeClass: report.BillsPaymentVolChange > 0
    //     ? "success"
    //     : report.BillsPaymentVolChange < 0
    //       ? "danger"
    //       : "secondary",
    // },
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentName: params["agentName"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    ClusterID: params["clusterId"],
    ANMID: params["anmId"],
    StateID: params["stateId"],
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    // BenchmarkPrecedingDays:params["benchmarkPrecedingDays"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToModalState = agentDetail => {
  return []
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name
    }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
