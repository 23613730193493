import {
  GET_AGENTS,
  GET_AGENT_DETAIL,
  GET_AGENT_DETAIL_SUCCESS,
  GET_AGENT_DETAIL_FAIL,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAIL,
  CREATE_AGENT,
  POST_AGENT_SUCCESS,
  AGENT_PURGE,
  AGENT_PURGE_SUCCESS,
  POST_AGENT_FAIL,
  DEVICE_RESET,
  DEVICE_RESET_FAIL,
  DEVICE_RESET_SUCCESS,
  RESET_LIMIT,
  RESET_LIMIT_FAIL,
  RESET_LIMIT_SUCCESS,
  UPGRADE_TO_SUPERAGENT,
  UPGRADE_TO_SUPERAGENT_FAIL,
  UPGRADE_TO_SUPERAGENT_SUCCESS,
  LINK_TO_POSTERMINAL,
  LINK_TO_POSTERMINAL_FAIL,
  LINK_TO_POSTERMINAL_SUCCESS,
  ENABLE_AGENT,
  ENABLE_AGENT_SUCCESS,
  UPDATE_AGENT,
  UPDATE_AGENT_FAIL,
  UPDATE_AGENT_SUCCESS,
  ACCOUNT_VALIDATION,
  ACCOUNT_VALIDATION_FAIL,
  ACCOUNT_VALIDATION_SUCCESS,
  GET_AGENT_LOANS,
  GET_AGENT_LOANS_SUCCESS,
  UNLINK_FROM_SUPERAGENT,
  UNLINK_FROM_SUPERAGENT_SUCCESS,
  UNLINK_FROM_SUPERAGENT_FAIL,
  GENERATE_POS_ACCOUNTS,
  GENERATE_POS_ACCOUNTS_SUCCESS,
  GENERATE_POS_ACCOUNTS_FAILURE,
  UPDATE_POS_ACCOUNTS,
  UPDATE_POS_ACCOUNTS_SUCCESS,
  UPDATE_POS_ACCOUNTS_FAILURE,
} from "./actionTypes"

export const getAgents = params => ({
  type: GET_AGENTS,
  params,
})
export const getAgentLoans = params => ({
  type: GET_AGENT_LOANS,
  params,
})
export const getAllAgents = () => ({
  type: GET_ALL_AGENTS,
})
export const getAgentsSuccess = agents => ({
  type: GET_AGENTS_SUCCESS,
  payload: agents,
})
export const getAgentLoansSuccess = agentLoans => ({
  type: GET_AGENT_LOANS_SUCCESS,
  payload: agentLoans,
})
export const getAgentsFail = error => ({
  type: GET_AGENTS_FAIL,
  payload: error,
})
export const getAgentDetail = id => ({
  type: GET_AGENT_DETAIL,
  id,
})

export const getAgentDetailSuccess = agents => ({
  type: GET_AGENT_DETAIL_SUCCESS,
  payload: agents,
})

export const getAgentDetailFail = error => ({
  type: GET_AGENT_DETAIL_FAIL,
  payload: error,
})

export const createAgent = agent => {
  return {
    type: CREATE_AGENT,
    payload: { agent },
  }
}

export const agentApiError = error => {
  return {
    type: POST_AGENT_FAIL,
    payload: error,
  }
}

export const postAgentSuccess = agent => {
  return {
    type: POST_AGENT_SUCCESS,
    payload: agent,
  }
}

export const agentPurge = () => ({
  type: AGENT_PURGE,
})

export const agentPurgeSuccess = () => ({
  type: AGENT_PURGE_SUCCESS,
})

export const enableAgent = (id, enable) => {
  return {
    type: ENABLE_AGENT,
    id,
    enable,
  }
}

export const enableAgentSuccess = agent => {
  return {
    type: ENABLE_AGENT_SUCCESS,
    payload: agent,
  }
}

export const updateAgent = agent => {
  return {
    type: UPDATE_AGENT,
    payload: { agent },
  }
}
export const updateAgentSuccess = agent => {
  return {
    type: UPDATE_AGENT_SUCCESS,
    payload: agent,
  }
}
export const unlinkParentAgent = agent => {
  return {
    type: UNLINK_FROM_SUPERAGENT,
    payload: { agent },
  }
}
export const unlinkParentAgentSuccess = agent => {
  return {
    type: UNLINK_FROM_SUPERAGENT_SUCCESS,
    payload: { agent },
  }
}
export const unlinkParentAgentFail = agent => {
  return {
    type: UNLINK_FROM_SUPERAGENT_FAIL,
    payload: { agent },
  }
}
export const purgeAgents = () => {
  return {
    type: PURGE_AGENTS,
  }
}

export const purgeAgentSuccess = () => {
  return {
    type: PURGE_AGENTS_SUCCESS,
  }
}

export const deviceReset = id => {
  return {
    type: DEVICE_RESET,
    payload: { id },
  }
}

export const deviceResetError = error => {
  return {
    type: DEVICE_RESET_FAIL,
    payload: error,
  }
}
export const deviceResetSuccess = response => {
  return {
    type: DEVICE_RESET_SUCCESS,
    payload: response,
  }
}

export const accountValidation = accountNumber => {
  return {
    type: ACCOUNT_VALIDATION,
    payload: { accountNumber },
  }
}

export const accountValidationError = error => {
  return {
    type: ACCOUNT_VALIDATION_FAIL,
    payload: error,
  }
}
export const accountValidationSuccess = response => {
  return {
    type: ACCOUNT_VALIDATION_SUCCESS,
    payload: response,
  }
}

export const resetLimit = id => {
  return {
    type: RESET_LIMIT,
    payload: { id },
  }
}

export const resetLimitError = error => {
  return {
    type: RESET_LIMIT_FAIL,
    payload: error,
  }
}
export const resetLimitSuccess = response => {
  return {
    type: RESET_LIMIT_SUCCESS,
    payload: response,
  }
}

export const upgradeToSuperAgent = payload => {
  return {
    type: UPGRADE_TO_SUPERAGENT,
    payload,
  }
}

export const upgradeToSuperAgentError = error => {
  return {
    type: UPGRADE_TO_SUPERAGENT_FAIL,
    payload: error,
  }
}
export const upgradeToSuperAgentSuccess = response => {
  return {
    type: UPGRADE_TO_SUPERAGENT_SUCCESS,
    payload: response,
  }
}

export const linkToPOSTerminal = payload => {
  return {
    type: LINK_TO_POSTERMINAL,
    payload,
  }
}

export const linkToPOSTerminalError = error => {
  return {
    type: LINK_TO_POSTERMINAL_FAIL,
    payload: error,
  }
}
export const linkToPOSTerminalSuccess = response => {
  return {
    type: LINK_TO_POSTERMINAL_SUCCESS,
    payload: response,
  }
}

export const createPOSAccounts  = (payload, id) => {
  return {
    type : GENERATE_POS_ACCOUNTS,
    payload,
    id
  }
}

export const createPOSAccountsSuccess = response => {
  return {
    type: GENERATE_POS_ACCOUNTS_SUCCESS,
    payload : response
  }
}

export const createPOSAccountsFailure = error => {
  return {
    type: GENERATE_POS_ACCOUNTS_FAILURE,
    payload : error
  }
}

