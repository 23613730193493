import { useMemo } from "react";
type UseClassNameProps={
    size?:"sm"|"md"|"lg"|"xl"|undefined,
  outline?: boolean,
  color?: "secondary"|"primary"|"light"|"dark"|"success"| "warning" | "danger" | "info" |undefined,
  className:string
}
const useClassify = (props:UseClassNameProps)=>{
    const {size,color,className,outline} =props;
    const classname = useMemo(() => {
      let c:any = "btn"
      if (outline) {
        c += color ? " btn-outline-" + color : "btn-outline"
      } else {
        c += color ? " btn-" + color : ""
      }
      if(size){
        c+=" btn-" + size
      }
      c += " " + className
      return c;
    },[color, size,className])
  
    return [classname]
  }

  export default useClassify;