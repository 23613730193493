import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert,
  Input
} from "reactstrap"
import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import ClusterCheckBox from 'components/Common/CheckBox';
import Loading from 'components/Common/Loading';
import { Validators } from 'helpers/validators';
import ClusterForm from 'components/Common/Form';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { Formik } from 'formik';
import moment from 'moment';
import { Utils } from 'helpers/utility';
const ClustersForm = props => {
  let { apiSuccess, apiError, loading, details, errorList, myError, isUpdate, editLoading, setErrorList, handleOnChange, handleValidSubmit } = props;
  const Initiate = (details) => {
    return {
      AutomateSettlement: true,
      SettlementTime: 0,
      AppzoneSettlementAccount: "",
      MirrorGL: details?.MirrorGL,
      ReceivableGL: details?.ReceivableGL,
      ExpenseGL: details?.ExpenseGL
    }
  }
  if (details == undefined) {
    return <Loading>Getting configuration</Loading>
  }


  return <React.Fragment>
    <Formik
      initialValues={Initiate(details)}
      validate={(values) => {
        let errors: any = {}
        const required = Validators.required("Please enter a value");



        const MFBMirrorGL = () =>
          [
            required,
            Validators.customAccountNumber([10, "Please only enter numbers", "Accounts can only be 8 or 10 digits"]),
          ].forEach(validator => {
            let error = validator(values?.MirrorGL)
            if (error) errors.MirrorGL = error;
          });

        const MFBReceivableGL = () =>
          [
            required,
            Validators.customAccountNumber([10, "Please only enter numbers", "Accounts can only be 8 or 10 digits"]),
          ].forEach(validator => {
            let error = validator(values?.ReceivableGL)
            if (error) errors.ReceivableGL = error;
          });
        const MFBExpenseGL = () =>
          [
            required,
            Validators.customAccountNumber([10, "Please only enter numbers", "Accounts can only be 8 or 10 digits"]),
          ].forEach(validator => {
            let error = validator(values?.ExpenseGL)
            if (error) errors.ExpenseGL = error;
          });


        const validate = () => {
          try {
            MFBMirrorGL();
            MFBReceivableGL();
            MFBExpenseGL();

            return errors;
          } catch (err) {
          console.debug(err)
          }
        }
        return validate();

      }}
      onSubmit={(values, { setSubmitting }) => {

        handleValidSubmit({ ...values, SettlementTime: moment().set("hour", values.SettlementTime).add(1, "hour") })


      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldError,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (<ClusterForm handleSubmit={handleSubmit}>

        <React.Fragment>


          <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
            <CardBody>
              {values.AutomateSettlement ? <Alert color="info">Changes to the settlement time would take effect from the next day</Alert> : null}

              <Row>
                <Col lg={4} md={4} sm={6}>
                  <ClusterInput
                    defaultValue={values?.MirrorGL}
                    focus={true}
                    label={"MFB Mirror GL"}
                    id={"MirrorGL"}
                    name={"MirrorGL"}
                    type="text"
                    required={true}
                    value={values.MirrorGL}
                    validationError={errors.MirrorGL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

                <Col lg={4} md={4} sm={6}>
                  <ClusterInput
                    defaultValue={values?.ReceivableGL}

                    label={"MFB Receivable GL"}
                    id={"ReceivableGL"}
                    name={"ReceivableGL"}
                    type="text"
                    required={true}
                    value={values.ReceivableGL}
                    validationError={errors.ReceivableGL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={4} md={4} sm={6}>
                  <ClusterInput
                    defaultValue={values?.ExpenseGL}

                    label={"MFB Expense GL"}
                    id={"ExpenseGL"}
                    name={"ExpenseGL"}
                    type="text"
                    required={true}
                    value={values.ExpenseGL}
                    validationError={errors.ExpenseGL}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>



              </Row>

              <Row>

                <Col lg={4} md={4} sm={6} className="mt-4">

                  <ClusterCheckBox
                    onHandleChange={(name, value) => { setFieldValue(name, value) }}
                    label={"Automatic Daily Settlement"}
                    name={"AutomateSettlement"}
                    id={"automaticDailySettlement"}
                    disabled={true}
                    checked={values.AutomateSettlement} >

                  </ClusterCheckBox>
                </Col>

                {values.AutomateSettlement ? <Col lg={4} sm={4}>
                  <FormGroup>
                    <Label for="DailySettlementTime">Daily Settlement Time</Label>

                    <Input
                      type="time"
                      value={moment(Utils.Date.hrToDate(values.SettlementTime)).format("HH:mm")}
                      id="DailySettlementTime"
                      name={"SettlementTime"}
                      onBlur={handleBlur}
                      disabled={true}
                      onChange={e => {
                        setFieldValue(
                          "SettlementTime", Utils.Date.hrFromTime(e.target.value)
                        )
                      }
                      }
                    />
                  </FormGroup>
                </Col> : null}

              </Row>
              <div style={{ display: "flex", justifyContent: "end", width: "100%" }}>
                <Link to="/settlement-approval-configuration">
                  Configure Withdrawal Approver
                </Link>
              </div>
            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={editLoading}
              disabled={!touched ? true : (editLoading || !isValid)}
              color={"primary"}
              addon={"bx bx-plus"}
            >
              Save
            </ClusterButton>
          </Col>
        </Row>

      </ClusterForm>)}
    </Formik>
  </React.Fragment>

}
ClustersForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(ClustersForm)))