import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import useSWR from "swr"
import moment from "moment"
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { getAgents, getAgentDetail, agentPurge } from "store/actions"
import { RECEIPTSTATUS, SEARCHOPTIONS } from "helpers/variables"
import ClusterModal from "components/Common/Modals/Modal"
import ClusterMap from "components/Common/Map/Maps"
import { post } from "helpers/api_helper"
import withDispose from "components/HOCs/WithDispose"
let SEARCHOPTS = SEARCHOPTIONS
let basicHeaders = [
  { title: "Agent Name", name: "agentName" },
  { title: "Agent Phone Number", name: "agentPhoneNumber" },
  { title: "Super Agent", name: "superAgent" },
  { title: "Agent Network Manager", name: "agentNetworkManager" },
  { title: "Agent Location", name: "agentLocation" },
  { title: "Agent Cluster Manager", name: "agentClusterManager" },
]
const fetcher = url => fetch(url).then(r => r.json())

const ActivityReportList = props => {
  const { reportType } = props
  const url = "api/Report/AgentActivityReport"
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [rows, setRows] = useState([])
  const [data, setData] = useState({})
  const [headers, setHeaders] = useState(basicHeaders)
  const [columns, setColumns] = useState([...headers])
  const [agentPhoneNumber, setAgentPhoneNumber] = useState("")
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  let maxSize = 10
  const [loading, setLoading] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)

  SEARCHOPTS.range.from = startDate.format("YYYY-MM-DD")
  SEARCHOPTS.range.to = endDate.format("YYYY-MM-DD")
  SEARCHOPTS.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "tel",
      label: "Super Agent Phone Number",
      id: "s-superAgentPhoneNo",
      defaultValue: "",
      name: "superAgentPhoneNo",
    },
  ]
  const [params, setParams] = useState({
    agentPhoneNumber,
    status,
    fromDate: startDateStr,
    toDate: endDateStr,
    page: pageNumber,
  })
  const [searchOptions, setSearchOptions] = useState(SEARCHOPTS)
  const [transactionTrack, setTransactionTrack] = useState()

  function createBody(params) {
    return {
      PhoneNumber: params["agentPhoneNumber"],
      InstitutionCode: "100305",
      FromDate: moment(params["fromDate"])
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      ToDate: moment(params["toDate"])
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      SuperAgentPhoneNumber: params["superAgentPhoneNumber"],
      AgentClusterManagerID: params["agentClusterManagerID"],
      agentNetworkManagerID: params["agentNetworkManagerID"],
      ReportType: reportType,
      StartIndex: (params["page"] - 1) * maxSize,
      MaxSize: params["maxSize"] || 10,
    }
  }

  useEffect(() => {
    populateRows()
  }, [data])

  useEffect(() => {
    let query = createBody(params)
    setLoading(true)
    search(query)
  }, [params])

  // useEffect(()=>{
  //   search(createBody(params))
  // },[])

  const populateRows = () => {
    setLoading(false)
    let h = [...basicHeaders]
    if (data && data.reports && data.reports?.length > 0) {
      let rws = []
      data.reports.forEach((report, i) => {
        let row = {
          id: report.ID,
          agentName: report.AgentName,
          agentPhoneNumber: report.AgentPhoneNumber,
          superAgent: report.SuperAgent,
          agentNetworkManager: report.AgentNetworkManager,
          agentLocation: report.AgentLocation,
          agentClusterManager: report.AgentClusterManager,
        }
        if (reportType == "AgencyBanking") {
          row["totalNumberOfAccountsOpened"] =
            report.TotalNumberOfAccountsOpened
          row["totalDeposits"] = report.TotalDeposits
          row["totalWithdrawals"] = report.TotalWithdrawals
        }
        if (reportType == "Geep") {
          row["totalNumberOfGeepCustomers"] = report.TotalNumberOfGeepCustomers
        }

        if (reportType == "MCash") {
          row["mcashCashOut"] = report.McashCashOut
          row["gtbCashOut"] = report.GTBCashOut
        }

        if (reportType == "Esusu") {
          row["esusuDeposits"] = report.EsusuDeposits
          row["numberOfEsusuCustomers"] = report.NumberOfEsusuCustomers
        }

        if (reportType == "FundsTransfer") {
          row["fundsTransferOutwards"] = report.FundsTransferOutwards
        }

        if (reportType == "CashOut") {
          row["terminalID"] = report.TerminalID
          row["posCashOutCount"] = report.POSCashOutCount
          row["posCashOutValue"] = report.POSCashOutValue
          row["fundsTransferCount"] = report.FundsTransferCount
          row["fundsTransferOutwards"] = report.FundsTransferOutwards
        }

        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewMap(report.AgentPhoneNumber)}
          >
            View on Map
          </Button>
        )
        rws.push(row)
      })

      if (reportType == "AgencyBanking") {
        h.push({
          title: "Total Number Of Accounts Opened",
          name: "totalNumberOfAccountsOpened",
        })
        h.push({ title: "Total Deposits", name: "totalDeposits" })
        h.push({ title: "Total Withdrawals", name: "totalWithdrawals" })
      }
      if (reportType == "Geep") {
        h.push({
          title: "Total Number Of Geep Customers",
          name: "totalNumberOfGeepCustomers",
        })
      }

      if (reportType == "MCash") {
        h.push({ title: "Mcash CashOut", name: "mcashCashOut" })
        h.push({ title: "GTB CashOut", name: "gtbCashOut" })
      }

      if (reportType == "Esusu") {
        h.push({ title: "Esusu Deposits", name: "esusuDeposits" })
        h.push({
          title: "Number Of Esusu Customers",
          name: "numberOfEsusuCustomers",
        })
      }

      if (reportType == "FundsTransfer") {
        h.push({
          title: "Funds Transfer Outwards",
          name: "fundsTransferOutwards",
        })
      }

      if (reportType == "CashOut") {
        h.push({ title: "TerminalID", name: "terminalID" })
        h.push({ title: "POS CashOut Count", name: "posCashOutCount" })
        h.push({ title: "POS CashOut Value", name: "posCashOutValue" })
        h.push({ title: "Funds Transfer Count", name: "fundsTransferCount" })
        h.push({
          title: "Funds Transfer Outwards",
          name: "fundsTransferOutwards",
        })
      }

      h.push({ title: "Action", name: "action", isBadge: false })
      setColumns([...h])
      setRows(rws)
    }
  }
  const handlePageChange = page => {
    setPageNumber(page)
    let p = { ...params, page }
    search(p)
  }

  function search(query) {
    if (!loading) {
      // setTimeout(()=>{
      //   if(loading)setLoading(false)
      // },30000);
      post(url, query, {}, true)
        .then(c => {
          setData(c)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
    }
  }

  const onAction = p => {
    p["page"] = pageNumber
    let prms = { ...params, ...p }
    setParams(prms)
    // search(prms);
  }

  function onViewMap(agentPhoneNumber) {
    // onGetAgentDetail(id)
    setmodal(true)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Agent Activity Report"}
            breadcrumbItem={"View Report"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: data.total,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{ url, query: createBody(params), alt: true }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {modal ? (
        <Modal
          isOpen={modal}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setmodal(!modal)
          }}
        >
          <div className="modal-content">
            <ModalHeader></ModalHeader>
            <ModalBody>
              <ClusterMap></ClusterMap>
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  setmodal(!modal)
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </div>
        </Modal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

ActivityReportList.propTypes = {
  data: PropTypes.any,
  reportType: PropTypes.string,
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(ActivityReportList))))
