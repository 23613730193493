import ClusterButton from "components/Common/Button/Button"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { DisposeProps } from "./WithDispose"
function withMinimize<T extends DisposeProps>(BaseComponent: React.ComponentType<T>) {
  return (props) => {

    // const {children, otherProps} = props;
    const [minimize, setMinimize] = useState(false)

    return minimize ? (
      <ClusterButton
        color={"danger"}
        onHandleClick={e => {
          setMinimize(!minimize)
        }}
        className="badge badge-danger"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <i className="bx bx-expand" style={{ marginRight: "3px" }}></i>
        <span>Expand</span>
      </ClusterButton>
    ) : (
      <div style={{ position: "relative" }}>
        <div
          onClick={e => {
            setMinimize(!minimize)
          }}
          className="badge badge-accent"
          style={{
            cursor: "pointer",
            zIndex: 9,
            position: "absolute",
            right: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #999999"
          }}
        >
          <b> <i className="bx bx-collapse" style={{ marginRight: "3px" }}></i>
            <span>minimize</span></b>
        </div>
        <BaseComponent {...props}>{props.children}</BaseComponent>
      </div>
    )
  }
}

export default withMinimize
