import React from "react"
// import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"
// import globe from "assets/images/globe.svg"

import Lottie from 'lottie-react-web'
import animation from 'assets/animation/globe.json'
const CarouselPage = () => {
  return (
    <React.Fragment>
      <Col xl={9}>
        <div className="auth-full-bg pt-lg-5 p-4">
          <div className="w-100">
            {/* <div className="bg-overlay"></div> */}
            {/* <div className="d-flex h-100 flex-column"> */}
              <div className="py-5 h-100 px-4 mt-auto">
                <div className="row justify-content-center">
                  <Lottie
                    options={{
                      animationData: animation,
                      
                    }}
                    segments={[1,120]}
                  />
                  {/* <img src={globe} alt="Cluster to The World" /> */}
                     </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  )
}
export default CarouselPage
