import { TransactionMode } from "enums/transaction-mode"
import { TransactionStatus } from "enums/transaction-status"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Date Logged", name: "date", entityKeyName: "DateLogged" },
  { title: "Amount", name: "amount", entityKeyName: "Amount" },
  {
    title: "Amount Settled",
    name: "amountSettled",
    entityKeyName: "AmountSettledToWallet",
  },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionType",
  },
  {
    title: "Transaction Mode",
    name: "transactionMode",
    entityKeyName: "TransactionMode",
  },
  {
    title: "Payment Reference",
    name: "paymentReference",
    entityKeyName: "TransactionReference",
  },
  {
    title: "Balance Before",
    name: "balanceBefore",
    entityKeyName: "BalanceBefore",
  },
  {
    title: "Balance After",
    name: "balanceAfter",
    entityKeyName: "BalanceAfter",
  },
  { title: "Status", name: "status", isBadge: true, entityKeyName: "Status" },
  { title: "", name: "View Details", isAction: true },
]

const ACCOUNTHEADERS = [
  { title: "Bank", name: "bank", entityKeyName: "BankName" },
  { title: "Account Name", name: "accountName", entityKeyName: "AccountName" },
  {
    title: "Account Number",
    name: "accountNumber",
    entityKeyName: "AccountNumber",
  },

  { title: "Action", name: "action", isAction: true },
]
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)

  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.range.enabled = true
  options.fields = [
    {
      type: "text",
      label: "Transaction Reference",
      id: "s-transReference",
      defaultValue: "",
      name: "transactionRef",
      width: 2,
    },
    {
      type: "dropdown",
      label: "Status",
      id: "s-status",
      defaultValue: "--Select Status--",
      data: Utils.Enums.toOptions(TransactionStatus),
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "Transaction Mode",
      id: "s-transactionMode",
      defaultValue: "All",
      data: Utils.Enums.toOptions(TransactionMode),
      name: "transactionMode",
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}

const AccountSearchOptions = (startDate, endDate) => {
  let options = Object.assign({}, SEARCHOPTIONS)

  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.range.enabled = true

  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapBanksToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.Code,
      key: item.Code,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}

const MapUsersToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.id,
      key: item.id,
      value: item.lastName + " " + item.firstName,
    }))
  }
  return []
}
const MapToRows = settlement => {
  return {
    id: settlement.ID,
    date: Format.date(settlement.DateLogged),
    institution: settlement?.InstitutionName,
    amount: Format.currency(settlement.Amount),
    amountSettled: Format.currency(settlement.AmountSettledToWallet),
    transactionType: settlement.TransactionType,
    transactionMode: settlement.TransactionMode,
    account: settlement.AccountTo?.AccountNumber,
    paymentReference: settlement?.TransactionReference,
    balanceBefore:
      settlement?.TransactionMode === "Top Up" && settlement?.BalanceBefore == 0
        ? "--"
        : Format.currency(settlement?.BalanceBefore),
    status: {
      label: settlement.Status,
      badgeClass: settlement.Status == "Successful" ? "success" : "secondary",
    },
    balanceAfter:
      settlement?.TransactionMode === "Top Up" && settlement?.BalanceAfter == 0
        ? "--"
        : Format.currency(settlement?.BalanceAfter),
  }
}
const MapAccountToRows = account => {
  return {
    id: account.ID,
    accountNumber: account.AccountNumber,
    accountName: account.AccountName,
    bank: account.BankName,
  }
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Status: parameters["status"],
    TransactionReference: parameters["transactionRef"],
    TransactionMode: parameters["transactionMode"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    FromDate: moment(parameters["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(parameters["toDate"])
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

export default {
  HEADERS,
  ACCOUNTHEADERS,
  AccountSearchOptions,
  SearchOptions,
  MapToRows,
  MapUsersToDropDown,
  MapAccountToRows,
  MapBanksToDropDown,
  SearchCriteria,
  MapToDropDown,
}
