import { GetInstitution } from "helpers/authentication/institution"
import { MOBILEACCOUNTSTATUS, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Status", name: "status", entityKeyName: "IsActive", isBadge: true },
  {
    title: "Account Status",
    name: "accountStatus",
    entityKeyName: "MobileAccountStatus",
    isBadge: true,
  },
  { title: "Last Name", name: "lastName", entityKeyName: "LastName" },
  { title: "Other Names", name: "otherNames", entityKeyName: "OtherNames" },
  { title: "Mobile Phone", name: "mobilePhone", entityKeyName: "MobilePhone" },
  {
    title: "Account Type",
    name: "accountType",
    entityKeyName: "TheMobileAccountType",
  },
  { title: "Date Created", name: "dateCreated", entityKeyName: "DateCreated" },
  {
    title: "Date Activated",
    name: "dateActivated",
    entityKeyName: "DateActivated",
    defaultValue: "Not yet activated",
  },
  { title: "Action", name: "action", isAction: true },
]
const BANKACCOUNTHEADERS = [
  { title: "Customer ID", name: "customerID" },
  { title: "Account Name", name: "accountName" },
  { title: "Account Number", name: "accountNumber" },
  { title: "Product", name: "product" },
  { title: "Gender", name: "gender" },
  { title: "Status", name: "status", isBadge: true },
]
const ERRORLIST = {
  email: undefined,
  gender: undefined,
  lastName: undefined,
  username: "",
  locationCode: undefined,
  email: undefined,
  otherNames: undefined,
  phoneNumber: undefined,
  personalPhoneNo: undefined,
  superAgentNumber: undefined,
  accountNumber: undefined,
  accountMode: undefined,
  gender: undefined,
  bvn: "",
  lga: "",
  province: "",
  accountType: "",
  networkManagerId: undefined,
  clusterManagerId: undefined,
  categoryId: undefined,
  allowSubAgentControl: "",
  address: undefined,
  mCashFee: "",
  incomeAccount: undefined,
  depositLimit: undefined,
  withdrawalLimit: undefined,
  transferLimit: undefined,
  cummulativeWithdrawalLimit: undefined,
  cummulativeDepositLimit: undefined,
  taxPayerID: "",
  staffID: "",
  businessName: "",
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Last Name",
      id: "s-lastName",
      defaultValue: "",
      name: "lastName",
    },
    {
      type: "text",
      label: "Other Names",
      id: "s-otherNames",
      defaultValue: "",
      name: "otherNames",
    },
    {
      type: "tel",
      label: "Mobile Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "mobileNumber",
    },
    {
      type: "text",
      label: "Mobile Teller Account",
      id: "s-mobileTellerAccount",
      defaultValue: "",
      name: "mobileTellerAccount",
    },
    {
      type: "dropdown",
      label: "Account Status",
      id: "status",
      defaultValue: "- Select -",
      data: MOBILEACCOUNTSTATUS,
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToRowsBankAccount = account => {
  return {
    customerID: account.CustomerID,
    accountName: account.Name,
    accountNumber: account.BankAccount,
    gender: account.Product,
    product: account.Gender,
    status: {
      label: account.BankStatus,
      badgeClass: account.BankStatus === "Active" ? "success" : "secondary",
    },
  }
}
const MapToRows = account => {
  const status = GetAccountStatus(account)
  return {
    id: account.ID,
    lastName: account.LastName,
    otherNames: account.OtherNames,
    mobilePhone: account.MobilePhone,
    institution: account?.InstitutionName,
    status: {
      label: account.IsActive ? "Active" : "In Active",
      badgeClass: account.IsActive ? "success" : "secondary",
    },
    accountStatus: { label: status, badgeClass: GetAccountStatusBadge(status) },
    dateCreated: account.DateCreated,
    accountType: account.TheMobileAccountType,
    dateActivated: DateActivated(account.DateActivated),
  }
}
const GetAccountStatus = account => {
  if (!account) return ""
  return account.MobileAccountStatus == "Active" &&
    DateActivated(account.DateActivated) == "Not yet activated"
    ? "Not Yet Activated"
    : account.MobileAccountStatus
}
const GetAccountStatusBadge = status => {
  return status == "New"
    ? "info"
    : status == "Active"
    ? "success"
    : status == "Not Yet Activated"
    ? "primary"
    : status == "HotListed"
    ? "danger"
    : "secondary"
}

const DateActivated = dateActivated => {
  return !dateActivated ||
    moment(dateActivated).isSameOrBefore(moment("1900-01-01T00:00:00"))
    ? "Not yet activated"
    : dateActivated
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    LastName: params["lastName"],
    OtherNames: params["otherNames"],
    MobileNumber: params["mobileNumber"],
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    MobileTellerAccount: params["mobileTellerAccount"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToModalState = accountDetail => {
  return [
    {
      title: "Mobile Wallet Details",
      items: [
        {
          id: "lastName",
          label: "Surname",
          name: "lastName",
          value: accountDetail.LastName,
        },
        {
          id: "otherNames",
          label: "Other Names",
          name: "otherNames",
          value: accountDetail.OtherNames,
        },
        {
          id: "phoneNumber",
          label: "Mobile Phone",
          name: "mobilePhone",
          value: accountDetail.MobilePhone,
        },
        {
          id: "branch",
          label: "Opened By",
          name: "openedBy",
          value: accountDetail.OpenedBy,
        },
        {
          id: "dateCreated",
          label: "Date Created",
          name: "dateCreated",
          value: accountDetail.DateCreated,
        },
        {
          id: "dateActivated",
          label: "Date Activated",
          name: "dateActivated",
          value: DateActivated(accountDetail.DateActivated),
        },
      ],
    },
    {
      title: "Customer Details",
      items: [
        {
          id: "lastName",
          label: "Surname",
          name: "lastName",
          value: accountDetail.LastName,
        },
        {
          id: "otherNames",
          label: "Other Names",
          name: "otherNames",
          value: accountDetail.OtherNames,
        },
      ],
    },
  ]
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
export default {
  HEADERS,
  GetAccountStatus,
  GetAccountStatusBadge,
  MapToRowsBankAccount,
  BANKACCOUNTHEADERS,
  ERRORLIST,
  MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  DateActivated,
}
