
import { defaultObj } from "helpers/utility"
import {
  GET_CLUSTER_DETAIL_SUCCESS,
  GET_CLUSTER_DETAIL_FAIL,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_FAIL,
  CLUSTER_PURGE,
  ENABLE_CLUSTER,
  ENABLE_CLUSTER_SUCCESS,
  CLUSTER_PURGE_SUCCESS,
  GET_CLUSTERS,
  POST_CLUSTER_SUCCESS,
  UPDATE_CLUSTER,
  CREATE_CLUSTER,
  UPDATE_CLUSTER_SUCCESS,
  POST_CLUSTER_FAIL,
  GET_ALL_CLUSTERS
} from "./actionTypes"

const INIT_STATE = {
  clusters: [],
  clusterDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined
}

const Clusters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CLUSTERS_SUCCESS:
      return {
        ...state,
        clusters: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CLUSTERS:
      return {
        ...state,
        loading: true,
        error:"",
        success:""
      }
    case GET_ALL_CLUSTERS:
      return {
        ...state,
        loading: true,
      }
    case GET_CLUSTERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CLUSTER_DETAIL_SUCCESS:
      return {
        ...state,
        clusterDetail: action.payload?.Data,
        loading: false,
      }

    case GET_CLUSTER_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_CLUSTER_SUCCESS:
      return {
        ...state,
        clusterDetail: defaultObj(state.clusterDetail),//action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }
    case UPDATE_CLUSTER:
      state = {
        ...state,
        editLoading: true,
        error:"",
        success:""
      }
      break
    case CREATE_CLUSTER:
     return {
        ...state,
        editLoading: true,
        error:"",
        success:""
      }
      
    case POST_CLUSTER_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break


    case UPDATE_CLUSTER_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: ""
      }
      break

    case ENABLE_CLUSTER:
      state = {
        ...state,
        editLoading: true,
        error:"",
        success:"",
      }
      break
    case ENABLE_CLUSTER_SUCCESS:
      let detail = (state.clusterDetail as any);
      detail.IsActive = action.payload?.enable
      return {
        ...state,
        editLoading: false,
        loading: false,
        clusterDetail:detail,
        error: "",
        statusChanged: action.payload?.enable,
        success: action.payload?.Message
      }
    case CLUSTER_PURGE_SUCCESS:
      
      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Clusters
