import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"

import Report from "../../ReportManagement/report"

import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { toast } from "react-toastify"

import { withTranslation } from "react-i18next"
import {
  getDebitRequests,
  getDebitRequestDetail,
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import DirectDebitLogic from "../direct-debit"
import ApproveDirectDebitLogic from "../approve-direct-debit"
import {
  GET_DEBIT_REQUESTS_DETAIL,
} from "helpers/url_helper"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

const DirectDebitList = props => {
  const {
    debitrequests,
    totalCount,
    loading,
    onGetDirectDebits,
  } = props
  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = DirectDebitLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetDirectDebits, HEADERS: HEADERS, SearchCriteria: SearchCriteria })
  
  const { institutionsData, institutions } = useInstitutionsData()
  const { loadData } = ApproveDirectDebitLogic
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

  useEffect(() => {
    populateRows()
  }, [props])

  const populateRows = () => {
    if (debitrequests) {
      let rws = []
      debitrequests.forEach(debitReq => {
        let row = MapToRows(debitReq)
        row["download"] = (
          <Button
            outline
            type="button"
            color="primary"
            size="sm"
            className="btn-outline waves-effect waves-light"
            onClick={() => {
              toast.info("Downloading invoice...")
              loadData(debitReq.ID, `Invoice ${debitReq.DateLogged}.pdf`)
            }}>
            Download Invoice
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Direct Debit"}
            breadcrumbItem={"View Direct Debit Requests"}
          />
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_DEBIT_REQUESTS_DETAIL,
                  query: SearchCriteria(params, maxSize)
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

DirectDebitList.propTypes = {
  debitrequests: PropTypes.array,
  single_debit_request: PropTypes.object,
  onGetDirectDebits: PropTypes.func,
  onGetSingleDirectDebits: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  debitRequests: {
    debitrequests,
    error,
    single_debit_request,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  return {
    debitrequests: [...debitrequests],
    error,
    single_debit_request: single_debit_request,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDirectDebits: query => dispatch(getDebitRequests(query)),
  onGetSingleDirectDebits: id => dispatch(getDebitRequestDetail(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(DirectDebitList))))
