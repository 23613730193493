import { BASESTATUS, Format, SEARCHOPTIONS } from "helpers/variables";

const HEADERS = [
    { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
    { title: "Serial Number", name: "serialNumber", entityKeyName: "SerialNumber" },
    { title: "Mode", name: "mode", entityKeyName: "Mode" },
    { title: "Agent Code", name: "agentCode", entityKeyName: "AgentCode" },
    { title: "Merchant Percentage", name: "merchantPercentage", entityKeyName: "MerchantPercentage" },
    { title: "Failure Reason", name: "failureReason", entityKeyName: "FailureReason" },
  
]

const ERRORLIST = {
    file: undefined,
    institution:undefined
}
const SearchOptions = () => {

    let options = Object.assign({}, SEARCHOPTIONS);
    options.range.enabled = false;
    options.fields = [
        {
            type: "text",
            label: "File Number",
            id: "s-fileNumber",
            defaultValue: "",
            name: "fileNumber",
        },
        {
            type: "date",
            label: "Date Uploaded",
            id: "s-dateUploaded",
            defaultValue: "",
            name: "dateUploaded",
        }
    ]
    return options
}
const MapToDropDown = (data) => {
    if (data instanceof Array) {
        return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
    }
    return [];
}
const MapToRows = (terminal) => {
    return {
        id: terminal.ID,
        terminalID: terminal.TerminalID,
        serialNumber: terminal.SerialNumber,
        name: terminal.Name,
        status: { label: terminal.IsActive ? "Active" : "Inactive", badgeClass: terminal.IsActive ? "success" : "secondary" },
        mode: terminal.Mode,
        agentCode: terminal.AgentCode ?? "Un-Assigned",
        failureReason:terminal.FailureReason,
        merchantPercentage: Format.percentage(terminal.MerchantPercentage)
    }


}
const SearchCriteria = (parameters, maxSize) => {
    return JSON.stringify({
        Name: parameters["name"],
        FileNumber: parameters["fileNumber"],
        DateUploaded: parameters["dateUploaded"],
        
        StartIndex: (parameters["page"] - 1) * maxSize,
        MaxSize: maxSize,
    })

}

const MapToModalState = (terminalDetail) => {
    return [
        {
            title: "",
            items: [
                {
                    id: "name",
                    label: "Name",
                    name: "name",
                    value: terminalDetail.Name,
                },
                {
                    id: "manager",
                    label: "Manager",
                    name: "managerId",
                    value: terminalDetail.terminalManager?.Name,
                }
            ],
        }
    ]
}

export default {
    HEADERS, SearchOptions, MapToRows, SearchCriteria, MapToModalState, ERRORLIST, MapToDropDown
}