import React, {useState} from 'react'
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import { connect } from "react-redux"
import withDispose from "components/HOCs/WithDispose"
import CreateNotificationForm from './create-notification-form'


const CreateNotification = props => {
    const [modal, setModal] = useState(true)
  return (
      <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <CreateNotificationForm isOpen={modal} setModal={setModal}></CreateNotificationForm>
    </React.Fragment>
  )
}

CreateNotification.propTypes = {
   history: PropTypes.object,
  loading: PropTypes.bool,
}

const mapStatetoProps = state => {
  const { menus } = state.permissions
  return { menus }
}

export default withRouter(
  connect(mapStatetoProps)(
    withDispose(CreateNotification)
  )
)
