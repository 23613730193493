import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SearchSection from "components/Common/Search/SearchSection"
import ClusterDataTable from "components/Common/DataTable"

const Report = props => {
  const { columns, rows } = props
  return (
    <React.Fragment>
      <ClusterDataTable columns={columns} rows={rows}></ClusterDataTable>
    </React.Fragment>
  )
}

Report.propTypes = {
  tableData: PropTypes.object,
  searchData: PropTypes.object,
}
export default Report
