import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { GET_ALL_CATEGORIES, } from "helpers/url_helper"
import { Row, Col, Card, CardBody, Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import ClusterDropdown from "components/Common/DropDown/Dropdown";
import ClusterButton from "components/Common/Button/Button"
import clusterian from "../../../assets/images/icons/clusterian.png"
import useSWR from "swr"
import { withTranslation } from "react-i18next"
import ClusterMultiInput from "components/Common/Input/MultiInput"
import ClusterInput from "components/Common/Input/Input"
import ClusterTextArea from "components/Common/TextArea"
import ClusterFileUploader from "components/Common/FileUploader"
import { DropdownType } from 'enums/dropdown-type';
import { postNotification } from "../../../store/actions"
import withDispose from "components/HOCs/WithDispose"
import { Utils } from "helpers/utility"
import { NotificationType } from "../notification"
import NotificationLogic from "../notification"
const { MapToDropDown } = NotificationLogic
import { fetcher } from "helpers/api_helper"
import { Validators } from "helpers/validators"


const CreateNotificationForm: React.FC<any> = props => {
  let { isOpen, error, success, loading, onPostNotification } = props
  const Initiate = () => {
    return {
      AgentPhoneNumbers: null,
      Type: NotificationType.General,
      AgentCategoryIDs: null,
      Message: "",
      Header: "",
      File: null
    }
  }
  const [notificationData, setNotificationData] = useState(Initiate())
  const [type, setType] = useState<NotificationType>()
  const categories = useSWR(GET_ALL_CATEGORIES, fetcher)
  const handleValidSubmit = event => {
    event.preventDefault()
    onPostNotification(notificationData)
  }

  useEffect(() => {
    if (success) {
      props.history.push('/view-notifications')
    }
  }, [error, success])

  return (

    <div className="page-content">
      <Container fluid>
        <Breadcrumbs
          title={"Notification"}
          image={clusterian}
          breadcrumbItem={"Send Notification"}
        />
        <Card
          outline
          style={{ background: "transparent", borderColor: "#999999" }}
          className="border"
        >

          <CardBody>
            <Row className="justify-content-center">
              <form
                className="form-horizontal mt-4 w-100"
                noValidate
                onSubmit={handleValidSubmit}
              >
                <Col lg={7} md={10} sm={12}>
                  <Col style={{ marginBottom: '10px' }}>
                    <ClusterDropdown
                      placeholder={"Agent Category"}
                      defaultValue={NotificationType.General}
                      items={Utils.Enums.toOptions(NotificationType)}
                      name={"Type"}
                      required={true}
                      value={notificationData.Type}
                      onChange={(name, value: NotificationType) => {
                        setType(value)
                        setNotificationData({ ...notificationData, Type: value })
                      }}
                    />
                  </Col>
                  {type == NotificationType.AgentCategory ? (
                    <Col style={{ marginBottom: '10px' }}>
                      <ClusterDropdown
                        type={DropdownType.MultiSelect}
                        placeholder={"Select Preferred Agent Category"}
                        value={notificationData.AgentCategoryIDs}
                        items={MapToDropDown((categories?.data as any)?.Data)}
                        name={"AgentCategoryIDs"}
                        required={notificationData?.AgentCategoryIDs == null}
                        onChange={(name, value) => {
                          setNotificationData({ ...notificationData, AgentCategoryIDs: value.split(",").map(Number) })
                        }}
                      />
                    </Col>
                  ) : (<></>)}

                  {type == NotificationType.Specific ? (
                    <Col style={{ marginBottom: '10px' }}>
                      <ClusterMultiInput
                        placeholder={"Input Phone Number with a trailing comma eg. 07032422311,"}
                        id={"AgentPhoneNumbers"}
                        name={"AgentPhoneNumbers"}
                        required={notificationData.AgentPhoneNumbers?.length === 0}
                        value={""}
                        type="phone"
                        validators={[Validators.phone(["Phone number should contain only numbers", "Phone number must be 11 digits"])]}
                        onHandleChange={(name, values) => {
                          setNotificationData({ ...notificationData, AgentPhoneNumbers: values })
                        }}
                      />
                    </Col>
                  ) : (<></>)}

                  <Col style={{ marginBottom: '10px' }}>
                    <ClusterInput
                      placeholder={"Message Subject"}
                      id={"Header"}
                      required={true}
                      name="Header"
                      value={notificationData.Header}
                      type="text"
                      onChange={(e) => {
                        setNotificationData({ ...notificationData, Header: e.target.value })
                      }} />
                  </Col>
                  <Col style={{ marginBottom: '10px' }}>
                    <ClusterFileUploader
                      id="File"
                      name="File"
                      accept={[".png, .jpg, .jpeg"]}
                      value={notificationData.File}
                      onHandleChange={(value) => {
                        setNotificationData({
                          ...notificationData, File: {
                            Base64: value?.Base64,
                            Name: value?.FileName,
                            Extension: value?.FileName.slice(-3)
                          }
                        })
                      }}
                    />
                  </Col>

                  <Col style={{ marginBottom: '10px' }}>
                    <ClusterTextArea
                      placeholder={"Type notification message here..."}
                      id={"Message"}
                      name="Message"
                      required={true}
                      value={notificationData.Message}
                      type="text"
                      onChange={(e) => {
                        setNotificationData({ ...notificationData, Message: e.target.value })
                      }}
                    />
                  </Col>

                    <Col style={{ marginBottom: '10px' }}>
                      <ClusterButton
                        loading={loading}
                        disabled={
                          notificationData?.Header == "" || notificationData?.Message == ""
                        }
                        color={"primary"}
                        className={"full-width"}
                      >
                        Send Notification
                      </ClusterButton>
                    </Col>
         
                </Col>
              </form>
            </Row>
          </CardBody>

        </Card>
      </Container>
    </div>
  )
}
CreateNotificationForm.propTypes = {
  loading: PropTypes.bool,
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  onPostNotification: PropTypes.func
}

const mapStateToProps = ({
  notifications: {
    notifications,
    error,
    success,
    loading
  }
}) => {
  return {
    notifications: [...notifications],
    error,
    success,
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onPostNotification: payload => dispatch(postNotification(payload))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateNotificationForm))))