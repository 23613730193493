import { GetInstitution } from "helpers/authentication/institution"
import { AGENTTYPE, BASESTATUSSTRING, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Action", name: "action", isAction: true },
  { title: "Status", name: "status", entityKeyName: "IsActive", isBadge: true },
  { title: "Last Name", name: "lastName", entityKeyName: "LastName" },
  { title: "Other Names", name: "otherNames", entityKeyName: "OtherNames" },
  { title: "Phone Number", name: "phoneNumber", entityKeyName: "PhoneNumber" },
  { title: "Gender", name: "gender", entityKeyName: "Gender" },
  { title: "Code", name: "code", entityKeyName: "Code" },
  { title: "Email", name: "email", entityKeyName: "Email" },
  { title: "BVN", name: "bvn", entityKeyName: "BVN" },
  { title: "Address", name: "address", entityKeyName: "AddressLocation" },
  { title: "Category", name: "category", entityKeyName: "Category.Name" },
  { title: "Cluster", name: "cluster", entityKeyName: "Cluster.Name" },
  {
    title: "Cluster Code",
    name: "clusterCode",
    entityKeyName: "Cluster.Code",
  },
  {
    title: "Cluster Manager",
    name: "clusterManager",
    entityKeyName: "ClusterManager.Name",
  },
  {
    title: "ANM",
    name: "agentNetworkManager",
    entityKeyName: "NetworkManager.Name",
  },
  { title: "Location", name: "location", entityKeyName: "Location.Name" },
  { title: "LGA", name: "lga", entityKeyName: "TheLGA.Name" },
  { title: "State", name: "state", entityKeyName: "TheLGA.State.Name" },
  {
    title: "Date Created",
    name: "dateCreated",
    entityKeyName: "MobileAccount.DateCreated",
  },
  {
    title: "Date Activated",
    name: "dateActivated",
    entityKeyName: "MobileAccount.DateActivated",
    defaultValue: "Not yet activated",
  },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  {
    title: "Income Account",
    name: "incomeAccount",
    entityKeyName: "IncomeAccount",
  },
  {
    title: "Agent Share(%)",
    name: "agentShare",
    entityKeyName: "AgentPercentageShareOfNetworkCommission",
  },
]
const ERRORLIST = {
  email: undefined,
  gender: undefined,
  lastName: undefined,
  username: "",
  locationCode: "",
  otherNames: undefined,
  phoneNumber: undefined,
  personalPhoneNo: undefined,
  superAgentNumber: "",
  accountNumber: "",
  accountMode: undefined,
  bvn: "",
  lga: "",
  province: "",
  clusterId: undefined,
  categoryId: undefined,
  allowSubAgentControl: "",
  address: undefined,
  mCashFee: "",
  incomeAccount: "",
  depositLimit: "",
  withdrawalLimit: "",
  transferLimit: "",
  cummulativeWithdrawalLimit: "",
  cummulativeDepositLimit: "",
  cardTransactionLimit: "",
  staffID: "",
  businessName: "",
}
const SearchOptions = (
  startDate,
  endDate,
  categories,
  networkManagers,
  clusters,
  institutions
) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Last Name",
      id: "s-lastName",
      defaultValue: "",
      name: "lastName",
    },
    {
      type: "text",
      label: "Other Names",
      id: "s-otherNames",
      defaultValue: "",
      name: "otherNames",
    },
    {
      type: "text",
      label: "Code",
      id: "s-Code",
      defaultValue: "",
      name: "code",
    },
    {
      type: "text",
      label: "Account Number",
      id: "s-accountNumber",
      defaultValue: "",
      name: "accountNumber",
    },
    {
      type: "text",
      label: "Location Code",
      id: "s-locationCode",
      defaultValue: "",
      name: "locationCode",
    },
    {
      type: "text",
      label: "Super Agent Phone No",
      id: "s-superAgentPhoneNumber",
      defaultValue: "",
      name: "superAgentPhoneNumber",
    },
    {
      type: "tel",
      label: "Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "phoneNumber",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select -",
      data: BASESTATUSSTRING,
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "Agent Type",
      id: "s-agentType",
      defaultValue: "- Select Agent Type -",
      data: AGENTTYPE,
      name: "agentType",
      width: 3,
    },
    {
      type: "dropdown",
      label: "Network Manager",
      id: "networkManager",
      defaultValue: "- Select a Network Manager -",
      data: MapToDropDown(networkManagers),
      name: "networkManagerId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "Cluster",
      id: "cluster",
      defaultValue: "- Select a Cluster -",
      data: MapToDropDown(clusters),
      name: "clusterId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "Category",
      id: "categoryId",
      defaultValue: "- Select a Category -",
      data: MapToDropDown(categories),
      name: "categoryId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    }
  ]
  return options
}
const MapToRows = agent => {
  return {
    id: agent.ID,
    lastName: agent.LastName,
    otherNames: agent.OtherNames,
    parentOtherNames: agent.ParentOtherNames,
    phoneNumber: agent.PhoneNumber,
    email: agent.Email,
    gender: agent.Gender,
    status: {
      label: agent.IsActive ? "Active" : "Inactive",
      badgeClass: agent.IsActive ? "success" : "secondary",
    },
    terminalID: agent.TerminalID,
    institution : agent.InstitutionName,
    dateCreated: agent.MobileAccount?.DateCreated,
    code: agent.Code,
    address: agent.AddressLocation,
    institutionCode: agent.InstitutionCode,
    location: agent.Location?.Name,
    bvn: agent.BankVerificationNumber,
    state: agent.LGA?.State?.Name,
    lga: agent.LGA?.Name,
    accountNumber: agent.AccountNumber,
    type: agent.Type,
    clusterCode: agent.Cluster?.Code,
    category: agent.Category?.Name,
    cluster: agent.Cluster?.Name,
    agentNetworkManager: agent.NetworkManager?.Name,
    dateOfBirth: agent.DateOfBirth,
    domiciliaryBank: agent.DomiciliaryBank,
    transferLimit: agent.TransferLimit,
    withdrawalLimit: agent.WithdrawalLimit,
    postingLimit: agent.PostingLimit,
    cummulativeWithdrawalLimit: agent.CummulativeWithdrawalLimit,
    cummulativePostingLimit: agent.CummulativeDepositLimit,
    dateActivated:
      !agent.MobileAccount?.DateActivated ||
      moment(agent.MobileAccount?.DateActivated).isSameOrBefore(
        moment("1900-01-01T00:00:00")
      )
        ? "Not yet Activated"
        : agent.MobileAccount?.DateActivated,
    accountMode: agent.AccountMode,
    incomeAccount: agent.IncomeAccount,
    totalNumberOfCustomers: agent.TotalNumberOfCustomers,
    agentShare: agent.AgentPercentageShareOfNetworkCommission,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    LastName: params["lastName"],
    OtherNames: params["otherNames"],
    AccountNumber: params["accountNumber"],
    LocationCode: params["locationCode"],
    AgentNetworkManagerID: params["networkManagerId"],
    ClusterID: params["clusterId"],
    AgentCategoryID: params["categoryId"],
    AgentType: params["agentType"],
    Code: params["code"],
    SuperAgentPhoneNumber: params["superAgentPhoneNumber"],
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    PhoneNumber: params["phoneNumber"],
    TerminalID: params["TerminalID"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToModalState = agentDetail => {
  const modalData =  [
    {
      title: "",
      items: [
        {
          id: "accountMode",
          label: "Account Mode",
          name: "accountMode",
          value: agentDetail.AccountMode,
        },
      ],
    },
    {
      title: "Basic Data",
      items: [
        {
          id: "lastName",
          label: "Surname",
          name: "lastName",
          value: agentDetail.LastName,
        },
        {
          id: "otherNames",
          label: "Other Names",
          name: "otherNames",
          value: agentDetail.OtherNames,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: agentDetail.Code,
        },
        {
          id: "terminalID",
          label: "Terminal ID",
          name: "terminalID",
          value: agentDetail.TerminalID,
        },
        {
          id: "bankVerificationNumber",
          label: "Bank Verification Number",
          name: "bankVerificationNumber",
          value: agentDetail.BankVerificationNumber,
        },
        {
          id: "dateOfBirth",
          label: "Date Of Birth",
          name: "dateOfBirth",
          value: agentDetail.DateOfBirth,
        },
        {
          id: "cluster",
          label: "Cluster",
          name: "cluster",
          value: agentDetail.Cluster?.Name,
        },
        {
          id: "clusterCode",
          label: "Cluster Code",
          name: "clusterCode",
          value: agentDetail.Cluster?.Code,
        },
        {
          id: "dateCreated",
          label: "Date Created",
          name: "dateCreated",
          value: agentDetail.MobileAccount?.DateCreated,
        },
        {
          id: "dateActivated",
          label: "Date Activated",
          name: "dateActivated",
          value:
            !agentDetail.MobileAccount?.DateActivated ||
            moment(agentDetail.MobileAccount?.DateActivated).isSameOrBefore(
              moment("1900-01-01T00:00:00")
            )
              ? "Not yet Activated"
              : agentDetail.MobileAccount?.DateActivated,
        },
        {
          id: "superAgent",
          label: "Super Agent",
          name: "superAgent",
          value: agentDetail.TheSuperAgent?.PhoneNumber,
        },
        {
          id: "totalNoOfCustomers",
          label: "Total Number Of Customers",
          name: "totalNoOfCustomers",
          value: agentDetail.TotalNumberOfCustomers,
        },
      ],
    },
    {
      title: "Contact Me",
      items: [
        {
          id: "email",
          label: "Email",
          name: "email",
          value: agentDetail.Email,
        },
        {
          id: "phoneNumber",
          label: "Phone Number",
          name: "phoneNumber",
          value: agentDetail.PhoneNumber,
        },
        {
          id: "street",
          label: "Street Address",
          name: "address",
          value: agentDetail.AddressLocation,
        },
        {
          id: "location",
          label: "Location",
          name: "location",
          value: agentDetail.Location?.Name,
        },
        {
          id: "state",
          label: "State",
          name: "state",
          value: agentDetail.LGA?.State?.Name,
        },
      ],
    },
    {
      title: "Account Information",
      items: [
        {
          id: "accountNo",
          label: "Account Number",
          name: "accountNumber",
          value: agentDetail.AccountNumber,
        },
        {
          id: "domiciliaryBank",
          label: "Domiciliary Bank",
          name: "domiciliaryBank",
          value: GetInstitution().name,
        },
        {
          id: "depositLimit",
          label: "Deposit Limit",
          name: "depositLimit",
          value: agentDetail.PostingLimit,
        },
        {
          id: "transferLimit",
          label: "Transfer Limit",
          name: "transferLimit",
          value: agentDetail.TransferLimit,
        },
        {
          id: "withdrawalLimit",
          label: "Withdrawal Limit",
          name: "withdrawalLimit",
          value: agentDetail.WithdrawalLimit,
        },
        {
          id: "cummulativeDepositLimit",
          label: "Cummulative Deposit Limit",
          name: "cummulativeDepositLimit",
          value: agentDetail.CummulativeDepositLimit,
        },
        {
          id: "cummulativeWithdrawalLimit",
          label: "Cummulative Withdrawal Limit",
          name: "cummulativeWithdrawalLimit",
          value: agentDetail.CummulativeWithdrawalLimit,
        },
        {
          id: "cardTransactionLimit",
          label: "Card Transaction limit",
          name: "cardTransactionLimit",
          value: agentDetail.CardTransactionAmountLimit,
        },
      ],
    },
  ]

  const posAccountItems = agentDetail?.PayWithTransfer?.Accounts?.map(items => {
    return {
      id: items.AccountName,
      label: items.AccountName,
      name: items.AccountName,
      value: items.AccountNumber,
    }})

    if (agentDetail?.PayWithTransfer?.IsEligible){
      modalData.push({
        title: "Pay With Transfer Account Information",
        items: posAccountItems,
      })
    }
  return modalData
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapToDropDownFilter = data => {
  const filteredData = data.filter(item => {
    if (item.Features && item.Features instanceof Array) {
      return item.Features.some(
        feature =>
          feature.Code === "CWT" ||
          feature.Code === "PWT" ||
          feature.Code === "TWT"
      )
    }
    return false
  })

  return filteredData.map(item => ({
    id: item.ID,
    key: item.ID,
    value: item.Name,
    name: item.Name,
  }))
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.TerminalID,
      key: item.TerminalID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  ERRORLIST,
  MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownFilter,
  MapToDropDownTerminal,
}
