import { UserCategory } from "enums/user-category"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import { SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const ERRORLIST ={
  street: undefined,
  city: undefined,
  email: undefined,
  firstName: undefined,
  gender: "",
  hasApprovalRights: "",
  lastName: undefined,
  otherNames: "",
  phoneNumber: undefined,
  // staffId: undefined,
  state: undefined,
  userRoleId: undefined,
  scopeItemId: "",
  username: undefined,
}
 const MapToRows = r => {
  return {
    id: r.id,
    firstname: r.firstName,
    surname: r.lastName,
    othernames: r.otherName,
    username: r.username,
    name: r.lastName + " " + r.firstName + " " + (r.otherName ?? ""),
    phoneno: r.phoneNumber,
    gender: r.gender,
    email: r.email,
    role: r.userRole?.name,
    status: {
      label: r.status,
      badgeClass: r.status == "Active" ? "success" : "secondary",
    },
    // branch: r.branch?.name,
    staffID: r.staffId,
    scope: r.userRole?.scope,
    state: r.address?.state,
    city: r.address?.city,
    institution: r.InstitutionName,
    street: r.address?.street,
    hasApprovalRights: r.hasApprovalRights,
  }
}
 const SearchCriteria = (params, maxSize) => {
  const authUser = authHeader().AuthUser;
  const institutionId = authUser?.userCategory == UserCategory.MFB
  ? authUser.institutionId
  : GetInstitution().remoteInstitutionId;
  return JSON.stringify({
    InstitutionId: institutionId,
    LastName: params["lastName"],
    OtherNames: params["otherNames"],
    Name: params["name"],
    Email: params["email"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}

const SearchOptions = (startDate, endDate) => {

  let options = Object.assign({}, SEARCHOPTIONS);
  options.range.enabled =true;
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Name",
      id: "s-name",
      defaultValue: "",
      name: "name",
    },
    {
      type: "text",
      label: "Email",
      id: "s-email",
      defaultValue: "",
      name: "email",
    }
  ]
  return options
}
 const HEADERS = [
  { title: "Name", name: "name",entityKeyName:"Name" },
  { title: "Email", name: "email", entityKeyName:"Email" },
  { title: "Phone Number", name: "phoneno",entityKeyName:"PhoneNumber" },
  { title: "Scope", name: "scope",entityKeyName:"UserRole.Scope" },
  { title: "Status", name: "status", isBadge: true ,entityKeyName:"Status"},
  { title: "Action", name: "action", isAction: true },
]
export default {
  HEADERS,
  // MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  // MapToDropDown,
  ERRORLIST
}
