import React, { useRef } from "react"
import {
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputProps,
  FormGroup,
} from "reactstrap"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import composedWithGuard from "components/HOCs/WithGuard"
import withInputValidation from "components/HOCs/WithInputValidation"
export const BaseInput: React.FC<InputProps & DisposeProps> = props => {
   const {
 required,
    append = false,
    value,
    autoComplete,
    focus,
    defaultValue,
    placeholder,
    children,
    type,
    label,
    name,
    onChange,
    id,
   onBlur, 
    disabled,
    onKeyUp ,
    onMouseDown,
     title, min, max
  } = props

  const ref = useRef(null);
  return (
    <>
      <InputGroup>
        {!append ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label for={id}>{label}</Label> {children}
          </div>
        ) : (
          <InputGroupAddon addonType="append">
            <Label for={id}>{label}</Label>
          </InputGroupAddon>
        )}


        <Input
          ref={ref}
          type={type}
          className="form-control mb-2 "
          id={id}
          step={"any"}
          value={value}
          onChange={onChange}
          onKeyUp={onKeyUp}
          required={required}
          defaultValue = {defaultValue}
          name={name}
          onMouseDown={onMouseDown}
          onBlur={onBlur}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={focus}
          min={min}
          max={max}
          title={title}
        />
        
      </InputGroup>
    </>)
  
}
export const GuardedBaseInput = composedWithGuard(BaseInput,"Input needs to be supplied","Input needs to be supplied")
const ValidatedGuardedInput = withInputValidation(GuardedBaseInput);

export const ClusterInput: React.FC<InputProps & DisposeProps> = props => {
  const {
  name,
 onHandleChange,
 onChange
 } = props

const handleOnChange = (e) => {
  if(onHandleChange) onHandleChange(name,e.target.value);
  if(onChange) onChange(e);
 
}
 return (
   <FormGroup>
     <ValidatedGuardedInput
      {...props}
      onChange={handleOnChange}
      />
   </FormGroup>
 )
}
export default withDispose(ClusterInput);

