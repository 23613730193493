import React, { useState, useEffect, useMemo, Component } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { Card, Row, Col, CardBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import { useGraphql } from "hooks/useGraphql"
import Loading from "components/Common/Loading"
import withDispose from "components/HOCs/WithDispose"
import { GetInstitution } from "helpers/authentication/institution"
const ServiceQualityCard = props => {
  const { field, startDateStr, trxType, endDateStr, color, code } = props
  const [endDate, setEndDate] = useState(endDateStr)
  const [startDate, setStartDate] = useState(startDateStr)

  const query = useMemo(() => {
    return `
            {
              analytics {
                failed: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: FAILED
                    startDate: "${moment(startDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    endDate: "${moment(endDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    institutionCode: "${code}"
                  }
                ) {
                  cumulative
                }
                successful: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: SUCCESSFUL
                    startDate: "${moment(startDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    endDate: "${moment(endDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    institutionCode: "${code}"
                  }
                ) {
                  cumulative
                }
                pending: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: PENDING
                    startDate: "${moment(startDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    endDate: "${moment(endDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    institutionCode: "${code}"
                  }
                ) {
                  cumulative
                }
                reversed: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: REVERSED
                    startDate: "${moment(startDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    endDate: "${moment(endDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    institutionCode: "${code}"
                  }
                ) {
                  cumulative
                }
                thirdPartyFailure: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: THIRDPARTYFAILURE
                    startDate: "${moment(startDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    endDate: "${moment(endDateStr).format(
      "YYYY-MM-DDTHH:mm:ss"
    )}"
                    institutionCode: "${code}"
                  }
                ) {
                  cumulative
                }
              }
            }
            `
  }, [trxType, field, endDate, startDate, code])

  let [data, { loading }, setData, setLoading] = useGraphql(query, {}, [query])

  useEffect(() => {
    setStartDate(endDateStr)
    setEndDate(startDateStr)
  }, [endDateStr, startDateStr])

  

  const successRate = useMemo(() => {
    let rate = 0
    let values = data?.analytics
    if (values) {
      let successful = values.successful.cumulative
      let failed = values.failed.cumulative
      let pending = values.pending.cumulative
      let reversed = values.reversed.cumulative
      let thirdPartyFailure = values.thirdPartyFailure.cumulative
      let total = successful + failed + pending;
      if (trxType == "POSAGENTCASHOUT") total = successful + pending
      rate = Math.round((successful / total) * 100, 2)

    }
    return rate;
  }, [useGraphql, data])

  useEffect(() => {
    setInterval(reload, 300000)
  }, [])


  function reload() {
    graphqlQuery(query, {}, [query]).then(response => {
      setData(response)
    })
  }

  return (
    <Row style={{ textAlign: "center" }}>
      <Col lg={12}>
        {!loading ? (
          <ApexRadial label={"Success Rate"} color={mapColor(color)} value={isNaN(successRate) ? 0 : successRate} />
        ) : (
          <Card>
            <CardBody>
              <Loading></Loading>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  )
}

ServiceQualityCard.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  currency: PropTypes.bool,
  height: PropTypes.number,
}

import ReactApexChart from "react-apexcharts"
import { mapColor } from "helpers/utility"
import { graphqlQuery } from "helpers/api_helper"

const ApexRadial = (props) => {
  const [series, setSeries] = useState([0]);
  const [options, setOptions] = useState({
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: "13px",
            color: void 0,
            offsetY: 60,
          },
          value: {
            offsetY: 22,
            fontSize: "16px",
            color: void 0,
            formatter: (e) => {
              return e + "%"
            },
          },
        },
      },
    },
    colors: [props.color],
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        shadeIntensity: 0.15,
        inverseColors: !1,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91],
      },
    },
    stroke: {
      dashArray: 4,
    },
    labels: [props.label],
  })
  useEffect(() => {
    let opts = Object.assign({}, options);
    if (props.value) {
      setSeries([props.value]);
    }
    if (props.color) {
      opts.colors = [props.color];
    }
    if (props.label) {
      opts.labels = [props.label];
    }
    setOptions(opts)
  }, [props])
  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height="250"
      />
    </React.Fragment>
  )

}



export default withTranslation()(withDispose(ServiceQualityCard))
