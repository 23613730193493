import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import clusterian from "../../../assets/images/icons/clusterian.png"
import ClusterForm from "components/Common/Form"
import ClusterButton from "components/Common/Button/Button"
import { Validators } from "helpers/validators"
import withFormValidation from "components/HOCs/WithFormValidation"
const UpgradeToSuperAgentModal = props => {
  const {
    open,
    onChangeModal,
    onUpgrade,
    agent,
    loading,
    myError,
    setErrorList,
    errorList,
  } = props
  const [isOpen, setOpen] = useState(open)
  const [superAgent, setSuperAgent] = useState({})
  const handleOnChange = (key, value) => {
    let u = superAgent
    u[key] = value
    setSuperAgent(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()

    onUpgrade({
      ...superAgent,
      id: agent.ID,
      lastName: agent.LastName,
      OtherNames: agent.OtherNames,
      phoneNumber: agent.PhoneNumber,
    })
  }

  const changeOpen = status => {
    setOpen(status)
    onChangeModal(status)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        changeOpen(!isOpen)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            changeOpen(!isOpen)
          }}
        >
          Upgrade To Super Agent
        </ModalHeader>
        <ModalBody>
          <ClusterForm handleSubmit={handleValidSubmit}>
            <React.Fragment>
              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        defaultValue={agent?.LastName + " " + agent?.OtherNames}
                        disabled
                        label={"Name"}
                        id={"m-name"}
                        type="text"
                        required={true}
                      ></ClusterInput>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        defaultValue={agent?.PhoneNumber}
                        disabled
                        label={"Phone Number"}
                        id={"m-phoneno"}
                        type="text"
                        required={true}
                      ></ClusterInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        focus
                        onError={err =>
                          setErrorList({ ...errorList, agencyName: err })
                        }
                        label={"Agency Name"}
                        id={"agencyName"}
                        name={"agencyName"}
                        validators={[
                          Validators.minLength(
                            3,
                            "Minimum number of characters is 3"
                          ),
                          Validators.maxLength(
                            50,
                            "Maximum number of characters is 50"
                          ),
                        ]}
                        type="text"
                        required={true}
                        onHandleChange={(key, value) =>
                          handleOnChange(key, value)
                        }
                      ></ClusterInput>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        onError={err =>
                          setErrorList({
                            ...errorList,
                            transactionAccount: err,
                          })
                        }
                        label={"Transaction Account"}
                        id={"transactionAccount"}
                        name={"transactionAccount"}
                        validators={[
                          Validators.accountNumber([
                            "Account number should contain only numbers",
                            "Account number must be 10 digits",
                          ]),
                        ]}
                        type="text"
                        required={true}
                        onHandleChange={(key, value) =>
                          handleOnChange(key, value)
                        }
                      ></ClusterInput>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        onError={err =>
                          setErrorList({ ...errorList, incomeAccount: err })
                        }
                        label={"Income Account"}
                        id={"incomeAccount"}
                        name={"incomeAccount"}
                        validators={[
                          Validators.accountNumber([
                            "Account number should contain only numbers",
                            "Account number must be 10 digits",
                          ]),
                        ]}
                        type="text"
                        required={true}
                        onHandleChange={(key, value) =>
                          handleOnChange(key, value)
                        }
                      ></ClusterInput>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
            <Row style={{ paddingBottom: "50px" }}>
              <Col lg={12}>
                <ClusterButton
                  loading={loading}
                  disabled={myError != "" ? true : false}
                  color={"primary"}
                  addon={"bx bx-save"}
                >
                  Upgrade
                </ClusterButton>
              </Col>
            </Row>
          </ClusterForm>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </div>
    </Modal>
  )
}

UpgradeToSuperAgentModal.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onChangeModal: PropTypes.func,
  onUpgrade: PropTypes.func,
  agent: PropTypes.object,
}

export default withFormValidation(UpgradeToSuperAgentModal)
