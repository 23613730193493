import { TransactionStatus } from "enums/transaction-status"
import { Utils } from "helpers/utility"
import { GetInstitution } from "helpers/authentication/institution"
import {
  Format,
  SEARCHOPTIONS,
} from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Collection Status", name: "status", entityKeyName: "CollectionStatus", isBadge: true },
  { title: "Date", name: "date", entityKeyName: "DateUpdated" },
  {
    title: "Agent Phone Number",
    name: "agentPhoneNumber",
    entityKeyName: "Transaction.FromPhoneNumber",
  },
  {
    title: "Agent Account Number",
    name: "agentAccountNumber",
    entityKeyName: "Transaction.FromAccount",
  },
  {
    title: "Customer ID",
    name: "customerID",
    entityKeyName: "CustomerID",
  },
  {
    title: "Biller Name",
    name: "biller",
    entityKeyName: "CategoryName",
  },
  { title: "Amount", name: "amount", entityKeyName: "Amount" },
  {
    title: "Biller Item",
    name: "billerItem",
    entityKeyName: "PaymentItemName",
  },
  {
    title: "Collection Reference",
    name: "collectionReference",
    entityKeyName: "CollectionReference",
  },
  {
    title: "Payment Reference",
    name: "paymentReference",
    entityKeyName: "PaymentReference",
  },
  {
    title: "Response Description",
    name: "response",
    entityKeyName: "Transaction.StatusDetails",
  }
]

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  
 options.fields = [
   {
     type: "text",
     label: "Collection Reference",
     id: "s-collectionReference",
     defaultValue: "",
     name: "collectionReference",
   },
   {
     type: "dropdown",
     label: "Status",
     id: "status",
     defaultValue: "- Select -",
     data: Utils.Enums.toOptions(TransactionStatus),
     name: "collectionStatus",
     width: 3,
   },
   {
     type: "text",
     label: "Agent Phone Number",
     id: "s-agentPhoneNumber",
     defaultValue: "",
     name: "agentPhoneNumber",
   },
   {
     type: "dropdown",
     label: "All Institutions",
     id: "institutionCode",
     defaultValue: "- Select -",
     data: MapToDropDown(institutions),
     name: "institutionCode",
     width: 3,
     isRemote: GetInstitution().code == "*"
   },
 ]
  
  return options
}
const MapToRows = collection => {
  return {
    id: collection.ID,
    institution: collection.InstitutionName,
    status: {
      label: collection.CollectionStatus,
      badgeClass:
        collection.CollectionStatus == "Successful"
          ? "success"
          : collection.CollectionStatus == "Failed"
          ? "danger"
          : "secondary",
    },

    date: Format.date(collection.DateUpdated),

    collectionReference: collection.CollectionReference,

    amount: Format.currency(collection.Amount),
    billerItem: collection.PaymentItemName,
    biller: collection.CategoryName,
    customerID: collection.CustomerID,
    agentPhoneNumber: collection.Transaction?.FromPhoneNumber,
    agentAccountNumber: collection.Transaction?.FromAccount,
    response: collection.Transaction?.StatusDetails,
    paymentReference: collection.PaymentReference,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentPhoneNumber: params["agentPhoneNumber"],
    CollectionReference: params["collectionReference"],
    CollectionStatus: params["collectionStatus"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
