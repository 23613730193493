import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Row, Col, Alert, Card, CardBody, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import cluster from "../..//assets/images/logo/dark/36x36.png"
import ClusterInput from "components/Common/Input/Input"
import ClusterButton from "components/Common/Button/Button"
import { Validators } from "helpers/validators"

const ForgetPasswordPage = props => {
  const [errorList, setErrorList] = useState({
    institutionCode: "",
    email: undefined,
  })
  const [myError, setMyError] = useState()
  const [apiError, setError] = useState()
  const [details, setDetails] = useState({})
  const handleValidSubmit = event => {
    event.preventDefault()
    props.userForgetPassword(details, props.history)
  }
  useEffect(() => {
    setError(undefined)
    if (props.forgetError) {
      setError(Validators.extractError(props.forgetError))
    }
  }, [props])

  useEffect(() => {
    setMyError(Validators.validateForm(errorList))
    // setLoading(false)
  }, [errorList])
  const handleOnChange = (key, value) => {
    let u = details
    u[key] = value
    setDetails(u)
  }
  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h3 className="text-primary">Welcome Back !</h3>
                        <p>Sign in to continue to Cluster.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <div className="avatar-title rounded-circle bg-light p-2">
                          <img
                            src={cluster}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {apiError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {apiError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <form
                      className="form-horizontal mt-4"
                      noValidate
                      onSubmit={e => handleValidSubmit(e)}
                    >
                      <ClusterInput
                        focus={true}
                        onError={err =>
                          setErrorList({ ...errorList, institutionCode: err })
                        }
                        placeholder={"Enter institution code"}
                        label={"Institution Code"}
                        id={"institutionCode"}
                        name={"institutionCode"}
                        validators={[
                          Validators.institutionCode([
                            "Only number allowed",
                            "Number of allowed digits is 6",
                          ]),
                        ]}
                        type="text"
                        required={false}
                        onHandleChange={(key, value) =>
                          handleOnChange(key, value)
                        }
                      ></ClusterInput>

                      <ClusterInput
                        onError={err =>
                          setErrorList({ ...errorList, email: err })
                        }
                        placeholder={"Enter email address"}
                        label={"Email"}
                        id={"email"}
                        name={"email"}
                        validators={[
                          Validators.email("Your email address is invalid"),
                        ]}
                        type="email"
                        required={true}
                        onHandleChange={(key, value) =>
                          handleOnChange(key, value)
                        }
                      ></ClusterInput>

                      <Row className="form-group">
                        <Col className="text-right">
                          <ClusterButton
                            loading={props.loading}
                            disabled={myError != "" ? true : false}
                            color={"primary"}
                            addon={"bx bx-save"}
                          >
                            Reset
                          </ClusterButton>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Go back to{" "}
                  <Link to="login" className="font-weight-medium text-primary">
                    Login
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Cluster.Africa Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Cluster
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func,
  loading: PropTypes.bool
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg, loading, editLoading } = state.ForgetPassword
  return {
    forgetError: Validators.extractError(forgetError),
    forgetSuccessMsg,
    loading
  }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
