import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { toTitleCase } from "helpers/utility"
const Breadcrumb: React.FC<any> = props => {
  const { match, prepend, noCrumb } = props
  const [goBack, setGoBack] = useState(false)
  useEffect(() => {
    const id = match?.params.id
    setGoBack(id && id > 0)
  }, [match])
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center justify-content-between">
          <div style={{ display: "flex", alignItems: "center" }}>
            {prepend}{" "}
            {goBack ? (
              <i
                className={"bx bx-arrow-back"}
                style={{
                  marginRight: "10px",
                  fontSize: "2em",
                  cursor: "pointer",
                }}
                color={"secondary"}
                onClick={e => {
                  e.preventDefault()
                  props.history.goBack()
                }}
              ></i>
            ) : (
              <></>
            )}
            <h4 className="mb-0 font-size-18">
              {toTitleCase(
                props.breadcrumbItem && goBack
                  ? props.breadcrumbItem.replace("Create", "Update")
                  : props.breadcrumbItem
              )}{" "}
            </h4>
            {/* {props.image ? (
              <div style={{ width: "25px", marginLeft: "10px" }}>
                <img src={props.image} />
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )} */}
            {props.children}
          </div>
          {noCrumb ? null : (
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <Link to="#">{props.title}</Link>
                </BreadcrumbItem>
                <BreadcrumbItem active>
                  <Link to="#">
                    {props.breadcrumbItem && goBack
                      ? props.breadcrumbItem.replace("Create", "Update")
                      : props.breadcrumbItem}
                  </Link>
                </BreadcrumbItem>
              </ol>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.any,
}

export default withRouter(Breadcrumb)
