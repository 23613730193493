import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  TabContent,
  NavLink,
  NavItem,
  Nav,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap"

import classnames from "classnames"
import withDispose from "components/HOCs/WithDispose"
const ClusterTabPanel:React.FC<any> = props => {
  let { tabs, title, children, justified } = props
  const [activeTabJustify, setactiveTabJustify] = useState("0")

  if (!tabs) tabs = []
  function toggleCustomJustified(tab) {
    if (activeTabJustify !== tab) {
      setactiveTabJustify(tab)
    }
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>
          <small>{title}</small>
        </CardTitle>
        <Nav
          tabs
          className={`nav-tabs-custom ${justified ? "nav-justified" : ""}`}
        >
          {tabs.map((tab, key) => (
            <NavItem key={`_navitem_${key}`}>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTabJustify === `${tab.id}`,
                })}
                onClick={() => {
                  toggleCustomJustified(`${tab.id}`)
                }}
              >
                <span className="d-none d-sm-block">{tab.label}</span>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <TabContent activeTab={activeTabJustify}>{children}</TabContent>
      </CardBody>
    </Card>
  )
}

ClusterTabPanel.propTypes = {
  tabs: PropTypes.array,
  title: PropTypes.string,
}
export default withDispose(ClusterTabPanel)
