import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Util } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import {
  institutionPurge,
  createInstitution,
  getAllInstitutions,
  getInstitutionDetail,
  updateInstitution,
  getStates,
  getFeatures,
  getFeaturesByInstitution,
  getAllFeatures,
} from "store/actions"
import InstitutionForm from "./form"
import InstitutionLogic from "../institution"
import withDispose from "components/HOCs/WithDispose"
import GuardedPage from "components/HOCs/GuardedPage"
import { EDIT_INSTITUTION } from "../permission"
import useSWR from "swr"
const fetcher = url => {
  return get(url, {}, true)
}

let featureIds = [];
import { get } from "helpers/api_helper"
import { GET_ALL_INSTITUTIONS } from "helpers/url_helper"
import { CREATE_INSTITUTION } from "store/institution/actionTypes"
import { Institution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
const CreateInstitution = props => {
  const {
    match,
    onCreateInstitution,
    onUpdateInstitution,
    onGetAllInstitutions,
    institutions,
    loading,
    success,
    features,
    institutionFeatures,
    featuresLoading,
    onGetAllFeatures,
    onGetFeatures,
    onGetInstitutionDetail,
    editLoading,
    error,
    institutionDetail,
    onGetStates,
    states,
    statesLoading
  } = props
  const { ERRORLIST } = InstitutionLogic
  const [institutionDetails, setInstitutionDetails] = useState()
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [isRotating, setIsRotating] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [featureList, setFeatureList] = useState([]);
  const [institution, setInstitution] = useState({ User: {} }) //data from form
  const allInstitutions = useSWR(GET_ALL_INSTITUTIONS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  useEffect(() => {
    getInstitution()
    onGetStates()
    onGetAllFeatures();
  }, [])

  useEffect(() => {
    getInstitution()
  }, [match])

  const handleOnChange = (key, value) => {
    let u = institution
    if (key.includes("User.")) {
      u.User[key.replace("User.", "")] = value
    } else {
      u[key] = value;
    }
    setInstitution(u)
  }
  const handleValidSubmit = values => {
    if (!isUpdate) {
      onCreateInstitution(values)
    } else {
      onUpdateInstitution(values)
    }
  }

  const getInstitution = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = institutions.find(c => c.id == id)
      if (!u) {
        if (Utils.Object.isEmpty(institutionDetail)) {
          onGetInstitutionDetail(id)
        } else {
          setInstitutionDetails(institutionDetail)
        }
        return
      }

      setInstitutionDetails(u)
    }
    else {
      setIsUpdate(false)
      if (!institutions || institutions.length == 0) {
        onGetAllInstitutions()
      }
    }
  }
  const onFeatureToggle = (groupName, itemName, itemValue, isChecked: boolean) => {
    const isParent = groupName == itemName;
    const institutionFeatures = (institutionDetail?.Features ?? []).map(feature => feature.ID);
    if (!institutionFeatures.some(f => featureIds.includes(f))) {
      featureIds = [...featureIds, ...institutionFeatures];
    }
    if (!isParent) {
      const featureAlreadyAssigned = featureIds.find(item => item == itemValue);
      if (!featureAlreadyAssigned) featureIds.push(itemValue)
      else {
        featureIds = [...Object.assign([], featureIds.filter(item => item !== itemValue))];
      }
    }
    setFeatureList([...featureIds]);
    handleOnChange("Features", featureIds);
  }
  return (
    <GuardedPage permissions={[CREATE_INSTITUTION, EDIT_INSTITUTION]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Institutions"}
              image={clusterian}
              breadcrumbItem={isUpdate ? "Update Institution" : "Create Institution"}
            />

            <InstitutionForm
              redirectUrl={isUpdate?"/view-institutions":"/service-configuration"}
              success={success}
              institutions={(allInstitutions?.data as any)?.Data ?? []}
              error={error}
              isUpdate={isUpdate}
              loading={loading}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              details={institutionDetail}
              states={states}
              statesLoading={statesLoading}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
              features={features}
              featuresLoading={featuresLoading}
              onFeatureToggle={onFeatureToggle}
            ></InstitutionForm>
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateInstitution.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateInstitution: PropTypes.func,
  onGetInstitutionDetail: PropTypes.func,
  onPurge: PropTypes.func,
  onBack: PropTypes.func,
  institutionDetail: PropTypes.object,
  institutions: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onGetAllInstitutions: PropTypes.func,
  onGetStates: PropTypes.func,
  onGetAllFeatures: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({
  institutions: {
    institutions,
    error,
    success,
    institutionDetail,
    totalCount,
    editLoading,
    loading,
  },
  features,
  misc: { states, statesLoading },
}) => {
  return {
    institutions: [...institutions],
    error,
    institutionDetail,
    totalCount,
    loading,
    success,
    editLoading,
    features: features.features,
    featuresLoading: features.loading,
    states, statesLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(institutionPurge()),
  onCreateInstitution: user => dispatch(createInstitution(user)),
  onGetInstitutionDetail: id => dispatch(getInstitutionDetail(id)),
  onGetAllFeatures: () => dispatch(getAllFeatures()),
  onGetFeatures: (institutionCode) => dispatch(getFeaturesByInstitution(institutionCode)),
  onGetAllInstitutions: () => dispatch(getAllInstitutions()),
  onUpdateInstitution: institution => dispatch(updateInstitution(institution)),
  onGetStates: () => dispatch(getStates()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateInstitution))))
