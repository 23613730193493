import { SettlementStatus } from "enums/settlement-status"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import {
  Format,
  SEARCHOPTIONS,
} from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Status", name: "status", entityKeyName: "Status", isBadge: true },
  { title: "Route", name: "route", entityKeyName: "Route" },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  {
    title: "Agent Phone Number",
    name: "agentPhoneNumber",
    entityKeyName: "AgentPhoneNumber",
  },
  {
    title: "Agent Code",
    name: "agentCode",
    entityKeyName: "AgentCode",
  },
  { title: "Super Agent", name: "superAgent", entityKeyName: "SuperAgent" },
  {
    title: "ANM",
    name: "agentNetworkManager",
    entityKeyName: "AgentNetworkManager",
  },
  { title: "Cluster Code", name: "clusterCode", entityKeyName: "ClusterCode" },
  { title: "Location", name: "agentLocation", entityKeyName: "AgentLocation" },
  {
    title: "Cluster Manager",
    name: "clusterManager",
    entityKeyName: "AgentClusterManager",
  },
  {
    title: "Agent Category",
    name: "agentCategory",
    entityKeyName: "AgentCategory",
  },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  {
    title: "Transaction Reference",
    name: "transactionReference",
    entityKeyName: "TransactionRef",
  },
  {
    title: "TransactionAmount",
    name: "transactionAmount",
    entityKeyName: "Amount",
  },
  { title: "Agent Share", name: "agentShare", entityKeyName: "AgentShare" },
  { title: "Bank Share", name: "bankShare", entityKeyName: "BankShare" },
  {
    title: "Appzone Share",
    name: "appzoneShare",
    entityKeyName: "AppzoneShare",
  },
  { title: "Date Logged", name: "dateLogged", entityKeyName: "DateLogged" }
]

const SearchOptions = (startDate, endDate, clusterManagers, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  const baseFields = [
    {
      type: "text",
      label: "Super Agent Phone No",
      id: "s-superAgentPhoneNumber",
      defaultValue: "",
      name: "superAgentPhoneNumber",
    },
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "phoneNumber",
    },
    {
      type: "text",
      label: "Terminal ID",
      id: "s-terminalID",
      defaultValue: "",
      name: "terminalID",
    },

    {
      type: "dropdown",
      label: "Cluster Manager",
      id: "clusterManager",
      defaultValue: "- Select a Cluster Manager -",
      data: MapToDropDown(clusterManagers),
      name: "clusterManagerId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]

  if (authHeader().AuthUser.viewScope != 'Agent') {
    options.fields = baseFields;
  } else {
    options.fields = [];
  }
  return options
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    agentName: transaction.AgentName,
    superAgent: transaction.SuperAgent,
    agentPhoneNumber: transaction.AgentPhoneNumber,
    institution: transaction.InstitutionName,
    status: {
      label: Utils.Strings.camelPad(transaction.Status),
      badgeClass:
        transaction.Status == SettlementStatus.SuccessfulSettlement
          ? "success"
          : "secondary",
    },
    terminalID: transaction.TerminalID,
    dateLogged: transaction.DateLogged,
    appzoneShare: Format.currency(transaction.AppzoneShare),
    bankShare: Format.currency(transaction.BankShare),
    agentShare: Format.currency(transaction.AgentShare),
    agentLocation: transaction.AgentLocation,
    transactionReference: transaction.TransactionRef,
    agentCategory: transaction.AgentCategory,
    transactionAmount: Format.currency(transaction.Amount),
    clusterCode: transaction.ClusterCode,
    agentCode: transaction.AgentCode,
    clusterManager: transaction.AgentClusterManager,
    agentNetworkManager: transaction.AgentClusterManager,
    route: transaction.Route,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    ClusterManagerID: params["clusterManagerId"],
    SuperAgentPhoneNumber: params["superAgentPhoneNumber"],
    AgentPhoneNumber: params["phoneNumber"],
    TerminalID: params["terminalID"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
