import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"

import {
  getBinRoutesSuccess,
  getBinRoutesFail,
  getRoutesSuccess,
  getRoutesFail,
  getBinsSuccess,
  getBinsFail,
  binRoutesApiError,
  postBinRoutesSuccess,
  binRoutePurgeSuccess,
  removeBinRouteSuccess,
  getBinRouteDetailsFail,
  getBinRouteDetailsSuccess
} from "./actions"

import {
  configureBinRoutes,
  getBINRoutes,
  getBins,
  getRoutes,
 removeBinRoute,
 getBINRouteDetails
} from "helpers/backend_helper"
import { BIN_ROUTE_PURGE, CONFIGURE_BIN_ROUTE, GET_BINS, GET_BIN_ROUTES, GET_BIN_ROUTE_DETAILS, GET_ROUTES, REMOVE_BIN_ROUTE } from "./actionTypes"

function* fetchBinRoutes({ params }) {
  try {
    const response = yield call(getBINRoutes, JSON.parse(params))
    yield put(getBinRoutesSuccess(response))
  } catch (error) {
    console.debug("Fetching BinRoutes Error", error)
    yield put(getBinRoutesFail(error))
  }
}
function* fetchBinRouteDetails({ params }) {
  try {
    const response = yield call(getBINRouteDetails, JSON.parse(params))
    yield put(getBinRouteDetailsSuccess(response))
  } catch (error) {
    console.debug("Fetching BinRoutes Details Error", error)
    yield put(getBinRouteDetailsFail(error))
  }
}

function* fetchRoutes() {
  try {
    const response = yield call(getRoutes)
    yield put(getRoutesSuccess(response))
  } catch (error) {
    console.debug("Fetching Clusters Error", error)
    yield put(getRoutesFail(error))
  }
}

function* fetchBins({ id }) {
  try {
    const response = yield call(getBins, id);
    yield put(getBinsSuccess(response))
  } catch (error) {
    yield put(getBinsFail(error))
  }
}


function* setupBinRoutes({ payload: { binRoutes } }) {
  try {

    const response = yield call(configureBinRoutes, [ ...JSON.parse(JSON.stringify(binRoutes)) ])
    yield put(postBinRoutesSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(binRoutesApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* remove({ payload: { routeDetailId } }) {
  try {

    const response = yield call(removeBinRoute, routeDetailId)

    yield put(removeBinRouteSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(binRoutesApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doPurge() {
  yield put(binRoutePurgeSuccess())
}

function* routesSaga() {
  yield takeLeading(GET_BIN_ROUTES, fetchBinRoutes)
  yield takeLeading(GET_BINS, fetchBins)
  yield takeLeading(GET_ROUTES, fetchRoutes)
  yield takeEvery(BIN_ROUTE_PURGE, doPurge)
  yield takeLeading(REMOVE_BIN_ROUTE, remove)
  yield takeLeading(CONFIGURE_BIN_ROUTE, setupBinRoutes)
  yield takeLeading(GET_BIN_ROUTE_DETAILS,fetchBinRouteDetails)
}

export default routesSaga
