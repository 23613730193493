export enum TransactionTypes {

    BalanceEnquiry = 1,
    MiniStatement = 2,
    Recharge = 3,
    BillsPayment = 4,
    CashIn = 5,
    CashOut = 6,
    PINChange = 7,
    PINReset = 8,
    Registration = 9,
    FundsTransferCommercialBank = 10,
    FundsTransferCashIn = 11,
    FundsTransferCashOut = 12,
    InwardsTransfer = 13,
    AccountActivation = 14,
    LocalFundsTransfer = 15,
    SetDefaultAccount = 16,
    LoanRequest = 17,
    CardLinking = 18,
    AgentToAgentFundsTransfer = 19,
    KiaKiaToKiaKia = 20,
    KiaKiaToSterlingBank = 21,
    KiaKiaToOtherBanks = 22,
    AccountLiquidation = 24,
    BVNUpdate = 25,
    MFBToOtherBanks = 26,
    BetaHealthRegistration = 27,
    BetaHealthSubPayment = 28,
    POSAgentCashOut = 29,
    CollectionPayment = 32,
    CollectionPaymentReference = 33,
    OffshoreTransfer = 34,
    WalletTransfer = 35,
    AgentLoanRequest = 37,
    CrossBankTokenWithdrawal = 39,
    PayWithTransfer=40,
    CardTransfer=41
}

export {}