
import { BIN_ROUTE_PURGE_SUCCESS, CONFIGURE_BIN_ROUTE, CONFIGURE_BIN_ROUTE_FAIL, CONFIGURE_BIN_ROUTE_SUCCESS, GET_BINS, GET_BINS_SUCCESS, GET_BIN_ROUTES, GET_BIN_ROUTES_FAIL, GET_BIN_ROUTES_SUCCESS, GET_BIN_ROUTE_DETAILS, GET_BIN_ROUTE_DETAILS_FAIL, GET_BIN_ROUTE_DETAILS_SUCCESS, GET_ROUTES, GET_ROUTES_FAIL, GET_ROUTES_SUCCESS, REMOVE_BIN_ROUTE, REMOVE_BIN_ROUTE_SUCCESS } from "./actionTypes"

export type RoutingDetail = {
  SwitchingRouteID: number,
  MinimumAmount: number,
  MaximumAmount: number,
  AgentCategoryID?: number,
  BIN?: string
}
export type BINRoute = {
  FilterValue : String,
  FilterBy : String,
  Routes: RoutingDetail[]
}
type ROUTE_STATE = {
  routes: any[],
  routeDetails: RoutingDetail[],
  binRoutes: any[],
  bins: any[],
  error?: any,
  success?: string,
  totalCount: number,
  loading: boolean,
  editLoading: boolean,
  statusChanged?: boolean
}
const INIT_STATE: ROUTE_STATE = {
  routes: [],
  binRoutes: [],
  routeDetails: [],
  bins: [],
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined
}

const Routes = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BIN_ROUTE_DETAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_BIN_ROUTE_DETAILS_SUCCESS:
      return {
        ...state,
        routeDetails: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_BIN_ROUTE_DETAILS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_BIN_ROUTES_SUCCESS:
      return {
        ...state,
        binRoutes: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_BINS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_BIN_ROUTES:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_ROUTES:
      return {
        ...state,
        loading: true,
      }
    case GET_ROUTES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_ROUTES_SUCCESS:
      return {
        ...state,
        routes: action.payload?.Data,
        loading: false,
      }
    case GET_BINS_SUCCESS:
      return {
        ...state,
        bins: action.payload?.Data,
        loading: false,
      }

    case GET_BIN_ROUTES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case CONFIGURE_BIN_ROUTE_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
        loading: false
      }
    case REMOVE_BIN_ROUTE:
      state = {
        ...state,
        editLoading: true,
        loading: false,
        error: "",
        success: ""
      }
      break
    case CONFIGURE_BIN_ROUTE:
      return {
        ...state,
        editLoading: true,
        loading: false,
        error: "",
        success: ""
      }

    case CONFIGURE_BIN_ROUTE_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break


    case REMOVE_BIN_ROUTE_SUCCESS:

      state = {
        ...state,
        binRoutes: state.binRoutes.map(r => {
          r.Routes = r.Routes.filter(d => {
            return d.ID != action.payload.Data?.ID
          })
          return r;
        }),
        success: action.payload?.Message,
        editLoading: false,
        loading: false,
        error: ""
      }
      break


    case BIN_ROUTE_PURGE_SUCCESS:

      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Routes
