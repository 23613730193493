

import React, { useState } from "react"

import { Col, Collapse, Row } from "reactstrap"
import ClusterCheckBox from "components/Common/CheckBox"
import { ConfigurationFormProps } from "."

const CommissionForm: React.FC<ConfigurationFormProps> = props => {
    const {
        values,
        handleChange,
        errors,
        handleBlur,
    } = props
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [transform, setTransform] = useState<string>();

    return <React.Fragment>
        <div
            className={`title p-2 accordion-header box-shadow ${isOpen ? "active" : ""}`}
            style={{ borderRadius: "5px" }}
            onClick={() => {
                setIsOpen(!isOpen)

                if (!isOpen) {
                    setTransform("rotateZ(90deg)")
                } else {
                    setTransform("rotateZ(0deg)")
                }
            }}
        ><Row>
                <Col lg={11} md={11} xs={11}>
                    <h3>Commission Sharing</h3>
                </Col>
                <Col
                    lg={1} md={1} xs={1}
                    className="pl-3"
                    style={{ display: "flex", alignItems: "center" }}
                >
                    <i className={`bx bxs-right-arrow text-secondary`} style={{ transform: transform }} />

                </Col>
            </Row>
        </div>
        <Collapse className="p-4" isOpen={isOpen}>

            <Row>
                <Col lg={3} md={3} sm={6} className={"mb-3"}>
                    <ClusterCheckBox
                        checked={values?.IsActive ?? false}
                        onHandleChange={(name, value) => { handleChange("IsActive", value); }}
                        label={values?.IsActive ? "Deactivate" : "Activate"}
                        name={"IsActive"}
                        id={"commission"}
                    ></ClusterCheckBox>
                </Col>
            </Row>


        </Collapse>
    </React.Fragment>



}

export default CommissionForm