import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button, Alert } from "reactstrap"

// Pages Components
import Report from "./Report"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { enableRole, getRoleDetail, getRoles, purgeRole } from "store/actions"
import RoleModal from "./modal"
import withDispose from "components/HOCs/WithDispose"
import RoleLogic from "../role"
import { toast } from "react-toastify"
import { Institution } from "helpers/authentication/institution"
import useReportData from "hooks/UseReportData"
const UserRoleList = props => {
  const {
    roles,
    onGetRoles,
    statusChanged,
    error,
    success,
    totalCount,
    onGetRoleDetails,
    role,
    loading,
    onEnableRole,
    editLoading,
  } = props
  const { HEADERS, MapToRows, SearchCriteria } = RoleLogic;
    const {
      columns,
      rows,
      handlePageChange,
      pageNumber,
      maxSize,
      setRows,
    } = useReportData({
      onGetData: onGetRoles,
      HEADERS: HEADERS,
      SearchCriteria: SearchCriteria,
    })
  const [modal, setmodal] = useState(false)
  const [viewedRole, setViewedRole] = useState()

  function onViewDetails(id) {
    onGetRoleDetails(id)
  }
  useEffect(() => {
    if (roles) {
      populateRows()
    }
  }, [props])

  useEffect(() => {
    if (role && Object.keys(role).length > 0) {
      setViewedRole(role)
      setmodal(true)
    }
  }, [role])
  const populateRows = () => {
    if (roles.length > 0) {
      let rws = []
      roles.forEach(role => {
        let row = MapToRows(role)
          ; (row["action"] = (
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => onViewDetails(role.id)}
            >
              View Details
            </Button>
          )),
            rws.push(row)
      })
      setRows(rws)
    }
  }
  useEffect(() => {

    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"User Roles"}
            breadcrumbItem={"View User Roles"}
          />
          {Institution.IsRemote() ? <Alert color="info">The user roles here only affect user roles on Remote accounts. To view Institution user roles, Login as the institution</Alert> : null}
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <RoleModal
        isEnabled={viewedRole?.status === "Active"}
        isOpen={modal}
        viewedRole={viewedRole}
        editLoading={editLoading}
        onEnableRole={onEnableRole}
        setModal={setmodal}
      ></RoleModal>
    </React.Fragment>
  )
}

UserRoleList.propTypes = {
  roles: PropTypes.array,
  onGetRoles: PropTypes.func,
  onGetRoleDetails: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
  role: PropTypes.object,
  onEnableRole: PropTypes.func,
  editLoading: PropTypes.bool,
  statusChanged: PropTypes.bool,
}

const mapStateToProps = ({
  Roles: { roles, error, success, loading, editLoading, role, statusChanged, totalCount },
}) => {
  return {
    roles,
    error,
    loading,
    role,
    success,
    editLoading,
    statusChanged,
    totalCount
  }
}

const mapDispatchToProps = dispatch => ({
  onGetRoles: (params) => dispatch(getRoles(params)),
  onGetRoleDetails: id => dispatch(getRoleDetail(id)),
  onEnableRole: (id, enable) => dispatch(enableRole(id, enable)),
  onPurge: () => dispatch(purgeRole()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(UserRoleList))))
