import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Badge,
} from "reactstrap"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// Redux
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"

import avatar from "../../assets/images/users/avatar-1.jpg"
// actions
import { editProfile, changePassword } from "../../store/actions"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Validators } from "helpers/validators"
import AuthUser from "helpers/authentication/authUser"
import authHeader from "helpers/authentication/auth-token-header"

const UserProfile = props => {
  const [email, setemail] = useState("")
  const [name, setname] = useState("")
  const [idx, setidx] = useState(1)
  const [errorList, setErrorList] = useState({
    newPassword: undefined,
    confirmPassword: undefined,
  })
  const [myError, setMyError] = useState()
  const [apiError, setError] = useState<string>()
  const [details, setDetails] = useState<any>({})
  const [authUser, setAuthUser] = useState(authHeader().AuthUser)

  // useEffect(() => {
  //     const obj = AuthUser
  //   if (obj) {
  //       setname(obj.username)
  //       setemail(obj.email)
  //       setidx(obj.uid)
  //   }
  // }, [props.success])

  function handleValidSubmit(event) {
    event.preventDefault()
    if (details.newPassword !== details.confirmPassword) {
      setError("Password do not match")
      return
    }
    props.changePassword(details.newPassword)
  }

  useEffect(() => {
    setError(undefined)
    if (props.error) {
      setError(Validators.extractError(props.error))
    }
  }, [props])

  useEffect(() => {
    setMyError(Validators.validateForm(errorList))
    // setLoading(false)
  }, [errorList])
  const handleOnChange = (key, value) => {
    let u = details
    u[key] = value
    setDetails(u)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Cluster" breadcrumbItem="Profile">
            {" "}
            <Badge
              style={{ marginLeft: "10px" }}
              className={"font-size-12 badge-soft-pink"}
              color={"pink"}
              pill
            >
              {authUser?.userRoleName}
            </Badge>
          </Breadcrumb>

          <h4 className="mb-2">Change Password</h4>

          <Card>
            <CardBody>
              <div className="p-2">
                {apiError ? (
                  <Alert color="danger" style={{ marginTop: "13px" }}>
                    {apiError}
                  </Alert>
                ) : null}
                {props.success ? (
                  <Alert color="success" style={{ marginTop: "13px" }}>
                    {props.success}
                  </Alert>
                ) : null}

                <form
                  className="form-horizontal mt-4"
                  noValidate
                  onSubmit={e => handleValidSubmit(e)}
                >
                  <ClusterInput
                    focus={true}
                    onError={err =>
                      setErrorList({ ...errorList, newPassword: err })
                    }
                    placeholder={"Enter new password"}
                    label={"New Password"}
                    id={"newPassword"}
                    name={"newPassword"}
                    validators={[
                      Validators.password(),
                    ]}
                    type="password"
                    required={true}
                    onHandleChange={(key, value) => handleOnChange(key, value)}
                  ></ClusterInput>
                  <ClusterInput
                    onError={err =>
                      setErrorList({ ...errorList, confirmPassword: err })
                    }
                    placeholder={"Enter confirm password"}
                    label={"Confirm Password"}
                    id={"confirmPassword"}
                    name={"confirmPassword"}
                    validators={[
                      Validators.minLength(
                        6,
                        "Minimum number of characters is 6"
                      ),
                    ]}
                    type="password"
                    required={true}
                    onHandleChange={(key, value) => handleOnChange(key, value)}
                  ></ClusterInput>

                  <Row className="form-group">
                    <Col className="text-right">
                      <ClusterButton
                        loading={props.loading}
                        disabled={myError != "" ? true : false}
                        color={"primary"}
                        addon={"bx bx-save"}
                      >
                        Submit
                      </ClusterButton>
                    </Col>
                  </Row>
                </form>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  error: PropTypes.any,
  loading: PropTypes.bool,
  success: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success, loading } = state.Profile
  return { error, success, loading }
}

export default withRouter(
  connect(mapStatetoProps, { editProfile, changePassword })(UserProfile)
)
