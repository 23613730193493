import { TransactionTypes } from "enums/transaction-types"
import { Validators } from "helpers/validators"

export const validator = values => {
  let errors: any = {}
  const required = Validators.required("Please enter a value")
  const name = () =>
    [required, Validators.minLength(3, "Minimum of 3 characters")].forEach(
      validator => {
        let error = validator(values.Name)
        if (error) errors.Name = error
      }
    )

  const percentage = () =>
    [required, Validators.percentage()].forEach(validator => {
      let error = validator(values.Percentage)
      if (error) errors.Percentage = error
    })

  const minimumFee = () =>
    [required, Validators.amount()].forEach(validator => {
      let error = validator(values.MinimumFee)
      if (error) errors.MinimumFee = error
    })
  const maximumFee = () =>
    [required, Validators.amount()].forEach(validator => {
      let error = validator(values.MaximumFee)
      if (error) errors.MaximumFee = error
    })
  const maximumServiceProviderFee = () =>
    [
      Validators.amount(),
      Validators.max(
        values?.MaximumFee,
        "Maximum service provider fee cannot be greater than maximum fee"
      ),
    ].forEach(validator => {
      let error = validator(values.MaximumServiceProviderFee)
      if (error) errors.MaximumServiceProviderFee = error
    })

  const minimumAmount = () =>
    [required, Validators.amount()].forEach(validator => {
      let error = validator(values.MinimumAmount)
      if (error) errors.MinimumAmount = error
    })
  const maximumAmount = () =>
    [required, Validators.amount()].forEach(validator => {
      let error = validator(values.MaximumAmount)
      if (error) errors.MaximumAmount = error
    })

  const flatAmount = () =>
    [required, Validators.amount()].forEach(validator => {
      let error = validator(values.FlatAmount)
      if (error) errors.FlatAmount = error
    })

  const bankShare = () =>
    [
      Validators.amount(),
      Validators.max(
        values?.FeeType == "Percentage" ? 100 : 1000000000000000000000000,
        "Amount must be between 0 and 100"
      ),
    ].forEach(validator => {
      let error = validator(values.BankShare)
      if (error) errors.BankShare = error
    })
  const agentShare = () =>
    [
      Validators.amount(),
      Validators.max(
        values?.FeeType == "Percentage" ? 100 : 1000000000000000000000000,
        "Amount must be between 0 and 100"
      ),
    ].forEach(validator => {
      let error = validator(values.AgentShare)
      if (error) errors.AgentShare = error
    })
  const anmShare = () =>
    [
      Validators.amount(),
      Validators.max(
        values?.FeeType == "Percentage" ? 100 : 1000000000000000000000000,
        "Amount must be between 0 and 100"
      ),
    ].forEach(validator => {
      let error = validator(values.ANMShare)
      if (error) errors.ANMShare = error
    })

  const serviceProviderShare = () =>
    [
      Validators.amount(),
      Validators.max(
        values?.FeeType == "Percentage" ? 100 : 1000000000000000000000000,
        "Amount must be between 0 and 100"
      ),
    ].forEach(validator => {
      let error = validator(values.ServiceProviderShare)
      if (error) errors.ServiceProviderShare = error
    })

  const vat = () =>
    [Validators.percentage()].forEach(validator => {
      let error = validator(values.VAT)
      if (error) errors.VAT = error
    })
  const bankIncomeAccount = () =>
    [
      Validators.customAccountNumber([
        13,
        "Only numbers allowed",
        "Minimum of 10 digits, Maximum of 13 digits",
      ]),
    ].forEach(validator => {
      let error = validator(values.BankIncomeAccount)
      if (error) errors.BankIncomeAccount = error
    })
  const bankExpenseAccount = () =>
    [
      Validators.customAccountNumber([
        13,
        "Only numbers allowed",
        "Minimum of 10 digits, Maximum of 13 digits",
      ]),
    ].forEach(validator => {
      let error = validator(values.BankExpenseAccount)
      if (error) errors.BankExpenseAccount = error
    })
  const bankSuspenseAccount = () =>
    [
      Validators.customAccountNumber([
        13,
        "Only numbers allowed",
        "Minimum of 10 digits, Maximum of 13 digits",
      ]),
    ].forEach(validator => {
      let error = validator(values.BankSuspenseAccount)
      if (error) errors.BankSuspenseAccount = error
    })
  const vatPayableAccount = () =>
    [
      Validators.customAccountNumber([
        13,
        "Only numbers allowed",
        "Minimum of 10 digits, Maximum of 13 digits",
      ]),
    ].forEach(validator => {
      let error = validator(values.VatPayableAccount)
      if (error) errors.VatPayableAccount = error
    })
  const transactionType = () =>
    [required].forEach(validator => {
      let error = validator(values.TransactionType)
      if (error) errors.TransactionType = error
    })

  const issuingCashOutInstitutionID = () =>
    [required].forEach(validator => {
      let error = validator(values.IssuingCashOutInstitutionID)
      if (error) errors.IssuingCashOutInstitutionID = error
    })

  const costBearer = () =>
    [required].forEach(validator => {
      let error = validator(values.Bearer)
      if (error) errors.Bearer = error
    })

  const applicability = () =>
    [required].forEach(validator => {
      let error = validator(values.Applicability)
      if (error) errors.Applicability = error
    })

  const paymentMode = () =>
    [required].forEach(validator => {
      let error = validator(values.PaymentMode)
      if (error) errors.PaymentMode = error
    })

  const commissionType = () =>
    [required].forEach(validator => {
      let error = validator(values.CommissionType)
      if (error) errors.CommissionType = error
    })
  const feeType = () =>
    [required].forEach(validator => {
      let error = validator(values.FeeType)
      if (error) errors.FeeType = error
    })

  const validate = () => {
    try {
      name()
      if (values?.FeeType == "Percentage") {
        percentage()
        minimumFee()
        maximumFee()
        maximumServiceProviderFee()
      } else {
        flatAmount()
      }
      if (values?.CommissionType == "Range") {
        minimumAmount()
        maximumAmount()
      }
      bankShare()
      agentShare()
      anmShare()
      serviceProviderShare()
      vat()
      bankIncomeAccount()
      bankExpenseAccount()
      bankSuspenseAccount()
      vatPayableAccount()
      transactionType()
      if (values?.TransactionType === TransactionTypes.POSAgentCashOut)
        issuingCashOutInstitutionID()
      costBearer()
      applicability()
      paymentMode()
      commissionType()
      feeType()
      return errors
    } catch (err) {
      console.log(err)
    }
  }
  return validate()
}
