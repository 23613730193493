import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import "assets/css/logo.css"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { ImageSize } from "enums/image-size"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  enableInstitution,
  getInstitutionDetail,
  getInstitutions,
  institutionPurge,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import InstitutionLogic from "../institution"
import { EDIT_INSTITUTION, ENABLE_INSTITUTIONS } from "../permission"
import { GET_ALL_INSTITUTIONS } from "helpers/url_helper"
import withDispose from "components/HOCs/WithDispose"
import AccordionToggle from "components/Common/Accordion/Toggle"
import ClusterImageViewer from "components/Common/ImageViewer"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import { Icons } from "enums/icons"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const InstitutionList = props => {
  const {
    MapToModalState,
    MapToRows,
    SearchCriteria,
    SearchOptions,
    HEADERS,
    MapFeatures,
  } = InstitutionLogic
  const {
    institutions,
    onGetInstitutions,
    error,
    success,
    onGetInstitutionDetail,
    institutionDetail,
    onPurge,
    totalCount,
    loading,
    onEnableInstitution,
    statusChanged,
    editLoading,
  } = props
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetInstitutions, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const { institutionsData } = useInstitutionsData()
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  const [viewedInstitution, setViewedInstitution] = useState<any>()

  useEffect(() => {
    populateRows()
  }, [institutions])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    if (institutionDetail && Object.keys(institutionDetail).length > 0) {
      setmodal(true)
      setViewedInstitution(institutionDetail)
      setModalData(MapToModalState(institutionDetail))
    }
  }, [institutionDetail])

  const populateRows = () => {
    if (institutions) {
      let rws = []
      institutions.forEach(institution => {
        let row = MapToRows(institution)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(institution.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetInstitutionDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Institution"}
            breadcrumbItem={"View Agent Institutions"}
          />
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_INSTITUTIONS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={viewedInstitution?.IsActive ? "success" : "secondary"}
          status={viewedInstitution?.IsActive ? "" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={
            <div className="institutionLogoBody">
              <span>Institution Details</span>
              {viewedInstitution?.Logo && (
                <ClusterImageViewer url={viewedInstitution?.Logo} size={ImageSize.sm} />
              )}
            </div>
          }
          append={[
            <FeatureList
              key={1}
              items={MapFeatures(
                viewedInstitution?.Features,
                viewedInstitution,
                true
              )}
              loading={loading}
            />,
          ]}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_INSTITUTIONS]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableInstitution(
                viewedInstitution?.ID,
                !viewedInstitution?.IsActive
              )
            }
            color="secondary"
          >
            {viewedInstitution?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-institution/" + viewedInstitution?.ID}>
            <ClusterButton
              permissions={[EDIT_INSTITUTION]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

InstitutionList.propTypes = {
  institutions: PropTypes.array,
  institutionDetail: PropTypes.object,
  onGetInstitutions: PropTypes.func,
  onGetInstitutionDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  institutions: {
    institutions,
    error,
    institutionDetail,
    success,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  return {
    institutions: [...institutions],
    error,
    institutionDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    success,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetInstitutions: query => dispatch(getInstitutions(query)),
  onGetInstitutionDetail: id => dispatch(getInstitutionDetail(id)),
  onPurge: () => dispatch(institutionPurge()),
  onEnableInstitution: (id, enable) => dispatch(enableInstitution(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(InstitutionList))))

const FeatureList = ({ items, loading }) => {
  if (items.length > 0) {
    return (
      <AccordionToggle
        parent={{
          id: 0,
          name: "feature",
          label: "Features",
          value: 0,
          checked: true,
          disabled: true,
          icon: Icons.Customize,
        }}
        children={items}
        loading={loading}
      />
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}
