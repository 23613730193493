import React from 'react'
import PropTypes from "prop-types"
import { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Container, Row, Col, Button, Modal, ModalHeader, ModalBody, Card, CardBody, CardImg } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import NotificationLogic from "../notification"
import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"
import {
  getNotifications, getNotificationDetails
} from "store/actions"
import Loading from 'components/Common/Loading'
import { EXPORT_NOTIFICATION_REPORT } from "helpers/url_helper"
import { Format } from "helpers/variables"
import { GET_ALL_CATEGORIES, } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import useSWR from "swr"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from 'hooks/useInstitutionData'


const NotificationHistory = props => {
  const {
    notifications,
    error,
    totalCount,
    loading,
    onGetNotifications,
    onGetNotificationDetail
  } = props
  const categories = useSWR(GET_ALL_CATEGORIES, fetcher)
  const { HEADERS, SearchOptions, MapToDropDown, SearchCriteria, MapToRows } = NotificationLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetNotifications, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [viewedNotification, setViewedNotification] = useState(null)
  const [modalLoading, setModalLoading] = useState(false)
  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, (categories?.data as any)?.Data, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, (categories?.data as any)?.Data, institutionsData))
  }, [institutions])
  useEffect(() => {
    populateRows()
  }, [props])

  const populateRows = () => {
    if (notifications) {
      let rws = []
      notifications.forEach(notification => {
        let row = MapToRows(notification)
        row["View Details"] = (
          <Button
            outline
            type="button"
            color="primary"
            size="sm"
            className="btn-outline waves-effect waves-light"
            onClick={() => {
              setIsModalOpen(true)
              onGetNotificationDetail(notification.ID)
              setModalLoading(true)
              setTimeout(() => {
                setViewedNotification(notification)
                setModalLoading(false)
              }, 1000)
            }}
          >
            View Details
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Notifications"}
            breadcrumbItem={"View Notification History"}
          />
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: EXPORT_NOTIFICATION_REPORT,
                  query: SearchCriteria(params, maxSize)
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {isModalOpen ? (
        <Modal
          isOpen={isModalOpen}
          role="dialog"
          autoFocus={true}
          centered={true}
          className="exampleModal"
          toggle={() => {
            setIsModalOpen(false)
          }}
        >
          <div className="modal-content">
            <ModalHeader
              toggle={() => {
                setIsModalOpen(false)
              }}
            ><div className='font-weight-bold'>
                Notification Details
              </div>
            </ModalHeader>
            {modalLoading ?
              (<ModalBody>
                <Loading></Loading>
              </ModalBody>
              ) :
              (<ModalBody>
                <Row className="justify-content-center">
                  <Col md={12}>
                    <Card >
                      <div className="bg-soft-primary">
                      </div>
                      <CardBody className="pt-0">
                        <div className="p-2">
                          <Row className="mt-2 mb-2 d-flex justify-content-between">
                            <Col className='d-block mr-5'>
                              <h5 className='font-weight-bold'>Date Logged</h5>
                              <p>{Format.date(viewedNotification?.DateLogged)}</p>
                            </Col>

                            <Col className='justify-content-end d-block ml-5'>
                              <h5 className='font-weight-bold'>Seen</h5>
                              <p style={{ 'display': 'block' }}>{viewedNotification?.ReadCount}</p>
                            </Col>
                          </Row>

                          <Row className="mt-2 mb-2 d-flex justify-content-between">
                            <Col className='d-block mr-5'>
                              <h5 className='font-weight-bold'>Agent Category</h5>
                              <p>{viewedNotification?.AgentCategory?.Name}</p>
                            </Col>
                            <Col className='justify-content-end d-block ml-5'>
                              <h5 className='font-weight-bold'>Last Read</h5>
                              <p>{viewedNotification.LastReadTime == null ? "" : Format.date(viewedNotification.LastReadTime)}</p>
                            </Col>
                          </Row>
                          <Row className="mt-2 mb-2 d-flex justify-content-between">
                            <Col className='d-block mr-5'>
                              <h5 className='font-weight-bold'>Logged By</h5>
                              <p>{viewedNotification?.LoggedBy}</p>
                            </Col>
                            <Col className='justify-content-end d-block ml-5'>
                              <h5 className='font-weight-bold'>Type</h5>
                              <p>{viewedNotification?.Type}</p>
                            </Col>
                          </Row>
                          <Col className="mt-3 mb-2" style={{ 'marginLeft': '-10px' }}>
                            <h5 className='font-weight-bold'>Header</h5>
                            <p>{viewedNotification?.Header}</p>
                          </Col>
                          <Col className='mt-2 mb-2' style={{ 'marginLeft': '-10px' }}>
                            <h5 className='font-weight-bold'>Subject</h5>
                            <p>{viewedNotification?.Message}</p>
                          </Col>
                          <h5 className='font-weight-bold mt-2 mb-2 ml-1'>Image</h5>
                          <CardImg bottom src={viewedNotification?.Url} className='w-100 h-50'>
                          </CardImg>

                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </ModalBody>
              )}
          </div>
        </Modal>) : (<></>)}

    </React.Fragment>
  )
}

NotificationHistory.propTypes = {
  notifications: PropTypes.array,
  notification: PropTypes.object,
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  onGetNotifications: PropTypes.func,
  onGetNotificationDetail: PropTypes.func
}

const mapStateToProps = ({
  notifications: {
    notifications,
    notification,
    error,
    totalCount,
    loading
  }
}) => {
  return {
    notifications: [...notifications],
    notification,
    error,
    totalCount,
    loading
  }
}

const mapDispatchToProps = dispatch => ({
  onGetNotifications: query => dispatch(getNotifications(query)),
  onGetNotificationDetail: id => dispatch(getNotificationDetails(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(NotificationHistory))))

