/* CASELOGS */
export const GET_CASELOGS = "GET_CASELOGS"
export const GET_ALL_CASELOGS = "GET_ALL_CASELOGS"
export const GET_CASELOGS_SUCCESS = "GET_CASELOGS_SUCCESS"
export const GET_CASELOGS_FAIL = "GET_CASELOGS_FAIL"

export const GET_CASELOG_DETAIL = "GET_CASELOG_DETAIL"
export const GET_CASELOG_DETAIL_SUCCESS = "GET_CASELOG_DETAIL_SUCCESS"
export const GET_CASELOG_DETAIL_FAIL = "GET_CASELOG_DETAIL_FAIL"

export const GET_CASELOG_MESSAGES = "GET_CASELOG_MESSAGES"
export const GET_CASELOG_MESSAGES_SUCCESS = "GET_CASELOG_MESSAGES_SUCCESS"
export const GET_CASELOG_MESSAGES_FAIL = "GET_CASELOG_MESSAGES_FAIL"

export const CREATE_CASELOG = "CREATE_CASELOG"
export const POST_CASELOG_SUCCESS = "POST_CASELOG_SUCCESS"
export const POST_CASELOG_FAIL = "POST_CASELOG_FAIL"

export const SEND_CASEMESSAGE = "SEND_CASEMESSAGE"
export const SEND_CASEMESSAGE_SUCCESS = "SEND_CASEMESSAGE_SUCCESS"
export const SEND_CASEMESSAGE_FAIL = "SEND_CASEMESSAGE_FAIL"

export const MARK_AS_READ = "MARK_AS_READ"
export const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS"
export const MARK_AS_READ_FAIL = "MARK_AS_READ_FAIL"

export const RESOLVE_CASE = "RESOLVE_CASE"
export const RESOLVE_CASE_SUCCESS = "RESOLVE_CASE_SUCCESS"
export const RESOLVE_CASE_FAIL = "RESOLVE_CASE_FAIL"

export const CASELOG_PURGE = "CASELOG_PURGE"
export const CASELOG_PURGE_SUCCESS = "CASELOG_PURGE_SUCCESS"

export const UPDATE_CASELOG = "UPDATE_CASELOG"
export const UPDATE_CASELOG_SUCCESS = "UPDATE_CASELOG_SUCCESS"
export const UPDATE_CASELOG_FAIL = "UPDATE_CASELOG_FAIL"

export const ENABLE_CASELOG = "ENABLE_CASELOG"
export const ENABLE_CASELOG_SUCCESS = "ENABLE_CASELOG_SUCCESS"
