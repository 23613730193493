/* CLUSTERS */
export const GET_CLUSTERS:any = "GET_CLUSTERS"
export const GET_ALL_CLUSTERS = "GET_ALL_CLUSTERS"
export const GET_CLUSTERS_SUCCESS = "GET_CLUSTERS_SUCCESS"
export const GET_CLUSTERS_FAIL = "GET_CLUSTERS_FAIL"

export const GET_CLUSTER_DETAIL:any = "GET_CLUSTER_DETAIL"
export const GET_CLUSTER_DETAIL_SUCCESS = "GET_CLUSTER_DETAIL_SUCCESS"
export const GET_CLUSTER_DETAIL_FAIL = "GET_CLUSTER_DETAIL_FAIL"


export const CREATE_CLUSTER:any= "CREATE_CLUSTER";
export const POST_CLUSTER_SUCCESS = "POST_CLUSTER_SUCCESS"
export const POST_CLUSTER_FAIL = "POST_CLUSTER_FAIL"

export const CLUSTER_PURGE = "CLUSTER_PURGE"
export const CLUSTER_PURGE_SUCCESS = "CLUSTER_PURGE_SUCCESS"


export const UPDATE_CLUSTER:any= "UPDATE_CLUSTER";
export const UPDATE_CLUSTER_SUCCESS= "UPDATE_CLUSTER_SUCCESS";
export const UPDATE_CLUSTER_FAIL= "UPDATE_CLUSTER_FAIL";

export const ENABLE_CLUSTER= "ENABLE_CLUSTER";
export const ENABLE_CLUSTER_SUCCESS = "ENABLE_CLUSTER_SUCCESS"