import  ClusterButton  from "components/Common/Button/Button"
import { Utils } from "helpers/utility"
import { Card, CardBody, Col, Row } from "reactstrap"
import { Icons } from "enums/icons"
import { SETTLEMENT_WITHDRAWAL } from "../permission"
import { withRouter } from "react-router-dom"

type WalletBalanceType = {
    balance : String,
    next : (step : WithdrawalStages) => void,
    isWalletBalance : Boolean,
    cardTitle : String,
    shouldShowReportBtn? : Boolean
}


enum WithdrawalStages {
    PaymentDetails,
    ConfirmOTP,
    Withdraw,
    End
}

const WalletBalanceCard: React.FC<any> = (props) => {

    const { balance, next, isWalletBalance, cardTitle, shouldShowReportBtn }: WalletBalanceType = props

    const navigateToReportPage = () => {
        props.history.push('/wallet-report')
    }

    return <Row style={{ display: "flex" }} >
        <Col>
            <Card
                className={"divider-top-primary"}
                style={{ paddingTop: "15px", paddingBottom: "0px", position: "relative" }}
            >

                <CardBody
                    style={{
                        paddingTop: "10px",
                        paddingBottom: "0px",
                        position: "relative",
                    }}
                >
                    <Card
                        color={"light-primary"}
                        style={{ paddingTop: "0px", paddingBottom: "0px", position: "relative" }}
                    >
                        <CardBody
                            style={{
                                paddingTop: "10px",
                                paddingBottom: "5px",
                                position: "relative",
                            }}
                        >
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                    <h6>{cardTitle}</h6>
                                    <h4 style={{ fontWeight: '600' }}>{balance}</h4>
                                </div>
                                {isWalletBalance ? <ClusterButton disabled={balance == Utils.Format.currency(0) || balance == '--'} permissions={[SETTLEMENT_WITHDRAWAL]} onHandleClick={() => next(WithdrawalStages.PaymentDetails)} color="primary" type={"button"} className="btn-sm ml-5" addon={Icons.Money}>Withdraw</ClusterButton > : <></>}
                                {shouldShowReportBtn && <ClusterButton color="primary" type={"button"} className="btn-sm ml-5" onHandleClick={() => navigateToReportPage()}>View report</ClusterButton>}
                            </div>
                        </CardBody>
                    </Card>

                </CardBody>
            </Card>
        </Col>
    </Row>
}

export default withRouter(WalletBalanceCard)