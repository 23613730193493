import React from "react"
import PropTypes from "prop-types"
import Loading from "../Loading"
import useClassify from "hooks/useClassify"
import composedWithGuard from "components/HOCs/WithGuard"

type ButtonProps = {
  type?: 'submit' | 'reset' | 'button' | undefined,
  size?: "sm" | "md" | "lg" | "xl" | undefined,
  style?: any,
  className?: string,
  addon?: string,
  addonType?: "append" | "prepend" | undefined,
  outline?: boolean,
  color?: "secondary" | "primary" | "light" | "dark" | "success" | "warning" | "danger" | "info" | undefined,
  disabled?: boolean,
  withLoader?: boolean,
  loading?: boolean,
  onHandleClick?: any,
  buttonStyle?: any,
  children: any,
  rename?: string,
  title?: string
}

export const ClusterButton: React.FC<ButtonProps> = props => {
  const {
    rename,
    type = "submit",
    withLoader = true,
    loading,
    buttonStyle,
    style,
    disabled,
    className,
    outline,
    color,
    size,
    addon,
    addonType = "prepend",
    onHandleClick,
    title
  } = props
  const [classname] = useClassify({ size, outline, color, className })

  const handleClick = e => {
    if (onHandleClick) {
      onHandleClick(e)
    }
  }

  if (withLoader) {
    return (
      <div style={{ display: "flex", ...style }}>
        <button
          onClick={e => handleClick(e)}
          title={title}
          disabled={loading ? true : disabled}
          type={type}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            ...buttonStyle,
          }}
          className={`${classname} waves-effect waves-light mb-2`}
        >
          {addon && addonType == "prepend" && (
            <i className={addon + " mr-2"} ></i>
          )}{" "}
          {rename ? rename : props.children}
          {" "}
          {addon && addonType == "append" && (
            <i className={addon} ></i>
          )}
        </button>
        {loading ? (
          <div style={{ marginLeft: "10px", display: "flex", alignItems: "center" }}>
            <Loading></Loading>
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </div>
    )
  } else {
    return (
      <button
        onClick={e => handleClick(e)}
        title={
          disabled
            ? "Fill the form to enable this button"
            : "Form has been filled"
        }
        disabled={disabled}
        type={type}
        style={{ display: "flex", alignItems: "center", marginTop: "10px" }}
        className={`${classname} waves-effect waves-light mb-2`}
      >
        {addon ? (
          <i className={addon} style={{ marginRight: "5px" }}></i>
        ) : (
          <React.Fragment></React.Fragment>
        )}{" "}
        {rename ? rename : props.children}
      </button>
    )
  }
}

ClusterButton.propTypes = {
  type: PropTypes.any,
  size: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  addon: PropTypes.string,
  outline: PropTypes.bool,
  color: PropTypes.any,
  disabled: PropTypes.bool,
  withLoader: PropTypes.bool,
  loading: PropTypes.bool,
  onHandleClick: PropTypes.func,
  buttonStyle: PropTypes.any,
  children: PropTypes.any,
  title: PropTypes.string
}

export default composedWithGuard(ClusterButton, "Form has been filled", "Fill the form to enable this button")
