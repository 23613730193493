import {
  Format,
  PAYMENTSTATUS,
  SEARCHOPTIONS,
  TRANSACTIONTYPENAMES,
} from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Action", name: "action", isAction: true },
  { title: "Status", name: "status", entityKeyName: "IsActive", isBadge: true },
  { title: "Name", name: "name", entityKeyName: "Name" },
  {
    title: "Description",
    name: "description",
    entityKeyName: "Description",
  },
  
  { title: "Fee", name: "fee", entityKeyName: "Fee" }
 
]
const HISTORYHEADERS = [
  { title: "Action", name: "action", isAction: true },
  { title: "Status", name: "status", entityKeyName: "IsActive",isBadge: true },
  { title: "Date", name: "date", entityKeyName: "StartDate" },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  { title: "AgentPhoneNumber", name: "agentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Previous Agent Category", name: "previousAgentCategory", entityKeyName: "PreviousAgentCategory" },
  { title: "New Agent Category", name: "newAgentCategory", entityKeyName: "Plan.Name" },
  { title: "Fee", name: "fee", entityKeyName: "Fee" },
  { title: "Target Exceeded", name: "targetReached", entityKeyName: "IsTargetReached",isBadge: true },
]
const OPTIONSHEADERS = [
  // { title: "Fee Discount", name: "feeDiscount", entityKeyName: "FeeDiscount" },
  {
    title: "Maximum Transaction Count",
    name: "maxTransactionCount",
    entityKeyName: "MaxTransactionCount",
  },
  {
    title: "Maximum Benefit Volume",
    name: "maxBenefitVolume",
    entityKeyName: "MaxBenefitVolume",
  },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionType",
  }
 
]
const MILESTONEHEADERS = [
  // { title: "Fee Discount", name: "feeDiscount", entityKeyName: "FeeDiscount" },
  {
    title: "Cummulative Transaction Count",
    name: "targetCountMaxLimit",
    entityKeyName: "TargetCountMaxLimit",
  },
  {
    title: "Cummulative Transaction Volume",
    name: "targetVolumeMaxLimit",
    entityKeyName: "TargetVolumeMaxLimit",
  },
  {
    title: "Cummulative Transaction Count Exceeded",
    name: "targetCountLeft",
    entityKeyName: "TargetCountLeft",
  },
  {
    title: "Cummulative Target Volume Exceeded",
    name: "targetVolumeLeft",
    entityKeyName: "TargetVolumeLeft",
  },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionType",
  }
 
]
let ERRORLIST = {
  name: undefined,
  description: undefined,
  validityPeriod: "",
  fee: undefined,
  feeAccountNumber:""
}

let OPTIONS_ERRORLIST = {
  maximumBenefitVolume: undefined,
  maximumTransactionCount:undefined,
  transactionType: undefined,
 
}


const UpdateErrorList = () => {
  let list = {...ERRORLIST}
  for (let key in list) {
    list[key] = ""
  }
  return list
}
const SearchOptions = (startDate, endDate) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    }
  ]
  return options
}

const MapMilestonesToRows = milestone => {
  return {
     targetCountLeft:milestone.TargetCountMaxLimit - milestone.TargetCountLeft,
     targetVolumeLeft:Format.currency(milestone.TargetVolumeMaxLimit-milestone.TargetVolumeLeft),
     targetCountMaxLimit:milestone.TargetCountMaxLimit,
     targetVolumeMaxLimit: Format.currency(milestone.TargetVolumeMaxLimit),
     transactionType:TRANSACTIONTYPENAMES.find(t=>t.key== milestone.TransactionType)?.value,
     
  }
}
const MapHistoryToRows = subscription => {
  return {
     terminalID:subscription.TerminalID,
     agentPhoneNumber:subscription.AgentPhoneNumber,
     agentName:subscription.AgentName,
     previousAgentCategory:subscription.PreviousAgentCategory,
     newAgentCategory:subscription.Plan.Name,
     date:Format.date(subscription.StartDate),
     fee: Format.currency(subscription.Fee),
     feeAccountNumber: subscription.FeeAccountNumber,
     status: {
      label:subscription.IsActive ? "Active" : "Inactive",
      badgeClass:subscription.IsActive ? "success" : "secondary",
    },
     targetReached: {
      label:subscription.IsTargetReached ? "Yes" : "No",
      badgeClass:subscription.IsTargetReached ? "success" : "secondary",
    }
  }
}
const MapToRows = subscriptionPlan => {
  return {
    id: subscriptionPlan.ID,
    name: subscriptionPlan.Name,
    description: subscriptionPlan.Description,
    validityPeriod: subscriptionPlan.ValidityPeriod,
    fee: Format.currency(subscriptionPlan.Fee),
    status: {
      label: subscriptionPlan.IsActive ? "Active" : "Inactive",
      badgeClass: subscriptionPlan.IsActive ? "success" : "secondary",
    },
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    Name: params["agentName"],
    AgentCode: params["agentCode"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    PaymentStatus: params["paymentStatus"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToRowsOptions = options => {
  return {
    feeDiscount: options?.FeeDiscount,
    maxTransactionCount:options?.MaximumTransactionCount,
    maxBenefitVolume:Format.currency(options?.MaximumBenefitVolume),
    transactionType: TRANSACTIONTYPENAMES.find(t=>t.key=== options?.TransactionType||t.value== options?.TransactionType)?.value,
    
  }
}
const MapToModalState = plan => {
  
  return [
    {
      title: "Basic Details",
      items: [
        {
          type: "text",
          label: "Name",
          id: "s-name",
          value: plan.Name,
          name: "name",
          width: 6,
        },
        {
          type: "text",
          label: "Description",
          id: "description",
          value: plan.Description,
          name: "description",
          width: 6,
        },
        {
          type: "text",
          label: "Fee",
          id: "fee",
          value: Format.currency(plan.Fee),
          name: "fee",
          width: 6,
        },
        {
          type: "text",
          label: "Fee Account Number",
          id: "feeAccountNumber",
          value: plan.FeeAccountNumber,
          name: "feeAccountNumber",
          width: 6,
        }
      ],
    },
  
  ]
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
  }
  return []
}
export default {
  HEADERS,
  ERRORLIST,
  OPTIONSHEADERS,
  MILESTONEHEADERS,
  OPTIONS_ERRORLIST,
  HISTORYHEADERS,
  MapMilestonesToRows,
  MapHistoryToRows,
  MapToModalState,
  MapToRowsOptions,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  UpdateErrorList,
}
