import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  getDebitRequests,
  patchDebitRequestsApproval
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import ApproveDirectDebitLogic from "../approve-direct-debit"
import { toast } from "react-toastify"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"

const ApproveDirectDebitList = props => {
  const {
    debitrequests,
    totalCount,
    loading,
    statusChanged,
    onGetDirectDebits,
    onPatchDebitRequests,
    onDownloadInvoice
  } = props
  const { HEADERS, MapToRows, SearchCriteria, SearchOptions, loadData } = ApproveDirectDebitLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ onGetData: onGetDirectDebits, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.startDate, params?.endDate, institutionsData)
  );

  useEffect(() => {
    setSearchOptions(SearchOptions(params?.startDate, params?.endDate, institutionsData))
  }, [institutions])

  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [statusChanged])

  const populateRows = () => {
    if (debitrequests) {
      let rws = []
      debitrequests.forEach(debitReq => {
        let row = MapToRows(debitReq);
        row["download"] = (

          <Button
            outline
            type="button"
            color="primary"
            size="sm"
            className="btn-outline waves-effect waves-light"
            onClick={() => {
              toast.info("Downloading Invoice..")
              loadData(debitReq.ID, `Invoice ${debitReq.DateLogged}.pdf`)
            }}>
            Download Invoice
          </Button>
        );
        row["approve"] = (
          <Button
            outline
            type="button"
            color="success"
            size="sm"
            className="btn-outline waves-effect waves-light"
            onClick={() => onApprove(debitReq.ID, true)}
          >
            Approve
          </Button>
        );
        row["reject"] = (
          <Button
            outline
            type="button"
            color="danger"
            size="sm"
            className="btn-outline waves-effect waves-light btn-outline"
            onClick={() => onReject(debitReq.ID, false)}>
            Reject
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  const downloadInvoiceData = (id) => {
    onDownloadInvoice(id)
  }

  function onApprove(id, approve) {
    onPatchDebitRequests(id, approve)
    toast.success("Request Approved")
  }

  function onReject(id, approve) {
    onPatchDebitRequests(id, approve)
    toast.error("Request Rejected")
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Direct Debit"}
            breadcrumbItem={"Pending Debit Requests"}
          />
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  onSearch: params => onAction(params),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ApproveDirectDebitList.propTypes = {
  debitrequests: PropTypes.array,
  single_debit_request: PropTypes.object,
  onGetDirectDebits: PropTypes.func,
  onGetSingleDirectDebits: PropTypes.func,
  onPatchDebitRequests: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  approved: PropTypes.bool,
  downloadUrl: PropTypes.any,
  isDownloaded: PropTypes.bool
}

const mapStateToProps = ({
  debitRequests: {
    debitrequests,
    error,
    single_debit_request,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    downloadUrl,
    approved,
    isDownloaded,
    onPatchDebitRequests
  },
}) => {
  return {
    debitrequests: [...debitrequests],
    error,
    single_debit_request: single_debit_request,
    totalCount,
    loading,
    statusChanged,
    editLoading,
    downloadUrl,
    approved,
    isDownloaded,
    onPatchDebitRequests
  }
}

const mapDispatchToProps = dispatch => ({
  onGetDirectDebits: query => dispatch(getDebitRequests(query)),
  onPatchDebitRequests: (id, approve) => dispatch(patchDebitRequestsApproval(id, approve))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ApproveDirectDebitList))))

