import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_CLUSTERS,
  GET_CLUSTER_DETAIL,
  CLUSTER_PURGE,
  ENABLE_CLUSTER,
  CREATE_CLUSTER,
  UPDATE_CLUSTER,
  GET_ALL_CLUSTERS
} from "./actionTypes"
import {
  getClustersSuccess,
  getClustersFail,
  getClusterDetailSuccess,
  getClusterDetailFail,
  clusterPurgeSuccess,
  updateClusterSuccess,
  postClusterSuccess,
  clusterApiError
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClusters,
  getClusterDetail,
  putCluster,
  postCluster,
  enableCluster,
  disableCluster,
  getAllClusters
} from "helpers/backend_helper"
import { enableClusterSuccess } from "store/actions"
const format = "yyyy-MM-DD";
const clusters ={
  Message: "Retrieved Successfully",
  Data: {
    Collection: [{
      ID: 1,
      Name: "Cluster 1",
      IsActive: true,
      Manager: "Banjo Pelumi"
    }, {
      ID: 2,
      Name: "Cluster 2",
      IsActive: true,
      Manager: "Banjo Pelumi"
    }, {
      ID: 3,
      Name: "Cluster 3",
      IsActive: true,
      Manager: "Amicki Fabian"
    }], TotalCount: 3
  }
}
function* fetchClusters({ params }) {
  try {
    const response = yield call(getClusters, JSON.parse(params))
    // let response = clusters;
    yield put(getClustersSuccess(response))
  } catch (error) {
    console.debug("Fetching Clusters Error", error)
    yield put(getClustersFail(error))
  }
}
function* fetchAllClusters() {
  try {
    const response = yield call(getAllClusters)
    yield put(getClustersSuccess(response))
  } catch (error) {
    console.debug("Fetching Clusters Error", error)
    yield put(getClustersFail(error))
  }
}

function* fetchClusterDetail({ id }) {
  try {
    const response = yield call(getClusterDetail, id);
    // const response = {Message:"Retrieved Successfully", Data: clusters.Data.Collection.find(c=>c.ID==id)}
    yield put(getClusterDetailSuccess(response))
  } catch (error) {
    yield put(getClusterDetailFail(error))
  }
}


function* createCluster({ payload: { cluster } }) {
  try {

    const response = yield call(postCluster, { ...JSON.parse(JSON.stringify(cluster)) })
    yield put(postClusterSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(clusterApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* updateCluster({ payload: { cluster } }) {
  try {

    const response = yield call(putCluster, { ...JSON.parse(JSON.stringify(cluster)) })

    yield put(updateClusterSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(clusterApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableCluster(payload) {
  try {
    let toEnable = true;
    let cta = enableCluster;
    if (!payload.enable) { cta = disableCluster; toEnable = false }
    const response = yield call(cta, payload.id)

    yield put(enableClusterSuccess({ ...response, enable: toEnable }))

  } catch (error) {
    console.debug(error)
    yield put(clusterApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* doPurge() {
  yield put(clusterPurgeSuccess())
}

function* clustersSaga() {
  yield takeLeading(GET_CLUSTERS, fetchClusters)
  yield takeLeading(GET_ALL_CLUSTERS, fetchAllClusters)
  yield takeEvery(GET_CLUSTER_DETAIL, fetchClusterDetail)
  yield takeEvery(CLUSTER_PURGE, doPurge)
  yield takeLeading(ENABLE_CLUSTER, doEnableCluster)
  yield takeLeading(UPDATE_CLUSTER, updateCluster)
  yield takeLeading(CREATE_CLUSTER, createCluster)
}

export default clustersSaga
