import {
  GET_USERS,
  GET_ALL_USERS,
  GET_USER_DETAILS,
  GET_USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  API_ERROR,
  POST_USER_ERROR,
  PURGE_USERS,
  PURGE_USERS_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  CREATE_USER,
  POST_SUCCESS,
  ENABLE_USER_SUCCESS,
  ENABLE_USER,
  GET_USERS_SUCCESS,
} from "./actionTypes"

export const getUsers = params => {
  return {
    type: GET_USERS,
    params,
  }
}
export const getAllUsers = () => {
  return {
    type: GET_ALL_USERS,
    
  }
}
export const getUserSuccess = users => {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  }
}

export const getDetailSuccess = user => {
  return {
    type: GET_USER_DETAILS_SUCCESS,
    payload: user,
  }
}
export const userApiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}
export const postUserError = error => {
  return {
    type: POST_USER_ERROR,
    payload: error,
  }
}
export const getUserDetail = id => ({
  type: GET_USER_DETAILS,
  id,
})

export const getUserDetailSuccess = user => ({
  type: GET_USER_DETAILS_SUCCESS,
  payload: user,
})

export const getUserDetailFail = error => ({
  type: USER_DETAILS_ERROR,
  payload: error,
})

export const createUser = user => {
  return {
    type: CREATE_USER,
    payload: { user },
  }
}

export const postSuccess = user => {
  return {
    type: POST_SUCCESS,
    payload: user,
  }
}

export const enableUser = (id, enable) => {
  return {
    type: ENABLE_USER,
    id,
    enable,
  }
}

export const enableUserSuccess = user => {
  return {
    type: ENABLE_USER_SUCCESS,
    payload: user,
  }
}

export const updateUser = user => {
  return {
    type: UPDATE_USER,
    payload: { user },
  }
}
export const updateUserSuccess = user => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: user,
  }
}
export const purgeUsers = () => {
  return {
    type: PURGE_USERS,
  }
}

export const purgeUserSuccess = () => {
  return {
    type: PURGE_USERS_SUCCESS,
  }
}
