/* INSTITUTIONS */
export const GET_INSTITUTIONS:any = "GET_INSTITUTIONS"
export const GET_INSTITUTIONS_SUCCESS = "GET_INSTITUTIONS_SUCCESS"
export const GET_INSTITUTIONS_FAIL = "GET_INSTITUTIONS_FAIL"


export const GET_INSTITUTION_SERVICE_CONFIGURATIONS:any = "GET_INSTITUTION_SERVICE_CONFIGURATIONS"
export const GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS = "GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS"
export const GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL = "GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL"

export const GET_INSTITUTION_DETAIL:any = "GET_INSTITUTION_DETAIL"
export const GET_INSTITUTION_DETAIL_SUCCESS = "GET_INSTITUTION_DETAIL_SUCCESS"
export const GET_INSTITUTION_DETAIL_FAIL = "GET_INSTITUTION_DETAIL_FAIL"
export const PURGE_INSTITUTIONS="PURGE_INSTITUTIONS"
export const PURGE_INSTITUTIONS_SUCCESS ="PURGE_INSTITUTIONS_SUCCESS"
export const GET_ALL_INSTITUTIONS = "GET_ALL_INSTITUTIONS"

export const CREATE_INSTITUTION:any = "CREATE_INSTITUTION"
export const POST_INSTITUTION_SUCCESS = "POST_INSTITUTION_SUCCESS"
export const POST_INSTITUTION_FAIL = "POST_INSTITUTION_FAIL"


export const CONFIGURE_SERVICES:any = "CONFIGURE_SERVICES"
export const CONFIGURE_SERVICES_SUCCESS = "CONFIGURE_SERVICES_SUCCESS"
export const CONFIGURE_SERVICES_FAIL = "CONFIGURE_SERVICES_FAIL"

export const INSTITUTION_PURGE = "INSTITUTION_PURGE"
export const INSTITUTION_PURGE_SUCCESS = "INSTITUTION_PURGE_SUCCESS"

export const UPDATE_INSTITUTION:any = "UPDATE_INSTITUTION"
export const UPDATE_INSTITUTION_SUCCESS = "UPDATE_INSTITUTION_SUCCESS"
export const UPDATE_INSTITUTION_FAIL = "UPDATE_INSTITUTION_FAIL"

export const ENABLE_INSTITUTION = "ENABLE_INSTITUTION"
export const ENABLE_INSTITUTION_SUCCESS = "ENABLE_INSTITUTION_SUCCESS"
