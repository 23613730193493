import {
  GET_CLUSTERMANAGERS,
  GET_CLUSTERMANAGER_DETAIL,
  GET_CLUSTERMANAGER_DETAIL_SUCCESS,
  GET_CLUSTERMANAGER_DETAIL_FAIL,
  GET_CLUSTERMANAGERS_SUCCESS,
  GET_CLUSTERMANAGERS_FAIL,
  GET_ALL_CLUSTERMANAGERS,
  CREATE_CLUSTERMANAGER,
  POST_CLUSTERMANAGER_SUCCESS,
  CLUSTERMANAGER_PURGE,
  CLUSTERMANAGER_PURGE_SUCCESS,
  POST_CLUSTERMANAGER_FAIL,
  UPDATE_CLUSTERMANAGER,
  UPDATE_CLUSTERMANAGER_SUCCESS,
  UPDATE_CLUSTERMANAGER_FAIL,
  ENABLE_CLUSTERMANAGER,
  ENABLE_CLUSTERMANAGER_SUCCESS,
} from "./actionTypes"

export const getClusterManagers = params => ({
  type: GET_CLUSTERMANAGERS,
  params,
})
export const getAllClusterManagers = () => ({
  type: GET_ALL_CLUSTERMANAGERS,
})
export const getClusterManagersSuccess = clusterManagers => ({
  type: GET_CLUSTERMANAGERS_SUCCESS,
  payload: clusterManagers,
})
export const getClusterManagersFail = error => ({
  type: GET_CLUSTERMANAGERS_FAIL,
  payload: error,
})
export const getClusterManagerDetail = id => ({
  type: GET_CLUSTERMANAGER_DETAIL,
  id,
})

export const getClusterManagerDetailSuccess = clusterManagers => ({
  type: GET_CLUSTERMANAGER_DETAIL_SUCCESS,
  payload: clusterManagers,
})

export const getClusterManagerDetailFail = error => ({
  type: GET_CLUSTERMANAGER_DETAIL_FAIL,
  payload: error,
})

export const createClusterManager = clusterManager => {
  return {
    type: CREATE_CLUSTERMANAGER,
    payload: { clusterManager },
  }
}

export const clusterManagerApiError = error => {
  return {
    type: POST_CLUSTERMANAGER_FAIL,
    payload: error,
  }
}

export const postClusterManagerSuccess = clusterManager => {
  return {
    type: POST_CLUSTERMANAGER_SUCCESS,
    payload: clusterManager,
  }
}

export const clusterManagerPurge = () => ({
  type: CLUSTERMANAGER_PURGE,
})

export const clusterManagerPurgeSuccess = () => ({
  type: CLUSTERMANAGER_PURGE_SUCCESS,
})

export const enableClusterManager = (id, enable) => {
  return {
    type: ENABLE_CLUSTERMANAGER,
    id,
    enable,
  }
}

export const enableClusterManagerSuccess = clusterManager => {
  return {
    type: ENABLE_CLUSTERMANAGER_SUCCESS,
    payload: clusterManager,
  }
}

export const updateClusterManager = clusterManager => {
  return {
    type: UPDATE_CLUSTERMANAGER,
    payload: { clusterManager },
  }
}
export const updateClusterManagerSuccess = clusterManager => {
  return {
    type: UPDATE_CLUSTERMANAGER_SUCCESS,
    payload: clusterManager,
  }
}
export const purgeClusterManagers = () => {
  return {
    type: PURGE_CLUSTERMANAGERS,
  }
}

export const purgeClusterManagerSuccess = () => {
  return {
    type: PURGE_CLUSTERMANAGERS_SUCCESS,
  }
}
