import React from "react"
import PropTypes from "prop-types"
import { TabPane, Row, Col } from "reactstrap"

import ClusterTabPanel from "components/Common/TabPanel"
import ComparativeCard from "./card"
import withDispose from "components/HOCs/WithDispose"
import { useDashboardView } from "hooks/useDashboardView"
const ComparativeList = ({ trxType, amount }) => {
    const {data} = useDashboardView();

  const handleOnChange = value => {}

  return (
    <React.Fragment>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <ClusterTabPanel
            title={"TRANSACTION VALUE BY ANM"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxVolComparativesByAnm"}
                    color={["#50d8d7"]}
                    currency={true}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={12} md={12} sm={12}>
          <ClusterTabPanel
            title={"TRANSACTION COUNT BY ANM"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxCountComparativesByAnm"}
                    color={["#50d8d7"]}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} sm={12}>
          <ClusterTabPanel
            title={"TRANSACTION VALUE BY STATE"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxVolComparativesByState"}
                    color={["#d4b2d8"]}
                    currency={true}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={6}>
          <ClusterTabPanel
            title={"TRANSACTION COUNT BY STATE"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxCountComparativesByState"}
                    color={["#d4b2d8"]}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} sm={12}>
          <ClusterTabPanel
            title={"TRANSACTION VALUE BY CLUSTER MANAGER"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxVolComparativesByClusterManager"}
                    color={["#ff7d0097"]}
                    currency={true}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={6}>
          <ClusterTabPanel
            title={"TRANSACTION COUNT BY CLUSTER MANAGER"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    trxType={types.type}
                    field={"trxCountComparativesByClusterManager"}
                    color={["#ff7d0097"]}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={12} sm={12}>
          <ClusterTabPanel
            title={"TRANSACTION VALUE BY STATUS"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    type={"donut"}
                    trxType={types.type}
                    field={"trxVolTrend"}
                    height={300}
                    currency={true}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={6}>
          <ClusterTabPanel
            title={"TRANSACTION COUNT BY STATUS"}
            tabs={data}
          >
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <ComparativeTabContent
                    type={"donut"}
                    trxType={types.type}
                    height={300}
                    field={"trxCountTrend"}
                  ></ComparativeTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>
    </React.Fragment>
  )
}

function ComparativeTabContent({
  trxType,
  color,
  field,
  currency,
  type,
  height,
}) {
  const handleOnChange = value => {}

  return (
    <React.Fragment>
      {/* <Row> */}
      {/* <Col lg={12} md={12} sm={12}> */}
      <ComparativeCard
        trxType={trxType}
        subtitle="7 Days"
        field={field}
        yValueLabel="value"
        currency={currency}
        trxStatusFilter
        color={color}
        type={type}
        height={height}
      ></ComparativeCard>
      {/* </Col>  */}

      {/* </Row> */}
    </React.Fragment>
  )
}

ComparativeList.propTypes = {
  trxType: PropTypes.string,
}
export default withDispose(ComparativeList)
