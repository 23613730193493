import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"
import { atom, useRecoilState } from "recoil"
import { useDispatch } from "react-redux"
import { logoutUser } from "../../store/actions"
import { executeQuery } from "hooks/useGraphql"
import { getAuthUser } from "helpers/authentication/auth-token-header"
export const viewerState = atom({
  key: "viewerState",
  default: null,
})
const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => {
  const dispatch = useDispatch()
  const [, setViewer] = useRecoilState(viewerState)
  const loadViewer = useCallback(async () => {
    const query = `
    {
      viewer {
        firstName
        lastName
        email
        roleType
        institutionCode
      }
    }
    `
    const { data, errors } = await executeQuery(query)
    if (data?.viewer) {
      setViewer(data?.viewer)
    } else if (errors) {
      const key = Math.random() * 100
    
      dispatch(logoutUser())
    }
  }, [setViewer, dispatch, logoutUser])

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthProtected && !getAuthUser()) {
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
