/* TERMINALS */
export const GET_TERMINALS = "GET_TERMINALS"
export const GET_ALL_TERMINALS = "GET_ALL_TERMINALS"
export const GET_TERMINALS_SUCCESS = "GET_TERMINALS_SUCCESS"
export const GET_TERMINALS_FAIL = "GET_TERMINALS_FAIL"

export const GET_TERMINAL_DETAIL = "GET_TERMINAL_DETAIL"
export const GET_TERMINAL_DETAIL_SUCCESS = "GET_TERMINAL_DETAIL_SUCCESS"
export const GET_TERMINAL_DETAIL_FAIL = "GET_TERMINAL_DETAIL_FAIL"

export const BATCH_UPLOAD_TERMINAL = "BATCH_UPLOAD_TERMINAL"
export const BATCH_UPLOAD_TERMINAL_SUCCESS = "BATCH_UPLOAD_TERMINAL_SUCCESS"
export const BATCH_UPLOAD_TERMINAL_FAIL = "BATCH_UPLOAD_TERMINAL_FAIL"

export const PROCESS_UPLOAD_TERMINAL = "PROCESS_UPLOAD_TERMINAL"
export const PROCESS_UPLOAD_TERMINAL_SUCCESS = "PROCESS_UPLOAD_TERMINAL_SUCCESS"
export const PROCESS_UPLOAD_TERMINAL_FAIL = "PROCESS_UPLOAD_TERMINAL_FAIL"

export const CREATE_TERMINAL= "CREATE_TERMINAL";
export const POST_TERMINAL_SUCCESS = "POST_TERMINAL_SUCCESS"
export const POST_TERMINAL_FAIL = "POST_TERMINAL_FAIL"

export const PURGE_TERMINALS = "PURGE_TERMINALS"
export const PURGE_TERMINALS_SUCCESS = "PURGE_TERMINALS_SUCCESS"


export const UPDATE_TERMINAL= "UPDATE_TERMINAL";
export const UPDATE_TERMINAL_SUCCESS= "UPDATE_TERMINAL_SUCCESS";
export const UPDATE_TERMINAL_FAIL= "UPDATE_TERMINAL_FAIL";

export const ENABLE_TERMINAL= "ENABLE_TERMINAL";
export const ENABLE_TERMINAL_SUCCESS = "ENABLE_TERMINAL_SUCCESS"


export const TERMINAL_ASSIGNMENT_REPORT= "TERMINAL_ASSIGNMENT_REPORT";
export const TERMINAL_ASSIGNMENT_REPORT_SUCCESS= "TERMINAL_ASSIGNMENT_REPORT_SUCCESS";
export const TERMINAL_ASSIGNMENT_REPORT_FAIL= "TERMINAL_ASSIGNMENT_REPORT_FAIL";


export const GET_ASSIGNED_TERMINALS = "GET_ASSIGNED_TERMINALS"
export const GET_ALL_ASSIGNED_TERMINALS = "GET_ALL_ASSIGNED_TERMINALS"
export const GET_ASSIGNED_TERMINALS_BY_CATEGORY =
  "GET_ASSIGNED_TERMINALS_BY_CATEGORY"
export const GET_ASSIGNED_TERMINALS_SUCCESS =
  "GET_ASSIGNED_TERMINALS_SUCCESS"
export const GET_ASSIGNED_TERMINALS_FAIL = "GET_ASSIGNED_TERMINALS_FAIL"

export const GET_ASSIGNED_TERMINAL_DETAIL = "GET_ASSIGNED_TERMINAL_DETAIL"
export const GET_ASSIGNED_TERMINAL_DETAIL_SUCCESS =
  "GET_ASSIGNED_TERMINAL_DETAIL_SUCCESS"
export const GET_ASSIGNED_TERMINAL_DETAIL_FAIL =
  "GET_ASSIGNED_TERMINAL_DETAIL_FAIL"

export const SAVE_ASSIGNED_TERMINAL_CONFIGURATION = "SAVE_ASSIGNED_TERMINAL_CONFIGURATION"
export const ADD_ASSIGNED_TERMINAL = "ADD_ASSIGNED_TERMINAL"
export const ADD_ASSIGNED_TERMINAL_SUCCESS =
  "ADD_ASSIGNED_TERMINAL_SUCCESS"

export const REMOVE_ASSIGNED_TERMINAL = "REMOVE_ASSIGNED_TERMINAL"
export const REMOVE_ASSIGNED_TERMINAL_SUCCESS =
  "REMOVE_ASSIGNED_TERMINAL_SUCCESS"

export const POST_ASSIGNED_TERMINAL_SUCCESS =
  "POST_ASSIGNED_TERMINAL_SUCCESS"
export const POST_ASSIGNED_TERMINAL_FAIL = "POST_ASSIGNED_TERMINAL_FAIL"


export const UPDATE_ASSIGNED_TERMINAL = "UPDATE_ASSIGNED_TERMINAL"
export const UPDATE_ASSIGNED_TERMINAL_SUCCESS =
  "UPDATE_ASSIGNED_TERMINAL_SUCCESS"
export const UPDATE_ASSIGNED_TERMINAL_FAIL =
  "UPDATE_ASSIGNED_TERMINAL_FAIL"
