import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Col, Container, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import {
  institutionPurge,
  createInstitution,
  getAllCashOutInstitutions,
  getInstitutionDetail,
  updateInstitution,
  getAllInstitutions,
  configureInstitutionServices,
  getInstitutionServiceConfiguration,
  getSettlementAccount,
} from "store/actions"
import ConfigurationForm from "./form"
import InstitutionLogic from "../institution"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import useSWR from "swr"
import { GET_ALL_INSTITUTIONS } from "helpers/url_helper"
import { Utils } from "helpers/utility"
import Loading from "components/Common/Loading"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { fetcher } from "helpers/api_helper"
import { Institution } from "pages/InstitutionManagement/institution"
import { SettlementAccountType } from "enums/settlement-account-type"
import { useRemoting } from "hooks/useRemoting"

const ServiceConfiguration: React.FC<DisposeProps & any> = props => {
  const {
    loading,
    success,
    institutionLoading,
    editLoading,
    error,
    serviceConfiguration,
    onConfigureServices,
    onGetServiceConfiguration,
    account
  } = props
  const { MapToDropDown } = InstitutionLogic
  const [institution, setInstitutionDetails] = useState<Institution>()
  const [isRotating, setIsRotating] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { handleSubmit, completed, setCompleted } = useRemoting();
  const { data } = useSWR(GET_ALL_INSTITUTIONS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })

  const handleValidSubmit = values => {
    let cashOutSettlementConfig = values.CashOutSettlement;
    const settlementAccountID = (cashOutSettlementConfig?.SettlementAccountID == 0) ? (cashOutSettlementConfig as any)?.SettlementAccount?.ID : cashOutSettlementConfig?.SettlementAccountID;
    cashOutSettlementConfig = { ...cashOutSettlementConfig, SettlementAccount: undefined, SettlementAccountID: settlementAccountID }
    const configuration = { ...values, InstitutionId: institution?.ID }
    configuration.CashOutSettlement = cashOutSettlementConfig;
    onConfigureServices(configuration)
  }


  useEffect(() => {
    if (completed == true) {
      setTimeout(() => {
        toggle();
      }, 2000)
    }
  }, [completed])

  if (Utils.List.isEmpty((data as any))) {
    return <div className="page-content">
      <Container fluid> <Loading>Fetching institutions</Loading>
      </Container>
    </div>
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Service Configuration"}
            image={clusterian}
            breadcrumbItem={institution ? `Service Configuration for ${institution?.Name}` : 'Select Institution'}
          />

          {Utils.Object.isEmpty(institution) ?
            <Row>
              <Col lg={3} md={4} sm={12}>
                <FormGroup>
                  <ClusterDropdown
                    placeholder={"-Select institution-"}
                    items={MapToDropDown((data as any)?.Data)}
                    name={"IntegrationType"}
                    onChange={(name, value) => {
                      const institutionDetail = (data as any).Data.find(institution => institution.ID == value);
                      setInstitutionDetails(institutionDetail);
                      onGetServiceConfiguration(institutionDetail?.Code)
                      if (!Utils.Object.isEmpty(institutionDetail)) {
                        toggle();
                        handleSubmit(institutionDetail?.ID);
                      }
                    }}
                    required={true}
                  />
                </FormGroup>
              </Col>
            </Row>
            :
            completed && <React.Fragment>
              <div style={{ display: "flex", justifyContent: "right", alignItems: "center", cursor: "pointer" }}>
                <p color="danger" onClick={() => {
                  setInstitutionDetails(undefined);
                  setCompleted();
                }}><u><b>Back to institution list</b></u></p>
              </div>
              <ConfigurationForm
                account={account}
                success={success}
                institutions={(data as any)?.Data ?? []}
                error={error}
                isUpdate={isUpdate}
                loading={loading}
                isRotating={isRotating}
                institutionCode={institution?.Code}
                editLoading={editLoading}
                configurationLoading={institutionLoading}
                details={serviceConfiguration}
                setIsRotating={setIsRotating}
                goBack={() => setInstitutionDetails(undefined)}
                handleValidSubmit={handleValidSubmit}
                toggle={toggle}
              ></ConfigurationForm>
            </React.Fragment>
          }
        </Container>
      </div>

      <LoadingModal isOpen={isOpen} toggle={toggle} name={institution?.Name} />


    </React.Fragment>
  )
}


ServiceConfiguration.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onConfigureInstitution: PropTypes.func,
  onPurge: PropTypes.func,
  onBack: PropTypes.func,
  institutionDetail: PropTypes.object,
  institutions: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  institutionLoading: PropTypes.bool,
  onGetAllInstitutions: PropTypes.func,
  onGetAllCashOutInstitutions: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({
  institutions: {
    institutions,
    error,
    success,
    totalCount,
    editLoading,
    loading,
    serviceConfiguration
  },
  cashOutInstitutions: {
    cashOutInstitutions
  },
  settlements: { accounts, account }
}) => {
  return {
    institutions: [...institutions],
    accounts,
    cashOutInstitutions,
    error,
    account,
    totalCount,
    institutionLoading: loading,
    success,
    editLoading,
    serviceConfiguration
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(institutionPurge()),
  onConfigureInstitution: user => dispatch(createInstitution(user)),
  onGetInstitutionDetail: id => dispatch(getInstitutionDetail(id)),
  onGetAllCashOutInstitutions: () => dispatch(getAllCashOutInstitutions()),
  onGetAllInstitutions: () => dispatch(getAllInstitutions()),
  onUpdateInstitution: institution => dispatch(updateInstitution(institution)),
  onConfigureServices: (configuration) => dispatch(configureInstitutionServices(configuration)),
  onGetServiceConfiguration: (code) => dispatch(getInstitutionServiceConfiguration(code))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ServiceConfiguration))))



const LoadingModal = ({ isOpen, toggle, name }) => {

  return <Modal
    isOpen={isOpen}
    role="dialog"
    autoFocus={true}
    centered={true}
    className="exampleModal"
    toggle={() => {
      toggle();
    }}
  >
    <div className="modal-content">
      <ModalHeader
        toggle={() => {
          close();
        }}
      >
        Remoting To {name} Institution
      </ModalHeader>
      <ModalBody>
        <Row className="justify-content-center">
          <Col md={12}>
            <Loading />
          </Col>
        </Row>
      </ModalBody>

    </div>
  </Modal>
}