import { call, put, takeEvery, takeLeading } from "redux-saga/effects"

// Login Redux States
import {
  LOGIN_USER,
  LOGOUT_USER,
  PURGE_LOGIN_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import {
  loginApiError,
  loginSuccess,
  logoutUserSuccess,
  purgeLoginSuccess,
} from "./actions"

// import { useCookies } from 'react-cookie';
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { postClusterLogin, postClusterLogout } from "helpers/backend_helper"

import { postFakeLogin, postJwtLogin } from "helpers/fakebackend_helper"
import authHeader from "helpers/authentication/auth-token-header"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user } }) {
  try {
    
      const response = yield call(postClusterLogin, user);
      yield put(loginSuccess(response))
    
  } catch (error) {
    console.debug(error)
    yield put(
      loginApiError(
        error?.data ?? "Oops, we are experiencing some technical difficulty"
      )
    )
  }
}

function* logoutUser() {
  try {
  
      const response = yield call(postClusterLogout, {
        token: authHeader()?.RefreshToken.tokenString,
      })
      yield put(logoutUserSuccess(response))
    
  } catch (error) {
    yield put(loginApiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, data, type)
      setAuthUser(JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      setAuthUser(JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(loginApiError(error))
  }
}

function* doPurge() {
  yield put(purgeLoginSuccess())
}
function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLeading(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(PURGE_LOGIN_USER, doPurge)
}

export default authSaga
