import PropTypes from "prop-types"
import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { toast } from "react-toastify"
import ClusterInput from "components/Common/Input/Input"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import { Container, Row, Col, Card, CardBody, Label } from "reactstrap"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import clusterian from "../../../assets/images/icons/clusterian.png"
import { withTranslation } from "react-i18next"
import { postDebitRequests } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import { Utils } from "helpers/utility"
import { InvoiceType } from "enums/invoice-type"
import ClusterButton from "components/Common/Button/Button"
import ClusterFileUploader from "components/Common/FileUploader"

const DirectDebit = props => {
  let { onPostDirectDebit, error, success, editLoading, loading } = props
  const [file, setFile] = useState(null)
  const [invoice, setInvoice] = useState({ Base64: null, FileName: "", ContentLength: null, ContentType: "", })
  const [debitData, setDebitData] = useState<any>({ TransactionAmount: null, Narration: "", InvoiceType: "", InvoiceFileName: invoice?.FileName, InvoiceBase64: invoice?.Base64 })
  const invoiceRef = useRef(null)
  const [disabled, setDisabled] = useState(true)
  const [debitDataFilled, setDebitDataFilled] = useState({ TransactionAmount: false, Narration: false, InvoiceType: false })
  useEffect(() => {
    const debitOptions = Object.values(debitDataFilled).every(
      option => option === true
    )
    setDisabled(!debitOptions)
  }, [debitDataFilled])
  const addInvoice = async value => {
    await storeInvoice({
      Base64: value?.Base64,
      FileName: value?.FileName,
    })
    await setFile(value?.File)
  }

  const storeInvoiceType = ({ InvoiceType }) => {
    setDebitData(current => ({ ...current, InvoiceType }))
  }
  const storeInvoice = ({ Base64, FileName }) => {
    setInvoice(invoice => ({
      ...invoice,
      InvoiceBase64: Base64,
      InvoiceFileName: FileName,
    }))
    setDebitData(debitData => ({
      ...debitData,
      InvoiceBase64: Base64,
      InvoiceFileName: FileName,
    }))
  }
  const runPost = async () => {
    const transactionAmount = parseFloat(debitData.TransactionAmount)
    if (transactionAmount == 0) {
      toast.error("Transaction amount cannot begin with zero")
    }
    if (!isNaN(transactionAmount)) {
      if (/^[1-9]\d*(\.\d+)?$/.test(debitData.TransactionAmount)) {
        if (file?.size > 2097152) {
          setTimeout(() => (toast.error(`Invoice shouldn't exceed 2mb`), 2000))
          setDebitData({
            TransactionAmount: null,
            Narration: "",
            InvoiceType: "",
            InvoiceFileName: "",
            InvoiceBase64: null,
          })
          return
        }
        await onPostDirectDebit(debitData)
        if (error?.data) {
          toast.error(error?.data?.Message)
          setDebitData({
            TransactionAmount: null,
            Narration: "",
            InvoiceType: "",
            InvoiceFileName: "",
            InvoiceBase64: null,
          })
          setDebitDataFilled({ TransactionAmount: false, Narration: false, InvoiceType: false, })
        }
        if (success) {
          setDebitData({ TransactionAmount: null, Narration: "", InvoiceType: "", InvoiceFileName: "", InvoiceBase64: null, })
          setDebitDataFilled({ TransactionAmount: false, Narration: false, InvoiceType: false, })
        }
        storeInvoice({
          Base64: null, FileName: null,
        })
        setDebitData({ ...debitData, InvoiceBase64: null })
        setTimeout(getResponse, 20000)
      }
    }
  }
  function getResponse() {
    if (success !== null || Object.entries(error).length == 0) {
      toast.success("Direct Debit request uploaded successfully")
      return
    }
    if (error?.Data && success === null) {
      toast.error("Direct Debit request could not be uploaded")
      return
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Direct Debit"}
            image={clusterian}
            breadcrumbItem={"Upload"}
          />
          <Card
            outline
            style={{ background: "transparent", borderColor: "#999999" }}
            className="border"
          >
            <CardBody>
              <Row>
                <Col lg={3} md={4}>
                  <Label>Upload Invoice</Label>
                  <ClusterFileUploader
                    id="invoice"
                    ref={invoiceRef}
                    value={debitData.InvoiceBase64}
                    name="invoice"
                    accept={[".pdf"]}
                    onHandleChange={addInvoice}
                  />
                </Col>
                <Col lg={3} md={4}></Col>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <Col lg={3} md={4}>
                  <ClusterInput
                    label={"Transaction Amount"}
                    id={"transactionAmount"}
                    name={"Transaction Amount"}
                    value={debitData.TransactionAmount}
                    required={true}
                    type="number"
                    onChange={e => {
                      const inputValue = e.target.value
                      setDebitData({
                        ...debitData,
                        TransactionAmount: inputValue,
                      })
                      setDebitDataFilled({
                        ...debitDataFilled,
                        TransactionAmount: Boolean(inputValue),
                      })
                    }}
                  />
                </Col>
                <Col lg={3} md={4}>
                  <ClusterInput
                    label={"Narration"}
                    id={"narration"}
                    name={"Narration"}
                    value={debitData.Narration}
                    required={true}
                    type="text"
                    onChange={e => {
                      setDebitData({
                        ...debitData,
                        Narration: e.target.value,
                      })
                      setDebitDataFilled({
                        ...debitDataFilled,
                        Narration: true,
                      })
                    }}
                  />
                </Col>
                <Col lg={3} md={4}>
                  <Label>Invoice Type</Label>
                  <ClusterDropdown
                    placeholder={"- Select an invoice type - "}
                    value={debitData?.InvoiceType}
                    required={true}
                    id={"invoiceType"}
                    name={"InvoiceType"}
                    items={Utils.Enums.toOptions(InvoiceType)}
                    onChange={(e: InvoiceType, id) => {
                      storeInvoiceType({
                        InvoiceType:
                          InvoiceType[Utils.Enums.toString(InvoiceType, id)],
                      })
                      setDebitDataFilled({
                        ...debitDataFilled,
                        InvoiceType: true,
                      })
                    }}
                  />
                </Col>
                <Col lg={3} md={4} style={{ marginTop: 17 }}>
                  <ClusterButton
                    disabled={
                      disabled ||
                        debitData?.InvoiceBase64 === null ||
                        debitData?.TransactionAmount === null ||
                        debitData?.Narration === ""
                        ? true
                        : false
                    }
                    loading={editLoading}
                    onHandleClick={runPost}
                    color={"primary"}
                    addon={"bx bxs-right-down-arrow-circle"}
                  >
                    Request Debit
                  </ClusterButton>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  )
}

DirectDebit.propTypes = {
  onPostDirectDebit: PropTypes.func,
  loading: PropTypes.bool,
  editLoading: PropTypes.bool,
  success: PropTypes.any,
  error: PropTypes.any
}

const mapStateToProps = ({ debitRequests: { success, error, loading, editLoading } }) => {
  return { success, error, loading, editLoading }
}

const mapDispatchToProps = dispatch => ({
  onPostDirectDebit: payload => dispatch(postDebitRequests(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(DirectDebit))))
