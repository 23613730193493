import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Button,
  Col,
  Container, Row
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images

//i18n
import { withTranslation } from "react-i18next"
import { settlementPurge, getSettlementAccount, walletSettlementConfiguration, getWalletSettlementConfiguration } from "store/actions"
import SettlementAccountForm from './form';
import withDispose from 'components/HOCs/WithDispose';
import moment from 'moment'
import WithdrawalLogic from "../settlement"
const SettlementAccountConfiguration = props => {
  const { onConfigureSettlement, wallet, onGetConfiguration, success, editLoading, loading, error } = props

  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = WithdrawalLogic;
  const [errorList, setErrorList] = useState({});
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);


  useEffect(() => {
    const params = SearchCriteria({
      page: 0,
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD")
    }, 10000);
    onGetConfiguration(params);
  }, [])


  const handleValidSubmit = (config) => {
    onConfigureSettlement(config)
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Configuration"}
            breadcrumbItem={"Configure settlements"}
          />

          <SettlementAccountForm
            success={success}
            error={error}
            isUpdate={isUpdate}
            loading={loading}
            errorList={errorList}
            isRotating={isRotating}
            editLoading={editLoading}
            details={wallet}
            setErrorList={setErrorList}
            setIsRotating={setIsRotating}
            handleValidSubmit={handleValidSubmit}
          />



        </Container>
      </div>
    </React.Fragment>
  )
}



SettlementAccountConfiguration.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({ settlements: { accounts, wallet, error, success, accountDetail, editLoading, totalCount, loading } }) => {
  return {
    accounts,
    error,
    success,
    accountDetail,
    totalCount,
    loading,
    editLoading,
    wallet
  }
}

const mapDispatchToProps = dispatch => ({
  onConfigureSettlement: (config) => dispatch(walletSettlementConfiguration(config)),
  onGetConfiguration: (params) => dispatch(getWalletSettlementConfiguration(params)),
  onPurge: () => dispatch(settlementPurge())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(SettlementAccountConfiguration))))
