// images
import cluster from "assets/images/icons/cluster.png"
import networkmanagers from "assets/images/icons/network.png"
import agent from "assets/images/icons/clusterian.png"
import almighty from "assets/images/icons/saturn.png"
import PlusIcon from "assets/icons/plusIcon.svg"
import defaultBackground from "assets/images/companies/defaultBackground.jpg"

const ImageHelper = {
  cluster,
  networkmanager: networkmanagers,
  agent,
  global: almighty,
  defaultLogoBackground: defaultBackground,
  plusIconLogo: PlusIcon,
}
export default ImageHelper
