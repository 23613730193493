import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  getAgents,
  agentPurge,
  getAgentLoans,
} from "store/actions"
import {
  GET_AGENT_LOAN_REPORT,
} from "helpers/url_helper"
import AgentLoanLogic from "../agent-loan"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import useReportData from "hooks/UseReportData"

const AgentLoanList = props => {
  const {
    agentLoans,
    onGetAgentLoans,
    error,
    success,
    totalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions,
  } = AgentLoanLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetAgentLoans, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(
      params?.fromDate,
      params?.toDate
    )
  )
  
  useEffect(() => {
     if (success) toast.success(success)
      if (error) toast.error(error)
    
  }, [error, success])
  useEffect(() => {
    populateRows()
  }, [props])

  const populateRows = () => {
    let rws = []
    if (agentLoans) {
      agentLoans.forEach(agent => {
        let row = MapToRows(agent)
       
        rws.push(row)
      })
    }
    setRows(rws)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Agent Overdrafts"} breadcrumbItem={"View Agent Overdrafts"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_AGENT_LOAN_REPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
     
    </React.Fragment>
  )
}

AgentLoanList.propTypes = {
  agentLoans: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetAgentLoans: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number
}

const mapStateToProps = ({
  agents: {
    agentLoans,
    error,
    success,
    totalCount,
    loading,
  },
}) => {
  return {
    agentLoans,
    error,
    success,
    totalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAgentLoans: query => dispatch(getAgentLoans(query)),
 
  onPurge: () => dispatch(agentPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(AgentLoanList))))
