import React, { useState, useEffect, useMemo  } from "react"
import { useSelector } from "react-redux"
import PropTypes from "prop-types"
import { Container, Row, Col, Button } from "reactstrap"
import { connect } from "react-redux"
import { withRouter} from "react-router-dom"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import TransactionLogic from "../transactions/transactions"
import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"
import {getWalletTopUpReport} from "store/actions"
import { EXPORT_WALLET_TOP_UP_REPORT } from "helpers/url_helper"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const TransactionsList = props => {
  const {
    transactions,
    error,
    success,
    totalCount,
    onGetWalletTransactions,
    loading
  } = props
    const {HEADERS, SearchOptions, MapToDropDown,SearchCriteria, MapToRows}  = TransactionLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetWalletTransactions, HEADERS: HEADERS, SearchCriteria: SearchCriteria })
  const { institutionsData, institutions } = useInstitutionsData()
    const [searchOptions, setSearchOptions] = useState(
      SearchOptions(params?.fromDate, params?.toDate, institutionsData)
      )
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

    useEffect(() => {
      populateRows()
    }, [transactions])
  
    const populateRows = () => {
      if (transactions) {
        let rws = []
        transactions.forEach(transaction => {
          let row = MapToRows(transaction)
          rws.push(row)
        })
        setRows(rws)
      }
    }
  useEffect(() => {
    populateRows()
  }, [props])
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                <Breadcrumbs
            title={"Settlements"}
            breadcrumbItem={"Transactions"}
          />

          <Row>
            <Col lg="12">
                <Report
                      tableData={{
                        columns: columns,
                        rows: rows,
                        paginationOptions: {
                          totalCount: totalCount,
                          onPageChange: page => handlePageChange(page),
                          pageNumber: pageNumber,
                          maxSize: maxSize,
                        },
                        pageTitle: undefined,
                        isLoading: loading,
                      }}
                      searchData={{
                        searchTitle: "Filter",
                        options: searchOptions,
                        onSearch: params => onAction(params),
                      }}
                      csv={{
                        url : EXPORT_WALLET_TOP_UP_REPORT,
                        query: SearchCriteria(params, maxSize)
                      }}
                />
            </Col>
          </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

TransactionsList.propTypes = {
  transactions : PropTypes.array,
  totalCount: PropTypes.number,
  loading: PropTypes.bool,
  onGetWalletTransactions : PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    transactions : state.settlements.transactions,
    totalCount : state.settlements.totalCount,
    loading : state.settlements.loading,
    success : state.settlements.success,
    error : state.settlements.error
  }}

const mapDispatchToProps = dispatch => ({
  onGetWalletTransactions : query => dispatch(getWalletTopUpReport(query))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(TransactionsList))))

