import axios from "axios"
import { post, del, get, put, graphqlQuery, patch } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
//Cluster Login
const postClusterLogin = data => post(url.POST_CLUSTER_LOGIN, data)

const postClusterRegister = data => post(url.POST_CLUSTER_REGISTER, data)

const postClusterLogout = data =>
  post(url.POST_CLUSTER_LOGOUT, null, { headers: data })

const postClusterChangePassword = data =>
  post(url.POST_CLUSTER_CHANGE_PASSWORD, null, { headers: data })

const postClusterForgetPwd = data =>
  get(url.POST_CLUSTER_PASSWORD_FORGET, { params: { ...data } })

// get
export const getClusterUsers = params =>
  post(`${url.GET_CLUSTER_USERS}`, params)

export const getAllClusterUsers = institutionId =>
  get(`${url.GET_ALL_CLUSTER_USERS}`, { params: { institutionId } })
export const getClusterUserDetails = id =>
  get(`${url.GET_CLUSTER_USERDETAILS}/${id}`, { params: { id } })

export const putClusterUser = user =>
  put(`${url.PUT_CLUSTER_USER}/${user.id}`, user)
export const postClusterUser = user => post(url.POST_CLUSTER_USER, user)
export const enableClusterUser = id =>
  post(`${url.ENABLE_CLUSTER_USER}/${id}`, id)
export const disableClusterUser = id =>
  post(`${url.DISABLE_CLUSTER_USER}/${id}`, id)

// User Roles
export const getClusterRoles = params =>
  post(`${url.GET_CLUSTER_ROLES}`, params)
export const getAllClusterRoles = institutionId =>
  get(`${url.GET_ALL_CLUSTER_ROLES}`, { params: { institutionId } })
export const postClusterRole = role => post(url.POST_CLUSTER_ROLE, role)
export const getClusterRoleDetail = id =>
  get(`${url.GET_CLUSTER_ROLE_DETAILS}/${id}`, { params: { id } })
export const putClusterRole = role =>
  put(`${url.PUT_CLUSTER_ROLE}/${role.id}`, role)
export const enableClusterRole = id =>
  post(`${url.ENABLE_CLUSTER_ROLE}/${id}`, id)
export const disableClusterRole = id =>
  post(`${url.DISABLE_CLUSTER_ROLE}/${id}`, id)

//Branches
export const getClusterBranches = institutionId =>
  get(`${url.GET_CLUSTER_BRANCHES}`, { params: { institutionId } })
export const postClusterBranch = branch => post(url.POST_CLUSTER_BRANCH, branch)
export const getClusterBranchDetail = id =>
  get(`${url.GET_CLUSTER_BRANCHDETAILS}/${id}`, { params: { id } })
export const putClusterBranch = branch =>
  put(`${url.PUT_CLUSTER_BRANCH}/${branch.id}`, branch)
export const enableClusterBranch = id =>
  post(`${url.ENABLE_CLUSTER_BRANCH}/${id}`, id)
export const disableClusterBranch = id =>
  post(`${url.DISABLE_CLUSTER_BRANCH}/${id}`, id)

// AGENTS
export const getAgents = params =>
  post(url.GET_CLUSTER_AGENTS, params, {}, true)
export const getAgentLoans = params =>
  post(url.GET_AGENT_LOAN_REPORT, params, {}, true)
export const getAllAgents = () => get(`${url.GET_ALL_AGENTS}`, {}, true)
export const getAgentDetail = id =>
  get(`${url.GET_CLUSTER_AGENT}/${id}`, { params: { id } }, true)
export const activateAgent = payload =>
  patch(`${url.ACTIVATE_CLUSTER_AGENT}/${payload.id}/${payload.activate}`, null)
export const enableAgent = id =>
  patch(`${url.ACTIVATE_CLUSTER_AGENT}/${id}/${true}`, null, {}, true)
export const disableAgent = id =>
  patch(`${url.ACTIVATE_CLUSTER_AGENT}/${id}/${false}`, null, {}, true)
export const accountValidation = accountNumber =>
  get(
    `${url.ACCOUNT_VALIDATION}/${accountNumber}`,
    { params: { accountNumber } },
    true
  )
export const postAgent = agent => post(url.POST_CLUSTER_AGENT, agent, {}, true)
export const putAgent = agent =>
  put(`${url.PUT_AGENT}/${agent.ID}`, agent, {}, true)
export const resetLimit = id =>
  patch(`${url.RESET_AGENT_LIMIT}?id=${id}`, null, {}, true)
export const deviceReset = id =>
  patch(`${url.RESET_AGENT_DEVICE}?id=${id}`, null, {}, true)
export const upgradeToSuperAgent = payload =>
  post(`${url.UPGRADE_TO_SUPERAGENT}?id=${payload.id}`, payload, {}, true)
export const terminalActivation = payload =>
  patch(
    `${url.TERMINAL_ACTIVATION}?id=${payload.id}&terminalID=${payload.terminalID}`,
    payload,
    {},
    true
  )
export const terminalDeactivation = payload =>
  patch(`${url.TERMINAL_DEACTIVATION}?id=${payload.id}`, payload, {}, true)
export const putUnlinkParentAgent = agent =>
  put(
    `${url.UNLINK_PARENT_AGENT}/${agent.id}?accountNumber=${agent.accountNumber}`,
    agent,
    {},
    true
  )
export const generatePOSAccounts = (payload, id) =>
  post(`${url.GENERATE_POS_ACCOUNTS}/${id}/generateposaccounts`,payload, {}, true)
  

//Reports
//get reports using graphql
export const getReports = (query, variables, deps) =>
  graphqlQuery(query, variables, deps)
//get receipts
export const getReceipts = parameters =>
  post(`${url.GET_RECEIPTREPORT}`, parameters, {}, true)
export const getCashOutReport = parameters =>
  post(`${url.GET_CASHOUTREPORT}`, parameters, {}, true)
export const getFundsTransferReport = parameters =>
  post(`${url.GET_FUNDSTRANSFERREPORT}`, parameters, {}, true)
export const getPayWithTransferReport = parameters =>
  post(`${url.GET_PAYWITHTRANSFERREPORT}`, parameters, {}, true)
export const getCardTransferReport = parameters =>
  post(`${url.GET_CARDTRANSFERREPORT}`, parameters, {}, true)
export const getTransactionReport = parameters =>
  post(`${url.GET_TRANSACTIONREPORT}`, parameters, {}, true)
export const getCollectionReport = parameters =>
  post(`${url.GET_COLLECTION_REPORT}`, parameters, {}, true)
export const getTransactionSummaryReport = parameters =>
  post(`${url.GET_TRANSACTION_SUMMARY_REPORT}`, parameters, {}, true)
export const getVolumeComparativesReport = parameters =>
  post(`${url.GET_VOLUME_COMPARATIVES_REPORT}`, parameters, {}, true)
export const logForDownload = parameters =>
  post(`${url.LOG_FOR_DOWNLOAD}`, parameters, {}, true)
export const getDownloadedReports = parameters =>
  post(`${url.GET_DOWNLOADS}`, parameters, {}, true)

export const getPermissions = userCategory =>
  get(`${url.GET_FUNCTION_ITEMS}`, { params: { userCategory } })

export const getFunctions = userCategory =>
  get(`${url.GET_FUNCTION_ITEMS}`, { params: { userCategory } })

export const getFunctionsMenuByUser = (institutionId, username) =>
  get(`${url.GET_FUNCTION_MENU_ITEMS_PER_USER}`, {
    params: { institutionId, username },
  })

export const getFunctionsActionsByUser = (institutionId, username) =>
  get(`${url.GET_FUNCTION_ACTION_ITEMS_PER_USER}`, {
    params: { institutionId, username },
  })

export const getFunctionsByUser = (institutionId, username) =>
  get(`${url.GET_FUNCTION_ITEMS_PER_USER}`, {
    params: { institutionId, username },
  })

// Notification
export const getNotifications = params =>
  post(`${url.GET_NOTIFICATION}`, params, {}, true)
export const postNotification = notification =>
  post(`${url.POST_NOTIFICATION}`, notification, {}, true)
export const exportNotificationReport = params =>
  post(`${url.EXPORT_NOTIFICATION_REPORT}`, params, {}, true)
export const getNotificationDetails = id =>
  get(`${url.GET_NOTIFICATION_DETAILS}/${id}`, { params: { id } }, true)

// CLUSTER MANAGERS
export const getClusterManagers = parameters =>
  post(`${url.GET_CLUSTER_MANAGERS}`, parameters, {}, true)
export const getClusterManagerDetail = id =>
  get(`${url.GET_CLUSTER_MANAGERDETAILS}/${id}`, { params: { id } }, true)
export const getAllClusterManagers = () =>
  get(`${url.GET_ALL_CLUSTER_MANAGERS}`, {}, true)
export const putClusterManager = clusterManager =>
  put(
    `${url.PUT_CLUSTER_MANAGER}/${clusterManager.ID}`,
    clusterManager,
    {},
    true
  )
export const postClusterManager = clusterManager =>
  post(url.POST_CLUSTER_MANAGER, clusterManager, {}, true)
export const enableClusterManager = id =>
  patch(
    `${url.ENABLE_CLUSTER_MANAGER}?id=${id}&activate=${true}`,
    null,
    {},
    true
  )
export const disableClusterManager = id =>
  patch(
    `${url.DISABLE_CLUSTER_MANAGER}?id=${id}&activate=${false}`,
    null,
    {},
    true
  )

// CLUSTERS
export const getClusters = parameters =>
  post(`${url.GET_CLUSTERS}`, parameters, {}, true)
export const getClusterDetail = id =>
  get(`${url.GET_CLUSTERDETAILS}/${id}`, { params: { id } }, true)
export const getAllClusters = () => get(`${url.GET_ALL_CLUSTERS}`, {}, true)
export const putCluster = cluster =>
  put(`${url.PUT_CLUSTER}/${cluster.ID}`, cluster, {}, true)
export const postCluster = cluster => post(url.POST_CLUSTER, cluster, {}, true)
export const enableCluster = id =>
  patch(`${url.ENABLE_CLUSTER}?id=${id}&activate=${true}`, null, {}, true)
export const disableCluster = id =>
  patch(`${url.DISABLE_CLUSTER}?id=${id}&activate=${false}`, null, {}, true)

// DIRECT DEBIT
export const postDebitRequests = payload =>
  post(`${url.POST_DEBIT_REQUESTS}`, payload, {}, true)
export const patchDebitRequestsApproval = (id, approve) =>
  patch(
    `${url.PATCH_DEBIT_REQUESTS_APPROVAL}?id=${id}&approve=${approve}`,
    null,
    {},
    true
  )
export const getDebitRequests = parameters =>
  post(`${url.GET_DEBIT_REQUESTS}`, parameters, {}, true)
export const getPendingDebits = () =>
  get(`${url.GET_PENDING_DEBIT_REQUESTS}`,{}, true)
export const getDebitRequestDetail = id =>
  get(`${url.GET_DEBIT_REQUESTS_DETAIL}/${id}`, { params: { id } }, true)
export const downloadInvoice = id =>
  post(`${url.DOWNLOAD_INVOICE}/${id}`, { id }, { responseType: "blob" }, true)
// BIN ROUTES
export const getBINRoutes = parameters =>
  post(`${url.GET_BINROUTES}`, parameters, {}, true)
export const getBINRouteDetails = parameters =>
  post(`${url.GET_BINROUTE_DETAILS}`, parameters, {}, true)
export const getBins = id => get(`${url.GET_BINS}?bin=${id}`, {}, true)
export const getRoutes = () => get(`${url.GET_ROUTES}`, {}, true)
export const removeBinRoute = id =>
  post(`${url.REMOVE_BINROUTE_DETAIL}/${id}`, null, {}, true)
export const configureBinRoutes = binRoutes =>
  post(url.CONFIGURE_ROUTES, binRoutes, {}, true)

// SETTLEMENTS
export const getSettlementWithdrawals = parameters =>
  post(`${url.GET_SETTLEMENT_WITHDRAWALS}`, parameters, {}, true)
export const getWalletBalance = () => get(`${url.GET_WALLET_BALANCE}`, {}, true)
export const getWallet = () => get(`${url.GET_SETTLEMENT_WALLET}`, {}, true)
export const sendWithdrawalOTP = otp =>
  post(url.SEND_WITHDRAWAL_OTP, otp, {}, true)
export const settlementWithdrawal = withdrawal =>
  post(url.SETTLEMENT_WITHDRAWAL, withdrawal, {}, true)
export const otpReceiver = withdrawal =>
  post(url.CONFIGURE_OTP_RECEIVER, withdrawal, {}, true)
export const createSettlementAccount = account =>
  post(url.CREATE_SETTLEMENT_ACCOUNT, account, {}, true)
export const disableSettlementAccount = id =>
  patch(
    `${url.ACTIVATE_SETTLEMENT_ACCOUNT}?id=${id}&activate=${false}`,
    null,
    {},
    true
  )
export const getSettlementAccounts = accountType =>
  get(`${url.GET_SETTLEMENT_ACCOUNTS}?type=${accountType}`, {}, true)
export const getWalletSettlementConfig = parameters =>
  post(`${url.GET_WALLET_SETTLEMENT_CONFIGURATION}`, parameters, {}, true)
export const walletSettlementConfig = parameters =>
  post(`${url.WALLET_SETTLEMENT_CONFIGURATION}`, parameters, {}, true)
export const putSettlementAccount = account =>
  put(`${url.CREATE_SETTLEMENT_ACCOUNT}/${account.ID}`, account, {}, true)
export const getWalletTopUpReport = params =>
  post(`${url.GET_WALLET_TOP_UP_REPORT}`, params, {}, true)
export const exportWalletTopUpReport = params =>
  post(`${url.EXPORT_WALLET_TOP_UP_REPORT}`, params, {}, true)
export const getWalletAccountStatement = params =>
  post(`${url.GET_WALLET_ACCOUNT_STATEMENT_REPORT}`, params, {}, true)
export const exportWalletAccountStatementReport = params =>
  post(`${url.EXPORT_WALLET_ACCOUNT_STATEMENT_REPORT}`, params, {}, true)
// INSTITUTIONS
export const getInstitutions = parameters =>
  post(`${url.GET_INSTITUTIONS}`, parameters, {}, true)
export const getInstitutionServiceConfiguration = code =>
  get(
    `${url.GET_INSTITUTION_SERVICE_CONFIGURATION}/${code}`,
    { params: { code } },
    true
  )
export const getInstitutionDetail = id =>
  get(`${url.GET_INSTITUTIONDETAILS}/${id}`, { params: { id } }, true)
export const getAllInstitutions = () =>
  get(`${url.GET_ALL_INSTITUTIONS}`, {}, true)
export const putInstitution = institution =>
  put(`${url.PUT_INSTITUTION}/${institution.ID}`, institution, {}, true)
export const postInstitution = institution =>
  post(url.POST_INSTITUTION, institution, {}, true)
export const enableInstitution = id =>
  patch(`${url.ENABLE_INSTITUTION}?id=${id}&activate=${true}`, null, {}, true)
export const disableInstitution = id =>
  patch(`${url.DISABLE_INSTITUTION}?id=${id}&activate=${false}`, null, {}, true)
export const configureInstitutionServices = services =>
  post(url.CONFIGURE_SERVICES, services, {}, true)

//UNSETTLED TRANSACTIONS
export const getUnsettledTransactions = parameters =>
  post(`${url.GET_UNSETTLED_TRANSACTIONS}`, parameters, {}, true)
export const validateUnsettledTransactions = parameters =>
  post(`${url.VALIDATE_UNSETTLED_TRANSACTIONS}`, parameters, {}, true)
export const processUploadUnsettledTransactions = parameters =>
  post(`${url.UPLOAD_UNSETTLED_TRANSACTIONS}`, parameters, {}, true)
export const getUnsettledTransactionDetail = batchID =>
  get(
    `${url.GET_UNSETTLED_TRANSACTIONS_DETAILS}/${batchID}`, {},
    true
  )
export const downloadUnsettledTransactionReceipt = batchID =>
  get(
    `${url.DOWNLOAD_UNSETTLED_TRANSACTIONS_RECEIPTS}/${batchID}`,
    { params: { batchID } },
    true
  )
export const downloadUnsettledTransactions = batchID =>
  get(
    `${url.DOWNLOAD_UNSETTLED_TRANSACTIONS}/${batchID}`,
    { params: { batchID } },
    true
  )
export const resolveUnsettledTransaction = (
  batchID: number,
  transactionID: number,
  approve: boolean
) =>
  patch(
    `${url.RESOLVE_UNSETTLED_TRANSACTIONS}/${batchID}/${transactionID}/${approve}`,
    null,
    {},
    true
  )
export const batchResolveUnsettledTransaction = (
  batchID: number,
  approve: boolean
) =>
  patch(
    `${url.BATCH_RESOLVE_UNSETTLED_TRANSACTIONS}/${batchID}/${approve}`,
    null,
    {},
    true
  )

// CASHOUT INSTITUTIONS
export const getCashOutInstitutions = parameters =>
  post(`${url.GET_CASHOUT_INSTITUTIONS}`, parameters, {}, true)
export const getCashOutInstitutionDetail = id =>
  get(`${url.GET_CASHOUT_INSTITUTIONDETAILS}/${id}`, { params: { id } }, true)
export const getAllCashOutInstitutions = () =>
  get(`${url.GET_ALL_CASHOUT_INSTITUTIONS}`, {}, true)
export const putCashOutInstitution = institution =>
  put(`${url.PUT_CASHOUT_INSTITUTION}/${institution.ID}`, institution, {}, true)
export const postCashOutInstitution = institution =>
  post(url.POST_CASHOUT_INSTITUTION, institution, {}, true)
export const enableCashOutInstitution = id =>
  patch(
    `${url.ENABLE_CASHOUT_INSTITUTION}?id=${id}&activate=${true}`,
    null,
    {},
    true
  )
export const disableCashOutInstitution = id =>
  patch(
    `${url.DISABLE_CASHOUT_INSTITUTION}?id=${id}&activate=${false}`,
    null,
    {},
    true
  )

// TERMINALS
export const getTerminals = parameters =>
  post(`${url.GET_TERMINALS}`, parameters, {}, true)
export const getUnassignedTerminals = parameters =>
  post(`${url.GET_UNASSIGNED_TERMINALS}`, parameters, {}, true)
export const getTerminalDetail = terminalID =>
  get(
    `${url.GET_TERMINALDETAILS}/${terminalID}`,
    { params: { terminalID } },
    true
  )
export const getAllTerminals = () => get(`${url.GET_ALL_TERMINALS}`, {}, true)
export const putTerminal = terminal =>
  put(`${url.PUT_TERMINAL}/${terminal.ID}`, terminal, {}, true)
export const postTerminal = terminal =>
  post(url.POST_TERMINAL, terminal, {}, true)
export const enableTerminal = id =>
  patch(`${url.ENABLE_TERMINAL}?id=${id}&activate=${true}`, null, {}, true)
export const disableTerminal = id =>
  patch(`${url.DISABLE_TERMINAL}?id=${id}&activate=${false}`, null, {}, true)
export const getTerminalAssignmentReport = parameters =>
  post(`${url.TERMINAL_ASSIGNMENT_REPORT}`, parameters, {}, true)

export const getAssignedTerminals = (isServiceProvider = false) =>
  get(`${url.GET_ASSIGNED_TERMINALS}/${isServiceProvider}`, {}, true)
export const getAllAssignedTerminals = () =>
  get(`${url.GET_ALL_ASSIGNED_TERMINALS}`, {}, true)
export const putAssignedTerminal = assignedTerminal =>
  put(
    `${url.PUT_ASSIGNED_TERMINAL}/${assignedTerminal?.ID}`,
    assignedTerminal,
    {},
    true
  )
export const batchInsertAssignedTerminals = assignedTerminals =>
  put(
    url.POST_ASSIGNED_TERMINAL,
    {
      assignedTerminals,
      IsServiceProvider: assignedTerminals.some(
        terminal => !!terminal.AffiliateID
      ),
    },
    {},
    true
  )
export const removeAssignedTerminal = id =>
  patch(`${url.REMOVE_ASSIGNED_TERMINAL}/${id}`, null, {}, true)
export const batchUploadTerminal = payload =>
  post(`${url.BATCH_UPLOAD_TERMINAL}`, payload, {}, true)

export const processUploadTerminal = payload =>
  post(`${url.PROCESS_UPLOAD_TERMINAL}`, payload, {}, true)

// FEATURES
export const getFeatures = parameters =>
  post(`${url.GET_FEATURES}`, parameters, {}, true)
export const getFeatureDetail = id =>
  get(`${url.GET_FEATUREDETAILS}/${id}`, { params: { id } }, true)
export const getFeatureByInstitution = institutionCode =>
  get(`${url.GET_FEATURE_BY_INSTITUTION}/${institutionCode}`, null, true)
export const getAllFeatures = () => get(`${url.GET_ALL_FEATURES}`, {}, true)
export const putFeature = terminal =>
  put(`${url.PUT_FEATURE}/${terminal.ID}`, terminal, {}, true)
export const postFeature = terminal =>
  post(url.POST_FEATURE, terminal, {}, true)
export const batchAssignFeatures = features =>
  put(url.BATCH_ASSIGN_FEATURES, features, {}, true)
export const enableFeature = id =>
  patch(`${url.ENABLE_FEATURE}?id=${id}&activate=${true}`, null, {}, true)
export const disableFeature = id =>
  patch(`${url.DISABLE_FEATURE}?id=${id}&activate=${false}`, null, {}, true)

// AUDIT_TRAILS
export const getAuditTrails = parameters =>
  post(`${url.GET_AUDIT_TRAILS}`, parameters, {}, true)
export const getAuditTrailDetail = id =>
  get(`${url.GET_AUDIT_TRAILDETAILS}/${id}`, { params: { id } }, true)
export const getAllAuditTrails = () =>
  get(`${url.GET_ALL_AUDIT_TRAILS}`, {}, true)
export const putAuditTrail = auditTrail =>
  put(`${url.PUT_AUDIT_TRAIL}/${auditTrail.ID}`, auditTrail, {}, true)
export const postAuditTrail = auditTrail =>
  post(url.POST_AUDIT_TRAIL, auditTrail, {}, true)
export const enableAuditTrail = id =>
  patch(`${url.ENABLE_AUDIT_TRAIL}?id=${id}&activate=${true}`, null, {}, true)
export const disableAuditTrail = id =>
  patch(`${url.DISABLE_AUDIT_TRAIL}?id=${id}&activate=${false}`, null, {}, true)

// CASE LOGS
export const getCaselogs = parameters =>
  post(`${url.GET_CASELOGS}`, parameters, {}, true)
export const getCaselogDetail = id =>
  get(`${url.GET_CASELOGDETAILS}/${id}`, { params: { id } }, true)

export const getCaselogMessages = id =>
  get(`${url.GET_CASELOGMESSAGES}?id=${id}`, null, true)
export const getAllCaselogs = () => get(`${url.GET_ALL_CASELOGS}`, {}, true)

export const putCaselog = caseLog =>
  put(`${url.PUT_CASELOG}/${caseLog.ID}`, caseLog, {}, true)
export const resolveCase = id =>
  patch(`${url.RESOLVE_CASE}/${id}`, null, {}, true)
export const markAsRead = payload =>
  get(
    `${url.MARK_AS_READ}?reference=${payload.reference}&agentPhoneNumber=${payload.agentPhoneNumber}`,
    {},
    true
  )
export const enableCaselog = id =>
  patch(`${url.ENABLE_CASELOG}/${id}/${true}`, id, {}, true)
export const disableCaselog = id =>
  patch(`${url.DISABLE_CASELOG}/${id}/${false}`, id, {}, true)
export const sendMessage = payload =>
  post(url.SEND_CASELOGMESSAGE, payload, {}, true)

// CASE LOGS
export const getChargeBacks = parameters =>
  post(`${url.GET_CHARGEBACKS}`, parameters, {}, true)
export const getChargeBackDetail = id =>
  get(`${url.GET_CHARGEBACKDETAILS}/${id}`, { params: { id } }, true)

export const updateChargeBack = payload =>
  patch(
    `${url.UPDATE_CHARGEBACK}/${payload.id}?id=${payload.id}&status=${payload.status}`,
    null,
    {},
    true
  )

export const regenerateChargeBackToken = id =>
  patch(`${url.REGENERATE_CHARGEBACK_TOKEN}/${id}?id=${id}`, null, {}, true)
export const getAllChargeBacks = () =>
  get(`${url.GET_ALL_CHARGEBACKS}`, {}, true)

//COMMISSION
export const getCommissions = parameters =>
  post(`${url.GET_COMMISSIONS}`, parameters, {}, true)
export const getAllCommissions = () =>
  get(`${url.GET_ALL_COMMISSIONS}`, {}, true)

//COMMISSION STRUCTURES
export const getCommissionStructures = parameters =>
  post(`${url.GET_COMMISSIONSTRUCTURES}`, parameters, {}, true)
export const getAllCommissionStructures = () =>
  get(`${url.GET_ALL_COMMISSIONSTRUCTURES}`, {}, true)
export const getCommissionStructuresByCategory = categoryId =>
  get(`${url.GET_COMMISSIONSTRUCTURES_BY_CATEGORY}/${categoryId}`, {}, true)
export const getCommissionStructureDetail = id =>
  get(`${url.GET_COMMISSIONSTRUCTURE_DETAILS}/${id}`, { params: { id } }, true)
export const putCommissionStructure = structure =>
  put(`${url.PUT_COMMISSION_STRUCTURE}/${structure?.ID}`, structure, {}, true)
export const batchInsertStructures = structures =>
  post(url.POST_COMMISSION_STRUCTURE, structures, {}, true)
export const removeCommissionStructure = id =>
  patch(`${url.REMOVE_COMMISSION_STRUCTURE}/${id}`, null, {}, true)
export const enableCommissionStructure = id =>
  patch(`${url.ENABLE_COMMISSION}/${id}/${true}`, id, {}, true)
export const disableCommissionStructure = id =>
  patch(`${url.DISABLE_COMMISSION}/${id}/${false}`, id, {}, true)

//SUBSCRIPTION_PLAN
export const getSubscriptions = parameters =>
  post(`${url.GET_SUBSCRIPTIONS}`, parameters, {}, true)
export const getSubscriptionPlans = parameters =>
  post(`${url.GET_SUBSCRIPTION_PLANS}`, parameters, {}, true)
export const getAllSubscriptions = () =>
  get(`${url.GET_ALL_SUBSCRIPTION_PLANS}`, {}, true)
export const getSubscriptionPlansByCategory = categoryId =>
  get(
    `${url.GET_SUBSCRIPTION_PLANS_BY_CATEGORY}?agentCategoryID=${categoryId}`,
    {},
    true
  )
export const getSubscriptionPlanDetail = id =>
  get(`${url.GET_SUBSCRIPTION_PLAN_DETAILS}/${id}`, { params: { id } }, true)
export const putSubscription = structure =>
  put(`${url.PUT_SUBSCRIPTION_PLAN}/${structure?.ID}`, structure, {}, true)
export const saveSubcription = structures =>
  post(url.POST_SUBSCRIPTION_PLAN, structures, {}, true)
export const removeSubscriptionOption = id =>
  patch(`${url.REMOVE_SUBSCRIPTION_PLAN}/${id}`, null, {}, true)
export const enableSubscription = id =>
  patch(`${url.ENABLE_SUBSCRIPTION_PLAN}/${id}/${true}`, id, {}, true)
export const disableSubscription = id =>
  patch(
    `${url.DISABLE_SUBSCRIPTION_PLAN}?subscriptionPlanID=${id}`,
    id,
    {},
    true
  )

//MOBILE ACCOUNT
export const getMobileAccounts = parameters =>
  post(`${url.GET_MOBILEACCOUNTS}`, parameters, {}, true)
export const getAllMobileAccounts = () =>
  get(`${url.GET_ALL_MOBILEACCOUNTS}`, {}, true)
export const getMobileAccountByPhoneNumber = phoneNumber =>
  get(`${url.GET_MOBILEACCOUNT_BY_PHONENUMBER}/${phoneNumber}`, {}, true)
export const getMobileAccountDetail = id =>
  get(`${url.GET_MOBILEACCOUNT_DETAILS}/${id}`, { params: { id } }, true)
export const putMobileAccount = mobileAccount =>
  put(`${url.PUT_MOBILEACCOUNT}/${mobileAccount.ID}`, mobileAccount, {}, true)
export const postMobileAccount = mobileAccount =>
  post(url.POST_MOBILEACCOUNT, mobileAccount, {}, true)
export const enableMobileAccount = id =>
  patch(
    `${url.ENABLE_MOBILEACCOUNT}?id=${id}&activate=${true}`,
    { id, activate: true },
    {},
    true
  )
export const disableMobileAccount = id =>
  patch(
    `${url.DISABLE_MOBILEACCOUNT}?id=${id}&activate=${false}`,
    { id, activate: false },
    {},
    true
  )

export const resetPin = id => patch(`${url.RESET_PIN}?id=${id}`, id, {}, true)
export const resetPinTries = id =>
  patch(`${url.RESET_PIN_TRIES}?id=${id}`, id, {}, true)
export const resetPassword = id =>
  patch(`${url.RESET_PASSWORD}?id=${id}`, id, {}, true)
export const resetPasswordTries = id =>
  patch(`${url.RESET_PASSWORD_TRIES}?id=${id}`, id, {}, true)
export const hotlist = id => patch(`${url.HOTLIST}?id=${id}`, id, {}, true)
export const generateVerificationCode = id =>
  patch(`${url.GENERATE_VERIFICATION_CODE}?id=${id}`, id, {}, true)

export const sendActivationCode = id =>
  patch(`${url.ACTIVATION_CODE}?id=${id}`, id, {}, true)

export const getMobileWallets = parameters =>
  post(`${url.GET_MOBILEWALLETS}`, parameters, {}, true)
export const getAllMobileWallets = () =>
  get(`${url.GET_ALL_MOBILEWALLETS}`, {}, true)
export const getMobileWalletDetail = id =>
  get(`${url.GET_MOBILEWALLET_DETAILS}/${id}`, { params: { id } }, true)

// NETWORK MANAGERS
export const getNetworkManagers = parameters =>
  post(`${url.GET_NETWORK_MANAGERS}`, parameters, {}, true)
export const getAllNetworkManagers = id =>
  get(`${url.GET_ALL_NETWORK_MANAGERS}/${id}`, {}, true)
export const getNetworkManagerDetail = id =>
  get(`${url.GET_NETWORK_MANAGERDETAILS}/${id}`, { params: { id } }, true)
export const putNetworkManager = networkManager =>
  put(
    `${url.PUT_NETWORK_MANAGER}/${networkManager.ID}`,
    networkManager,
    {},
    true
  )
export const postNetworkManager = networkManager =>
  post(url.POST_NETWORK_MANAGER, networkManager, {}, true)
export const enableNetworkManager = id =>
  patch(`${url.ENABLE_NETWORK_MANAGER}?id=${id}&activate=${true}`, id, {}, true)
export const disableNetworkManager = id =>
  patch(
    `${url.DISABLE_NETWORK_MANAGER}?id=${id}&activate=${false}`,
    id,
    {},
    true
  )

//CATEGORES
export const getCategories = parameters =>
  post(`${url.GET_CATEGORIES}`, parameters, {}, true)
export const getAllCategories = () => get(`${url.GET_ALL_CATEGORIES}`, {}, true)
export const getCategoryDetail = id =>
  get(`${url.GET_CATEGORYDETAILS}/${id}`, { params: { id } }, true)
export const getCategoryFeatures = id =>
  get(`${url.GET_CATEGORYDETAILS}/getfeatures/${id}`, null, true)
export const putCategory = agentCategory =>
  put(`${url.PUT_CATEGORY}/${agentCategory.ID}`, agentCategory, {}, true)
export const postCategory = agentCategory =>
  post(url.POST_CATEGORY, agentCategory, {}, true)
export const enableCategory = id =>
  patch(`${url.ENABLE_CATEGORY}?id=${id}&activate=${true}`, id, {}, true)
export const disableCategory = id =>
  patch(`${url.DISABLE_CATEGORY}?id=${id}&activate=${false}`, id, {}, true)

// States
export const getStates = () => get(`${url.GET_STATES}`, {}, true)
export {
  getLoggedInUser,
  isUserAuthenticated,
  postClusterLogin,
  postClusterLogout,
  postClusterRegister,
  postClusterForgetPwd,
  postClusterChangePassword,
}
