import { Validators } from "helpers/validators"
import { GetInstitution } from "helpers/authentication/institution"
import {
  Format,
  SEARCHOPTIONS,
  TRANSACTIONSTATUS,
  TRANSACTIONTYPENAMES,
} from "helpers/variables"
import moment from "moment"
import report from "pages/UserManagement/Users/list/report"

const HEADERS = [


  { title: "Agent Name", name: "AgentName", entityKeyName: "AgentName" },
  { title: "Agent Code", name: "AgentCode", entityKeyName: "AgentCode" },
  { title: "Agent PhoneNumber", name: "AgentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
  { title: "Cashout Vol", name: "CashoutVol", entityKeyName: "CashoutVol" },
  { title: "Cashout Vol Change", name: "CashoutVolChange", entityKeyName: "CashoutVolChange", isBadge: true },
  { title: "Cashout Percent Change", name: "CashoutPercentChange", entityKeyName: "CashoutPercentChange", isBadge: true },
  { title: "CashIn Vol", name: "CashInVol", entityKeyName: "CashInVol" },
  { title: "CashIn Vol Change", name: "CashInVolChange", entityKeyName: "CashInVolChange", isBadge: true },
  { title: "CashIn Percent Change", name: "CashInPercentChange", entityKeyName: "CashInPercentChange", isBadge: true },
  { title: "Interbank FT Vol", name: "InterbankFTVol", entityKeyName: "InterbankFTVol" },
  { title: "Interbank FT Vol Change", name: "InterbankFTVolChange", entityKeyName: "InterbankFTVolChange", isBadge: true },
  { title: "Interbank FT Percent Change", name: "InterbankFTPercentChange", entityKeyName: "InterbankFTPercentChange", isBadge: true },
  { title: "Token Withdrawal Vol", name: "TokenWithdrawalVol", entityKeyName: "TokenWithdrawalVol" },
  { title: "Token Withdrawal Vol Change", name: "TokenWithdrawalVolChange", entityKeyName: "TokenWithdrawalVolChange", isBadge: true },
  { title: "Token Withdrawal Percent Change", name: "TokenWithdrawalPercentChange", entityKeyName: "TokenWithdrawalPercentChange", isBadge: true },
  { title: "Intrabank FT Vol", name: "IntrabankFTVol", entityKeyName: "IntrabankFTVol" },
  { title: "Intrabank FT Vol Change", name: "IntrabankFTVolChange", entityKeyName: "IntrabankFTVolChange", isBadge: true },
  { title: "Intrabank FT Percent Change", name: "IntrabankFTPercentChange", entityKeyName: "IntrabankFTPercentChange", isBadge: true },
  { title: "Bills Payment Vol", name: "BillsPaymentVol", entityKeyName: "BillsPaymentVol" },
  { title: "Bills Payment Vol Change", name: "BillsPaymentVolChange", entityKeyName: "BillsPaymentVolChange", isBadge: true },
  { title: "Bills Payment Percent Change", name: "BillsPaymentPercentChange", entityKeyName: "BillsPaymentPercentChange", isBadge: true },
]

const SearchOptions = (startDate, endDate, clusters, states, networkManagers, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Name",
      id: "s-agentName",
      defaultValue: "",
      name: "agentName",
    },
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "number",
      label: "Benchmark Preceding Days",
      id: "benchmarkPrecedingDays",
      defaultValue: 1,
      name: "benchmarkPrecedingDays",
      validators: [
        Validators.required("This field is required"),
        Validators.min(1, "Minimum amount is 1 day"),
      ],
    },
    {
      type: "dropdown",
      label: "Cluster",
      id: "clusterId",
      defaultValue: "- Select -",
      data: MapToDropDown(clusters),
      name: "clusterId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "State",
      id: "stateId",
      defaultValue: "- Select -",
      data: MapToDropDown(states),
      name: "stateId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "Network Manager",
      id: "anmId",
      defaultValue: "- Select -",
      data: MapToDropDown(networkManagers),
      name: "anmId",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  return options
}
const MapToRows = report => {
  return {
    AgentName: report.AgentName,
    AgentCode: report.AgentCode,
    AgentPhoneNumber: report.AgentPhoneNumber,
    CashoutVol: Format.currency(report.CashoutVol),
    institution: report.InstitutionName,
    CashoutVolChange: {
      label: Format.currency(report.CashoutVolChange),
      badgeClass:
        report.CashoutVolChange > 0
          ? "success"
          : report.CashoutVolChange < 0
          ? "danger"
          : "secondary",
    },
    CashoutPercentChange: {
      label: Format.percentage(report.CashoutPercentChange),
      badgeClass:
        report.CashoutVolChange > 0
          ? "success"
          : report.CashoutVolChange < 0
          ? "danger"
          : "secondary",
    },

    CashInVol: Format.currency(report.CashInVol),

    CashInVolChange: {
      label: Format.currency(report.CashInVolChange),
      badgeClass:
        report.CashInVolChange > 0
          ? "success"
          : report.CashInVolChange < 0
          ? "danger"
          : "secondary",
    },
    CashInPercentChange: {
      label: Format.percentage(report.CashInPercentChange),
      badgeClass:
        report.CashInVolChange > 0
          ? "success"
          : report.CashInVolChange < 0
          ? "danger"
          : "secondary",
    },

    InterbankFTVol: Format.currency(report.InterbankFTVol),

    InterbankFTVolChange: {
      label: Format.currency(report.InterbankFTVolChange),
      badgeClass:
        report.InterbankFTVolChange > 0
          ? "success"
          : report.InterbankFTVolChange < 0
          ? "danger"
          : "secondary",
    },
    InterbankFTPercentChange: {
      label: Format.percentage(report.InterbankFTPercentChange),
      badgeClass:
        report.InterbankFTVolChange > 0
          ? "success"
          : report.InterbankFTVolChange < 0
          ? "danger"
          : "secondary",
    },

    TokenWithdrawalVol: Format.currency(report.TokenWithdrawalVol),

    TokenWithdrawalVolChange: {
      label: Format.currency(report.TokenWithdrawalVolChange),
      badgeClass:
        report.TokenWithdrawalVolChange > 0
          ? "success"
          : report.TokenWithdrawalVolChange < 0
          ? "danger"
          : "secondary",
    },
    TokenWithdrawalPercentChange: {
      label: Format.percentage(report.TokenWithdrawalPercentChange),
      badgeClass:
        report.TokenWithdrawalVolChange > 0
          ? "success"
          : report.TokenWithdrawalVolChange < 0
          ? "danger"
          : "secondary",
    },

    IntrabankFTVol: Format.currency(report.IntrabankFTVol),
    IntrabankFTVolChange: {
      label: Format.currency(report.IntrabankFTVolChange),
      badgeClass:
        report.IntrabankFTVolChange > 0
          ? "success"
          : report.IntrabankFTVolChange < 0
          ? "danger"
          : "secondary",
    },
    IntrabankFTPercentChange: {
      label: Format.percentage(report.IntrabankFTPercentChange),
      badgeClass:
        report.IntrabankFTVolChange > 0
          ? "success"
          : report.IntrabankFTVolChange < 0
          ? "danger"
          : "secondary",
    },

    BillsPaymentVol: Format.currency(report.BillsPaymentVol),
    BillsPaymentVolChange: {
      label: Format.currency(report.BillsPaymentVolChange),
      badgeClass:
        report.BillsPaymentVolChange > 0
          ? "success"
          : report.BillsPaymentVolChange < 0
          ? "danger"
          : "secondary",
    },
    BillsPaymentPercentChange: {
      label: Format.percentage(report.BillsPaymentPercentChange),
      badgeClass:
        report.BillsPaymentVolChange > 0
          ? "success"
          : report.BillsPaymentVolChange < 0
          ? "danger"
          : "secondary",
    },
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentName: params["agentName"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    ClusterID: params["clusterId"],
    ANMID: params["anmId"],
    StateID: params["stateId"],
    BenchmarkPrecedingDays: params["benchmarkPrecedingDays"],
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
