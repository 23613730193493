import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
    { title: "BIN/Scheme", name: "value", entityKeyName: "Value" },
    { title: "Switching Route", name: "switchingRoute", entityKeyName: "SwitchingRoute" },
    {
        title: "Description",
        entityKeyName: "Description",
        name: "description"
    },
    { title: "Agent Category", name: "agentCategory", entityKeyName: "AgentCategory" },
    { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {

}
const SearchOptions = (startDate, endDate, institutions) => {
    let options = Object.assign({}, SEARCHOPTIONS)

    options.range.from =  moment(startDate).format("YYYY-MM-DD")
    options.range.to = moment(endDate).format("YYYY-MM-DD")
    options.range.enabled = false
    options.fields = [
      {
        type: "text",
        label: "BIN/Scheme",
        id: "s-value",
        defaultValue: "",
        name: "value",
      },
      {
        type: "dropdown",
        label: "All Institutions",
        id: "institutionCode",
        defaultValue: "- Select -",
        data: MapToDropDown(institutions),
        name: "institutionCode",
        width: 2,
        isRemote: GetInstitution().code == "*",
      },
    ]
    return options
}

const MapToRows = binRoute => {

    return {
      id: binRoute.ID,
      value: binRoute.Value,
      switchingRoute: binRoute.SwitchingRoute,
      agentCategory: binRoute.AgentCategory,
      institution: binRoute.InstitutionName,
      description: `Amounts between ${Format.currency(
        binRoute.MinimumAmount
      )} and ${Format.currency(binRoute.MaximumAmount)} `,
    }
}


const SearchCriteria = (parameters, maxSize) => {
    return JSON.stringify({
      StartIndex: (parameters["page"] - 1) * maxSize,
      Value: parameters["value"],
      InstitutionCode:
        parameters["institutionCode"] != "*"
          ? parameters["institutionCode"]
          : "",
      FromDate: moment(parameters["fromDate"])
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      ToDate: moment(parameters["toDate"])
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      MaxSize: maxSize,
    })
}

const MapToDropDown = data => {
    if (data instanceof Array) {
        return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
    }
    return []
}

const SpreadBinRoutes = (binRoutes, categories) => {
    let routes = [];
    (binRoutes ?? []).forEach((r) => {

        const value = r.Value;
        r.Routes.forEach((route) => {

            let detail = { ID: r.ID, RouteDetailID: route.ID, SwitchingRouteID: route.Route.ID, Value: value, SwitchingRoute: route.Route?.Name, MinimumAmount: route.MinimumAmount, MaximumAmount: route.MaximumAmount, AgentCategoryID: route.AgentCategoryID, AgentCategory: "", BinCount: r.Routes.length, SwitchingRouteCount: r.Routes.filter(c => c.Route?.ID == route.Route?.ID).length };

            if (detail.AgentCategoryID && !Utils.List.isEmpty(categories)) detail.AgentCategory = categories.find(c => c.ID == detail.AgentCategoryID)?.Name
            routes.push(detail);
        });
    })
    return routes;
}

const AttachCategory = (routingDetails, categories) => {
    routingDetails = routingDetails.map((route) => {

        let detail = { ID: route.ID, SwitchingRouteID: route.Route.ID, Value: route.Value, SwitchingRoute: route.Route?.Name, MinimumAmount: route.MinimumAmount, MaximumAmount: route.MaximumAmount, AgentCategoryID: route.AgentCategoryID, AgentCategory: "", SwitchingRouteCount: routingDetails.filter(c => c.Route?.ID == route.Route?.ID).length };

        if (detail.AgentCategoryID && !Utils.List.isEmpty(categories)) detail.AgentCategory = categories.find(c => c.ID == detail.AgentCategoryID)?.Name
       return detail;
    });

    return routingDetails;
}
export default { ERRORLIST, HEADERS, SearchCriteria, MapToDropDown, MapToRows, SearchOptions, AttachCategory, SpreadBinRoutes }