import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import { DISPUTESTATUS, Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  
  { title: "Action", name: "action", isAction: true },
  {
    title: "Dispute Status",
    name: "disputeStatus",
    entityKeyName: "DisputeStatus",
    isBadge: true,
  },
  { title: "Log Date", name: "logDate", entityKeyName: "DateLogged" },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
  {
    title: "Customer Phone Number",
    name: "customerPhoneNumber",
    entityKeyName: "CustomerPhoneNumber",
  },
  {
    title: "Agent Category",
    name: "agentCategory",
    entityKeyName: "AgentCategory.Name",
  },
  {
    title: "Issuer Bank",
    name: "issuerBank",
    entityKeyName: "IssuingBankName",
  },
  { title: "Amount", name: "Amount", entityKeyName: "Amount" },
  { title: "RRN", name: "rrn", entityKeyName: "RetrievalReferenceNumber" },
  { title: "Transaction Date", name: "transactionDate", entityKeyName: "TransactionDate" }
]
const ERRORLIST = {
  name: undefined,
  code: undefined,
  phoneNumber: undefined,
  email: undefined,
}
const SearchOptions = (startDate, endDate, categories, institutions) => {
  let options = SEARCHOPTIONS
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Dispute Reference",
      id: "s-disputeReference",
      defaultValue: "",
      name: "disputeReference",
    },
    {
      type: "text",
      label: "RRN",
      id: "s-rrn",
      defaultValue: "",
      name: "rrn",
    },
    {
      type: "text",
      label: "Customer Phone Number",
      id: "s-phoneNumber",
      defaultValue: "",
      name: "customerPhoneNumber",
    },
    {
      type: "dropdown",
      label: "Agent Category",
      id: "s-agentCategory",
      defaultValue: "- Select Category -",
      name: "agentCategory",
      data: MapToDropDown(categories),
      width: 3,
    },
    {
      type: "dropdown",
      label: "Dispute Status",
      id: "s-disputeStatus",
      defaultValue: "- Select -",
      name: "disputeStatus",
      data: DISPUTESTATUS,
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
const MapToRows = chargeBack => {
  return {
    logCode: chargeBack.DisputeReference,
    institution: chargeBack.InstitutionName,
    transactionDate: Format.date(chargeBack.TransactionDate),
    logDate: Utils.Date.format(chargeBack.DateLogged),
    agentName: chargeBack.AgentName,
    terminalID: chargeBack.TerminalID,
    cardHolderName: chargeBack.CardHolder,
    customerPhoneNumber: chargeBack.CustomerPhoneNumber,
    agentCategory: chargeBack.AgentCategory?.Name ?? "NONE",
    issuerBank: chargeBack.IssuingBankName,
    Amount: Format.currency(chargeBack.Amount),
    rrn: chargeBack.RetrievalReferenceNumber,
    disputeStatus: {
      label: chargeBack.DisputeStatus,
      badgeClass: GetDisputeStatus(chargeBack.DisputeStatus),
    },
  }
}
const GetDisputeStatus = status => {
  if (!status) return "secondary"
  return status.includes("ResolvedApproved")
    ? "success"
    : status.includes("ResolvedReversed")
    ? "info"
    : status == "Open"
    ? "warning"
    : status == "NotProcessed"
    ? "pink"
    : "secondary"
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    AgentCategoryID: parameters["agentCategory"],
    CustomerPhoneNumber: parameters["customerPhoneNumber"],
    DisputeReference: parameters["disputeReference"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    DisputeStatus: parameters["disputeStatus"],
    RRN: parameters["rrn"],
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
    FromDate: moment(parameters["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(parameters["toDate"])
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
  })
}

const MapToModalState = claim => {
  return [
    {
      title: "Claim Details",
      items: [
        {
          id: "logCode",
          label: "Log Code",
          name: "logCode",
          value: claim.DisputeReference,
        },
        {
          id: "logDate",
          label: "Log Date",
          name: "logDate",
          value: Format.date(claim.DateLogged),
        },
        {
          id: "transactionDate",
          label: "Transaction Date",
          name: "transactionDate",
          value: Format.date(claim.TransactionDate),
        },
        {
          id: "amount",
          label: "Amount",
          name: "amount",
          value: Format.currency(claim.Amount),
        },
        {
          id: "rrn",
          label: "Retrieval Reference Number",
          name: "rrn",
          value: claim.RetrievalReferenceNumber,
        },
        {
          id: "stan",
          label: "STAN",
          name: "stan",
          value: claim.STAN,
        },
        {
          id: "issuingBank",
          label: "Issuing Bank",
          name: "issuingBank",
          value: claim.IssuingBankName,
        },
        {
          id: "cashOutDate",
          label: "Cash Out Date",
          name: "cashOutDate",
          value: claim.CashOutDate ?? "Not Yet Cashed Out",
        },
      ],
    },
    {
      title: "Agent Details",
      items: [
        {
          id: "agentName",
          label: "Agent Name",
          name: "agentName",
          value: claim.AgentName,
        },
        {
          id: "agentPhoneNumber",
          label: "Agent Phone Number",
          name: "agentPhoneNumber",
          value: claim.AgentPhoneNumber,
        },
        {
          id: "agentCategory",
          label: "Agent Category",
          name: "agentCategory",
          value: claim.AgentCategory?.Name ?? "NONE",
        },
        {
          id: "terminalID",
          label: "Terminal ID",
          name: "terminalID",
          value: claim.TerminalID,
        },
      ],
    },
    {
      title: "Customer Details",
      items: [
        {
          id: "customerPhoneNumber",
          label: "Customer Phone Number",
          name: "customerPhoneNumber",
          value: claim.CustomerPhoneNumber,
        },
        {
          id: "cardHolder",
          label: "Card Holder",
          name: "cardHolder",
          value: claim.CardHolder,
        },
      ],
    },
  ]
}

export default {
  HEADERS,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  ERRORLIST,
  MapToDropDown,
  GetDisputeStatus,
}
