import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { useLocation, useParams, withRouter } from "react-router-dom"

import moment from "moment"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap"
import { Link } from "react-router-dom"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"
import roleImg from "../../../assets/images/icons/saturn.png"
import profile from "../../../assets/images/profile-img.png"
import logo from "../../../assets/images/logo.svg"
//i18n
import { withTranslation } from "react-i18next"
import {
  doAgentAccountValidation,
  getAgentDetail,
  agentPurge,
} from "store/actions"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import { Validators } from "helpers/validators"
import ActivityReportList from "./list"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
const headers = [
  { title: "Agent", name: "agentName" },
  { title: "Amount", name: "amount" },
  { title: "Terminal", name: "terminal" },
  { title: "PAN", name: "pan" },
  { title: "Ref. No", name: "refno" },
  { title: "Resp. Code", name: "responseCode" },
  { title: "Status", name: "status", isBadge: true },
  { title: "Date", name: "time" },
  { title: "Action", name: "action", isBadge: false },
]
const ActivityReport = props => {
  const [reportTypeSelected, setReportTypeSelected] = useState(false)
  const [errorList, setErrorList] = useState({
    reportType: undefined,
  })
  const [myError, setMyError] = useState()
  const [apiError, setError] = useState()
  const [apiSuccess, setSuccess] = useState()
  const [loading, setLoading] = useState(false)
  const [reportType, setReportType] = useState()
  const [didMount, setDidMount] = useState(false)
  useEffect(() => {
    setDidMount(true)

    return () => {
      setDidMount(false)
    }
  }, [])
  const handleOnChange = (key, value) => {
    setReportType(value)
  }

  const handleValidSubmit = e => {
    //  setLoading(true);
    e.preventDefault()
    setReportTypeSelected(true)
  }

  const onBack = () => {
    setReportTypeSelected(false)
  }
  if (!didMount) {
    return null
  }
  return (
    <React.Fragment>
      {reportTypeSelected ? (
        <ActivityReportList reportType={reportType}></ActivityReportList>
      ) : (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title={"Agent"} breadcrumbItem={"Activity Report"} />
            <React.Fragment>
              <Row>
                <Col lg={3}></Col>
                <Col lg={6}>
                  <Card
                    outline
                    style={{
                      background: "transparent",
                      borderColor: "#999999",
                    }}
                    className="border"
                  >
                    <CardBody className="pt-0">
                      <div className="p-2">
                        <form
                          className="form-horizontal mt-4"
                          noValidate
                          onSubmit={e => handleValidSubmit(e)}
                        >
                          <FormGroup>
                            <Label>Report Type</Label>
                            <Row>
                              <Col className="text-right" lg={6} md={6} sm={12}>
                                <ClusterDropdown
                                  placeholder={"- Select -"}
                                  items={[
                                    {
                                      id: "AgencyBanking",
                                      value: "Agency Banking",
                                      key: 0,
                                    },
                                    {
                                      id: "CashOut",
                                      value: "Cash Out",
                                      key: 1,
                                    },
                                    {
                                      id: "FundsTransfer",
                                      value: "Funds Transfer",
                                      key: 2,
                                    },
                                  ]}
                                  onChange={(name, value) => {
                                    handleOnChange("reportType", value)
                                    setErrorList({
                                      ...errorList,
                                      reportType: "",
                                    })
                                  }}
                                ></ClusterDropdown>
                              </Col>
                            </Row>
                          </FormGroup>
                          <Row className="form-group">
                            <Col className="text-right">
                              <ClusterButton
                                loading={loading}
                                disabled={!reportType ? true : false}
                                color={"primary"}
                                addon={"bx bx-save"}
                              >
                                Next
                              </ClusterButton>
                            </Col>
                          </Row>
                        </form>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={3}></Col>
              </Row>
            </React.Fragment>
          </Container>
        </div>
      )}
    </React.Fragment>
  )
}

ActivityReport.propTypes = {
  accountEnquiry: PropTypes.object,
  error: PropTypes.any,
  success: PropTypes.string,
  onCreateAgent: PropTypes.func,
  agents: PropTypes.array,
  agentDetail: PropTypes.object,
  onPurge: PropTypes.func,
}

const mapStateToProps = ({ agents, error }) => {
  const err = Validators.extractError(agents.error)
  return {}
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(ActivityReport)))
