import { GetInstitution } from "helpers/authentication/institution"
import { Format, PAYMENTSTATUS, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const HEADERS = [
  {
    title: "Payment Status",
    name: "paymentStatus",
    entityKeyName: "PaymentStatus",
    isBadge: true,
  },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Agent Code", name: "agentCode", entityKeyName: "AgentCode" },
  { title: "ANM", name: "anm", entityKeyName: "AgentNetworkManager" },
  {
    title: "Agent Phone Number",
    name: "agentPhoneNumber",
    entityKeyName: "AgentPhoneNumber",
  },
  {
    title: "Agent Category",
    name: "agentCategory",
    entityKeyName: "AgentCategory",
  },
  {
    title: "Transaction Reference",
    name: "transactionReference",
    entityKeyName: "TransactionReference",
  },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionType",
  },
  {
    title: "Transaction Amount",
    name: "transactionAmount",
    entityKeyName: "TransactionAmount",
  },
  {
    title: "Commission Amount",
    name: "commissionAmount",
    entityKeyName: "CommissionAmount",
  },
  {
    title: "Agent Share",
    name: "agentShare",
    entityKeyName: "AgentCommissionFee",
  },
  { title: "ANM Share", name: "anmShare", entityKeyName: "ANMCommissionFee" },
  {
    title: "Bank Share",
    name: "bankShare",
    entityKeyName: "BankCommissionFee",
  },
  {
    title: "Appzone Share",
    name: "appzoneShare",
    entityKeyName: "ServiceProviderCommissionFee",
  },
  {
    title: "Payment Details",
    name: "paymentDetails",
    entityKeyName: "FailureReason",
  },
  {
    title: "Commission Date",
    name: "commissionDate",
    entityKeyName: "LogDate",
  },
  {
    title: "Settlement Date",
    name: "settlementDate",
    entityKeyName: "SettlementDate",
  },
]
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Name",
      id: "s-agentName",
      defaultValue: "",
      name: "agentName",
    },
    {
      type: "text",
      label: "Agent Code",
      id: "s-agentCode",
      defaultValue: "",
      name: "agentCode",
    },
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "dropdown",
      label: "Payment Status",
      id: "paymentStatus",
      defaultValue: "- Select Status -",
      data: PAYMENTSTATUS,
      name: "paymentStatus",
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToRows = commission => {
  return {
    id: commission.ID,
    agentName: commission.AgentName,
    agentCode: commission.AgentCode,
    anm: commission.AgentNetworkManager,
    institution: commission.InstitutionName,
    agentPhoneNumber: commission.AgentPhoneNumber,
    agentCategory: commission.AgentCategory,
    transactionReference: commission.TransactionReference,
    transactionType: commission.TransactionType,
    transactionAmount: Format.currency(commission.TransactionAmount),
    commissionAmount: Format.currency(commission.CommissionAmount),
    agentShare: Format.currency(commission.AgentCommissionFee),
    anmShare: Format.currency(commission.ANMCommissionFee),
    bankShare: Format.currency(commission.BankCommissionFee),
    appzoneShare: Format.currency(commission.ServiceProviderCommissionFee),
    paymentStatus: {
      label: commission.PaymentStatus,
      badgeClass:
        commission.PaymentStatus == "Successful"
          ? "success"
          : commission.PaymentStatus == "Pending"
          ? "secondary"
          : "danger",
    },
    paymentDetails: commission.FailureReason,
    commissionDate: commission.LogDate,
    settlementDate: commission.SettlementDate,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentName: params["agentName"],
    AgentCode: params["agentCode"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    Status: params["paymentStatus"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
}
