/* CLUSTERMANAGERS */
export const GET_CLUSTERMANAGERS = "GET_CLUSTERMANAGERS"
export const GET_ALL_CLUSTERMANAGERS = "GET_ALL_CLUSTERMANAGERS"
export const GET_CLUSTERMANAGERS_SUCCESS = "GET_CLUSTERMANAGERS_SUCCESS"
export const GET_CLUSTERMANAGERS_FAIL = "GET_CLUSTERMANAGERS_FAIL"

export const GET_CLUSTERMANAGER_DETAIL = "GET_CLUSTERMANAGER_DETAIL"
export const GET_CLUSTERMANAGER_DETAIL_SUCCESS =
  "GET_CLUSTERMANAGER_DETAIL_SUCCESS"
export const GET_CLUSTERMANAGER_DETAIL_FAIL = "GET_CLUSTERMANAGER_DETAIL_FAIL"

export const CREATE_CLUSTERMANAGER = "CREATE_CLUSTERMANAGER"
export const POST_CLUSTERMANAGER_SUCCESS = "POST_CLUSTERMANAGER_SUCCESS"
export const POST_CLUSTERMANAGER_FAIL = "POST_CLUSTERMANAGER_FAIL"

export const CLUSTERMANAGER_PURGE = "CLUSTERMANAGER_PURGE"
export const CLUSTERMANAGER_PURGE_SUCCESS = "CLUSTERMANAGER_PURGE_SUCCESS"

export const UPDATE_CLUSTERMANAGER = "UPDATE_CLUSTERMANAGER"
export const UPDATE_CLUSTERMANAGER_SUCCESS = "UPDATE_CLUSTERMANAGER_SUCCESS"
export const UPDATE_CLUSTERMANAGER_FAIL = "UPDATE_CLUSTERMANAGER_FAIL"

export const ENABLE_CLUSTERMANAGER = "ENABLE_CLUSTERMANAGER"
export const ENABLE_CLUSTERMANAGER_SUCCESS = "ENABLE_CLUSTERMANAGER_SUCCESS"
