import React, { useEffect, useState, useMemo } from "react"

import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"

import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import Loading from "components/Common/Loading"
import ClusterForm from "components/Common/Form"
import AgentLogic from "../agent"
import ClusterTextArea from "components/Common/TextArea"
import { GENDER } from "helpers/variables"
import ClusterCheckBox from "components/Common/CheckBox"
import { Utils } from "helpers/utility"
import { AccountMode } from "enums/account-mode"
import { Formik } from "formik"
import { InstitutionsHelper } from "helpers/institutions"
import useAgent from "./useAgent"
import { fetcher } from "helpers/api_helper"
import { ACCOUNT_VALIDATION } from "helpers/url_helper"
import { GetInstitution } from "helpers/authentication/institution"

const AgentDetailsForm = props => {
  let {
    categories,
    categoriesLoading,
    back,
    initialValues,
    clustersLoading,
    roles,
    clusters,
    isUpdate,
    editLoading,
    agent,
    handleValidSubmit,
  } = props
  const [account, setAccount] = useState<any>({
    data: {},
    accountNumber: "",
    exists: false,
    isValidating: false,
    error: {},
  })
  useEffect(() => {
    setAccount({
      data: {},
      accountNumber: initialValues.AccountNumber,
      error: {},
      exists: false,
      isValidating: true,
    })
    fetcher(ACCOUNT_VALIDATION + "/" + initialValues.AccountNumber)
      .then(c => {
        const thisAccount = (c as any).Data
        setAccount({
          accountNumber: initialValues.AccountNumber,
          data: thisAccount,
          exists: !Utils.Object.isEmpty(thisAccount),
          isValidating: false,
        })
      })
      .catch(err => {
        setAccount({ ...account, error: err })
      })
  }, [])
  const { MapToDropDown, MapToDropDownFilter } = AgentLogic
  const [clusterId, setClusterId] = useState()
  const [selectedState, setSelectedState] = useState(agent?.LGA?.State?.ID ?? '')
  const {
    assignableRoles,
    lgas,
    states,
    prepopulated,
    provinces,
    SENSITIVE_PERMISSIONS,
    networkManagers_,
    validator,
  } = useAgent(initialValues?.AccountMode, clusterId, roles, isUpdate === true)

  const filteredLGAs = selectedState === '' && !isUpdate ? lgas : lgas.filter((lga) => lga?.State?.ID === selectedState);

  const trimValue = (value) => {
    if (typeof value === 'string') {
      return value.trim()
    }
    else return value
  }

  useEffect(() => {
    setClusterId(agent?.Cluster?.ID)
  }, [agent])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        handleValidSubmit({ ...values })
      }}
      validate={validator}
    >
      {({
        values,
        touched,
        isValid,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <ClusterForm handleSubmit={handleSubmit}>
          {
            <React.Fragment>
              <React.Fragment>
                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={3} md={4}>
                        <ClusterInput
                          defaultValue={values?.AccountMode}
                          disabled={true}
                          label={"Account Mode"}
                          id={"Mode"}
                          name="AccountMode"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.AccountMode}
                          validationError={errors?.AccountMode}
                        />
                        <ClusterButton
                          className="btn btn-outline-primary btn-sm waves-effect waves-light mt-2"
                          onHandleClick={e => {
                            e.preventDefault()
                            back()
                          }}
                          disabled={false}
                        >
                          {isUpdate === true ? "Revalidate" : "Change"}
                        </ClusterButton>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </React.Fragment>
              {!Utils.Object.isEmpty(account?.data?.Agent)
                ? (
                  <React.Fragment>
                    <Row>
                      <Col lg={12}>
                        <p>Parent Agent Details</p>
                      </Col>
                    </Row>

                    <Card
                      outline
                      style={{
                        background: "transparent",
                        borderColor: "#999999",
                      }}
                      className="border"
                    >
                      <CardBody>
                        <Row>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              disabled={true}
                              defaultValue={
                                account?.data?.Agent?.OtherNames ||
                                agent?.TheSuperAgent?.Othernames
                              }
                              label={"First Name"}
                              value={
                                account?.data?.Agent?.OtherNames ||
                                agent?.TheSuperAgent?.Othernames
                              }
                            />
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              disabled={true}
                              defaultValue={
                                account?.data?.Agent?.LastName ||
                                agent?.TheSuperAgent?.Lastname
                              }
                              label={"Last Name"}
                              value={
                                account?.data?.Agent?.LastName ||
                                agent?.TheSuperAgent?.Lastname
                              }
                            />
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              disabled={true}
                              defaultValue={
                                account?.data?.Agent?.Email ||
                                agent?.TheSuperAgent?.Email
                              }
                              label={"Email"}
                              value={
                                account?.data?.Agent?.Email ||
                                agent?.TheSuperAgent?.Email
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              permissions={SENSITIVE_PERMISSIONS}
                              disabled={true}
                              label={"Account Number"}
                              name={"ParentAccountNumber"}
                              defaultValue={values?.AccountNumber}
                              type="text"
                              onBlur={handleBlur}
                              value={trimValue(values?.AccountNumber)}
                            />
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              permissions={SENSITIVE_PERMISSIONS}
                              disabled={true}
                              label={"Income Account"}
                              name={"ParentIncomeAccount"}
                              defaultValue={values?.AccountNumber}
                              type="text"
                              onBlur={handleBlur}
                              value={trimValue(values?.AccountNumber)}
                            />
                          </Col>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              permissions={SENSITIVE_PERMISSIONS}
                              disabled={true}
                              label={"Commission Account"}
                              name={"ParentCommissionAccount"}
                              defaultValue={values?.AccountNumber}
                              type="text"
                              onBlur={handleBlur}
                              value={trimValue(values?.AccountNumber)}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </React.Fragment>
                ) : null}
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <p>Basic Data</p>
                  </Col>
                </Row>

                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          // disabled={prepopulated(values?.LastName)}
                          defaultValue={values?.LastName || account?.data?.LastName}
                          focus
                          label={"Surname"}
                          id={"surname"}
                          name={"LastName"}
                          type="text"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.LastName}
                          validationError={errors?.LastName}
                        />
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          disabled={prepopulated(values?.OtherNames)}
                          defaultValue={values?.OtherNames || account?.data?.OtherNames}
                          label={"Other Names"}
                          id={"otherNames"}
                          name={"OtherNames"}
                          type="text"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.OtherNames}
                          validationError={errors?.OtherNames}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {InstitutionsHelper.Validator.isBankOne ? (
                        <React.Fragment>
                          <Col lg={4} md={4}>
                            <ClusterInput
                              defaultValue={values?.Username}
                              label={"Username"}
                              id={"username"}
                              name={"Username"}
                              type="text"
                              required={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.Username}
                              validationError={errors?.Username}
                            />
                          </Col>
                        </React.Fragment>
                      ) : (
                        <Col lg={4} md={4}>
                          <ClusterInput
                            defaultValue={values?.BusinessName}
                            label={"Business Name"}
                            id={"businessName"}
                            name={"BusinessName"}
                            type="text"
                            required={false}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.BusinessName}
                            validationError={errors?.BusinessName}
                          />
                        </Col>
                      )}
                      <Col lg={3} md={4}>
                        <ClusterInput
                          permissions={SENSITIVE_PERMISSIONS}
                          defaultValue={values?.BankVerificationNumber || account?.data?.BankVerificationNumber}
                          label={"Bank Verification Number"}
                          id={"bvn"}
                          name={"BankVerificationNumber"}
                          type="number"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.BankVerificationNumber}
                          validationError={errors?.BankVerificationNumber}
                        />
                      </Col>

                      <Col lg={2} md={2}>
                        <FormGroup>
                          <Label>Gender</Label>
                          <ClusterDropdown
                            disabled={prepopulated(values?.Gender)}
                            id="Gender"
                            name={"Gender"}
                            defaultValue={values?.Gender || account?.data?.Gender}
                            value={values?.Gender}
                            placeholder={"- Select - "}
                            items={GENDER}
                            required={true}
                            onChange={(name, value) => {
                              setFieldValue("Gender", value)
                            }}
                            validationError={errors?.Gender}
                          />
                        </FormGroup>
                      </Col>

                      {isUpdate === true &&
                        values?.UserID ? null : !assignableRoles ? (
                          <Loading />
                        ) : (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <Label>User Role</Label>
                            {Utils.List.isEmpty(assignableRoles) ? (
                              <Alert color="danger">
                                No User Roles available for the agent scope
                              </Alert>
                            ) : (
                              <ClusterDropdown
                                id="UserRoleId"
                                name={"UserRoleId"}
                                placeholder={"- Select a role - "}
                                defaultValue={""}
                                items={assignableRoles}
                                onChange={(name, value) => {
                                  setFieldValue("UserRoleId", value)
                                }}
                                validationError={errors?.UserRoleId}
                              />
                            )}
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg={3} md={4}>
                        <FormGroup>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label for="category">Category</Label>
                            <Link
                              to="/create-category/0"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                                border: 0,
                              }}
                              className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                            >
                              <i
                                className="bx bx-plus"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Create new
                            </Link>
                          </div>
                          {!categoriesLoading ? (
                            categories ? (
                              <ClusterDropdown
                                required={true}
                                name={"CategoryID"}
                                id="CategoryID"
                                defaultValue={values?.Category?.Name}
                                value={values?.Category?.Name}
                                placeholder={"-Select an agent category-"}
                                items={
                                  values?.AccountMode ===
                                    AccountMode[AccountMode.Wallet] ||
                                    !agent?.IsSubAgent
                                    ? MapToDropDown(categories)
                                    : MapToDropDownFilter(categories)
                                }
                                onChange={(name, value) => {
                                  setFieldValue("CategoryID", value)
                                }}
                                validationError={errors?.CategoryID}
                              />
                            ) : (
                              <Alert color="danger">
                                No Categories are available
                              </Alert>
                            )
                          ) : (
                            <Loading></Loading>
                          )}
                        </FormGroup>
                      </Col>

                      <Col lg={4} md={4}>
                        <FormGroup>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Label>Cluster</Label>
                            <Link
                              to="/create-cluster/0"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: 0,
                                border: 0,
                              }}
                              className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                            >
                              <i
                                className="bx bx-plus"
                                style={{ marginRight: "5px" }}
                              ></i>
                              Create new
                            </Link>
                          </div>
                          {!clustersLoading ? (
                            clusters ? (
                              <ClusterDropdown
                                required={true}
                                defaultValue={values?.Cluster?.Name}
                                value={values?.Cluster?.Name}
                                id={"ClusterID"}
                                placeholder={"-Select a cluster-"}
                                items={MapToDropDown(clusters)}
                                name={"ClusterID"}
                                onChange={(name, value) => {
                                  setFieldValue("ClusterID", value)
                                  setClusterId(value)
                                }}
                                validationError={errors?.ClusterID}
                              />
                            ) : (
                              <Alert color="danger">
                                No Clusters are available
                              </Alert>
                            )
                          ) : (
                            <Loading></Loading>
                          )}
                        </FormGroup>
                      </Col>
                      {networkManagers_.data ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <div
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Label>Network Manager</Label>
                              <Link
                                to="/create-anm/0"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  padding: 0,
                                  border: 0,
                                }}
                                className="btn btn-outline-primary btn-sm waves-effect waves-light mb-2"
                              >
                                <i
                                  className="bx bx-plus"
                                  style={{ marginRight: "5px" }}
                                ></i>{" "}
                                Create new
                              </Link>
                            </div>
                            <ClusterDropdown
                              defaultValue={
                                values?.Cluster?.ID == clusterId
                                  ? values?.NetworkManager?.Name
                                  : values?.Cluster?.ID == clusterId
                                    ? values?.NetworkManager?.Name
                                    : ""
                              }
                              value={values?.NetworkManager?.Name}
                              placeholder={"-Select a network manager-"}
                              name={"NetworkManagerID"}
                              id={"NetworkManagerID"}
                              items={MapToDropDown(networkManagers_.data)}
                              onChange={(name, value) => {
                                setFieldValue("NetworkManagerID", value)
                              }}
                              validationError={errors?.NetworkManagerID}
                            />
                          </FormGroup>
                        </Col>
                      ) : null}
                    </Row>
                  </CardBody>
                </Card>
              </React.Fragment>
              {(values?.AccountMode === AccountMode[AccountMode.CoreBanking] &&
                Utils.Object.isEmpty(account?.data?.Agent)) ||
                !agent?.IsSubAgent ? (
                <React.Fragment>
                  <Row>
                    <Col lg={12}>
                      <p>Account Information</p>
                    </Col>
                  </Row>

                  <Card
                    outline
                    style={{
                      background: "transparent",
                      borderColor: "#999999",
                    }}
                    className="border"
                  >
                    <CardBody>
                      <Row>
                        <Col lg={3} md={4}>
                          <ClusterInput
                            disabled={true}
                            permissions={SENSITIVE_PERMISSIONS}
                            label={"Account Number"}
                            id={"accountNumber"}
                            name={"AccountNumber"}
                            defaultValue={values?.AccountNumber}
                            type="text"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={trimValue(values?.AccountNumber)}
                            validationError={errors?.AccountNumber}
                          />
                        </Col>
                        <Col lg={3} md={4}>
                          <ClusterInput
                            disabled={true}
                            defaultValue={
                              values?.DomiciliaryBank || GetInstitution().name
                            }
                            label={"Domiciliary bank"}
                            id={"domiciliaryBank"}
                            name={"DomiciliaryBank"}
                            type="text"
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              trimValue(values?.DomiciliaryBank) || GetInstitution().name
                            }
                            validationError={errors?.DomiciliaryBank}
                          />
                        </Col>

                        <Col lg={3} md={4}>
                          <ClusterInput
                            disabled={true}
                            permissions={SENSITIVE_PERMISSIONS}
                            defaultValue={values?.IncomeAccount}
                            required={
                              values?.AccountMode === AccountMode.CoreBanking
                                ? true
                                : false
                            }
                            label={"Income Account"}
                            id={"incomeAccount"}
                            name={"IncomeAccount"}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={trimValue(values?.IncomeAccount)}
                            validationError={errors?.IncomeAccount}
                          />
                        </Col>
                        <Col lg={3} md={4}>
                          <ClusterInput
                            disabled={true}
                            permissions={SENSITIVE_PERMISSIONS}
                            defaultValue={values?.CommissionAccount}
                            required={
                              values?.AccountMode === AccountMode.CoreBanking
                                ? true
                                : false
                            }
                            label={"Commission Account"}
                            id={"commissionAccount"}
                            name={"CommissionAccount"}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={trimValue(values?.CommissionAccount)}
                            validationError={errors?.CommissionAccount}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </React.Fragment>
              ) : (
                <React.Fragment />
              )}
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <p>Contact Me</p>
                  </Col>
                </Row>

                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.Email || account?.data?.Email}
                          label={"Email"}
                          id={"email"}
                          name={"Email"}
                          type="email"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.Email}
                          validationError={errors?.Email}
                        />
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          permissions={SENSITIVE_PERMISSIONS}
                          defaultValue={values?.PhoneNumber || account?.data?.PhoneNo}
                          value={trimValue(values?.PhoneNumber)}
                          label={"Assigned Phone Number"}
                          id={"phoneNumber"}
                          name={"PhoneNumber"}
                          type="tel"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          validationError={errors?.PhoneNumber}
                        />
                      </Col>
                      <Col lg={4} md={4}>
                        <ClusterInput
                          defaultValue={values?.PersonalPhoneNumber}
                          label={"Personal Phone Number"}
                          id={"personalPhonenumber"}
                          name={"PersonalPhoneNumber"}
                          type="tel"
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.PersonalPhoneNumber}
                          validationError={errors?.PersonalPhoneNumber}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {!Utils.Object.isEmpty(account?.data?.Agent) &&
                        agent?.IsSubAgent && (
                          <Col lg={4} md={4}>
                            <ClusterInput
                              disabled
                              defaultValue={
                                values?.TheSuperAgent?.PhoneNumber ||
                                account?.data?.Agent?.PhoneNumber
                              }
                              label={"Super Agent Phone Number"}
                              id={"superPhonenumber"}
                              name={"SuperAgentPhoneNumber"}
                              type="tel"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required={false}
                              value={
                                values?.SuperAgentPhoneNumber ||
                                account?.data.Agent?.PhoneNumber
                              }
                              validationError={errors?.SuperAgentPhoneNumber}
                            />
                          </Col>
                        )}

                      <Col lg={4} md={4}>
                        <FormGroup>
                          <Label>Address</Label>
                          <ClusterTextArea
                            disabled={prepopulated(values?.AddressLocation)}
                            name="AddressLocation"
                            className="form-control"
                            defaultValue={values?.AddressLocation ?? ""}
                            id={"address"}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.AddressLocation}
                            validationError={errors?.AddressLocation}
                          />
                        </FormGroup>
                      </Col>

                      {!InstitutionsHelper.Validator.isAccessCongo ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <Label>State</Label>
                            <ClusterDropdown
                              placeholder={"- Enter a State - "}
                              defaultValue={values?.LGA?.State?.Name}
                              value={values?.LGA?.State?.Name}
                              name={"STATEID"}
                              id="STATEID"
                              items={MapToDropDown(states)}
                              onChange={(name, value) => {
                                setSelectedState(value)
                              }}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}

                      {!InstitutionsHelper.Validator.isAccessCongo ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <Label>L.G.A</Label>
                            <ClusterDropdown
                              placeholder={"- Enter a L.G.A - "}
                              defaultValue={values?.LGA?.Name}
                              value={values?.LGA?.Name}
                              name={"LGAID"}
                              id="LGAID"
                              items={MapToDropDown(filteredLGAs)}
                              required={true}
                              onChange={(name, value) => {
                                setFieldValue("LGAID", value)
                              }}
                              validationError={errors?.LGAID}
                            />
                          </FormGroup>
                        </Col>
                      ) : InstitutionsHelper.Validator.isAccessCongo ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <Label>Province</Label>
                            <ClusterDropdown
                              name="ProvinceID"
                              id="ProvinceID"
                              placeholder={"- Enter a Province - "}
                              defaultValue={values?.Province?.Name}
                              items={MapToDropDown(provinces)}
                              required={true}
                              onChange={(name, value) => {
                                setFieldValue("ProvinceID", value)
                              }}
                              validationError={errors?.ProvinceID}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}

                      {InstitutionsHelper.Validator.isPolaris ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <ClusterInput
                              label={"Tax Payer ID"}
                              id={"taxPayerID"}
                              name={"TaxPayerID"}
                              defaultValue={values?.TaxPayerID}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.TaxPayerID}
                              validationError={errors?.TaxPayerID}
                            />
                          </FormGroup>
                        </Col>
                      ) : InstitutionsHelper.Validator.isAccessNg ? (
                        <Col lg={4} md={4}>
                          <FormGroup>
                            <ClusterInput
                              label={"Staff ID"}
                              id={"staffID"}
                              name={"StaffID"}
                              defaultValue={values?.StaffID}
                              type="text"
                              required={true}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.StaffID}
                              validationError={errors?.StaffID}
                            />
                          </FormGroup>
                        </Col>
                      ) : (
                        <React.Fragment></React.Fragment>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </React.Fragment>
              <React.Fragment>
                <Row>
                  <Col lg={12}>
                    <p>Configurations</p>
                  </Col>
                </Row>

                <Card
                  outline
                  style={{ background: "transparent", borderColor: "#999999" }}
                  className="border"
                >
                  <CardBody>
                    <Row>
                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.PostingLimit}
                          label={"Deposit Limit"}
                          id={"depositLimit"}
                          name={"PostingLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.PostingLimit}
                          validationError={errors?.PostingLimit}
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.CummulativeDepositLimit}
                          label={"Cummulative Deposit Limit"}
                          id={"cummulativeDepositLimit"}
                          name={"CummulativeDepositLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.CummulativeDepositLimit}
                          validationError={errors?.CummulativeDepositLimit}
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.WithdrawalLimit}
                          label={"Withdrawal Limit"}
                          id={"withdrawalLimit"}
                          name={"WithdrawalLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.WithdrawalLimit}
                          validationError={errors?.WithdrawalLimit}
                        />
                      </Col>

                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.CummulativeWithdrawalLimit}
                          label={"Cummulative Withdrawal Limit"}
                          id={"cummulativeWithdrawalLimit"}
                          name={"CummulativeWithdrawalLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.CummulativeWithdrawalLimit}
                          validationError={errors?.CummulativeWithdrawalLimit}
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.CardTransactionAmountLimit ?? 0}
                          label={"Card Transaction Limit"}
                          id={"cardTransactionLimit"}
                          name={"CardTransactionAmountLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.CardTransactionAmountLimit}
                          validationError={errors?.CardTransactionAmountLimit}
                        />
                      </Col>
                      <Col lg={3} md={6}>
                        <ClusterInput
                          defaultValue={values?.TransferLimit}
                          label={"Transfer Limit"}
                          id={"transferLimit"}
                          name={"TransferLimit"}
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.TransferLimit}
                          validationError={errors?.TransferLimit}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Col
                  lg={4}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <ClusterCheckBox
                    checked={values?.AllowSubDelegation}
                    onHandleChange={(name, value) => setFieldValue(name, value)}
                    label={"Allow Sub Agent Control"}
                    name={"AllowSubDelegation"}
                    id={"allowSubControl"}
                    validationError={errors?.AllowSubDelegation}
                  />
                </Col>
              </React.Fragment>
              <Row style={{ paddingBottom: "50px" }}>
                <Col lg={12}>
                  <ClusterButton
                    loading={editLoading}
                    disabled={!touched ? true : editLoading || !isValid}
                    color={"primary"}
                    addon={"bx bx-save"}
                  >
                    {isUpdate === true ? "Update" : "Save"}
                  </ClusterButton>
                </Col>
              </Row>
            </React.Fragment>
          }
        </ClusterForm>
      )}
    </Formik>
  )
}

export default AgentDetailsForm
