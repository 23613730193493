import { REPORTSTATUS, GETSTATUS, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Requested By", name: "requestedBy", entityKeyName: "RequestedBy" },
  {
    title: "Date Requested",
    name: "dateRequested",
    entityKeyName: "DateRequested",
  },
  { title: "Super Agent", name: "superAgent", entityKeyName: "SuperAgent" },
  { title: "Report Type", name: "ReportType", entityKeyName: "ReportType" },
  {
    title: "ANM",
    name: "agentNetworkManager",
    entityKeyName: "AgentNetworkManager",
  },
  {
    title: "Date Range From",
    name: "dateRangeFrom",
    entityKeyName: "FromDate",
  },
  { title: "Date Range To", name: "dateRangeTo", entityKeyName: "ToDate" },
  { title: "Status", name: "status", entityKeyName: "Status", isBadge: true },
  { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
  fromDate: "",
  toDate: "",
  reportType: undefined,
}
const SearchOptions = (startDate, endDate, reportTypes) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  // options.range.from = moment(startDate).format("YYYY-MM-DD")
  // options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.range.enabled = false
  options.fields = [
    {
      type: "date",
      label: "Date Requested",
      id: "s-dateRequested",
      defaultValue: moment(),//.format("YYYY-MM-DD"),
      name: "dateRequested",
    },
    {
      type: "text",
      label: "Requested By",
      id: "s-requestedBy",
      defaultValue: "",
      name: "requestedBy",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select -",
      data: REPORTSTATUS,
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "Report Type",
      id: "reportType",
      defaultValue: "- Select a Report Type -",
      data: MapTypesToDropDown(reportTypes),
      name: "reportType",
      width: 3,
    },
  ]
  return options
}
const MapToRows = download => {
  return {
    id: download.ID,
    requestedBy: download.RequestedBy,
    dateRequested: download.RequestDate,
    superAgent: download.SuperAgent,
    ReportType: download.ReportType,
    agentNetworkManager: download.AgentNetworkManager,
    dateRangeFrom: download.FromDate,
    dateRangeTo: download.ToDate,
    status: {
      label: download.ReportStatus,
      badgeClass:
        download.ReportStatus == "Finished"
          ? "success"
          : download.ReportStatus == "Logged"
          ? "secondary"
          : "info",
    },
  }
}
const SearchCriteria = (params, maxSize) => {
 
  return JSON.stringify({
    ReportType: params["reportType"],
    Status: params["status"],
    RequestedBy: params["requestedBy"],
    DateRequested: params["dateRequested"],
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
  }
  return []
}
const MapTypesToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item, key: item, value: item }))
  }
  return []
}
export default {
  HEADERS,
  ERRORLIST,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapTypesToDropDown,
}
