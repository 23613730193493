import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "./report"

//i18n
import { withTranslation } from "react-i18next"
import {
  getCommissionStructureDetail,
  getCommissionStructuresByCategory,
  commissionStructurePurge,
  removeCommissionStructure,
  updateCommissionStructure,
} from "store/actions"
import CommissionStructureLogic from "../../commission-structure"
import withDispose from "components/HOCs/WithDispose"
const fetcher = url => {
  return get(url, {}, true)
}
import { get } from "helpers/api_helper"
import EditModal from "./modal"
import useSWR from "swr"
import { GET_ALL_CASHOUT_INSTITUTIONS } from "helpers/url_helper"
import { Utils } from "helpers/utility"

const CommissionStructureList = props => {
  const {
    commissionStructures,
    onGetCommissionStructures,
    error,
    onSaveConfiguration,
    onGetCommissionStructureDetail,
    commissionStructureDetail,
    onPurge,
    totalCount,
    onUpdateCommissionStructure,
    onDeleteCommissionStructure,
    deleteLoading,
    editLoading,
    loading,
    categoryId,
    success,
    statusChanged,
    structuresToModify,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    ERRORLIST,
    UpdateErrorList,
    SearchOptions,
    MapToModalState,
  } = CommissionStructureLogic
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([...HEADERS])
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [isEnabled, setIsEnabled] = useState(false)
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  const maxSize = 10
  const [pageNumber, setPageNumber] = useState(1)
  const [viewedCommissionStructure, setViewedCommissionStructure] = useState<any>()
  const [commissionStructure, setCommissionStructure] = useState<any>({}) 

  const [params, setParams] = useState({
    fromDate: startDateStr,
    toDate: endDateStr,
    page: pageNumber,
  })
  const [errorList, setErrorList] = useState(UpdateErrorList())

  const { data, isValidating } = useSWR(GET_ALL_CASHOUT_INSTITUTIONS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {
    populateRows()
  }, [])

  useEffect(() => {
    if (
      !Utils.Object.isEmpty(commissionStructureDetail)
    ) {
      setmodal(true)
      setViewedCommissionStructure(commissionStructureDetail)
      setModalData(MapToModalState(commissionStructureDetail, (data as any)?.Data))
    }
  }, [commissionStructureDetail])

  useEffect(() => {
    search(categoryId)
  }, [categoryId])

  useEffect(() => {
    search(categoryId)
  }, [statusChanged])

  useEffect(() => {
    populateRows()
  }, [success])

  const populateRows = () => {
    if (props.commissionStructures) {
      let rws = []
      props.commissionStructures.forEach(structure => {
        let row = MapToRows(structure)
          ; (row["action"] = (
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => onViewDetails(structure.ID)}
            >
              View Details
            </Button>
          )),
            rws.push(row)
      })

      setRows(rws)
    }
  }
  const handlePageChange = page => {
    setPageNumber(page)
    let p = { ...params, page }
    search(SearchCriteria(p, maxSize))
  }

  function search(query) {
    if (query) onGetCommissionStructures(query)
  }

  const handleOnChange = (key, value) => {
    let u = Object.assign({},commissionStructure)
    u[key] = value
    setCommissionStructure(u)
  }

  const handleValidSubmit = values => {
    
          let structureToUpdate = {
          ...commissionStructureDetail,
          ...values,
          IsActive: true,
          AgentCategoryID: categoryId,
          // IssuingCashOutInstitutionID: values?.IssuingCashOutInstitutionID?? commissionStructureDetail.IssuingCashOutInstitution?.ID,
        }
        for (let key in structureToUpdate) {
          if (
            structureToUpdate[key] instanceof Object ||
            structureToUpdate[key] instanceof Array
          ) {
            delete structureToUpdate[key]
          }
        }
        onUpdateCommissionStructure(structureToUpdate)     
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetCommissionStructureDetail(id)
  }

  return (
    <React.Fragment>
      <Container fluid>

        <Row>
          <Col lg="12">
            <Report
              tableData={{
                columns: columns,
                rows: rows,
                paginationOptions: {
                  totalCount: totalCount,
                  onPageChange: page => handlePageChange(page),
                  pageNumber: pageNumber,
                  maxSize: maxSize,
                },
                pageTitle: undefined,
                isLoading: loading,
              }}
            />
          </Col>
        </Row>
      </Container>

      {modal ? (
        <EditModal
          badgeClass={
            viewedCommissionStructure?.IsActive ? "success" : "secondary"
          }
          status={viewedCommissionStructure?.IsActive ? "Active" : "Inactive"}
          open={modal}
          cashOutInstitutions={(data as any)?.Data}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          structure={commissionStructureDetail}
          setErrorList={setErrorList}
          errorList={errorList}
          handleValidSubmit={handleValidSubmit}
          deleteLoading={deleteLoading}
          editLoading={editLoading}
          handleOnChange={handleOnChange}
          onDelete={onDeleteCommissionStructure}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </EditModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

CommissionStructureList.propTypes = {
  commissionStructures: PropTypes.array,
  commissionStructureDetail: PropTypes.object,
  onGetCommissionStructures: PropTypes.func,
  onGetCommissionStructureDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  onEnableCommissionStructure: PropTypes.func,
  categoryId: PropTypes.number,
}

const mapStateToProps = ({
  commissionStructures: {
    commissionStructures,
    structuresToModify,
    error,
    success,
    commissionStructureDetail,
    totalCount,
    deleteLoading,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  return {
    commissionStructures,
    structuresToModify,
    error,
    success,
    deleteLoading,
    commissionStructureDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCommissionStructures: categoryId =>
    dispatch(getCommissionStructuresByCategory(categoryId)),
  onGetCommissionStructureDetail: id =>
    dispatch(getCommissionStructureDetail(id)),
  onPurge: () => dispatch(commissionStructurePurge()),
  onDeleteCommissionStructure: payload =>
    dispatch(removeCommissionStructure(payload)),
  onSaveConfiguration: payload => dispatch(removeCommissionStructure(payload)),
  onUpdateCommissionStructure: structure =>
    dispatch(updateCommissionStructure(structure)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CommissionStructureList))))
