import React, { useState, useEffect, useMemo } from "react"
import { saveFile } from "helpers/download_util/file_download"
import PropTypes from "prop-types"
import {
  Card,
  Col,
  Row,
  CardBody,
  CardTitle,
  Badge,
  CardHeader,
  CardFooter,
} from "reactstrap"
import { CSVLink } from "react-csv"
import NoData from "components/Common/NoData"
import Loading from "components/Common/Loading"
import Pagination from "components/Common/Pagination"
import ClusterButton from "./Button/Button"
import { post } from "helpers/api_helper"
import moment from "moment"
import { toast } from "react-toastify"
import { GetInstitution, Institution } from "helpers/authentication/institution"
import { Environment } from "common/env-config"
import { Utils } from "helpers/utility"
const excelFormat = {
  Filter: {
    LastName: "string",
    OtherNames: "string",
    Code: "string",
    LocationCode: "string",
    AccountNumber: "string",
    PhoneNumber: "string",
    FromDate: "2021-09-02T10:44:59.941Z",
    ToDate: "2021-09-02T10:44:59.941Z",
    SuperAgentPhoneNumber: "string",
    TerminalID: "string",
    Status: 0,
    AgentType: 0,
    AgentNetworkManagerID: 0,
    AgentClusterManagerID: 0,
    AgentCategoryID: 0,
    StartIndex: 0,
    MaxSize: 0,
  },
  Columns: [
    {
      EntityKeyName: "string",
      ColumnIndex: 0,
      HeaderName: "string",
      StringFormatPattern: "string",
    },
  ],
  FileName: "string",
}
const ClusterDataTable = props => {
  let {
    columns,
    rows,
    onPageChange,
    paginationOptions,
    title,
    loading,
    csv,
  } = props

  if (!columns) columns = []
  if (GetInstitution().code == '*' && rows[0]?.institution){
    columns = [...columns.slice(0, 1), { title: "Institution", name: "institution", entityKeyName: "InstitutionName" }, ...columns.slice(1,columns.length)]
  }
  if (!rows) rows = []
  if (paginationOptions == undefined)
    paginationOptions = { maxSize: 0, totalCount: 0, pageNumber: 0 }
  const { maxSize, totalCount, pageNumber } = paginationOptions;
  let [csvLoading, setCSVLoading] = useState(false)
  let count = 1

  const rowItems = useMemo(() => {
    let r = []
    let rowSpanTracker = {};
    if (Utils.List.isEmpty(rows)) return r;

    rows.forEach((row) => {
      let item = { data: [] }
      columns.forEach((c) => {
        let skipRow = false;
        let details = row[c.name];
        let rowSpan = 1;
        const hasTableSettings = typeof details === 'object' && details?.settings;
        if (hasTableSettings) {
          rowSpan = details.settings?.span ?? 1;
          details = details.value;
        }
        if (rowSpan && rowSpan > 1) {
          const trackerKey = `${details}`
          let tracker = rowSpanTracker[trackerKey];
          let isTracking = tracker && tracker > 0;
          if (isTracking) {

            tracker -= 1;
            rowSpanTracker[trackerKey] = tracker;
            rowSpan = tracker;
            skipRow = true;
          }
          else {
            rowSpanTracker[trackerKey] = rowSpan - 1;
          }
        } else {
          rowSpan = 1;
        }

        if (!skipRow) {
          if (!c.isBadge) {
            item.data.push(<td key={(count += 1)} rowSpan={rowSpan}>{details}</td>)
          } else {
            item.data.push(
              <td key={(count += 1)} rowSpan={rowSpan}>
                <Badge
                  className={"font-size-12 badge-soft-" + details.badgeClass}
                  color={details.badgeClass}
                  pill
                >
                  {details?.label}
                </Badge>
              </td>
            )
          }
        }
      })
      r.push(item)
    })
    return r;
  }, [rows])


  const loadData = query =>
    new Promise(async (resolve, reject) => {
      try {
        setCSVLoading(true)
        let blob = await post(
          csv.url + "/export",
          query,
          { responseType: "blob" },
          (csv.pathType && csv.pathType != "core") ? false : true
        )
        saveFile(blob, query.FileName)
        setCSVLoading(false)
      } catch (d) {
        setCSVLoading(false)
        if (d?.status == 400) toast.success("The export might take a little while, it would be sent to your email");
        else toast.error("Sorry we seems to have experienced a technical difficulty")
      }
    })

  const downloadExcel = () => {
    if (csv) {
      let format = Object.assign({}, excelFormat);

      format.FileName = `Report_${moment()}.xlsx`
      format.Filter = JSON.parse(csv.query)

      if (csv.maxDuration && Environment.IsProduction && Math.abs((moment(format.Filter.ToDate).diff(moment(format.Filter.FromDate), "day"))) >= csv.maxDuration) {
        toast.error(`Please select a date range less than ${csv.maxDuration} days for this report. For date ranges above ${csv.maxDuration} days please use the 'Log and Download' module`);
        return;
      }
      format.Columns = []
      columns.forEach((column, i) => {
        if (!column.isAction && column.entityKeyName) {
          let c = {
            EntityKeyName: column.entityKeyName,
            ColumnIndex: i,
            HeaderName: column.title,
            StringFormatPattern: undefined,
          }
          if (column.defaultValue) c["DefaultValue"] = column.defaultValue
          format.Columns.push(c)
        }
      })
      format.Filter.MaxSize = 100000000
      format.Filter.StartIndex = 0
      loadData(format)
        .then(c => { })
        .catch(c => {
          toast.error(
            "There seems to have been an issue loading the report. Kindly retry"
          )
        })
    } else
      toast.error(
        "Exporting to excel is not properly configured for this page, Kindly contact support"
      )
  }
  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {!title ? (
                <React.Fragment></React.Fragment>
              ) : (
                <CardTitle className="mb-4">{title}</CardTitle>
              )}

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      {columns.map((c, key) => (
                        <th key={key}>{c.title}</th>
                      ))}
                    </tr>
                  </thead>
                  {loading ? (
                    <tbody>
                      <tr style={{ textAlign: "center", paddingTop: "20px" }}>
                        <td colSpan={columns.length}>
                          <Loading></Loading>
                        </td>
                      </tr>
                    </tbody>
                  ) : rows != undefined && rows.length > 0 ? (
                    <tbody>
                      {rowItems.map((row, key) => (
                        <tr key={"_tr_" + key}>
                          {row.data.map((r, key) => r)}
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan={columns.length}>
                          <NoData></NoData>{" "}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>

                <Pagination
                  maxSize={maxSize}
                  totalCount={totalCount}
                  pageNumber={pageNumber}
                  setPageNumber={page => onPageChange(page)}
                >
                  {csv ? (
                    <ClusterButton
                      color={"success"}
                      className={"btn-sm"}
                      addon={"bx bx-upload"}
                      loading={csvLoading}
                      onHandleClick={downloadExcel}
                    >
                      To Excel
                    </ClusterButton>
                  ) : (
                    <React.Fragment></React.Fragment>
                  )}
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}
ClusterDataTable.propTypes = {
  title: PropTypes.string,
  columns: PropTypes.array,
  rows: PropTypes.array,
  paginationOptions: PropTypes.object,
  onPageChange: PropTypes.func,
  loading: PropTypes.bool,
  csv: PropTypes.object,
}
export default ClusterDataTable
