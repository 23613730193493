import { GetInstitution, Institution } from "helpers/authentication/institution"
import {
  AGENTTYPE,
  BASESTATUS,
  Format,
  GETACCOUNTMODE,
  GETAGENTTYPE,
  GETGENDER,
  GETSTATUS,
  RECEIPTSTATUS,
  SEARCHOPTIONS,
} from "helpers/variables"
import moment from "moment"

const get_headers=()=>{
  var headers = []
  if(Institution.IsRemote()){
    headers.push({ title: "Route", name: "route", entityKeyName: "NodeName" })
  }
    [{ title: "Action", name: "action", isAction: true },
    { title: "Agent", name: "agentName", entityKeyName: "AgentName" },
    { title: "Amount", name: "amount", entityKeyName: "Amount" },
    { title: "Terminal", name: "terminal", entityKeyName: "TerminalID" },
    { title: "PAN", name: "pan", entityKeyName: "PAN" },
    {
      title: "Ref. No",
      name: "refno",
      entityKeyName: "RetrievalReferenceNumber",
    },
    { title: "STAN", name: "stan", entityKeyName: "STAN" },
    { title: "Resp. Code", name: "responseCode", entityKeyName: "ResponseCode" },
    { title: "Status", name: "status", isBadge: true, entityKeyName: "Status" },
    { title: "Date", name: "time", entityKeyName: "DateTime" },
  ].map(h=>headers.push(h))
 return headers
}


const HEADERS = get_headers()
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")

  options.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "number",
      label: "Agent Code",
      id: "agentCode",
      defaultValue: "",
      name: "agentCode",
    },
    {
      type: "text",
      label: "STAN",
      id: "stan",
      defaultValue: "",
      name: "stan",
    },
    {
      type: "text",
      label: "RRN",
      id: "rrn",
      defaultValue: "",
      name: "rrn",
    },
    {
      type: "number",
      label: "Amount",
      id: "amount",
      defaultValue: "",
      name: "amount",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select -",
      data: RECEIPTSTATUS,
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  return options
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    agentName: transaction.AgentName,
    amount: transaction.Amount,
    terminal: transaction.TerminalID,
    institution: transaction.InstitutionName,
    pan: transaction.PAN,
    stan: transaction.STAN,
    refno: transaction.RetrievalReferenceNumber,
    time: moment(transaction.DateTime).format("MMM Do yyyy HH:mm:ss"),
    responseCode: transaction.ResponseCode,
    status: {
      label: transaction.ResponseCode == "00" ? "Approved": !transaction.ResponseCode?"Pending" : "Declined",
      badgeClass: transaction.ResponseCode == "00" ? "success": !transaction.ResponseCode?"secondary" : "danger",
    },
    methodIcon: "fa-cc-mastercard",
    paymentMethod: transaction.CardHolder,
    route: transaction.NodeName??"NIBSS"
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentPhoneNumber: params["agentPhoneNumber"],
    AgentCode: params["agentCode"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    status: params["status"],
    STAN: params["stan"],
    RRN: params["rrn"],
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    AmountDecimal: params["amount"],
    StartIndex: (params["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}
const MapToModalState = agentDetail => {
  return [
    {
      title: "",
      items: [
        {
          id: "accountMode",
          label: "Account Mode",
          name: "accountMode",
          value: agentDetail.AccountMode,
        },
      ],
    },
    {
      title: "Basic Data",
      items: [
        {
          id: "lastName",
          label: "Surname",
          name: "lastName",
          value: agentDetail.LastName,
        },
        {
          id: "otherNames",
          label: "Other Names",
          name: "otherNames",
          value: agentDetail.OtherNames,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: agentDetail.Code,
        },
        {
          id: "terminalID",
          label: "Terminal ID",
          name: "terminalID",
          value: agentDetail.TerminalID,
        },
        {
          id: "agentType",
          label: "Agent Type",
          name: "agentType",
          value: agentDetail.Type,
        },
        {
          id: "dateCreated",
          label: "Date Created",
          name: "dateCreated",
          value: agentDetail.DateCreated,
        },
        {
          id: "dateActivated",
          label: "Date Activated",
          name: "dateActivated",
          value: agentDetail.DateActivated,
        },
        {
          id: "superAgent",
          label: "Super Agent",
          name: "superAgent",
          value: agentDetail.SuperAgent?.Mobilenumber,
        },
      ],
    },
    {
      title: "Contact Me",
      items: [
        {
          id: "email",
          label: "Email",
          name: "email",
          value: agentDetail.Email,
        },
        {
          id: "phoneNumber",
          label: "Phone Number",
          name: "phoneNumber",
          value: agentDetail.PhoneNumber,
        },
        {
          id: "street",
          label: "Street Address",
          name: "address",
          value: agentDetail.AddressLocation,
        },
      ],
    },
    {
      title: "Account Information",
      items: [
        {
          id: "accountNo",
          label: "Account Number",
          name: "accountNumber",
          value: agentDetail.AccountNumber,
        },
        {
          id: "domiciliaryBank",
          label: "Domiciliary Bank",
          name: "domiciliaryBank",
          value: GetInstitution.name,
        },
        {
          id: "postingLimit",
          label: "Posting Limit",
          name: "postingLimit",
          value: agentDetail.PostingLimit,
        },
        {
          id: "transferLimit",
          label: "Transfer Limit",
          name: "transferLimit",
          value: agentDetail.TransferLimit,
        },
        {
          id: "withdrawalLimit",
          label: "Withdrawal Limit",
          name: "withdrawalLimit",
          value: agentDetail.WithdrawalLimit,
        },
        {
          id: "cummulativePostingLimit",
          label: "Cummulative Posting Limit",
          name: "cummulativePostingLimit",
          value: agentDetail.CumulativePostingLimit,
        },
        {
          id: "cummulativeWithdrawalLimit",
          label: "Cummulative Withdrawal Limit",
          name: "cummulativeWithdrawalLimit",
          value: agentDetail.CumulativeWithdrawalLimit,
        },
      ],
    },
  ]
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
