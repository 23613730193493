import {
  GET_PERMISSIONS,
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_FUNCTION_MENU,
  GET_FUNCTION_MENU_SUCCESS,
  GET_FUNCTION_MENU_FAIL,
  GET_FUNCTION_ACTIONS,
  GET_FUNCTION_ACTIONS_SUCCESS,
  GET_FUNCTION_ACTIONS_FAIL,
  GET_FUNCTIONS,
  GET_FUNCTIONS_SUCCESS,
  GET_FUNCTIONS_FAIL,
  GET_FUNCTIONS_BY_USER,
  GET_FUNCTIONS_BY_USER_SUCCESS,
  GET_FUNCTIONS_BY_USER_FAIL,
} from "./actionTypes"

export const getPermissions = params => ({
  type: GET_PERMISSIONS,
  params,
})
export const getPermissionsSuccess = permissions => ({
  type: GET_PERMISSIONS_SUCCESS,
  payload: permissions,
})
export const getPermissionsFail = error => ({
  type: GET_PERMISSIONS_FAIL,
  payload: error,
})

export const getMenu = (institutionId?: number, username?: string, userCategory?: string) => ({
  type: GET_FUNCTION_MENU,
  institutionId,
  username,
  userCategory,
})
export const getMenuSuccess = menus => ({
  type: GET_FUNCTION_MENU_SUCCESS,
  payload: menus,
})
export const getMenuFail = error => ({
  type: GET_FUNCTION_MENU_FAIL,
  payload: error,
})

export const getActions = () => ({
  type: GET_FUNCTION_ACTIONS,
})
export const getActionsSuccess = actions => ({
  type: GET_FUNCTION_ACTIONS_SUCCESS,
  payload: actions,
})
export const getActionsFail = error => ({
  type: GET_FUNCTION_ACTIONS_FAIL,
  payload: error,
})

export const getFunctions = () => ({
  type: GET_FUNCTIONS,
})
export const getFunctionsSuccess = functions => ({
  type: GET_FUNCTIONS_SUCCESS,
  payload: functions,
})
export const getFunctionsFail = error => ({
  type: GET_FUNCTIONS_FAIL,
  payload: error,
})

export const getFunctionsByUser = () => ({
  type: GET_FUNCTIONS_BY_USER,
})
export const getFunctionsByUserSuccess = functions => ({
  type: GET_FUNCTIONS_BY_USER_SUCCESS,
  payload: functions,
})
export const getFunctionsByUserFail = error => ({
  type: GET_FUNCTIONS_BY_USER_FAIL,
  payload: error,
})
