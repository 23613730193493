import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  auditTrailPurge,
  getAuditTrails,
  enableAuditTrail,
  getAuditTrailDetail,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import { GET_ALL_AUDIT_TRAILS } from "helpers/url_helper"
import AuditTrailLogic from "../audittrail"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import ClusterDataTable from "components/Common/DataTable"
import useReportData from "hooks/UseReportData"

type AuditTrailData = {
  DataValue: any[]
}
const AuditTrailList = props => {
  const {
    auditTrails,
    onGetAuditTrails,
    error,
    success,
    onGetAuditTrailDetail,
    auditTrailDetail,
    totalCount,
    loading
  } = props
  const {
    MapToRows,
    MapToRowsEntityChange,
    ENTITY_CHANGE_HEADERS,
    SearchCriteria,
    HEADERS,
    SearchOptions,
    MapToModalState,
  } = AuditTrailLogic
  const {
    columns,
    rows,
    handlePageChange,
    pageNumber,
    maxSize,
    onAction,
    params,
    setRows,
  } = useReportData({
    onGetData: onGetAuditTrails,
    HEADERS: HEADERS,
    SearchCriteria: SearchCriteria,
  })
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions()
  )
  
  const [viewedAuditTrail, setViewedAuditTrail] = useState<AuditTrailData>()

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {
    if (auditTrailDetail && Object.keys(auditTrailDetail).length > 0) {
      setmodal(true)
      setViewedAuditTrail(auditTrailDetail)
      setModalData(MapToModalState(auditTrailDetail))
    }
  }, [auditTrailDetail])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])

  const populateRows = () => {
    let rws = []
    if (auditTrails) {
      auditTrails.forEach(auditTrail => {
        let row = MapToRows(auditTrail)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(auditTrail.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }
  function onViewDetails(id) {
    setmodal(true)
    onGetAuditTrailDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Audit Trail"}
            breadcrumbItem={"View Audit Trails"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_AUDIT_TRAILS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal

          open={modal}
          title={"Audit Trail Details"}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          success={success}
          error={error}
          append={[
            <EntityChangeReport
              key={1}
              columns={ENTITY_CHANGE_HEADERS}
              rows={
                MapToRowsEntityChange(viewedAuditTrail?.DataValue ?? [])

              }
            />,
          ]}
        >

        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

AuditTrailList.propTypes = {
  auditTrails: PropTypes.array,
  auditTrailDetail: PropTypes.object,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetAuditTrails: PropTypes.func,
  onGetAuditTrailDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool
}

const mapStateToProps = ({
  auditTrails: {
    auditTrails,
    error,
    success,
    auditTrailDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged
  },
}) => {
  return {
    auditTrails,
    error,
    auditTrailDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    success,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAuditTrails: query => dispatch(getAuditTrails(query)),
  onGetAuditTrailDetail: (id) => dispatch(getAuditTrailDetail(id)),
  onPurge: () => dispatch(auditTrailPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(AuditTrailList))))


const EntityChangeReport = props => {
  if (props.rows.length > 0) {
    return (
      <ClusterDataTable
        columns={props.columns}
        loading={false}
        rows={props.rows}
      ></ClusterDataTable>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}