import { GetInstitution } from "helpers/authentication/institution"
import { SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Name", name: "name", entityKeyName: "Name" },
  { title: "Code", name: "code", entityKeyName: "Code" },
  { title: "Phone Number", name: "phoneNumber", entityKeyName: "PhoneNumber" },
  { title: "Email", name: "email", entityKeyName: "Email" },
  { title: "Status", name: "status", isBadge: true, entityKeyName: "IsActive" },
  { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
  name: undefined,
  phoneNumber: undefined,
  accountNumber: "",
  email: undefined
}
const NOUSER_ERRORLIST = {
  ...ERRORLIST,
  username:undefined,
  userRoleId:undefined,
  gender:undefined,
  street:undefined,
  city:undefined,
  state:undefined
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Name",
      id: "s-name",
      defaultValue: "",
      name: "name",
    },
    {
      type: "text",
      label: "Code",
      id: "s-code",
      defaultValue: "",
      name: "code",
    },
    {
      type: "text",
      label: "Phone Number",
      id: "s-phoneNumber",
      defaultValue: "",
      name: "phoneNumber",
    },
    {
      type: "text",
      label: "Email",
      id: "s-email",
      defaultValue: "",
      name: "email",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
const MapToRows = clusterManager => {
  return {
    id: clusterManager.ID,
    name: clusterManager.Name,
    email: clusterManager.Email,
    code: clusterManager.Code,
    institution: clusterManager.InstitutionName,
    status: {
      label: clusterManager.IsActive ? "Active" : "Inactive",
      badgeClass: clusterManager.IsActive ? "success" : "secondary",
    },
    phoneNumber: clusterManager.PhoneNumber,
  }
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Name: parameters["name"],
    Email: parameters["email"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    PhoneNumber: parameters["phoneNumber"],
    Code: parameters["code"],
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

const MapToModalState = clusterManagerDetail => {
  return [
    {
      title: "",
      items: [
        {
          id: "name",
          label: "Name",
          name: "name",
          value: clusterManagerDetail.Name,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: clusterManagerDetail.Code,
        },
        {
          id: "phoneNumber",
          label: "Phone Number",
          name: "phoneNumber",
          value: clusterManagerDetail.PhoneNumber,
        },
        {
          id: "email",
          label: "Email",
          name: "email",
          value: clusterManagerDetail.Email,
        },
      ],
    },
  ]
}

export default {
  HEADERS,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  ERRORLIST,
  NOUSER_ERRORLIST,
  MapToDropDown,
}
