import PropTypes from "prop-types"
import React, { useEffect } from "react"

import {
  Switch,
  BrowserRouter as Router,
  Route,
  withRouter,
} from "react-router-dom"
import { connect } from "react-redux"

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes"

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware"

// layouts Format
import VerticalLayout from "./components/VerticalLayout"
import HorizontalLayout from "./components/HorizontalLayout"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import "react-toastify/dist/ReactToastify.css"

// Import Firebase Configuration file

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { getFunctionsByUser, getMenu } from "store/actions"
import { Validators } from "helpers/validators"

import { RecoilRoot } from "recoil"
import Pages404 from "pages/Utility/pages-404"
import { ToastContainer } from "react-toastify"
import authHeader from "helpers/authentication/auth-token-header"
import moment from "moment"
import Loading from "components/Common/Loading"
import clusterLogo from "assets/images/logo/dark/192x192.png"
import { Col, Row } from "reactstrap"

const App = props => {
  const { menus, onGetPermissions, onGetFunctions, functionsForUser, history } = props
  function getLayout() {
    let layoutCls = VerticalLayout

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()

  useEffect(() => {
    onGetPermissions();
    onGetFunctions();

    setInterval(() => {
      if (isTokenExpired()) {
        props.history.push("/logout")
      }

    }, 5000)

  }, [])

  const isTokenExpired = () => {
    let access = authHeader()
    let expiryDate = access?.AccessToken?.exp
    return !expiryDate || moment().isSameOrAfter(moment(expiryDate))
  }
  if ((!menus || menus.length == 0) && Object.keys(authHeader()).length > 0) {
    return (
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <div style={{ textAlign: "center" }}>
              <div style={{ width: "80px", margin: "20px auto" }}>
                <img src={clusterLogo} />
              </div>
              <h3 className="mt-2" style={{ fontWeight: 800 }}>
                Almost There ...
              </h3>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <React.Fragment>
      <RecoilRoot >
        <Router basename="/">
          <Switch>
            {authRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}
            {userRoutes
              .filter(route => {
                return [...menus, ...functionsForUser].find(p => {
                  return p.children.find(x => x.path === route.path)
                })
                  ? true
                  : false
              })
              .map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
            <Route component={Pages404} />
          </Switch>
        </Router>
      </RecoilRoot>
      <ToastContainer containerId="my-toast-container"></ToastContainer>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
  menus: PropTypes.array,
  functionsForUser: PropTypes.array,
  error: PropTypes.any,
  onGetPermissions: PropTypes.func,
}

const mapStateToProps = ({ permissions: { error, menus, functionsForUser }, Layout }) => {
  const err = Validators.extractError(error)
  return {
    layout: Layout,
    menus,
    error: err,
    functionsForUser
  }
}
const mapDispatchToProps = dispatch => ({
  onGetPermissions: () => dispatch(getMenu()),
  onGetFunctions: () => dispatch(getFunctionsByUser()),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App))
