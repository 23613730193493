import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  ModalFooter,
  Badge,
  Label,
} from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import ClusterForm from "components/Common/Form"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import {
  APPLICABILITY,
  COMMISSIONTYPE,
  COSTBEARER,
  FEETYPE,
  PAYMENTMODE,
  TRANSACTIONTYPENAMES,
} from "helpers/variables"
import { Validators } from "helpers/validators"
import Logic from "../../commission-structure";
import withFormValidation from "components/HOCs/WithFormValidation"
import Loading from "components/Common/Loading"
import {
  DELETE_COMMISSIONSTRUCTURE,
  EDIT_COMMISSIONSTRUCTURE,
} from "pages/AgentCommissionMgt/permission"
import { TransactionTypes } from "enums/transaction-types"
import { Utils } from "helpers/utility"
import ClusterButton from "components/Common/Button/Button"
import { Formik } from "formik"
import { validator } from "../Validators"

const EditModal = props => {
  const {
    open,
    dataSet,
    structure,
    onDelete,
    deleteLoading,
    onChangeModal,
    editLoading,
    UpdateErrorList,
    isBadge,
    badgeClass,
    cashOutInstitutions,
    status,
    title,
    handleValidSubmit,
  } = props
  const { MapCashOutInstitutionToDropdown } = Logic
  const [isOpen, setOpen] = useState(open)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(dataSet)
  const [transactionType, setTransactionType] = useState(structure?.TransactionType)

  const [feeType, setFeeType] = useState(structure.FeeType)
  const [commissionType, setCommissionType] = useState(structure.CommissionType)
  const changeOpen = status => {
    setOpen(status)
    onChangeModal(status)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])
  useEffect(() => {
    if (dataSet && dataSet.length > 0) {
      setTimeout(() => setLoading(false), 1000)
    }
    setData(dataSet)
  }, [dataSet])
  useEffect(() => {
    if (structure) {
      setFeeType(FEETYPE.find(f => f.value == structure?.FeeType)?.value)
      setCommissionType(
        COMMISSIONTYPE.find(f => f.value == structure?.CommissionType)?.value
      )
    }
    if (UpdateErrorList) UpdateErrorList(structure)
  }, [structure])

  const initiate = () => {
    return {
      Name: structure?.Name,
      TransactionType: structure?.TransactionType,
      IssuingCashOutInstitutionID: structure.IssuingCashOutInstitution?.ID,
      Bearer: structure?.Bearer,
      Applicability: structure?.Applicability,
      PaymentMode: structure?.PaymentMode,
      CommissionType: structure?.CommissionType,
      FeeType: structure?.FeeType,
      Percentage: structure?.Percentage,
      MinimumFee: structure?.MinimumFee,
      MaximumFee: structure?.MaximumFee,
      MaximumServiceProviderFee: structure?.MaximumServiceProviderFee,
      FlatAmount: structure?.FlatAmount,
      MinimumAmount: structure?.MinimumAmount,
      MaximumAmount: structure?.MaximumAmount,
      BankShare: structure?.BankShare,
      AgentShare: structure?.AgentShare,
      ANMShare: structure?.ANMShare,
      ServiceProviderShare: structure?.ServiceProviderShare,
      VAT: structure?.VAT,
      BankIncomeAccount: structure?.BankIncomeAccount,
      BankExpenseAccount: structure?.BankExpenseAccount,
      BankSuspenseAccount: structure?.BankSuspenseAccount,
      VatPayableAccount: structure?.VatPayableAccount
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      toggle={() => {
        changeOpen(!isOpen)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            changeOpen(!isOpen)
          }}
        >
          {/* Agent Details  */}
          {title}
          {dataSet && isBadge ? (
            <Badge
              style={{ marginLeft: "10px" }}
              className={"font-size-12 badge-soft-" + badgeClass}
              color={badgeClass}
              pill
            >
              {status}
            </Badge>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ModalHeader>
        {loading ? (
          <ModalBody>
            {" "}
            <Loading></Loading>
          </ModalBody>
        ) : (
          <React.Fragment>
            <ModalBody>
              <Formik
                initialValues={initiate()}
                onSubmit={(values) => {
                  handleValidSubmit({ ...values })
                }}
                validate={validator}
              >
                {({
                  values = { ...structure },
                  touched,
                  isValid,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  status,
                }) => (
                  <ClusterForm handleSubmit={handleSubmit}>
                    <Row>
                      <Col lg={12} md={12} sm={12}>
                        <Row>
                          <Col lg={12} md={12}>
                            <ClusterInput
                              defaultValue={values?.Name}
                              label={"Name"}
                              id={"name"}
                              name={"Name"}
                              onBlur={handleBlur}
                              validationError={errors?.Name}
                              type="text"
                              required={true}
                              onChange={handleChange}
                            ></ClusterInput>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Transaction Type</Label>
                              <ClusterDropdown
                                useValue
                                defaultValue={
                                  values?.TransactionType
                                }
                                name="TransactionType"
                                placeholder={"- Select -"}
                                items={Utils.Enums.toOptions(TransactionTypes)}
                                onChange={(name, value) => {
                                  setFieldValue("TransactionType", TransactionTypes[value])
                                  setTransactionType(value);
                                }}
                                validationError={errors?.TransactionType}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>
                          {values?.TransactionType == "POSAgentCashOut" ? <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Issuer</Label>
                              <ClusterDropdown
                                useValue
                                defaultValue={
                                  cashOutInstitutions.find(inst => inst.ID == structure.IssuingCashOutInstitution?.ID)?.BankCode
                                }

                                name="IssuingCashOutInstitutionID"
                                placeholder={"- Select -"}
                                items={MapCashOutInstitutionToDropdown(cashOutInstitutions)}
                                onChange={(name, value) => {
                                  setFieldValue("IssuingCashOutInstitutionID", value)
                                }}
                                validationError={errors?.IssuingCashOutInstitutionID}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col> : null}
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Cost Bearer</Label>
                              <ClusterDropdown
                                useValue
                                defaultValue={values?.Bearer}
                                placeholder={"- Select -"}
                                items={COSTBEARER}
                                name="Bearer"
                                onChange={(name, value) => {
                                  setFieldValue("Bearer", value)
                                }}
                                validationError={errors?.Bearer}
                                required={true}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>
                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Applicability</Label>
                              <ClusterDropdown
                                useValue
                                name="Applicability"
                                defaultValue={
                                  values?.Applicability
                                }
                                placeholder={"- Select -"}
                                items={APPLICABILITY}
                                onChange={(name, value) => {
                                  setFieldValue("Applicability", value);
                                }}
                                validationError={errors?.Applicability}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>

                          <Col lg={12} md={12}>
                            <FormGroup>
                              <Label>Payment Mode</Label>
                              <ClusterDropdown
                                useValue
                                name="PaymentMode"
                                defaultValue={
                                  values?.PaymentMode
                                }
                                placeholder={"- Select -"}
                                items={PAYMENTMODE}
                                validationError={errors?.PaymentMode}
                                onChange={(name, value) => {
                                  setFieldValue("PaymentMode", value)
                                }}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <Row>
                          <Col lg={12} md={12}>
                            <p>
                              <b>Fees</b>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormGroup>
                              <Label>Commission Type</Label>
                              <ClusterDropdown
                                useValue
                                defaultValue={
                                  values?.CommissionType}
                                name="CommissionType"
                                items={COMMISSIONTYPE}
                                onChange={(name, value) => {
                                  setFieldValue(name, value)
                                  setCommissionType(value)
                                  setFieldValue("MaximumAmount", 0)
                                  setFieldValue("MinimumAmount", 0)
                                  handleChange(name)
                                }}
                                validationError={errors?.CommissionType}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>
                          <Col lg={6} md={6}>
                            <FormGroup>
                              <Label>Fee Type</Label>
                              <ClusterDropdown
                                useValue
                                defaultValue={values?.FeeType}
                                items={FEETYPE}
                                name="FeeType"
                                value={values?.FeeType}
                                onChange={(name, value) => {
                                  setFieldValue(name, value)
                                  setFeeType(value)
                                }}
                                validationError={errors?.FeeType}
                              ></ClusterDropdown>
                            </FormGroup>
                          </Col>

                          {values?.FeeType == "Percentage" ? (
                            <React.Fragment>
                              <Col lg={12} md={12}>
                                <ClusterInput
                                  defaultValue={values?.Percentage ?? ""}
                                  label={"Percentage"}
                                  id={"percentage"}
                                  name={"Percentage"}
                                  validators={[Validators.percentage()]}
                                  required={true}
                                  type="number"
                                  onChange={(name, value) => {
                                    setFieldValue("Percentage", value)
                                    setFieldValue('FlatAmount', 0)
                                    handleChange(name)
                                  }}
                                  onBlur={handleBlur}
                                  value={values?.Percentage}
                                  validationError={errors?.Percentage}

                                ></ClusterInput>
                              </Col>

                              <Col lg={6} md={6}>
                                <ClusterInput
                                  defaultValue={values?.MinimumFee ?? ""}
                                  label={"Minimum Fee"}
                                  id={"minimumFee"}
                                  name={"MinimumFee"}
                                  type="number"
                                  onChange={(name, value) => {
                                    handleChange(name)
                                    if (value == '') {
                                      setFieldValue(name, 0)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  validationError={errors?.MinimumFee}
                                ></ClusterInput>
                              </Col>
                              <Col lg={6} md={6}>
                                <ClusterInput
                                  defaultValue={values?.MaximumFee ?? ""}
                                  label={"Maximum Fee"}
                                  id={"maximumFee"}
                                  name={"MaximumFee"}
                                  type="number"
                                  onChange={(name, value) => {
                                    handleChange(name)
                                    if (value == '') {
                                      setFieldValue(name, 0)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  validationError={errors?.MaximumFee}
                                ></ClusterInput>
                              </Col>
                              <Col lg={6} md={6}>
                                <ClusterInput
                                  defaultValue={values?.MaximumServiceProviderFee ?? ""}
                                  label={"Maximum Service Provider Fee"}
                                  id={"maximumServiceProviderFee"}
                                  name={"MaximumServiceProviderFee"}
                                  type="number"
                                  onChange={(name, value) => {
                                    handleChange(name)
                                    if (value == '') {
                                      setFieldValue(name, 0)
                                    }
                                  }}
                                  onBlur={handleBlur}
                                  validationError={errors?.MaximumServiceProviderFee}
                                ></ClusterInput>
                              </Col>
                            </React.Fragment>
                          ) : (
                            <Col lg={12} md={12}>
                              <ClusterInput
                                defaultValue={values?.FlatAmount}
                                label={"Flat Amount"}
                                id={"flatAmount"}
                                name={"FlatAmount"}
                                type="number"
                                onChange={(name, value) => {
                                  setFieldValue("FlatAmount", value)
                                  setFieldValue("Percentage", 0)
                                  setFieldValue("MinimumFee", 0)
                                  setFieldValue("MaximumFee", 0)
                                  handleChange(name)
                                }}
                                onBlur={handleBlur}
                                validationError={errors?.FlatAmount}
                              ></ClusterInput>
                            </Col>
                          )}
                          {values?.CommissionType == "Range" ? (
                            <React.Fragment>
                              <Col lg={6} md={6}>
                                <ClusterInput
                                  defaultValue={values?.MinimumAmount ?? ""}
                                  label={"Minimum Amount"}
                                  id={"minimumAmount"}
                                  name={"MinimumAmount"}
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  validationError={errors?.MinimumAmount}
                                ></ClusterInput>
                              </Col>

                              <Col lg={6} md={6}>
                                <ClusterInput
                                  defaultValue={values?.MaximumAmount ?? ""}
                                  label={"Maximum Amount"}
                                  id={"maximumAmount"}
                                  name={"MaximumAmount"}
                                  type="number"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  validationError={errors?.MaximumAmount}
                                ></ClusterInput>
                              </Col>
                            </React.Fragment>
                          ) : (
                            <React.Fragment></React.Fragment>
                          )}
                        </Row>
                      </Col>

                      <Col lg={12} md={6} sm={12}>
                        <Row>
                          <Col lg={12} md={12}>
                            <p>
                              <b>Shares</b>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={12}>
                            <ClusterInput
                              defaultValue={values?.BankShare ?? 0}
                              label={
                                "Bank Share(" +
                                (values?.FeeType == "Percentage" ? "%" : "₦") +
                                ")"
                              }
                              id={"bankShare"}
                              name={"BankShare"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.BankShare}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={12}>
                            <ClusterInput
                              defaultValue={values?.AgentShare ?? 0}
                              label={
                                "Agent Share(" +
                                (values?.FeeType == "Percentage" ? "%" : "₦") +
                                ")"
                              }
                              id={"agentShare"}
                              name={"AgentShare"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.AgentShare}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={12}>
                            <ClusterInput
                              defaultValue={values?.ANMShare ?? 0}
                              label={
                                "ANM Share(" +
                                (values?.FeeType == "Percentage" ? "%" : "₦") +
                                ")"
                              }
                              id={"anmShare"}
                              name={"ANMShare"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.ANMShare}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={12}>
                            <ClusterInput
                              defaultValue={values?.ServiceProviderShare ?? 0}
                              label={
                                "Service Provider Share(" +
                                (values?.FeeType == "Percentage" ? "%" : "₦") +
                                ")"
                              }
                              id={"serviceProviderShare"}
                              name={"ServiceProviderShare"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.ServiceProviderShare}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={12}>
                            <ClusterInput
                              defaultValue={values?.VAT ?? 0}
                              label={"VAT (%)"}
                              id={"vat"}
                              name={"VAT"}
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.VAT}
                            ></ClusterInput>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={12} md={6} sm={12}>
                        <Row>
                          <Col lg={12} md={12}>
                            <p>
                              <b>Account Info</b>
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={values?.BankIncomeAccount ?? ""}
                              label={"Bank Income Account"}
                              id={"BankIncomeAccount"}
                              name={"BankIncomeAccount"}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.BankIncomeAccount}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={values?.BankExpenseAccount ?? ""}
                              label={"Bank Expense Account"}
                              id={"BankExpenseAccount"}
                              name={"BankExpenseAccount"}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.BankExpenseAccount}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={values?.BankSuspenseAccount ?? ""}
                              label={"Bank Suspense Account"}
                              id={"BankSuspenseAccount"}
                              name={"BankSuspenseAccount"}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.BankSuspenseAccount}
                            ></ClusterInput>
                          </Col>
                          <Col lg={6} md={6}>
                            <ClusterInput
                              defaultValue={values?.VatPayableAccount ?? ""}
                              label={"VAT Account"}
                              id={"vatAccount"}
                              name={"VatPayableAccount"}
                              type="text"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              validationError={errors?.VatPayableAccount}
                            ></ClusterInput>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row style={{ paddingBottom: "0px" }}>
                      <Col
                        lg={12}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <ClusterButton style={{ marginRight: "10px", marginBottom: "4px", marginLeft: "-20px" }}
                          permissions={[EDIT_COMMISSIONSTRUCTURE]}
                          loading={editLoading}
                          disabled={!touched
                            ? true
                            : !isValid}
                          color={"primary"}
                          addon={"bx bx-save"}
                        >
                          Update
                        </ClusterButton>
                      </Col>
                    </Row>

                  </ClusterForm>)}
              </Formik>
            </ModalBody>
            <ModalFooter style={{ position: "absolute", bottom: "5px", width: "fit-content", marginLeft: "180px" }}>
              {props.children}
              <Row style={{ paddingBottom: "0px" }}>
                <Col
                  lg={12}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <ClusterButton
                    permissions={[DELETE_COMMISSIONSTRUCTURE]}
                    loading={deleteLoading}
                    onHandleClick={e => {
                      e.preventDefault()
                      onDelete(structure)
                      changeOpen(!isOpen)
                    }}
                    color={"danger"}
                    addon={"bx bx-save"}
                  >
                    Delete
                  </ClusterButton>
                </Col>
              </Row>
            </ModalFooter>
          </React.Fragment>
        )}
      </div>
    </Modal>
  )

}

EditModal.propTypes = {
  open: PropTypes.bool,
  dataSet: PropTypes.array,
  onChangeModal: PropTypes.func,
  onDelete: PropTypes.func,
  badgeClass: PropTypes.string,
  status: PropTypes.string,
  success: PropTypes.string,
  isBadge: PropTypes.bool,
  append: PropTypes.array,
  deleteLoading: PropTypes.bool,
}

export default withFormValidation(EditModal)
