import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { reportPurge, getCashOutReports } from "store/actions"
import {
  GET_ALL_CASHOUTREPORT,
  GET_ALL_CLUSTER_MANAGERS
} from "helpers/url_helper"

import CashoutLogic from "./cashout"
import withDispose from "components/HOCs/WithDispose"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"

const CashOutList = props => {
  const {
    cashOutTransactions,
    onGetTransactions,
    error,
    totalCount,
    loading,
  } = props

  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions
  } = CashoutLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
    } = useReportData({ onGetData: onGetTransactions, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period: 1, periodicity: Periodicity.Day })

  const { institutionsData, institutions } = useInstitutionsData()

  const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })

  const clusterManagerData = useMemo(()=>{
    return (clusterManagers?.data as any)?.Data ??[]
  },[clusterManagers.data])

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, (clusterManagers?.data as any)?.Data, institutionsData)
  )
  useEffect(() => {
    setSearchOptions(
      SearchOptions(
        params?.fromDate,
        params?.toDate,
        (clusterManagers?.data as any)?.Data,
        institutionsData
      )
    )
  }, [clusterManagers, institutions])

  useEffect(() => {
    populateRows()
  }, [cashOutTransactions])

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])

  const populateRows = () => {
    let rws = []
    if (cashOutTransactions) {
      cashOutTransactions.forEach(transaction => {
        let row = MapToRows(transaction)

        rws.push(row)
      })
    }
    setRows(rws)
  }

  return (
          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_CASHOUTREPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
  )
}

CashOutList.propTypes = {
  cashOutTransactions: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetTransactions: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { cashOutTransactions, error, success, cashOutTotalCount, loading },
}) => {
  return {
    cashOutTransactions,
    error,
    success,
    totalCount: cashOutTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetTransactions: query => dispatch(getCashOutReports(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CashOutList))))
