import PropTypes from "prop-types"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label, CardTitle } from "reactstrap"
// images
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import OptionsList from "./list";
import withFormValidation from "components/HOCs/WithFormValidation"
import { TRANSACTIONTYPENAMES } from "helpers/variables"
import { Utils } from "helpers/utility"
import { TransactionTypes } from "enums/transaction-types"
const OptionsForm = props => {
  let {
    errorList,
    myError,
    isFormInvalid,
    editLoading,
    setErrorList,
    handleOnChange,
    handleOptionsSubmit,
  } = props


  const [feeDiscount, setFeeDiscount] = useState(0);
  const [maximumBenefitVolume, setMaximumBenefitVolume] = useState(0);
  const [maximumTransactionCount, setMaximumTransactionCount] = useState(0);
  const [transactionType, setTransactionType] = useState();

  const handleSubmit = (e) => {
    e.preventDefault()
    setFeeDiscount(0)
    setMaximumBenefitVolume(0)
    setMaximumTransactionCount(0)
    setTransactionType(undefined)
    handleOptionsSubmit(e)
  }
  return (
    <React.Fragment>

      <Card>

        <CardBody>
          <CardTitle>
            Plan Options
          </CardTitle>

          <Row>

            <Col lg={4} md={6}>
              <ClusterInput
                onError={err =>
                  setErrorList({ ...errorList, maximumTransactionCount: err })
                }
                defaultValue={maximumTransactionCount}
                label={"Maximum Transaction Count"}
                id={"maximumTransactionCount"}
                name={"MaximumTransactionCount"}

                type="number"
                required={true}
                onHandleChange={(key, value) => {
                  handleOnChange(key, value)
                  if (value) setErrorList({ ...errorList, maximumTransactionCount: "" })
                  else setErrorList({ ...errorList, maximumTransactionCount: undefined })
                }
                }
              ></ClusterInput>
            </Col>

            <Col lg={4} md={6}>
              <ClusterInput
                onError={err =>
                  setErrorList({ ...errorList, maximumBenefitVolume: err })
                }
                defaultValue={maximumBenefitVolume}
                label={"Maximum Benefit Volume"}
                id={"maximumBenefitVolume"}
                name={"MaximumBenefitVolume"}

                type="number"
                required={true}
                onHandleChange={(key, value) => {
                  handleOnChange(key, value)
                  if (value) setErrorList({ ...errorList, maximumBenefitVolume: "" })
                  else setErrorList({ ...errorList, maximumBenefitVolume: undefined })
                }
                }
              ></ClusterInput>
            </Col>

            <Col lg={4} md={6}>

              <FormGroup >
                <Label>Transaction Type</Label>
                <ClusterDropdown
                  defaultValue={transactionType}
                  placeholder={"-Select-"}
                  name={"TransactionType"}
                  items={Utils.Enums.toOptions(TransactionTypes).filter(t => t.value == 29)}
                  onChange={(name, value) => {
                    handleOnChange("TransactionType", TransactionTypes[value]);
                    setErrorList({ ...errorList, transactionType: '' })
                  }}
                />

              </FormGroup></Col>

          </Row>
          <Row style={{ paddingBottom: "0px" }}>
            <Col
              lg={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <ClusterButton
                className="btn-sm"
                loading={editLoading}
                disabled={isFormInvalid}
                color={"primary"}
                addon={"bx bx-save"}
                type={"button"}
                onHandleClick={e => handleSubmit(e)}
              >
                Add Option
              </ClusterButton>
            </Col>
          </Row>

          <Row>
            <OptionsList />
          </Row>
        </CardBody>
      </Card>

    </React.Fragment>
  )
}
OptionsForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  agentDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleOptionsSubmit: PropTypes.func,
}
export default withRouter(
  withFormValidation(OptionsForm)
)
