import {
  GET_CASELOGS,
  GET_CASELOG_DETAIL,
  GET_CASELOG_DETAIL_SUCCESS,
  GET_CASELOG_DETAIL_FAIL,
  GET_CASELOG_MESSAGES,
  GET_CASELOG_MESSAGES_SUCCESS,
  GET_CASELOG_MESSAGES_FAIL,
  GET_CASELOGS_SUCCESS,
  GET_CASELOGS_FAIL,
  GET_ALL_CASELOGS,
  CREATE_CASELOG,
  POST_CASELOG_SUCCESS,
  CASELOG_PURGE,
  CASELOG_PURGE_SUCCESS,
  POST_CASELOG_FAIL,
  SEND_CASEMESSAGE,
  SEND_CASEMESSAGE_SUCCESS,
  SEND_CASEMESSAGE_FAIL,
  RESOLVE_CASE,
  RESOLVE_CASE_FAIL,
  RESOLVE_CASE_SUCCESS,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAIL,
} from "./actionTypes"

export const getCaseLogs = params => ({
  type: GET_CASELOGS,
  params,
})
export const getAllCaseLogs = () => ({
  type: GET_ALL_CASELOGS,
})
export const getCaseLogsSuccess = caseLogs => ({
  type: GET_CASELOGS_SUCCESS,
  payload: caseLogs,
})
export const getCaseLogsFail = error => ({
  type: GET_CASELOGS_FAIL,
  payload: error,
})
export const getCaseLogDetail = id => ({
  type: GET_CASELOG_DETAIL,
  id,
})

export const getCaseLogDetailSuccess = caseLogs => ({
  type: GET_CASELOG_DETAIL_SUCCESS,
  payload: caseLogs,
})

export const getCaseLogDetailFail = error => ({
  type: GET_CASELOG_DETAIL_FAIL,
  payload: error,
})

export const getCaseLogMessages = id => ({
  type: GET_CASELOG_MESSAGES,
  id,
})

export const getCaseLogMessagesSuccess = caseLogs => ({
  type: GET_CASELOG_MESSAGES_SUCCESS,
  payload: caseLogs,
})

export const getCaseLogMessagesFail = error => ({
  type: GET_CASELOG_MESSAGES_FAIL,
  payload: error,
})

export const sendMessage = payload => ({
  type: SEND_CASEMESSAGE,
  payload,
})

export const sendMessageSuccess = caseLogs => ({
  type: SEND_CASEMESSAGE_SUCCESS,
  payload: caseLogs,
})

export const sendMessageFail = error => ({
  type: SEND_CASEMESSAGE_FAIL,
  payload: error,
})
export const markAsRead = payload => ({
  type: MARK_AS_READ,
  payload,
})

export const markAsReadSuccess = caseLogs => ({
  type: MARK_AS_READ_SUCCESS,
  payload: caseLogs,
})

export const markAsReadFail = error => ({
  type: MARK_AS_READ_FAIL,
  payload: error,
})
export const resolveCase = id => {
  return {
    type: RESOLVE_CASE,
    payload: { id },
  }
}

export const resolveCaseError = error => {
  return {
    type: RESOLVE_CASE_FAIL,
    payload: error,
  }
}
export const caseLogApiError = error => {
  return {
    type: POST_CASELOG_FAIL,
    payload: error,
  }
}
export const resolveCaseSuccess = payload => {
  return {
    type: RESOLVE_CASE_SUCCESS,
    payload,
  }
}

export const caseLogPurge = () => ({
  type: CASELOG_PURGE,
})

export const caseLogPurgeSuccess = () => ({
  type: CASELOG_PURGE_SUCCESS,
})

export const enableCaseLog = (id, enable) => {
  return {
    type: ENABLE_CASELOG,
    id,
    enable,
  }
}

export const enableCaseLogSuccess = caseLog => {
  return {
    type: ENABLE_CASELOG_SUCCESS,
    payload: caseLog,
  }
}

export const updateCaseLog = caseLog => {
  return {
    type: UPDATE_CASELOG,
    payload: { caseLog },
  }
}
export const updateCaseLogSuccess = caseLog => {
  return {
    type: UPDATE_CASELOG_SUCCESS,
    payload: caseLog,
  }
}
export const purgeCaseLogs = () => {
  return {
    type: PURGE_CASELOGS,
  }
}

export const purgeCaseLogSuccess = () => {
  return {
    type: PURGE_CASELOGS_SUCCESS,
  }
}
