import React from 'react'
import { Row, Col } from "reactstrap"

type DetailsRowItemProp = {
    LHTitle?: String, LHContent?: String, RHTitle?: String, RHContent?: String
}

function DetailsRowItem({ LHTitle, LHContent, RHTitle, RHContent } : DetailsRowItemProp) {
    const Status = {
        Successful: 'Successful',
        Pending: 'Pending',
        Failed: 'Failed'
    }

    const lHColor = LHContent == Status.Successful ? 'green' : LHContent == Status.Failed ? 'red' : LHContent == Status.Pending ? 'grey' : '#495057'
    const rHColor = RHContent == Status.Successful ? 'green' : RHContent == Status.Failed ? 'red' : RHContent == Status.Pending ? 'grey' : '#495057'
    return (
        <Row className="d-flex justify-content-end" style={{ lineHeight:'17.07px'}}>
            <Col className='d-block mr-5 text-left'>
                <h5 className='font-weight-bold'>{LHTitle}</h5>
                <p style={{ color: `${lHColor}`, fontWeight: '500' }}>{LHContent}</p>
            </Col>

            <Col className='justify-content-end d-block ml-5 text-right'>
                <h5 className='font-weight-bold text-end'>{RHTitle}</h5>
                <p style={{ color: `${rHColor}`, fontWeight:'500' }}>{RHContent}</p>
            </Col>
        </Row>

    )
}

export default DetailsRowItem