import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { getClusterDetail, getClusters, clusterPurge, enableCluster } from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import withDispose from "components/HOCs/WithDispose"
import ClusterLogic from "../cluster"
import { EDIT_CLUSTER, ENABLE_CLUSTERS } from "../permission"
import { GET_ALL_CLUSTERS, GET_ALL_CLUSTER_MANAGERS, GET_CLUSTERS } from "helpers/url_helper"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const ClusterList = props => {
  const {
    clusters,
    onGetClusters,
    error,
    success,
    onGetClusterDetail,
    clusterDetail,
    onPurge,
    totalCount,
    editLoading,
    statusChanged,
    loading,
    onEnableCluster
  } = props
  const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })
  const { HEADERS, MapToModalState, MapToRows, SearchCriteria, SearchOptions } = ClusterLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ onGetData: onGetClusters, HEADERS: HEADERS, SearchCriteria: SearchCriteria })
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const { institutionsData, institutions } = useInstitutionsData()
  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate,(clusterManagers?.data as any)?.Data, institutionsData))
  const [viewedCluster, setViewedManager] = useState<any>()
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, (clusterManagers?.data as any)?.Data, institutionsData))
  }, [ clusterManagers, institutions])

  useEffect(() => {
    populateRows()
  }, [clusters])

  useEffect(() => {
    if (clusterDetail && Object.keys(clusterDetail).length > 0) {
      setmodal(true)
      setViewedManager(clusterDetail)
      setModalData(MapToModalState(clusterDetail))
    }
  }, [clusterDetail])

  useEffect(() => {
    getIsEnabled()
  }, [statusChanged, clusterDetail])

  useEffect(() => {
    let query = SearchCriteria(params, maxSize);
    search(query)
  }, [statusChanged])

  useEffect(() => {

    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  const populateRows = () => {
    if (clusters) {
      let rws = []
      clusters.forEach(cluster => {
        let row = MapToRows(cluster);
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(cluster.ID)}
          >
            View Details
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function getIsEnabled() {
    let enabled = false
    if (clusterDetail?.IsActive || statusChanged == true) enabled = true
    setIsEnabled(enabled)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetClusterDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Cluster"} breadcrumbItem={"View Clusters"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{ url: GET_ALL_CLUSTERS, query: SearchCriteria(params, maxSize) }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={viewedCluster?.IsActive ? "success" : "secondary"}
          status={viewedCluster?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Cluster Details"}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_CLUSTERS]}
            type="button"
            outline
            onHandleClick={() => onEnableCluster(viewedCluster?.ID, !viewedCluster?.IsActive)}
            color="secondary"
          >
            {viewedCluster?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-cluster/" + viewedCluster?.ID}>
            <ClusterButton permissions={[EDIT_CLUSTER]} type="button" color="primary">
              Edit
            </ClusterButton>
          </Link>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

ClusterList.propTypes = {
  clusters: PropTypes.array,
  clusterDetail: PropTypes.object,
  onGetClusters: PropTypes.func,
  onGetClusterDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  clusters: { clusters, error, success, clusterDetail, totalCount, loading, statusChanged, editLoading },
}) => {
  // console.debug(clusters, error, clusterDetail, totalCount, loading)
  return {
    clusters: [...clusters],
    error,
    clusterDetail: clusterDetail,
    totalCount,
    success,
    loading, statusChanged, editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onGetClusters: query => dispatch(getClusters(query)),
  onGetClusterDetail: id => dispatch(getClusterDetail(id)),
  onPurge: () => dispatch(clusterPurge()),

  onEnableCluster: (id, enable) => dispatch(enableCluster(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ClusterList))))
