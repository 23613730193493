import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import { purgeTerminals, saveAssignedTerminals } from "store/actions"
import TerminalAssignmentServiceProviderList from "./list/index"
import AssignTerminal from "./create/index"
import withDispose from "components/HOCs/WithDispose"
import TerminalAssignmentServiceProviderLogic from "../terminal-assignment-serviceprovider"
import ClusterButton from "components/Common/Button/Button"

const TerminalAssignmentServiceProvider = props => {
  const {
    terminalsToModify,
    onSaveConfiguration,
    editLoading,
    saved,
    onPurge,
  } = props
  const { MapToDropDown } = TerminalAssignmentServiceProviderLogic

  useEffect(() => {
    if (saved) {
      window.location.reload(false)
    }
  }, [saved])
  const saveConfiguration = () => {
    const assignedTerminals = terminalsToModify.map(t => ({
      AffiliateID: t.Affiliate.ID,
      TerminalID: t.Terminal.ID,
    }))

    onSaveConfiguration(assignedTerminals)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <React.Fragment>
          <AssignTerminal></AssignTerminal>
          <TerminalAssignmentServiceProviderList></TerminalAssignmentServiceProviderList>
          <ClusterButton
            className={"btn-lg"}
            buttonStyle={{
              width: "100%",
              marginTop: "10px",
              justifyContent: "center",
              height: "4em",
            }}
            color={"success"}
            loading={editLoading}
            onHandleClick={saveConfiguration}
            disabled={
              terminalsToModify && terminalsToModify.length > 0 ? false : true
            }
          >
            Save
          </ClusterButton>
        </React.Fragment>
      </div>
    </React.Fragment>
  )
}

TerminalAssignmentServiceProvider.propTypes = {
  error: PropTypes.any,
  success: PropTypes.string,
  onSaveConfiguration: PropTypes.func,
  onPurge: PropTypes.func,
  terminalsToModify: PropTypes.array,
}

const mapStateToProps = ({
  terminals: {
    editLoading,
    assignedTerminals,
    terminalsToModify,
    error,
    success,
    saved,
  },
}) => {
  return {
    terminalsToModify,
    error,
    success,
    editLoading,
    saved,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(purgeTerminals()),
  onSaveConfiguration: payload => dispatch(saveAssignedTerminals(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(TerminalAssignmentServiceProvider))))
