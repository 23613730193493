import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { withTranslation } from "react-i18next"
import {
  subscriptionPlanPurge,
} from "store/actions"
import SubscriptionList from "./list/index"
import withDispose from "components/HOCs/WithDispose"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import { fetcher, get } from "helpers/api_helper"
import SubscriptionLogic from "./volume-covenant"
import useSWR from "swr"
import {
  Card,
  CardBody,
  Row,
  Col,
  Label,
  FormGroup,
  Container,
} from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import Loading from "components/Common/Loading"
import ClusterButton from "components/Common/Button/Button"
import Breadcrumbs from "components/Common/Breadcrumb"

const Subscription = props => {
  const { MapToDropDown } = SubscriptionLogic
  const [categoryId, setCategoryId] = useState()
  const [category, setCategory] = useState()
  const { data, error } = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const onHandleSubmit = e => {
    e.preventDefault()
    setCategory(categoryId)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!category ? (
          <React.Fragment>
            <Container fluid>
              <Breadcrumbs
                title={"Subscriptions"}
                breadcrumbItem={"Select an Agent Category"}
              />

              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={5} md={6} sm={12}>
                      <FormGroup>
                        <Label>Agent Categories</Label>
                        {data ? (
                          <ClusterDropdown
                            name={"CategoryID"}
                            placeholder={"- Select a category - "}
                            items={MapToDropDown((data as any)?.Data)}
                            onChange={(name, value) => {
                              setCategoryId(value)
                            }}
                          ></ClusterDropdown>
                        ) : (
                          <Loading></Loading>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ClusterButton
                        onHandleClick={onHandleSubmit}
                        color={"primary"}
                        disabled={categoryId ? false : true}
                      >
                        Next
                      </ClusterButton>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </React.Fragment>
        ) : (
          <React.Fragment>


            <SubscriptionList
              categoryName={(data as any)?.Data?.find(c => c.ID == categoryId)?.Name}
              categoryId={categoryId}
              setCategory={setCategory}
            ></SubscriptionList>

          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  )
}

Subscription.propTypes = {
  error: PropTypes.any,
  success: PropTypes.string,
  onPurge: PropTypes.func
}

const mapStateToProps = ({
  subscriptions: {
    editLoading,
    subscriptionPlans,
    error,
    success,
  },
}) => {
  return {
    error,
    success,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(subscriptionPlanPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(Subscription))))
