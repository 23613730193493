import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { Validators } from "helpers/validators"
import withDispose from "components/HOCs/WithDispose"
const ClusterTextArea: React.FC<any> = props => {
  let {
    required,
    append = false,
    value,
    autoComplete,
    focus,
    validators,
    placeholder,
    children,
    disabled,
    onError,
    defaultValue,
    type,
    btn,
    style,
    label,
    name,
    id,
    className,
    outline,
    color,
    addon,
    onHandleChange,
    onChange,
    validationError,
    onBlur
  } = props
  const [error, setError] = useState()
  const [inputValue, setInputValue] = useState(value ?? type === "number" ? 0 : "")

  const handleOnBlur = e => {
    if (onBlur) onBlur(e)
    debounceValidate(e, "On Blur", 0)
  }
  const handleOnChange = (name, e) => {
    debounceValidate(e, name)
    if (onHandleChange) onHandleChange(name, e.target.value)
    if (onChange) onChange(e)
    setInputValue(e.target.value)
  }

  const debounceValidate = (e, name, timeout = 700) => {
    if (validators) {
      setTimeout(() => {
        const err = validate(e)
        setError(err)
        if (onError) onError(err)
      }, timeout)
    }
  }
  const handleOnKeyUp = (name, e) => {
    debounceValidate(e, name)
  }
  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue)
    } else {
      setInputValue("")
    }
  }, [defaultValue])

  useEffect(() => {
    setInputValue(value)
  }, [value])

  useEffect(() => {
    setError(validationError)
  }, [validationError])

  useEffect(() => {
    if (defaultValue) {
      setInputValue(defaultValue)
    } else {
      setInputValue("")
    }
  }, [])

  const validate = e => {
    const msgs = []
    if (!validators) validators = []
    if (required) { validators.push(Validators.required("Please enter a value")) }
    else {
      if (!e.target.value || e.target.value == 0) {

        setError(undefined)
        return "";
      }
    }

    validators?.forEach(validator => {
      const msg = validator(e)
      msgs.push(msg)
      if (msg && !error) {
        setError(msg)
        if (onError) onError(msg)
      } else {
        if (!msg) {
          setError(undefined)
        }
      }
    })

    if (msgs.every(msg => msg == undefined)) {
      return ""
    } else {
      let msg = msgs.find(msg => msg != undefined)
      return msg
    }
  }

  return (
    <FormGroup>
      <InputGroup>
        {!append ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Label for={id}>{label}</Label> {children}
          </div>
        ) : (
          <InputGroupAddon addonType="append">
            <Label for={id}>{label}</Label>
          </InputGroupAddon>
        )}
        <textarea
          className="form-control mb-2 " //mr-sm-3
          id={id}
          value={inputValue}
          onChange={e => handleOnChange(name, e)}
          onKeyUp={e => handleOnKeyUp(name, e)}
          required={required}
          name={name}
          onMouseDown={e => handleOnKeyUp(name, e)}
          onBlur={e => handleOnBlur(e)}
          disabled={disabled}
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={focus}
        ></textarea>

        {error ? (
          <div className="invalid-feedback" style={{ display: "block" }}>
            {error}
          </div>
        ) : (
          <React.Fragment></React.Fragment>
        )}

      </InputGroup>
    </FormGroup>
  )
}

ClusterTextArea.propTypes = {
  defaultValue: PropTypes.any,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  addon: PropTypes.string,
  outline: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  focus: PropTypes.bool,
  validators: PropTypes.array,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  onHandleChange: PropTypes.func,
  btn: PropTypes.object,
  value: PropTypes.any,
  onError: PropTypes.func,
  autoComplete: PropTypes.string,
}

export default withDispose(ClusterTextArea)
