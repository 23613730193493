import { GetInstitution } from "helpers/authentication/institution"
import { SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"

const HEADERS = [
  { title: "Action", name: "action", isAction: true },
  { title: "Status", name: "status", isBadge: true, entityKeyName: "IsClosed" },
  { title: "Date Logged", name: "name", entityKeyName: "DateLogged" },
  {
    title: "Agent Phone Number",
    name: "phoneNumber",
    entityKeyName: "AgentPhoneNumber",
  },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Subject", name: "subject", entityKeyName: "Subject" },
  { title: "Product", name: "product", entityKeyName: "Product" },
  {
    title: "Case Category",
    name: "caseCategory",
    entityKeyName: "CaseCategoryName",
  },
  {
    title: "Unread Feedbacks",
    name: "unreadFeedbacks",
    entityKeyName: "UnreadFeedbacks",
  },
]
const ERRORLIST = {
  name: undefined,
  code: undefined,
  phoneNumber: undefined,
  email: undefined,
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = SEARCHOPTIONS
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Case Reference",
      id: "s-caseReference",
      defaultValue: "",
      name: "caseReference",
    },
    {
      type: "text",
      label: "Case Reporter Email",
      id: "s-caseReporterEmail",
      defaultValue: "",
      name: "caseReporterEmail",
    },
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-phoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "s-isClosed",
      defaultValue: "- Select -",
      name: "isClosed",
      data: [
        { id: false, value: "Open", key: 0 },
        { id: true, value: "Closed", key: 1 },
      ],
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
const MapToRows = caseLog => {
  return {
    name: caseLog.DateLogged,
    phoneNumber: caseLog.AgentPhoneNumber,
    agentName: caseLog.AgentName,
    subject: caseLog.Subject,
    description: caseLog.Description,
    institution: caseLog.InstitutionName,
    caseReference: caseLog.CaseReference,
    product: caseLog.Product,
    caseReporterEmail: caseLog.CaseReporterEmail,
    status: {
      label: caseLog.IsClosed == 1 ? "Closed" : "Open",
      badgeClass: caseLog.IsClosed == 0 ? "warning" : "secondary",
    },
    caseCategory: caseLog.CaseCategoryName,
    unreadFeedbacks: caseLog.UnreadFeedbacks,
  }
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    CaseReporterEmail: parameters["caseReporterEmail"],
    AgentPhoneNumber: parameters["agentPhoneNumber"],
    CaseReference: parameters["caseReference"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    IsClosed: parameters["isClosed"],
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
    FromDate: moment(parameters["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(parameters["toDate"])
      .endOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
  })
}

const MapToModalState = clusterManagerDetail => {
  return [
    {
      title: "",
      items: [
        {
          id: "name",
          label: "Name",
          name: "name",
          value: clusterManagerDetail.Name,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: clusterManagerDetail.Code,
        },
        {
          id: "phoneNumber",
          label: "Phone Number",
          name: "phoneNumber",
          value: clusterManagerDetail.PhoneNumber,
        },
        {
          id: "email",
          label: "Email",
          name: "email",
          value: clusterManagerDetail.Email,
        },
      ],
    },
  ]
}

export default {
  HEADERS,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  ERRORLIST,
  MapToDropDown,
}
