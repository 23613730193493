
import { UserType } from "enums/user-type"
import { defaultObj } from "helpers/utility"
import {
  GET_FEATURE_DETAIL_SUCCESS,
  GET_FEATURE_DETAIL_FAIL,
  GET_FEATURES_SUCCESS,
  GET_FEATURES_FAIL,
  PURGE_FEATURES,
  ENABLE_FEATURE,
  ENABLE_FEATURE_SUCCESS,
  PURGE_FEATURES_SUCCESS,
  GET_FEATURES,
  POST_FEATURE_SUCCESS,
  UPDATE_FEATURE,
  CREATE_FEATURE,
  UPDATE_FEATURE_SUCCESS,
  POST_FEATURE_FAIL,
  GET_ALL_FEATURES,
  GET_FEATURES_BY_INSTITUTION,
  GET_INSTITUTION_FEATURES_SUCCESS

} from "./actionTypes"
export type Feature={
  ID:number,
  Code:string,
  Name: string,
  UserType:UserType
}
type FeatureState = {
  features: any[],
  institutionFeatures: any[],
  featureDetail: any,
  error: any,
  success: any,
  saved: boolean,
  totalCount: number,
  loading: boolean,
  editLoading: boolean,
  statusChanged: any
}
const INIT_STATE: FeatureState = {
  features: [],
  institutionFeatures: [],
  featureDetail: {},
  error: {},
  success: undefined,
  saved: false,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined
}

const Features = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEATURES_SUCCESS:
      return {
        ...state,
        features: (action.payload?.Data?.Collection || action.payload?.Data || []).filter(feature => feature.IsActive),
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_INSTITUTION_FEATURES_SUCCESS:
      return {
        ...state,
        institutionFeatures: (action.payload?.Data?.Collection || action.payload?.Data || []).filter(feature => feature.IsActive),
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_FEATURES:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_ALL_FEATURES:
      return {
        ...state,
        loading: true,
      }
    case GET_FEATURES_BY_INSTITUTION:
      return {
        ...state,
        loading: true,
      }
    case GET_FEATURES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_FEATURE_DETAIL_SUCCESS:
      return {
        ...state,
        featureDetail: action.payload?.Data,
        loading: false,
      }

    case GET_FEATURE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_FEATURE_SUCCESS:
      return {
        ...state,
        featureDetail: defaultObj(state.featureDetail),
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }
    case UPDATE_FEATURE:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }
      break
    case CREATE_FEATURE:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case POST_FEATURE_FAIL:
      state = { ...state, error: action.payload, success: undefined, loading: false, editLoading: false }
      break


    case UPDATE_FEATURE_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: ""
      }
      break

    case ENABLE_FEATURE:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_FEATURE_SUCCESS:
      let detail = state.featureDetail;
      detail.IsActive = action.payload?.enable
      return {
        ...state,
        editLoading: false,
        loading: false,
        featureDetail: detail,
        error: "",
        statusChanged: action.payload?.enable,
        success: action.payload?.Message
      }

    case PURGE_FEATURES_SUCCESS:

      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Features
