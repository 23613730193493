import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import { Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import ClusterButton from "components/Common/Button/Button"
import { Utils } from "helpers/utility"
import { Institution } from "pages/InstitutionManagement/institution"
import { Formik } from "formik"
import ClusterForm from "components/Common/Form"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import BillPaymentForm from "./billpayment-form"
import CashOutServiceForm from "./cashout-settlement"
import CommissionForm from "./commission-form"
import { CashOutInstitution } from "store/cashout-institution/reducer"
import { BillsPaymentProvider } from "enums/billspayment-provider"
import { Validators } from "helpers/validators"
import Loading from "components/Common/Loading"
import { ServiceType } from "enums/service-type"
import withFormValidation from "components/HOCs/WithFormValidation"
import PayWithTransferForm from "./paywithtransfer-form"
import { PayWithTransferMode } from "enums/pay-with-transfer-mode"
export type ConfigurationFormProps = any & {
  details: Institution
}
type CommissionConfiguration = {
  IsActive: boolean
}
type PayWithTransferConfiguration = {
  ProductCode: String,
  Mode : String,
  MaximumNumberOfAccounts : Number
}
type BillsPaymentConfiguration = {
  Provider: BillsPaymentProvider,
  ITEX: ITEXCredentials
}
type ITEXCredentials = {
  UseDefault: boolean
  Wallet: string,
  Identifier: string
  APIKey: string
  Username: string,
  Password: string,
  OrganisationCode: string,
  Pin: string
}
export type ServiceConfiguration = {
  Service?: ServiceType,
  BillsPayment: BillsPaymentConfiguration,
  Commissions: CommissionConfiguration,
  CashOutSettlement: CashOutInstitution
  PayWithTransfer: PayWithTransferConfiguration
}

let fieldErrors: any = {}
const ConfigurationForm: React.FC<ConfigurationFormProps> = props => {
  const {
    apiSuccess,
    success,
    features,
    isUpdate,
    institutionCode,
    history,
    goBack,
    editLoading,
    configurationLoading,
    details,
    handleValidSubmit,
    account
  } = props

  const [services, setServices] = useState<any[]>([]);
  const [configuration, setConfiguration] = useState(details)
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(Utils.Object.isEmpty(details?.CashOutSettlement) ? "/routing-configuration" : "")

  const payWithTransferValidator = (values: PayWithTransferConfiguration) => {
    const errors: any = {}
    const required = Validators.required("Please enter a value")

    const productCode = () => {
      [required].forEach(validator => {
        let error = validator(values.ProductCode)
        if (error) errors.ProductCode = error
      })
    }
    const mode = () => {
      [required].forEach(validator => {
        let error = validator(values.Mode)
        if (error) errors.Mode = error
      })
    }
    const maxNumberOfAccounts = () => {
      [Validators.range(1,10)].forEach(validator => {
        let error = validator(values.MaximumNumberOfAccounts)
        if (error) errors.MaximumNumberOfAccounts = error
      })
    }
    const validate = () => {
      try {
        if(values.ProductCode == PayWithTransferMode.Bank){
          productCode() 
        }
        maxNumberOfAccounts()
        mode()
        return errors
      } catch (err) {
      }
    }
    return validate()
  }

  const cashOutValidator = (values: CashOutInstitution) => {
    const errors: any = {}
    const required = Validators.required("Please enter a value");
    const walletRoutes = () => [required].forEach(
      validator => {
        let error = validator(values?.WalletTopUpSupportedRouteIDs)
        if (error) errors.WalletTopUpSupportedRouteIDs = error;
      });
    const maxRetries = () => [
      Validators.range(1, 20)
    ].forEach(validator => {
      let error = validator(values?.MaxRetryCount)
      if (error) errors.MaxRetryCount = error;
    });
    const validate = () => {
      try {
        maxRetries();
        walletRoutes();
        if (values.RID && (!values.SettlementAccountID || values.SettlementAccountID == 0)) {
          errors.SettlementAccountID = "Lead Settlement Account is required";
        }
        if (!values.RID && (values.SettlementAccountID && values.SettlementAccountID > 0)) {
          errors.RID = "Receiving Institution ID is required";
        }
        return errors;
      } catch (err) {
      }
    }
    return validate();
  }

  const validator = (values: ServiceConfiguration) => {
    const errors: any = {}

    const validate = () => {
      try {
        if (services.includes(ServiceType.CashOutSettlement) || values.Service == ServiceType.CashOutSettlement) {
          errors.CashOutSettlement = cashOutValidator(values.CashOutSettlement)
          if (Utils.Object.isEmpty(errors.CashOutSettlement)) delete (errors.CashOutSettlement)
        }

        if (services.includes(ServiceType.PayWithTransfer) || values.Service == ServiceType.PayWithTransfer) {
          errors.PayWithTransfer = payWithTransferValidator(values.PayWithTransfer)
          if (Utils.Object.isEmpty(errors.PayWithTransfer)) delete (errors.PayWithTransfer)
        }
        return errors;
      } catch (err) {
        console.log(err)
      }
    }
    return validate();
  }


  const Initiate = (details: ServiceConfiguration): ServiceConfiguration => {

    let currentServices = Object.assign([], services);
    if (!Utils.Object.isEmpty(details?.BillsPayment)) currentServices = Utils.List.tryAdd(currentServices, ServiceType.BillsPayment)
    if (!Utils.Object.isEmpty(details?.CashOutSettlement)) currentServices = Utils.List.tryAdd(currentServices, ServiceType.CashOutSettlement)
    if (!Utils.Object.isEmpty(details?.Commissions)) currentServices = Utils.List.tryAdd(currentServices, ServiceType.Commissions)
    if (!Utils.Object.isEmpty(details?.PayWithTransfer)) currentServices = Utils.List.tryAdd(currentServices, ServiceType.PayWithTransfer)

    if (services.length != currentServices.length) {
      setServices(currentServices);
    }
    return {
      BillsPayment: details?.BillsPayment,
      CashOutSettlement: { ...details?.CashOutSettlement, SettlementAccountID: details?.CashOutSettlement?.SettlementAccount?.ID ?? 0 },
      Commissions: details?.Commissions,
      PayWithTransfer: details?.PayWithTransfer
    };
  }
  useEffect(() => {
    setConfiguration(details)
  }, [details])

  useEffect(() => {
    if (!editLoading && isSubmitting) {
      if (redirectUrl && history && services.includes(ServiceType.CashOutSettlement)) {

        setTimeout(() => history.push(redirectUrl), 2000)
      } else {
        setTimeout(() => goBack(), 1000);
      }
      setIsSubmitting(false)
    }
  }, [editLoading])
  if (configurationLoading || (!configurationLoading && Utils.Object.isEmpty(configuration))) return <Loading>Loading configuration</Loading>
  return <React.Fragment>
    <Formik
      initialValues={Initiate(configuration)}
      onSubmit={(values, { setSubmitting }) => {
        setIsSubmitting(true)
        if (values.CashOutSettlement.SettlementAccountID > 0) {

        }
        handleValidSubmit({ ...values })

      }}
      validate={validator}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldError,
        handleChange,
        setErrors,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (<ClusterForm handleSubmit={handleSubmit}>

        <React.Fragment>
          <Card
            outline
            style={{ background: "transparent", borderColor: "#999999" }}
            className="border"
          >
            <CardBody>
              <Row>
                <Col lg={3} md={4} sm={12}>
                  <FormGroup>
                    <Label>Service</Label>

                    <ClusterDropdown
                      placeholder={"-Select a service-"}
                      items={Utils.Enums.toOptions(ServiceType).filter(type => type.value != ServiceType.Commissions)}
                      name={"ServiceType"}
                      onChange={(name, value) => {
                        setFieldValue("Service", value);
                      }}
                    ></ClusterDropdown>

                  </FormGroup>
                </Col>

                <Col lg={4} md={4} sm={6} className="mt-4">
                  <ClusterButton
                    color={"secondary"}
                    addon={"bx bx-plus"}
                    type={"button"}
                    className="btn-sm"
                    onHandleClick={() => {
                      setServices(Utils.List.tryAdd(services, values?.Service));
                    }}
                  >
                    Add
                  </ClusterButton>
                </Col>

              </Row>

            </CardBody>
          </Card>
        </React.Fragment>

        {services.map((service, i) => {
          switch (service) {
            case ServiceType.CashOutSettlement:
              return <div className="mb-4" key={i}>
                <CashOutServiceForm
                  {...props}
                  values={values.CashOutSettlement}
                  institutionCode={institutionCode}
                  handleChange={(name, value) => {
                    if (name) {

                      let cashOutSettlementConfig = Object.assign({}, values.CashOutSettlement);

                      const property = name.split(".")
                      if (property.length > 1) {
                        cashOutSettlementConfig[property[0]][property[1]] = value;
                      }
                      else cashOutSettlementConfig[name] = value;
                      if (!errors.CashOutSettlement) errors.CashOutSettlement = {};
                      setFieldValue("CashOutSettlement", cashOutSettlementConfig);
                    }
                  }}
                  errors={errors.CashOutSettlement}
                  handleBlur={handleBlur}
                /></div>
            case ServiceType.BillsPayment:
              return <div className="mb-4" key={i} >
                <BillPaymentForm {...props} values={values.BillsPayment} errors={errors.BillsPayment}
                  handleChange={
                    (name, value) => {
                      if (name) {
                        let billPaymentConfig = Object.assign({}, values.BillsPayment);

                        if (name == "Provider") {
                          if (value == undefined) delete (billPaymentConfig[billPaymentConfig["Provider"]])
                          else billPaymentConfig[value] = { ...values.BillsPayment[value] };
                        }

                        const property = name.split(".")
                        if (property.length > 1) {
                          if (!billPaymentConfig[property[0]]) billPaymentConfig[property[0]] = {}
                          billPaymentConfig[property[0]][property[1]] = value;
                        }
                        else billPaymentConfig[name] = value;

                        setFieldValue("BillsPayment", billPaymentConfig);
                      }
                    }
                  }
                />
              </div>
            case ServiceType.Commissions:
              return <div className="mb-4" key={i}>
                <CommissionForm

                  {...props}
                  values={values.Commissions}
                  handleChange={(name, value) => {
                    if (name) {
                      let commissionConfig = Object.assign({}, values.Commissions);

                      const property = name.split(".")
                      if (property.length > 1) {
                        commissionConfig[property[0]][property[1]] = value;
                      }
                      else commissionConfig[name] = value;
                      setFieldValue("Commission", commissionConfig);
                    }

                  }}
                  errors={errors.CashOutSettlement}

                /></div>

            case ServiceType.PayWithTransfer:
              return <div className="mb-4" key={i}>
                <PayWithTransferForm
                  {...props}
                  values={values.PayWithTransfer}
                  handleChange={(name, value) => {
                    if (name) {
                      let payWithTransferConfig = Object.assign({}, values.PayWithTransfer)
                      const property = name.split(".")
                      if (property.length > 1) {
                        payWithTransferConfig[property[0]][property[1]] = value;
                      }
                      else payWithTransferConfig[name] = value
                         if(payWithTransferConfig["Mode"] == PayWithTransferMode.Virtual){
                          payWithTransferConfig["ProductCode"] = ''
                               }
                      setFieldValue("PayWithTransfer", payWithTransferConfig)
                    }
                  }}
                  errors={errors.PayWithTransfer}
                />
              </div>
            default: return null;
          }
        })}

        <Col lg={2} md={4} sm={6}>

          <ClusterButton
            color={"primary"}
            buttonStyle={{
              width: "100%",
              marginTop: "10px",
              height: "3em",
            }}
            addon={"bx bx-save"}
            className={"btn-lg"}
            loading={editLoading}
            disabled={editLoading ? false : !Utils.List.isEmpty(services) && isValid ? false : true}
          >
            Save
          </ClusterButton>

        </Col>



      </ClusterForm>)}

    </Formik>
  </React.Fragment>

}
ConfigurationForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  features: PropTypes.array,
  institutions: PropTypes.array,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(ConfigurationForm))


