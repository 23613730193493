import { IsDevelopment } from "helpers/authentication/viewer"
import { Utils } from "helpers/utility"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import { getFunctionsByUser, getMenu } from "store/actions"

export function withGuard<T>(BaseComponent: React.ComponentType<T>, enabledText:string, disabledText:string) {
  return (props) => {
    const {
      permissions,
      onGetFunctions,
      functions,
      menus,
      onGetMenus,
      dispatch,
      rename,
      children,
      disabled=false,
      ...otherProps
    } = props

    const [actions, setActions] = useState([])

    useEffect(() => {
      onGetFunctions()
      onGetMenus()
    }, [])


    //Only useful for guarded pages to differentiate between create and edit rights
    const getActions = () => {
      let actions = [];
      //Check the actions that are allowed on the page
      if (permissions) {
        permissions.forEach(p => {

          if ([...functions, ...menus].find(f => {
            return f.children.find(c => p == c.roleName)
          })) {
            if (p.toLowerCase().includes("edit")) actions.push("EDIT")
            if (p.toLowerCase().includes("create")) actions.push("CREATE")
          }
        })
      }
      setActions(actions);
    }

    const {elementRename,guarded} = useMemo(() => {
      let r=rename;
      if (!Utils.List.isEmpty(permissions)) {
        let exists = [...functions, ...menus].some(f => {
          return f.children.some(c => permissions.includes(c.roleName))
        });
        if (!exists && rename) {
          r= rename;
        } else {
          r=undefined;
        }
        getActions();
        return {guarded:!exists,elementRename:r}
      }
     return {guarded:false,elementRename:r}
    }, [functions, menus,permissions])


   
    const guardedTitle =
      "You do not have rights to this action. Kindly contact your administrator";

  const {inputDisabled,title} = useMemo(() => {

      if (Utils.Boolean.Convert(guarded)===true) {

        return {inputDisabled:Utils.Boolean.Convert(guarded),title:guardedTitle}
      }
      const isDisabled = Utils.Boolean.Convert(disabled);

      return {inputDisabled:isDisabled, title: isDisabled===true?disabledText:enabledText};
    }, [guarded, disabled])

    return (
      <BaseComponent {...otherProps} rename={elementRename} title = {title} disabled={inputDisabled} actions={actions}>
        {children}
      </BaseComponent>
    )
  }
}

withGuard.PropTypes = {
  permissions: PropTypes.array,
  functions: PropTypes.array,
  menus: PropTypes.array,
  rename: PropTypes.string,
  disabled:PropTypes.bool
}
const mapStateToProps = ({ permissions: { functionsForUser, menus } }) => {
  return { functions: functionsForUser, menus }
}
const mapDispatchToProps = dispatch => ({
  onGetFunctions: () => dispatch(getFunctionsByUser()),
  onGetMenus: () => dispatch(getMenu()),
})

const composedWithGuard: any = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGuard
)

export default composedWithGuard
