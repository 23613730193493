import {
  GET_CATEGORIES,
  GET_CATEGORY_DETAIL,
  GET_CATEGORY_DETAIL_SUCCESS,
  GET_CATEGORY_DETAIL_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_FAIL,
  CREATE_CATEGORY,
  POST_CATEGORY_SUCCESS,
  CATEGORY_PURGE,
  CATEGORY_PURGE_SUCCESS,
  POST_CATEGORY_FAIL,
  UPDATE_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  GET_ALL_CATEGORIES,
  ENABLE_CATEGORY,
  ENABLE_CATEGORY_SUCCESS,
} from "./actionTypes"

export const getCategories = params => ({
  type: GET_CATEGORIES,
  params,
})

export const getAllCategories = () => ({
  type: GET_ALL_CATEGORIES,
})
export const getCategoriesSuccess = categories => ({
  type: GET_CATEGORIES_SUCCESS,
  payload: categories,
})
export const getCategoriesFail = error => ({
  type: GET_CATEGORIES_FAIL,
  payload: error,
})
export const getCategoryDetail = id => ({
  type: GET_CATEGORY_DETAIL,
  id,
})

export const getCategoryDetailSuccess = categories => ({
  type: GET_CATEGORY_DETAIL_SUCCESS,
  payload: categories,
})

export const getCategoryDetailFail = error => ({
  type: GET_CATEGORY_DETAIL_FAIL,
  payload: error,
})

export const createCategory = category => {
  return {
    type: CREATE_CATEGORY,
    payload: { category },
  }
}

export const categoryApiError = error => {
  return {
    type: POST_CATEGORY_FAIL,
    payload: error,
  }
}

export const postCategorySuccess = category => {
  return {
    type: POST_CATEGORY_SUCCESS,
    payload: category,
  }
}

export const categoryPurge = () => ({
  type: CATEGORY_PURGE,
})

export const categoryPurgeSuccess = () => ({
  type: CATEGORY_PURGE_SUCCESS,
})

export const enableCategory = (id, enable) => {
  return {
    type: ENABLE_CATEGORY,
    id,
    enable,
  }
}

export const enableCategorySuccess = category => {
  return {
    type: ENABLE_CATEGORY_SUCCESS,
    payload: category,
  }
}

export const updateCategory = category => {
  return {
    type: UPDATE_CATEGORY,
    payload: { category },
  }
}
export const updateCategorySuccess = category => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: category,
  }
}
export const purgeCategories = () => {
  return {
    type: PURGE_CATEGORIES,
  }
}

export const purgeCategorySuccess = () => {
  return {
    type: PURGE_CATEGORIES_SUCCESS,
  }
}
