export const GET_ROLES = "GET_ROLES"
export const CREATE_ROLE = "CREATE_ROLE"
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const POST_SUCCESS = "POST_SUCCESS"
export const API_ERROR = "ROLES_API_ERROR"
export const GET_ROLE_DETAILS = "GET_ROLE_DETAILS"
export const GET_ROLE_DETAILS_SUCCESS = "GET_ROLE_DETAILS_SUCCESS"
export const GET_ROLE_DETAILS_FAIL = "GET_ROLE_DETAILS_FAIL"

export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE"
export const UPDATE_USER_ROLE_SUCCESS = "UPDATE_USER_ROLE_SUCCESS"
export const UPDATE_USER_ROLE_FAIL = "UPDATE_USER_ROLE_FAIL"

export const PURGE_USER_ROLES = "PURGE_USER_ROLES"
export const PURGE_USER_ROLES_SUCCESS = "PURGE_USER_ROLES_SUCCESS"

export const ENABLE_USER_ROLE = "ENABLE_USER_ROLE"
export const ENABLE_USER_ROLE_SUCCESS = "ENABLE_USER_ROLE_SUCCESS"
export const ENABLE_USER_ROLE_FAIL = "ENABLE_USER_ROLE_FAIL"
export const GET_ALL_ROLES="GET_ALL_ROLES"
