import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert
} from "reactstrap"
import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import Loading from 'components/Common/Loading';
import { Validators } from 'helpers/validators';
import ClusterForm from 'components/Common/Form';
import AccountLogic from '../settlement';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { Formik } from 'formik';
import useSWR from 'swr';
import { ACCOUNT_ENQUIRY } from 'helpers/url_helper';
import { fetcher } from 'helpers/api_helper';
import { Utils } from 'helpers/utility';
import { SettlementAccountType } from 'enums/settlement-account-type';
const SettlementAccountForm = props => {
  let { apiSuccess, apiError, details, commercialBanks, type, errorList, myError, isUpdate, editLoading, setErrorList, handleOnChange, handleValidSubmit } = props;
  let { MapBanksToDropDown } = AccountLogic;
  const [bankCode, setBankCode] = useState<string>();
  const [isAccountNameValid, setIsAccountNameValid] = useState<boolean>(false);
  const [accountNumber, setAccountNumber] = useState<string>();
  const accountName = useSWR(`${ACCOUNT_ENQUIRY}?bankCode=${bankCode}&accountNumber=${accountNumber}`, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })
  const [name, setAccountName] = useState<string>(undefined);
  const Initiate = (details) => {
    if (!Utils.Object.isEmpty(details)) {
      if (!bankCode) setBankCode(details?.BankCode)
      if (!accountNumber) setAccountNumber(details?.AccountNumber)
    }
    return {
      ID: details?.ID ?? 0,
      BankName: details?.BankName,
      BankID: commercialBanks?.find(a => a.Code == details?.BankCode)?.ID,
      BankCode: details?.BankCode,
      AccountName: details?.AccountName,
      AccountNumber: details?.AccountNumber,
      Type: type
    }
  }
  useEffect(() => {
    if (accountNumber && bankCode) {
      setAccountName(((accountName.data as any)?.Data));
      const required = Validators.required("Please enter a value");
      [required, Validators.minLength(3, "Minimum number of characters is 3")
      ].forEach(validator => {
        let error = validator(name)
        if (error) setIsAccountNameValid(false)
        else setIsAccountNameValid(true)
      });
    } else {
      setAccountName("")
    }
  }, [accountName])
  if (details == undefined && type == SettlementAccountType.Wallet) {
    return <Loading>Getting account</Loading>
  }
  if (commercialBanks == undefined) {
    return <Loading>Getting banks</Loading>
  }


  return <React.Fragment>
    {type == SettlementAccountType.Wallet ? !Utils.Object.isEmpty(details) ? <Alert color="warning">To change your settlement account, kindly contact support at creditclubsupport@appzonegroup.com</Alert> : <Alert color="info">You can only configure your account once, you would need to send a mail to change it</Alert> : null}
    <Formik
      initialValues={Initiate(details)}
      validate={(values) => {
        let errors: any = {}
        const required = Validators.required("Please enter a value");

        const accountNumber = () =>
          [
            required,
            Validators.accountNumber(["Please only enter numbers", "Accounts can only be 8 or 10 digits"]),
          ].forEach(validator => {
            let error = validator(values?.AccountNumber)
            if (error) errors.AccountNumber = error;
          });
        const accountName = () => [required, Validators.minLength(3, "Minimum number of characters is 3")
        ].forEach(validator => {
          let error = validator(name)
          if (error) errors.AccountName = error;
        });
        const bank = () => [required, Validators.min(1, "Please select a bank")
        ].forEach(validator => {
          let error = validator(values?.BankCode)
          if (error) errors.BankCode = error;
        });

        const validate = () => {
          try {
            accountNumber();
            bank();
            const shouldValidateAccountDetails = values.BankCode && values.AccountNumber && !errors.BankCode && !errors.AccountNumber;
            if (shouldValidateAccountDetails) {
              setBankCode(values.BankCode)
              setAccountNumber(values.AccountNumber)
            }
            return errors;
          } catch (err) {
            console.log(err)
          }
        }
        return validate();

      }}
      onSubmit={(values, { setSubmitting }) => {

        const bank = commercialBanks.find(a => a.Code == values?.BankCode);
        let account = { ...values, BankName: bank?.Name, AccountName: name };
        delete account["BankID"];
        handleValidSubmit({ ...values, BankName: bank?.Name, AccountName: name })


      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldError,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (<ClusterForm handleSubmit={handleSubmit}>

        <React.Fragment>


          <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
            <CardBody>
              <Row>

                <Col lg={4} md={6} sm={12}>

                  <ClusterInput
                    defaultValue={values?.AccountNumber}
                    focus
                    label={"Account Number"}
                    id={"accountNumber"}
                    name={"AccountNumber"}
                    type="text"
                    required={true}
                    disabled={!Utils.Object.isEmpty(details)}
                    value={values.AccountNumber}
                    validationError={errors.AccountNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <FormGroup>
                    <Label>Bank</Label>

                    <ClusterDropdown
                      id={"bankID"}
                      name={"BankCode"}
                      placeholder={"-Select-"}
                      disabled={!Utils.Object.isEmpty(details)}
                      defaultValue={commercialBanks?.find(a => a.Code == values?.BankCode)?.Name}
                      value={commercialBanks?.find(a => a.Code == values?.BankCode)?.Name}
                      items={MapBanksToDropDown(commercialBanks)}
                      onChange={(name, value) => {
                        setFieldValue("BankCode", value)
                      }}
                      validationError={errors.BankCode as any}
                    />

                  </FormGroup>
                </Col>

                {accountName.isValidating && accountNumber && bankCode ? <Loading /> :
                  name === undefined && Utils.Object.isEmpty(details) ?
                    <Col lg={4} md={6} sm={12}>
                      <FormGroup>
                        <Label>Account Name</Label>
                        <Alert color="warning" className="p-2"> Enter valid account details </Alert>
                      </FormGroup>
                    </Col> :
                    <Col lg={4} md={4} sm={12}>

                      <ClusterInput
                        defaultValue={name}
                        disabled={true}
                        label={"Account Name"}
                        id={"name"}
                        name={"AccountName"}
                        type="text"
                        value={name}
                        validationError={errors.AccountName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Col>}
              </Row>

            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={editLoading}
              disabled={!Utils.Object.isEmpty(details) ? true : (!touched ? true : (editLoading || (!isValid || !isAccountNameValid)))}
              color={"primary"}
              addon={"bx bx-plus"}
            >
              Save Account
            </ClusterButton>
          </Col>
        </Row>

      </ClusterForm>)}
    </Formik>
  </React.Fragment>

}
SettlementAccountForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(SettlementAccountForm)))