import { GetInstitution } from "helpers/authentication/institution";
import { BASESTATUS, SEARCHOPTIONS } from "helpers/variables";

const HEADERS = [
    { title: "Agent", name: "agent", entityKeyName: "AgentName" },
    { title: "Affiliate", name: "affiliate", entityKeyName: "Affiliate" },
    { title: "Terminal", name: "terminal", entityKeyName: "TerminalID" },
   
    { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
    agent: undefined,
    terminal:undefined,
   
}
const SearchOptions = (startDate, endDate, institutions) => {

    let options = Object.assign({}, SEARCHOPTIONS);
    options.range.enabled = false;
    options.fields = [
      {
        type: "text",
        label: "Terminal ID",
        id: "s-terminalID",
        defaultValue: "",
        name: "terminalID",
      },
      {
        type: "dropdown",
        label: "Status",
        id: "s-status",
        defaultValue: "- Select -",
        data: BASESTATUS,
        name: "status",
        width: 3,
      },
      {
        type: "text",
        label: "Agent Code",
        id: "s-agentCode",
        defaultValue: "",
        name: "agentCode",
      },
      {
        type: "text",
        label: "Serial Number",
        id: "s-serialNumber",
        defaultValue: "",
        name: "serialNumber",
      },
      {
        type: "text",
        label: "Agent Phone Number",
        id: "s-agentPhoneNumber",
        defaultValue: "",
        name: "agentPhoneNumber",
      },
      {
        type: "dropdown",
        label: "All Institutions",
        id: "institutionCode",
        defaultValue: "- Select -",
        data: MapToDropDown(institutions),
        name: "institutionCode",
        width: 2,
        isRemote: GetInstitution().code == "*",
      },
    ]
    return options
}
const MapToDropDown = (data) => {
    if (data instanceof Array) {
        return data.map(item => ({ id: item.ID, key: item.ID, value:!item.LastName? item.TerminalID: `${item.LastName},${item.OtherNames} (${item.PhoneNumber})` }))
    }
    return [];
}

const MapToRows = (terminal) => {
    return {
      id: terminal.Terminal?.TerminalID,
      institution: terminal?.Institution,
      agent: `${terminal.Agent?.LastName},${terminal.Agent?.OtherNames} (${terminal.Agent?.PhoneNumber})`,
      affiliate: terminal.Affiliate?.Name ?? GetInstitution().name,
      terminal: terminal.Terminal?.TerminalID,
    }


}
const SearchCriteria = (parameters, maxSize) => {
    return JSON.stringify({
      Name: parameters["name"],
      SerialNumber: parameters["serialNumber"],
      TerminalID: parameters["terminalID"],
      InstitutionCode:
        parameters["institutionCode"] != "*"
          ? parameters["institutionCode"]
          : "",
      AgentCode: parameters["agentCode"],
      Status: parameters["status"],
      AgentPhoneNumber: parameters["agentPhoneNumber"],
      StartIndex: (parameters["page"] - 1) * maxSize,
      MaxSize: maxSize,
    })

}

const MapToModalState = (terminalDetail) => {
    return [
        {
            title: "",
            items: [
                {
                    id: "name",
                    label: "Name",
                    name: "name",
                    value: terminalDetail.Name,
                },
                {
                    id: "manager",
                    label: "Manager",
                    name: "managerId",
                    value: terminalDetail.terminalManager?.Name,
                }
            ],
        }
    ]
}
const UpdateErrorList = () => {
    let list = {...ERRORLIST}
    for (let key in list) {
      list[key] = ""
    }
    return list
  }
export default {
    HEADERS,UpdateErrorList, SearchOptions, MapToRows, SearchCriteria, MapToModalState, ERRORLIST, MapToDropDown
}