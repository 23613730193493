import { Environment } from "common/env-config"
import { ClusterInstitutions } from "enums/institutions"
import { GetInstitution, Institution } from "./authentication/institution"

export class InstitutionsHelper {
  static Polaris = Environment.IsProduction ? "100330" : "100305"
  static PurpleMoney = Environment.IsProduction ? "100538" : "100609"
  static Access = Environment.IsProduction ? "100636" : "000000"
  static Sterling = Environment.IsProduction ? "100567" : "000000"
  static AccessCongo = Environment.IsProduction ? "100254" : "100287"
  static Halo = Environment.IsProduction ? "" : "100616"
  static Heritage = Environment.IsProduction ? "000001" : "100325"
  static Grooming = Environment.IsProduction ? "100261" : "100625"
  static Oche = Environment.IsProduction ? "000226" : ""
  static TCF = Environment.IsProduction ? "100568" : "100309"
  static RemoteHome = Environment.IsProduction ? "*" : "*"

  static Verify = (code: string, institution: ClusterInstitutions): boolean => {
    switch (institution) {
      case ClusterInstitutions.Access:
        return code === this.Access
      case ClusterInstitutions.Polaris:
        return code === this.Polaris
      case ClusterInstitutions.PurpleMoney:
        return code === this.PurpleMoney
      case ClusterInstitutions.Sterling:
        return code === this.Sterling
      case ClusterInstitutions.AccessCongo:
        return code === this.AccessCongo
      case ClusterInstitutions.Halo:
        return code === this.Halo
      case ClusterInstitutions.Heritage:
        return code === this.Heritage
      case ClusterInstitutions.Grooming:
        return code === this.Grooming
      case ClusterInstitutions.Oche:
        return code === this.Oche
      case ClusterInstitutions.TCF:
        return code === this.TCF
      case ClusterInstitutions.RemoteHome:
        return code == this.RemoteHome
      default:
        return false
    }
  }

  private static isConsolidatedCashOutInstitution = () => {
    return (
      InstitutionsHelper.Verify(
        Institution.Code,
        ClusterInstitutions.Polaris
      ) ||
      InstitutionsHelper.Verify(
        Institution.Code,
        ClusterInstitutions.AccessCongo
      ) ||
      InstitutionsHelper.Verify(Institution.Code, ClusterInstitutions.Access) ||
      InstitutionsHelper.Verify(
        Institution.Code,
        ClusterInstitutions.PurpleMoney
      )
    )
  }
  private static isExternal = () => {
    let institution = GetInstitution()
    return `${InstitutionsHelper.Polaris},${InstitutionsHelper.Heritage},${InstitutionsHelper.Grooming},${InstitutionsHelper.Halo}`.includes(
      institution.code
    )
  }

  static Validator: InstitutionValidation = {
    isTCF: InstitutionsHelper.Verify(Institution.Code, ClusterInstitutions.TCF),
    isAccessNg: InstitutionsHelper.Verify(
      Institution.Code,
      ClusterInstitutions.Access
    ),
    isSterling: InstitutionsHelper.Verify(
      Institution.Code,
      ClusterInstitutions.Sterling
    ),
    isPolaris: InstitutionsHelper.Verify(
      Institution.Code,
      ClusterInstitutions.Polaris
    ),
    isBankOne: !InstitutionsHelper.isConsolidatedCashOutInstitution(),
    isPurpleMoney: InstitutionsHelper.Verify(
      Institution.Code,
      ClusterInstitutions.PurpleMoney
    ),
    isAccessCongo: InstitutionsHelper.Verify(
      Institution.Code,
      ClusterInstitutions.AccessCongo
    ),
    isConsolidated: InstitutionsHelper.isConsolidatedCashOutInstitution(),
    isExternal: InstitutionsHelper.isExternal(),
  }
}

type InstitutionValidation = {
  isTCF: boolean
  isAccessNg: boolean
  isSterling: boolean
  isPolaris: boolean
  isBankOne: boolean
  isPurpleMoney: boolean
  isAccessCongo: boolean
  isConsolidated: boolean
  isExternal: boolean
}
