import React from "react"
import { Row, Col, Card } from "reactstrap"
const ClusterInformation = props => {
  const { image, title, description, onClick, id, className } = props
  return (
    <Card
      outline
      className={"border text-pink "+className}
      color="pink"
      style={{
        background: "transparent",
        width: "100%",
        padding: "20px",
        paddingBottom: "15px",
        cursor: "pointer"
      }}
      onClick={onClick}
      id={id}
    >
      <Row>
        {image && (
          <Col
            lg={2}
            md={2}
            xs={2}
            style={{ display: "flex", alignItems: "center" }}
          >
            <img src={image} style={{ height: "auto" }} alt={title}/>
          </Col>
        )}
        <Col lg={image?10:12} sm={image?10:12} xs={image?10:12}>
          <h5>{title}</h5>
          <p>{description}</p>
        </Col>
      </Row>
    </Card>
  )
}

export default ClusterInformation
