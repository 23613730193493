import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router-dom"
import { Row, Col, CardBody, Card } from "reactstrap"


const Instructions = props => {
  const {instructions,description } = props

  return (
    <Row className="instructions">
      <Col xs="12">
        <Card>
          <CardBody>
            <p><b>Read the instructions carefully</b></p>
            <p className="description">{description}</p>
           <div >
           {
              instructions.map((instruction,i) => (<InstructionItem key={"instruction_"+i} position={instruction.position} label={instruction.label} />))
            }
           </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const InstructionItem = ({ position, label }) => {
  return (<div className="d-flex align-items-center mt-2 mb-2">
    <div className="badge badge-secondary">{position} Column</div><div className="ml-2"><b>{label}</b></div>
  </div>)
}

Instructions.propTypes = {
  instructions: PropTypes.array,
  description:PropTypes.string
}

export default withRouter(Instructions)
