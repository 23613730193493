
import useClassify from "hooks/useClassify";

const ClusterTag = props =>{
    const {children,onClick,color,outline} =props;
    const [classname] = useClassify({size:"sm",outline,color:"light",className:"mr-2 mb-2"})
    return <div className={classname}  style={{display:"inline-block"}} >
      <div style={{display: "flex",
            alignItems: "center",
            justifyContent:"center"}}>
      {children}{" "}
      {<i className={"bx bx-x"} style={{ marginRight: "5px" }} onClick={onClick}/>}
      </div>
            </div>
  }

  export default ClusterTag;