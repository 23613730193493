import { GetInstitution } from "helpers/authentication/institution"
import {
  Format,
  SEARCHOPTIONS,
} from "helpers/variables"
import moment from "moment"
const HEADERS = [
  {
    title: "Date Initiated",
    name: "dateInitiated",
    entityKeyName: "DateCreated",
  },
  {
    title: "Date Concluded",
    name: "dateConcluded",
    entityKeyName: "DateUpdated",
  },
  { title: "Status", name: "status", entityKeyName: "Status", isBadge: true },
  {
    title: "Amount Received",
    name: "amountExpected",
    entityKeyName: "AmountExpected",
  },
  {
    title: "Amount Settled To Primary Account",
    name: "amountPaid",
    entityKeyName: "AmountPaid",
  },
  { title: "PWT Account Number", name: "virtualAccount", entityKeyName: "VirtualAccount" },
  { title: "Agent Mobile Number", name: "agentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
  { title: "RRN", name: "reference", entityKeyName: "Reference" },
  { title: "Sender Name", name: "customerName", entityKeyName: "CustomerName" },
  { title: "Sender Account Number", name: "customerAccountNumber", entityKeyName: "CustomerAccountNumber" },
  {
    title: "Agent Name",
    name: "agentName",
    entityKeyName: "AgentName",
  },
  {
    title: "Agent Account Number",
    name: "agentAccountNumber",
    entityKeyName: "AgentAccountNumber",
  },
  {
    title: "Fee",
    name: "fee",
    entityKeyName: "Fee",
  },
  {
    title : "Comment",
    name : "failureReason",
    entityKeyName: "FailureReason"
  }
]

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "text",
      label: "Transaction Reference",
      id: "s-reference",
      defaultValue: "",
      name: "reference",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select a type -",
      data: [{ id: "Successful", value: "Successful", key: 0 },{ id: "Pending", value: "Pending", key: 1 },{ id: "Failed", value: "Failed", key: 1 }],
      name: "status",
      width: 2,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  return options
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    institution: transaction.InstitutionName,
    status: {
      label: transaction.Status,
      badgeClass:
        transaction.Status == "Successful"
          ? "success"
          : transaction.Status == "Failed"
          ? "danger"
          : "secondary",
    },
    agentName: transaction.AgentName,
    customerName: transaction.CustomerName,
    virtualAccount:transaction.VirtualAccount,
    amountPaid : Format.currency(transaction.AmountPaid),
    amountExpected: Format.currency(transaction.AmountExpected),
    customerAccountNumber : transaction.CustomerAccountNumber,
    agentAccountNumber:transaction.AgentAccountNumber,
    agentPhoneNumber : transaction.AgentPhoneNumber,
    reference:transaction.Reference,
    fee: Format.currency(transaction.Fee),
    dateInitiated: Format.date(transaction.DateCreated),
    dateConcluded: Format.date(transaction.DateUpdated),
    failureReason : transaction.FailureReason
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    Reference: params["reference"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
