import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ClusterDataTable from "components/Common/DataTable"
import withDispose from "components/HOCs/WithDispose"

const Report = props => {
  const { tableData } = props
  const { columns, rows, paginationOptions, pageTitle, isLoading } = tableData
  const { pageNumber, totalCount, maxSize, onPageChange } = paginationOptions
  const [loading, setLoading] = useState(isLoading)
  const [data, setData] = useState(rows)

  useEffect(() => {
    setData(rows)
    setLoading(isLoading)
  }, [rows])
  useEffect(() => {
    setLoading(isLoading)
  }, [tableData])
  const setPageNumber = page => {
    setLoading(true)
    onPageChange(page)
  }

  const handleOnSearch = params => {
    setLoading(true)
    onSearch(params)
  }
  const handleOnDetails = id => {
    onViewDetails(id)
  }
  return (
    <React.Fragment>
      <ClusterDataTable
        columns={columns}
        loading={loading}
        rows={data}
        paginationOptions={{
          maxSize: maxSize,
          totalCount: totalCount,
          pageNumber: pageNumber,
        }}
        onPageChange={page => setPageNumber(page)}
      ></ClusterDataTable>
    </React.Fragment>
  )
}

Report.propTypes = {
  tableData: PropTypes.object,
  searchData: PropTypes.object,
}
export default withDispose(Report)
