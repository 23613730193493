import {
  call,
  put,
  all,
  fork,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"

// Login Redux States
import {
  CREATE_BRANCH,
  ENABLE_BRANCH,
  GET_BRANCHES,
  GET_BRANCH_DETAILS,
  PURGE_BRANCHES,
  UPDATE_BRANCH,
} from "./actionTypes"
import {
  branchApiError,
  enableBranchSuccess,
  getBranchDetailSuccess,
  getBranchSuccess,
  postBranchSuccess,
  purgeBranchSuccess,
  updateBranchSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../helpers/firebase_helper"
import {
  getClusterBranches,
  postClusterBranch,
  getClusterBranchDetail,
  putClusterBranch,
  enableClusterBranch,
  disableClusterBranch,
} from "helpers/backend_helper"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"

const fireBaseBackend = getFirebaseBackend()
function* fetchBranches() {
  const authUser = authHeader().AuthUser
  try {
    let response = yield call(
      getClusterBranches,
      authHeader().isRemote
        ? GetInstitution().remoteInstitutionId
        : authUser?.institutionId
    )

    yield put(getBranchSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(branchApiError(error.data))
  }
}

function* createBranch({ payload: { role } }) {
  try {
    const authUser = authHeader().AuthUser
    role["UserCategory"] = authUser?.userCategory
    const response = yield call(postClusterBranch, {
      ...JSON.parse(JSON.stringify(role)),
      institutionId: authHeader().isRemote
        ? GetInstitution().remoteInstitutionId
        : authUser?.institutionId,
    })
    yield put(postBranchSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(branchApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* updateBranch({ payload: { role } }) {
  try {
    // console.debug(role)

    const authUser = authHeader().AuthUser
    const response = yield call(putClusterBranch, {
      ...JSON.parse(JSON.stringify(role)),
      institutionId: authHeader().isRemote
        ? GetInstitution().remoteInstitutionId
        : authUser?.institutionId,
    })

    yield put(updateBranchSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(branchApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchBranchDetails(payload) {
  try {
    const response = yield call(getClusterBranchDetail, payload.id)
    yield put(getBranchDetailSuccess(response))
  } catch (error) {
    yield put(branchApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableBranch(payload) {
  try {
    let toEnable = true
    // console.debug(role)
    let cta = enableClusterBranch
    if (!payload.enable) {
      cta = disableClusterBranch
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableBranchSuccess(toEnable))
  } catch (error) {
    console.debug(error)
    yield put(branchApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doPurge() {
  yield put(purgeBranchSuccess())
}

function* branchSaga() {
  yield takeLeading(GET_BRANCHES, fetchBranches)
  yield takeLeading(GET_BRANCH_DETAILS, fetchBranchDetails)
  yield takeLeading(UPDATE_BRANCH, updateBranch)
  yield takeEvery(PURGE_BRANCHES, doPurge)
  yield takeLeading(CREATE_BRANCH, createBranch)
  yield takeLeading(ENABLE_BRANCH, doEnableBranch)
}

export default branchSaga
