import {
  GET_COMMISSIONS,
  GET_COMMISSION_DETAIL,
  GET_COMMISSION_DETAIL_SUCCESS,
  GET_COMMISSION_DETAIL_FAIL,
  GET_COMMISSIONS_SUCCESS,
  GET_COMMISSIONS_FAIL,
  CREATE_COMMISSION,
  POST_COMMISSION_SUCCESS,
  COMMISSION_PURGE,
  COMMISSION_PURGE_SUCCESS,
  POST_COMMISSION_FAIL,
  GET_ALL_COMMISSIONS,
} from "./actionTypes"

export const getCommissions = params => ({
  type: GET_COMMISSIONS,
  params,
})
export const getAllCommissions = params => ({
  type: GET_ALL_COMMISSIONS,
  params,
})
export const getCommissionsSuccess = commissions => ({
  type: GET_COMMISSIONS_SUCCESS,
  payload: commissions,
})
export const getCommissionsFail = error => ({
  type: GET_COMMISSIONS_FAIL,
  payload: error,
})
export const getCommissionDetail = id => ({
  type: GET_COMMISSION_DETAIL,
  id,
})

export const getCommissionDetailSuccess = commissions => ({
  type: GET_COMMISSION_DETAIL_SUCCESS,
  payload: commissions,
})

export const getCommissionDetailFail = error => ({
  type: GET_COMMISSION_DETAIL_FAIL,
  payload: error,
})

export const createCommission = commission => {
  return {
    type: CREATE_COMMISSION,
    payload: { commission },
  }
}

export const commissionApiError = error => {
  return {
    type: POST_COMMISSION_FAIL,
    payload: error,
  }
}

export const postCommissionSuccess = commission => {
  return {
    type: POST_COMMISSION_SUCCESS,
    payload: commission,
  }
}

export const commissionPurge = () => ({
  type: COMMISSION_PURGE,
})

export const commissionPurgeSuccess = () => ({
  type: COMMISSION_PURGE_SUCCESS,
})

export const enableCommission = (id, enable) => {
  return {
    type: ENABLE_COMMISSION,
    id,
    enable,
  }
}

export const enableCommissionSuccess = commission => {
  return {
    type: ENABLE_COMMISSION_SUCCESS,
    payload: commission,
  }
}

export const updateCommission = commission => {
  return {
    type: UPDATE_COMMISSION,
    payload: { commission },
  }
}
export const updateCommissionSuccess = commission => {
  return {
    type: UPDATE_COMMISSION_SUCCESS,
    payload: commission,
  }
}
export const purgeCommissions = () => {
  return {
    type: PURGE_COMMISSIONS,
  }
}

export const purgeCommissionSuccess = () => {
  return {
    type: PURGE_COMMISSIONS_SUCCESS,
  }
}
