import { GetInstitution } from "helpers/authentication/institution"
import { SEARCHOPTIONS } from "helpers/variables"

const HEADERS = [
  { title: "Action Initiator", name: "actionInitiator", entityKeyName: "UserName" },
  { title: "Full Name", name: "fullName", entityKeyName: "FullName" },
  { title: "Action", name: "trailAction", entityKeyName: "Action" },
  { title: "Action Date", name: "actionDate", entityKeyName: "ActionDate" },
  { title: "Subject", name: "subject", entityKeyName: "SubjectIdentifier" },
  { title: "DataType", name: "dataType", entityKeyName: "DataType" },
  { title: "Application", name: "application", entityKeyName: "ApplicationName" },
  { title: "", name: "action", isAction: true },
]

const SearchOptions = () => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Action Initiator",
      id: "s-actionInitiator",
      defaultValue: "",
      name: "actionInitiator",
    },
    {
      type: "text",
      label: "Action Type",
      id: "s-actionType",
      defaultValue: "",
      name: "actionType",
    },
    {
      type: "text",
      label: "Data Type",
      id: "s-dataType",
      defaultValue: "",
      name: "dataType",
    },
    {
      type: "text",
      label: "Subject",
      id: "s-subject",
      defaultValue: "",
      name: "subject",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name}))
  }
  return []
}
const MapToRows = auditTrail => {
  return {
    id: auditTrail.ID,
    actionInitiator: auditTrail.UserName,
    institution: auditTrail.InstitutionName,
    fullName: auditTrail.FullName,
    trailAction: auditTrail.Action,
    actionDate: auditTrail.ActionDate,
    subject: auditTrail.SubjectIdentifier,
    dataType: auditTrail.DataType,
    application: auditTrail.ApplicationName,
  }
}


const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    ActionInitiator: parameters["actionInitiator"],
    From: parameters["from"],
    To: parameters["to"],
    ActionType: parameters["actionType"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    DataType: parameters["dataType"],
    Subject: parameters["subject"],
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}
const MapToModalState = audit => {
  return [

    {
      title: "Basic Data",
      items: [
        {
          id: "fullName",
          label: "Name",
          name: "fullName",
          value: audit.FullName,
        },
        {
          id: "userName",
          label: "UserName",
          name: "userName",
          value: audit.UserName,
        },
        {
          id: "dataType",
          label: "Data Type",
          name: "dataType",
          value: audit.DataType,
        },
        {
          id: "applicationName",
          label: "Application Name",
          name: "applicationName",
          value: audit.ApplicationName,
        },
        {
          id: "action",
          label: "Action",
          name: "action",
          value: audit.Action,
        },
        {
          id: "actionDate",
          label: "Action Date",
          name: "actionDate",
          value: audit.ActionDate,
        }
      ],
    }
  ]
}
const MapToRowsEntityChange = values => {
  return values.map(value => ({
    name: value.Name,
    valueBefore: {
      label: value.ValueBefore,
      badgeClass: 'danger',
    },
    valueAfter: {
      label: value.ValueAfter,
      badgeClass: 'success',
    }
  })
  )
}

const ENTITY_CHANGE_HEADERS = [
  { title: "Field Name", name: "name" },
  { title: "Value Before ", name: "valueBefore", isBadge:true },
  { title: "Value After ", name: "valueAfter" , isBadge:true},
]

export default {
  HEADERS,
  ENTITY_CHANGE_HEADERS,
  MapToRowsEntityChange,
  SearchOptions,
  MapToRows,
  MapToModalState,
  SearchCriteria,
  MapToDropDown,
}
