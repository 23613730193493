import {
  GET_CLUSTERS,
  GET_CLUSTER_DETAIL,
  GET_CLUSTER_DETAIL_SUCCESS,
  GET_CLUSTER_DETAIL_FAIL,
  GET_CLUSTERS_SUCCESS,
  GET_CLUSTERS_FAIL,
  GET_ALL_CLUSTERS,
  CREATE_CLUSTER,
  POST_CLUSTER_SUCCESS,
  CLUSTER_PURGE,
  CLUSTER_PURGE_SUCCESS,
  POST_CLUSTER_FAIL,
  UPDATE_CLUSTER,
  UPDATE_CLUSTER_SUCCESS,
  UPDATE_CLUSTER_FAIL,
  ENABLE_CLUSTER,
  ENABLE_CLUSTER_SUCCESS
} from "./actionTypes"

export const getClusters = (params) => ({
  type: GET_CLUSTERS,
  params
})
export const getAllClusters = () => ({
  type: GET_ALL_CLUSTERS
})
export const getClustersSuccess = clusters => ({
  type: GET_CLUSTERS_SUCCESS,
  payload: clusters,
})
export const getClustersFail = error => ({
  type: GET_CLUSTERS_FAIL,
  payload: error,
})
export const getClusterDetail = id => ({
  type: GET_CLUSTER_DETAIL,
  id,
})

export const getClusterDetailSuccess = clusters => ({
  type: GET_CLUSTER_DETAIL_SUCCESS,
  payload: clusters,
})

export const getClusterDetailFail = error => ({
  type: GET_CLUSTER_DETAIL_FAIL,
  payload: error,
})


export const createCluster = (cluster) => {
  return {
    type: CREATE_CLUSTER,
    payload: { cluster },
  }
}

export const clusterApiError = error => {
  return {
    type: POST_CLUSTER_FAIL,
    payload: error,
  }
}

export const postClusterSuccess = cluster => {
  return {
    type: POST_CLUSTER_SUCCESS,
    payload: cluster,
  }
}

export const clusterPurge = () => ({
  type: CLUSTER_PURGE
})

export const clusterPurgeSuccess = () => ({
  type: CLUSTER_PURGE_SUCCESS
})


export const enableCluster = (id, enable) => {
  return {
    type: ENABLE_CLUSTER,
    id, enable
  }
}

export const enableClusterSuccess = cluster => {
  return {
    type: ENABLE_CLUSTER_SUCCESS,
    payload: cluster,
  }
}

export const updateCluster = (cluster) => {
  return {
    type: UPDATE_CLUSTER,
    payload: { cluster },
  }
}
export const updateClusterSuccess = cluster => {
  return {
    type: UPDATE_CLUSTER_SUCCESS,
    payload: cluster,
  }
}