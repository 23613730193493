import { GetInstitution } from "helpers/authentication/institution";
import { SEARCHOPTIONS } from "helpers/variables";

const HEADERS = [
    { title: "Name", name: "name",entityKeyName:"Name" },
    { title: "Code", name: "code",entityKeyName:"Code" },
    { title: "Manager", name: "manager",entityKeyName:"ClusterManager.Name" },
    { title: "Status", name: "status", isBadge: true,entityKeyName:"IsActive" },
    { title:"Action", name: "action", isAction:true },
]
const ERRORLIST = {
    name: undefined,
    code:undefined
}
const SearchOptions = (startDate, endDate,clusterManagers, institutions) => {

    let options = Object.assign({},SEARCHOPTIONS);

    // options.range.from = startDate.format("YYYY-MM-DD")
    // options.range.to = endDate.format("YYYY-MM-DD")
    options.range.enabled = false;
    options.fields = [
      {
        type: "text",
        label: "Name",
        id: "s-name",
        defaultValue: "",
        name: "name",
      },
      {
        type: "dropdown",
        label: "Cluster Manager",
        id: "s-clusterManager",
        defaultValue: "- Select -",
        data: MapToDropDown(clusterManagers),
        name: "managerId",
        width: 3,
      },
      {
        type: "dropdown",
        label: "All Institutions",
        id: "institutionCode",
        defaultValue: "- Select -",
        data: MapToDropDown(institutions),
        name: "institutionCode",
        width: 2,
        isRemote: GetInstitution().code == "*",
      },
    ]
    return options
}
const MapToDropDown=(data)=>{
    if(data instanceof Array){
        return data.map(item=>({id:item.ID,key:item.ID,value:item.Name}))
    }
    return [];
}
const MapToRows = (cluster) => {
    return {
      id: cluster.ID,
      name: cluster.Name,
      code: cluster.Code,
      institution: cluster.InstitutionName,
      status: {
        label: cluster.IsActive ? "Active" : "Inactive",
        badgeClass: cluster.IsActive ? "success" : "secondary",
      },
      manager: cluster.ClusterManager?.Name,
    }

}
const SearchCriteria = (parameters, maxSize) => {
    return JSON.stringify({
      Name: parameters["name"],
      InstitutionCode:
        parameters["institutionCode"] != "*"
          ? parameters["institutionCode"]
          : "",
      AgentClusterManagerID: parameters["managerId"],
      StartIndex: (parameters["page"] - 1) * maxSize,
      MaxSize: maxSize,
    })

}

const MapToModalState = (clusterDetail) => {
    return [
        {
            title: "",
            items: [
                {
                    id: "name",
                    label: "Name",
                    name: "name",
                    value: clusterDetail.Name,
                },{
                    id: "code",
                    label: "Code",
                    name: "code",
                    value: clusterDetail.Code,
                },
                {
                    id: "manager",
                    label: "Manager",
                    name: "managerId",
                    value: clusterDetail.ClusterManager?.Name,
                }
            ],
        }
    ]
}

export default {
    HEADERS, SearchOptions, MapToRows, SearchCriteria, MapToModalState, ERRORLIST,MapToDropDown
}