/* NETWORKMANAGERS */
export const GET_NETWORKMANAGERS = "GET_NETWORKMANAGERS"
export const GET_ALL_NETWORKMANAGERS = "GET_ALL_NETWORKMANAGERS"
export const GET_NETWORKMANAGERS_SUCCESS = "GET_NETWORKMANAGERS_SUCCESS"
export const GET_NETWORKMANAGERS_FAIL = "GET_NETWORKMANAGERS_FAIL"

export const GET_NETWORKMANAGER_DETAIL = "GET_NETWORKMANAGER_DETAIL"
export const GET_NETWORKMANAGER_DETAIL_SUCCESS =
  "GET_NETWORKMANAGER_DETAIL_SUCCESS"
export const GET_NETWORKMANAGER_DETAIL_FAIL = "GET_NETWORKMANAGER_DETAIL_FAIL"

export const CREATE_NETWORKMANAGER = "CREATE_NETWORKMANAGER"
export const POST_NETWORKMANAGER_SUCCESS = "POST_NETWORKMANAGER_SUCCESS"
export const POST_NETWORKMANAGER_FAIL = "POST_NETWORKMANAGER_FAIL"

export const NETWORKMANAGER_PURGE = "NETWORKMANAGER_PURGE"
export const NETWORKMANAGER_PURGE_SUCCESS = "NETWORKMANAGER_PURGE_SUCCESS"

export const UPDATE_NETWORKMANAGER = "UPDATE_NETWORKMANAGER"
export const UPDATE_NETWORKMANAGER_SUCCESS = "UPDATE_NETWORKMANAGER_SUCCESS"
export const UPDATE_NETWORKMANAGER_FAIL = "UPDATE_NETWORKMANAGER_FAIL"

export const ENABLE_NETWORKMANAGER = "ENABLE_NETWORKMANAGER"
export const ENABLE_NETWORKMANAGER_SUCCESS = "ENABLE_NETWORKMANAGER_SUCCESS"
