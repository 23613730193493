import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { withRouter } from "react-router-dom"
import { GENDER } from "helpers/variables"
import { Row, Col, Card, CardBody, FormGroup, Label, Alert } from "reactstrap"

import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import { Utils } from 'helpers/utility';
import Loading from 'components/Common/Loading';
import ClusterTextArea from "components/Common/TextArea"
import ClusterForm from 'components/Common/Form';
import withFormValidation from 'components/HOCs/WithFormValidation';
import { useUser } from "pages/UserManagement/Users/create/useUser"
import { Formik } from "formik"
import UserPermissionsForm from "./user-permission-form"
import UserDetailsForm from "./user-details-form"

const UserForm = props => {
  let { states, statesLoading, isUpdate, editLoading, userDetails, roles, handleValidSubmit } = props;

  const [isPrepopulated, setIsPrepopulated] = useState(false)
  const [prepopulatedValues, setPrepopulatedValues] = useState({});




  if (isUpdate === true && (Utils.Object.isEmpty(userDetails)))
    return <Loading><p>Getting User ...</p></Loading>

  if (!isPrepopulated) return <UserPermissionsForm {...props} setIsPrepopulated={setIsPrepopulated} setPrepopulatedValues={setPrepopulatedValues} />

  return <UserDetailsForm {...props} setIsPrepopulated={setIsPrepopulated} isPrepopulated={isPrepopulated} setPrepopulatedValues={setPrepopulatedValues} prepopulatedValues={prepopulatedValues} />
}
UserForm.propTypes = {
  success: PropTypes.any,
  error: PropTypes.any,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  roleLoading: PropTypes.bool,
  editLoading: PropTypes.bool,
  userDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
  branches: PropTypes.array,
  roles: PropTypes.array,
  states: PropTypes.array,
}

export default withRouter(withFormValidation(UserForm))
