import {
  GET_BRANCHES,
  API_ERROR,
  POST_BRANCH_SUCCESS,
  CREATE_BRANCH,
  GET_BRANCH_DETAILS,
  GET_BRANCH_DETAILS_SUCCESS,
  UPDATE_BRANCH,
  UPDATE_BRANCH_SUCCESS,
  PURGE_BRANCHES,
  PURGE_BRANCHES_SUCCESS,
  ENABLE_BRANCH,
  ENABLE_BRANCH_SUCCESS,
  GET_BRANCH_SUCCESS,
} from "./actionTypes"

export const getBranches = () => {
  return {
    type: GET_BRANCHES,
  }
}
export const getBranchDetail = id => {
  return {
    type: GET_BRANCH_DETAILS,
    id,
  }
}

export const getBranchSuccess = branches => {
  return {
    type: GET_BRANCH_SUCCESS,
    payload: branches,
  }
}

export const createBranch = branch => {
  return {
    type: CREATE_BRANCH,
    payload: { branch },
  }
}

export const postBranchSuccess = branch => {
  return {
    type: POST_BRANCH_SUCCESS,
    payload: branch,
  }
}

export const updateBranch = branch => {
  return {
    type: UPDATE_BRANCH,
    payload: { branch },
  }
}
export const updateBranchSuccess = branch => {
  return {
    type: UPDATE_BRANCH_SUCCESS,
    payload: branch,
  }
}
export const getBranchDetailSuccess = branch => {
  return {
    type: GET_BRANCH_DETAILS_SUCCESS,
    payload: branch,
  }
}
export const branchApiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

export const purgeBranch = () => {
  return {
    type: PURGE_BRANCHES,
  }
}

export const purgeBranchSuccess = () => {
  return {
    type: PURGE_BRANCHES_SUCCESS,
  }
}

export const enableBranch = (id, enable) => {
  return {
    type: ENABLE_BRANCH,
    id,
    enable,
  }
}
export const enableBranchSuccess = branch => {
  return {
    type: ENABLE_BRANCH_SUCCESS,
    payload: branch,
  }
}
