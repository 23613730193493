import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
} from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import clusterian from "../../../assets/images/icons/clusterian.png"
import Loading from "../Loading"
import withDispose from "components/HOCs/WithDispose"
const ClusterModal = props => {
  const {
    success,
    error,
    open,
    dataSet,
    onChangeModal,
    isBadge,
    badgeClass,
    append,
    status,
    title,
  } = props
  const [isOpen, setOpen] = useState(open)
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(dataSet)
  const changeOpen = status => {
    setOpen(status)
    onChangeModal(status)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])

  useEffect(() => {
    if (dataSet && dataSet?.length > 0) {
      setTimeout(() => setLoading(false), 1000)
      setData(dataSet)
    } else if (!dataSet) {
      setLoading(false)
    }
  }, [dataSet])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      toggle={() => {
        changeOpen(!isOpen)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            changeOpen(!isOpen)
          }}
        >
          {title}
          {dataSet && isBadge ? (
            <Badge
              style={{ marginLeft: "10px" }}
              className={"font-size-12 badge-soft-" + badgeClass}
              color={badgeClass}
              pill
            >
              {status}
            </Badge>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ModalHeader>
        {loading ? (
          <ModalBody>
            {" "}
            <Loading></Loading>
          </ModalBody>
        ) : (
          <React.Fragment>
            <ModalBody>
              {data &&
                data?.map((section, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Row className="animate__animated animate__fadeInUp">
                        <Col lg={12}>
                          <p>{section?.title}</p>
                        </Col>
                      </Row>

                      <Card
                        outline
                        style={{
                          background: "transparent",
                          borderColor: "#999999",
                        }}
                        className="border"
                      >
                        <CardBody>
                          <Row>
                            {section?.items?.map((item, idx) => {
                              return (
                                <Col
                                  className="animate__animated animate__fadeInUp"
                                  lg={6}
                                  md={6}
                                  sm={12}
                                  key={"items-" + idx}
                                >
                                  <ClusterInput
                                    defaultValue={item?.value}
                                    label={item?.label}
                                    id={item?.id?.toString()}
                                    name={item?.name}
                                    type="text"
                                    required={true}
                                    disabled
                                  ></ClusterInput>
                                </Col>
                              )
                            })}
                          </Row>
                        </CardBody>
                      </Card>
                    </React.Fragment>
                  )
                })}
              {append ? append?.map(a => a) : <React.Fragment></React.Fragment>}
            </ModalBody>
            <ModalFooter className="animate__animated animate__fadeInUp">
              {props.children}
            </ModalFooter>
          </React.Fragment>
        )}
      </div>
    </Modal>
  )
  //customer id, name, account number, gender, product, status, institution
}

ClusterModal.propTypes = {
  open: PropTypes.bool,
  dataSet: PropTypes.array,
  onChangeModal: PropTypes.func,
  title: PropTypes.string,
  badgeClass: PropTypes.string,
  status: PropTypes.string,
  success: PropTypes.string,
  isBadge: PropTypes.bool,
  append: PropTypes.array,
}

export default withDispose(ClusterModal)
