import ClusterForm from "components/Common/Form"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { ClusterInput } from "components/Common/Input/Input"
import { ClusterButton } from "components/Common/Button/Button"
import { Validators } from "helpers/validators"
import withFormValidation from "components/HOCs/WithFormValidation"
import { withRouter } from "react-router"

const CreatePOSAccountModal = (props) => {
    const { agent, loading, onChangeModal, open, onCreatePOSAccount, error, success } = props
    const Initiate = () => {
        return {
            NumberOfAccounts: 0,
            Accounts: [{ 'accountName' : `${agent?.LastName} ${agent?.OtherNames}`}]
        }
    }
    const [isOpen, setOpen] = useState(open)
    const [request, setRequest] = useState(Initiate())

    const changeOpen = status => {
        setOpen(status)
        onChangeModal(status)
    }

    const handleValidSubmit = e => {
        e.preventDefault()
        onCreatePOSAccount(request,agent.ID)

        setTimeout(() => {
            changeOpen(!open)
        }, 1500)
    }

    return (
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            tabIndex={-1}
            className="exampleModal"
            toggle={() => {
                changeOpen(!isOpen)
            }}
        >
            <div className="modal-content">
                <ModalHeader
                    toggle={() => {
                        changeOpen(!isOpen)
                    }}
                >
                    Create POS Account
                </ModalHeader>
                <ModalBody>
                    <ClusterForm handleSubmit={handleValidSubmit}>
                        <>
                            <Card
                                outline
                                style={{ background: "transparent", borderColor: "#999999" }}
                                className="border"
                            >
                                <CardBody>
                                    <Row>
                                        <Col lg={6} md={6} sm={6}>
                                            <ClusterInput
                                                defaultValue={`${agent?.LastName} ${agent?.OtherNames}`}
                                                label={"Account Name"}
                                                id={"m-accountName"}
                                                type="text"
                                                required={true}
                                                onHandleChange={(name,value) => { setRequest({...request, Accounts: [{ 'accountName': value}] })}}
                                            ></ClusterInput>
                                        </Col>
                                        <Col lg={6} md={6} sm={6}>
                                            <ClusterInput
                                                label={"Number of Account"}
                                                id={"m-numberOfAccount"}
                                                type="number"
                                                min={1}
                                                max={10}
                                                validators={[
                                                    Validators.max(10, 'Number of account cannot be greater than 10'),
                                                    Validators.min(1, 'Number of account cannot be less than 1' )]}
                                                required={true}
                                                onHandleChange={(name,value) => { setRequest({...request, NumberOfAccounts: Number(value)})}}
                                            ></ClusterInput>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </>
                        <Row style={{ paddingBottom: "50px" }}>
                            <Col lg={12}>
                                <ClusterButton
                                    loading={loading}
                                    color={"primary"}
                                    addon={"bx bx-save"}
                                >
                                   Create
                                </ClusterButton>
                            </Col>
                        </Row>
                    </ClusterForm>
                </ModalBody>
                <ModalFooter></ModalFooter>
            </div>
        </Modal>)
}

CreatePOSAccountModal.propTypes = {
    loading: PropTypes.bool,
    open: PropTypes.bool,
    setModal: PropTypes.func,
    error: PropTypes.any,
    success: PropTypes.any,
    onCreatePOSAccount: PropTypes.func,
    onChangeModal : PropTypes.func,
    agent: PropTypes.object
}

export default withFormValidation(withRouter(CreatePOSAccountModal))