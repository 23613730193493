import {
  GET_COMMISSION_STRUCTURES,
  GET_COMMISSION_STRUCTURE_DETAIL,
  GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS,
  GET_COMMISSION_STRUCTURE_DETAIL_FAIL,
  GET_COMMISSION_STRUCTURES_SUCCESS,
  GET_COMMISSION_STRUCTURES_FAIL,
  SAVE_STRUCTURE_CONFIGURATION,
  POST_COMMISSION_STRUCTURE_SUCCESS,
  COMMISSION_STRUCTURE_PURGE,
  COMMISSION_STRUCTURE_PURGE_SUCCESS,
  POST_COMMISSION_STRUCTURE_FAIL,
  GET_ALL_COMMISSION_STRUCTURES,
  GET_COMMISSION_STRUCTURES_BY_CATEGORY,
  ADD_COMMISSION_STRUCTURE,
  REMOVE_COMMISSION_STRUCTURE,
  ADD_COMMISSION_STRUCTURE_SUCCESS,
  REMOVE_COMMISSION_STRUCTURE_SUCCESS,
  UPDATE_COMMISSION_STRUCTURE_SUCCESS,
  UPDATE_COMMISSION_STRUCTURE,
} from "./actionTypes"

export const getCommissionStructures = params => ({
  type: GET_COMMISSION_STRUCTURES,
  params,
})
export const getAllCommissionStructures = () => ({
  type: GET_ALL_COMMISSION_STRUCTURES,
})
export const getCommissionStructuresByCategory = categoryId => ({
  type: GET_COMMISSION_STRUCTURES_BY_CATEGORY,
  categoryId,
})
export const getCommissionStructuresSuccess = commissionStructures => ({
  type: GET_COMMISSION_STRUCTURES_SUCCESS,
  payload: commissionStructures,
})
export const getCommissionStructuresFail = error => ({
  type: GET_COMMISSION_STRUCTURES_FAIL,
  payload: error,
})
export const getCommissionStructureDetail = id => ({
  type: GET_COMMISSION_STRUCTURE_DETAIL,
  id,
})

export const getCommissionStructureDetailSuccess = commissionStructures => ({
  type: GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS,
  payload: commissionStructures,
})

export const getCommissionStructureDetailFail = error => ({
  type: GET_COMMISSION_STRUCTURE_DETAIL_FAIL,
  payload: error,
})

export const saveCommissionStructures = structures => {
  return {
    type: SAVE_STRUCTURE_CONFIGURATION,
    payload: { structures },
  }
}

export const addCommissionStructure = commissionStructure => {
  return {
    type: ADD_COMMISSION_STRUCTURE,
    payload: commissionStructure,
  }
}
export const addCommissionStructureSuccess = commissionStructure => ({
  type: ADD_COMMISSION_STRUCTURE_SUCCESS,
  payload: commissionStructure,
})
export const removeCommissionStructure = commissionStructure => {
  return {
    type: REMOVE_COMMISSION_STRUCTURE,
    payload: commissionStructure,
  }
}
export const removeCommissionStructureSuccess = commissionStructure => ({
  type: REMOVE_COMMISSION_STRUCTURE_SUCCESS,
  payload: commissionStructure,
})
export const commissionStructureApiError = error => {
  return {
    type: POST_COMMISSION_STRUCTURE_FAIL,
    payload: error,
  }
}

export const postCommissionStructureSuccess = commissionStructure => {
  return {
    type: POST_COMMISSION_STRUCTURE_SUCCESS,
    payload: commissionStructure,
  }
}

export const commissionStructurePurge = () => ({
  type: COMMISSION_STRUCTURE_PURGE,
})

export const commissionStructurePurgeSuccess = () => ({
  type: COMMISSION_STRUCTURE_PURGE_SUCCESS,
})

export const enableCommissionStructure = (id, enable) => {
  return {
    type: ENABLE_COMMISSION_STRUCTURE,
    id,
    enable,
  }
}

export const enableCommissionStructureSuccess = commissionStructure => {
  return {
    type: ENABLE_COMMISSION_STRUCTURE_SUCCESS,
    payload: commissionStructure,
  }
}

export const updateCommissionStructure = commissionStructure => {
  return {
    type: UPDATE_COMMISSION_STRUCTURE,
    payload: { commissionStructure },
  }
}
export const updateCommissionStructureSuccess = commissionStructure => {
  return {
    type: UPDATE_COMMISSION_STRUCTURE_SUCCESS,
    payload: commissionStructure,
  }
}
export const purgeCommissionStructures = () => {
  return {
    type: PURGE_COMMISSION_STRUCTURES,
  }
}

export const purgeCommissionStructureSuccess = () => {
  return {
    type: PURGE_COMMISSION_STRUCTURES_SUCCESS,
  }
}
