/* CASHOUT_INSTITUTIONS */
export const GET_CASHOUT_INSTITUTIONS:any = "GET_CASHOUT_INSTITUTIONS"
export const GET_CASHOUT_INSTITUTIONS_SUCCESS = "GET_CASHOUT_INSTITUTIONS_SUCCESS"
export const GET_CASHOUT_INSTITUTIONS_FAIL = "GET_CASHOUT_INSTITUTIONS_FAIL"

export const GET_CASHOUT_INSTITUTION_DETAIL:any = "GET_CASHOUT_INSTITUTION_DETAIL"
export const GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS = "GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS"
export const GET_CASHOUT_INSTITUTION_DETAIL_FAIL = "GET_CASHOUT_INSTITUTION_DETAIL_FAIL"
export const PURGE_CASHOUT_INSTITUTIONS="PURGE_CASHOUT_INSTITUTIONS"
export const PURGE_CASHOUT_INSTITUTIONS_SUCCESS ="PURGE_CASHOUT_INSTITUTIONS_SUCCESS"
export const GET_ALL_CASHOUT_INSTITUTIONS = "GET_ALL_CASHOUT_INSTITUTIONS"
export const CREATE_CASHOUT_INSTITUTION:any = "CREATE_CASHOUT_INSTITUTION"
export const POST_CASHOUT_INSTITUTION_SUCCESS = "POST_CASHOUT_INSTITUTION_SUCCESS"
export const POST_CASHOUT_INSTITUTION_FAIL = "POST_CASHOUT_INSTITUTION_FAIL"

export const CASHOUT_INSTITUTION_PURGE = "CASHOUT_INSTITUTION_PURGE"
export const CASHOUT_INSTITUTION_PURGE_SUCCESS = "CASHOUT_INSTITUTION_PURGE_SUCCESS"

export const UPDATE_CASHOUT_INSTITUTION:any = "UPDATE_CASHOUT_INSTITUTION"
export const UPDATE_CASHOUT_INSTITUTION_SUCCESS = "UPDATE_CASHOUT_INSTITUTION_SUCCESS"
export const UPDATE_CASHOUT_INSTITUTION_FAIL = "UPDATE_CASHOUT_INSTITUTION_FAIL"

export const ENABLE_CASHOUT_INSTITUTION = "ENABLE_CASHOUT_INSTITUTION"
export const ENABLE_CASHOUT_INSTITUTION_SUCCESS = "ENABLE_CASHOUT_INSTITUTION_SUCCESS"
