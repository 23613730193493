/* RECEIPTS */
export const GET_RECEIPTS:any = "GET_RECEIPTS"
export const GET_RECEIPTS_SUCCESS:any = "GET_RECEIPTS_SUCCESS"
export const GET_RECEIPTS_FAIL:any = "GET_RECEIPTS_FAIL"

export const GET_RECEIPT_DETAIL:any = "GET_RECEIPT_DETAIL"
export const GET_RECEIPT_DETAIL_SUCCESS:any = "GET_RECEIPT_DETAIL_SUCCESS"
export const GET_RECEIPT_DETAIL_FAIL:any = "GET_RECEIPT_DETAIL_FAIL"

export const GET_REPORTS:any = "GET_REPORTS"
export const GET_REPORTS_SUCCESS:any = "GET_REPORTS_SUCCESS"
export const GET_REPORTS_FAIL:any = "GET_REPORTS_FAIL"

export const GET_CASHOUTREPORTS:any = "GET_CASHOUTREPORTS"
export const GET_CASHOUTREPORTS_SUCCESS:any = "GET_CASHOUTREPORTS_SUCCESS"
export const GET_CASHOUTREPORTS_FAIL:any = "GET_CASHOUTREPORTS_FAIL"

export const GET_PAYWITHTRANSFER_REPORTS:any = "GET_PAYWITHTRANSFER_REPORTS"
export const GET_PAYWITHTRANSFER_REPORTS_SUCCESS:any = "GET_PAYWITHTRANSFER_REPORTS_SUCCESS"
export const GET_PAYWITHTRANSFER_REPORTS_FAIL:any = "GET_PAYWITHTRANSFER_REPORTS_FAIL"


export const GET_CARDTRANSFER_REPORTS:any = "GET_CARDTRANSFER_REPORTS"
export const GET_CARDTRANSFER_REPORTS_SUCCESS:any = "GET_CARDTRANSFER_REPORTS_SUCCESS"
export const GET_CARDTRANSFER_REPORTS_FAIL:any = "GET_CARDTRANSFER_REPORTS_FAIL"

export const GET_FUNDSTRANSFERREPORTS:any = "GET_FUNDSTRANSFERREPORTS"
export const GET_FUNDSTRANSFERREPORTS_SUCCESS:any = "GET_FUNDSTRANSFERREPORTS_SUCCESS"
export const GET_FUNDSTRANSFERREPORTS_FAIL:any = "GET_FUNDSTRANSFERREPORTS_FAIL"

export const GET_SUMMARYREPORTS:any = "GET_SUMMARYREPORTS"
export const GET_SUMMARYREPORTS_SUCCESS:any = "GET_SUMMARYREPORTS_SUCCESS"
export const GET_SUMMARYREPORTS_FAIL:any = "GET_SUMMARYREPORTS_FAIL"


export const GET_COMPARATIVESREPORTS:any = "GET_COMPARATIVESREPORTS"
export const GET_COMPARATIVESREPORTS_SUCCESS:any = "GET_COMPARATIVESREPORTS_SUCCESS"
export const GET_COMPARATIVESREPORTS_FAIL:any = "GET_COMPARATIVESREPORTS_FAIL"

export const GET_TRANSACTION_REPORTS:any = "GET_TRANSACTION_REPORTS"
export const GET_TRANSACTION_REPORTS_SUCCESS:any = "GET_TRANSACTION_REPORTS_SUCCESS"
export const GET_TRANSACTION_REPORTS_FAIL:any = "GET_TRANSACTION_REPORTS_FAIL"

export const GET_COLLECTION_REPORTS:any = "GET_COLLECTION_REPORTS"
export const GET_COLLECTION_REPORTS_SUCCESS:any = "GET_COLLECTION_REPORTS_SUCCESS"
export const GET_COLLECTION_REPORTS_FAIL:any = "GET_COLLECTION_REPORTS_FAIL"

export const REPORT_PURGE:any = "REPORT_PURGE"
export const REPORT_PURGE_SUCCESS:any = "REPORT_PURGE_SUCCESS"

export const GET_DOWNLOAD_REPORTS:any = "GET_DOWNLOAD_REPORTS"
export const GET_DOWNLOAD_REPORTS_SUCCESS:any = "GET_DOWNLOAD_REPORTS_SUCCESS"
export const GET_DOWNLOAD_REPORTS_FAIL:any = "GET_DOWNLOAD_REPORTS_FAIL"

export const LOG_DOWNLOAD:any = "LOG_DOWNLOAD"
export const LOG_DOWNLOAD_SUCCESS:any = "LOG_DOWNLOAD_SUCCESS"
export const LOG_DOWNLOAD_FAIL:any = "LOG_DOWNLOAD_FAIL"

export const DOWNLOAD_REPORT:any = "DOWNLOAD_REPORT"
export const DOWNLOAD_REPORT_SUCCESS:any = "DOWNLOAD_REPORT_SUCCESS"
export const DOWNLOAD_REPORT_FAIL:any = "DOWNLOAD_REPORT_FAIL"
