/* CHARGEBACKS */
export const GET_CHARGEBACKS = "GET_CHARGEBACKS"
export const GET_ALL_CHARGEBACKS = "GET_ALL_CHARGEBACKS"
export const GET_CHARGEBACKS_SUCCESS = "GET_CHARGEBACKS_SUCCESS"
export const GET_CHARGEBACKS_FAIL = "GET_CHARGEBACKS_FAIL"

export const GET_CHARGEBACK_DETAIL = "GET_CHARGEBACK_DETAIL"
export const GET_CHARGEBACK_DETAIL_SUCCESS = "GET_CHARGEBACK_DETAIL_SUCCESS"
export const GET_CHARGEBACK_DETAIL_FAIL = "GET_CHARGEBACK_DETAIL_FAIL"

export const CREATE_CHARGEBACK = "CREATE_CHARGEBACK"
export const POST_CHARGEBACK_SUCCESS = "POST_CHARGEBACK_SUCCESS"
export const POST_CHARGEBACK_FAIL = "POST_CHARGEBACK_FAIL"

export const CHARGEBACK_PURGE = "CHARGEBACK_PURGE"
export const CHARGEBACK_PURGE_SUCCESS = "CHARGEBACK_PURGE_SUCCESS"

export const UPDATE_CHARGEBACK = "UPDATE_CHARGEBACK"
export const UPDATE_CHARGEBACK_SUCCESS = "UPDATE_CHARGEBACK_SUCCESS"
export const UPDATE_CHARGEBACK_FAIL = "UPDATE_CHARGEBACK_FAIL"

export const REGENERATE_CHARGEBACK_TOKEN = "REGENERATE_CHARGEBACK_TOKEN"
export const REGENERATE_CHARGEBACK_TOKEN_SUCCESS = "REGENERATE_CHARGEBACK_TOKEN_SUCCESS"
export const REGENERATE_CHARGEBACK_TOKEN_FAIL = "REGENERATE_CHARGEBACK_TOKEN_FAIL"

export const ENABLE_CHARGEBACK = "ENABLE_CHARGEBACK"
export const ENABLE_CHARGEBACK_SUCCESS = "ENABLE_CHARGEBACK_SUCCESS"
