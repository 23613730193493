import { call, put, takeEvery, takeLeading } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_UNSETTLED_TRANSACTIONS,
  GET_UNSETTLED_TRANSACTION_DETAIL,
  VALIDATE_UNSETTLED_TRANSACTION,
  PURGE_UNSETTLED_TRANSACTIONS,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION,
  RESOLVE_UNSETTLED_TRANSACTION,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT,
  DOWNLOAD_UNSETTLED_TRANSACTION,
} from "./actionTypes"
import {
  getUnsettledTransactionsSuccess,
  getUnsettledTransactionsFail,
  getUnsettledTransactionDetailSuccess,
  getUnsettledTransactionDetailFail,
  purgeUnsettledTransactionSuccess,
  batchResolveUnsettledTransactionSuccess,
  batchResolveUnsettledTransactionFail,
  resolveUnsettledTransactionFail,
  resolveUnsettledTransactionSuccess,
  processUploadUnsettledTransactionFail,
  validateUnsettledTransactionSuccess,
  validateUnsettledTransactionFail,
  processUploadUnsettledTransactionSuccess,
  downloadUnsettledTransactionSuccess,
  downloadUnsettledTransactionFail,
  downloadUnsettledTransactionReceiptSuccess,
  downloadUnsettledTransactionReceiptFail
} from "./actions"

import {
  getUnsettledTransactions,
  getUnsettledTransactionDetail,
  processUploadUnsettledTransactions,
  batchResolveUnsettledTransaction,
  validateUnsettledTransactions,
  downloadUnsettledTransactions,
  downloadUnsettledTransactionReceipt,
  resolveUnsettledTransaction
} from "helpers/backend_helper"

function* fetchUnsettledTransactions({ params }) {
  try {
    const response = yield call(getUnsettledTransactions, JSON.parse(params))
    yield put(getUnsettledTransactionsSuccess(response))
  } catch (error) {
    console.debug("Fetching UnsettledTransactions Error", error)
    yield put(getUnsettledTransactionsFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchUnsettledTransactionDetail({ id }) {
  try {
    const response = yield call(getUnsettledTransactionDetail, id);
    yield put(getUnsettledTransactionDetailSuccess(response))
  } catch (error) {
    yield put(getUnsettledTransactionDetailFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doDownloadUnsettledTransactions({ batchID }) {
  try {
    const response = yield call(downloadUnsettledTransactions, batchID);
    yield put(downloadUnsettledTransactionSuccess(response))
  } catch (error) {
    yield put(downloadUnsettledTransactionFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doDownloadUnsettledTransactionReceipts({ batchID }) {
  try {
    const response = yield call(downloadUnsettledTransactionReceipt, batchID);
    yield put(downloadUnsettledTransactionReceiptSuccess(response))
  } catch (error) {
    yield put(downloadUnsettledTransactionReceiptFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* processUpload({ payload: { unsettledTransactions } }) {
  try {

    const response = yield call(processUploadUnsettledTransactions, unsettledTransactions)

    yield put(processUploadUnsettledTransactionSuccess(response))

  } catch (error) {
    console.debug("ERROR", error)
    yield put(processUploadUnsettledTransactionFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* validateUnsettledTransactionForUpload({ payload: { unsettledTransactions } }) {
  try {
    const response = yield call(validateUnsettledTransactions, unsettledTransactions)

    yield put(validateUnsettledTransactionSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(validateUnsettledTransactionFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doBatchResolveUnsettledTransaction({ batchID, approve }) {
  try {

    const response = yield call(batchResolveUnsettledTransaction, batchID, approve)

    yield put(batchResolveUnsettledTransactionSuccess({ ...response, approve, batchID }))

  } catch (error) {
    console.debug(error)
    yield put(batchResolveUnsettledTransactionFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* doResolveUnsettledTransaction({ batchID, transactionID, approve }) {
  try {

    const response = yield call(resolveUnsettledTransaction, batchID, transactionID, approve)

    yield put(resolveUnsettledTransactionSuccess({ ...response, transactionID, approve, batchID }))

  } catch (error) {
    console.debug(error)
    yield put(resolveUnsettledTransactionFail(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}


function* doPurge() {
  yield put(purgeUnsettledTransactionSuccess())
}

function* unsettledTransactionsSaga() {
  yield takeLeading(GET_UNSETTLED_TRANSACTIONS, fetchUnsettledTransactions)
  yield takeEvery(GET_UNSETTLED_TRANSACTION_DETAIL, fetchUnsettledTransactionDetail)
  yield takeEvery(PURGE_UNSETTLED_TRANSACTIONS, doPurge)
  yield takeLeading(VALIDATE_UNSETTLED_TRANSACTION, validateUnsettledTransactionForUpload)
  yield takeLeading(PROCESS_UPLOAD_UNSETTLED_TRANSACTION, processUpload)
  yield takeLeading(BATCH_RESOLVE_UNSETTLED_TRANSACTION, doBatchResolveUnsettledTransaction)
  yield takeLeading(RESOLVE_UNSETTLED_TRANSACTION, doResolveUnsettledTransaction)
  yield takeLeading(DOWNLOAD_UNSETTLED_TRANSACTION, doDownloadUnsettledTransactions)
  yield takeLeading(DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT, doDownloadUnsettledTransactionReceipts)
}

export default unsettledTransactionsSaga
