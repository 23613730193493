export const GET_NOTIFICATIONS : any = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_FAIL = "GET_NOTIFICATIONS_FAIL"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const POST_NOTIFICATION : any = "POST_NOTIFICATION"
export const POST_NOTIFICATION_SUCCESS = "POST_NOTIFICATION_SUCCESS"
export const POST_NOTIFICATION_FAIL = "POST_NOTIFICATION_FAIL"
export const EXPORT_NOTIFICATION_REPORT : any = "EXPORT_NOTIFICATION_REPORT"
export const EXPORT_NOTIFICATION_REPORT_FAIL = "EXPORT_NOTIFICATION_REPORT_FAIL"
export const EXPORT_NOTIFICATION_REPORT_SUCCESS = "EXPORT_NOTIFICATION_REPORT_SUCCESS"
export const GET_NOTIFICATION_DETAILS : any = "GET_NOTIFICATION_DETAILS"
export const GET_NOTIFICATION_DETAILS_FAIL = "GET_NOTIFICATION_DETAILS_FAIL"
export const GET_NOTIFICATION_DETAILS_SUCCESS = "GET_NOTIFICATION_DETAILS_SUCCESS"