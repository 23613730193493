
import { defaultObj } from "helpers/utility"
import {
  GET_AUDIT_TRAIL_DETAIL_SUCCESS,
  GET_AUDIT_TRAIL_DETAIL_FAIL,
  GET_AUDIT_TRAILS_SUCCESS,
  GET_AUDIT_TRAILS_FAIL,
  AUDIT_TRAIL_PURGE,
  ENABLE_AUDIT_TRAIL,
  ENABLE_AUDIT_TRAIL_SUCCESS,
  AUDIT_TRAIL_PURGE_SUCCESS,
  GET_AUDIT_TRAILS,
  POST_AUDIT_TRAIL_SUCCESS,
  UPDATE_AUDIT_TRAIL,
  CREATE_AUDIT_TRAIL,
  UPDATE_AUDIT_TRAIL_SUCCESS,
  POST_AUDIT_TRAIL_FAIL,
  GET_ALL_AUDIT_TRAILS
} from "./actionTypes"

const INIT_STATE = {
  auditTrails: [],
  auditTrailDetail: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined
}

const AuditTrails = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        auditTrails: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_AUDIT_TRAILS:
      return {
        ...state,
        loading: true,
        error:"",
        success:""
      }
    case GET_ALL_AUDIT_TRAILS:
      return {
        ...state,
        loading: true,
      }
    case GET_AUDIT_TRAILS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_AUDIT_TRAIL_DETAIL_SUCCESS:
      return {
        ...state,
        auditTrailDetail: action.payload?.Data,
        loading: false,
      }

    case GET_AUDIT_TRAIL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_AUDIT_TRAIL_SUCCESS:
      return {
        ...state,
        auditTrail: defaultObj(state.auditTrail),//action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }
    case UPDATE_AUDIT_TRAIL:
      state = {
        ...state,
        editLoading: true,
        error:"",
        success:""
      }
      break
    case CREATE_AUDIT_TRAIL:
     return {
        ...state,
        editLoading: true,
        error:"",
        success:""
      }
      
    case POST_AUDIT_TRAIL_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break


    case UPDATE_AUDIT_TRAIL_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: ""
      }
      break

    case ENABLE_AUDIT_TRAIL:
      state = {
        ...state,
        editLoading: true,
        error:"",
        success:"",
      }
      break
    case ENABLE_AUDIT_TRAIL_SUCCESS:
      let detail = state.auditTrailDetail;
      detail.IsActive = action.payload?.enable
      return {
        ...state,
        editLoading: false,
        loading: false,
        auditTrailDetail:detail,
        error: "",
        statusChanged: action.payload?.enable,
        success: action.payload?.Message
      }
    case AUDIT_TRAIL_PURGE_SUCCESS:
      
      return INIT_STATE
    default:
      return state
  }
  return state
}

export default AuditTrails
