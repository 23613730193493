import { call, put, takeEvery, takeLeading, takeLatest } from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_AUDIT_TRAILS,
  GET_AUDIT_TRAIL_DETAIL,
  AUDIT_TRAIL_PURGE,
  ENABLE_AUDIT_TRAIL,
  CREATE_AUDIT_TRAIL,
  UPDATE_AUDIT_TRAIL,
  GET_ALL_AUDIT_TRAILS
} from "./actionTypes"
import {
  getAuditTrailsSuccess,
  getAuditTrailsFail,
  getAuditTrailDetailSuccess,
  getAuditTrailDetailFail,
  auditTrailPurgeSuccess,
  updateAuditTrailSuccess,
  postAuditTrailSuccess,
  auditTrailApiError
} from "./actions"

//Include Both Helper File with needed methods
import {
  getAuditTrails,
  getAuditTrailDetail,
  putAuditTrail,
  postAuditTrail,
  enableAuditTrail,
  disableAuditTrail,
  getAllAuditTrails
} from "helpers/backend_helper"
import { enableAuditTrailSuccess } from "store/actions"
const format = "yyyy-MM-DD";
const auditTrails ={
  Message: "Retrieved Successfully",
  Data: {
    Collection: [{
      ID: 1,
      Name: "AuditTrail 1",
      IsActive: true,
      Manager: "Banjo Pelumi"
    }, {
      ID: 2,
      Name: "AuditTrail 2",
      IsActive: true,
      Manager: "Banjo Pelumi"
    }, {
      ID: 3,
      Name: "AuditTrail 3",
      IsActive: true,
      Manager: "Amicki Fabian"
    }], TotalCount: 3
  }
}
function* fetchAuditTrails({ params }) {
  try {
    const response = yield call(getAuditTrails, JSON.parse(params))
    // let response = auditTrails;
    yield put(getAuditTrailsSuccess(response))
  } catch (error) {
    console.debug("Fetching AuditTrails Error", error)
    yield put(getAuditTrailsFail(error))
  }
}
function* fetchAllAuditTrails() {
  try {
    const response = yield call(getAllAuditTrails)
    yield put(getAuditTrailsSuccess(response))
  } catch (error) {
    console.debug("Fetching AuditTrails Error", error)
    yield put(getAuditTrailsFail(error))
  }
}

function* fetchAuditTrailDetail({ id }) {
  try {
    const response = yield call(getAuditTrailDetail, id);
    // const response = {Message:"Retrieved Successfully", Data: auditTrails.Data.Collection.find(c=>c.ID==id)}
    yield put(getAuditTrailDetailSuccess(response))
  } catch (error) {
    yield put(getAuditTrailDetailFail(error))
  }
}


function* createAuditTrail({ payload: { auditTrail } }) {
  try {

    const response = yield call(postAuditTrail, { ...JSON.parse(JSON.stringify(auditTrail)) })
    yield put(postAuditTrailSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(auditTrailApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* updateAuditTrail({ payload: { auditTrail } }) {
  try {

    const response = yield call(putAuditTrail, { ...JSON.parse(JSON.stringify(auditTrail)) })

    yield put(updateAuditTrailSuccess(response))

  } catch (error) {
    console.debug(error)
    yield put(auditTrailApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableAuditTrail(payload) {
  try {
    let toEnable = true;
    let cta = enableAuditTrail;
    if (!payload.enable) { cta = disableAuditTrail; toEnable = false }
    const response = yield call(cta, payload.id)

    yield put(enableAuditTrailSuccess({ ...response, enable: toEnable }))

  } catch (error) {
    console.debug(error)
    yield put(auditTrailApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}
function* doPurge() {
  yield put(auditTrailPurgeSuccess())
}

function* auditTrailsSaga() {
  yield takeLeading(GET_AUDIT_TRAILS, fetchAuditTrails)
  yield takeLeading(GET_ALL_AUDIT_TRAILS, fetchAllAuditTrails)
  yield takeEvery(GET_AUDIT_TRAIL_DETAIL, fetchAuditTrailDetail)
  yield takeEvery(AUDIT_TRAIL_PURGE, doPurge)
  yield takeLeading(ENABLE_AUDIT_TRAIL, doEnableAuditTrail)
  yield takeLeading(UPDATE_AUDIT_TRAIL, updateAuditTrail)
  yield takeLeading(CREATE_AUDIT_TRAIL, createAuditTrail)
}

export default auditTrailsSaga
