import {
  GET_UNSETTLED_TRANSACTIONS,
  GET_UNSETTLED_TRANSACTIONS_FAIL,
  GET_UNSETTLED_TRANSACTIONS_SUCCESS,
  GET_UNSETTLED_TRANSACTION_DETAIL,
  GET_UNSETTLED_TRANSACTION_DETAIL_FAIL,
  GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS,
  PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL,
  RESOLVE_UNSETTLED_TRANSACTION,
  RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
  RESOLVE_UNSETTLED_TRANSACTION_FAIL,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
  BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL,
  VALIDATE_UNSETTLED_TRANSACTION_FAIL,
  VALIDATE_UNSETTLED_TRANSACTION,
  VALIDATE_UNSETTLED_TRANSACTION_SUCCESS,
  DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS,
  DOWNLOAD_UNSETTLED_TRANSACTION,
  DOWNLOAD_UNSETTLED_TRANSACTION_FAIL,
  PURGE_UNSETTLED_TRANSACTIONS,
  PURGE_UNSETTLED_TRANSACTIONS_SUCCESS,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL,
  DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS
} from "./actionTypes"

export const getUnsettledTransactions = (params) => ({
  type: GET_UNSETTLED_TRANSACTIONS,
  params
})
export const getAllUnsettledTransactions = () => ({
  type: GET_UNSETTLED_TRANSACTIONS
})
export const getUnsettledTransactionsSuccess = unsettledTransactions => ({
  type: GET_UNSETTLED_TRANSACTIONS_SUCCESS,
  payload: unsettledTransactions,
})
export const getUnsettledTransactionsFail = error => ({
  type: GET_UNSETTLED_TRANSACTIONS_FAIL,
  payload: error,
})

export const getUnsettledTransactionDetail = id => ({
  type: GET_UNSETTLED_TRANSACTION_DETAIL,
  id,
})

export const getUnsettledTransactionDetailSuccess = unsettledTransactions => ({
  type: GET_UNSETTLED_TRANSACTION_DETAIL_SUCCESS,
  payload: unsettledTransactions,
})

export const getUnsettledTransactionDetailFail = error => ({
  type: GET_UNSETTLED_TRANSACTION_DETAIL_FAIL,
  payload: error,
})


export const downloadUnsettledTransaction = batchID => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION,
  batchID,
})

export const downloadUnsettledTransactionSuccess = unsettledTransactions => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION_SUCCESS,
  payload: unsettledTransactions,
})

export const downloadUnsettledTransactionFail = error => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION_FAIL,
  payload: error,
})


export const downloadUnsettledTransactionReceipt = batchID => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT,
  batchID,
})

export const downloadUnsettledTransactionReceiptSuccess = unsettledTransactions => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_SUCCESS,
  payload: unsettledTransactions,
})

export const downloadUnsettledTransactionReceiptFail = error => ({
  type: DOWNLOAD_UNSETTLED_TRANSACTION_RECIEPT_FAIL,
  payload: error,
})



export const processUploadUnsettledTransactionFail = error => {
  return {
    type: PROCESS_UPLOAD_UNSETTLED_TRANSACTION_FAIL,
    payload: error,
  }
}

export const processUploadUnsettledTransaction = (unsettledTransactions) => {
  return {
    type: PROCESS_UPLOAD_UNSETTLED_TRANSACTION,
    payload: { unsettledTransactions },
  }
}
export const processUploadUnsettledTransactionSuccess = unsettledTransaction => {
  return {
    type: PROCESS_UPLOAD_UNSETTLED_TRANSACTION_SUCCESS,
    payload: unsettledTransaction,
  }
}

export const resolveUnsettledTransaction = (batchID: number, transactionID: number, approve: boolean) => {
  return {
    type: RESOLVE_UNSETTLED_TRANSACTION,
    batchID, transactionID, approve
  }
}

export const resolveUnsettledTransactionSuccess = unsettledTransaction => {
  return {
    type: RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
    payload: unsettledTransaction,
  }
}

export const resolveUnsettledTransactionFail = error => {
  return {
    type: RESOLVE_UNSETTLED_TRANSACTION_FAIL,
    payload: error,
  }
}


export const batchResolveUnsettledTransaction = (batchID: number, approve: boolean) => {
  return {
    type: BATCH_RESOLVE_UNSETTLED_TRANSACTION,
    batchID, approve
  }
}

export const batchResolveUnsettledTransactionSuccess = unsettledTransaction => {
  return {
    type: BATCH_RESOLVE_UNSETTLED_TRANSACTION_SUCCESS,
    payload: unsettledTransaction,
  }
}

export const batchResolveUnsettledTransactionFail = error => {
  return {
    type: BATCH_RESOLVE_UNSETTLED_TRANSACTION_FAIL,
    payload: error,
  }
}



export const validateUnsettledTransactionFail = error => {
  return {
    type: VALIDATE_UNSETTLED_TRANSACTION_FAIL,
    payload: error,
  }
}

export const validateUnsettledTransaction = (unsettledTransactions) => {
  return {
    type: VALIDATE_UNSETTLED_TRANSACTION,
    payload: { unsettledTransactions },
  }
}
export const validateUnsettledTransactionSuccess = unsettledTransaction => {
  return {
    type: VALIDATE_UNSETTLED_TRANSACTION_SUCCESS,
    payload: unsettledTransaction,
  }
}



export const purgeUnsettledTransactions = () => {
  return {
    type: PURGE_UNSETTLED_TRANSACTIONS
  }
}

export const purgeUnsettledTransactionSuccess = () => {
  return {
    type: PURGE_UNSETTLED_TRANSACTIONS_SUCCESS
  }
}