import * as actionTypes from "./actionTypes";

export const getNotifications = params => ({
    type : actionTypes.GET_NOTIFICATIONS,
    params
})

export const getNotificationsSuccess = notifications => ({
    type : actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload : notifications
})

export const getNotificationsFailure = error => ({
    type : actionTypes.GET_NOTIFICATIONS_FAIL,
    payload : error
})

export const getNotificationDetails = id => ({
    type : actionTypes.GET_NOTIFICATION_DETAILS,
    id
})

export const getNotificationDetailsSuccess = notification => ({
    type : actionTypes.GET_NOTIFICATION_DETAILS_SUCCESS,
    payload : notification
})

export const getNotificationDetailsFailure = error => ({
    type : actionTypes.GET_NOTIFICATION_DETAILS_FAIL,
    payload : error
})

export const postNotification = payload => ({
    type : actionTypes.POST_NOTIFICATION,
    payload
})

export const postNotificationSuccess = payload => ({
    type : actionTypes.POST_NOTIFICATION_SUCCESS,
    payload
})

export const postNotificationFailure = payload => ({
    type : actionTypes.POST_NOTIFICATION_FAIL,
    payload
})

export const exportNotification = params => ({
    type : actionTypes.EXPORT_NOTIFICATION_REPORT,
    params
})

export const exportNotificationSuccess = report => ({
    type : actionTypes.EXPORT_NOTIFICATION_REPORT_SUCCESS,
    payload : report
})

export const exportNotificationFailure = error => ({
    type : actionTypes.EXPORT_NOTIFICATION_REPORT_FAIL,
    payload : error
})