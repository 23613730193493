import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { withRouter } from "react-router-dom"
import Loading from "components/Common/Loading"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import { AccountMode } from "enums/account-mode"
import { AgentSection } from "enums/agent-section"
import AccountModeForm from "./account-mode-form"
import AgentDetailsForm from "./agent-details-form"
import withFormValidation from "components/HOCs/WithFormValidation"

const AgentForm = props => {
  const { isUpdate, loading, agent, agents } = props
  const [modeActivated, setModeActivated] = useState(false)
  useEffect(() => {
    if (!Utils.Object.isEmpty(agent) && isUpdate && agent.AccountMode)
      setModeActivated(true)
  }, [agent])
  const generateFormValues = agent => {
    return {
      UserID: agent?.UserID ?? 0,
      Cluster: agent?.Cluster,
      ClusterID: agent?.Cluster?.ID,
      ClusterManager: agent?.ClusterManager,
      ClusterManagerID: agent?.ClusterManager?.ID ?? 0,
      NetworkManager: agent?.NetworkManager,
      NetworkManagerID: agent?.NetworkManager?.ID ?? 0,
      SuperAgentPhoneNumber: agent?.TheSuperAgent?.MobileNumber,
      Category: agent?.Category,
      CategoryID: agent?.Category?.ID ?? 0,
      LastName: agent?.LastName,
      BusinessName: agent?.BusinessName,
      Gender: agent?.Gender,
      OtherNames: agent?.OtherNames,
      AddressLocation: agent?.AddressLocation,
      PhoneNumber: agent?.PhoneNumber,
      AccountNumber: agent?.AccountNumber,
      PersonalPhoneNumber: agent?.PersonalPhoneNumber,
      PostingLimit: agent?.PostingLimit,
      Email: agent?.Email,
      CommissionAccount: agent?.AccountNumber,
      AllowSubDelegation: agent?.AllowSubDelegation ?? false,
      CummulativeDepositLimit: agent?.CummulativeDepositLimit,
      CummulativeWithdrawalLimit: agent?.CummulativeWithdrawalLimit,
      IncomeAccount: agent?.AccountNumber,
      Username: agent?.Username,
      Location: agent?.Location,
      DateOfBirth: agent?.DateOfBirth,
      BankVerificationNumber: agent?.BankVerificationNumber,
      TaxPayerID: agent?.TaxPayerID,
      MCashAgentFee: agent?.MCashAgentFee,
      WithdrawalLimit: agent?.WithdrawalLimit,
      TransferLimit: agent?.TransferLimit,
      LGA: agent?.LGA,
      LGAID: agent?.LGA?.ID ?? 0,
      StaffID: agent?.StaffID,
      Province: agent?.Province,
      ProvinceID: agent?.Province?.ID ?? 0,
      AccountMode: agent?.AccountMode,
      AgentType: agent.AgentType,
      CardTransactionAmountLimit: agent?.CardTransactionAmountLimit,
      ID: agent?.ID ?? 0,
      IsWallet: agent?.AccountMode === AccountMode[AccountMode.Wallet],
      DomiciliaryBank: agent?.DomiciliaryBank || GetInstitution().name,
      ParentAgentID:
        agent?.AgentType === AgentSection[AgentSection.SubAgent] &&
        agent?.AccountMode !== AccountMode[AccountMode.Wallet]
          ? agent?.ParentAgentID
          : null,
      ParentLastName:
        agent?.AgentType === AgentSection[AgentSection.SubAgent] &&
        agent?.AccountMode !== AccountMode[AccountMode.Wallet]
          ? agent?.ParentLastName
          : null,
      ParentOtherNames:
        agent?.AgentType === AgentSection[AgentSection.SubAgent] &&
        agent?.AccountMode !== AccountMode[AccountMode.Wallet]
          ? agent?.ParentOtherNames
          : null,
      ParentEmail:
        agent?.AgentType === AgentSection[AgentSection.SubAgent] &&
        agent?.AccountMode !== AccountMode[AccountMode.Wallet]
          ? agent?.ParentEmail
          : null,
    }
  }
  const [formValues, setFormValues] = useState<any>({})
  const initialValues = useMemo(() => {
    return generateFormValues({ ...agent, ...formValues })
  }, [agent, formValues])

  if (isUpdate === true && loading) {
    return (
      <Loading>
        <p>Getting Agent ...</p>
      </Loading>
    )
  }

  if (isUpdate === true && Utils.Object.isEmpty(agent)) {
    return (
      <Loading>
        <p>Could not load the agent's details, Please refresh the page</p>
      </Loading>
    )
  }
  return (
    <React.Fragment>
      {!modeActivated ? (
        <AccountModeForm
          {...props}
          setFormValues={setFormValues}
          next={() => setModeActivated(!modeActivated)}
        />
      ) : (
        <div>
          {
            <AgentDetailsForm
              {...props}
              back={() => setModeActivated(!modeActivated)}
              initialValues={initialValues}
            />
          }
        </div>
      )}
    </React.Fragment>
  )
}
AgentForm.propTypes = {
  id: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  details: PropTypes.object,
  onUpdateSelection: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(AgentForm))
