import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Container, Row, Col
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"


//i18n
import { withTranslation } from "react-i18next"
import { batchUploadTerminal, purgeTerminals } from "store/actions"
import BatchUploadTerminalsForm from './form';
import ProcessBatchUploadList from "../view";
import TerminalLogic from "../../batch-upload";
import Instructions from "components/Common/Instruction";
import withDispose from 'components/HOCs/WithDispose';
import GuardedPage from 'components/HOCs/GuardedPage'
import { BATCH_UPLOAD_TERMINAL, EDIT_TERMINAL } from '../../permission'
import useSWR from 'swr'
import { fetcher } from 'helpers/api_helper'
import { GET_ALL_INSTITUTIONS } from 'helpers/url_helper'
const BatchUploadTerminals = props => {
  const { success, editLoading, loading, error, onPurge, onBatchUpload, processResult } = props
  const { ERRORLIST } = TerminalLogic;
  const [errorList, setErrorList] = useState(ERRORLIST);
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [batchUpload, setBatchUpload] = useState({}); //data from form
  const institutions = useSWR(GET_ALL_INSTITUTIONS, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const handleOnChange = (key, value) => {
    let u = batchUpload;
    u[key] = value;
    setBatchUpload(batchUpload);
  }
  const handleValidSubmit = (e) => {
    e.preventDefault();
    onBatchUpload(batchUpload)
  }


  return (
    <GuardedPage permissions={[BATCH_UPLOAD_TERMINAL]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Batch Terminal Upload"}
              breadcrumbItem={"Upload"}
            />

            {!processResult ? <Row>
              <Col lg={6} md={6} sm={12}>
                <Instructions description="Please ensure the file to be uploaded is in a CSV format. Using the headers listed below in the same order" instructions={[{ position: "First", label: "Terminal ID" }, { position: "Second", label: "Mode (EPMS or POSVAS)" }, { position: "Third", label: "Serial Number" }, { position: "Fourth", label: "Merchant Percentage (%)" }]} />
              </Col>
              <Col lg={6} md={6} sm={12}>
                <BatchUploadTerminalsForm
                  // redirectUrl={"/view-batch-uploads"}
                  success={success}
                  error={error}
                  isUpdate={isUpdate}
                  loading={loading}
                  institutions={institutions?.data?.Data}
                  errorList={errorList}
                  isRotating={isRotating}
                  editLoading={editLoading}
                  setErrorList={setErrorList}
                  handleOnChange={handleOnChange}
                  setIsRotating={setIsRotating}
                  handleValidSubmit={handleValidSubmit}
                ></BatchUploadTerminalsForm>
              </Col>
            </Row> :
              <ProcessBatchUploadList
                setErrorList={setErrorList}
                terminals={processResult?.Terminals}
                TheFileBase64={batchUpload.TheFileBase64}
                UseFirstLineAsHeader={batchUpload.UseFirstLineAsHeader}
                InstitutionID={batchUpload.InstitutionID}
                totalCount={processResult?.TotalCount}
                isValidList={processResult?.IsValidList}
                summaryText={processResult?.SummaryText}
              />
            }
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>)
}



BatchUploadTerminals.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onBatchUpload: PropTypes.func,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({ terminals: { processResult, error, success, editLoading, totalCount, loading } }) => {
  return {
    error,
    success,
    processResult,
    totalCount,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onBatchUpload: (data) => dispatch(batchUploadTerminal(data)),
  onPurge: () => dispatch(purgeTerminals())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(BatchUploadTerminals))))








