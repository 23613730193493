import { GET_STATES, GET_STATES_FAIL, GET_STATES_SUCCESS } from "./actionTypes"

export const getStates = () => ({
  type: GET_STATES,
})

export const getStatesSuccess = states => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})
