import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import { addCommissionStructure, getAllCashOutInstitutions } from "store/actions"
import CommissionStructureForm from "./form"
import CommissionStructureLogic from "../../commission-structure"
import withDispose from "components/HOCs/WithDispose"
import { GET_ALL_CASHOUT_INSTITUTIONS } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import useSWR from "swr"
const AddCommissionStructure:React.FC<any> = props => {
  const {
    onAddCommissionStructure,
    categoryId,
    categoryName,
    setCategory,
    success,
    editLoading,
    loading,
    error,
  } = props
  const { ERRORLIST } = CommissionStructureLogic
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [structure, setStructure] = useState({}) //data from form

  const handleOnChange = (key, value) => {
    let u = structure
    u[key] = value
    setStructure(u)
  }
  const handleValidSubmit = e => {
    
    onAddCommissionStructure({ ...e, AgentCategoryID: categoryId })
  }

  const { data, isValidating } = useSWR(GET_ALL_CASHOUT_INSTITUTIONS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs
          title={"Commission Structure"}
          image={clusterian}
          breadcrumbItem={"Setup Commission Structure"}
          prepend={
            <i
              className={"bx bx-arrow-back"}
              style={{
                marginRight: "10px",
                fontSize: "2em",
                cursor: "pointer",
              }}
              color={"secondary"}
              onClick={e => {
                e.preventDefault()
                setCategory(undefined)
              }}
            ></i>
          }
        />

        <div style={{ textAlign: "center" }}>
          <small>Agent Category</small>
          <h3 className="m-1">{categoryName}</h3>
        </div>
        <CommissionStructureForm
          // redirectUrl={"/view-commissionStructures"}
          success={success}
          error={error}
          loading={loading}
          errorList={errorList}
          editLoading={editLoading}
          setErrorList={setErrorList}
          handleOnChange={handleOnChange}
          handleValidSubmit={handleValidSubmit}
          cashOutInstitutions={(data as any)?.Data}
          values={structure}
        ></CommissionStructureForm>
        
      </Container>
    </React.Fragment>
  )
}

AddCommissionStructure.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onAddCommissionStructure: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onBack: PropTypes.func,
  agentDetail: PropTypes.object,
  commissionStructures: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  commissionStructures: {
    commissionStructures,
    error,
    success,
    agentDetail,
    editLoading,
    totalCount,
    loading,
  },
  networkManagers: { networkManagers },
  clusterManagers: { clusterManagers },
  categories: { categories },
  cashOutInstitutions: { cashOutInstitutions }
}) => {
  return {
    commissionStructures,
    error,
    success,
    agentDetail: agentDetail,
    totalCount,
    cashOutInstitutions,
    loading,
    editLoading,
    clusterManagers,
    networkManagers,
    categories,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddCommissionStructure: structure =>
    dispatch(addCommissionStructure(structure)),
  onGetAllCashOutInstitutions: () => dispatch(getAllCashOutInstitutions())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withDispose(AddCommissionStructure)))
