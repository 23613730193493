import {
  GET_CASHOUT_INSTITUTIONS,
  GET_CASHOUT_INSTITUTION_DETAIL,
  GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS,
  GET_CASHOUT_INSTITUTION_DETAIL_FAIL,
  GET_CASHOUT_INSTITUTIONS_SUCCESS,
  GET_CASHOUT_INSTITUTIONS_FAIL,
  CREATE_CASHOUT_INSTITUTION,
  POST_CASHOUT_INSTITUTION_SUCCESS,
  CASHOUT_INSTITUTION_PURGE,
  CASHOUT_INSTITUTION_PURGE_SUCCESS,
  POST_CASHOUT_INSTITUTION_FAIL,
  UPDATE_CASHOUT_INSTITUTION,
  UPDATE_CASHOUT_INSTITUTION_SUCCESS,
  GET_ALL_CASHOUT_INSTITUTIONS,
  ENABLE_CASHOUT_INSTITUTION,
  ENABLE_CASHOUT_INSTITUTION_SUCCESS,
  PURGE_CASHOUT_INSTITUTIONS,
  PURGE_CASHOUT_INSTITUTIONS_SUCCESS
} from "./actionTypes"

export const getCashOutInstitutions = params => ({
  type: GET_CASHOUT_INSTITUTIONS,
  params,
})

export const getAllCashOutInstitutions = () => ({
  type: GET_ALL_CASHOUT_INSTITUTIONS,
})
export const getCashOutInstitutionsSuccess = cashOutInstitutions => ({
  type: GET_CASHOUT_INSTITUTIONS_SUCCESS,
  payload: cashOutInstitutions,
})
export const getCashOutInstitutionsFail = error => ({
  type: GET_CASHOUT_INSTITUTIONS_FAIL,
  payload: error,
})
export const getCashOutInstitutionDetail = id => ({
  type: GET_CASHOUT_INSTITUTION_DETAIL,
  id,
})

export const getCashOutInstitutionDetailSuccess = cashOutInstitutions => ({
  type: GET_CASHOUT_INSTITUTION_DETAIL_SUCCESS,
  payload: cashOutInstitutions,
})

export const getCashOutInstitutionDetailFail = error => ({
  type: GET_CASHOUT_INSTITUTION_DETAIL_FAIL,
  payload: error,
})

export const createCashOutInstitution = cashOutInstitution => {
  return {
    type: CREATE_CASHOUT_INSTITUTION,
    payload: { cashOutInstitution },
  }
}

export const cashOutInstitutionApiError = error => {
  return {
    type: POST_CASHOUT_INSTITUTION_FAIL,
    payload: error,
  }
}

export const postCashOutInstitutionSuccess = cashOutInstitution => {
  return {
    type: POST_CASHOUT_INSTITUTION_SUCCESS,
    payload: cashOutInstitution,
  }
}

export const cashOutInstitutionPurge = () => ({
  type: CASHOUT_INSTITUTION_PURGE,
})

export const cashOutInstitutionPurgeSuccess = () => ({
  type: CASHOUT_INSTITUTION_PURGE_SUCCESS,
})

export const enableCashOutInstitution = (id, enable) => {
  return {
    type: ENABLE_CASHOUT_INSTITUTION,
    id,
    enable,
  }
}

export const enableCashOutInstitutionSuccess = cashOutInstitution => {
  return {
    type: ENABLE_CASHOUT_INSTITUTION_SUCCESS,
    payload: cashOutInstitution,
  }
}

export const updateCashOutInstitution = cashOutInstitution => {
  return {
    type: UPDATE_CASHOUT_INSTITUTION,
    payload: { cashOutInstitution },
  }
}
export const updateCashOutInstitutionSuccess = cashOutInstitution => {
  return {
    type: UPDATE_CASHOUT_INSTITUTION_SUCCESS,
    payload: cashOutInstitution,
  }
}
export const purgeCashOutInstitutions = () => {
  return {
    type: PURGE_CASHOUT_INSTITUTIONS,
  }
}

export const purgeCashOutInstitutionSuccess = () => {
  return {
    type: PURGE_CASHOUT_INSTITUTIONS_SUCCESS,
  }
}
