import { TransactionTypes } from "enums/transaction-types"
import { Utils } from "helpers/utility"
import {
  APPLICABILITY,
  COMMISSIONTYPE,
  COSTBEARER,
  FEETYPE,
  Format,
  PAYMENTMODE,
  PAYMENTSTATUS,
  SEARCHOPTIONS,
  TRANSACTIONTYPENAMES,
} from "helpers/variables"
import moment from "moment"
import { DropdownOptions } from "types/dropdown-options"
const HEADERS = [
  { title: "Action", name: "action", isAction: true },
  { title: "Name", name: "agentName", entityKeyName: "Name" },
  {
    title: "Applicability",
    name: "applicability",
    entityKeyName: "Applicability",
  },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionType",
  },
  {
    title: "Issuer",
    name: "issuer",
    entityKeyName: "IssuingCashOutInstitution?.BankCode",
  },
  {
    title: "Commission Type",
    name: "commissionType",
    entityKeyName: "CommissionType",
  },
  { title: "Flat Amount", name: "flatAmount", entityKeyName: "FlatAmount" },
  { title: "Fee Type", name: "feeType", entityKeyName: "FeeType" },
  { title: "Percentage", name: "percentage", entityKeyName: "Percentage" },
  { title: "VAT", name: "vat", entityKeyName: "VAT" },
  { title: "Agent Share", name: "agentShare", entityKeyName: "AgentShare" },
  { title: "ANM Share", name: "anmShare", entityKeyName: "ANMShare" },
  { title: "Bank Share", name: "bankShare", entityKeyName: "BankShare" },
  {
    title: "Service Provider Share",
    name: "serviceProviderShare",
    entityKeyName: "ServiceProviderShare",
  },
  { title: "Payment Mode", name: "paymentMode", entityKeyName: "PaymentMode" },
  {
    title: "Bank Income Account",
    name: "bankIncomeAccount",
    entityKeyName: "BankIncomeAccount",
  },
  { title: "Minimum Fee", name: "minimumFee", entityKeyName: "MinimumFee" },
  {
    title: "Minimum Amount",
    name: "minimumAmount",
    entityKeyName: "MinimumAmount",
  },
  { title: "Maximum Fee", name: "maximumFee", entityKeyName: "MaximumFee" },
  {
    title: "Maximum Service Provider Fee",
    name: "maximumServiceProviderFee",
    entityKeyName: "MaximumServiceProviderFee",
  },
  {
    title: "Maximum Amount",
    name: "maximumAmount",
    entityKeyName: "MaximumAmount",
  },
  { title: "Cost Bearer", name: "costBearer", entityKeyName: "Bearer" },
  { title: "Summary", name: "summary", entityKeyName: "Summary" },
]
let ERRORLIST = {
  name: undefined,
  applicability: undefined,
  transactionType: undefined,
  commissionType: undefined,
  flatAmount: "",
  feeType: undefined,
  percentage: "",
  vat: "",
  vatAccount: "",
  agentShare: "",
  anmShare: "",
  bankShare: undefined,
  serviceProviderShare: undefined,
  paymentMode: undefined,
  bankIncomeAccount: undefined,
  minimumFee: "",
  minimumAmount: "",
  maximumFee: "",
  maximumAmount: "",
  costBearer: undefined,
}

const UpdateErrorList = () => {
  let list = { ...ERRORLIST }
  for (let key in list) {
    list[key] = ""
  }
  return list
}
const SearchOptions = (startDate, endDate) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "text",
      label: "Agent Name",
      id: "s-agentName",
      defaultValue: "",
      name: "agentName",
    },
    {
      type: "text",
      label: "Agent Code",
      id: "s-agentCode",
      defaultValue: "",
      name: "agentCode",
    },
    {
      type: "text",
      label: "Agent Phone Number",
      id: "s-agentPhoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "dropdown",
      label: "Payment Status",
      id: "paymentStatus",
      defaultValue: "- Select Status -",
      data: PAYMENTSTATUS,
      name: "paymentStatus",
      width: 3,
    },
  ]
  return options
}
const MapToRows = commission => {
  let isPercentage = commission.FeeType == "Percentage"
  return {
    id: commission.ID,
    agentName: commission.Name,
    applicability: Utils.Strings.camelPad(commission.Applicability),
    transactionType: Utils.Strings.camelPad(commission.TransactionType),
    commissionType: Utils.Strings.camelPad(commission.CommissionType),
    flatAmount: Utils.Format.currency(commission.FlatAmount),
    feeType: Utils.Strings.camelPad(commission.FeeType),
    percentage: Utils.Format.percentage(commission.Percentage),
    vat: Utils.Format.percentage(commission.VAT),
    issuer: commission.IssuingCashOutInstitution?.BankCode,
    agentShare: isPercentage
      ? Utils.Format.percentage(commission.AgentShare)
      : Utils.Format.currency(commission.AgentShare),
    anmShare: isPercentage
      ? Utils.Format.percentage(commission.ANMShare)
      : Utils.Format.currency(commission.ANMShare),
    bankShare: isPercentage
      ? Utils.Format.percentage(commission.BankShare)
      : Utils.Format.currency(commission.BankShare),
    serviceProviderShare: isPercentage
      ? Utils.Format.percentage(commission.ServiceProviderShare)
      : Utils.Format.currency(commission.ServiceProviderShare),
    paymentMode: Utils.Strings.camelPad(commission.PaymentMode),
    bankIncomeAccount: commission.BankIncomeAccount,
    minimumFee: Utils.Format.currency(commission.MinimumFee),
    minimumAmount: Utils.Format.currency(commission.MinimumAmount),
    maximumFee: Utils.Format.currency(commission.MaximumFee),
    maximumServiceProviderFee: Utils.Format.currency(
      commission.MaximumServiceProviderFee ?? 0
    ),
    maximumAmount: Utils.Format.currency(commission.MaximumAmount),
    costBearer: commission.Bearer,
    summary: commission.Summary,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    Name: params["agentName"],
    AgentCode: params["agentCode"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    PaymentStatus: params["paymentStatus"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
type ModalStateItem = {
  type: string
  label: string
  id: string
  defaultValue?: any
  data?: any[]
  name: string
  width?: number
}
type ModalState = {
  title: String
  items: ModalStateItem[]
}
const MapToModalState = (structure: any, cashOutInstitutions: any = []) => {
  const options: ModalState[] = [
    {
      title: "Basic Details",
      items: [
        {
          type: "text",
          label: "Name",
          id: "s-name",
          defaultValue: structure.Name,
          name: "name",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Commission Type",
          id: "commissionType",
          defaultValue: structure.CommissionType,
          data: COMMISSIONTYPE,
          name: "commissionType",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Transaction Type",
          id: "transactionType",
          defaultValue: structure.TransactionType,
          data: TRANSACTIONTYPENAMES,
          name: "transactionType",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Cost Bearer",
          id: "bearer",
          defaultValue: structure.Bearer,
          data: COSTBEARER,
          name: "bearer",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Applicability",
          id: "applicability",
          defaultValue: structure.Applicability,
          data: APPLICABILITY,
          name: "applicability",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Payment Mode",
          id: "paymentMode",
          defaultValue: structure.PaymentMode,
          data: PAYMENTMODE,
          name: "paymentMode",
          width: 6,
        },
        {
          type: "number",
          label: "VAT",
          id: "s-vat",
          defaultValue: structure.VAT,
          name: "vat",
          width: 6,
        },
      ],
    },
    {
      title: "Fee Sharing Details",
      items: [
        {
          type: "dropdown",
          label: "Fee Type",
          id: "feeType",
          defaultValue: structure.FeeType,
          data: FEETYPE,
          name: "feeType",
          width: 6,
        },
        {
          type: "number",
          label: "Flat Amout",
          id: "s-flatAmount",
          defaultValue: structure.FlatAmount,
          name: "flatAmount",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Transaction Type",
          id: "transactionType",
          defaultValue: structure.TransactionType,
          data: TRANSACTIONTYPENAMES,
          name: "transactionType",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Cost Bearer",
          id: "bearer",
          defaultValue: structure.Bearer,
          data: COSTBEARER,
          name: "bearer",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Applicability",
          id: "applicability",
          defaultValue: structure.Applicability,
          data: APPLICABILITY,
          name: "applicability",
          width: 6,
        },
        {
          type: "dropdown",
          label: "Payment Mode",
          id: "paymentMode",
          defaultValue: structure.PaymentMode,
          data: PAYMENTMODE,
          name: "paymentMode",
          width: 6,
        },
      ],
    },
  ]

  if (
    Utils.Enums.compare(
      TransactionTypes,
      structure.TransactionType,
      TransactionTypes.POSAgentCashOut
    )
  ) {
    options[0].items.push({
      type: "dropdown",
      label: "Issuer",
      id: "issuer",
      defaultValue: cashOutInstitutions.find(
        inst => inst.ID == structure.IssuingCashOutInstitution?.ID
      )?.BankCode,
      data: MapCashOutInstitutionToDropdown(cashOutInstitutions),
      name: "issuer",
      width: 6,
    })
  }

  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}

const MapCashOutInstitutionToDropdown = (data): DropdownOptions[] => {
  if (data instanceof Array) {
    const filteredData = data.filter(
      inst =>
        inst.BankMode == "ThirdParty" &&
        !inst.BankCode.includes("APP") &&
        !inst.BankCode.includes("GAC") &&
        inst.IsActive
    )
    return filteredData.map(institution => ({
      id: institution.ID,
      key: institution.ID,
      value: institution.ID,
      name: institution.BankCode,
    }))
  }
  return []
}
export default {
  HEADERS,
  ERRORLIST,
  MapToModalState,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  UpdateErrorList,
  MapCashOutInstitutionToDropdown,
}
