import {
  GET_TERMINALS,
  GET_TERMINAL_DETAIL,
  GET_TERMINAL_DETAIL_SUCCESS,
  GET_TERMINAL_DETAIL_FAIL,
  GET_TERMINALS_SUCCESS,
  GET_TERMINALS_FAIL,
  GET_ALL_TERMINALS,
  CREATE_TERMINAL,
  POST_TERMINAL_SUCCESS,
  PURGE_TERMINALS,
  PURGE_TERMINALS_SUCCESS,
  POST_TERMINAL_FAIL,
  UPDATE_TERMINAL,
  UPDATE_TERMINAL_SUCCESS,
  UPDATE_TERMINAL_FAIL,
  ENABLE_TERMINAL,
  ENABLE_TERMINAL_SUCCESS,

  GET_ASSIGNED_TERMINALS,
  GET_ASSIGNED_TERMINALS_SUCCESS,
  GET_ASSIGNED_TERMINALS_FAIL,
  SAVE_ASSIGNED_TERMINAL_CONFIGURATION,
  POST_ASSIGNED_TERMINAL_SUCCESS,
  POST_ASSIGNED_TERMINAL_FAIL,
  GET_ALL_ASSIGNED_TERMINALS,
  ADD_ASSIGNED_TERMINAL,
  REMOVE_ASSIGNED_TERMINAL,
  ADD_ASSIGNED_TERMINAL_SUCCESS,
  REMOVE_ASSIGNED_TERMINAL_SUCCESS,
  UPDATE_ASSIGNED_TERMINAL_SUCCESS,
  UPDATE_ASSIGNED_TERMINAL,
  BATCH_UPLOAD_TERMINAL,
  BATCH_UPLOAD_TERMINAL_SUCCESS,
  PROCESS_UPLOAD_TERMINAL,
  PROCESS_UPLOAD_TERMINAL_SUCCESS,
  TERMINAL_ASSIGNMENT_REPORT,
  TERMINAL_ASSIGNMENT_REPORT_SUCCESS,
  TERMINAL_ASSIGNMENT_REPORT_FAIL
} from "./actionTypes"

export const getTerminals = (params) => ({
  type: GET_TERMINALS,
  params
})
export const getAllTerminals = () => ({
  type: GET_ALL_TERMINALS
})
export const getTerminalsSuccess = terminals => ({
  type: GET_TERMINALS_SUCCESS,
  payload: terminals,
})
export const getTerminalsFail = error => ({
  type: GET_TERMINALS_FAIL,
  payload: error,
})
export const terminalAssignmentReport = (params) => {
  return {
    type: TERMINAL_ASSIGNMENT_REPORT,
    params
  }
}
export const terminalAssignmentReportSuccess = report => {
  return {
    type: TERMINAL_ASSIGNMENT_REPORT_SUCCESS,
    payload: report,
  }
}
export const terminalAssignmentReportFail = error => {
  return {
    type: TERMINAL_ASSIGNMENT_REPORT_FAIL,
    payload: error,
  }
}
export const getTerminalDetail = id => ({
  type: GET_TERMINAL_DETAIL,
  id,
})

export const getTerminalDetailSuccess = terminals => ({
  type: GET_TERMINAL_DETAIL_SUCCESS,
  payload: terminals,
})

export const getTerminalDetailFail = error => ({
  type: GET_TERMINAL_DETAIL_FAIL,
  payload: error,
})


export const createTerminal = (terminal) => {
  return {
    type: CREATE_TERMINAL,
    payload: { terminal },
  }
}

export const terminalApiError = error => {
  return {
    type: POST_TERMINAL_FAIL,
    payload: error,
  }
}

export const postTerminalSuccess = terminal => {
  return {
    type: POST_TERMINAL_SUCCESS,
    payload: terminal,
  }
}


export const enableTerminal = (id, enable) => {
  return {
    type: ENABLE_TERMINAL,
    id, enable
  }
}

export const enableTerminalSuccess = terminal => {
  return {
    type: ENABLE_TERMINAL_SUCCESS,
    payload: terminal,
  }
}

export const updateTerminal = (terminal) => {
  return {
    type: UPDATE_TERMINAL,
    payload: { terminal },
  }
}
export const updateTerminalSuccess = terminal => {
  return {
    type: UPDATE_TERMINAL_SUCCESS,
    payload: terminal,
  }
}



export const batchUploadTerminal = (terminal) => {
  return {
    type: BATCH_UPLOAD_TERMINAL,
    payload: { terminal },
  }
}
export const batchUploadTerminalSuccess = terminal => {
  return {
    type: BATCH_UPLOAD_TERMINAL_SUCCESS,
    payload: terminal,
  }
}


export const processUploadTerminal = (terminal) => {
  return {
    type: PROCESS_UPLOAD_TERMINAL,
    payload: { terminal },
  }
}
export const processUploadTerminalSuccess = terminal => {
  return {
    type: PROCESS_UPLOAD_TERMINAL_SUCCESS,
    payload: terminal,
  }
}

export const getAssignedTerminals = params => ({
  type: GET_ASSIGNED_TERMINALS,
  params,
})
export const getAllAssignedTerminals = () => ({
  type: GET_ALL_ASSIGNED_TERMINALS,
})
export const getAssignedTerminalsByCategory = categoryId => ({
  type: GET_ASSIGNED_TERMINALS_BY_CATEGORY,
  categoryId,
})
export const getAssignedTerminalsSuccess = assignedTerminals => ({
  type: GET_ASSIGNED_TERMINALS_SUCCESS,
  payload: assignedTerminals,
})
export const getAssignedTerminalsFail = error => ({
  type: GET_ASSIGNED_TERMINALS_FAIL,
  payload: error,
})

export const saveAssignedTerminals = assignedTerminals => {
  return {
    type: SAVE_ASSIGNED_TERMINAL_CONFIGURATION,
    payload: { assignedTerminals },
  }
}

export const addAssignedTerminal = assignedTerminal => {
  return {
    type: ADD_ASSIGNED_TERMINAL,
    payload: assignedTerminal,
  }
}
export const addAssignedTerminalSuccess = assignedTerminal => ({
  type: ADD_ASSIGNED_TERMINAL_SUCCESS,
  payload: assignedTerminal,
})
export const removeAssignedTerminal = assignedTerminalID => {
  return {
    type: REMOVE_ASSIGNED_TERMINAL,
    payload: assignedTerminalID,
  }
}
export const removeAssignedTerminalSuccess = assignedTerminal => ({
  type: REMOVE_ASSIGNED_TERMINAL_SUCCESS,
  payload: assignedTerminal,
})
export const assignedTerminalApiError = error => {
  return {
    type: POST_ASSIGNED_TERMINAL_FAIL,
    payload: error,
  }
}

export const postAssignedTerminalSuccess = assignedTerminal => {
  return {
    type: POST_ASSIGNED_TERMINAL_SUCCESS,
    payload: assignedTerminal,
  }
}


export const updateAssignedTerminal = assignedTerminal => {
  return {
    type: UPDATE_ASSIGNED_TERMINAL,
    payload: { assignedTerminal },
  }
}
export const updateAssignedTerminalSuccess = assignedTerminal => {
  return {
    type: UPDATE_ASSIGNED_TERMINAL_SUCCESS,
    payload: assignedTerminal,
  }
}


export const purgeTerminals = () => {
  return {
    type: PURGE_TERMINALS
  }
}

export const purgeTerminalSuccess = () => {
  return {
    type: PURGE_TERMINALS_SUCCESS
  }
}