 let HEADERS = [
  { title: "Name", name: "name" },
  { title: "Description", name: "description" },
  { title: "Code", name: "code" },
  { title: "Scope", name: "scope" },
  { title: "Status", name: "status", isBadge: true },
  { title: "Action", name: "action", isAction: true },
]

 const MapToRows = r => {
  return {
    id: r.id,
    name: r.name,
    description: r.description,
    code: r.code,
    status: {
      label: r.status,
      badgeClass: r.status == "Active" ? "success" : "secondary",
    },
    scope: r.scope,
    institution: r.InstitutionName,
    userPermissions: r.permissions,
    sub: r.scopeItem,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
   
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
export default {
  HEADERS,
  // MapToModalState,
  SearchCriteria,
  MapToRows,
  // SearchOptions,
  // MapToDropDown,
}
