export enum Icons{
    File = "bxs-file",
    Folder="bxs-folder",
    Customize="bxs-customize",
    Widget="bxs-widget",
    Plus="bx-plus",
    Search="bx-search-alt",
    Select="bx bx-selection",
    Money="bx bx-money"
}
export {}