/* COMMISSION_STRUCTURES */
export const GET_COMMISSION_STRUCTURES = "GET_COMMISSION_STRUCTURES"
export const GET_ALL_COMMISSION_STRUCTURES = "GET_ALL_COMMISSION_STRUCTURES"
export const GET_COMMISSION_STRUCTURES_BY_CATEGORY =
  "GET_COMMISSION_STRUCTURES_BY_CATEGORY"
export const GET_COMMISSION_STRUCTURES_SUCCESS =
  "GET_COMMISSION_STRUCTURES_SUCCESS"
export const GET_COMMISSION_STRUCTURES_FAIL = "GET_COMMISSION_STRUCTURES_FAIL"

export const GET_COMMISSION_STRUCTURE_DETAIL = "GET_COMMISSION_STRUCTURE_DETAIL"
export const GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS =
  "GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS"
export const GET_COMMISSION_STRUCTURE_DETAIL_FAIL =
  "GET_COMMISSION_STRUCTURE_DETAIL_FAIL"

export const SAVE_STRUCTURE_CONFIGURATION = "SAVE_STRUCTURE_CONFIGURATION"
export const ADD_COMMISSION_STRUCTURE = "ADD_COMMISSION_STRUCTURE"
export const ADD_COMMISSION_STRUCTURE_SUCCESS =
  "ADD_COMMISSION_STRUCTURE_SUCCESS"

export const REMOVE_COMMISSION_STRUCTURE = "REMOVE_COMMISSION_STRUCTURE"
export const REMOVE_COMMISSION_STRUCTURE_SUCCESS =
  "REMOVE_COMMISSION_STRUCTURE_SUCCESS"

export const POST_COMMISSION_STRUCTURE_SUCCESS =
  "POST_COMMISSION_STRUCTURE_SUCCESS"
export const POST_COMMISSION_STRUCTURE_FAIL = "POST_COMMISSION_STRUCTURE_FAIL"

export const COMMISSION_STRUCTURE_PURGE = "COMMISSION_STRUCTURE_PURGE"
export const COMMISSION_STRUCTURE_PURGE_SUCCESS =
  "COMMISSION_STRUCTURE_PURGE_SUCCESS"

export const UPDATE_COMMISSION_STRUCTURE = "UPDATE_COMMISSION_STRUCTURE"
export const UPDATE_COMMISSION_STRUCTURE_SUCCESS =
  "UPDATE_COMMISSION_STRUCTURE_SUCCESS"
export const UPDATE_COMMISSION_STRUCTURE_FAIL =
  "UPDATE_COMMISSION_STRUCTURE_FAIL"

export const ENABLE_COMMISSION_STRUCTURE = "ENABLE_COMMISSION_STRUCTURE"
export const ENABLE_COMMISSION_STRUCTURE_SUCCESS =
  "ENABLE_COMMISSION_STRUCTURE_SUCCESS"
