import {
  POST_DEBIT_REQUESTS,
  POST_DEBIT_REQUESTS_SUCCESS,
  POST_DEBIT_REQUESTS_FAIL,
  PATCH_DEBIT_REQUESTS_APPROVAL,
  PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS,
  PATCH_DEBIT_REQUESTS_APPROVAL_FAIL,
  GET_DEBIT_REQUESTS,
  GET_DEBIT_REQUESTS_DETAIL,
  GET_DEBIT_REQUESTS_DETAIL_FAIL,
  GET_DEBIT_REQUESTS_DETAIL_SUCCESS,
  GET_DEBIT_REQUESTS_FAIL,
  GET_DEBIT_REQUESTS_SUCCESS,
} from "./actionTypes"

export const postDebitRequests = payload => ({
  type: POST_DEBIT_REQUESTS,
  payload,
})
export const postDebitRequestSuccess = payload => ({
  type: POST_DEBIT_REQUESTS_SUCCESS,
  payload,
})
export const postDebitRequestFail = payload => ({
  type: POST_DEBIT_REQUESTS_FAIL,
  payload,
})
export const patchDebitRequestsApproval = (id, approve) => ({
  type: PATCH_DEBIT_REQUESTS_APPROVAL,
  id,
  approve
})
export const patchDebitRequestsApprovalSuccess = id => ({
  type: PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS,
  id
})
export const patchDebitRequestsApprovalFail = error => ({
  type: PATCH_DEBIT_REQUESTS_APPROVAL_FAIL,
  payload : error
})
export const getDebitRequests = params => ({
  type: GET_DEBIT_REQUESTS,
  params,
})
export const getDebitRequestDetail = id => ({
  type: GET_DEBIT_REQUESTS_DETAIL,
  id,
})
export const getDebitRequestsSuccess = debitrequests => ({
  type: GET_DEBIT_REQUESTS_SUCCESS,
  payload: debitrequests,
})
export const getDebitRequestsFail = error => ({
  type: GET_DEBIT_REQUESTS_FAIL,
  payload: error,
})

export const getDebitRequestDetailSuccess = debitrequests => ({
  type: GET_DEBIT_REQUESTS_DETAIL_SUCCESS,
  payload: debitrequests,
})
export const getDebitRequestDetailFail = error => ({
  type: GET_DEBIT_REQUESTS_DETAIL_FAIL,
  payload: error,
})
