import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import { terminalAssignmentReport, purgeTerminals } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import TerminalAssignmentReportLogic from "../terminal-assignment-report"
import { TERMINAL_ASSIGNMENT_EXPORT, TERMINAL_ASSIGNMENT_REPORT } from "helpers/url_helper"
import { toast } from "react-toastify"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"


const TerminalList = props => {
  const {
    assignmentReport,
    onGetReport,
    error,
    success,
    reportTotalCount,
    loading
  } = props

  const { HEADERS, MapToRows, SearchCriteria, SearchOptions } = TerminalAssignmentReportLogic;
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetReport, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const { institutionsData, institutions } = useInstitutionsData()
  
  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate, institutionsData))

  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
 
  useEffect(() => {
    populateRows()
  }, [assignmentReport])

  useEffect(() => {

    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  
  const populateRows = () => {
    if (assignmentReport) {
      let rws = []
      assignmentReport.forEach(cluster => {
        let row = MapToRows(cluster);
        rws.push(row)
      })

      setRows(rws)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"POS Terminal Assignment Report"} breadcrumbItem={"View report"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: reportTotalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{ url: TERMINAL_ASSIGNMENT_EXPORT, query: SearchCriteria(params, maxSize) }}
              />
            </Col>
          </Row>
        </Container>
      </div>
     
    </React.Fragment>
  )
}

TerminalList.propTypes = {
  assignmentReport: PropTypes.array,
  onGetReport: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  reportTotalCount: PropTypes.number,
}

const mapStateToProps = ({
  terminals: { assignmentReport, error,success, reportTotalCount, loading, statusChanged, editLoading },
}) => {
  // console.debug(assignmentReport, error, clusterDetail, totalCount, loading)
  return {
    assignmentReport,
    error,
    reportTotalCount,
    success,
    loading, statusChanged, editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onGetReport: query => dispatch(terminalAssignmentReport(query)),
  onPurge: () => dispatch(purgeTerminals())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(TerminalList))))
