import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Badge,
} from "reactstrap"

import ClusterInput from "components/Common/Input/Input"
import ClusterInformation from "components/Common/Information/Information"
import FunctionList from "../function-Item/index"
import { CLUSTERMANAGER, GLOBAL, NETWORKMANAGER } from "helpers/variables"

import roleImg from "../../../../assets/images/icons/role.png"
import cluster from "../../../../assets/images/icons/cluster.png"
import networkmanagers from "../../../../assets/images/icons/network.png"
import almighty from "../../../../assets/images/icons/saturn.png"

import { EDIT_USER_ROLE, ENABLE_USER_ROLES } from "../permission"
import { toTitleCase } from "helpers/utility"
import Loading from "components/Common/Loading"
import {
  GET_ALL_CLUSTER_MANAGERS,
  GET_ALL_NETWORK_MANAGERS,
} from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import ClusterButton from "components/Common/Button/Button"
import useSWR from "swr"
const RoleModal = props => {
  let { isOpen, viewedRole, onEnableRole, editLoading, setModal, isEnabled } =
    props
  const [scopeLoading, setScopeLoading] = useState(false)
  const toggleOpen = () => {
    setModal(!isOpen)
  }

  const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [scopeItem, setScopeItem] = useState()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (viewedRole) {
      setTimeout(() => setLoading(false), 1000)
    }
  }, [viewedRole])

  useEffect(() => {
    if (viewedRole && viewedRole.scopeItemId) {
      if (viewedRole.scope == "Cluster Manager") {
        setScopeItem(
          clusterManagers.data?.Data.find(c => c.ID == viewedRole.scopeItemId)
            ?.Name
        )
      } else if (viewedRole.scope == "Network Manager") {
        setScopeItem(
          networkManagers.data?.Data.find(c => c.ID == viewedRole.scopeItemId)
            ?.Name
        )
      }
    }
  }, [viewedRole, clusterManagers, networkManagers])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        toggleOpen()
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            toggleOpen()
          }}
        >
          User Role Details
          {viewedRole ? (
            <Badge
              style={{ marginLeft: "10px" }}
              className={
                "font-size-12 badge-soft-" + isEnabled ? "success" : "secondary"
              }
              color={isEnabled ? "success" : "secondary"}
              pill
            >
              {isEnabled ? "Active" : "Inactive"}
            </Badge>
          ) : (
            <React.Fragment></React.Fragment>
          )}
        </ModalHeader>
        {loading ? (
          <ModalBody>
            {" "}
            <Loading></Loading>
          </ModalBody>
        ) : (
          <React.Fragment>
            {" "}
            <ModalBody>
              {/* <Row>
                <Col lg={12}>
                  <div
                    style={{ width: "15%", minWidth: "30px", margin: "auto" }}
                  >
                    <img src={roleImg} />
                  </div>
                </Col>
              </Row> */}
              {viewedRole ? (
                <Row>
                  <Col lg={12} md={12}>
                    <Card
                      style={{
                        background: "transparent",
                        borderColor: "transparent",
                      }}
                    >
                      <CardBody>
                        <Row>
                          <Col lg={12} md={6}>
                            <ClusterInput
                              defaultValue={viewedRole.name}
                              disabled
                              label={"Name"}
                              id={"name"}
                              name={"name"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={12} md={6}>
                            <ClusterInput
                              defaultValue={viewedRole.description}
                              disabled
                              label={"Description"}
                              id={"description"}
                              name={"description"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={12} md={6}>
                            <ClusterInput
                              defaultValue={viewedRole.code}
                              disabled
                              label={"Code"}
                              id={"code"}
                              name={"code"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                          <Col lg={12} md={6}>
                            <ClusterInput
                              defaultValue={viewedRole.scope}
                              disabled
                              label={"Scope"}
                              id={"scope"}
                              name={"scope"}
                              type="text"
                            ></ClusterInput>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12} md={12} sm={12}>
                    <FunctionList
                      disabled
                      scope={viewedRole?.scope.replace(" ", "")}
                      type="view-details"
                      items={viewedRole.permission?.functionItems}
                      id={props.match.params.id}
                    ></FunctionList>
                  </Col>
                </Row>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </ModalBody>
            <ModalFooter>
              <ClusterButton
                loading={editLoading}
                permissions={[ENABLE_USER_ROLES]}
                type="button"
                outline
                onHandleClick={() =>
                  onEnableRole(
                    viewedRole?.id,
                    !(viewedRole?.status == "Active")
                  )
                }
                color="secondary"
              >
                {viewedRole?.status == "Active" ? "Disable" : "Enable"}
              </ClusterButton>
              <Link to={"/create-user-role/" + viewedRole?.id}>
                <ClusterButton
                  permissions={[EDIT_USER_ROLE]}
                  type="button"
                  color="primary"
                >
                  Edit
                </ClusterButton>
              </Link>
              <ClusterButton
                type="button"
                color="secondary"
                onHandleClick={() => {
                  toggleOpen()
                }}
              >
                Close
              </ClusterButton>
            </ModalFooter>
          </React.Fragment>
        )}
      </div>
    </Modal>
  )
}
RoleModal.propTypes = {
  isOpen: PropTypes.bool,
  viewedRole: PropTypes.object,
  setModal: PropTypes.func,
  onEnableRole: PropTypes.func,
  editLoading: PropTypes.bool,
  isEnabled: PropTypes.bool,
}
export default withRouter(RoleModal)
