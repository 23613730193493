import { defaultObj } from "helpers/utility"
import {
  GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS,
  GET_COMMISSION_STRUCTURE_DETAIL_FAIL,
  GET_COMMISSION_STRUCTURES_SUCCESS,
  GET_COMMISSION_STRUCTURES_FAIL,
  COMMISSION_STRUCTURE_PURGE,
  ENABLE_COMMISSION_STRUCTURE,
  ENABLE_COMMISSION_STRUCTURE_SUCCESS,
  COMMISSION_STRUCTURE_PURGE_SUCCESS,
  GET_COMMISSION_STRUCTURES,
  POST_COMMISSION_STRUCTURE_SUCCESS,
  UPDATE_COMMISSION_STRUCTURE,
  SAVE_STRUCTURE_CONFIGURATION,
  UPDATE_COMMISSION_STRUCTURE_SUCCESS,
  POST_COMMISSION_STRUCTURE_FAIL,
  GET_ALL_COMMISSION_STRUCTURES,
  GET_COMMISSION_STRUCTURES_BY_CATEGORY,
  ADD_COMMISSION_STRUCTURE,
  REMOVE_COMMISSION_STRUCTURE,
  ADD_COMMISSION_STRUCTURE_SUCCESS,
  REMOVE_COMMISSION_STRUCTURE_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  commissionStructures: [],
  commissionStructureDetail: {},
  structuresToModify: [],
  error: undefined,
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  deleteLoading: false,
  statusChanged: undefined,
  isStructureConfigured : 1
}

const AgentCommissionStructures = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_COMMISSION_STRUCTURE:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
      }
    case REMOVE_COMMISSION_STRUCTURE:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
        deleteLoading: true,
      }
    case ADD_COMMISSION_STRUCTURE_SUCCESS:
      let error //= state.commissionStructures.find(s => s.Name == action.payload.Name) ? "A commission structure with the same name already exists" : ""
      let success = !state.commissionStructures.find(
        s => s.Name == action.payload.Name
      )
        ? "Successfully Added"
        : ""
      let structuresToModify = [...state.structuresToModify]
      let commissionStructures = [...state.commissionStructures]
      if (action.payload) {
        if (!commissionStructures.find(s => s.Name == action.payload.Name)) {
          structuresToModify.push({ ...action.payload, flag: "add" })
          commissionStructures.push(action.payload)
        } else {
          success = action.payload.Name + " Updated"
          commissionStructures.map(c => {
            if (c.Name == action.payload.Name) {
              c = action.payload
            }
            return c
          })
          structuresToModify.map(c => {
            if (c.Name == action.payload.Name) {
              c = action.payload
            }
            return c
          })
        }
      }
      return {
        ...state,
        structuresToModify,
        commissionStructures,
        editLoading: false,
        success,
        error,
      }
    case REMOVE_COMMISSION_STRUCTURE_SUCCESS:
      let removeError = !state.commissionStructures.find(
        s => s.Name == action.payload.Name
      )
        ? "Already removed"
        : ""
      let removeSuccess = state.commissionStructures.find(
        s => s.Name == action.payload.Name
      )
        ? "Successfully removed"
        : ""
      let modifiedStructures = [...state.structuresToModify] ?? []
      let structures = [...state.commissionStructures]

      if (action.payload) {
        structures = structures.filter(s => s.Name != action.payload.Name)
        if (modifiedStructures.find(s => s.Name == action.payload.Name)) {
          modifiedStructures = modifiedStructures.filter(
            m => m.name != action.payload.Name
          )
        } else {
          modifiedStructures.push({ ...action.payload, flag: "delete" })
        }
      }
      return {
        ...state,
        structuresToModify: modifiedStructures,
        commissionStructures: structures,
        editLoading: false,
        success: removeSuccess,
        error: removeError,
        deleteLoading: false,
      }
    case GET_COMMISSION_STRUCTURES_SUCCESS:
      return {
        ...state,
        commissionStructures: action.payload?.Data || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_COMMISSION_STRUCTURES:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_COMMISSION_STRUCTURES:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMISSION_STRUCTURES_BY_CATEGORY:
      return {
        ...state,
        loading: true,
      }
    case GET_COMMISSION_STRUCTURES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_COMMISSION_STRUCTURE_DETAIL_SUCCESS:
      return {
        ...state,
        commissionStructureDetail: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
      }

    case GET_COMMISSION_STRUCTURE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_COMMISSION_STRUCTURE_SUCCESS:
      return {
        ...state,
        commissionStructure: defaultObj(state.commissionStructure), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
        isStructureConfigured : 1
      }
    case UPDATE_COMMISSION_STRUCTURE:
      return {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
      }

    case SAVE_STRUCTURE_CONFIGURATION:
      state = {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
        isStructureConfigured : 0
      }
      break
    case POST_COMMISSION_STRUCTURE_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        success: "",
        loading: false,
        deleteLoading: false,
        editLoading: false,
      }
      break

    case UPDATE_COMMISSION_STRUCTURE_SUCCESS:
      let updatedStructures = state.commissionStructures
      let updatedStructureDetail = state.commissionStructureDetail
      if (action.payload?.Data) {
        updatedStructures = updatedStructures.map(s => {
          if (s.ID == action.payload.Data.ID) {
            s = action.payload.Data
          }
          return s
        })
      }
      state = {
        ...state,
        commissionStructures: updatedStructures,
        commissionStructureDetail: updatedStructureDetail,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_COMMISSION_STRUCTURE:
      state = {
        ...state,
        editLoading: true,
      }
      break
    case ENABLE_COMMISSION_STRUCTURE_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        statusChanged: action.payload,
      }
    case COMMISSION_STRUCTURE_PURGE_SUCCESS:
      return INIT_STATE

    default:
      return state
  }
  return state
}

export default AgentCommissionStructures
