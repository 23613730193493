/* MOBILEACCOUNTS */
export const GET_MOBILEACCOUNTS = "GET_MOBILEACCOUNTS"
export const GET_ALL_MOBILEACCOUNTS = "GET_ALL_MOBILEACCOUNTS"
export const GET_MOBILEACCOUNTS_SUCCESS = "GET_MOBILEACCOUNTS_SUCCESS"
export const GET_MOBILEACCOUNTS_FAIL = "GET_MOBILEACCOUNTS_FAIL"

export const GET_MOBILEACCOUNT_DETAIL = "GET_MOBILEACCOUNT_DETAIL"
export const GET_MOBILEACCOUNT_DETAIL_SUCCESS =
  "GET_MOBILEACCOUNT_DETAIL_SUCCESS"
export const GET_MOBILEACCOUNT_DETAIL_FAIL = "GET_MOBILEACCOUNT_DETAIL_FAIL"

export const GET_MOBILEWALLETS = "GET_MOBILEWALLETS"
export const GET_ALL_MOBILEWALLETS = "GET_ALL_MOBILEWALLETS"
export const GET_MOBILEWALLETS_SUCCESS = "GET_MOBILEWALLETS_SUCCESS"
export const GET_MOBILEWALLETS_FAIL = "GET_MOBILEWALLETS_FAIL"

export const GET_MOBILEWALLET_DETAIL = "GET_MOBILEWALLET_DETAIL"
export const GET_MOBILEWALLET_DETAIL_SUCCESS = "GET_MOBILEWALLET_DETAIL_SUCCESS"
export const GET_MOBILEWALLET_DETAIL_FAIL = "GET_MOBILEWALLET_DETAIL_FAIL"

export const GET_MOBILEACCOUNT_BY_PHONE = "GET_MOBILEACCOUNT_BY_PHONE"
export const GET_MOBILEACCOUNT_BY_PHONE_SUCCESS =
  "GET_MOBILEACCOUNT_BY_PHONE_SUCCESS"
export const GET_MOBILEACCOUNT_BY_PHONE_FAIL = "GET_MOBILEACCOUNT_BY_PHONE_FAIL"

export const CREATE_MOBILEACCOUNT = "CREATE_MOBILEACCOUNT"
export const POST_MOBILEACCOUNT_SUCCESS = "POST_MOBILEACCOUNT_SUCCESS"
export const POST_MOBILEACCOUNT_FAIL = "POST_MOBILEACCOUNT_FAIL"

export const MOBILEACCOUNT_PURGE = "MOBILEACCOUNT_PURGE"
export const MOBILEACCOUNT_PURGE_SUCCESS = "MOBILEACCOUNT_PURGE_SUCCESS"

export const UPDATE_MOBILEACCOUNT = "UPDATE_MOBILEACCOUNT"
export const UPDATE_MOBILEACCOUNT_SUCCESS = "UPDATE_MOBILEACCOUNT_SUCCESS"
export const UPDATE_MOBILEACCOUNT_FAIL = "UPDATE_MOBILEACCOUNT_FAIL"

export const ENABLE_MOBILEACCOUNT = "ENABLE_MOBILEACCOUNT"
export const ENABLE_MOBILEACCOUNT_SUCCESS = "ENABLE_MOBILEACCOUNT_SUCCESS"

export const HOTLIST = "HOTLIST"
export const HOTLIST_SUCCESS = "HOTLIST_SUCCESS"
export const HOTLIST_FAIL = "HOTLIST_FAIL"

export const RESET_PIN = "RESET_PIN"
export const RESET_PIN_SUCCESS = "RESET_PIN_SUCCESS"
export const RESET_PIN_FAIL = "RESET_PIN_FAIL"

export const RESET_PIN_TRIES = "RESET_PIN_TRIES"
export const RESET_PIN_TRIES_SUCCESS = "RESET_PIN_TRIES_SUCCESS"
export const RESET_PIN_TRIES_FAIL = "RESET_PIN_TRIES_FAIL"

export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"

export const RESET_PASSWORD_TRIES = "RESET_PASSWORD_TRIES"
export const RESET_PASSWORD_TRIES_SUCCESS = "RESET_PASSWORD_TRIES_SUCCESS"
export const RESET_PASSWORD_TRIES_FAIL = "RESET_PASSWORD_TRIES_FAIL"

export const GENERATE_VERIFICATION_CODE = "GENERATE_VERIFICATION_CODE"
export const GENERATE_VERIFICATION_CODE_SUCCESS =
  "GENERATE_VERIFICATION_CODE_SUCCESS"
export const GENERATE_VERIFICATION_CODE_FAIL = "GENERATE_VERIFICATION_CODE_FAIL"

export const SEND_ACTIVATION_CODE = "SEND_ACTIVATION_CODE"
export const SEND_ACTIVATION_CODE_SUCCESS = "SEND_ACTIVATION_CODE_SUCCESS"
export const SEND_ACTIVATION_CODE_FAIL = "SEND_ACTIVATION_CODE_FAIL"
