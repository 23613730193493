import PropTypes from "prop-types"
import React, { useState } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody } from "reactstrap"
// images
import ClusterInput from "components/Common/Input/Input"
import ClusterTextArea from "components/Common/TextArea"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"
import ClusterButton from "components/Common/Button/Button"
import OptionsForm from "./options-form"
import withFormValidation from "components/HOCs/WithFormValidation"
import SubscriptionLogic from "../volume-covenant";
const SubscriptionPlanForm = props => {
  let {
    isFormInvalid,
    errorList,
    setErrorList,
    handleOnChange,
    editLoading,
    handleOptionsOnChange,
    handleValidSubmit,
    handleValidOptionsSubmit } = props

  const { OPTIONS_ERRORLIST } = SubscriptionLogic
  const [errorOptionsList, setOptionsErrorList] = useState(OPTIONS_ERRORLIST)
  return (
    <React.Fragment>
      <ClusterForm handleSubmit={handleValidSubmit}>

        <React.Fragment>
          <Card
            outline
            style={{ background: "transparent", borderColor: "#999999" }}
            className="border"
          >
            <CardBody>
              <Row>

                <Col lg={4} md={6}>
                  <ClusterInput
                    onError={err =>
                      setErrorList({ ...errorList, name: err })
                    }
                    label={"Name"}
                    id={"name"}
                    name={"Name"}
                    validators={[
                      Validators.minLength(3, "Minimum of 3 characters"),
                    ]}
                    type="text"
                    required={true}
                    onHandleChange={(key, value) =>
                      handleOnChange(key, value)
                    }
                  ></ClusterInput>
                </Col>

                <Col lg={4} md={6}>
                  <ClusterInput
                    onError={err =>
                      setErrorList({ ...errorList, feeAccountNumber: err })
                    }
                    label={"Fee Account Number"}
                    id={"feeAccountNumber"}
                    name={"FeeAccountNumber"}
                    validators={[
                      Validators.accountNumber(["Account numbers should contain only numbers", "Account numbers must be 10 digits"]),
                    ]}
                    type="text"
                    onHandleChange={(key, value) =>
                      handleOnChange(key, value)
                    }
                  ></ClusterInput>
                </Col>


                <Col lg={4} md={6}>
                  <ClusterInput
                    onError={err =>
                      setErrorList({ ...errorList, fee: err })
                    }
                    label={"Fee"}
                    id={"fee"}
                    name={"Fee"}
                    type="number"
                    required={true}
                    onHandleChange={(key, value) =>
                      handleOnChange(key, value)
                    }
                  ></ClusterInput>
                </Col>

                <Col lg={4} md={6}>

                  <ClusterTextArea
                    onError={err =>
                      setErrorList({ ...errorList, description: err })
                    }
                    label={"Description"}
                    id={"description"}
                    name={"Description"}
                    validators={[
                      Validators.maxLength(
                        100,
                        "Maximum number of characters is 100"
                      ),
                      Validators.minLength(
                        4,
                        "Minimum number of characters is 4"
                      ),
                    ]}
                    type="text"
                    required={true}
                    onHandleChange={(key, value) =>
                      handleOnChange(key, value)
                    }
                  ></ClusterTextArea>
                </Col>
              </Row>

              <OptionsForm
                errorList={errorOptionsList}
                setErrorList={setOptionsErrorList}
                handleOptionsSubmit={handleValidOptionsSubmit}
                handleOnChange={handleOptionsOnChange}
              />


            </CardBody>
          </Card>
        </React.Fragment>
        <ClusterButton
          className={"btn-lg"}
          buttonStyle={{
            width: "100%",
            marginTop: "10px",
            justifyContent: "center",
            height: "4em",
          }}
          color={"success"}
          loading={editLoading}
          disabled={isFormInvalid}

          addon={"bx bx-save"}
          type={"button"}
          onHandleClick={handleValidSubmit}

        >
          Save Plan
        </ClusterButton>
      </ClusterForm>


    </React.Fragment>
  )
}
SubscriptionPlanForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  scopeLoading: PropTypes.bool,
  agentDetails: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withFormValidation(withRouter(
  SubscriptionPlanForm)
)
