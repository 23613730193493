import {
  GET_BIN_ROUTES,
  GET_BINS,
  GET_BIN_ROUTES_SUCCESS,
  GET_BIN_ROUTES_FAIL,
  GET_BINS_SUCCESS,
  GET_BINS_FAIL,
  CONFIGURE_BIN_ROUTE,
  CONFIGURE_BIN_ROUTE_FAIL,
  CONFIGURE_BIN_ROUTE_SUCCESS,
  BIN_ROUTE_PURGE,
  BIN_ROUTE_PURGE_SUCCESS,
  REMOVE_BIN_ROUTE,
  REMOVE_BIN_ROUTE_SUCCESS,
  GET_ROUTES,
  GET_ROUTES_SUCCESS,
  GET_ROUTES_FAIL,
  GET_BIN_ROUTE_DETAILS,
  GET_BIN_ROUTE_DETAILS_FAIL,
  GET_BIN_ROUTE_DETAILS_SUCCESS
} from "./actionTypes"

export const getBinRoutes = (params) => ({
  type: GET_BIN_ROUTES,
  params
})
export const getBinRoutesSuccess = binRoutes => ({
  type: GET_BIN_ROUTES_SUCCESS,
  payload: binRoutes,
})
export const getBinRoutesFail = error => ({
  type: GET_BIN_ROUTES_FAIL,
  payload: error,
})


export const getBinRouteDetails = (params) => ({
  type: GET_BIN_ROUTE_DETAILS,
  params
})
export const getBinRouteDetailsSuccess = binRoutes => ({
  type: GET_BIN_ROUTE_DETAILS_SUCCESS,
  payload: binRoutes,
})
export const getBinRouteDetailsFail = error => ({
  type: GET_BIN_ROUTE_DETAILS_FAIL,
  payload: error,
})

export const getBins = (bin) => ({
  type: GET_BINS,
  bin
})
export const getBinsSuccess = bins => ({
  type: GET_BINS_SUCCESS,
  payload: bins,
})
export const getBinsFail = error => ({
  type: GET_BINS_FAIL,
  payload: error,
})
export const getRoutes = () => ({
  type: GET_ROUTES
})

export const getRoutesSuccess = routes => ({
  type: GET_ROUTES_SUCCESS,
  payload: routes,
})

export const getRoutesFail = error => ({
  type: GET_ROUTES_FAIL,
  payload: error,
})


export const configureBinRoutes = (binRoutes) => {
  return {
    type: CONFIGURE_BIN_ROUTE,
    payload: { binRoutes },
  }
}

export const binRoutesApiError = error => {
  return {
    type: CONFIGURE_BIN_ROUTE_FAIL,
    payload: error,
  }
}

export const postBinRoutesSuccess = binRoutes => {
  return {
    type: CONFIGURE_BIN_ROUTE_SUCCESS,
    payload: binRoutes,
  }
}

export const binRoutePurge = () => ({
  type: BIN_ROUTE_PURGE
})

export const binRoutePurgeSuccess = () => ({
  type: BIN_ROUTE_PURGE_SUCCESS
})



export const removeBinRoute = (routeDetailId) => {
  return {
    type: REMOVE_BIN_ROUTE,
    payload: { routeDetailId },
  }
}
export const removeBinRouteSuccess = binRoute => {
  return {
    type: REMOVE_BIN_ROUTE_SUCCESS,
    payload: binRoute,
  }
}