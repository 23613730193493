import {
  GET_PERMISSIONS_SUCCESS,
  GET_PERMISSIONS_FAIL,
  GET_FUNCTION_MENU_FAIL,
  GET_FUNCTION_MENU_SUCCESS,
  GET_FUNCTIONS_BY_USER_SUCCESS,
  GET_FUNCTIONS_BY_USER_FAIL,
  GET_FUNCTION_ACTIONS_SUCCESS,
  GET_FUNCTION_ACTIONS_FAIL,
  GET_FUNCTIONS_SUCCESS,
  GET_FUNCTIONS_FAIL,
  GET_PERMISSIONS,
  GET_FUNCTION_MENU,
  GET_FUNCTION_ACTIONS,
  GET_FUNCTIONS_BY_USER,
  GET_FUNCTIONS,
} from "./actionTypes"

const INIT_STATE = {
  permissions: [],
  error: {},
  menus: [],
  functions: [],
  functionsForUser: [],
  actions: [],
  success: undefined,
  loading: true,
  functionsLoading: true,
  menuLoading: false,
}

const Permissions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PERMISSIONS:
      return {
        ...state,
        loading: true,
        menuLoading: false,
      }
    case GET_PERMISSIONS_SUCCESS:
      return {
        ...state,
        permissions: action.payload,
        loading: false,
        menuLoading: false,
      }
    case GET_PERMISSIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        menuLoading: false,
      }
    case GET_FUNCTION_MENU:
      return {
        ...state,
        menuLoading: true,
      }
    case GET_FUNCTION_MENU_SUCCESS:
      return {
        ...state,
        menus: action.payload,
        loading: false,
        menuLoading: false,
      }
    case GET_FUNCTION_MENU_FAIL:
      return {
        ...state,
        error: action.payload,
        menuLoading: false,
      }
    case GET_FUNCTION_ACTIONS:
      return {
        ...state,
        loading: true,
        menuLoading: false,
      }
    case GET_FUNCTION_ACTIONS_SUCCESS:
      return {
        ...state,
        actions: action.payload,
        loading: false,
        menuLoading: false,
      }
    case GET_FUNCTION_ACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        menuLoading: false,
      }
    case GET_FUNCTIONS_BY_USER:
      return {
        ...state,
        functionsLoading: true,
        menuLoading: false,
      }
    case GET_FUNCTIONS_BY_USER_SUCCESS:
      return {
        ...state,
        functionsForUser: action.payload,
        functionsLoading: false,
        menuLoading: false,
      }
    case GET_FUNCTIONS_BY_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        functionsLoading: false,
        menuLoading: false,
      }
    case GET_FUNCTIONS:
      return {
        ...state,
        functionsLoading: true,
        menuLoading: false,
      }
    case GET_FUNCTIONS_SUCCESS:
      return {
        ...state,
        functions: action.payload,
        functionsLoading: false,
        menuLoading: false,
      }
    case GET_FUNCTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        functionsLoading: false,
        menuLoading: false,
      }
    default:
      return state
  }
}

export default Permissions
