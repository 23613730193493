import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"
import { CardSubtitle } from "reactstrap"
const NoData = props => {
  return (
    // <Row >
    <div style={{ textAlign: "center", marginTop: "30px" }}>
      <Row
        style={{
          fontSize: "3em",
          fontWeight: "lighter",
          margin: "15px auto",
          justifyContent: "center",
        }}
      >
        <i className="bx bx-search"></i>
      </Row>
      <CardSubtitle className="mb-4">No results found</CardSubtitle>
    </div>
    // </Row>
  )
}

NoData.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default NoData
