import toastr from "toastr"
import moment from "moment"
import { GetInstitution } from "./authentication/institution"
import { DateFormat } from "enums/date-format"
import { SortingType } from "enums/sorting-type"
import { NO_VALUE } from "constants/strings"
import { Symbols } from "constants/symbols"
import { getAuthUser } from "./authentication/auth-token-header"
export class Utils {
  static arrayBufferToBase64=(buffer: ArrayBuffer): string=> {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
  static hasChanged = (prevValue: any, value: any) => {

    if (!prevValue && value) return true;
    if (!value && prevValue) return true;
    if (typeof value == "object") {
      if (Array.isArray(value)) {
        if (Utils.List.isEmpty(prevValue) && !Utils.List.isEmpty(value)) return true;
        if (Utils.List.isEmpty(value) && !Utils.List.isEmpty(prevValue)) return true;
        return !Utils.List.equals(prevValue, value)
      } else {
        if (Utils.Object.isEmpty(prevValue) && !Utils.Object.isEmpty(value)) return true;
        if (Utils.Object.isEmpty(value) && !Utils.Object.isEmpty(prevValue)) return true;
        return !Utils.Object.equals(prevValue, value)
      }
    }
    return prevValue !== value
  }

  static Strings = {
    toTitleCase: str => {
      if (!str) return str
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      })
    },
    toCurrency: (amount: string) => {
      return this.Format.currency(parseFloat(amount))
    },
    camelPad: (str) => {
      if (!str) return str;
      return str.toString()
        // Look for long acronyms and filter out the last letter
        .replace(/([A-Z]+)([A-Z][a-z])/g, ' $1 $2')
        // Look for lower-case letters followed by upper-case letters
        .replace(/([a-z\d])([A-Z])/g, '$1 $2')
        // Look for lower-case letters followed by numbers
        .replace(/([a-zA-Z])(\d)/g, '$1 $2')
        .replace(/^./, function (str) { return str.toUpperCase(); })
        // Remove any white space left around the word
        .trim();
    }
  }
  static Enums = {
    toOptions: (enumType: any): any[] => {
      let keys = Object.keys(enumType);
      const isNumberEnum: boolean = keys.some((k: any) => typeof enumType[k] === "number");
      keys = isNumberEnum ? keys.filter((k: any) => typeof enumType[k] === "number") : keys;
      return keys.map((key, i) => ({ key: i, id: enumType[key], value: enumType[key], name: key })).sort(compare("name"))

    },
    compare: (enumType: any, first: any, second: any): boolean => {
      if (first == undefined || second == undefined) return false;
      if (typeof first === typeof second) return first == second;
      if (typeof first === "string") return enumType[second] == first;
      return enumType[first] == second;
    },
    toString: (enumType, enumKey) => {
      return enumType[enumType[enumKey]];
    }
  }
  static Object = {
    isEmpty: (list: any): boolean => {
      return !list || Object.keys(list).length == 0
    },
    is: (obj) => typeof obj === 'object',
    equals: (o1, o2) => {
      const isAnyValueEmpty = (!o1 || !o2);
      if (isAnyValueEmpty) return false;
      const isEqual = (typeof o1 === 'object' && typeof o2 === 'object' && Object.keys(o1).length > 0
        ? Object.keys(o1).length === Object.keys(o2).length
        && JSON.stringify(o1) === JSON.stringify(o2) : o1 === o2);
      return isEqual;
    }
  }
  static List = {
    sort: (list: any[], key: string, type: SortingType): any[] => {
      const lessThan = type == SortingType.Asc ? -1 : 1;
      const greaterThan = (-lessThan);
      return list.sort((a, b) => {
        if (!a[key]) return 0
        if (a[key] < b[key]) {
          return lessThan
        }
        if (a[key] > b[key]) {
          return greaterThan
        }
        return 0
      });
    },
    isEmpty: (list: any[]): boolean => {
      return !list || list.length == 0
    },
    equals: (a1, a2) => (a1.length === a2.length && a1.every((o, idx) => Utils.Object.equals(o, a2[idx]))),
    tryAdd: (list: any[], value: any, comparativeKey?: string | number) => {
      let listClone = Object.assign([], list);
      if (!listClone) return listClone;
      const itemInList = listClone.find(item => comparativeKey ? item[comparativeKey] == value[comparativeKey] : item == value);

      if (!itemInList) listClone.push(value)
      return listClone;
    },
    tryRemove: (list: any[], value: any, comparativeKey?: string | number) => {
      const itemInList = list.find(item => comparativeKey ? item[comparativeKey] == value[comparativeKey] : item == value);

      if (itemInList) list = list.filter(item => comparativeKey ? item[comparativeKey] != value[comparativeKey] : item != value);
      return list;
    }
  }
  static Date = {
    format: (date, format: DateFormat = DateFormat.Default): string => {
      return moment(date).format(format);
    },
    timeToDate: (time: string): Date => {
      const date = moment();
      const timeSplit = time.split(":");
      const hours = +timeSplit[0];
      const minutes = +timeSplit[1];

      return date.set("hour", hours).set("minute", minutes).toDate();
    },
    hrToDate: (hour: number): Date => {
      const date = moment();

      return date.set("hour", hour).set("minute", 0).toDate();
    },
    hrFromTime: (time: string): number => {
      const timeSplit = time.split(":");
      console.log(+timeSplit[0])
      return +timeSplit[0];
    },
    minutesFromTime: (time: string): number => {
      const timeSplit = time.split(":");
      return +timeSplit[1];
    },
    isToday: (date: string): boolean => {
      return moment(date).isSameOrAfter(moment(), "day");
    }

  }
  static Boolean = {

    IsBoolean: (x) => x === false || x === true,
    Convert: (x) => Utils.Boolean.IsBoolean(x) ? x : !x ? false : true
  }
  static Format = {
    currency: (amount: number): string => {
      return Symbols.NAIRA + new Intl.NumberFormat("en-US", { style: "currency", "currency": "USD" }).format(amount).replace(".00", "").replace("$", "")

    },
    percentage: (value: number): string => {
      if (!value) return NO_VALUE;
      return value + Symbols.PERCENT
    },
    // date: (date, format: DateFormat = DateFormat.Default): string => {
    //   return moment(date).format(format);
    // },
    // timeToDate:(time:string):Date=>{
    //   const date = moment();
    //   const timeSplit = time.split(":");
    //   const hours = +timeSplit[0];
    //   const minutes = +timeSplit[1];

    //   return date.set("hour", hours).set("minute", minutes).toDate();
    // }

  }
  static triggerInputChange = (node, enteredValue) => {

    const lastValue = node.value;

    node.value = enteredValue;

    const event = new Event("input", { bubbles: true });

    const tracker = node._valueTracker;

    if (tracker) {
      tracker.setValue(lastValue);
    }

    node.dispatchEvent(event);

  }
}
const format = (n, currency = true) => {
  let value = currency
    ? n.toLocaleString("en-US", { style: "currency", currency: "NGN" })
    : n
  return value
}
export const mapColor = color => {
  return {
    dark: "#343a40",
    grey: "#ADB5BD",
    primary: "#F49800",
    pink: "#CB2C63",
    "light-pink": "#FCE4EC",
    blue: "#01579B",
    "light-blue": "#E1F5FE",
    teal: "#409089",
    "light-teal": "#E0F2F1",
    green: "#81AC37",
    "light-green": "#E8F5E9",
    orange: "#ED722E",
    "light-orange": "#FBE9E7",
    purple: "#6C41B5",
    "light-purple": "#EDE7F6",
    white: "FFFFFF"
  }[color]
}


export const compare = key => {
  return (a, b) => {
    if (!a[key]) return 0
    if (a[key] < b[key]) {
      return -1
    }
    if (a[key] > b[key]) {
      return 1
    }
    return 0
  }
}

export const compareMultiple = (keys: any[], i = 0) => {
  return (a, b) => {
    if (a[keys[i]] === b[keys[i]]) {
      // Price is only important when cities are the same
      return b.SwitchingRoute - a.SwitchingRoute;
    }
    return a[keys[i]] > b[keys[i]] ? 1 : -1;
  }
}
export const toFixedDown = (digits = 0) => {
  const re = new RegExp(`(\\d+\\.\\d{${digits}})(\\d)`)
  const m = digits?.toString().match(re)
  const l = m ? parseFloat(m[1]) : digits?.valueOf()
  return l
}
const decimalControl = value => {
  // let decimal = value.toString().split('.')[1];
  // if(decimal==="00"){value=value.split('.')[0];}
  return value.toString().replace(".00", "")
}
export const formatGraphDataForDisplay = (data, field, startDate, endDate) => {
  return (data?.analytics[field]?.dataProvider?.nodes || [])
    .filter(x => {
      if (
        moment(x.xValue).startOf("day").format("YYYY-MM-DDTHH:mm:ss") >=
        moment(startDate).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
      )
        return x
    })
    .map(({ xValue, yValue, transactionTypeName }) => ({
      xValue,
      yValue: yValue, //new Date(yValue)//isValidDate(yValue)? new Date(yValue):yValue,
      transactionTypeName
    }))
    .sort(function (a, b) {
      if (moment(a.xValue) == moment(b.xValue)) return 0
      if (moment(a.xValue) < moment(b.xValue)) return -1
      if (moment(a.xValue) > moment(b.xValue)) return 1
    })
}

export const dataFormatter = number => {
  let value = ""
  if (number > 1000000000) {
    value = Math.ceil(number / 1000000000).toString() + "B"
  } else if (number > 1000000) {
    value = (number / 1000000).toString() + "M"
  } else if (number > 1000) {
    value = (number / 1000).toString() + "K"
  } else {
    value = number.toString()
  }
}
export const formatter = (n, currency = false, short = false) => {
  if (short) {
    if (n < 1e3) return format(n, currency)
    if (n >= 1e3 && n < 1e6)
      return format(toFixedDown(+(n / 1e3)), currency) + "K"
    if (n >= 1e6 && n < 1e9)
      return format(toFixedDown(+(n / 1e6)), currency) + "M"
    if (n >= 1e9 && n < 1e12)
      return format(toFixedDown(+(n / 1e9)), currency) + "B"
    if (n >= 1e12) return format(toFixedDown(+(n / 1e12)), currency) + "T"
  }
  if (!n) {
    let v = "--"
    if (currency == true) v = "₦ " + v
    return v
  }
  let value = format(n, currency)

  return decimalControl(value).toString().replace("NGN", "₦")
}

export const toTitleCase = str => {
  if (!str) return str
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export function showToast(title, message, toastType) {
  // const ele = document.getElementsByName("toastType")
  // const position = document.getElementsByName("positions")
  //     let toastType = "success"
  //     // const title = document.getElementById("title").value
  //     let message = "Have fun storming the castle!"
  // let title = "Trends"
  // if (document.getElementById("message").value !== "")
  //   message = document.getElementById("message").value

  // //Close Button
  // const closeButton = document.getElementById("closeButton").checked

  // //Debug
  // const debug = document.getElementById("debugInfo").checked

  // //Progressbar
  // const progressBar = document.getElementById("progressBar").checked

  // //Duplicates
  // const preventDuplicates = document.getElementById("preventDuplicates").checked

  // //Newest on Top
  // const newestOnTop = document.getElementById("newestOnTop").checked

  //position class
  let positionClass = "toast-top-right"

  //Fetch position
  // for (let p = 0; p < position.length; p++) {
  //   if (position[p].checked) positionClass = position[p].value
  // }

  //Show Easing
  const showEasing = "swing" //document.getElementById("showEasing").value

  //Hide Easing
  const hideEasing = "linear" //document.getElementById("hideEasing").value

  //show method
  const showMethod = "fadeIn" //document.getElementById("showMethod").value

  //Hide method
  const hideMethod = "fadeIn" // document.getElementById("hideMethod").value

  //show duration
  const showDuration = 300 //document.getElementById("showDuration").value

  //Hide duration
  const hideDuration = 1000 //document.getElementById("hideDuration").value

  //timeout
  const timeOut = 5000 // document.getElementById("timeOut").value

  //extended timeout
  const extendedTimeOut = 1000 // document.getElementById("extendedTimeOut").value

  //Fetch checked Type
  // for (let i = 0; i < ele.length; i++) {
  //   if (ele[i].checked) toastType = ele[i].value
  // }

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    debug: false,
    progressBar: true,
    preventDuplicates: true,
    newestOnTop: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  }

  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (toastType === "info") toastr.info(message, title)
  else if (toastType === "warning") toastr.warning(message, title)
  else if (toastType === "error") toastr.error(message, title)
  else toastr.success(message, title)
}

export const getFunctions = scope => {
  return {
    default: [

      {
        scope: "Global",
        title: "Users",
        canAdd: true,
        isChecked: false,
        children: [
          { title: "View Users", canAdd: true, isChecked: false, icon: "show" },
          {
            title: "Add User",
            canAdd: true,
            isChecked: false,
            icon: "plus-circle",
          },
          {
            title: "Edit User",
            canAdd: true,
            isChecked: false,
            icon: "highlight",
          },
          {
            title: "Delete User",
            canAdd: true,
            isChecked: false,
            icon: "x-circle",
          },
        ],
      },
      {
        scope: "",
        title: "User Roles",
        canAdd: true,
        isChecked: false,
        children: [
          {
            title: "View User Roles",
            canAdd: true,
            isChecked: false,
            icon: "show",
          },
          {
            title: "Add User Role",
            canAdd: true,
            isChecked: false,
            icon: "plus-circle",
          },
          {
            title: "Edit User Role",
            canAdd: true,
            isChecked: false,
            icon: "highlight",
          },
          {
            title: "Delete User Role",
            canAdd: true,
            isChecked: false,
            icon: "x-circle",
          },
        ],
      },
    ],
    more: [
      {
        scope: "",
        title: "Agents",
        canAdd: true,
        isChecked: false,
        children: [
          {
            title: "View Agents",
            canAdd: true,
            isChecked: false,
            icon: "show",
          },
          {
            title: "Add Agents",
            canAdd: true,
            isChecked: false,
            icon: "plus-circle",
          },
          {
            title: "Edit Agent",
            canAdd: true,
            isChecked: false,
            icon: "highlight",
          },
          {
            title: "Delete Agent",
            canAdd: true,
            isChecked: false,
            icon: "x-circle",
          },
        ],
      },
      {
        scope: "",
        title: "Reports",
        canAdd: true,
        isChecked: false,
        children: [
          { title: "View Transaction Receipt", canAdd: true, isChecked: false },
          {
            title: "View Agent Summary Report",
            canAdd: true,
            isChecked: false,
          },
        ],
      },
    ],
  }
}

export const autoGenerateCode = (prefix, maxNumber) => {
  const randomNumber = moment().unix()
  // * Math.random();
  return prefix + "_" + randomNumber
}

export const isProd = () => {
  return process.env.REACT_APP_NODE_ENV == "production"
}
export const getInstitution = () => {
  return GetInstitution().remoteInstitutionId
}

export const getActiveUser = () => {
  return JSON.parse(getAuthUser())
}

export const defaultObj = obj => {
  for (let prop in obj) {
    let value = obj[prop]
    if (typeof value == "number") obj[prop] = 0
    if (typeof value == "object") {
      if (Array.isArray(value)) {
        obj[prop] = []
      } else {
        obj[prop] = null
      }
    }
    if (typeof value == "boolean") obj[prop] = false
    if (typeof value == "string") obj[prop] = ""
  }
  return obj
}
