import PropTypes from "prop-types"
import React, { } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardTitle, CardBody, FormGroup, Label } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import Loading from "components/Common/Loading"
import ClusterForm from "components/Common/Form"
import CategoryLogic from "../category"
import ClusterCheckBox from "components/Common/CheckBox"
import ClusterTextArea from "components/Common/TextArea";
import AccordionToggle, { Toggler } from "components/Common/Accordion/Toggle";
import withFormValidation from "components/HOCs/WithFormValidation"
import { Icons } from "enums/icons"
import { Formik } from "formik"
import AgentCategoryValidator from "./validator"
import { EsusuFundsControlStatus } from "enums/esusu-fund-control-status"
import { Utils } from "helpers/utility"
import { UserType } from "enums/user-type"
const AgentCategoryForm = props => {
  const {
    isUpdate,
    features,
    editLoading,
    institutionFeatures,
    categories,
    loading,
    featureLoading,
    details,
    handleValidSubmit
  } = props

  const { MapToDropDown, MapFeatures, Initiate } = CategoryLogic;

  if (isUpdate && Utils.Object.isEmpty(details)) {
    return <Loading>
      <p>Getting Agent Category...</p>
    </Loading>
  }

  if (!institutionFeatures) return <Loading>Getting available institution features</Loading>
  return (
    <React.Fragment>
      <Formik
        initialValues={Initiate(details, institutionFeatures, isUpdate)}
        validate={AgentCategoryValidator}
        onSubmit={(values, { setSubmitting }) => {

          handleValidSubmit({ ...values, Features: values.FeatureIds })
        }}
      >
        {({
          values,
          errors,
          touched,
          isValid,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <ClusterForm handleSubmit={handleSubmit}>

            <React.Fragment>
              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={3} md={3} sm={6}>
                      <ClusterInput
                        defaultValue={values?.Name}
                        focus
                        label={"Name"}
                        id={"name"}
                        name={"Name"}
                        value={values?.Name}
                        type="text"
                        required={true}
                        validationError={errors.Name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      ></ClusterInput>
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <Label>Parent</Label>
                        {!categories ? (
                          <Loading></Loading>
                        ) : (
                          <ClusterDropdown
                            id={"parentId"}
                            name={"ParentID"}
                            defaultValue={
                              categories.find(c => c.ID == values?.ParentID)
                                ?.Name
                            }
                            placeholder={"-Select-"}
                            value={categories.find(c => c.ID == values?.ParentID)
                              ?.Name}
                            items={MapToDropDown(categories).filter(c => c.id != details?.ID)}
                            onChange={(name, value) => {
                              setFieldValue("ParentID", value)
                            }}
                          ></ClusterDropdown>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={3} md={3} sm={12}>
                      <ClusterTextArea
                        defaultValue={values?.Description}
                        value={values?.Description}
                        required={true}
                        onChange={handleChange}
                        label={"Description"}
                        id={"description"}
                        name={"Description"}

                      />
                    </Col>
                    <Col lg={3} md={3} sm={6}>
                      <FormGroup>
                        <Label>Type</Label>

                        <ClusterDropdown
                          id={"userType"}
                          name={"UserType"}
                          defaultValue={
                            isUpdate ? values?.UserType : UserType[values?.UserType]
                          }
                          placeholder={"-Select-"}
                          value={values?.UserType}
                          items={Utils.Enums.toOptions(UserType)}
                          onChange={(name, value) => {
                            setFieldValue("UserType", UserType[value])
                          }}
                        ></ClusterDropdown>

                      </FormGroup>
                    </Col>

                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterCheckBox
                        checked={values?.EnableSwitching ?? true}
                        onHandleChange={(name, value) => setFieldValue(name, value)}
                        label={"Enable Card Transaction Switching"}
                        name={"EnableSwitching"}
                        id={"EnableSwitching"}
                      />
                    </Col>
                    <Col lg={4} md={4} sm={6}>
                      <ClusterCheckBox
                        checked={values?.EsusuFundsControlStatus == EsusuFundsControlStatus.Enable}
                        onHandleChange={(name, value) => setFieldValue(name, value ? EsusuFundsControlStatus.Enable : EsusuFundsControlStatus.Disable)}
                        label={"Fund Control Status"}
                        name={"EsusuFundsControlStatus"}
                        id={"fundControlStatus"}
                      />
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </React.Fragment>
            <React.Fragment>
              <Card>
                <CardBody>
                  <CardTitle>
                    <b>Restrict access to certain features</b>
                  </CardTitle>
                  <AccordionToggle
                    parent={{ id: 0, name: "feature", label: "Features", value: 0, checked: Utils.List.isEmpty(values?.Features) ? true : values?.Features?.length == institutionFeatures.length, disabled: true, icon: Icons.Customize }}
                    children={MapFeatures(institutionFeatures, values, false, isUpdate)}
                    watch={[values.UserType]}
                    loading={loading || featureLoading}
                    onChange={(groupName, itemName, itemValue, isChecked) => {
                      const isParent = groupName == itemName;
                      if (!isParent) {
                        const toggler = Toggler(values.Features, institutionFeatures, "ID")
                        const selectedItems = toggler.select(itemValue, isChecked)
                        setFieldValue("Features", [...selectedItems]);
                        setFieldValue("FeatureIds", [...selectedItems.map(feature => feature.ID)]);

                      }

                    }}
                  />
                </CardBody>
              </Card>
            </React.Fragment>

            <ClusterButton
              loading={editLoading}
              disabled={(isUpdate && !touched) ? true : (editLoading || !isValid)}
              color={"primary"}
              addon={"bx bx-save"}
            >
              {isUpdate ? "Update" : "Save"}
            </ClusterButton>
          </ClusterForm>
        )}
      </Formik>



    </React.Fragment>
  )
}
AgentCategoryForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  categories: PropTypes.array,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(withFormValidation(AgentCategoryForm))
