import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

// images
import clusterian from "../../../assets/images/icons/clusterian.png"

//i18n
import { withTranslation } from "react-i18next"
import {
  categoryPurge,
  createCategory,
  enableUser,
  getAgentDetail,
  getAllCategories,
  getCategories,
  getCategoryDetail,
  getFeaturesByInstitution,
  getInstitutionDetail,
  updateCategory,
} from "store/actions"
import AgentCategoryForm from "./form"
import CategoryLogic from "../category"
import withDispose from "components/HOCs/WithDispose"
import GuardedPage from "components/HOCs/GuardedPage"
import { EDIT_CATEGORY } from "../permission"
import useSWR from "swr"
const fetcher = url => {
  return get(url, {}, true)
}
import { get } from "helpers/api_helper"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import { CREATE_CATEGORY } from "store/agent-category/actionTypes"
import { Institution } from "helpers/authentication/institution"
import authHeader from "helpers/authentication/auth-token-header"
let featureIds: any[]
const CreateAgentCategory = props => {
  const {
    match,
    onCreateCategory,
    onUpdateCategory,
    onGetAllCategories,
    categories,
    loading,
    featureLoading,
    institutionFeatures,
    success,
    onGetCategoryDetail,
    editLoading,
    error,
    categoryDetail,
    onGetInstitution
  } = props
  const { ERRORLIST } = CategoryLogic
  const [categoryDetails, setCategoryDetails] = useState()
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [isRotating, setIsRotating] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [category, setCategory] = useState({})
  const allCategories = useSWR(GET_ALL_CATEGORIES, fetcher, {

    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  useEffect(() => {
    const institutionId = authHeader().AuthUser?.institutionId;
    onGetInstitution(institutionId)
  }, [])

  useEffect(() => {
    getCategory()
  }, [match])
  const handleOnChange = (key, value) => {
    let u = category
    u[key] = value
    setCategory(u)
  }
  const handleValidSubmit = category => {

    if (!isUpdate) {
      onCreateCategory(category)
    } else {
      onUpdateCategory({ ...categoryDetail, ...category })
    }
  }

  const getCategory = () => {
    const id = match.params.id
    if (id && id > 0) {
      setIsUpdate(true)
      let u = categories.find(c => c.id == id)
      if (!u) {
        if (Object.keys(categoryDetail).length == 0) {
          onGetCategoryDetail(id)
        } else {
          setCategoryDetails(categoryDetail)
        }
        return
      }
      setCategoryDetails(u)
    } else {
      setIsUpdate(false)
      if (!categories || categories.length == 0) {
        onGetAllCategories()
      }
    }
  }



  return (
    <GuardedPage permissions={[CREATE_CATEGORY, EDIT_CATEGORY]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Categories"}
              image={clusterian}
              breadcrumbItem={"Create Category"}
            />

            <AgentCategoryForm
              redirectUrl={"/view-categories"}
              success={success}
              categories={(allCategories?.data as any)?.Data}
              error={error}
              isUpdate={isUpdate}
              loading={loading}
              errorList={errorList}
              isRotating={isRotating}
              editLoading={editLoading}
              details={categoryDetail}
              featureLoading={featureLoading}
              institutionFeatures={institutionFeatures}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              handleValidSubmit={handleValidSubmit}
            ></AgentCategoryForm>
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateAgentCategory.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateCategory: PropTypes.func,
  onGetCategoryDetail: PropTypes.func,
  onPurge: PropTypes.func,
  onBack: PropTypes.func,
  categoryDetail: PropTypes.object,
  categories: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  onGetAllCategories: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({
  categories: {
    categories,
    error,
    success,
    categoryDetail,
    totalCount,
    editLoading,
    loading

  },
  features,
  institutions
}) => {
  return {
    categories: [...categories],
    error,
    categoryDetail,
    totalCount,
    loading,
    success,
    featureLoading: institutions.loading,
    editLoading,
    institutionFeatures: (institutions.institutionDetail?.Features ?? []).filter(feature => feature.IsActive)
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(categoryPurge()),
  onCreateCategory: user => dispatch(createCategory(user)),
  onGetCategoryDetail: id => dispatch(getCategoryDetail(id)),
  onGetAllCategories: () => dispatch(getAllCategories()),
  onGetInstitutionFeatures: (institutionCode) => dispatch(getFeaturesByInstitution(institutionCode)),
  onUpdateCategory: category => dispatch(updateCategory(category)),
  onGetInstitution: id => dispatch(getInstitutionDetail(id))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateAgentCategory))))
