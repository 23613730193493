import ClusterTag from "components/Common/Tag";
import PropTypes from "prop-types";
 const withTags = (BaseComponent) => {
    const Wrapper = (props) => {
    const {tags=[], onClear} =props;
  
      return <>
                <BaseComponent 
                  {...props} 
                  />
                  <div>
            {tags.map((value, index) => (
              <ClusterTag key={index} onClick={() => onClear(index)}>{value}</ClusterTag>
            ))}
        </div>
              </>;
    };
    Wrapper.propTypes = {
    tags: PropTypes.array,
    onClear:PropTypes.func,
    };
    return Wrapper;
  };

  export default withTags;