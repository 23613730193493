import ClusterInput from "components/Common/Input/Input"
import ClusterForm from "components/Common/Form"
import React from "react";
import { ModalHeader, ModalBody, Row, Col, Modal, ModalFooter } from "reactstrap";
import { Formik } from "formik";
import ClusterButton from "components/Common/Button/Button";
import { Validators } from "helpers/validators";

const ConfirmOTPModal = (props) => {
    const { isOpen, isSubmitting, resendOTP, toggleOpen, handleValidSubmit } = props;
    const validate = (values) => {
        let errors: any = {}
        const required = Validators.required("Please enter a value");

        const otp = () => [
            required,
            Validators.length(5, `OTP has to be 5 characters long`)
        ].forEach(validator => {
            let error = validator(values?.OTP.toString())
            if (error) errors.OTP = error;
        });

        const validate = () => {
            try {
                otp();
                return errors;
            } catch (err) {
                console.log(err)
            }
        }
        return validate();
    }
    return <Modal
        isOpen={isOpen}
        role="dialog"
        autoFocus={true}
        centered={true}
        className="exampleModal"
        toggle={() => {
            toggleOpen(!isOpen)
        }}
    >
        <div className="modal-content">
            <ModalHeader
                toggle={() => {
                    toggleOpen(!isOpen)
                }}
            >
                Enter OTP

            </ModalHeader>

            <React.Fragment>
                <ModalBody>
                    <Formik
                        initialValues={{ OTP: undefined }}
                        validate={validate}
                        onSubmit={(values, { setSubmitting }) => {
                            handleValidSubmit(values)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            isValid,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            resetForm

                        }) => (
                            <ClusterForm handleSubmit={(e) => {
                                handleSubmit(e)
                                setTimeout(() => resetForm(), 2000)

                            }}>
                                <Row>
                                    <Col
                                        className="animate__animated animate__fadeInUp"
                                        lg={12}
                                        md={12}
                                        sm={12}
                                    >
                                        <ClusterInput
                                            defaultValue={values.OTP}
                                            label={"OTP"}
                                            id={"otp"}
                                            name={"OTP"}
                                            type="number"
                                            required={true}
                                            value={values.OTP}
                                            validationError={errors.OTP}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        ></ClusterInput>
                                    </Col>

                                </Row>
                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>

                                    <ClusterButton
                                        loading={isSubmitting}
                                        disabled={touched ? !isValid : true}
                                        color={"primary"}
                                        addon={"bx bx-send"}
                                    >
                                        Withdraw
                                    </ClusterButton>


                                    <ClusterButton
                                        color={"light"}
                                        addon={"bx bx-reset"}
                                        type={"button"}
                                        onHandleClick={(e) => {
                                            e.preventDefault()
                                            resendOTP()
                                        }}
                                    >
                                        Resend otp
                                    </ClusterButton>
                                </div>
                            </ClusterForm>)}
                    </Formik>
                </ModalBody>
                <ModalFooter className="animate__animated animate__fadeInUp">
                    {props.children}
                </ModalFooter>
            </React.Fragment>

        </div>
    </Modal>

}

export default ConfirmOTPModal;