import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import moment from "moment"
import { Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"
import { withTranslation } from "react-i18next"
import { binRoutePurge, getBinRouteDetails, getBinRoutes, removeBinRoute } from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import BinRouteLogic from "../routing"
import useSWR from "swr"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import { EDIT_BIN_ROUTE } from "../permission"
import ClusterButton from "components/Common/Button/Button"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const BinRouteList = props => {
  const {
    routeDetails,
    onGetBinRouteDetails,
    removeBinRoute,
    error,
    success,
    onPurge,
    totalCount,
    statusChanged,
    loading
  } = props

  const { HEADERS, AttachCategory, MapToRows, SearchCriteria, SearchOptions } = BinRouteLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetBinRouteDetails, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const categories = useSWR(`${GET_ALL_CATEGORIES}`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])

  useEffect(() => {
    populateRows()
  }, [routeDetails])

  
  useEffect(() => {
    if (success) {
      params["page"] = pageNumber
      let query = SearchCriteria(params, maxSize)
      search(query)
    }
  }, [success])

  const populateRows = () => {
    if (routeDetails) {
      let rws = []
      let routes = AttachCategory(routeDetails, ((categories?.data as any)?.Data as any[]));
      routes.forEach(detail => {
        let row = MapToRows(detail);
        row["action"] = (
          <ClusterButton
            permissions={[EDIT_BIN_ROUTE]}
            type="button"
            className="btn-sm btn-rounded waves-effect waves-light"
            outline
            onHandleClick={() => onRemove(detail.ID)}
            color="secondary"
          >
            Remove
          </ClusterButton>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function search(query) {
    onGetBinRouteDetails(query)
  }

  function onRemove(id) {
    removeBinRoute(id)
  }

  return (
    <React.Fragment>

      <Row className="mt-4">
        <Col lg="12">
          <Report
            tableData={{
              columns: columns,
              rows: rows,
              paginationOptions: {
                totalCount: totalCount,
                onPageChange: page => handlePageChange(page),
                pageNumber: pageNumber,
                maxSize: maxSize,
              },
              pageTitle: undefined,
              isLoading: loading,
            }}
            searchData={{
              searchTitle: "Filter",
              options: searchOptions,
              onSearch: params => onAction(params),
            }}
          />
        </Col>
      </Row>

    </React.Fragment>
  )
}

BinRouteList.propTypes = {
  binRoutes: PropTypes.array,
  onGetBinRouteDetails: PropTypes.func,
  removeBinRoute: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  routes: { binRoutes, routeDetails, error, success, totalCount, loading, statusChanged, editLoading },
}) => {
  return {
    binRoutes: [...binRoutes],
    error,
    totalCount,
    success,
    loading, statusChanged, editLoading,
    routeDetails
  }
}

const mapDispatchToProps = dispatch => ({
  onGetBinRouteDetails: query => dispatch(getBinRouteDetails(query)),
  removeBinRoute: routeDetailId => dispatch(removeBinRoute(routeDetailId)),
  onPurge: () => dispatch(binRoutePurge())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(BinRouteList))))
