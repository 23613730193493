import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { Card, Row, Col, CardBody, CardSubtitle } from "reactstrap"

import ClusterDropdown from "components/Common/DropDown/Dropdown"

//i18n
import { formatter, toTitleCase } from "helpers/utility"
import { graphqlQuery } from "helpers/api_helper"
import { useGraphql } from "hooks/useGraphql"
import Loading from "components/Common/Loading"
import { TRANSACTIONSTATUS, TIMEBASIS } from "helpers/variables"
import { GetInstitution } from "helpers/authentication/institution"
import withDispose from "components/HOCs/WithDispose"
const SummaryCard = props => {
  const { title, field, trxType, currency, index } = props
  const [range, setRange] = useState("24 hours")
  const [trxStatus, setTrxStatus] = useState("SUCCESSFUL")
  const institutionCode = GetInstitution().code
  const [startDateStr, endDateStr] = useMemo(
    () => [
      range.split(" ")[0] == 24 ||
      range.split(" ")[1].toLowerCase().includes("day")
        ? moment()
            .subtract(...range.split(" "))
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss")
        : moment()
            .subtract(...range.split(" "))
            .format("YYYY-MM-DDTHH:mm:ss"),

      range.split(" ")[0] != 24 &&
      range.split(" ")[1].toLowerCase().includes("hour")
        ? moment().format("YYYY-MM-DDTHH:mm:ss")
        : moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [range]
  )

  const query = useMemo(() => {
    return `
    {
      analytics {
        ${field}(
          filter: {
            trxType: ${trxType}
            trxStatus: ${trxStatus}
            startDate: "${moment(startDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
            endDate: "${moment(endDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
            institutionCode: "${institutionCode}"
          }
        ) {
          cumulative
        }
      }
    }
    `
  }, [trxType, field, endDateStr, startDateStr, trxStatus])

  let [data, { loading }, setData, setLoading] = useGraphql(query, {}, [query])
  let [reloading, setReload] = useState(false)

  const handleOnChange = (key, value) => {
    switch (key) {
      case "status":
        setTrxStatus(value.toString().toUpperCase())
        break
      case "range":
        setRange(value)
        break
      default:
        break
    }
  }
  useEffect(() => {
    //Refresh data every 5 mins
    setInterval(reload, 300000)
  }, [])

  function reload() {
    if (!loading) {
      // console.debug("Reloading")
      // onGetReports(query,{},[query])
      setReload(true)
      graphqlQuery(query, {}, [query]).then(response => {
        setData(response)
        setReload(false)
      })
    }
    // console.debug(response)
  }

  return (
    <Card
      outline
      color="primary"
      className="border"
      style={{
        paddingTop: "10px",
        paddingBottom: "15px",
        position: "relative",
        zIndex: index,
      }}
    >
      <CardBody>
        {!loading ? (
          <React.Fragment>
            <div
              onClick={reload}
              className={reloading ? "rotating" : ""}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
                fontSize: "1.5em",
              }}
            >
              <i className="bx bx-revision"></i>
            </div>
            <CardSubtitle style={{ marginBottom: "10px" }}>
              {title}
            </CardSubtitle>
            <Row>
              <Col lg={12}>
                <h2>
                  {formatter(
                    data?.analytics[field]?.cumulative,
                    currency ? true : false
                  )}
                </h2>
              </Col>
            </Row>
            <Row style={{ marginTop: "30px", transform: "translateX(-10px)" }}>
              <Col lg={6}>
                <ClusterDropdown
                  myClass={"btn-sm"}
                  defaultValue={toTitleCase(trxStatus)}
                  items={TRANSACTIONSTATUS}
                  onChange={(name, value) => handleOnChange("status", value)}
                ></ClusterDropdown>
              </Col>
              <Col lg={6}>
                <ClusterDropdown
                  defaultValue={range}
                  myClass={"btn-sm"}
                  items={TIMEBASIS}
                  onChange={(name, value) => handleOnChange("range", value)}
                ></ClusterDropdown>
              </Col>
            </Row>
          </React.Fragment>
        ) : (
          <Loading></Loading>
        )}
      </CardBody>
    </Card>
  )
}

SummaryCard.propTypes = {
  title: PropTypes.string,
  price: PropTypes.any,
  error: PropTypes.any,
}

// const mapStateToProps = ({ reports } ) =>
// ({
//     analytics: reports.receipts,
//     error:reports.error
// })

// const mapDispatchToProps = dispatch => ({
//   onGetReports: (query,variables,deps) => dispatch(getReports(query,variables,deps)),
// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(SummaryCard))

export default withDispose(SummaryCard)
