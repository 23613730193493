import {
  GET_INSTITUTIONS,
  GET_INSTITUTION_DETAIL,
  GET_INSTITUTION_DETAIL_SUCCESS,
  GET_INSTITUTION_DETAIL_FAIL,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAIL,
  CREATE_INSTITUTION,
  POST_INSTITUTION_SUCCESS,
  INSTITUTION_PURGE,
  INSTITUTION_PURGE_SUCCESS,
  POST_INSTITUTION_FAIL,
  UPDATE_INSTITUTION,
  UPDATE_INSTITUTION_SUCCESS,
  GET_ALL_INSTITUTIONS,
  ENABLE_INSTITUTION,
  ENABLE_INSTITUTION_SUCCESS,
  PURGE_INSTITUTIONS,
  PURGE_INSTITUTIONS_SUCCESS,
  CONFIGURE_SERVICES,
  CONFIGURE_SERVICES_SUCCESS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS,
  GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL
} from "./actionTypes"

export const getInstitutions = params => ({
  type: GET_INSTITUTIONS,
  params,
})

export const getAllInstitutions = () => ({
  type: GET_ALL_INSTITUTIONS,
})
export const getInstitutionsSuccess = institutions => ({
  type: GET_INSTITUTIONS_SUCCESS,
  payload: institutions,
})
export const getInstitutionsFail = error => ({
  type: GET_INSTITUTIONS_FAIL,
  payload: error,
})
export const getInstitutionDetail = id => ({
  type: GET_INSTITUTION_DETAIL,
  id,
})

export const getInstitutionDetailSuccess = institutions => ({
  type: GET_INSTITUTION_DETAIL_SUCCESS,
  payload: institutions,
})

export const getInstitutionDetailFail = error => ({
  type: GET_INSTITUTION_DETAIL_FAIL,
  payload: error,
})


export const getInstitutionServiceConfiguration = institutionCode => ({
  type: GET_INSTITUTION_SERVICE_CONFIGURATIONS,
  institutionCode,
})

export const getInstitutionServiceConfigurationSuccess = serviceConfiguration => ({
  type: GET_INSTITUTION_SERVICE_CONFIGURATIONS_SUCCESS,
  payload: serviceConfiguration,
})

export const getInstitutionServiceConfigurationFail = error => ({
  type: GET_INSTITUTION_SERVICE_CONFIGURATIONS_FAIL,
  payload: error,
})

export const createInstitution = institution => {
  return {
    type: CREATE_INSTITUTION,
    payload: { institution },
  }
}

export const institutionApiError = error => {
  return {
    type: POST_INSTITUTION_FAIL,
    payload: error,
  }
}

export const postInstitutionSuccess = institution => {
  return {
    type: POST_INSTITUTION_SUCCESS,
    payload: institution,
  }
}


export const configureInstitutionServices = services => {
  return {
    type: CONFIGURE_SERVICES,
    payload: { services },
  }
}



export const configureInstitutionServicesSuccess = services => {
  return {
    type: CONFIGURE_SERVICES_SUCCESS,
    payload: services,
  }
}

export const institutionPurge = () => ({
  type: INSTITUTION_PURGE,
})

export const institutionPurgeSuccess = () => ({
  type: INSTITUTION_PURGE_SUCCESS,
})

export const enableInstitution = (id, enable) => {
  return {
    type: ENABLE_INSTITUTION,
    id,
    enable,
  }
}

export const enableInstitutionSuccess = institution => {
  return {
    type: ENABLE_INSTITUTION_SUCCESS,
    payload: institution,
  }
}

export const updateInstitution = institution => {
  return {
    type: UPDATE_INSTITUTION,
    payload: { institution },
  }
}
export const updateInstitutionSuccess = institution => {
  return {
    type: UPDATE_INSTITUTION_SUCCESS,
    payload: institution,
  }
}
export const purgeInstitutions = () => {
  return {
    type: PURGE_INSTITUTIONS,
  }
}

export const purgeInstitutionSuccess = () => {
  return {
    type: PURGE_INSTITUTIONS_SUCCESS,
  }
}
