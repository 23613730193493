import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import SearchSection from "components/Common/Search/SearchSection"
import ClusterDataTable from "components/Common/DataTable"
import withDispose from "components/HOCs/WithDispose"

const Report = props => {
  const { searchData, tableData, csv } = props
  const { columns, rows, paginationOptions, pageTitle, isLoading } = tableData
  const { searchTitle, onSearch, options } = searchData
  const { pageNumber, totalCount, maxSize, onPageChange } = paginationOptions
  const [loading, setLoading] = useState(isLoading)
  const [data, setData] = useState(rows)
  const [searchOptions, setSearchOptions] = useState(options)

  useEffect(() => {
    setData(rows)
    setLoading(isLoading)
  }, [rows, isLoading])

  useEffect(() => {
    if (props.searchData?.options) {
      setSearchOptions(props.searchData?.options)
    }
  }, [props])

  const setPageNumber = page => {
    setLoading(true)
    onPageChange(page)
  }

  const handleOnSearch = params => {
    setLoading(true)
    onSearch(params)
  }

  return (
    <React.Fragment>
      <SearchSection
        options={searchOptions}
        handleOnSearch={params => handleOnSearch(params)}
      ></SearchSection>
      <ClusterDataTable csv={csv} columns={columns} loading={loading} rows={data} paginationOptions={{ maxSize: maxSize, totalCount: totalCount, pageNumber: pageNumber }} onPageChange={(page) => setPageNumber(page)}></ClusterDataTable>
    </React.Fragment>
  )
}

Report.propTypes = {
  tableData: PropTypes.object,
  searchData: PropTypes.object,
  csv: PropTypes.any,
}
export default withDispose(Report)
