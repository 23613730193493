import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
const API_BASE_URL = process.env.REACT_APP_BASE_URL
export const GRAPHQL_ENDPOINT = `${API_BASE_URL}${process.env.REACT_APP_ANALYTICS_URL}`
const AUTH_API_URL = `${API_BASE_URL}${process.env.REACT_APP_AUTH_URL}`
const CORE_API_URL = `${API_BASE_URL}${process.env.REACT_APP_CORE_URL}`
const RECEIPT_PRINTING_URL = process.env.REACT_APP_GO_URL

export enum BaseApis{
  Auth,
  Core,
  Receipt, 
  Blob,
  Analytics
}
const fiveMinutes = 5 * 60 * 1000
const axiosAuthApi = axios.create({
  baseURL: AUTH_API_URL,
})
axiosAuthApi.defaults.headers.common["Content-Type"] = "application/json"
axiosAuthApi.defaults.headers.common["Accept"] = "application/json"
axiosAuthApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axiosAuthApi.defaults.withCredentials = true
axiosAuthApi.interceptors.response.use(
  response => response.data,
  error => handleError(error)
)

const axiosReceiptApi = axios.create({
  baseURL: RECEIPT_PRINTING_URL,
})
axiosReceiptApi.defaults.headers.common["Content-Type"] = "application/json"
axiosReceiptApi.defaults.headers.common["Accept"] = "application/json"
axiosReceiptApi.interceptors.response.use(
  response => response.data,
  error => handleError(error)
)


const axiosBlob = axios.create({
  baseURL: CORE_API_URL,
})
axiosBlob.defaults.withCredentials = true

const axiosCoreApi = axios.create({
  baseURL: CORE_API_URL,
})
axiosCoreApi.defaults.headers.common["Content-Type"] = "application/json"
axiosCoreApi.defaults.headers.common["Accept"] = "application/json"
axiosCoreApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axiosCoreApi.defaults.timeout = 3 * 60 * 1000
axiosCoreApi.defaults.timeoutErrorMessage =
  "This request took too long to get a response back so it was canceled"
axiosCoreApi.defaults.withCredentials = true
axiosCoreApi.interceptors.response.use(
  response => response.data,
  error => handleError(error)
)

const axiosAnalyticsApi = axios.create({
  baseURL: GRAPHQL_ENDPOINT,
})
axiosAnalyticsApi.defaults.headers.common["Content-Type"] = "application/json"
axiosAnalyticsApi.defaults.headers.common["Accept"] = "application/json"
axiosAnalyticsApi.defaults.headers.common["Access-Control-Allow-Origin"] = "*"
axiosAnalyticsApi.defaults.timeout = 3 * 60 * 1000
axiosAnalyticsApi.defaults.timeoutErrorMessage =
  "This request took too long to get a response back so it was canceled"
axiosAnalyticsApi.defaults.withCredentials = true


const getAxiosInstance=(isCore:boolean, baseApi?:BaseApis )=>{
 
  if(!baseApi) return !isCore ? axiosAuthApi : axiosCoreApi

  switch (baseApi){
    case BaseApis.Core: return axiosCoreApi
    case BaseApis.Receipt: return axiosReceiptApi
    case BaseApis.Blob: return axiosBlob
    case BaseApis.Analytics: return axiosAnalyticsApi
    default: return axiosAuthApi
  }
}

const handleError = error => {
  if (!error) {
    window.location.href = "/logout"
    console.error(error)
  }
  if (error.response?.status == 401) {
    console.error("Authorization Error", error)
    window.location.href = "/logout"
  }

  return Promise.reject(error?.response)
}

export async function get(url, config:AxiosRequestConfig = {}, isCore = false, baseApi:BaseApis = BaseApis.Auth) {
  let instance = getAxiosInstance(isCore,baseApi)
  
  return instance.get(url, { ...config }).then(response => response)
}

export async function getBlob(url) {
  return fetch(CORE_API_URL + url, {
    headers: {
      "Content-Type": "application/json; application/octet-stream",
    },
    credentials: "include",
  })
}

export async function post(url, data, config:AxiosRequestConfig = {}, isCore = false, baseApi:BaseApis = BaseApis.Auth) {
  let instance = getAxiosInstance(isCore,baseApi)

  return  instance
    .post(url, data, { ...config, timeout: fiveMinutes })
    .then(response => response)
}

export async function put(url, data, config = {}, isCore = false, baseApi:BaseApis = BaseApis.Auth) {
  let instance = getAxiosInstance(isCore,baseApi)
  return  instance
    .put(url, { ...data }, { ...config, timeout: fiveMinutes })
    .then(response => response)
}
export async function patch(url, data, config = {}, isCore = false, baseApi:BaseApis = BaseApis.Auth) {
  let instance = getAxiosInstance(isCore,baseApi)
  return  instance.patch(url, data, { ...config }).then(response => response)
}

export async function del(url, config = {}, isCore = false, baseApi:BaseApis = BaseApis.Auth) {
  let instance = getAxiosInstance(isCore,baseApi)
  return  instance.delete(url, { ...config }).then(response => response)
}
export async function graphqlQuery(query, variables, deps = []) {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  }
  return await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers,
    credentials: "include",
    body: JSON.stringify({
      query,
      variables,
    }),
  })
    .then(response => response.json())
    .then(json => json.data)
    .catch(error => {
      console.log(error)
      Promise.reject(error)
    })
}
export const fetcher = url => {
  return get(url, {}, true)
}

export const fetchWithRequestBody = (url,data) => {
  return post(url, data, {}, true)
}

export const authFetcher = url => {
  return get(url, {}, false)
}
