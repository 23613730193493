import React, { useMemo, useEffect } from "react"
import { Row, Container, Col, Card, CardBody, CardTitle } from "reactstrap"

import moment from "moment"
import Loading from "components/Common/Loading"
import { useGraphql } from "hooks/useGraphql"
import { graphqlQuery } from "helpers/api_helper"
import { GetInstitution } from "helpers/authentication/institution"
import Knob from "pages/AllCharts/knob/knob"

export const ActiveRates = props => {
  const { startDateStr, endDateStr, field, title, code } = props
  const institutionCode = GetInstitution().code
  const handleOnChange = e => {}
  const query = useMemo(() => {
    let q = `
  {
    analytics {
      ${field}(
        filter: {
         
          startDate: "${moment(startDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
          endDate: "${moment(endDateStr).format("YYYY-MM-DDTHH:mm:ss")}"
          institutionCode: "${code != '*' ? code : institutionCode}"
        }
      ) {
        cumulative,
        total
      }
    }
  }
  `
    return q
  }, [endDateStr, startDateStr])
  let [data, { loading }, setData, setLoading]: any = useGraphql(query, {}, [
    query,
  ])
  useEffect(() => {
    //Refresh data every 5 mins
    setInterval(reload, 300000)
  }, [])
  function reload() {
    if (!loading) {
      setLoading(true)
      graphqlQuery(query, {}, [query]).then(response => {
        setData(response)
        setLoading(false)
      })
    }
  }

  const getValue = data => {
    return data?.analytics[field]?.cumulative ?? 0
  }
  const getTotal = data => {
    return data?.analytics[field]?.total ?? 0
  }
  const getPercentage = data => {
    const percentage = getValue(data) / getTotal(data)
    return isNaN(percentage) ? 0 : percentage * 100
  }
  return (
    <Card
      // className={" divider-top-"+color}
      style={{ paddingTop: "15px", paddingBottom: "0px", position: "relative" }}
    >
      <CardTitle
        style={{
          paddingLeft: "20px",
          paddingRight: "20px",
          position: "relative",
        }}
      >
        <small>
          <b>Active {title}</b>
        </small>
      </CardTitle>
      <CardBody
        style={{
          paddingTop: "10px",
          paddingBottom: "10px",
          position: "relative",
        }}
      >
        <Row
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            textAlign: "center",
          }}
        >
          {" "}
          <Col lg={12}>
            {!loading ? (
              <Knob
                onChange={handleOnChange}
                value={getPercentage(data)
                  .toFixed(2)
                  .toString()
                  .replace(".00", "")}
                min={0}
                max={100}
                inputColor={"primary"}
                fontSize={30}
                // label={"Out of " + getTotal(data)}
                label={`${getValue(data)} Active`}
              ></Knob>
            ) : (
              <Loading></Loading>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}