import { get } from "helpers/api_helper"
import { useMemo } from "react"
import useSWR from "swr"

export const useInstitutionsData = () => {
  const fetch = url => get(url, {}).then(res => res)
  const url = "/api/v1/Institutions"
  const institutions = useSWR(url, fetch, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })

   const institutionsData = useMemo(() => {
     return (institutions as any)?.data?.map(r => ({
       Name: r.name,
       Value: r.name,
       ID: r.institutionCode,
       Key: r.id,
     }))
   }, [institutions])



  return {institutionsData, institutions}
}
