import { GetInstitution } from "helpers/authentication/institution";
import { Utils } from "helpers/utility";
import {  Format, SEARCHOPTIONS } from "helpers/variables";
import moment from "moment";

const HEADERS = [
    { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
    { title: "Amount", name: "amount", entityKeyName: "Amount" },
    { title: "RRN", name: "rrn", entityKeyName: "RRN" },
    { title: "Date (YYYYMMDD)", name: "date", entityKeyName: "Date" },
    { title: "Account Number", name: "accountNumber", entityKeyName: "AccountNumber" },
    { title: "Phone Number", name: "phoneNumber", entityKeyName: "PhoneNumber" },
    { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
    { title: "MaskedPAN", name: "maskedPAN", entityKeyName: "MaskedPAN" },
    { title: "STAN", name: "stan", entityKeyName: "STAN" },
    { title: "AgentCode", name: "agentCode", entityKeyName: "AgentCode" },
    { title: "POS Type", name: "posType", entityKeyName: "POSType" },
    { title: "Failure Reason", name: "failureReason", entityKeyName: "FailureReason" }

]


const TRANSACTION_HEADERS = [
    { title: "Approve", name: "approve", isAction: true },
    { title: "Reject", name: "reject", isAction: true },
    { title: "Terminal ID", name: "terminalID", entityKeyName: "TerminalID" },
    { title: "Amount", name: "amount", entityKeyName: "Amount" },
    { title: "RRN", name: "rrn", entityKeyName: "RRN" },
    { title: "Date (YYYYMMDD)", name: "date", entityKeyName: "TrxDate" },
    { title: "Account Number", name: "accountNumber", entityKeyName: "AccountNumber" },
    { title: "Phone Number", name: "phoneNumber", entityKeyName: "PhoneNumber" },
    { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
     { title: "STAN", name: "stan", entityKeyName: "STAN" },
    
]

const APPROVAL_HEADERS = [
    { title: "Action", name: "action", isAction: true },
    { title: "Status", name: "status", isBadge: true, entityKeyName: "IsApproved" },
    { title: "Requestor", name: "username", entityKeyName: "UserName" },
    { title: "Description", name: "description", entityKeyName: "Description" },
    { title: "Time Logged", name: "dateLogged", entityKeyName: "DateLogged" },
    { title: "Transactions Approved", name: "amountApproved", entityKeyName: "AmountApproved" },
    { title: "Transactions Rejected", name: "amountRejected", entityKeyName: "AmountRejected" },
    { title: "Total", name: "total", entityKeyName: "Total" },

]
enum TreatmentStatus {
    Treated = "Treated",
    Pending = "Pending"
}

const SearchOptions = (startDate, endDate, institutions) => {

    let options = Object.assign({}, SEARCHOPTIONS);
    options.range.enabled = true
    options.range.from = moment(startDate).format("YYYY-MM-DD")
    options.range.to = moment(endDate).format("YYYY-MM-DD")
    options.fields = [
      {
        type: "dropdown",
        label: "Status",
        id: "status",
        defaultValue: "- Select -",
        data: Utils.Enums.toOptions(TreatmentStatus),
        name: "status",
        width: 2,
      },
    ]
    return options
}
const MapToDropDown = (data) => {
    if (data instanceof Array) {
        return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
    }
    return [];
}
const MapToRows = (unsettledTransaction) => {
    if(Utils.Object.isEmpty(unsettledTransaction)) return;
    return {
      terminalID: unsettledTransaction.TerminalID,
      amount: Format.currency(unsettledTransaction.Amount),
      rrn: unsettledTransaction.RRN,
      agentCode: unsettledTransaction.AgentCode,
      date: Format.date(unsettledTransaction.Date),
      accountNumber: unsettledTransaction.AccountNumber,
      phoneNumber: unsettledTransaction.PhoneNumber,
      agentName: unsettledTransaction.AgentName,
      maskedPAN: unsettledTransaction.MaskedPAN,
      stan: unsettledTransaction.STAN,
      posType: unsettledTransaction.POSType,
      failureReason: unsettledTransaction.FailureReason,
    }


}
const MapApprovalToRows = (unsettledTransaction) => {
    const isTreated = (unsettledTransaction.AmountApproved + unsettledTransaction.AmountRejected) >= unsettledTransaction.Total;
    return {
      status: {
        label: isTreated ? "Treated" : "Pending",
        badgeClass: isTreated ? "success" : "secondary",
      },
      description: unsettledTransaction.Description,
      amountApproved: unsettledTransaction.AmountApproved,
      amountRejected: unsettledTransaction.AmountRejected,
      username: unsettledTransaction.UserName,
      institution: unsettledTransaction.InstitutionName,
      total: unsettledTransaction.Total,
      dateLogged: unsettledTransaction.DateLogged,
      DateResolved: unsettledTransaction.DateResolved,
    }


}
const SearchCriteria = (params, maxSize) => {
    return JSON.stringify({
      IsTreated:
        params["status"] == "Pending"
          ? false
          : params["status"] == "Treated"
          ? true
          : "",
      InstitutionCode:
        params["institutionCode"] != "*"
          ? params["institutionCode"]
          : "",
      FromDate: moment(params["fromDate"])
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      ToDate: moment(params["toDate"])
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss"),
      StartIndex: (params["page"] - 1) * maxSize ?? 0,
      MaxSize: maxSize,
    })

}

const MapToModalState = (detail) => {
    return [
        {
            title: "",
            items: [

                {
                    id: "description",
                    label: "Description",
                    name: "description",
                    value: detail.Description,
                }
            ]
        }
    ]
}

export default {
    HEADERS,APPROVAL_HEADERS,TRANSACTION_HEADERS, SearchOptions, MapToRows, SearchCriteria, MapToModalState,  MapToDropDown,MapApprovalToRows
}