import { Format, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
import { Utils } from "helpers/utility"
import { GetInstitution } from "helpers/authentication/institution"
const HEADERS = [
  {
    title: "  Invoice Type",
    name: "invoiceType",
    entityKeyName: "InvoiceType",
  },
  { title: "Amount", name: "amount", entityKeyName: "Amount" },
  {
    title: "Debit Account",
    name: "debitAccount",
    entityKeyName: "DebitAccount",
  },
  {
    title: "Credit Account",
    name: "creditAccount",
    entityKeyName: "CreditAccount",
  },
  { title: "Narration", name: "narration", entityKeyName: "Narration" },
  {
    title: "Requested By",
    name: "requestedBy",
    entityKeyName: "RequestedByUser",
  },
  { title: "Date Logged", name: "dateLogged", entityKeyName: "DateLogged" },
  { title: "Approval Status", name: "status", entityKeyName: "IsApproved" },
  {
    title: "Approved By",
    name: "approvedBy",
    entityKeyName: "ApprovedByUser",
  },
  {
    title: "Date Approved/Rejected",
    name: "dateApproved",
    entityKeyName: "DateApprovedOrRejected",
  },
  {
    title: "Transaction Status",
    name: "transactionStatus",
    entityKeyName: "TransactionStatus",
  },
  {
    title: "Status Details",
    name: "statusDetails",
    entityKeyName: "ResponseMessage",
  },
  {
    title: "Transaction Reference",
    name: "transactionRef",
    entityKeyName: "RequestReference",
  },
  { title: "", name: "download", isAction: true }
]

enum DirectDebitInvoiceType {
  SMS = 1,
  Email = 2,
  Other = 3,
}

enum TransactionStatus {
  Failed = "Failed",
  Successful = "Successful",
  Pending = "Pending",
}

enum ApprovalStatus {
  Pending = "Pending",
  Approved = "Approved",
  Rejected = "Rejected",
}

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "dropdown",
      label: "Invoice Type",
      id: "s-invoiceType",
      defaultValue: "- Select -",
      name: "invoiceType",
      data: Utils.Enums.toOptions(DirectDebitInvoiceType),
      width: 2,
    },
    {
      type: "dropdown",
      label: "Transaction Status",
      id: "s-transactionStatus",
      defaultValue: "- Select -",
      name: "transactionStatus",
      data: Utils.Enums.toOptions(TransactionStatus),
      width: 2,
    },
    {
      type: "dropdown",
      label: "Approval Status",
      id: "s-approvalStatus",
      defaultValue: "- Select -",
      name: "approvalStatus",
      data: Utils.Enums.toOptions(ApprovalStatus),
      width: 2,
    },
    {
      type: "text",
      label: "Requested By",
      id: "s-requestedBy",
      defaultValue: "",
      name: "requestedBy",
    },
    {
      type: "text",
      label: "Approved By",
      id: "s-approvedBy",
      defaultValue: "",
      name: "approvedBy",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapToRows = debitrequests => {
  const isApproved = debitrequests.IsApproved
  return {
    invoiceType: debitrequests.InvoiceType,
    amount: Format.currency(debitrequests.Amount),
    debitAccount: debitrequests.DebitAccount,
    institution: debitrequests?.InstitutionName,
    creditAccount: debitrequests.CreditAccount,
    narration: debitrequests.Narration,
    requestedBy: debitrequests.RequestedByUser,
    dateLogged: Format.date(debitrequests.DateLogged),
    status: isApproved
      ? "Approved"
      : isApproved !== null
      ? "Rejected"
      : "Pending",
    approvedBy: debitrequests.ApprovedByUser,
    dateApproved: debitrequests.DateApprovedOrRejected,
    transactionStatus: debitrequests.TransactionStatus,
    statusDetails: debitrequests.ResponseMessage,
    transactionRef: debitrequests.RequestReference,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    Requester: params["requestedBy"],
    Approver: params["approvedBy"],
    TransactionStatus: params["transactionStatus"],
    InstitutionCode:
      params["institutionCode"] != "*" ? params["institutionCode"] : "",
    ApprovalStatus: params["approvalStatus"],
    InvoiceType: params["invoiceType"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

export default {
  HEADERS,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToDropDown,
}
