
import { useMemo, useState, useEffect } from "react";
import { Status } from "enums/status";
import { UserScope } from "enums/user-scope";
import { fetcher } from "helpers/api_helper";
import authHeader from "helpers/authentication/auth-token-header";
import ImageHelper from "helpers/image-helper";
import { GET_ALL_CLUSTERS, GET_ALL_NETWORK_MANAGERS, GET_ALL_CLUSTER_MANAGERS } from "helpers/url_helper";
import { Utils } from "helpers/utility";
import { Validators } from "helpers/validators";
import { AGENT } from "helpers/variables";
import useSWR from "swr";
import { usePrevious } from "../../../../hooks/usePrevious";
export const useScope = (userDetails: any, prepopulatedValues:any,  roles: any, userRoleId: number) => {
    const [showScopeItem, setShowScopeItem] = useState(false);
    const [scopeItemLabel, setShowScopeItemLabel] = useState<any>();
    const [scopeItemLoading, setScopeItemLoading] = useState(false);
    const [viewScopes, setViewScopes] = useState([]);
    const [scope, setScope] = useState<UserScope>();
    const previousScope = usePrevious(scope);

    const [clusterManagerId, setClusterManagerId] = useState();
    const clusters = useSWR(GET_ALL_CLUSTERS, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false
    })
    const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false
    })

    const clusterManagers = useSWR(GET_ALL_CLUSTER_MANAGERS, fetcher, {
        revalidateOnFocus: true,
        revalidateOnReconnect: false
    })

    const clustersData = useMemo(() => ((clusters.data as any)?.Data ?? []), [clusters.data]);
    const clusterManagersData = useMemo(() => ((clusterManagers.data as any)?.Data ?? []), [clusterManagers.data]);
    const networkManagersData = useMemo(() => ((networkManagers.data as any)?.Data ?? []), [networkManagers.data]);
    const scopeMetaData = [{
        scope: UserScope.Global,
        image: ImageHelper.global,
        title: "Global Scope",
        description: "Users created under this scope can see all the data for the modules they have rights to view",
    },
    {
        scope: UserScope.Cluster,
        image: ImageHelper.cluster,
        title: "Cluster Scope",
        description: "Users created under this scope can see data of agents or merchants that belong to the clusters assigned to the user",
    },
    {
        scope: UserScope.NetworkManager,
        image: ImageHelper.networkmanager,
        title: "Network Scope",
        description: "Users created under this scope can see data of agents or merchants that belong to the network manager assigned to the user",
    },
    {
        scope: UserScope.Agent,
        image: ImageHelper.agent,
        title: AGENT.title,
        description: AGENT.description,
    }
    ].filter(i => i.scope != UserScope.Agent)


    const filteredRoles = useMemo(() => {
        const authUser = authHeader().AuthUser;
        const myRoles = [];
        Object.assign(myRoles, roles);
        if (!Utils.Object.isEmpty(authUser)) {
            const role = myRoles.find(r => r.id == authUser.userRoleId);
            if (!Utils.Object.isEmpty(role) && (role?.scope === UserScope.Cluster || role?.scope === UserScope.NetworkManager)) {
                return myRoles.filter(r => r.scope == role.scope && r.status == Status[Status.Active])
            }
            return myRoles.filter(r => r.scope == Utils.Strings.camelPad(scope) && r.status == Status[Status.Active])
        }

        return []
    }, [scope, roles])

    const scopeItems = useMemo((): any[] => {
        const roleId = userRoleId ?? userDetails?.userRole?.id;
        let items = [];

        if (Utils.List.isEmpty(clustersData) || Utils.List.isEmpty(networkManagersData)) return [];
        const role = filteredRoles.find(r => r.id == roleId);
        if (role && scope != UserScope.Global) {
            setShowScopeItem(true);
            setShowScopeItemLabel(Utils.Strings.camelPad(scope))
            if (scope == UserScope.Cluster) {

                items = clustersData
            } else if (scope == UserScope.NetworkManager) {
                items = networkManagersData
            }
        } else {
            setShowScopeItem(false);
            setShowScopeItemLabel("")
            items = []
        }
        return items;
    }, [userDetails, filteredRoles, clusters, networkManagers, scope, userRoleId, viewScopes])

    const availableScopeItems = useMemo(() => {
        if (scope == UserScope.Cluster && clusterManagerId) return scopeItems.filter(s => (s.ClusterManager?.ID == clusterManagerId)) ?? [];
        return scopeItems ?? [];
    }, [clusterManagerId, scopeItems, userRoleId])

    const getUserDetailsFromManager = (manager) => {

        const names = manager.Name.split(" ");
        const lastName = names[0] ?? "";
        const firstName = names[1] ?? ""
        const username = `${firstName[0] ?? lastName[0] ?? ""}${lastName}`;
        const email = manager.Email ?? "";
        const phoneNumber = manager.PhoneNumber ?? ""
        let user = { firstName, lastName, otherName: "", phoneNumber, email, username };


        if (scope == UserScope.NetworkManager) user["gender"] = manager.Gender ?? "";

        return user;
    }


    const user = useMemo(() => {
        let u = {};
        if (scope == UserScope.Cluster && clusterManagerId) {
            const manager = (clusterManagersData).find(c => c.ID == clusterManagerId);
            if (Utils.Object.isEmpty(manager)) return u;
            u = getUserDetailsFromManager(manager);

        }
        if (scope == UserScope.NetworkManager && !Utils.List.isEmpty(viewScopes)) {
            const manager = (networkManagersData ?? []).find(c => c.ID == viewScopes[0]);
            if (Utils.Object.isEmpty(manager)) return u;
            u = getUserDetailsFromManager(manager);
        }

        return u;
    }, [viewScopes])
    const toArray = (value) => {
        return !value ? [] : value.toString().split(",").map(c => +(c))
    }
    useEffect(() => {
        if (!Utils.Object.isEmpty(userDetails)) {

            setScope(prepopulatedValues?.scope||userDetails?.userRole?.scope);
            setViewScopes(toArray(prepopulatedValues?.viewScopeIds||userDetails?.viewScopeIds))
        }
    }, [userDetails])

    const initialValues = useMemo(() => {

        return {
            scope: prepopulatedValues?.scope || userDetails?.userRole?.scope,
            userRoleId: prepopulatedValues?.userRoleId || (userDetails?.userRole?.id ?? userDetails?.userRoleId),
            viewScopeIds: prepopulatedValues?.viewScopeIds || userDetails?.viewScopeIds,
            clusterManagerId: prepopulatedValues?.clusterManagerId ||clusterManagerId
        }
    }, [userDetails, prepopulatedValues])

    const defaultValue = useMemo(() => {
        return scope == UserScope.Cluster ? scopeItems.filter(s => {
            return viewScopes.includes(s.ID)

        }).map(r => ({ key: r.ID, value: r.Name })) : scopeItems.find(s => viewScopes.includes(s.ID))?.Name ?? ""
    }, [scope, viewScopes, scopeItems])

    const validator = (values) => {

        let errors: any = {}
        const required = Validators.required("Please enter a value");

        const userRoleId = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.userRoleId)
                if (error) errors.userRoleId = error;
            });
        const scope = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.scope)
                if (error) errors.scope = error;
            });

        const viewScopeIds = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.viewScopeIds)
                if (error) errors.viewScopeIds = error;
            });

        const validate = () => {
            try {
                scope();
                userRoleId();
                if (values.scope != UserScope.Global) {
                    viewScopeIds()
                }

                return errors;
            } catch (err) {
                console.log(err)
            }
        }
        return validate();

    }
   

    return {
        scope:
        {
            scope: scope,
            setScope,
            scopeMetaData: scopeMetaData,
            scopeChanged: Utils.hasChanged(previousScope, scope),
            canHaveScopeItems: !Utils.List.isEmpty(scopeItems),
            scopeItemLabel,
            viewScopes,
            setViewScopes,
            items: {
                scopeItemLoading,
                scopeItems: availableScopeItems
            }
        },
        filteredRoles,
        user,
        validator,
        initialValues,
        defaultValue,
        toArray
    }

}
export const useUser = (userDetails: any, prepopulatedValues:any) => {

    const initialValues = useMemo(() => {
        const form = {
          firstName: userDetails?.firstName,
          lastName: userDetails?.lastName,
          otherName: userDetails?.otherName,
          phoneNumber: userDetails?.phoneNumber,
          email: userDetails?.email,
          username: userDetails?.username,
          gender: userDetails?.gender,
          address: userDetails?.address?.street,
          city: userDetails?.address?.city,
          state: userDetails?.address?.state,
          ...prepopulatedValues,
        }
        return form;
      }, [prepopulatedValues, userDetails])

    const validator = (values) => {

        let errors: any = {}
        const required = Validators.required("Please enter a value");

        const phoneNumber = () =>
            [
                required,
                Validators.phone([
                    "Phone number should contain only numbers",
                    "Phone number must be 11 digits",
                ]),
            ].forEach(validator => {
                let error = validator(values?.phoneNumber)
                if (error) errors.phoneNumber = error;
            });


        const gender = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.gender)
                if (error) errors.gender = error;
            });

        const state = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.state)
                if (error) errors.state = error;
            });

        const userRoleId = () =>
            [
                required
            ].forEach(validator => {
                let error = validator(values?.userRoleId)
                if (error) errors.userRoleId = error;
            });

        const firstName = () => [required, Validators.minLength(
            3,
            "Minimum number of characters is 3"
        )
        ].forEach(validator => {
            let error = validator(values?.firstName)
            if (error) errors.firstName = error;
        });

        const lastName = () => [required, Validators.minLength(
            3,
            "Minimum number of characters is 3"
        )
        ].forEach(validator => {
            let error = validator(values?.lastName)
            if (error) errors.lastName = error;
        });


        const username = () => [required, Validators.minLength(
            6,
            "Minimum number of characters is 6"
        )
        ].forEach(validator => {
            let error = validator(values?.username)
            if (error) errors.username = error;
        });

        const email = () => [required, Validators.email("Enter a valid email e.g abc@xyz.com")
        ].forEach(validator => {
            let error = validator(values?.email)
            if (error) errors.email = error;
        });

        const address = () => [required, Validators.maxLength(
            100,
            "Maximum number of characters is 100"
        ),
            Validators.minLength(
                4,
                "Minimum number of characters is 4"
            )
        ].forEach(validator => {
            let error = validator(values?.address)
            if (error) errors.address = error;
        });

        const city = () => [required, Validators.minLength(3, "Invalid city")
        ].forEach(validator => {
            let error = validator(values?.city)
            if (error) errors.city = error;
        });
        const validate = () => {
            try {
                phoneNumber();
                email();
                lastName();
                firstName();
                username();
                address();
                city();
                state();
                gender();
                userRoleId();

                return errors;
            } catch (err) {
                console.log(err)
            }
        }
        return validate();

    }

    return {
        validator,
        initialValues
    }

}
