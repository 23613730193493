import { get } from "helpers/api_helper"
import { filesDownload } from "helpers/download_util/files_download"
import { toast } from "react-toastify"

export type ViewedBatchType = {
  IsApproved: Boolean
  AmountApproved: Number
  AmountRejected: Number
  Description: String
  TransactionsApproved: String
  TransactionsRejected: String
}

export async function downloadReceipt(
  url: String,
  viewedBatch: ViewedBatchType
) {
  try {
    toast.info("Downloading receipt")
    const response = await get(url, {}, true)
    const base64 = (response as any)?.Data
    if (base64) {
      filesDownload(base64, `${viewedBatch?.Description}.zip`)
      toast.success("Receipt downloaded")
    } else {
      toast.error(`Could not generate receipt.`)
    }
  } catch (e) {
    toast.error(`Could not generate receipt. Reason: (${e.message})`)
  }
}

export async function downloadTransactions(
  url: String,
  viewedBatch: ViewedBatchType
) {
  try {
    toast.info("Downloading transactions")
    const response = await get(url, {}, true)
    const base64 = (response as any)?.Data
    if (base64) {
      filesDownload(base64, `${viewedBatch?.Description}.csv`)
      toast.success("Transactions downloaded")
    } else {
      toast.error(`Could not generate receipt.`)
    }
  } catch (e) {
    toast.error(`Could not download transactions. Reason: (${e.message})`)
  }
}
