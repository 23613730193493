import { fetcher, GRAPHQL_ENDPOINT } from "helpers/api_helper"
import authHeader from "helpers/authentication/auth-token-header"
import ClusterStorage from "helpers/storage-helper"
import { GET_FEATURE_BY_INSTITUTION } from "helpers/url_helper"
import { compare, Utils } from "helpers/utility"
import { TRANSACTIONTYPENAMES } from "helpers/variables"
import { useEffect, useState, useMemo } from "react"
import { Feature } from "store/features/reducer"
import useSWR from "swr"
type DashboardTransactionTypes = {
  idx: number,
  id: number,
  label: string,
  cut: number,
  type: string,
  color: string,
}
const featuresCacheKey = "INSTITUTION_FEATURES";
export function useDashboardView() {
  const [features, setFeatures] = useState()

  const featureResponse = useSWR(
    GET_FEATURE_BY_INSTITUTION + "/" + authHeader().AuthUser?.institutionCode,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
    ,)
  const data: DashboardTransactionTypes[] = useMemo(() => {
    //THESE NEED TO BE THE EXACT SAME SPELLING AS THE TRANSACTION TYPE IN THE DATABASE SO AS NOT TO FAIL
    const permittedTransactionTypes = ["CASHOUT", "POSAGENTCASHOUT", "CASHIN", "RECHARGE", "BILLSPAYMENT", "FUNDSTRANSFERCOMMERCIALBANK", "LOCALFUNDSTRANSFER", "CROSSBANKTOKENWITHDRAWAL", "PAYWITHTRANSFER", "CARDTRANSFER", "AGENTLOANREQUEST", "COLLECTIONPAYMENT"];

    const features = ClusterStorage.getItem(featuresCacheKey) as unknown as Feature[];
    if (!Utils.List.isEmpty(features)) {

      const transactionTypes = TRANSACTIONTYPENAMES.filter(t => permittedTransactionTypes.includes(t.value.toUpperCase()));
      const colors = ["blue", "pink", "orange", "teal", "purple", "green", "primary", "grey"]
      const cards = transactionTypes.filter(t => {

        const isFeatureAssigned = features.some(f => f.Code === t.flag);
        return isFeatureAssigned;
      }).sort(compare("value")).map((t, index) => {
        return {
          idx: t.key,
          id: index,
          label: t.friendlyName??Utils.Strings.camelPad(t.value),
          cut: 0.1,
          type: t.id.toUpperCase(),
          color: colors[index % colors.length],
        }
      })
      return cards;

    }

    return [];
  }, [features])

  useEffect(() => {
    const features = (featureResponse.data as any)?.Data;
    if (!Utils.List.isEmpty(features)) {
      ClusterStorage.setItem(featuresCacheKey, features);
      setFeatures(features)
    }
  }, [featureResponse])
  return { data }
}

