import { defaultObj } from "helpers/utility"
import {
  GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS,
  GET_SUBSCRIPTION_PLAN_DETAIL_FAIL,
  GET_SUBSCRIPTION_PLANS_SUCCESS,
  GET_SUBSCRIPTION_PLANS_FAIL,
  GET_SUBSCRIPTIONS_SUCCESS,
  GET_SUBSCRIPTIONS_FAIL,
  SUBSCRIPTION_PLAN_PURGE,
  ENABLE_SUBSCRIPTION_PLAN,
  ENABLE_SUBSCRIPTION_PLAN_SUCCESS,
  SUBSCRIPTION_PLAN_PURGE_SUCCESS,
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTIONS,
  POST_SUBSCRIPTION_PLAN_SUCCESS,
  UPDATE_SUBSCRIPTION_PLAN,
  SAVE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN_SUCCESS,
  POST_SUBSCRIPTION_PLAN_FAIL,
  GET_ALL_SUBSCRIPTION_PLANS,
  GET_ALL_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_PLANS_BY_CATEGORY,
  ADD_SUBSCRIPTION_PLAN_OPTION,
  REMOVE_SUBSCRIPTION_PLAN_OPTION,
  ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
  REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  subscriptionPlans: [],
  subscriptionPlanDetail: {},
  subscriptionPlanOptions: [],
  subscriptions: [],
  error: undefined,
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  deleteLoading: false,
  statusChanged: undefined,

  optionsSuccess: undefined,
  optionsError: undefined,
}

const Subscriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_SUBSCRIPTION_PLAN_OPTION:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
        optionsSuccess: undefined,
        optionsError: undefined,
      }
    case REMOVE_SUBSCRIPTION_PLAN_OPTION:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        optionsSuccess: undefined,
        optionsError: undefined,
        error: undefined,
        deleteLoading: true,
      }
    case ADD_SUBSCRIPTION_PLAN_OPTION_SUCCESS:
      let error; //= state.subscriptionPlans.find(s => s.Name == action.payload.Name) ? "A commission structure with the same name already exists" : ""
      let success = !state.subscriptionPlanOptions.find(
        s => s.TransactionType == action.payload.TransactionType
      )
        ? "Successfully Added"
        : ""
      let subscriptionPlanOptions = [...state.subscriptionPlanOptions]
      let options = [...state.subscriptionPlanOptions]
      if (action.payload) {
        if (!options.find(s => s.TransactionType == action.payload.TransactionType)) {
          subscriptionPlanOptions.push({ ...action.payload, flag: "add" })
          options.push(action.payload)
        } else {
          success = action.payload.TransactionType + " Updated"
          options.map(c => {
            if (c.TransactionType == action.payload.TransactionType) {
              c = action.payload
            }
            return c
          })
          subscriptionPlanOptions.map(c => {
            if (c.TransactionType == action.payload.TransactionType) {
              c = action.payload
            }
            return c
          })
        }
      }
      return {
        ...state,
        subscriptionPlanOptions,
        editLoading: false,
        optionsSuccess: success,
        optionsError: error
      }
    case REMOVE_SUBSCRIPTION_PLAN_OPTION_SUCCESS:
      let removeError = !state.subscriptionPlanOptions.find(
        s => s.TransactionType == action.payload.TransactionType
      )
        ? "Already removed"
        : ""
      let removeSuccess = state.subscriptionPlanOptions.find(
        s => s.TransactionType == action.payload.TransactionType
      )
        ? "Successfully removed"
        : ""
      let modifiedStructures = [...state.subscriptionPlanOptions] ?? []
      let structures = [...state.subscriptionPlanOptions]

      if (action.payload) {
        structures = structures.filter(s => s.TransactionType != action.payload.TransactionType)
        if (modifiedStructures.find(s => s.TransactionType == action.payload.TransactionType)) {
          modifiedStructures = modifiedStructures.filter(
            m => m.name != action.payload.TransactionType
          )
        } else {
          modifiedStructures.push({ ...action.payload, flag: "delete" })
        }
      }
      return {
        ...state,
        subscriptionPlanOptions: modifiedStructures,
        editLoading: false,
        deleteLoading: false,
        optionsError: removeError,
        optionsSuccess: removeSuccess
      }
    case GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        subscriptionPlans: action.payload?.Data || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_SUBSCRIPTION_PLANS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: (action.payload?.Data.Collections??action.payload?.Data?.Reports) || [],
        totalCount: (action.payload?.Data?.TotalCount??action.payload?.Data.totalCount) || 0,
        loading: false,
      }
    case GET_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBSCRIPTION_PLANS_BY_CATEGORY:
      return {
        ...state,
        loading: true,
      }
    case GET_SUBSCRIPTION_PLANS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_SUBSCRIPTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case GET_SUBSCRIPTION_PLAN_DETAIL_SUCCESS:
      return {
        ...state,
        subscriptionPlanDetail: action.payload?.Data,
        success: action.payload?.Message,
        loading: false,
      }

    case GET_SUBSCRIPTION_PLAN_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        subscriptionPlan: defaultObj(state.subscriptionPlan), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_SUBSCRIPTION_PLAN:
      return {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
      }

    case SAVE_SUBSCRIPTION_PLAN:
      state = {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
      }
      break
    case POST_SUBSCRIPTION_PLAN_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        success: "",
        loading: false,
        deleteLoading: false,
        editLoading: false,
      }
      break

    case UPDATE_SUBSCRIPTION_PLAN_SUCCESS:
      let updatedStructures = state.subscriptionPlans
      let updatedStructureDetail = state.subscriptionPlanDetail
      if (action.payload?.Data) {
        updatedStructures = updatedStructures.map(s => {
          if (s.ID == action.payload.Data.ID) {
            s = action.payload.Data
          }
          return s
        })
      }
      state = {
        ...state,
        subscriptionPlans: updatedStructures,
        subscriptionPlanDetail: updatedStructureDetail,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_SUBSCRIPTION_PLAN:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_SUBSCRIPTION_PLAN_SUCCESS:
      let detail = state.subscriptionPlanDetail
      detail.IsActive = false;
      let subscriptionPlans = state.subscriptionPlans
      if (detail) {
        subscriptionPlans = subscriptionPlans.map(c => {
          if (c.ID == detail.ID) {
            c.IsActive = detail.IsActive
          } else {
            return c
          }
        }) ?? []
      }
      return {
        ...state,
        subscriptionPlans,
        editLoading: false,
        loading: false,
        subscriptionPlanDetail: detail,
        error: "",
        statusChanged: !state.statusChanged,
        success: action.payload?.Message,
      }
    case SUBSCRIPTION_PLAN_PURGE_SUCCESS:
      return INIT_STATE

    default:
      return state
  }
  return state
}

export default Subscriptions
