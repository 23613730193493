import {
  GET_CHARGEBACKS,
  GET_CHARGEBACK_DETAIL,
  GET_CHARGEBACK_DETAIL_SUCCESS,
  GET_CHARGEBACK_DETAIL_FAIL,
  GET_CHARGEBACKS_SUCCESS,
  GET_CHARGEBACKS_FAIL,
  GET_ALL_CHARGEBACKS,
  CHARGEBACK_PURGE,
  CHARGEBACK_PURGE_SUCCESS,
  UPDATE_CHARGEBACK,
  UPDATE_CHARGEBACK_SUCCESS,
  UPDATE_CHARGEBACK_FAIL,
  REGENERATE_CHARGEBACK_TOKEN,
  REGENERATE_CHARGEBACK_TOKEN_SUCCESS,
  REGENERATE_CHARGEBACK_TOKEN_FAIL,
} from "./actionTypes"

export const getChargeBacks = params => ({
  type: GET_CHARGEBACKS,
  params,
})
export const getAllChargeBacks = () => ({
  type: GET_ALL_CHARGEBACKS,
})
export const getChargeBacksSuccess = claims => ({
  type: GET_CHARGEBACKS_SUCCESS,
  payload: claims,
})
export const getChargeBacksFail = error => ({
  type: GET_CHARGEBACKS_FAIL,
  payload: error,
})
export const getChargeBackDetail = id => ({
  type: GET_CHARGEBACK_DETAIL,
  id,
})

export const getChargeBackDetailSuccess = claims => ({
  type: GET_CHARGEBACK_DETAIL_SUCCESS,
  payload: claims,
})

export const getChargeBackDetailFail = error => ({
  type: GET_CHARGEBACK_DETAIL_FAIL,
  payload: error,
})
export const updateChargeBack = (id, status) => {
  return {
    type: UPDATE_CHARGEBACK,
    payload: { id, status },
  }
}
export const updateChargeBackSuccess = payload => {
  return {
    type: UPDATE_CHARGEBACK_SUCCESS,
    payload,
  }
}

export const updateChargeBackError = error => {
  return {
    type: UPDATE_CHARGEBACK_FAIL,
    payload: error,
  }
}


export const regenerateChargeBackToken = (id) => {
  return {
    type: REGENERATE_CHARGEBACK_TOKEN,
    payload: { id },
  }
}
export const regenerateChargeBackTokenSuccess = payload => {
  return {
    type: REGENERATE_CHARGEBACK_TOKEN_SUCCESS,
    payload,
  }
}

export const regenerateChargeBackTokenError = error => {
  return {
    type: REGENERATE_CHARGEBACK_TOKEN_FAIL,
    payload: error,
  }
}

export const chargeBackPurge = () => ({
  type: CHARGEBACK_PURGE,
})

export const chargeBackPurgeSuccess = () => ({
  type: CHARGEBACK_PURGE_SUCCESS,
})

export const purgeChargeBacks = () => {
  return {
    type: PURGE_CHARGEBACKS,
  }
}

export const purgeChargeBackSuccess = () => {
  return {
    type: PURGE_CHARGEBACKS_SUCCESS,
  }
}
