import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import {
  GET_DEBIT_REQUESTS_DETAIL,
  GET_DEBIT_REQUESTS,
  POST_DEBIT_REQUESTS,
  PATCH_DEBIT_REQUESTS_APPROVAL,
} from "./actionTypes"

import {
  getDebitRequestDetailFail,
  getDebitRequestDetailSuccess,
  getDebitRequestsFail,
  getDebitRequestsSuccess,
  postDebitRequestFail,
  postDebitRequestSuccess,
  patchDebitRequestsApprovalSuccess,
  patchDebitRequestsApprovalFail,
} from "./actions"

import {
  postDebitRequests,
  patchDebitRequestsApproval,
  getDebitRequests,
  getPendingDebits,
  getDebitRequestDetail,
  downloadInvoice
} from "helpers/backend_helper"

function* doUploadDirectDebit({ payload }) {
  try {
    const response = yield call(postDebitRequests, payload)
    yield put(postDebitRequestSuccess(response))
  } catch (error) {
    console.debug("Posting Debit Requests Error", error)
    yield put(postDebitRequestFail(error))
  }
}
function* doApproveDirectDebit({ id, approve }) {
  try {
    const response = yield call(patchDebitRequestsApproval, id, approve)
    yield put(patchDebitRequestsApprovalSuccess(response))
  } catch (error) {
    console.debug("Posting Debit Requests Error", error)
    yield put(patchDebitRequestsApprovalFail(error))
  }
}
function* fetchDebitRequests({ params }) {
  try {
    const response = yield call(getDebitRequests, JSON.parse(params))
    yield put(getDebitRequestsSuccess(response))
  } catch (error) {
    console.debug("Fetching Debit Requests Error", error)
    yield put(getDebitRequestsFail(error))
  }
}

function* fetchDebitRequestDetail({ id }) {
  try {
    const response = yield call(getDebitRequestDetail, id)
    yield put(getDebitRequestDetailSuccess(response))
  } catch (error) {
    yield put(getDebitRequestDetailFail(error))
  }
}


function* debitRequestsSaga() {
  yield takeLeading(POST_DEBIT_REQUESTS, doUploadDirectDebit)
  yield takeLeading(PATCH_DEBIT_REQUESTS_APPROVAL, doApproveDirectDebit)
  yield takeLeading(GET_DEBIT_REQUESTS, fetchDebitRequests)
  yield takeEvery(GET_DEBIT_REQUESTS_DETAIL, fetchDebitRequestDetail)
}

export default debitRequestsSaga
