/* AGENTS */
export const GET_AGENTS = "GET_AGENTS"
export const GET_AGENT_LOANS = "GET_AGENT_LOANS"
export const GET_AGENT_LOANS_SUCCESS = "GET_AGENT_LOANS_SUCCESS"
export const GET_ALL_AGENTS = "GET_ALL_AGENTS"
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS"
export const GET_AGENTS_FAIL = "GET_AGENTS_FAIL"

export const GET_AGENT_DETAIL = "GET_AGENT_DETAIL"
export const GET_AGENT_DETAIL_SUCCESS = "GET_AGENT_DETAIL_SUCCESS"
export const GET_AGENT_DETAIL_FAIL = "GET_AGENT_DETAIL_FAIL"

export const CREATE_AGENT = "CREATE_AGENT"
export const POST_AGENT_SUCCESS = "POST_AGENT_SUCCESS"
export const POST_AGENT_FAIL = "POST_AGENT_FAIL"

export const AGENT_PURGE = "AGENT_PURGE"
export const AGENT_PURGE_SUCCESS = "AGENT_PURGE_SUCCESS"

export const UPDATE_AGENT = "UPDATE_AGENT"
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS"
export const UPDATE_AGENT_FAIL = "UPDATE_AGENT_FAIL"

export const ENABLE_AGENT = "ENABLE_AGENT"
export const ENABLE_AGENT_SUCCESS = "ENABLE_AGENT_SUCCESS"

export const DEVICE_RESET = "DEVICE_RESET"
export const DEVICE_RESET_SUCCESS = "DEVICE_RESET_SUCCESS"
export const DEVICE_RESET_FAIL = "DEVICE_RESET_FAIL"

export const RESET_LIMIT = "RESET_LIMIT"
export const RESET_LIMIT_SUCCESS = "RESET_LIMIT_SUCCESS"
export const RESET_LIMIT_FAIL = "RESET_LIMIT_FAIL"

export const UPGRADE_TO_SUPERAGENT = "UPGRADE_TO_SUPERAGENT"
export const UPGRADE_TO_SUPERAGENT_SUCCESS = "UPGRADE_TO_SUPERAGENT_SUCCESS"
export const UPGRADE_TO_SUPERAGENT_FAIL = "UPGRADE_TO_SUPERAGENT_FAIL"

export const UNLINK_FROM_SUPERAGENT = "UNLINK_FROM_SUPERAGENT"
export const UNLINK_FROM_SUPERAGENT_FAIL = "UNLINK_FROM_SUPERAGENT_FAIL"
export const UNLINK_FROM_SUPERAGENT_SUCCESS = "UNLINK_FROM_SUPERAGENT_SUCCESS"

export const LINK_TO_POSTERMINAL = "LINK_TO_POSTERMINAL"
export const LINK_TO_POSTERMINAL_SUCCESS = "LINK_TO_POSTERMINAL_SUCCESS"
export const LINK_TO_POSTERMINAL_FAIL = "LINK_TO_POSTERMINAL_FAIL"

export const ACCOUNT_VALIDATION = "ACCOUNT_VALIDATION"
export const ACCOUNT_VALIDATION_SUCCESS = "ACCOUNT_VALIDATION_SUCCESS"
export const ACCOUNT_VALIDATION_FAIL = "ACCOUNT_VALIDATION_FAIL"

export const GENERATE_POS_ACCOUNTS = "GENERATE_POS_ACCOUNTS"
export const GENERATE_POS_ACCOUNTS_SUCCESS = "GENERATE_POS_ACCOUNTS_SUCCESS"
export const GENERATE_POS_ACCOUNTS_FAILURE = "GENERATE_POS_ACCOUNTS_FAILURE"

