
export const filesDownload = (base64String: string, fileName: string) => {
      const byteCharacters = atob(base64String)
      const byteNumbers = new Uint8Array(byteCharacters.length)
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
      }
      const fileBlob = new Blob([byteNumbers], { type: "application/zip" })
      const fileUrl = URL.createObjectURL(fileBlob)

      const link = document.createElement("a")
      link.href = fileUrl
      link.download = fileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
}