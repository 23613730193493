import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"

import { Container, Card, CardBody, Row, Col, FormGroup, Label } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import { addSubscriptionPlanOption, saveSubscriptionPlans } from "store/actions"
import SubscriptionForm from "./form"
import SubscriptionLogic from "../volume-covenant"
import withDispose from "components/HOCs/WithDispose"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import { fetcher } from "helpers/api_helper"
import useSWR from "swr"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import Loading from "components/Common/Loading"
import ClusterButton from "components/Common/Button/Button"
import { toast } from "react-toastify"
const AddSubscription = props => {
  const {
    onAddSubscription,
    success,
    editLoading,
    subscriptionPlanOptions,
    loading,
    error,
    optionsSuccess,
    optionsError,
    onSaveSubscription,
  } = props
  const { ERRORLIST, MapToDropDown, OPTIONS_ERRORLIST } = SubscriptionLogic
  const [errorList, setErrorList] = useState(ERRORLIST)
  const [errorOptionsList, setOptionsErrorList] = useState(OPTIONS_ERRORLIST)
  const [isRotating, setIsRotating] = useState(false)
  const [subscriptionPlan, setSubscription] = useState({}) //data from form
  const [subscriptionPlanOption, setSubscriptionOptions] = useState({}) //data from form
  const [categoryId, setCategoryId] = useState()
  const [category, setCategory] = useState()
  const { data } = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const onHandleSubmit = e => {
    e.preventDefault()
    setCategory(categoryId)
  }
  const handleOnChange = (key, value) => {
    let u = subscriptionPlan
    u[key] = value
    setSubscription(u)
  }
  const handleOptionsOnChange = (key, value) => {
    let u = subscriptionPlanOption
    u[key] = value
    setSubscriptionOptions(u)
  }

  const handleValidSubmit = e => {
    e.preventDefault()
    const subscriptionPlanPlan = { ...subscriptionPlan, ValidityPeriod: 30, Options: subscriptionPlanOptions, AgentCategoryID: categoryId }

    onSaveSubscription(subscriptionPlanPlan)
  }

  const handleValidOptionsSubmit = e => {
    e.preventDefault()
    onAddSubscription({ ...subscriptionPlanOption })


  }

  useEffect(() => {
    if (optionsError) {
      toast.error(optionsError)
    }
    if (optionsSuccess) {
      toast.success(optionsSuccess)
    }
  }, [optionsSuccess,
    optionsError])

  return (


    <React.Fragment>
      <div className="page-content">
        {!category ? (
          <React.Fragment>
            <Container fluid>
              <Breadcrumbs
                title={"Subscriptions"}
                breadcrumbItem={"Select an Agent Category"}
              />

              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={5} md={6} sm={12}>
                      <FormGroup>
                        <Label>Agent Categories</Label>
                        {data ? (
                          <ClusterDropdown
                            name={"CategoryId"}
                            placeholder={"- Select a category - "}
                            items={MapToDropDown((data as any)?.Data)}
                            onChange={(name, value) => {
                              setCategoryId(value)
                            }}
                          ></ClusterDropdown>
                        ) : (
                          <Loading></Loading>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ClusterButton
                        onHandleClick={onHandleSubmit}
                        color={"primary"}
                        disabled={categoryId ? false : true}
                      >
                        Next
                      </ClusterButton>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Container>
          </React.Fragment>
        ) : (
          <Container fluid>
            <Breadcrumbs
              title={"Subscription Plan"}

              breadcrumbItem={"Setup Subscription Plan for " + (data as any)?.Data?.find(c => c.ID == categoryId)?.Name}
              prepend={
                <i
                  className={"bx bx-arrow-back"}
                  style={{
                    marginRight: "10px",
                    fontSize: "2em",
                    cursor: "pointer",
                  }}
                  color={"secondary"}
                  onClick={e => {
                    e.preventDefault()
                    setCategory(undefined)
                  }}
                ></i>
              }
            />
            <SubscriptionForm
              redirectUrl={"/view-subscription-plans"}
              success={success}
              error={error}
              loading={loading}
              errorList={errorList}
              editLoading={editLoading}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              handleOptionsOnChange={handleOptionsOnChange}
              handleValidSubmit={handleValidSubmit}
              handleValidOptionsSubmit={handleValidOptionsSubmit}
            ></SubscriptionForm>

          </Container>
        )}
      </div>
    </React.Fragment>
  )
}

AddSubscription.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onAddSubscription: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onBack: PropTypes.func,
  agentDetail: PropTypes.object,
  subscriptionPlans: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  subscriptions: {
    subscriptionPlans,
    subscriptionPlanOptions,
    error,
    success,
    optionsSuccess,
    optionsError,
    agentDetail,
    editLoading,
    totalCount,
    loading,
  },
  networkManagers: { networkManagers },
  clusterManagers: { clusterManagers },
  categories: { categories },
}) => {
  return {
    subscriptionPlans,
    error,
    success,
    subscriptionPlanOptions,
    agentDetail: agentDetail,
    totalCount,
    loading,
    editLoading,
    clusterManagers,
    networkManagers,
    categories,

    optionsSuccess,
    optionsError,
  }
}

const mapDispatchToProps = dispatch => ({
  onAddSubscription: subscriptionPlan =>
    dispatch(addSubscriptionPlanOption(subscriptionPlan)),
  onSaveSubscription: payload => dispatch(saveSubscriptionPlans(payload))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withDispose(AddSubscription)))
