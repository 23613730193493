import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Link, withRouter } from "react-router-dom"

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Alert
} from "reactstrap"
import ClusterDropdown from 'components/Common/DropDown/Dropdown';
import ClusterButton from 'components/Common/Button/Button';
import ClusterInput from 'components/Common/Input/Input';
import Loading from 'components/Common/Loading';
import { Validators } from 'helpers/validators';
import ClusterForm from 'components/Common/Form';
import AccountLogic from '../settlement';
import withFormValidation from 'components/HOCs/WithFormValidation';
import withDispose from 'components/HOCs/WithDispose';
import { Formik } from 'formik';
import { Utils } from 'helpers/utility';
const ClustersForm = props => {
  let { apiSuccess, apiError, wallet, users, errorList, myError, isUpdate, editLoading, setErrorList, handleOnChange, handleValidSubmit } = props;
  let { MapUsersToDropDown } = AccountLogic;
  const Initiate = (details) => {
    const user = users?.find(a => a.id == details?.ApproverUserID);
    return {
      Email: details?.Email,
      PhoneNumber: details?.PhoneNumber,
      ApproverUserID: details?.ApproverUserID ?? 0,
      Name: details?.Name ?? (user?.lastName + ' ' + user?.firstName)
    }
  }

  if (wallet == undefined) return <Loading>Getting details... Ensure wallet settlement mode is enabled for this institution</Loading>
  if (Utils.List.isEmpty(users)) {
    return <Loading>Getting Users</Loading>
  }

  return <React.Fragment>
    <Formik
      initialValues={Initiate(wallet)}
      validate={(values) => {
        let errors: any = {}
        const required = Validators.required("Please enter a value");
        const phoneNumber = () =>
          [
            required,
            Validators.phone(["Please only enter numbers", "Minimum number of characters is 11"]),
          ].forEach(validator => {
            let error = validator(values?.PhoneNumber)
            if (error) errors.PhoneNumber = error;
          });
        const email = () => [required, Validators.email("Minimum number of characters is 3")
        ].forEach(validator => {
          let error = validator(values?.Email)
          if (error) errors.Email = error;
        });
        const userId = () => [required, Validators.min(1, "Please select a user")
        ].forEach(validator => {
          let error = validator(values?.ApproverUserID)
          if (error) errors.ApproverUserID = error;
        });

        const validate = () => {
          try {
            // phoneNumber();
            // email();
            userId();
            return errors;
          } catch (err) {
            console.log(err)
          }
        }
        return validate();

      }}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        const user = users?.find(a => a.id == values.ApproverUserID);
        handleValidSubmit({ ...values, Email: user?.email, PhoneNumber: user?.phoneNumber, Name: user?.firstName + ' ' + user?.lastName })

      }}
    >
      {({
        values,
        errors,
        touched,
        isValid,
        setFieldError,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        isSubmitting,
      }) => (<ClusterForm handleSubmit={handleSubmit}>

        <React.Fragment>


          <Card outline style={{ background: "transparent", borderColor: "#999999" }} className="border" >
            <CardBody>
              <Row>
                <Col lg={4} md={4} sm={6}>
                  <FormGroup>
                    <Label>Approver</Label>

                    <ClusterDropdown
                      id={"userID"}
                      name={"ApproverUserID"}
                      placeholder={"-Select-"}
                      defaultValue={values.Name}
                      items={MapUsersToDropDown(users)}
                      onChange={(name, value) => {

                        setFieldValue("ApproverUserID", value)
                      }}
                      required={true}
                    />

                  </FormGroup>
                </Col>

                <Col lg={4} md={4} sm={6}>

                  <ClusterInput
                    defaultValue={values?.PhoneNumber}
                    focus
                    label={"Phone Number"}
                    id={"phoneNumber"}
                    name={"PhoneNumber"}
                    type="text"
                    disabled={true}
                    required={true}
                    value={users?.find(a => a.id == values?.ApproverUserID)?.phoneNumber}
                    validationError={errors.PhoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col lg={4} md={4} sm={6}>

                  <ClusterInput
                    defaultValue={values?.Email}
                    focus
                    label={"Email"}
                    id={"email"}
                    name={"Email"}
                    type="email"
                    disabled={true}
                    required={true}
                    value={users?.find(a => a.id == values?.ApproverUserID)?.email}
                    validationError={errors.Email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>

              </Row>

            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={editLoading}
              disabled={!touched ? true : (editLoading || !isValid)}
              color={"primary"}
              addon={"bx bx-plus"}
            >
              Save
            </ClusterButton>
          </Col>
        </Row>

      </ClusterForm>)}
    </Formik>
  </React.Fragment>

}
ClustersForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  onUpdateSelection: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func
}
export default withRouter(withFormValidation(withDispose(ClustersForm)))