import { call, put, takeEvery, takeLeading } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_CHARGEBACKS,
  GET_CHARGEBACK_DETAIL,
  CHARGEBACK_PURGE,
  GET_ALL_CHARGEBACKS,
  UPDATE_CHARGEBACK,
  REGENERATE_CHARGEBACK_TOKEN
} from "./actionTypes"
import {
  getChargeBacksSuccess,
  getChargeBacksFail,
  getChargeBackDetailSuccess,
  getChargeBackDetailFail,
  chargeBackPurgeSuccess,
  regenerateChargeBackTokenSuccess,
  regenerateChargeBackTokenError
} from "./actions"
import {
  getAllChargeBacks,
  getChargeBackDetail,
  getChargeBacks,
  updateChargeBack,
  regenerateChargeBackToken
} from "helpers/backend_helper"
import { updateChargeBackError, updateChargeBackSuccess } from "store/actions"

const format = "yyyy-MM-DD"
function* fetchChargeBacks({ params }) {
  try {
    const response = yield call(getChargeBacks, JSON.parse(params))
    yield put(getChargeBacksSuccess(response))
  } catch (error) {
    console.debug("Fetching ChargeBacks Error", error)
    yield put(getChargeBacksFail(error))
  }
}
function* fetchAllChargeBacks() {
  try {
    const response = yield call(getAllChargeBacks)
    yield put(getChargeBacksSuccess(response))
  } catch (error) {
    console.debug("Fetching ChargeBacks Error", error)
    yield put(getChargeBacksFail(error))
  }
}

function* fetchChargeBackDetail({ id }) {
  try {
    const response = yield call(getChargeBackDetail, id)
    yield put(getChargeBackDetailSuccess(response))
  } catch (error) {
    yield put(getChargeBackDetailFail(error))
  }
}
function* updateClaim({ payload: { id, status } }) {
  try {
    console.debug(id, status)
    const response = yield call(updateChargeBack, { id, status })

    yield put(updateChargeBackSuccess({ ...response, status }))
  } catch (error) {
    console.debug(error)
    yield put(
      updateChargeBackError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* regenerateToken({ payload: { id } }) {
  try {
    console.debug(id, status)
    const response = yield call(regenerateChargeBackToken, id)

    yield put(regenerateChargeBackTokenSuccess(response ))
  } catch (error) {
    console.debug(error)
    yield put(
      regenerateChargeBackTokenError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doPurge() {
  yield put(chargeBackPurgeSuccess())
}

function* chargeBacksSaga() {
  yield takeLeading(GET_CHARGEBACKS, fetchChargeBacks)
  yield takeLeading(GET_ALL_CHARGEBACKS, fetchAllChargeBacks)
  yield takeLeading(GET_CHARGEBACK_DETAIL, fetchChargeBackDetail)
  yield takeEvery(CHARGEBACK_PURGE, doPurge)
  yield takeLeading(UPDATE_CHARGEBACK, updateClaim)
  yield takeLeading(REGENERATE_CHARGEBACK_TOKEN,regenerateToken);
}

export default chargeBacksSaga
