import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { Link, withRouter } from "react-router-dom"

import { Card, CardBody } from "reactstrap"
import ClusterButton from "components/Common/Button/Button"
import Loading from "components/Common/Loading"
import { Utils } from "helpers/utility"
import InstitutionBasicDetailsForm from "./basic-details-form"
import InstitutionWalletDetailsForm from "./wallet-details-form"
import InstitutionSuperAdminForm from "./superadmin-details-form"
import InstitutionFeaturesForm from "./feature-form"
import { Institution } from "pages/InstitutionManagement/institution"

type InstitutionFormProps = any & {
  details: Institution
}
enum InstitutionPages {
  BasicDetails,
  WalletDetails,
  SuperAdminDetails,
  Features,
  ServiceConfiguration
}
const InstitutionForm: React.FC<InstitutionFormProps> = props => {
  const {
    apiSuccess,
    features,
    isUpdate,
    loading,
    details,
    handleValidSubmit,
  } = props

  const [formState, setFormState] = useState<Institution>(details);
  const [currentPage, setCurrentPage] = useState<number>(InstitutionPages.BasicDetails);
  const [breadCrumb, setBreadCrumb] = useState<number[]>([]);

  useEffect(() => {
    if (!Utils.Object.isEmpty(details)) {
      setFormState(details as Institution)
    }
  }, [details, features])

  const onNext = (values: any) => {
    let institution = formState;
    if (currentPage == InstitutionPages.SuperAdminDetails) {
      institution = { ...formState, User: values }
    } else {
      institution = { ...formState, ...values }
    }
    setFormState(institution);
    breadCrumb.push(currentPage);
    setBreadCrumb(breadCrumb)
    const nextPage = getNextPageNumber(institution);
    setCurrentPage(nextPage);
    const isLastPage = currentPage == InstitutionPages.Features;
    if (isLastPage) handleValidSubmit({ ...institution, Features: values.FeatureIds })
  }

  const onPrev = () => {
    const prevPage = breadCrumb.pop();
    setBreadCrumb(breadCrumb);
    setCurrentPage(prevPage);
  }

  const getNextPageNumber = (values: Institution) => {
    if (currentPage == InstitutionPages.BasicDetails && values.WalletAccountEnabled) return InstitutionPages.WalletDetails;
    if ((currentPage == InstitutionPages.BasicDetails || currentPage == InstitutionPages.WalletDetails) && (!isUpdate || !details.UserID)) return InstitutionPages.SuperAdminDetails;
    if (currentPage == InstitutionPages.WalletDetails || currentPage == InstitutionPages.SuperAdminDetails) return InstitutionPages.Features;
    if (apiSuccess && !isUpdate) return InstitutionPages.ServiceConfiguration;
    return InstitutionPages.Features;
  }

  if (loading && isUpdate && Utils.Object.isEmpty(details)) {
    return <Loading>
      Getting Institution...
    </Loading>
  }

  switch (currentPage) {
    case InstitutionPages.WalletDetails:
      return <InstitutionWalletDetailsForm index={1} {...props} institution={{ ...details, ...formState }} onNext={onNext} onPrev={onPrev} />
    case InstitutionPages.SuperAdminDetails:
      return <InstitutionSuperAdminForm index={2} {...props} institution={{ ...details, ...formState }} onNext={onNext} onPrev={onPrev} />
    case InstitutionPages.Features:
      return <InstitutionFeaturesForm index={3} {...props} institution={{ ...details, ...formState }} onNext={onNext} onPrev={onPrev} ></InstitutionFeaturesForm>
    case InstitutionPages.ServiceConfiguration:
      return <Card>
        <CardBody>
          <h1>{apiSuccess}</h1>
          Proceed to configure some services for your institution

          <ClusterButton

            color={"primary"}
            addon={"bx bx-link-external"}
          >
            <Link to="/service-configuration">  Configure services</Link>
          </ClusterButton>

        </CardBody>
      </Card>;
    default:
      return <InstitutionBasicDetailsForm index={0} {...props} institution={{ ...details, ...formState }} onNext={onNext} onPrev={onPrev} />

  }

}
InstitutionForm.propTypes = {
  id: PropTypes.string,
  apiSuccess: PropTypes.string,
  apiError: PropTypes.string,
  myError: PropTypes.string,
  features: PropTypes.array,
  institutions: PropTypes.array,
  isUpdate: PropTypes.bool,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  details: PropTypes.object,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  setIsRotating: PropTypes.func,
  handleValidSubmit: PropTypes.func,
}
export default withRouter(InstitutionForm)


