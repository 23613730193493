import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap"
import { withRouter } from "react-router-dom"
import accessControl from "../../assets/images/icons/access-control.svg"
import errorImg from "../../assets/images/11132.jpg"
const ClusterPage:React.FC<any> = props => {
  const { guarded, children, match, actions } = props
  const [isGuarded, setGuarded] = useState(guarded)

  const guardedTitle =
    "You do not have rights to this action. Kindly contact your administrator"
  useEffect(() => {
    const id = match.params.id
    if (!guarded && id != 0 && (actions.length==1 && actions[0]=="CREATE")) {
      setGuarded(true)
    }
    else if (!guarded && id == 0 && (actions.length==1 && actions[0]=="EDIT")) {
      setGuarded(true)
    }
    else if (guarded){
      setGuarded(true)
    }
    else {
      setGuarded(false)
    }
  }, [guarded])
  return !isGuarded ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="page-content">
      <Row>
        <Col>
          <Card style={{ textAlign: "center" }}>
            <CardBody>
              <img
                style={{ maxWidth: "100px" }}
                src={accessControl}
                alt="You dont have the rights to this page"
              />
              <h1>Thou shall not pass</h1>
              <h5>{guardedTitle}</h5>

              <img src={errorImg} style={{ maxWidth: "600px" }} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

ClusterPage.propTypes = {
  guarded: PropTypes.bool,
  actions:PropTypes.array
}

export default withRouter(ClusterPage)
