import * as actionTypes from "./actionTypes";
import * as actions from "./actions"
import { toast } from "react-toastify"

import {
  call,
  put,
  takeLeading,
  takeEvery
} from "redux-saga/effects"

import {
    getNotifications,
    postNotification,
    exportNotificationReport,
    getNotificationDetails
} from "helpers/backend_helper"


function* fetchNotification({params}){
    try {
        const response = yield call(getNotifications, JSON.parse(params))
        yield put(actions.getNotificationsSuccess(response))
    }
    catch (error){
        console.debug("Fetching Notifications error", error)
        yield put(actions.getNotificationsFailure(error))
    }
}

function* fetchNotificationDetails({id}){
    try {
        const response = yield call(getNotificationDetails, id)
        yield put(actions.getNotificationDetailsSuccess(response))
    }
    catch (error){
        console.debug("Fetching Notification error", error)
        yield put(actions.getNotificationDetailsFailure(error))
    }
}

function* doPostNotification({payload}){
    try {
        const response = yield call(postNotification, payload)
        yield put(actions.postNotificationSuccess(response))
        toast.success(response.Message)
    } catch(error){
        console.debug("Posting notification error", error)
        yield put(actions.postNotificationFailure(error))
        toast.error(error.data.Message)
    }
}


function* notificationSaga(){
    yield takeLeading(actionTypes.GET_NOTIFICATIONS, fetchNotification)
    yield takeLeading(actionTypes.POST_NOTIFICATION, doPostNotification)
    yield takeEvery(actionTypes.GET_NOTIFICATION_DETAILS, fetchNotificationDetails)
}

export default notificationSaga