import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import {
  Button,
  Col,
  Container, Row
} from "reactstrap"

import Breadcrumbs from "../../../components/Common/Breadcrumb"

import { withTranslation } from "react-i18next"
import { settlementPurge, updateCluster, getSettlementWithdrawals, getSettlementAccount, removeSettlementAccount, saveSettlementAccount } from "store/actions"
import SettlementAccountForm from './form';
import AccountLogic from "../settlement";
import withDispose from 'components/HOCs/WithDispose';
import useSWR from 'swr'
import { GET_SETTLEMENT_COMMERCIAL_BANKS } from 'helpers/url_helper'
import { fetcher } from 'helpers/api_helper'
import moment from 'moment'
import Report from './report'
import useReportData from "hooks/UseReportData"

const SettlementAccountConfiguration = props => {
  const { match, onSaveAccount, onGetAccount,  success, editLoading, loading, error, account } = props

  const [errorList, setErrorList] = useState({});
  const [isRotating, setIsRotating] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  useEffect(() => {
    onGetAccount()
  }, [])


  const commercialBanks = useSWR(GET_SETTLEMENT_COMMERCIAL_BANKS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false
  })
 
  const handleValidSubmit = (account) => {
   
      onSaveAccount(account)
    
  }

 


 

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Account"}
            breadcrumbItem={"Setup account"}
          />

          <SettlementAccountForm
            success={success}
            error={error}
            isUpdate={isUpdate}
            loading={loading}
            errorList={errorList}
            details={account}
            isRotating={isRotating}
            editLoading={editLoading}
            commercialBanks={(commercialBanks?.data as any)?.Data}
            setErrorList={setErrorList}
            setIsRotating={setIsRotating}
            handleValidSubmit={handleValidSubmit}
          />



        </Container>
      </div>
    </React.Fragment>
  )
}



SettlementAccountConfiguration.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onSaveAccount: PropTypes.func,
  onGetAccountDetail: PropTypes.func,
  onBack: PropTypes.func,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any
}

const mapStateToProps = ({ settlements: { account, error, success, accountDetail, editLoading, totalCount, loading } }) => {
  return {
    error,
    success,
    account,
    totalCount,
    loading,
    editLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onSaveAccount: (settlement) => dispatch(saveSettlementAccount(settlement)),
  onGetAccount: () => dispatch(getSettlementAccount()),
  onRemoveAccount:(id)=>dispatch(removeSettlementAccount(id,false)),
  onUpdateAccount: (settlement) => dispatch(updateCluster(settlement)),
  onPurge: () => dispatch(settlementPurge())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(SettlementAccountConfiguration))))









const SettlementAccountList = (props) => {
  const {
    accounts,
    onGetAccount,
    onRemoveAccount,
    error,
    success,
    totalCount,
    loading
  } = props
  const { ACCOUNTHEADERS, MapAccountToRows, SearchCriteria, AccountSearchOptions } = AccountLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetAccount, HEADERS: ACCOUNTHEADERS, SearchCriteria: SearchCriteria })

  const [searchOptions, setSearchOptions] = useState(
    AccountSearchOptions(params?.fromDate, params?.toDate)
  )

  useEffect(() => {
    populateRows()
  }, [accounts])

  const populateRows = () => {
    if (accounts) {
      let rws = []
      accounts.forEach(account => {
        let row = MapAccountToRows(account);
        row["action"] = (
          <Button
            type="button"
            color="danger"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => deactivateAccount(account.ID)}
          >
            Remove Account
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }
  function deactivateAccount(id) {
    onRemoveAccount(id)
  }

  return <React.Fragment>
    <Row className="mt-4 mb-2">
      <Col lg="12">
        <h5><b>Accounts</b></h5>
      </Col>
    </Row>
    <Row>
      <Col lg="12">
        <Report
          tableData={{
            columns: columns,
            rows: rows,
            paginationOptions: {
              totalCount: totalCount,
              onPageChange: page => handlePageChange(page),
              pageNumber: pageNumber,
              maxSize: maxSize,
            },
            pageTitle: undefined,
            isLoading: loading,
          }}
          searchData={{
            searchTitle: "Filter",
            options: searchOptions,
            onSearch: params => onAction(params),
          }}
        />
      </Col>
    </Row>

  </React.Fragment>
}
