/* ROUTES */
export const GET_BINS:any = "GET_BINS"
export const GET_BINS_SUCCESS = "GET_BINS_SUCCESS"
export const GET_BINS_FAIL = "GET_BINS_FAIL"

export const GET_BIN_ROUTES:any = "GET_BIN_ROUTES"
export const GET_BIN_ROUTES_SUCCESS = "GET_BIN_ROUTES_SUCCESS"
export const GET_BIN_ROUTES_FAIL = "GET_BIN_ROUTES_FAIL"

export const GET_BIN_ROUTE_DETAILS:any = "GET_BIN_ROUTE_DETAILS"
export const GET_BIN_ROUTE_DETAILS_SUCCESS = "GET_BIN_ROUTE_DETAILS_SUCCESS"
export const GET_BIN_ROUTE_DETAILS_FAIL = "GET_BIN_ROUTE_DETAILS_FAIL"

export const GET_ROUTES:any = "GET_ROUTES"
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS"
export const GET_ROUTES_FAIL = "GET_ROUTES_FAIL"


export const CONFIGURE_BIN_ROUTE:any= "CONFIGURE_BIN_ROUTE";
export const CONFIGURE_BIN_ROUTE_SUCCESS = "CONFIGURE_BIN_ROUTE_SUCCESS"
export const CONFIGURE_BIN_ROUTE_FAIL = "CONFIGURE_BIN_ROUTE_FAIL"

export const BIN_ROUTE_PURGE:any = "BIN_ROUTE_PURGE"
export const BIN_ROUTE_PURGE_SUCCESS = "BIN_ROUTE_PURGE_SUCCESS"


export const REMOVE_BIN_ROUTE:any= "REMOVE_BIN_ROUTE";
export const REMOVE_BIN_ROUTE_SUCCESS= "REMOVE_BIN_ROUTE_SUCCESS";
export const REMOVE_BIN_ROUTE_FAIL= "REMOVE_BIN_ROUTE_FAIL";