import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_NETWORKMANAGERS,
  GET_NETWORKMANAGER_DETAIL,
  NETWORKMANAGER_PURGE,
  ENABLE_NETWORKMANAGER,
  CREATE_NETWORKMANAGER,
  UPDATE_NETWORKMANAGER,
  GET_ALL_NETWORKMANAGERS,
} from "./actionTypes"
import {
  getNetworkManagersSuccess,
  getNetworkManagersFail,
  getNetworkManagerDetailSuccess,
  getNetworkManagerDetailFail,
  networkManagerPurgeSuccess,
  updateNetworkManagerSuccess,
  postNetworkManagerSuccess,
  networkManagerApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getNetworkManagers,
  getNetworkManagerDetail,
  putNetworkManager,
  postNetworkManager,
  enableNetworkManager,
  disableNetworkManager,
  getAllNetworkManagers,
} from "helpers/backend_helper"
import { enableNetworkManagerSuccess } from "store/actions"
const format = "yyyy-MM-DD"
function* fetchNetworkManagers({ params }) {
  try {
    const response = yield call(getNetworkManagers, JSON.parse(params))
    yield put(getNetworkManagersSuccess(response))
  } catch (error) {
    console.debug("Fetching NetworkManagers Error", error)
    yield put(getNetworkManagersFail(error))
  }
}
function* fetchAllNetworkManagers() {
  try {
    const response = yield call(getAllNetworkManagers)
    yield put(getNetworkManagersSuccess(response))
  } catch (error) {
    console.debug("Fetching All NetworkManagers Error", error)
    yield put(getNetworkManagersFail(error))
  }
}
function* fetchNetworkManagerDetail({ id }) {
  try {
    const response = yield call(getNetworkManagerDetail, id)
    yield put(getNetworkManagerDetailSuccess(response))
  } catch (error) {
    yield put(getNetworkManagerDetailFail(error))
  }
}

function* createNetworkManager({ payload: { networkManager } }) {
  try {
    const response = yield call(postNetworkManager, {
      ...JSON.parse(JSON.stringify(networkManager)),
    })
    yield put(postNetworkManagerSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      networkManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateNetworkManager({ payload: { networkManager } }) {
  try {
    const response = yield call(putNetworkManager, {
      ...JSON.parse(JSON.stringify(networkManager)),
    })

    yield put(updateNetworkManagerSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      networkManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doEnableNetworkManager(payload) {
  try {
    let toEnable = true
    let cta = enableNetworkManager
    if (!payload.enable) {
      cta = disableNetworkManager
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableNetworkManagerSuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      networkManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doPurge() {
  yield put(networkManagerPurgeSuccess())
}

function* networkManagersSaga() {
  yield takeLeading(GET_NETWORKMANAGERS, fetchNetworkManagers)
  yield takeLeading(GET_ALL_NETWORKMANAGERS, fetchAllNetworkManagers)
  yield takeEvery(GET_NETWORKMANAGER_DETAIL, fetchNetworkManagerDetail)
  yield takeEvery(NETWORKMANAGER_PURGE, doPurge)
  yield takeLeading(ENABLE_NETWORKMANAGER, doEnableNetworkManager)
  yield takeLeading(UPDATE_NETWORKMANAGER, updateNetworkManager)
  yield takeLeading(CREATE_NETWORKMANAGER, createNetworkManager)
}

export default networkManagersSaga
