export const ENABLE_MOBILEACCOUNTS = "ENABLE_MOBILEACCOUNTS"
export const RESET_PIN = "RESET_PIN"
export const RESET_PIN_TRIES = "RESET_PIN_TRIES"
export const GENERATE_VERIFICATION_CODE = "GENERATE_VERIFICATION_CODE"
export const GENERATE_ACTIVATION_CODE = "GENERATE_ACTIVATION_CODE"
export const HOTLIST = "HOTLIST"
export const VIEW_MOBILEACCOUNTS = "VIEW_MOBILEACCOUNTS"
export const EDIT_MOBILEACCOUNT = "EDIT_MOBILEACCOUNT"
export const CREATE_MOBILEACCOUNT = "CREATE_MOBILEACCOUNT"
export const VIEW_MOBILEACCOUNT_DETAILS = "VIEW_MOBILEACCOUNT_DETAILS"
export const DELETE_MOBILEACCOUNT = "DELETE_MOBILEACCOUNT"

export const ENABLE_MOBILEWALLETS = "ENABLE_MOBILEWALLETS"
export const RESET_MOBILEWALLET_LIMIT = "RESET_MOBILEWALLET_LIMIT"
export const UPGRADE_TO_SUPER_MOBILEWALLET = "UPGRADE_TO_SUPER_MOBILEWALLET"
export const VIEW_MOBILEWALLETS = "VIEW_MOBILEWALLETS"
export const EDIT_MOBILEWALLET = "EDIT_MOBILEWALLET"
export const VIEW_MOBILEWALLET_DETAILS = "VIEW_MOBILEWALLET_DETAILS"
export const DELETE_MOBILEWALLET = "DELETE_MOBILEWALLET"
