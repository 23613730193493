import React, { useEffect, useState } from "react"
import { Spinner, Row, Col } from "reactstrap"
const Loading: React.FC<any> = props => {
  return (
    // <Row style={{  textAlign: "center" }}>
    // {" "}
    // <Col lg={12} style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
    <div style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
      <Spinner type="border" className="mr-2" color="secondary" />
      {props.children}
    </div>
    //    </Col>
    // </Row> 
  )
}

export default Loading
