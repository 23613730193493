import PropTypes from "prop-types"
import React, { Component } from "react"

import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
} from "../../store/actions"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"
import Rightbar from "../CommonForBoth/Rightbar"

// Track inactivity
import IdleTimer from "react-idle-timer"
import { IdleTimeOutModal } from "../Common/Modals/IdleModal"
import { get } from "helpers/api_helper"
import { REFRESH_SESSION } from "helpers/url_helper"
import authHeader, { setAuthUser } from "helpers/authentication/auth-token-header"
import moment from "moment"
class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgentData?.platform),
      timeout: 1000 * 60 * 5,
      showModal: false,
      userLoggedIn: false,
      loading: false,
      isTimedOut: false,
    }
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.handleStay = this.handleStay.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }
  componentWillUnmount() {
    // this._isMounted = false;

    this.setState = (state, callback) => {
      return
    }
  }
  componentDidMount() {
    if (this.props.isPreloader === true) {
      document.getElementById("preloader").style.display = "block"
      document.getElementById("status").style.display = "block"

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none"
        document.getElementById("status").style.display = "none"
      }, 2500)
    } else {
      document.getElementById("preloader").style.display = "none"
      document.getElementById("status").style.display = "none"
    }

    // Scroll Top to 0
    window.scrollTo(0, 0)
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname)

    document.title = currentage + " | Cluster.Africa"
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme)
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth)
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType)
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme)
    }
  }
  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile)
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile)
    }
  }

  //Track Inactivity
  _onAction(e) {
    // console.debug('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    // console.debug('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    // console.debug('user is idle', e)
    const isTimedOut = this.state.isTimedOut
    if (isTimedOut) {
      this.props.history.push("/logout")
    } else {
      this.setState({ showModal: true })
      this.idleTimer.reset()
      this.setState({ isTimedOut: true })
    }
  }

  handleStay() {
    this.setState({ showModal: false })
    // this.setState({ loading: true })
    get(REFRESH_SESSION, null, false).then(response => {
      // this.setState({ loading: false })
      // this.setState({ showModal: false })
      setAuthUser(JSON.stringify(response))
    })
  }

  handleLogout() {
    this.setState({ showModal: false })
    this.props.history.push("/logout")
  }

  render() {
    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref
          }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout}
        />
        <div id="preloader">
          <div id="status">
            <div className="spinner-chase">
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
              <div className="chase-dot" />
            </div>
          </div>
        </div>

        <div id="layout-wrapper">
          <Header toggleMenuCallback={this.toggleMenuCallback} />
          <Sidebar
            theme={this.props.leftSideBarTheme}
            type={this.props.leftSideBarType}
            isMobile={this.state.isMobile}
          />
          <div className="main-content">{this.props.children}</div>
          <Footer />
        </div>
        {this.props.showRightSidebar ? <Rightbar /> : null}

        <IdleTimeOutModal
          loading={this.state.loading}
          showModal={this.state.showModal}
          handleStay={this.handleStay}
          handleLogout={this.handleLogout}
        />
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
}

const mapStatetoProps = state => {
  return {
    ...state.Layout,
  }
}
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
})(withRouter(Layout))
