
import {
  Format,
  SEARCHOPTIONS,
} from "helpers/variables"
import { GetInstitution } from "helpers/authentication/institution"
import moment from "moment"
const HEADERS = [
  { title: "Date", name: "date", entityKeyName: "Date" },
  { title: "Transfer Status", name: "transferStatus", entityKeyName: "TransferStatus", isBadge: true },
  { title: "Withdrawal Status", name: "withdrawalStatus", entityKeyName: "WithdrawalStatus", isBadge: true },
  {
    title: "Transfer Amount",
    name: "transferAmount",
    entityKeyName: "TransferAmount",
  },
  {
    title: "Withdrawal Amount",
    name: "withdrawalAmount",
    entityKeyName: "WithdrawalAmount",
  },
  { title: "Fee", name: "fee", entityKeyName: "Fee" },
  { title: "Beneficiary Name", name: "beneficiaryName", entityKeyName: "BeneficiaryName" },
  { title: "Beneficiary Account", name: "beneficiaryAccount", entityKeyName: "BeneficiaryAccount" },
  { title: "Beneficiary Bank", name: "beneficiaryBank", entityKeyName: "BeneficiaryBank" },
  { title: "Reference", name: "retrievalReferenceNumber", entityKeyName: "TransactionReference" },
  { title: "STAN", name: "stan", entityKeyName: "STAN" },
  { title: "PAN", name: "maskedPan", entityKeyName: "MaskedPAN" },
  { title: "Agent Name", name: "agentName", entityKeyName: "AgentName" },
  { title: "Agent Phone Number", name: "agentPhoneNumber", entityKeyName: "AgentPhoneNumber" },
  { title: "Status Details", name: "statusDetails", entityKeyName: "StatusDetails" }

]

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "agentPhoneNumber",
    },
    {
      type: "text",
      label: "Transaction Reference",
      id: "s-reference",
      defaultValue: "",
      name: "reference",
    },
    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select a type -",
      data: [
        { id: "Successful ", value: "Successful", key: 0 },
        { id: "Pending ", value: "Pending", key: 1 },
        { id: "Failed", value: "Failed", key: 1 },
      ],
      name: "status",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options;
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    institution: transaction.InstitutionName,
    transferStatus: {
      label: transaction.TransferStatus,
      badgeClass:
        transaction.TransferStatus == "Successful"
          ? "success"
          : transaction.TransferStatus == "Failed"
          ? "danger"
          : "secondary",
    },
    date: Format.date(transaction.Date),
    withdrawalStatus: {
      label: transaction.WithdrawalStatus,
      badgeClass:
        transaction.WithdrawalStatus == "Successful"
          ? "success"
          : transaction.WithdrawalStatus == "Failed"
          ? "danger"
          : "secondary",
    },
    transferAmount: Format.currency(transaction.TransferAmount),
    withdrawalAmount: Format.currency(transaction.WithdrawalAmount),
    fee: Format.currency(transaction.Fee),
    beneficiaryName: transaction.BeneficiaryName,
    beneficiaryAccount: transaction.BeneficiaryAccount,
    beneficiaryBank: transaction.BeneficiaryBank,
    retrievalReferenceNumber: transaction.TransactionReference,
    stan: transaction.STAN,
    maskedPan: transaction.MaskedPAN,
    agentName: transaction.AgentName,
    agentPhoneNumber: transaction.AgentPhoneNumber,
    statusDetails: transaction.StatusDetails,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    RetrievalReferenceNumber: params["reference"],
    AgentPhoneNumber: params["agentPhoneNumber"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown
}
