import {
  GET_SETTLEMENT_WITHDRAWALS_SUCCESS,
  GET_SETTLEMENT_WITHDRAWALS,
  SETTLEMENT_WITHDRAWAL_SUCCESS,
  SETTLEMENT_WITHDRAWAL,
  SETTLEMENT_WITHDRAWAL_FAIL,
  SEND_WITHDRAWAL_OTP,
  SEND_WITHDRAWAL_OTP_SUCCESS,
  SETTLEMENT_PURGE_SUCCESS,
  SETTLEMENT_WALLET_BALANCE,
  SETTLEMENT_WALLET_BALANCE_SUCCESS,
  SETTLEMENT_WALLET_BALANCE_FAIL,
  SEND_WITHDRAWAL_OTP_FAIL,
  ADD_SETTLEMENT_ACCOUNT,
  ADD_SETTLEMENT_ACCOUNT_FAIL,
  ADD_SETTLEMENT_ACCOUNT_SUCCESS,
  GET_SETTLEMENT_ACCOUNTS,
  GET_SETTLEMENT_ACCOUNTS_SUCCESS,
  ACTIVATE_SETTLEMENT_ACCOUNT,
  ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS,
  CONFIGURE_OTP_RECEIVER_SUCCESS,
  CONFIGURE_OTP_RECEIVER,
  CONFIGURE_OTP_RECEIVER_FAIL,
  WALLET_SETTLEMENT_CONFIGURATION,
  WALLET_SETTLEMENT_CONFIGURATION_FAIL,
  WALLET_SETTLEMENT_CONFIGURATION_SUCCESS,
  GET_WALLET_SETTLEMENT_CONFIGURATION,
  GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS,
  GET_WALLET_TOP_UP_REPORT,
  GET_WALLET_TOP_UP_REPORT_SUCCESS,
  GET_WALLET_TOP_UP_REPORT_FAIL,
  GET_WALLET_ACCOUNT_STATEMENT_REPORT,
  GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL,
  GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  walletAccountStatementReport : [],
  settlementWithdrawals: [],
  balance: 0,
  totalWalletTopUps:0,
  wallet: undefined,
  account: undefined,
  accounts:[],
  transactions : [],
  approver: {},
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  otpSending: false,
  balanceLoading: false,
  statusChanged: undefined
}

export const Settlements = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_WALLET_ACCOUNT_STATEMENT_REPORT:
      return {
        ...state,
        loading : true,
        error : null,
        success : null
      }
    case GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS:
      return {
        ...state,
        walletAccountStatementReport: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        totalWalletTopUps: action.payload?.Data?.TotalAmount || 0,
        loading: false,
      }
    case GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL:
      return {
        ...state,
        error : action.payload,
        success : null,
        loading : false
      }
    case GET_WALLET_TOP_UP_REPORT:
      return {
        ...state,
        loading : true,
        error : null,
        success : null
      }

    case GET_WALLET_TOP_UP_REPORT_SUCCESS:
      return {
        ...state,
        transactions : action.payload?.Data?.Collection || [],
        totalCount : action.payload?.Data?.TotalCount || 0,
        totalWalletTopUps: action.payload?.Data?.TotalAmount || 0,
        loading : false
      }
    
    case GET_WALLET_TOP_UP_REPORT_FAIL:
      return {
        ...state,
        loading : false,
        success : null,
        error : action.payload
      }
      
    case GET_SETTLEMENT_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        settlementWithdrawals: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_SETTLEMENT_WITHDRAWALS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }

    case GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS:

      return {
        ...state,
        wallet: action.payload?.Data,
        loading: false,
      }
    case GET_WALLET_SETTLEMENT_CONFIGURATION:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }

    case GET_SETTLEMENT_ACCOUNTS_SUCCESS:
      return {
        ...state,
        account: ((action.payload?.Data || [])[0]??{}),
        accounts:(action.payload?.Data || []),
        loading: false,
      }
    case GET_SETTLEMENT_ACCOUNTS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }


    case SETTLEMENT_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }

    case SETTLEMENT_WITHDRAWAL:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case SETTLEMENT_WITHDRAWAL_FAIL:
      state = { ...state, error: action.payload?.Message, loading: false, editLoading: false }
      break


    case ADD_SETTLEMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        account: action.payload?.Data,
        editLoading: false
      }

    case ADD_SETTLEMENT_ACCOUNT:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case ADD_SETTLEMENT_ACCOUNT_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break


    case WALLET_SETTLEMENT_CONFIGURATION_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }

    case WALLET_SETTLEMENT_CONFIGURATION:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case WALLET_SETTLEMENT_CONFIGURATION_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break

    case CONFIGURE_OTP_RECEIVER_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }

    case CONFIGURE_OTP_RECEIVER:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case CONFIGURE_OTP_RECEIVER_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break



    case SETTLEMENT_WALLET_BALANCE:
      return {
        ...state,
        balanceLoading: true,
        error: "",
        success: ""
      }
    case SETTLEMENT_WALLET_BALANCE_SUCCESS:
      return {
        ...state,
        error: "",
        balance: action.payload.Data,
        editLoading: false
      }

    case SETTLEMENT_WALLET_BALANCE_FAIL:
      state = { ...state, error: action.payload, loading: false, editLoading: false }
      break


    case SEND_WITHDRAWAL_OTP:
      state = {
        ...state,
        otpSending: true,
        error: "",
        success: ""
      }
      break
    case SEND_WITHDRAWAL_OTP_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        otpSending: false,
        error: ""
      }
      break
    case SEND_WITHDRAWAL_OTP_FAIL:
      state = { ...state, error: action.payload, loading: false, otpSending: false }
      break;

    case ACTIVATE_SETTLEMENT_ACCOUNT:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        account: action.payload.Data,
        error: "",
        success: action.payload?.Message
      }
    case SETTLEMENT_PURGE_SUCCESS:

      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Settlements
