import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"
import Account from "./auth/register/reducer"
import ForgetPassword from "./auth/forgetpwd/reducer"
import Profile from "./auth/profile/reducer"
import Users from "./auth/users/reducer"
import Roles from "./auth/roles/reducer"

//agents
import agents from "./agent/reducer"

//permissions
import permissions from "./permission/reducer"

//reports
import reports from "./reports/reducer"

//branch
import branches from "./branch/reducer"
import misc from "./misc/reducer"
import caseLogs from "./caselog/reducer"

//cluster manager
import clusterManagers from "./agent-cluster-manager/reducer"
//cluster
import clusters from "./cluster/reducer"

//network manager
import networkManagers from "./agent-network-manager/reducer"

import categories from "./agent-category/reducer"

import commissions from "./agent-commission/reducer"
import commissionStructures from "./agent-commission-structure/reducer"
import mobileAccounts from "./mobile-account/reducer"
import chargeBacks from "./chargeback/reducer"
import institutions from "./institution/reducer"
import cashOutInstitutions from "./cashout-institution/reducer"
import terminals from "./terminal/reducer"
import features from "./features/reducer"
import subscriptions from "./subscriptions/reducer";
import auditTrails from "./audit-trail/reducer";
import settlements from "./settlement/reducer";
import routes from "./route/reducer"
import unsettledTransactions from "./unsettled-transactions/reducer"
import debitRequests from "./direct-debit/reducer"
import notifications from "./notification/reducer"

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  Users,
  Roles,
  ForgetPassword,
  Profile,
  permissions,
  agents,
  reports,
  branches,
  clusterManagers,
  clusters,
  networkManagers,
  categories,
  misc,
  commissions,
  commissionStructures,
  mobileAccounts,
  caseLogs,
  chargeBacks,
  institutions,
  terminals,
  subscriptions,
  auditTrails,
  features,
  cashOutInstitutions,
  settlements,
  routes,
  unsettledTransactions,
  debitRequests,
  notifications
})

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === "LOGOUT_USER") {
    state = undefined
  }

  return appReducer(state, action)
}

// export default rootReducer;
export default rootReducer
