import {
  call,
  put,
  all,
  fork,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"

// Login Redux States
import {
  CREATE_ROLE,
  ENABLE_USER_ROLE,
  GET_ROLES,
  GET_ALL_ROLES,
  GET_ROLE_DETAILS,
  PURGE_USER_ROLES,
  UPDATE_USER_ROLE,
} from "./actionTypes"
import {
  roleApiError,
  enableRoleSuccess,
  getRoleDetailSuccess,
  getRoleSuccess,
  postRoleSuccess,
  purgeRoleSuccess,
  updateRoleSuccess,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  getClusterRoles,
  getAllClusterRoles,
  postClusterRole,
  getClusterRoleDetail,
  putClusterRole,
  enableClusterRole,
  disableClusterRole,
} from "helpers/backend_helper"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import { UserCategory } from "enums/user-category"

const fireBaseBackend = getFirebaseBackend()
function* fetchRoles({ params }) {
  const authUser = authHeader().AuthUser
  const institutionId = authUser?.userCategory == UserCategory.MFB
    ? authUser.institutionId
    : GetInstitution().remoteInstitutionId;
  try {
    let response = yield call(
      getClusterRoles,
      { InstitutionId: institutionId, ...JSON.parse(params) }
    )

    yield put(getRoleSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(roleApiError(error.data))
  }
}

function* fetchAllRoles() {
  const authUser = authHeader().AuthUser
  const institutionId = authUser?.userCategory == UserCategory.MFB
    ? authUser.institutionId
    : GetInstitution().remoteInstitutionId;
  try {
    let response = yield call(
      getAllClusterRoles,
      institutionId
    )

    yield put(getRoleSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(roleApiError(error.data))
  }
}


function* createRole({ payload: { role } }) {
  const authUser = authHeader().AuthUser
  try {
    role["UserCategory"] = authUser?.userCategory
    const response = yield call(postClusterRole, {
      ...JSON.parse(JSON.stringify(role)),
      institutionId:
        authUser?.userCategory == UserCategory.MFB
          ? authUser?.institutionId
          : GetInstitution().remoteInstitutionId,
    })
    yield put(postRoleSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(roleApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* updateRole({ payload: { role } }) {
  const authUser = authHeader().AuthUser
  try {
    // console.debug(role)

    const response = yield call(putClusterRole, {
      ...JSON.parse(JSON.stringify(role)),
      institutionId:
        authUser?.userCategory == UserCategory.MFB
          ? authUser?.institutionId
          : GetInstitution().remoteInstitutionId,
    })

    yield put(updateRoleSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(roleApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* fetchRoleDetails(payload) {
  const authUser = authHeader().AuthUser
  try {
    const response = yield call(getClusterRoleDetail, payload.id)
    yield put(getRoleDetailSuccess(response))
  } catch (error) {
    yield put(roleApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doEnableRole(payload) {
  const authUser = authHeader().AuthUser
  try {
    let toEnable = true
    // console.debug(role)
    let cta = enableClusterRole
    if (!payload.enable) {
      cta = disableClusterRole
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableRoleSuccess(toEnable))
  } catch (error) {
    console.debug(error)
    yield put(roleApiError(error?.data ?? "Sorry an error occurred, Kindly retry"))
  }
}

function* doPurge() {
  yield put(purgeRoleSuccess())
}

function* roleSaga() {
  yield takeLeading(GET_ROLES, fetchRoles)
  yield takeLeading(GET_ALL_ROLES, fetchAllRoles)
  yield takeLeading(GET_ROLE_DETAILS, fetchRoleDetails)
  yield takeLeading(UPDATE_USER_ROLE, updateRole)
  yield takeEvery(PURGE_USER_ROLES, doPurge)
  yield takeLeading(CREATE_ROLE, createRole)
  yield takeLeading(ENABLE_USER_ROLE, doEnableRole)
}

export default roleSaga
