import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import moment from "moment"
import { Col, Row, CardTitle, FormGroup, Label, Input } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterInput from "components/Common/Input/Input"
import { SEARCHOPTIONS } from "helpers/variables"
import Loading from "../Loading"
import withMinimize from "components/HOCs/WithMinimize"
import ClusterCheckBox from "../CheckBox"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import { Validators } from "helpers/validators"
import { Utils } from "helpers/utility"
import { Formik } from "formik"

const SearchSection: React.FC<DisposeProps & any> = props => {
  let { title, handleOnSearch, options, rowClass } = props
  if (options == undefined) options = SEARCHOPTIONS
  const [myOptions, setOptions] = useState(options)
  const [fields, setFields] = useState(options?.fields)
  const [myRange, setRange] = useState(options?.range)
  const [dateSearchEnabled, setSearchEnabled] = useState(true)
  const [fromDate, setFromDate] = useState(myRange?.from)
  const [toDate, setToDate] = useState(myRange?.to)
  const [defaultFromDate, setDefaultFromDate] = useState<string>()
  const [defaultToDate, setDefaultToDate] = useState<string>()

  useEffect(() => {
    if (props.options) {
      setOptions(props.options)
      setFields(props.options.fields)
      setRange(props.options.range)
      setSearchEnabled(props.options.range.enabled)
    }
  }, [props])

  useEffect(() => {
    if (props?.options?.range?.enabled) {
      setDefaultFromDate(moment(props.options.range.from).format("YYYY-MM-DD"))
      setDefaultToDate(moment(props.options.range.to).format("YYYY-MM-DD"))
    }
  }, [])

  const search = criteria => {
    if (fields.find(field => field.name == "dateRequested") && !criteria["dateRequested"]) {
      criteria["dateRequested"] = fields.find(field => field.name == "dateRequested").defaultValue;
    }
    criteria["page"] = 1
    return handleOnSearch(criteria)
  }
  const reset = (resetForm: any) => {
    let opts = Object.assign({}, SEARCHOPTIONS)
    opts.fields.map(field => {
      switch (field.type) {
        case "dropdown":
          break
        default:
          field.defaultValue = ""
          break
      }
      return field
    })
    let searchCriteria = {}
    fields.forEach(field => {
      delete searchCriteria[field.name];
    })
    setFields([])
    searchCriteria = { ...searchCriteria, fromDate: defaultFromDate, toDate: defaultToDate }
    setFromDate(moment(defaultFromDate).format("YYYY-MM-DD"))
    setToDate(moment(defaultToDate).format("YYYY-MM-DD"))
    search(searchCriteria);
    resetForm()
  }
  return (
    <Row className={rowClass}>
      <Col lg={12}>
        {!title ? (
          <React.Fragment></React.Fragment>
        ) : (
          <CardTitle className="mb-4">{title}</CardTitle>
        )}
        <Formik
          initialValues={{ fromDate: moment(fromDate).format("YYYY-MM-DD"), toDate: moment(toDate).format("YYYY-MM-DD") }}
          validate={() => { }}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => setSubmitting(false), 30000);
            search(values)
          }}
        >
          {({
            values,
            errors,
            touched,
            isValid,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            resetForm,
            isSubmitting,
          }) => (

            <form onSubmit={handleSubmit}>
              <Row>
                {dateSearchEnabled ? (
                  <React.Fragment>
                    <Col lg={2} sm={3}>
                      <FormGroup>
                        <Label for="fromDate">From Date</Label>

                        <Input
                          type="date"
                          value={moment(values.fromDate).format("YYYY-MM-DD")}
                          id="fromDate"
                          name={"fromDate"}
                          onBlur={handleBlur}
                          onChange={e =>
                            setFieldValue(
                              "fromDate",
                              moment(e.target.value)
                                .startOf("day")
                                .format("YYYY-MM-DDTHH:mm:ss")
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={2} sm={3}>
                      <FormGroup>
                        <Label for="toDate">To Date</Label>
                        <Input
                          type="date"
                          value={moment(values.toDate).format("YYYY-MM-DD")}
                          id="toDate"
                          name="toDate"
                          onBlur={handleBlur}
                          onChange={e =>
                            setFieldValue(
                              "toDate",
                              moment(e.target.value)
                                .endOf("day")
                                .format("YYYY-MM-DDTHH:mm:ss")
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                {!Utils.List.isEmpty(fields) && fields.slice(0, fields.length - 1).map((field, key) =>
                (<SearchField
                  key={`search_field_${key}`}
                  type={field.type}
                  name={field.name}
                  id={field.id}
                  placeholder={field.placeholder}
                  label={field.label}
                  options={field.data}
                  defaultValue={field.defaultValue}
                  width={field.width}
                  validators={field.validators}
                  handleChange={setFieldValue}
                  value={values[field.name]}
                />))}

                {fields.slice(-1).map((field, key) =>
                  field.label == 'All Institutions' ?
                    field.isRemote &&
                    (<SearchField
                      key={`search_field_${key}`}
                      type={field.type}
                      name={field.name}
                      id={field.id}
                      placeholder={field.placeholder}
                      label={field.label}
                      options={field.data}
                      defaultValue={field.defaultValue}
                      width={field.width}
                      validators={field.validators}
                      handleChange={setFieldValue}
                      value={values[field.name]}
                      isRemote={field.isRemote}
                    />) : <SearchField
                      key={`search_field_${key}`}
                      type={field.type}
                      name={field.name}
                      id={field.id}
                      placeholder={field.placeholder}
                      label={field.label}
                      options={field.data}
                      defaultValue={field.defaultValue}
                      width={field.width}
                      validators={field.validators}
                      handleChange={setFieldValue}
                      value={values[field.name]} />)}

                {myOptions.actions.enabled ? (
                  <React.Fragment>
                    <Col lg={1} md={2} sm={3}>
                      <Label> </Label>
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                        className="btn btn-primary waves-effect waves-light mb-2 animate__animated animate__fadeInUp"

                        disabled={!isValid}
                        type={"submit"}
                      >
                        <i
                          className="bx bx-search"
                          style={{ marginRight: "5px" }}
                        ></i>{" "}
                        Search
                      </button>
                    </Col>
                    <Col lg={1} md={2} sm={3}>
                      <Label> </Label>
                      <button
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "10px",
                        }}
                        type={"button"}
                        className="btn btn-dark waves-effect waves-light mb-2 animate__animated animate__fadeInUp"
                        onClick={e => {
                          e.preventDefault()
                          reset(resetForm)
                        }}

                      >
                        <i
                          className="bx bx-reset"
                          style={{ marginRight: "5px" }}
                        ></i>{" "}
                        Reset
                      </button>
                    </Col>
                  </React.Fragment>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </Row>
            </form>)}
        </Formik>
      </Col>
    </Row>
  )
}

SearchSection.propTypes = {
  handleOnSearch: PropTypes.func,
  title: PropTypes.string,
  options: PropTypes.object,
  rowClass: PropTypes.string,
}

export default withDispose(withMinimize(SearchSection))

//key={`_dropdown_${key}`}
const SearchField = (props) => {
  const { name, value, type, width, id, label, options, handleChange, placeholder, defaultValue, validators, isRemote } = props;
  switch (type) {
    case "dropdown":
      return (
        <Col lg={width || 1} md={3} >
          <FormGroup>
            {label != undefined ? (
              <Label for={id}>{label}</Label>
            ) : (
              <React.Fragment></React.Fragment>
            )}
            {!options ? (
              <Loading></Loading>
            ) : (
              <ClusterDropdown
                name={name}
                placeholder={placeholder}
                items={options}
                // defaultValue={defaultValue}
                onChange={(key, value) => handleChange(name, value)}
              ></ClusterDropdown>
            )}
          </FormGroup>
        </Col>
      )
    case "checkbox":
      return (
        <Col
          style={{ display: "flex", alignItems: "center" }}
          lg={width || 1}
          md={3}
        >
          <FormGroup style={{ display: "flex", alignItems: "center" }}>
            <ClusterCheckBox
              onHandleChange={(name, value) => handleChange(name, value)}
              label={label}
              name={name}
              id={id}
              checked={!defaultValue ? false : true}
            ></ClusterCheckBox>
          </FormGroup>
        </Col>
      )
    default:
      return (
        <Col lg={width || 2} md={3} >

          <ClusterInput
            defaultValue={defaultValue}
            onError={err => {
              // let list = { ...errorList };
              // list[name] = err;
              // setErrorList(list);
              // let validation = Validators.validateForm(list)
              // if (validation) {
              //   setFormInvalid(true)
              // } else {

              //   setFormInvalid(false)
              // }
            }}
            label={label}
            id={id}
            name={name}
            value={value}
            validators={validators}
            type={type}
            onHandleChange={handleChange}
            isRemote={false}
          />
        </Col>
      )
  }
}
