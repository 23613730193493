import React, {  useMemo } from "react"
import "assets/css/logo.css"
import ImageHelper from "helpers/image-helper"
import { ImageType } from "enums/image-type"
import { ImageSize } from "enums/image-size"
 enum ImageValueType {
  Base64="base64",
  Url="url",
  Default="default"
 }

const ClusterImageViewer =props=>{
    const { url, base64,  type = ImageType.Default, size} =props
   
   const renderType = useMemo(()=>{
    if(base64) return ImageValueType.Base64;
    if(url) return ImageValueType.Url;
    return ImageValueType.Default
   },[url,base64])

    const versionedUrl = useMemo(()=>{
      
      if(!url) return url;
      const timestamp = Date.now();
      const separator = url.includes('?') ? '&' : '?';
      const updatedUrl = `${url}${separator}timestamp=${timestamp}`;
    
      return updatedUrl;
    },[url])

    const [aspectRatioWidth, aspectRatioHeight] = [1,1];
    const sizing = useMemo(()=> {
      if(size==ImageSize.sm) return 50;
      if(size==ImageSize.md) return 75;
      if(size==ImageSize.lg) return 100;
      if(size==ImageSize.xl) return 150;
      return 200;
    }, [size])

    const dimensions = useMemo(()=> {
      const width = sizing * (aspectRatioWidth / aspectRatioHeight);
      const height = width * aspectRatioHeight / aspectRatioWidth;

      return {width,height}
    }, [sizing])

    const width = useMemo(()=> {
      return dimensions.width+"px"
    }, [dimensions])

    const height = useMemo(()=> {
     return dimensions.height+"px"
    }, [dimensions])

    if(renderType == ImageValueType.Base64)  return <div className="iconBody" style={{ width: width, height: height, borderRadius: type==ImageType.Profile?"100%":"0" }}>
    <img
       style={{ width: "100%" }}
       src={ base64}
       
     />
  </div>
    if(renderType == ImageValueType.Url) return  <div className="iconBody" style={{backgroundImage:`url('${versionedUrl }')`,  width: width, height: height, borderRadius: type==ImageType.Profile?"100%":"0", backgroundSize:"cover", backgroundPosition:"center"  }}/>
    
   return <div className="iconBody" style={{ width: width, height: height }}>
   <img
      style={{ width: "100%", height:"auto" }}
      src={ ImageHelper.defaultLogoBackground}
      
    />
 </div>
  }

  export default ClusterImageViewer