import { defaultObj } from "helpers/utility"
import {
  GET_CASELOG_DETAIL_SUCCESS,
  GET_CASELOG_DETAIL_FAIL,
  GET_CASELOG_MESSAGES_SUCCESS,
  GET_CASELOG_MESSAGES_FAIL,
  GET_CASELOGS_SUCCESS,
  GET_CASELOGS_FAIL,
  CASELOG_PURGE,
  ENABLE_CASELOG,
  ENABLE_CASELOG_SUCCESS,
  CASELOG_PURGE_SUCCESS,
  GET_CASELOGS,
  POST_CASELOG_SUCCESS,
  UPDATE_CASELOG,
  CREATE_CASELOG,
  UPDATE_CASELOG_SUCCESS,
  POST_CASELOG_FAIL,
  GET_ALL_CASELOGS,
  SEND_CASEMESSAGE,
  SEND_CASEMESSAGE_FAIL,
  SEND_CASEMESSAGE_SUCCESS,
  RESOLVE_CASE,
  RESOLVE_CASE_FAIL,
  RESOLVE_CASE_SUCCESS,
  MARK_AS_READ_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  caseLogs: [],
  caseLogDetail: {},
  messages: [],
  error: {},
  success: undefined,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const CaseLogs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CASELOGS_SUCCESS:
      return {
        ...state,
        caseLogs: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_CASELOGS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_ALL_CASELOGS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_CASELOGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_CASELOG_DETAIL_SUCCESS:
      return {
        ...state,
        caseLogDetail: action.payload?.Data,
        loading: false,
      }

    case GET_CASELOG_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_CASELOG_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload?.Data ?? [],
        success: action.payload?.Message,
        loading: false,
      }

    case GET_CASELOG_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case UPDATE_CASELOG:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case CREATE_CASELOG:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case POST_CASELOG_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case POST_CASELOG_SUCCESS:
      return {
        ...state,
        caseLog: defaultObj(state.caseLog), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case SEND_CASEMESSAGE:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case SEND_CASEMESSAGE_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case SEND_CASEMESSAGE_SUCCESS:
      let newMessages = action.payload?.Data
        ? [...state.messages, action.payload?.Data]
        : state.messages
      return {
        ...state,
        messages: newMessages,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case MARK_AS_READ_SUCCESS:
      return {
        ...state,
      }
    case RESOLVE_CASE:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }

    case RESOLVE_CASE_FAIL:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }
      break

    case RESOLVE_CASE_SUCCESS:
      let detail = state.caseLogDetail
      detail.IsResolvedBySupport = true
      return {
        ...state,
        caseLogDetail: detail,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
      }
    case UPDATE_CASELOG_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break

    case ENABLE_CASELOG:
      state = {
        ...state,
        editLoading: true,

        error: "",
        success: "",
      }
      break
    case ENABLE_CASELOG_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        statusChanged: action.payload,
      }
    case CASELOG_PURGE_SUCCESS:
      return INIT_STATE
    // return {
    //   ...state,
    //   accountEnquiry: {},
    //   caseLogDetail:{},
    //   error:{},
    //   success:undefined
    // }

    default:
      return state
  }
  return state
}

export default CaseLogs
