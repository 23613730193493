import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Row, Col, Collapse } from "reactstrap"
import withDispose, { DisposeProps } from "components/HOCs/WithDispose"
import { Icons } from "enums/icons"
import ClusterToggle from "../../../toggle";
import { AccordionOption } from "types/accordion-option";
import { Utils } from "helpers/utility"
import NoData from "components/Common/NoData"

type AccordionBodyProps = DisposeProps & {
    isOpen: boolean,
    onChange: (id, value) => any,
    items: AccordionOption[]
}
const AccordionBody: React.FC<AccordionBodyProps> = props => {
    const { items, onChange, isOpen } = props
    const [children, setChildren] = useState(items)
    useEffect(() => {
        setChildren(items)
    }, [items])

    if(Utils.List.isEmpty(children)){
        return <Row className="mt-3 ml-1"> <Col lg={12} md={12} sm={12} > <NoData/> </Col></Row>
    }
    return <Row className="mt-3 ml-1">
        <Col lg={1} md={1} sm={1} />
        <Col lg={11}>
            <Collapse isOpen={isOpen}>
                {children.map((item, key) => {
                    return (
                        <ClusterToggle
                            key={key}
                            name={item.name}
                            label={item.label}
                            id={item.id}
                            checked={item.checked}
                            value={item.value}
                            disabled={item.disabled}
                            icon={item.icon}
                            onChange={onChange}
                        />
                    )
                })}
            </Collapse>
        </Col>
    </Row>
}

AccordionBody.propTypes = {
    items: PropTypes.array,
    isOpen: PropTypes.bool,
}

export default withDispose(AccordionBody)
