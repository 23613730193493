import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
  ModalFooter,
  Badge,
} from "reactstrap"
import ClusterInput from "components/Common/Input/Input"
import clusterian from "../../../assets/images/icons/clusterian.png"
import ClusterForm from "components/Common/Form"
import ClusterButton from "components/Common/Button/Button"
import { Validators } from "helpers/validators"
import withFormValidation from "components/HOCs/WithFormValidation"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import AgentLogic from "../agent"
import { GET_ALL_TERMINALS, GET_TERMINALS } from "helpers/url_helper"
import useSWR from "swr"
import Loading from "components/Common/Loading"
import { fetcher } from "helpers/api_helper"
const LinkToPOSModal = props => {
  const {
    open,
    onChangeModal,
    onLinkToPos,
    agent,
    loading,
    myError,
    setErrorList,
    errorList,
  } = props
  const [isOpen, setOpen] = useState(open)
  const [submitted, setSubmitted] = useState()
  const [terminal, setTerminal] = useState({})
  const { data, error } = useSWR(
    `${GET_ALL_TERMINALS}/GetUnassigned/false`,
    fetcher
  )
  const { MapToDropDownTerminal } = AgentLogic
  const handleOnChange = (key, value) => {
    let u = terminal
    u[key] = value
    setTerminal(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()

    onLinkToPos({ ...terminal, id: agent.ID, link: true })

    setTimeout(() => {
      if (!myError) changeOpen(false)
    }, 1000)
  }
  const changeOpen = status => {
    setOpen(status)
    onChangeModal(status)
  }
  useEffect(() => {
    setOpen(open)
  }, [open])

  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={() => {
        changeOpen(!isOpen)
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            changeOpen(!isOpen)
          }}
        >
          Link To POS Terminal
        </ModalHeader>
        <ModalBody>
          <ClusterForm handleSubmit={handleValidSubmit}>
            <React.Fragment>
              <Card
                outline
                style={{ background: "transparent", borderColor: "#999999" }}
                className="border"
              >
                <CardBody>
                  <Row>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        defaultValue={agent?.LastName + " " + agent?.OtherNames}
                        disabled
                        label={"Name"}
                        id={"m-name"}
                        type="text"
                        required={true}
                      ></ClusterInput>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <ClusterInput
                        defaultValue={agent?.PhoneNumber}
                        disabled
                        label={"Phone Number"}
                        id={"m-phoneno"}
                        type="text"
                        required={true}
                      ></ClusterInput>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={4} md={4} sm={6}>
                      <FormGroup>
                        <Label>Terminal ID</Label>
                        {!data?.Data ? (
                          <Loading></Loading>
                        ) : (
                          <ClusterDropdown
                            type={"type-ahead"}
                            items={MapToDropDownTerminal(data.Data)}
                            onChange={(name, value) => {
                              handleOnChange("terminalID", value)
                              setErrorList({ ...errorList, terminalID: "" })
                            }}
                          ></ClusterDropdown>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </React.Fragment>
            <Row style={{ paddingBottom: "50px" }}>
              <Col lg={12}>
                <ClusterButton
                  loading={loading}
                  disabled={myError != "" ? true : false}
                  color={"primary"}
                  addon={"bx bx-save"}
                >
                  Link
                </ClusterButton>
              </Col>
            </Row>
          </ClusterForm>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </div>
    </Modal>
  )
}

LinkToPOSModal.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool,
  onChangeModal: PropTypes.func,
  onLinkToPos: PropTypes.func,
  agent: PropTypes.object,
}

export default withFormValidation(LinkToPOSModal)
