import authHeader from "helpers/authentication/auth-token-header"
import AuthUser from "helpers/authentication/authUser"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import {
  AGENTTYPE,
  BASESTATUS,
  Format,
  GETACCOUNTMODE,
  GETAGENTTYPE,
  GETGENDER,
  GETSTATUS,
  SEARCHOPTIONS,
} from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Request Date", name: "requestDate", entityKeyName: "RequestDate" },
  { title: "Transfer Status", name: "transferStatus", entityKeyName: "TransferStatus", isBadge: true },
  {
    title: "Amount",
    name: "amount",
    entityKeyName: "Amount",
  },
  { title: "Beneficiary Account Name", name: "beneficiaryAccountName", entityKeyName: "BeneficiaryAccountName" },
  { title: "Beneficiary Account Bank", name: "beneficiaryAccountBank", entityKeyName: "BeneficiaryBankName" },
  { title: "Beneficiary Account Number", name: "beneficiaryAccountNumber", entityKeyName: "BeneficiaryAccountNumber" },
  { title: "Originator Account Name", name: "originatorAccountName", entityKeyName: "OriginatorAccountName" },
  // { title: "Originator Account Bank", name: "originatorAccountBank", entityKeyName: "OriginatorAccountBank" },
  { title: "Originator Account Number", name: "originatorAccountNumber", entityKeyName: "OriginatorAccountNumber" },
  {
    title: "Agent Phone Number",
    name: "agentPhoneNumber",
    entityKeyName: "AgentPhoneNumber",
  },
  {
    title: "Funds Transfer Type",
    name: "fundsTransferType",
    entityKeyName: "FundsTransferType",
  },
  { title: "Payment Reference", name: "paymentReference", entityKeyName: "PaymentReference" },
  {
    title: "Reference Number",
    name: "referenceNumber",
    entityKeyName: "ReferenceNumber",
  },
  {
    title: "SessionID",
    name: "sessionID",
    entityKeyName: "SessionID",
  },
  { title: "Reversal Status", name: "reversalStatus", entityKeyName: "ReversalStatus" },
  {
    title: "NIP Response Code",
    name: "nipResponseCode",
    entityKeyName: "NIPResponseCode",
  },
  { title: "Name Enquiry Session ID", name: "nameEnquirySessionID", entityKeyName: "NameEnquirySessionID" },

]

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "phoneNumber",
    },
    {
      type: "text",
      label: "Originator Account",
      id: "s-originatorAccountNumber",
      defaultValue: "",
      name: "originatorAccountNumber",
    },
    {
      type: "text",
      label: "Beneficiary Account",
      id: "s-beneficiaryAccountNumber",
      defaultValue: "",
      name: "beneficiaryAccountNumber",
    },
    {
      type: "dropdown",
      label: "FundsTransfer Type",
      id: "fundsTransferType",
      defaultValue: "- Select a type -",
      data: [
        { id: "InterBankTransfer ", value: "InterBankTransfer ", key: 0 },
        { id: "IntraBankTransfer ", value: "IntraBankTransfer ", key: 1 },
      ],
      name: "reportType",
      width: 3,
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  return options;
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    institution: transaction.InstitutionName,
    transferStatus: {
      label: transaction.TransferStatus,
      badgeClass:
        transaction.TransferStatus == "Successful" ? "success" : "secondary",
    },
    agentPhoneNumber: transaction.AgentPhoneNumber,
    requestDate: transaction.RequestDate,
    amount: transaction.Amount,
    beneficiaryAccountName: transaction.BeneficiaryAccountName,
    beneficiaryAccountBank: transaction.BeneficiaryBankName,
    beneficiaryAccountNumber: transaction.BeneficiaryAccountNumber,
    originatorAccountName: transaction.OriginatorAccountName,
    originatorAccountBank: transaction.OriginatorAccountBank,
    originatorAccountNumber: transaction.OriginatorAccountNumber,
    fundsTransferType: Utils.Strings.camelPad(transaction.FundsTransferType),
    paymentReference: transaction.PaymentReference,
    referenceNumber: transaction.ReferenceNumber,
    sessionID: transaction.SessionID,
    reversalStatus: Utils.Strings.camelPad(transaction.ReversalStatus),
    nipResponseCode: transaction.NIPResponseCode,
    nameEnquirySessionID: transaction.NameEnquirySessionID,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    BeneficiaryAccountNumber: params["beneficiaryAccountNumber"],
    OriginatorAccountNumber: params["originatorAccountNumber"],
    AgentPhoneNumber: params["phoneNumber"],
    ReportType: params["reportType"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}

const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name  }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
