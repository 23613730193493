import { Gender } from "enums/gender"
import { IntegrationType } from "enums/integration-type"
import { GetInstitution } from "helpers/authentication/institution"
import { SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
import { AccordionOption } from "types/accordion-option"

export type InstitutionBasicDetails = {

  Name: string,
  BaseURI: string,
  Code?: string,
  TokenLifeSpan?: number,
  Address: string,
  IntegrationType: IntegrationType,
  ServiceProviderAccountNumber: string,
  AntiMoneyLaunderingLimit: number,
  WalletAccountEnabled: boolean
}

export type InstitutionWalletDetails = {
  WalletCentralAccount: string,
  WalletThirdPartyMirrorAccount: string,
  WalletBaseURL: string,
  WalletProductCode: string,
  WalletAccountOfficerCode: string
}

export type InstitutionSuperAdmin = {
  ID?:number,
  LastName: string,
  FirstName: string,
  OtherName: string,
  Username: string,
  Gender: Gender,
  Email: string,
  PhoneNumber: string,
  Street: string,
  City: string,
  State: string
}
export type Institution = InstitutionBasicDetails & InstitutionWalletDetails  & {
  ID?: number,
  IsActive: boolean,
  Features: any[],
  FeatureIds:number[],
  User: InstitutionSuperAdmin,
  UserID?:number
}


const HEADERS = [
  { title: "Status", name: "status", entityKeyName: "IsActive", isBadge: true },
  { title: "Name", name: "name", entityKeyName: "Name" },
  { title: "Code", name: "code", entityKeyName: "Code" },
  {
    title: "Integration Type",
    entityKeyName: "IntegrationType",
    name: "integrationType"
  },
  { title: "Anti-Money Laundering Limit", name: "antiMoneyLaunderingLimit", entityKeyName: "AntiMoneyLaunderingLimit" },
  { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
  name: undefined,
  code: "",
  integrationType: undefined,
  baseURI: undefined,
  antiMoneyLaunderingLimit: undefined,
  appzoneAccount: undefined,
  walletAccountEnabled: "",
  walletCentralAccount: "",
  mirrorCentralAccount: "",
  walletBaseUrl: "",
  walletProductCode: "",
  walletAccountCode: "",
  street: undefined,
  city: undefined,
  email: undefined,
  firstName: undefined,
  gender: undefined,
  lastName: undefined,
  otherNames: "",
  phoneNumber: undefined,
  state: undefined,
  username: undefined,
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)

  options.range.from = startDate.format("YYYY-MM-DD")
  options.range.to = endDate.format("YYYY-MM-DD")
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Name",
      id: "s-name",
      defaultValue: "",
      name: "name",
    },
    {
      type: "text",
      label: "Code",
      id: "s-code",
      defaultValue: "",
      name: "code",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    },
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name }))
  }
  return []
}
const MapToRows = institution => {
  return {
    id: institution.ID,
    name: institution.Name,
    code: institution.Code,
    institution: institution.InstitutionName,
    status: {
      label: institution.IsActive ? "Active" : "Inactive",
      badgeClass: institution.IsActive ? "success" : "secondary",
    },
    integrationType: institution.IntegrationType,
    antiMoneyLaunderingLimit: institution.AntiMoneyLaunderingLimit,
  }
}


const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Name: parameters["name"],
    Code: parameters["code"],
    InstitutionCode:
      parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

const MapToModalState = institutionDetail => {
  let details = [
    {
      title: "",
      items: [
        {
          id: "name",
          label: "Name",
          name: "name",
          value: institutionDetail.Name,
        },
        {
          id: "shortName",
          label: "Short Name",
          name: "shortName",
          value: institutionDetail.ShortName,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: institutionDetail.Code,
        },
        {
          id: "address",
          label: "Address",
          name: "address",
          value: institutionDetail.Address,
        },
        {
          id: "tokenLifeSpan",
          label: "Token Life Span",
          name: "tokenLifeSpan",
          value: institutionDetail.TokenLifeSpan,
        },
        {
          id: "integrationType",
          label: "Integration Type",
          name: "integrationType",
          value: institutionDetail.IntegrationType,
        },
        {
          id: "antiMoneyLaunderingLimit",
          label: "Anti Money Laundering Limit",
          name: "antiMoneyLaunderingLimit",
          value: institutionDetail.AntiMoneyLaunderingLimit,
        }
      ],
    },
  ]

  if (institutionDetail.WalletAccountEnabled) {
    details.push({
      title: "Wallet Details",
      items: [
        {
          id: "walletCentralAccount",
          label: "Wallet Central Account",
          name: "walletCentralAccount",
          value: institutionDetail.WalletCentralAccount,
        },
        {
          id: "mirrorCentralAccount",
          label: "Mirror Central Account",
          name: "mirrorCentralAccount",
          value: institutionDetail.WalletThirdPartyMirrorAccount,
        },
        {
          id: "walletBaseUrl",
          label: "Wallet Base Url",
          name: "walletBaseUrl",
          value: institutionDetail.WalletBaseURL,
        },
        {
          id: "walletProductCode",
          label: "Wallet Product Code",
          name: "walletProductCode",
          value: institutionDetail.WalletProductCode,
        },
        {
          id: "walletAccountCode",
          label: "Wallet Account Code",
          name: "walletAccountCode",
          value: institutionDetail.WalletAccountOfficerCode,
        }
      ],
    })
  }
  return details;
}

const MapFeatures = (features: any[], institution: any, disabled: boolean): AccordionOption[] => {
  const institutionFeatures = institution?.Features;
  return features ? features.map(feature => {
    const isChecked = institutionFeatures && institutionFeatures.some(i => i.ID == feature.ID);
    return { id: feature.ID, name: (feature.Code + feature.ID), label: feature.Name, value: feature.ID, checked: isChecked, disabled }
  }) : []
}

export default {
  HEADERS,
  ERRORLIST,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  MapToDropDown,
  MapFeatures
}
