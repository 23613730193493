import { GRAPHQL_ENDPOINT } from "helpers/api_helper"
import authHeader from "helpers/authentication/auth-token-header"
import { GetInstitution } from "helpers/authentication/institution"
import { useEffect, useCallback, useState } from "react"

export function useGraphql(query, variables = {}, deps) {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  const loadData = useCallback(() => {
    setLoading(true)
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
    fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify({
        query,
        variables,
      }),
    })
      .then(response => response.json())
      .then(json => {
        setLoading(false)
        setData(json.data)
        // showToast()
        // alert(loading)
      })
      .catch(error => {
        setLoading(false)
        setError(error?.message)
        // showToast("Sorry","We could not get data at the moment, please retry","error")
        // notification.error({
        //   key: "GRAPHQL",
        //   message: "Sorry",
        //   description: "We could not get data at the moment, please retry"//error?.message,
        // });
      })
  }, [query, variables])

  useEffect(() => {
    let componentMounted = true;
    const fetchData = async () => {
      //you async action is here
      if (componentMounted) {
        loadData()
      }
    };
    fetchData();
    return () => {
      componentMounted = false;
    }
  }, deps)
  return [data, { loading, reload: loadData, error }, setData, setLoading]
}

export async function executeQuery(query, variables?:any):Promise<any> {
  return new Promise(resolve => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }

    const token = authHeader().AnalyticsAccessToken
    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    }

    fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify({ query, variables }),
    })
      .then(response => response.json())
      .then(resolve)
      .catch(err => {
        console.error(err)
        resolve({ data: null, errors: [err] })
      })
  })
}

export async function executeMutation(operationName, query, variables) {
  return new Promise(resolve => {
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    }

    const token = localStorage.getItem("token")
    if (token) {
      headers["Authorization"] = `Bearer ${token}`
    }
    fetch(GRAPHQL_ENDPOINT, {
      method: "POST",
      headers,
      credentials: "include",
      body: JSON.stringify({ query, variables, operationName }),
    })
      .then(response => response.json())
      .then(resolve)
      .catch(err => {
        console.error(err)
        resolve({ data: null, errors: [err] })
      })
  })
}
export async function signInToAnalyticsWithEmailAndPassword(
  identifier,
  password,
  institutionCode
) {
  return new Promise(resolve => {
    executeMutation(
      "AuthenticateMutation",
      `
    mutation AuthenticateMutation($input: AuthenticateInput!) {
      authenticate(input: $input) {
        status
        message
        user {
          uid: iD
          lastName
          firstName
        }
        token {
          accessToken
        }
      }
    }
    `,
      {
        input: { identifier, password, institutionCode },
      }
    ).then(({ data, errors }) => {
      if (errors?.length) {
        resolve({
          message: errors[0]?.message ?? "An error occurred",
        })
      }

      if (data?.authenticate?.status) {
        localStorage.setItem("token", data?.authenticate?.token?.accessToken)
        resolve({
          ...data?.authenticate,
          message: null,
        })
      } else {
        resolve({
          message: data?.authenticate?.message ?? "An error occurred",
        })
      }
    })
  })
}

export async function signUpToAnalyticsWithEmailAndPassword(
  firstName,
  lastName,
  email,
  phoneNumber,
  roleType,
  roleTypeId
) {
  return new Promise(resolve => {
    executeQuery(
      `
    mutation CreateUserMutation($input: CreateUserInput!) {
      createUser(input: $input) {
        status
        message
      }
    }
  `,
      {
        input: {
          firstName,
          lastName,
          email,
          phoneNumber,
          roleType,
          roleTypeId,
          institutionCode: GetInstitution().code,
          sendEmail: false,
        },
      }
    ).then(({ data, errors }) => {
      // console.debug(data)
    })
  })
}

export async function changePasswordForAnalytics(password, newPassword) {
  return new Promise(resolve => {
    executeQuery(
      `mutation ChangePasswordMutation($input: ChangePasswordInput!) {
        changePassword(input: $input) {
          status
          message
        }
      }
    `,
      {
        input: {
          currentPassword: password,
          newPassword,
          institutionCode: GetInstitution().code,
        },
      }
    ).then(({ data, errors }) => {
      // console.debug(data)
    })
  })
}

export async function resetPasswordForAnalytics(email) {
  return new Promise(resolve => {
    executeQuery(
      `mutation ResetPasswordMutation($input: ResetPasswordInput!) {
         resetPassword(input: $input) {
           status
           message
         }
       }
     `,
      {
        input: {
          identifier: email,
          institutionCode: GetInstitution().code,
          sendEmail: false,
        },
      }
    ).then(({ data, errors }) => {
      // console.debug(data)
    })
  })
}

export async function loadViewer() {
  return new Promise(resolve => {
    executeQuery(
      `{
          viewer{
            firstName,
            lastName,
            email,
            roleType,
            institutionCode
          }
        }
      `
      , null).then(({ data, errors }) => {
        // console.debug(data)
      })
  })
}
