import authHeader from "helpers/authentication/auth-token-header"
import AuthUser from "helpers/authentication/authUser"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import {
  Format,
  GetTransactionTypeNames,
  SEARCHOPTIONS,
  TRANSACTIONSTATUS,
} from "helpers/variables"
import moment from "moment"
const HEADERS = [
  { title: "Status", name: "status", entityKeyName: "Status", isBadge: true },
  { title: "Date", name: "date", entityKeyName: "Date" },
  { title: "Agent Code", name: "agentCode", entityKeyName: "AgentCode" },
  { title: "Agent Name", name: "agent", entityKeyName: "AgentName" },
  {
    title: "Agent Category",
    name: "agentCategory",
    entityKeyName: "AgentCategory",
  },
  { title: "From Account", name: "fromAccount", entityKeyName: "FromAccount" },
  { title: "Details", name: "details", entityKeyName: "StatusDetails" },
  {
    title: "Transaction Type",
    name: "transactionType",
    entityKeyName: "TransactionTypeName",
  },
  {
    title: "Agent Mobile Number",
    name: "phoneNumber",
    entityKeyName: "FromPhoneNumber",
  },
  {
    title: "RRN",
    name: "rrn",
    entityKeyName: "RetrievalReferenceNumber",
  },
  { title: "Amount", name: "amount", entityKeyName: "Amount" },
  {
    title: "CustomerAccount",
    name: "customerAccount",
    entityKeyName: "ToAccount",
  },
  { title: "Branch", name: "branchName", entityKeyName: "Cluster" },
  { title: "Branch Code", name: "branchCode", entityKeyName: "ClusterCode" },
  { title: "Device Type", name: "deviceType", entityKeyName: "DeviceType" },
]

const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  const baseFields = [
    {
      type: "dropdown",
      label: "Transaction Type",
      id: "transactionType",
      defaultValue: "- Select -",
      data: GetTransactionTypeNames(),
      name: "transactionType",
      width: 3,
    },

    {
      type: "dropdown",
      label: "Status",
      id: "status",
      defaultValue: "- Select -",
      data: TRANSACTIONSTATUS,
      name: "status",
      width: 3,
    }
  ]
  const otherFields = [
    {
      type: "text",
      label: "To Account",
      id: "s-toAccount",
      defaultValue: "",
      name: "toAccount",
    },
    {
      type: "tel",
      label: "From Account",
      id: "fromAccount",
      defaultValue: "",
      name: "fromAccount",
    },
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      defaultValue: "",
      name: "phoneNumber",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 3,
      isRemote: GetInstitution().code == "*"
    },
  ]
  if (authHeader().AuthUser?.viewScope == 'Agent') {
    options.fields = baseFields;
  } else {
    options.fields = [...baseFields, ...otherFields];
  }
  return options
}
const MapToRows = transaction => {
  return {
    id: transaction.ID,
    status: {
      label: transaction.Status,
      badgeClass:
        transaction.Status == "Successful"
          ? "success"
          : transaction.Status == "Failed"
          ? "danger"
          : "secondary",
    },

    date: Format.date(transaction.Date),
    agent: transaction.AgentName,
    agentCode: transaction.AgentCode,
    branchName: transaction.Cluster,
    branchCode: transaction.ClusterCode,
    deviceType: Utils.Strings.camelPad(transaction.DeviceType),
    agentCategory: transaction.AgentCategory,
    rrn: transaction.RetrievalReferenceNumber,
    fromAccount: transaction.FromAccount,
    details: transaction.StatusDetails,
    institution: transaction.InstitutionName,
    transactionType: Utils.Strings.camelPad(transaction.TransactionTypeName),
    phoneNumber: transaction.FromPhoneNumber,
    amount: Format.currency(transaction.Amount),
    customerAccount: transaction.ToAccount,
  }
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    AgentClusterManagerID: params["clusterManagerId"],
    SuperAgentPhoneNumber: params["superAgentPhoneNumber"],
    AgentPhoneNumber: params["phoneNumber"],
    TerminalID: params["TerminalID"],
    TransactionType: params["transactionType"],
    FromAccount: params["fromAccount"],
    ToAccount: params["toAccount"],
    Status: params["status"],
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    InstitutionCode: params["institutionCode"] != '*' ? params["institutionCode"] : '',
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize ?? 0,
    MaxSize: maxSize,
  })
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name , name: item.Name }))
  }
  return []
}
const MapToDropDownTerminal = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.TerminalID,
    }))
  }
  return []
}
export default {
  HEADERS,
  SearchCriteria,
  MapToRows,
  SearchOptions,
  MapToDropDown,
  MapToDropDownTerminal,
}
