import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
// images
import clusterian from "assets/images/icons/clusterian.png"

// Pages Components
import Report from "./report"

import ClusterModal from "components/Common/Modals/Modal"
//i18n
import { withTranslation } from "react-i18next"
import {
  getSubscriptionPlanDetail,
  getSubscriptionPlansByCategory,
  subscriptionPlanPurge,
  removeSubscriptionOption,
  updateSubscription,
  enableSubscription,
} from "store/actions"
import SubscriptionLogic from "../volume-covenant"
import withDispose from "components/HOCs/WithDispose"
import { } from "helpers/backend_helper"
import { toast } from "react-toastify"
import { ENABLE_SUBSCRIPTION_PLANS } from "../permission"
import ClusterButton from "components/Common/Button/Button"
import ClusterDataTable from "components/Common/DataTable"
import useReportData from "hooks/UseReportData"

const SubscriptionList = props => {
  const {
    categoryName,
    setCategory,
    onGetSubscriptions,
    onGetSubscriptionDetail,
    subscriptionPlanDetail,
    totalCount,
    onDisableSubscription,
    editLoading,
    loading,
    categoryId,
    statusChanged,
    error, success
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    MapToRowsOptions,
    OPTIONSHEADERS,
    UpdateErrorList,
    MapToModalState,
  } = SubscriptionLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ onGetData: onGetSubscriptions, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [isEnabled, setIsEnabled] = useState(false)
  const [viewedSubscription, setViewedSubscription] = useState<any>()
  const [errorList, setErrorList] = useState(UpdateErrorList())

  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {

    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])

  useEffect(() => {
    if (
      subscriptionPlanDetail &&
      Object.keys(subscriptionPlanDetail).length > 0
    ) {
      setmodal(true)
      setViewedSubscription(subscriptionPlanDetail)
      setModalData(MapToModalState(subscriptionPlanDetail))
    }
  }, [subscriptionPlanDetail])

  useEffect(() => {
    search(categoryId)
  }, [categoryId])

  const getIsEnabled = () => {
    let enabled = false
    if (subscriptionPlanDetail?.IsActive || statusChanged == true)
      enabled = true
    setIsEnabled(enabled)
  }
  useEffect(() => {
    getIsEnabled()
    if (!subscriptionPlanDetail?.IsActive) props.history.push("/view-subscription-plans")
  }, [statusChanged, subscriptionPlanDetail])

  const populateRows = () => {
    if (props.subscriptionPlans) {
      let rws = []
      props.subscriptionPlans.forEach(structure => {
        if (structure) {
          let row = MapToRows(structure);
          row["action"] = (
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
              onClick={() => onViewDetails(structure.ID)}
            >
              View Details
            </Button>
          )
          rws.push(row)
        }
      })

      setRows(rws)
    }
  }
  const [subscriptionPlan, setSubscription] = useState({}) //data from form

  function onViewDetails(id) {
    setmodal(true)
    onGetSubscriptionDetail(id)
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumbs
          title={"Subscription Plans"}
          image={clusterian}
          breadcrumbItem={"Plans for " + categoryName}
          prepend={
            <i
              className={"bx bx-arrow-back"}
              style={{
                marginRight: "10px",
                fontSize: "2em",
                cursor: "pointer",
              }}
              color={"secondary"}
              onClick={e => {
                e.preventDefault()
                setCategory(undefined)
              }}
            ></i>
          }
        />
        <Row>
          <Col lg="12">
            <Report
              tableData={{
                columns: columns,
                rows: rows,
                paginationOptions: {
                  totalCount: totalCount,
                  onPageChange: page => handlePageChange(page),
                  pageNumber: pageNumber,
                  maxSize: maxSize,
                },
                pageTitle: undefined,
                isLoading: loading,
              }}
            />
          </Col>
        </Row>
      </Container>

      {modal ? (
        <ClusterModal
          badgeClass={viewedSubscription?.IsActive ? "success" : "secondary"}
          status={viewedSubscription?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Plan Details"}
          append={[
            <OptionsReport
              key={1}
              columns={OPTIONSHEADERS}
              rows={
                !viewedSubscription?.Options || viewedSubscription?.Options.length == 0
                  ? []
                  : viewedSubscription?.Options?.map(option => MapToRowsOptions(option))

              }
            />,
          ]}
        >
          {viewedSubscription?.IsActive &&
            <ClusterButton
              loading={editLoading}
              permissions={[ENABLE_SUBSCRIPTION_PLANS]}
              type="button"
              outline
              onHandleClick={() => onDisableSubscription(viewedSubscription?.ID, false)}
              color="secondary"
            >
              {viewedSubscription?.IsActive ? "Disable" : "Enable"}
            </ClusterButton>
          }
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>

      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

const OptionsReport = props => {
  if (props.rows.length > 0) {
    return (
      <ClusterDataTable
        columns={props.columns}
        loading={false}
        rows={props.rows}
      ></ClusterDataTable>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}
SubscriptionList.propTypes = {
  subscriptionPlans: PropTypes.array,
  subscriptionPlanDetail: PropTypes.object,
  onGetSubscriptions: PropTypes.func,
  onGetSubscriptionDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  onDisableSubscription: PropTypes.func,
  categoryId: PropTypes.number,
}

const mapStateToProps = ({
  subscriptions: {
    subscriptionPlans,
    error,
    success,
    subscriptionPlanDetail,
    totalCount,
    deleteLoading,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  // console.debug(subscriptionPlans,error,success)
  return {
    subscriptionPlans,
    error,
    success,
    deleteLoading,
    subscriptionPlanDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetSubscriptions: categoryId =>
    dispatch(getSubscriptionPlansByCategory(categoryId)),
  onGetSubscriptionDetail: id =>
    dispatch(getSubscriptionPlanDetail(id)),
  onPurge: () => dispatch(subscriptionPlanPurge()),
  onDeleteSubscription: payload =>
    dispatch(removeSubscriptionOption(payload)),
  onSaveConfiguration: payload => dispatch(removeSubscriptionOption(payload)),
  onUpdateSubscription: structure =>
    dispatch(updateSubscription(structure)),
  onDisableSubscription: (id, status) => dispatch(enableSubscription(id, status))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(SubscriptionList))))
