import withDispose from "components/HOCs/WithDispose"
import ClusterStorage from "helpers/storage-helper"
import PropTypes, { instanceOf } from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"

const Logout = props => {
  useEffect(() => {
    ClusterStorage.clear();
    props.logoutUser(props.history)
  }, [])
  useEffect(() => {
   props.history.push("/login")
  }, [props])

  return <></>
}

Logout.propTypes = {
  history: PropTypes.object,
  logoutUser: PropTypes.func,
  error: PropTypes.any,
  user: PropTypes.any,
}

const mapStateToProps = state => {
  const { error, user } = state.Login
  return { error, user: user }
}

export default withRouter(
  connect(mapStateToProps, { logoutUser })(withDispose(Logout))
)
