import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Alert } from "reactstrap"

import Breadcrumbs from "../../../../components/Common/Breadcrumb"

import roleImg from "../../../../assets/images/icons/saturn.png"

import { withTranslation } from "react-i18next"
import {
  createUser,
  getAllRoles,
  getBranches,
  getStates,
  getUserDetail,
  purgeUsers,
  updateUser,
} from "store/actions"
import { GLOBAL } from "helpers/variables"
import ClusterInformation from "components/Common/Information/Information"
import UserForm from "./form"
import withDispose from "components/HOCs/WithDispose"
import authHeader from "helpers/authentication/auth-token-header"
import GuardedPage from "components/HOCs/GuardedPage"
import { CREATE_USER, EDIT_USER } from "../permissions"
import { Institution } from "helpers/authentication/institution"
import UserLogic from "../user"
import { Utils } from "helpers/utility"
import { Gender } from "enums/gender"

const CreateUser = props => {
  const {
    error,
    onCreateUser,
    onGetRoles,
    loading,
    editLoading,
    roleLoading,
    statesLoading,
    onUpdateUser,
    match,
    roles,
    onPurge,
    states,
    users,
    onGetUserDetail,
    success,
    onGetStates,
  } = props

  const { ERRORLIST } = UserLogic
  const [errorList, setErrorList] = useState(Object.assign({}, ERRORLIST))
  const [role, setRole] = useState<any>()
  const [isUpdate, setIsUpdate] = useState<undefined | boolean>()
  const [isRotating, setIsRotating] = useState(false)
  const [userToCreate, setUser] = useState<any>()

  const getUser = () => {
    const id = match.params.id;
    const onUpdate = id && id > 0;
    if (onUpdate) {
      setIsUpdate(true)

      onGetUserDetail(id)
    } else if (id == 0) {
      setIsUpdate(false)
    }
  }

  useEffect(() => {
    onGetRoles()
    getUser()
    onGetStates()
  }, [])

  useEffect(() => {
    getUser()
  }, [match])


  const userDetails = useMemo(() => {
    if (!Utils.List.isEmpty(props.user)) {
      return props.user;
    }
    return null;
  }, [props.user])


  const handleOnChange = (key, value) => {
    let u = Object.assign({}, userToCreate);
    u[key] = value
    setUser(u)
  }

  const formValues = useMemo(() => {
    return Object.assign({}, userToCreate);
  }, [userToCreate])


  const closeInfo = () => {
    setRole(false)
  }

  const handleValidSubmit = entity => {

    if (isUpdate) {
      entity = {
        ...userDetails,
        address: userDetails?.address?.street,
        city: userDetails?.address?.city,
        state: userDetails?.address?.state,
        userRoleId: userDetails.userRole?.id,
        branchId: userDetails.branch?.id,
        institutionId: userDetails.institution?.id,
        ...entity,
      }
      for (let key in entity) {
        if (entity[key] instanceof Object || entity[key] instanceof Array) {
          delete entity[key]
        }
      }
      onUpdateUser(entity)
    } else {
      if (authHeader().isRemote) {
        entity["branchId"] = 1
      }
      if (!entity["gender"]) entity["gender"] = Gender[Gender.Male]
      onCreateUser(entity)
    }
  }
  return (
    <GuardedPage permissions={[EDIT_USER, CREATE_USER]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Users"}
              breadcrumbItem={isUpdate ? "Update A User" : "Create A User"}
            />
            {Institution.IsRemote() ? <Alert color="info">Changes to the users here only affect users on Remote accounts. To change an Institution user, Login as the institution</Alert> : null}
            <UserForm
              redirectUrl={"/view-users"}
              isUpdate={isUpdate}
              loading={loading}
              error={error}
              success={success}
              errorList={errorList}
              editLoading={editLoading}
              isRotating={isRotating}
              statesLoading={statesLoading}
              userDetails={userDetails}
              roleLoading={roleLoading}
              setErrorList={setErrorList}
              handleOnChange={handleOnChange}
              setIsRotating={setIsRotating}
              roles={roles}
              states={states}
              values={formValues}
              setFormValues={setUser}
              handleValidSubmit={handleValidSubmit}
            />

            {role ? (
              <div
                style={{
                  maxWidth: "500px",
                  position: "fixed",
                  bottom: "5%",
                  right: "2.5%",
                  zIndex: "9999",
                  cursor: "pointer",
                }}
                onClick={() => {
                  closeInfo()
                }}
              >
                <ClusterInformation
                  image={roleImg}
                  title={GLOBAL.title}
                  description={GLOBAL.description}
                ></ClusterInformation>
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CreateUser.propTypes = {
  user: PropTypes.object,
  users: PropTypes.array,
  roles: PropTypes.array,
  error: PropTypes.any,
  onCreateUser: PropTypes.func,
  onGetRoles: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  success: PropTypes.any,
  editLoading: PropTypes.bool,
  roleLoading: PropTypes.bool,
  statesLoading: PropTypes.bool,
  branches: PropTypes.array,
  states: PropTypes.array,
  onGetUserDetail: PropTypes.func,
  onGetStates: PropTypes.func,
}

const mapStateToProps = ({
  Users: { user, users, loading, error, editLoading, success },
  Roles,
  branches: { branches },
  misc: { states, statesLoading },
}) => {
  return {
    user,
    users,
    error,
    loading,
    editLoading,
    roleLoading: Roles.loading,
    roles: Roles.roles,
    success,
    branches,
    states,
    statesLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onCreateUser: user => dispatch(createUser(user)),
  onUpdateUser: user => dispatch(updateUser(user)),
  onGetRoles: () => dispatch(getAllRoles()),
  onGetUserDetail: id => dispatch(getUserDetail(id)),
  onGetBranches: () => dispatch(getBranches()),
  onPurge: () => dispatch(purgeUsers()),
  onGetStates: () => dispatch(getStates()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(CreateUser))))
