import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"
import {
  removeAssignedTerminal,
  updateAssignedTerminal,
  getAssignedTerminals,
  purgeTerminals,
} from "store/actions"
import TerminalAssignmentLogic from "../../terminal-assignment"
import withDispose from "components/HOCs/WithDispose"
import { fetcher, get } from "helpers/api_helper"
import EditModal from "./modal"
import { toast } from "react-toastify"
import useSWR from "swr"
import { GET_ALL_AGENTS, GET_UNASSIGNED_TERMINALS } from "helpers/url_helper"
import useReportData from "hooks/UseReportData"

const TerminalAssignmentList = props => {
  const {
    assignedTerminals,
    onGetAssignedTerminals,
    error,
    onSaveConfiguration,
    onPurge,
    totalCount,
    onUpdateAssignedTerminal,
    onDeleteAssignedTerminal,
    deleteLoading,
    editLoading,
    loading,
    categoryId,
    statusChanged,
    terminalsToModify,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    ERRORLIST,
    UpdateErrorList,
    SearchOptions,
    MapToModalState,
  } = TerminalAssignmentLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetAssignedTerminals, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [isEnabled, setIsEnabled] = useState(false)
  const [viewedAssignedTerminal, setViewedAssignedTerminal] = useState()
  const [errorList, setErrorList] = useState(UpdateErrorList())
  
  useEffect(() => {
    populateRows()
  }, [props])
  
  // useEffect(() => {
  //   if (
  //     assignedTerminalDetail &&
  //     Object.keys(assignedTerminalDetail).length > 0
  //   ) {
  //     setmodal(true)
  //     setViewedAssignedTerminal(assignedTerminalDetail)
  //     setModalData(MapToModalState(assignedTerminalDetail))
  //   }
  // }, [assignedTerminalDetail])

  // const getIsEnabled = () => {
  //   let enabled = false
  //   if (assignedTerminalDetail?.IsActive || statusChanged == true)
  //     enabled = true
  //   setIsEnabled(enabled)
  // }
  // useEffect(() => {
  //   getIsEnabled()
  // }, [statusChanged, assignedTerminalDetail])
  // useEffect(() => {
  //   search()
  // }, [statusChanged])

  const populateRows = () => {
    if (props.assignedTerminals) {
      let rws = []
      props.assignedTerminals.forEach(terminal => {
        let row = MapToRows(terminal)
        ;(row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onRemove(terminal.Terminal.ID)}
          >
            Remove
          </Button>
        )),
          rws.push(row)
      })

      setRows(rws)
    }
  }

  const [assignedTerminal, setAssignedTerminal] = useState({}) //data from form
  const handleOnChange = (key, value) => {
    let u = assignedTerminal
    u[key] = value
    setAssignedTerminal(u)
  }
  const handleValidSubmit = e => {
    e.preventDefault()
    // onSaveConfiguration(terminalsToModify)
  }

  const handleUpdate = e => {
    e.preventDefault()
    let terminalToUpdate = {
      ...assignedTerminalDetail,
      ...assignedTerminal,
      IsActive: true,
      AgentCategoryID: categoryId,
      IssuingCashOutInstitutionID:
        assignedTerminalDetail.IssuingCashOutInstitution?.ID,
    }
    for (let key in terminalToUpdate) {
      if (
        terminalToUpdate[key] instanceof Object ||
        terminalToUpdate[key] instanceof Array
      ) {
        delete terminalToUpdate[key]
      }
    }
    onUpdateAssignedTerminal(terminalToUpdate)
  }
  function onRemove(id) {
    setmodal(true)
    onDeleteAssignedTerminal(id)
  }

  return (
    <React.Fragment>
      <Container fluid>
        {/* <Breadcrumbs  breadcrumbItem={"Commission Structures"} /> */}

        <Row>
          <Col lg="12">
            <Report
              tableData={{
                columns: columns,
                rows: rows,
                paginationOptions: {
                  totalCount: totalCount,
                  onPageChange: page => handlePageChange(page),
                  pageNumber: pageNumber,
                  maxSize: maxSize,
                },
                pageTitle: undefined,
                isLoading: loading,
              }}
            />
          </Col>
        </Row>
      </Container>

      {/* {modal ? (
        <EditModal
          badgeClass={
            viewedAssignedTerminal?.IsActive ? "success" : "secondary"
          }
          status={viewedAssignedTerminal?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          terminal={assignedTerminalDetail}
          setErrorList={setErrorList}
          errorList={errorList}
          handleValidSubmit={handleValidSubmit}
          deleteLoading={deleteLoading}
          editLoading={editLoading}
          handleOnChange={handleOnChange}
          onDelete={onDeleteAssignedTerminal}
          onUpdate={handleUpdate}
        >
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </EditModal>
      ) : (
        <React.Fragment></React.Fragment>
      )} */}
    </React.Fragment>
  )
}

TerminalAssignmentList.propTypes = {
  assignedTerminals: PropTypes.array,
  onGetAssignedTerminals: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  onEnableAssignedTerminal: PropTypes.func,
}

const mapStateToProps = ({
  terminals: {
    assignedTerminals,
    terminalsToModify,
    error,
    success,
    totalCount,
    deleteLoading,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  // console.debug(assignedTerminals,error,success)
  return {
    assignedTerminals,
    terminalsToModify,
    error,
    success,
    deleteLoading,
    totalCount,
    loading:false,
    editLoading,
    deleteLoading,
    statusChanged,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAssignedTerminals: () =>
    dispatch(getAssignedTerminals(false)),
  onPurge: () => dispatch(purgeTerminals()),
  onDeleteAssignedTerminal: payload =>
    dispatch(removeAssignedTerminal(payload)),
  onSaveConfiguration: payload => dispatch(removeAssignedTerminal(payload)),
  onUpdateAssignedTerminal: terminal =>
    dispatch(updateAssignedTerminal(terminal)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(TerminalAssignmentList))))
