import React from "react"
import { Redirect } from "react-router-dom"
// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/login"
import Logout from "../pages/Authentication/logout"
import ForgetPwd from "../pages/Authentication/forget-password"
import RemotePage from "../pages/Remote/index"

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Login2 from "../pages/AuthenticationInner/Login2"
import Register1 from "../pages/AuthenticationInner/Register"
import Register2 from "../pages/AuthenticationInner/Register2"
import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

//TSD
import PerformanceHighlights from "../pages/Dashboard/performance-highlights"
import Comparatives from "../pages/Dashboard/comparatives"
import ServiceQuality from "../pages/Dashboard/service-quality"
import TSD from "../pages/Dashboard/TSD"
//Reports
import TransactionReceipt from "../pages/Reports/receipts-report"
//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"
//Direct Debit
import ViewDirectDebitRequests from "../pages/DirectDebit/view-direct-debit-requests/index"
import DirectDebitUpload from "../pages/DirectDebit/upload-direct-debit"
import ApproveDirectDebit from "../pages/DirectDebit/direct-debit-approval/index"

// Notification
import ViewNotificationHistory from "../pages/NotificationManagement/history/index"
import CreateNotification from "../pages/NotificationManagement/create/index"

//Users
import UserList from "../pages/UserManagement/Users/list/index"
import AddUser from "../pages/UserManagement/Users/create/index"

//Report
import Reports from "pages/Reports/Reports"

//Agents
import AgentList from "../pages/AgentManagement/list/index"
import AgentLoanList from "../pages/AgentManagement/loan-report/index"
import AddAgent from "../pages/AgentManagement/create/index"
import AgentActivityReport from "../pages/AgentManagement/activity-report"
import AgentSummaryReport from "../pages/Reports/agent-summary-report"
import AgentVolumeComparativeReport from "pages/Reports/agent-volume-comparatives"
import CashOutReport from "../pages/Reports/card-cashout-report/index"
import FundsTransferReport from "../pages/Reports/fundtransfer-report/index"
import CollectionReport from "../pages/Reports/collection-report"
import TransactionReport from "../pages/Reports/transaction-report/index"
import PayWithTransferList from "../pages/Reports/paywithtransfer-report"
import CardTransferList from "../pages/Reports/cardtransfer-report"
import AgentTracker from "../pages/AgentLocationManagement/tracker"

import MobileAccountList from "../pages/MobileAccountManagement/list"
import ModifyAccount from "../pages/MobileAccountManagement/modify"

import LogReport from "../pages/Reports/downloads/log"
import DownloadReport from "../pages/Reports/downloads/view"
//Agent Categories
import AgentCategoryList from "../pages/AgentCategoryManagement/list"
import AddAgentCategory from "../pages/AgentCategoryManagement/create"

//Agent Cluster Manager
import AgentClusterList from "../pages/AgentClusterManagement/list"
import AddAgentCluster from "../pages/AgentClusterManagement/create"

//Cluster
import ClusterList from "../pages/ClusterManagement/list"
import AddCluster from "../pages/ClusterManagement/create"
//Institution
import InstitutionList from "../pages/InstitutionManagement/list"
import AddInstitution from "../pages/InstitutionManagement/create"
import ServiceConfiguration from "../pages/ServiceConfigurationManagement/create"
import RoutingConfiguration from "../pages/RoutingConfigurationManagement/configure"

//Terminal
import TerminalList from "../pages/TerminalManagement/list"
import AddTerminal from "../pages/TerminalManagement/create"
import TerminalAssignment from "../pages/TerminalManagement/assignment"
import TerminalAssignmentServiceProvider from "../pages/TerminalManagement/assignment-service-provider"
import TerminalBatchUpload from "../pages/TerminalManagement/batch-upload/upload"
import TerminalAssignmentReport from "../pages/TerminalManagement/assignment-report"

//AuditTrail
import AuditTrailList from "../pages/AuditTrailManagement/list"

//Agent Network Manager
import ANMList from "../pages/AgentNetworkManagement/list"
import AddANM from "../pages/AgentNetworkManagement/create"

import CaseLogList from "../pages/CaseLoggingManagement/list"
import CaseLogDetails from "../pages/CaseLoggingManagement/details"

import ChargeBackList from "../pages/ChargeBackManagement/list"
//Commissions
import CommissionsList from "../pages/AgentCommissionMgt/list"
import CommissionStructure from "../pages/AgentCommissionMgt/commission-structure/index"

//User Roles
import UserRoleList from "../pages/UserManagement/Roles/list/index"
import AddUserRole from "../pages/UserManagement/Roles/create/index"
import authHeader from "helpers/authentication/auth-token-header"

//Subscriptions
import Subscriptions from "../pages/VolumeCovenant"
import CreateSubscription from "../pages/VolumeCovenant/create"
import SubscriptionHistoryList from "../pages/VolumeCovenant/report"

//Settlements
import WalletReport from "../pages/SettlementManagement/wallet"
import SettlementAccount from "../pages/SettlementManagement/account"
import SettlementConfiguration from "../pages/SettlementManagement/configuration"
import SettlementApprovalConfiguration from "../pages/SettlementManagement/approval-configuration"
import TransactionsList from "../pages/SettlementManagement/transactions"

import UploadUnsettledTransactions from "../pages/UnsettledTransactionManagement/Upload"
import ApproveUnsettledTransactions from "../pages/UnsettledTransactionManagement/Approve"
const userRoutes = [
  //TSD
  { path: "/performance-highlights", component: PerformanceHighlights },
  { path: "/comparatives", component: Comparatives },
  { path: "/service-quality", component: ServiceQuality },
  { path: "/transaction-dashboard", component: TSD },

  //reports
  { path: "/receipts", component: TransactionReceipt },
  { path: "/all-reports", component: Reports },
  {path : "/agent-summary", component : AgentSummaryReport},
  {
    path: "/agent-volume-comparative",
    component: AgentVolumeComparativeReport,
  },

  //profile
  { path: "/profile", component: UserProfile },

  // users
  { path: "/view-users", component: UserList },
  { path: "/create-user/:id", component: AddUser },

  // user role
  { path: "/view-user-roles", component: UserRoleList },
  { path: "/create-user-role/:id", component: AddUserRole },
  { path: "/remote", component: RemotePage },

  // agents
  { path: "/view-agents", component: AgentList },
  { path: "/agent-overdrafts", component: AgentLoanList },
  { path: "/create-agent/:id", component: AddAgent },
  { path: "/agent-activity-report", component: AgentActivityReport },
  { path: "/cashout-report", component: CashOutReport },
  { path: "/fundstransfer-report", component: FundsTransferReport },
  { path: "/transaction-report", component: TransactionReport },
  { path: "/collection-report", component: CollectionReport },
  { path: "/pay-with-transfer-report", component: PayWithTransferList },
  { path: "/card-transfer-report", component: CardTransferList },
  { path: "/agent-tracker", component: AgentTracker },

  { path: "/log-report", component: LogReport },
  { path: "/download-report", component: DownloadReport },

  // mobile accounts
  { path: "/view-mobile-accounts", component: MobileAccountList },
  { path: "/modify-account", component: ModifyAccount },
  // agent categories
  { path: "/view-categories", component: AgentCategoryList },
  { path: "/create-category/:id", component: AddAgentCategory },

  // agent cluster
  { path: "/view-cluster-managers", component: AgentClusterList },
  { path: "/create-cluster-manager/:id", component: AddAgentCluster },

  // cluster
  { path: "/view-clusters", component: ClusterList },
  { path: "/create-cluster/:id", component: AddCluster },
  // instituion
  { path: "/view-institutions", component: InstitutionList },
  { path: "/create-institution/:id", component: AddInstitution },
  { path: "/service-configuration", component: ServiceConfiguration },
  { path: "/routing-configuration", component: RoutingConfiguration },

  // terminal
  { path: "/view-terminals", component: TerminalList },
  { path: "/create-terminal/:id", component: AddTerminal },
  { path: "/terminal-assignment", component: TerminalAssignment },
  {
    path: "/terminal-assignment-service-provider",
    component: TerminalAssignmentServiceProvider,
  },
  { path: "/terminal-assignment-report", component: TerminalAssignmentReport },
  { path: "/terminal-batch-upload", component: TerminalBatchUpload },

  //AuditTrail
  { path: "/view-audit-trail", component: AuditTrailList },
  // commissions
  { path: "/view-commissions", component: CommissionsList },
  { path: "/commission-structure", component: CommissionStructure },

  //Direct Debit

  { path: "/upload-direct-debit", component: DirectDebitUpload },
  { path: "/view-direct-debit-requests", component: ViewDirectDebitRequests },
  {path : "/approve-direct-debit", component : ApproveDirectDebit},

  // Notification 
  {path : "/view-notifications", component : ViewNotificationHistory},
  {path : "/create-notification", component : CreateNotification},

  // settlements
  { path: "/settlement-account", component: SettlementAccount },
  { path: "/wallet-report", component: WalletReport },
  {
    path: "/settlement-approval-configuration",
    component: SettlementApprovalConfiguration,
  },
  { path: "/settlement-configuration", component: SettlementConfiguration },
  {path : "/wallet-top-ups", component : TransactionsList},

  { path: "/view-subscription-plans", component: Subscriptions },
  { path: "/create-subscription-plan", component: CreateSubscription },
  { path: "/view-subscriptions", component: SubscriptionHistoryList },

  { path: "/view-caselogs", component: CaseLogList },
  { path: "/view-caselog/:id", component: CaseLogDetails },
  { path: "/chargeback-claims", component: ChargeBackList },
  // agent network manager
  { path: "/view-anms", component: ANMList },
  { path: "/create-anm/:id", component: AddANM },
  {
    path: "/upload-unsettled-transactions",
    component: UploadUnsettledTransactions,
  },
  {
    path: "/approve-unsettled-transactions",
    component: ApproveUnsettledTransactions,
  },
  // this route should be at the end of all other routes
  {
    path: "/",
    exact: true,
    component: () => {
      return !authHeader()?.AuthUser ? (
        <Redirect to="/login" />
      ) : (
        <Redirect to="/performance-highlights" />
      )
    },
  },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/page-recoverpw-2", component: Recoverpw2 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },
]

export { userRoutes, authRoutes }
