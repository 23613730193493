import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { withTranslation } from "react-i18next"
import ClusterMap from "components/Common/Map/Maps"
import SearchSection from "components/Common/Search/SearchSection"
import withDispose from "components/HOCs/WithDispose"
import { fetcher, post } from "helpers/api_helper"
import { formatter } from "helpers/utility"
import TrackerLogic from "./tracker"
import { GET_ALL_CATEGORIES } from "helpers/url_helper"
import useSWR from "swr"

const AgentTracker = props => {
  const { error } = props
  const { SearchCriteria, SearchOptions } = TrackerLogic
  const url = "api/Geolocation/GetAgentCurrentLocations"
  const [range, setRange] = useState(() => [
    moment().subtract(2, "year"),
    moment(),
  ])
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  const [loading, setLoading] = useState(false)
  const maxSize = 1000
  const [pageNumber, setPageNumber] = useState(1)
  const [params, setParams] = useState({
    fromDate: startDateStr,
    toDate: endDateStr,
    page: pageNumber,
  })

  const categories = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [markers, setMarkers] = useState([])
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, categories?.data?.Data)
  )
  useEffect(() => {
    setSearchOptions(
      SearchOptions(params?.fromDate, params?.toDate, categories?.data?.Data)
    )
  }, [categories])

  useEffect(() => {
    setLoading(false)
  }, [error])

  useEffect(() => {
    search(SearchCriteria(params, maxSize))
    setInterval(() => search(SearchCriteria(params, maxSize)), 100000)
  }, [])

  function search(params) {
    post(url, JSON.parse(params), {}, true).then(c => {
      // console.debug(c)
      let markers = []
      c?.Results?.forEach(m => {
        let marker = {}
        marker.location = { lat: +m.Latitude, lng: +m.Longitude }
        marker.children = (
          <div
            style={{
              display: "flex",
              textAlign: "center",
              flexDirection: "column",
            }}
          >
            <h4>{m.Name}</h4>
            <p style={{ margin: 0 }}>
              <b>{formatter(m.TransactionAmount, true)}</b>
            </p>
            <p>{m.TransactionTypeName}</p>
            <small>
              <b>{m.Address}</b>
            </small>
            <small>{m.DateLogged}</small>
          </div>
        )

        markers.push(marker)
      })
      // console.debug(markers);
      setMarkers(markers)
    })
    //     Address: "15 Ayoade St, Bariga, Lagos, Nigeria"
    // AppName: "CREDITCLUB"
    // BranchID: 1394
    // Code: "00107"
    // CustomerName: "OLUSEYI GABRIEL SEGUN-OKUSELU"
    // CustomerPhoneNumber: ""
    // DateLogged: "2020-05-17T12:50:06"
    // InstitutionCode: "100305"
    // IsCustomerGeoLocation: false
    // Latitude: "6.5337847"
    // Longitude: "3.3815878"
    // Name: "George Ashiru Murewa"
    // PhoneNumber: "08182341135"
    // TransactionAmount: 0
    // TransactionTypeName: "KiaKiaToSterlingBank"

    // {id:1,name:"Ezeala Charity", title:"",children:"Selected",location:{ lat: 6.457171, lng: 3.327709 }}
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <SearchSection
          rowClass={"map-search"}
          options={searchOptions}
          handleOnSearch={params => search(SearchCriteria(params, maxSize))}
        ></SearchSection>

        <ClusterMap markers={markers}></ClusterMap>
      </div>
    </React.Fragment>
  )
}

AgentTracker.propTypes = {
  agents: PropTypes.array,
  agentDetail: PropTypes.object,
  onGetAgents: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onPurge: PropTypes.func,
}

const mapStateToProps = ({ agents: { agents, error, agentDetail } }) => {
  return {
    agents: [...agents],
    error: error,
    agentDetail: agentDetail,
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(AgentTracker))))
