import { Utils } from "helpers/utility";
import { useEffect, useRef } from "react";

export const useHasPropsChanged = (value: any) => {
    const prevVal = usePrevious(value)
    return Utils.hasChanged(prevVal, value);
}


export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}