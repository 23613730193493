import {
  GET_NETWORKMANAGERS,
  GET_NETWORKMANAGER_DETAIL,
  GET_NETWORKMANAGER_DETAIL_SUCCESS,
  GET_NETWORKMANAGER_DETAIL_FAIL,
  GET_NETWORKMANAGERS_SUCCESS,
  GET_NETWORKMANAGERS_FAIL,
  CREATE_NETWORKMANAGER,
  POST_NETWORKMANAGER_SUCCESS,
  NETWORKMANAGER_PURGE,
  NETWORKMANAGER_PURGE_SUCCESS,
  POST_NETWORKMANAGER_FAIL,
  GET_ALL_NETWORKMANAGERS,
  UPDATE_NETWORKMANAGER,
  UPDATE_NETWORKMANAGER_SUCCESS,
  ENABLE_NETWORKMANAGER,
  ENABLE_NETWORKMANAGER_SUCCESS,
} from "./actionTypes"

export const getNetworkManagers = params => ({
  type: GET_NETWORKMANAGERS,
  params,
})
export const getAllNetworkManagers = params => ({
  type: GET_ALL_NETWORKMANAGERS,
  params,
})
export const getNetworkManagersSuccess = networkManagers => ({
  type: GET_NETWORKMANAGERS_SUCCESS,
  payload: networkManagers,
})
export const getNetworkManagersFail = error => ({
  type: GET_NETWORKMANAGERS_FAIL,
  payload: error,
})
export const getNetworkManagerDetail = id => ({
  type: GET_NETWORKMANAGER_DETAIL,
  id,
})

export const getNetworkManagerDetailSuccess = networkManagers => ({
  type: GET_NETWORKMANAGER_DETAIL_SUCCESS,
  payload: networkManagers,
})

export const getNetworkManagerDetailFail = error => ({
  type: GET_NETWORKMANAGER_DETAIL_FAIL,
  payload: error,
})

export const createNetworkManager = networkManager => {
  return {
    type: CREATE_NETWORKMANAGER,
    payload: { networkManager },
  }
}

export const networkManagerApiError = error => {
  return {
    type: POST_NETWORKMANAGER_FAIL,
    payload: error,
  }
}

export const postNetworkManagerSuccess = networkManager => {
  return {
    type: POST_NETWORKMANAGER_SUCCESS,
    payload: networkManager,
  }
}

export const networkManagerPurge = () => ({
  type: NETWORKMANAGER_PURGE,
})

export const networkManagerPurgeSuccess = () => ({
  type: NETWORKMANAGER_PURGE_SUCCESS,
})

export const enableNetworkManager = (id, enable) => {
  return {
    type: ENABLE_NETWORKMANAGER,
    id,
    enable,
  }
}

export const enableNetworkManagerSuccess = networkManager => {
  return {
    type: ENABLE_NETWORKMANAGER_SUCCESS,
    payload: networkManager,
  }
}

export const updateNetworkManager = networkManager => {
  return {
    type: UPDATE_NETWORKMANAGER,
    payload: { networkManager },
  }
}
export const updateNetworkManagerSuccess = networkManager => {
  return {
    type: UPDATE_NETWORKMANAGER_SUCCESS,
    payload: networkManager,
  }
}
export const purgeNetworkManagers = () => {
  return {
    type: PURGE_NETWORKMANAGERS,
  }
}

export const purgeNetworkManagerSuccess = () => {
  return {
    type: PURGE_NETWORKMANAGERS_SUCCESS,
  }
}
