import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTIONS,
  GET_SUBSCRIPTION_PLAN_DETAIL,
  SUBSCRIPTION_PLAN_PURGE,
  ENABLE_SUBSCRIPTION_PLAN,
  SAVE_SUBSCRIPTION_PLAN,
  UPDATE_SUBSCRIPTION_PLAN,
  GET_ALL_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_BY_CATEGORY,
  ADD_SUBSCRIPTION_PLAN_OPTION,
  REMOVE_SUBSCRIPTION_PLAN_OPTION,
} from "./actionTypes"
import {
  getSubscriptionPlansSuccess,
  getSubscriptionPlansFail,
  getSubscriptionPlanDetailSuccess,
  getSubscriptionPlanDetailFail,
  subscriptionPlanPurgeSuccess,
  updateSubscriptionSuccess,
  postSubscriptionSuccess,
  getSubscriptionsSuccess,
  getSubscriptionsFail,
  subscriptionPlanApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getSubscriptions,
  getSubscriptionPlans,
  getSubscriptionPlansByCategory,
  getSubscriptionPlanDetail,
  putSubscription,
  enableSubscription,
  disableSubscription,
  getAllSubscriptions,
  saveSubcription,
  removeSubscriptionOption,
} from "helpers/backend_helper"
import {
  addSubscriptionOptionSuccess,
  enableSubscriptionSuccess,
  removeSubscriptionOptionSuccess,
} from "store/actions"
const format = "yyyy-MM-DD"
function* fetchSubscriptionPlans({ params }) {
  try {
    const response = yield call(getSubscriptionPlans, JSON.parse(params))
    yield put(getSubscriptionPlansSuccess(response))
  } catch (error) {
    console.debug("Fetching Subscriptions Error", error)
    yield put(getSubscriptionPlansFail(error))
  }
}
function* fetchSubscriptions({ params }) {
  try {
    const response = yield call(getSubscriptions, JSON.parse(params))
    yield put(getSubscriptionsSuccess(response))
  } catch (error) {
    console.debug("Fetching Subscriptions Error", error)
    yield put(getSubscriptionsFail(error))
  }
}
function* fetchSubscriptionPlansByCategory({ categoryId }) {
  try {
    const response = yield call(getSubscriptionPlansByCategory, categoryId)
    yield put(getSubscriptionPlansSuccess(response))
  } catch (error) {
    console.debug("Fetching Subscriptions Error", error)
    yield put(getSubscriptionPlansFail(error))
  }
}
function* fetchAllSubscriptions() {
  try {
    const response = yield call(getAllSubscriptions)
    yield put(getSubscriptionPlansSuccess(response))
  } catch (error) {
    console.debug("Fetching All Subscriptions Error", error)
    yield put(getSubscriptionPlansFail(error))
  }
}
function* fetchSubscriptionDetail({ id }) {
  try {
    const response = yield call(getSubscriptionPlanDetail, id)
    yield put(getSubscriptionPlanDetailSuccess(response))
  } catch (error) {
    yield put(getSubscriptionPlanDetailFail(error))
  }
}

function* saveSubscription({ payload: { subscriptionPlan } }) {
  try {
   
      const response = yield call(saveSubcription, subscriptionPlan)
      yield put(postSubscriptionSuccess(response))
    
  } catch (error) {
    console.debug(error)
    yield put(
      subscriptionPlanApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function sleep(milliseconds) {
  let start = new Date().getTime()
  for (let i = 0; i < 1e7; i++) {
    if (new Date().getTime() - start > milliseconds) {
      break
    }
  }
}
function* addSubscriptionOption({ payload }) {
  try {
    sleep(200)
    yield put(addSubscriptionOptionSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      subscriptionPlanApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doRemoveSubscription({ payload }) {
  try {
    const response = yield call(removeSubscriptionOption, payload.ID)
    yield put(removeSubscriptionOptionSuccess(payload))
  } catch (error) {
    console.debug(error)
    yield put(
      subscriptionPlanApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateSubscription({ payload: { subscriptionPlan } }) {
  try {
    
    const response = yield call(putSubscription, subscriptionPlan)

    yield put(
      updateSubscriptionSuccess({
        ...response,
        Data: subscriptionPlan,
      })
    )
    // }
  } catch (error) {
    console.debug(error)
    yield put(
      subscriptionPlanApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doEnableSubscription(payload) {
  try {
    let toEnable = true
    let cta = enableSubscription
    if (!payload.enable) {
      cta = disableSubscription
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableSubscriptionSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      subscriptionPlanApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doPurge() {
  yield put(subscriptionPlanPurgeSuccess())
}

function* subscriptionPlansSaga() {
  yield takeLeading(GET_SUBSCRIPTIONS, fetchSubscriptions)
  yield takeLeading(GET_SUBSCRIPTION_PLANS, fetchSubscriptionPlans)
  yield takeLeading(GET_ALL_SUBSCRIPTION_PLANS, fetchAllSubscriptions)
  yield takeEvery(
    GET_SUBSCRIPTION_PLAN_DETAIL,
    fetchSubscriptionDetail
  )
  yield takeEvery(SUBSCRIPTION_PLAN_PURGE, doPurge)
  yield takeLeading(ENABLE_SUBSCRIPTION_PLAN, doEnableSubscription)
  yield takeLeading(UPDATE_SUBSCRIPTION_PLAN, updateSubscription)
  yield takeLeading(SAVE_SUBSCRIPTION_PLAN, saveSubscription)
  yield takeLeading(
    GET_SUBSCRIPTION_PLANS_BY_CATEGORY,
    fetchSubscriptionPlansByCategory
  )
  yield takeLeading(ADD_SUBSCRIPTION_PLAN_OPTION, addSubscriptionOption)
  yield takeLeading(REMOVE_SUBSCRIPTION_PLAN_OPTION, doRemoveSubscription)
}

export default subscriptionPlansSaga
