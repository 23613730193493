import { SettlementAccountType } from "enums/settlement-account-type"
import { GET_WALLET_ACCOUNT_STATEMENT_REPORT, GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL, GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS,  EXPORT_WALLET_TOP_UP_REPORT, EXPORT_WALLET_TOP_UP_REPORT_FAIL, EXPORT_WALLET_TOP_UP_REPORT_SUCCESS, GET_WALLET_TOP_UP_REPORT, GET_WALLET_TOP_UP_REPORT_FAIL, GET_WALLET_TOP_UP_REPORT_SUCCESS, ACTIVATE_SETTLEMENT_ACCOUNT, ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS, ADD_SETTLEMENT_ACCOUNT, ADD_SETTLEMENT_ACCOUNT_FAIL, ADD_SETTLEMENT_ACCOUNT_SUCCESS, CONFIGURE_OTP_RECEIVER, CONFIGURE_OTP_RECEIVER_FAIL, CONFIGURE_OTP_RECEIVER_SUCCESS, GET_SETTLEMENT_ACCOUNTS, GET_SETTLEMENT_ACCOUNTS_FAIL, GET_SETTLEMENT_ACCOUNTS_SUCCESS, GET_SETTLEMENT_WITHDRAWALS, GET_SETTLEMENT_WITHDRAWALS_FAIL, GET_SETTLEMENT_WITHDRAWALS_SUCCESS, GET_WALLET_SETTLEMENT_CONFIGURATION, GET_WALLET_SETTLEMENT_CONFIGURATION_FAIL, GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS, SEND_WITHDRAWAL_OTP, SEND_WITHDRAWAL_OTP_FAIL, SEND_WITHDRAWAL_OTP_SUCCESS, SETTLEMENT_PURGE, SETTLEMENT_PURGE_SUCCESS, SETTLEMENT_WALLET_BALANCE, SETTLEMENT_WALLET_BALANCE_FAIL, SETTLEMENT_WALLET_BALANCE_SUCCESS, SETTLEMENT_WITHDRAWAL, SETTLEMENT_WITHDRAWAL_FAIL, SETTLEMENT_WITHDRAWAL_SUCCESS, WALLET_SETTLEMENT_CONFIGURATION, WALLET_SETTLEMENT_CONFIGURATION_FAIL, WALLET_SETTLEMENT_CONFIGURATION_SUCCESS } from "./actionTypes"

export const getWalletAccountStatementReport = (params) => ({
  type : GET_WALLET_ACCOUNT_STATEMENT_REPORT,
  params
})

export const getWalletAccountStatementReportSuccess = report => ({
  type: GET_WALLET_ACCOUNT_STATEMENT_REPORT_SUCCESS,
  payload : report,
})

export const getWalletAccountStatementReportFail = error => ({
  type: GET_WALLET_ACCOUNT_STATEMENT_REPORT_FAIL,
  payload : error,
})

export const getSettlementWithdrawals = (params) => ({
  type: GET_SETTLEMENT_WITHDRAWALS,
  params
})
export const getSettlementWithdrawalsSuccess = settlementWithdrawals => ({
  type: GET_SETTLEMENT_WITHDRAWALS_SUCCESS,
  payload: settlementWithdrawals,
})
export const getSettlementWithdrawalsFail = error => ({
  type: GET_SETTLEMENT_WITHDRAWALS_FAIL,
  payload: error,
})

export const getSettlementAccount = (accountType:SettlementAccountType = SettlementAccountType.Wallet) => ({
  type: GET_SETTLEMENT_ACCOUNTS,
  accountType
})
export const getSettlementAccountSuccess = account => ({
  type: GET_SETTLEMENT_ACCOUNTS_SUCCESS,
  payload: account,
})
export const getSettlementAccountFail = error => ({
  type: GET_SETTLEMENT_ACCOUNTS_FAIL,
  payload: error,
})

export const withdrawSettlement = (withdrawal) => {
  return {
    type: SETTLEMENT_WITHDRAWAL,
    payload: { withdrawal },
  }
}

export const withdrawSettlementApiError = error => {
  return {
    type: SETTLEMENT_WITHDRAWAL_FAIL,
    payload: error,
  }
}

export const withdrawSettlementSuccess = withdrawal => {
  return {
    type: SETTLEMENT_WITHDRAWAL_SUCCESS,
    payload: withdrawal,
  }
}


export const removeSettlementAccount = (id, enable) => {
  return {
    type: ACTIVATE_SETTLEMENT_ACCOUNT,
    id, enable
  }
}

export const removeSettlementAccountSuccess = terminal => {
  return {
    type: ACTIVATE_SETTLEMENT_ACCOUNT_SUCCESS,
    payload: terminal,
  }
}

export const saveSettlementAccount = (account) => {
  return {
    type: ADD_SETTLEMENT_ACCOUNT,
    payload: { account },
  }
}

export const saveSettlementAccountApiError = error => {
  return {
    type: ADD_SETTLEMENT_ACCOUNT_FAIL,
    payload: error,
  }
}

export const saveSettlementAccountSuccess = account => {
  return {
    type: ADD_SETTLEMENT_ACCOUNT_SUCCESS,
    payload: account,
  }
}

export const getWalletSettlementConfiguration = (params) => {
  return {
    type: GET_WALLET_SETTLEMENT_CONFIGURATION,
   params ,
  }
}

export const getWalletSettlementConfigurationApiError = error => {
  return {
    type:GET_WALLET_SETTLEMENT_CONFIGURATION_FAIL,
    payload: error,
  }
}

export const getWalletSettlementConfigurationSuccess = account => {
  return {
    type:GET_WALLET_SETTLEMENT_CONFIGURATION_SUCCESS,
    payload: account,
  }
}


export const walletSettlementConfiguration = (config) => ({
  type: WALLET_SETTLEMENT_CONFIGURATION,
  payload:{config}
})
export const walletSettlementConfigurationSuccess = config => ({
  type: WALLET_SETTLEMENT_CONFIGURATION_SUCCESS,
  payload: config,
})
export const walletSettlementConfigurationFail = error => ({
  type: WALLET_SETTLEMENT_CONFIGURATION_FAIL,
  payload: error,
})

export const configureOTPReceiver = (approver) => {
  return {
    type: CONFIGURE_OTP_RECEIVER,
    payload: { approver },
  }
}

export const configureOTPReceiverApiError = error => {
  return {
    type: CONFIGURE_OTP_RECEIVER_FAIL,
    payload: error,
  }
}

export const configureOTPReceiverSuccess = approver => {
  return {
    type: CONFIGURE_OTP_RECEIVER_SUCCESS,
    payload: approver,
  }
}

export const getWalletBalance = () => {
  return {
    type: SETTLEMENT_WALLET_BALANCE
  }
}

export const getWalletBalanceApiError = error => {
  return {
    type: SETTLEMENT_WALLET_BALANCE_FAIL,
    payload: error,
  }
}

export const getWalletBalanceSuccess = withdrawal => {
  return {
    type: SETTLEMENT_WALLET_BALANCE_SUCCESS,
    payload: withdrawal,
  }
}

export const settlementPurge = () => ({
  type: SETTLEMENT_PURGE
})

export const settlementPurgeSuccess = () => ({
  type: SETTLEMENT_PURGE_SUCCESS
})


export const sendWithdrawalOTP = (otp) => {
  return {
    type: SEND_WITHDRAWAL_OTP,
    payload: { otp },
  }
}
export const sendWithdrawalOTPSuccess = otp => {
  return {
    type: SEND_WITHDRAWAL_OTP_SUCCESS,
    payload: otp,
  }
}

export const sendWithdrawalOTPFail = error => {
  return {
    type: SEND_WITHDRAWAL_OTP_FAIL,
    payload: error,
  }
}

export const getWalletTopUpReport = params => {
  return {
  type : GET_WALLET_TOP_UP_REPORT,
  params
}}

export const getWalletTopUpReportSuccess = wallet => {
  return {
  type : GET_WALLET_TOP_UP_REPORT_SUCCESS,
  payload : wallet
}}

export const getWalletTopUpReportFail = error => {
  return {
  type : GET_WALLET_TOP_UP_REPORT_FAIL,
  payload : error
}}

export const exportWalletTopUpReport = params => {
  return {
  type : EXPORT_WALLET_TOP_UP_REPORT,
  params
}}

export const exportWalletTopUpSuccess = report => {
  return {
  type : EXPORT_WALLET_TOP_UP_REPORT_SUCCESS,
  payload : report
}}

export const exportWalletTopUpFail = error => {
  return {
  type : EXPORT_WALLET_TOP_UP_REPORT_FAIL,
  payload : error
}}
