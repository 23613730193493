import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { Card, Row, Col, CardBody } from "reactstrap"

//i18n
import { graphqlQuery } from "helpers/api_helper"
import { useGraphql } from "hooks/useGraphql"
import Loading from "components/Common/Loading"
import ClusterChart from "components/Common/Chart"
import { formatGraphDataForDisplay, toTitleCase } from "helpers/utility"
import Search from "../search"
import { GetInstitution } from "helpers/authentication/institution"
import withDispose from "components/HOCs/WithDispose"
const ComparativeCard = props => {
  const { field, color, trxType, currency, type = "bar", height = 400 } = props
  const [range, setRange] = useState("7 days")
  const [trxStatus, setTrxStatus] = useState("SUCCESSFUL")
  const institutionCode = GetInstitution().code
  const [startDateStr, setStartDateStr] = useState(
    range.split(" ")[0] == 24 ||
      range.split(" ")[1].toLowerCase().includes("day")
      ? moment()
          .subtract(...range.split(" "))
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss")
      : moment()
          .subtract(...range.split(" "))
          .format("YYYY-MM-DDTHH:mm:ss")
  )

  const [endDateStr, setEndDateStr] = useState(
    range.split(" ")[0] != 24 &&
      range.split(" ")[1].toLowerCase().includes("hour")
      ? moment().format("YYYY-MM-DDTHH:mm:ss")
      : moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss")
  )
  const query = useMemo(() => {
    switch (type) {
      case "donut":
        return `
            {
              analytics {
                failed: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: FAILED
                    startDate: "${moment(startDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    endDate: "${moment(endDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    institutionCode: "${institutionCode}"
                  }
                ) {
                  cumulative
                }
                successful: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: SUCCESSFUL
                    startDate: "${moment(startDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    endDate: "${moment(endDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    institutionCode: "${institutionCode}"
                  }
                ) {
                  cumulative
                }
                pending: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: PENDING
                    startDate: "${moment(startDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    endDate: "${moment(endDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    institutionCode: "${institutionCode}"
                  }
                ) {
                  cumulative
                }
                reversed: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: REVERSED
                    startDate: "${moment(startDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    endDate: "${moment(endDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    institutionCode: "${institutionCode}"
                  }
                ) {
                  cumulative
                }
                thirdPartyFailure: ${field}(
                  filter: {
                    trxType: ${trxType}
                    trxStatus: THIRDPARTYFAILURE
                    startDate: "${moment(startDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    endDate: "${moment(endDateStr).format(
                      "YYYY-MM-DDTHH:mm:ss"
                    )}"
                    institutionCode: "${institutionCode}"
                  }
                ) {
                  cumulative
                }
              }
            }
            `

      default:
        return `
        {
          analytics {
            ${field}(
              filter: {
                trxType: ${trxType}
                trxStatus: ${trxStatus}
                startDate: "${moment(startDateStr).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )}"
                endDate: "${moment(endDateStr)
                  .endOf("day")
                  .format("YYYY-MM-DDTHH:mm:ss")}"
                institutionCode: "${institutionCode}"
              }
            ) {
              dataProvider(first: 100) {
                nodes {
                  xValue
                  yValue
                }
              }
            }
          }
        }
        `
    }
  }, [trxType, field, endDateStr, startDateStr, trxStatus])

  let [data, { loading }, setData, setLoading] = useGraphql(query, {}, [query])
  let [reloading, setReload] = useState(false)
  let [graphData, setGraphData] = useState()

  useEffect(() => {
    //Refresh data every 5 mins
    setInterval(reload, 300000)
  }, [])

  useEffect(() => {
    let xAxis
    let yAxis
    switch (type) {
      case "donut":
        let analytics = data?.analytics
        xAxis = []
        yAxis = []
        if (analytics) {
          for (let label in analytics) {
            let value = analytics[label].cumulative
            if (value > 0) {
              yAxis.push(toTitleCase(label))

              xAxis.push(value)
            }
            setGraphData({ yAxis, xAxis })
          }
        }
        break
      default:
        let nodes = formatGraphDataForDisplay(
          data,
          field,
          startDateStr,
          endDateStr
        )
        let xValue = []
        let yValue = []

        nodes.forEach(node => {
          xValue.push(node.xValue)
          yValue.push(node.yValue)
        })

        xAxis = {
          type: "text",
          data: xValue,
        }
        yAxis = [
          {
            name: "Amount",
            data: yValue,
          },
        ]
        setGraphData({ yAxis, xAxis })
        break
    }
  }, [useGraphql, data])

  const reload = () => {
    if (!loading) {
      setReload(true)
      graphqlQuery(query, {}, [query]).then(response => {
        setData(response)
        setReload(false)
      })
    }
  }

  const search = params => {
    for (let key in params) {
      let value = params[key]
      switch (key) {
        case "status":
          setTrxStatus(value.toString().toUpperCase())
          break
        case "fromDate":
          setStartDateStr(value)
          break
        case "toDate":
          setEndDateStr(value)
          break
        default:
          break
      }
    }
  }

  return (
    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
      {" "}
      <Col lg={12}>
        <Search
          handleOnSearch={search}
          endDateStr={endDateStr}
          startDateStr={startDateStr}
        ></Search>
        {!loading ? (
          <React.Fragment>
            <div
              onClick={reload}
              className={reloading ? "rotating" : ""}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: "5%",
                top: `-15%`,
                fontSize: "2em",
              }}
            >
              <i className="bx bx-revision"></i>
            </div>
            <ClusterChart
              type={type}
              color={color}
              data={graphData}
              currency={currency}
              height={height}
            ></ClusterChart>
          </React.Fragment>
        ) : (
          <Card>
            <CardBody>
              <Loading></Loading>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  )
}

ComparativeCard.propTypes = {
  title: PropTypes.string,
  color: PropTypes.array,
  currency: PropTypes.bool,
  type: PropTypes.string,
  height: PropTypes.number,
}

// const mapStateToProps = ({ reports } ) =>
// ({
//     analytics: reports.receipts,
//     error:reports.error
// })

// const mapDispatchToProps = dispatch => ({
//   onGetReports: (query,variables,deps) => dispatch(getReports(query,variables,deps)),
// })

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withRouter(ComparativeCard))

export default withDispose(ComparativeCard)
