import { EsusuFundsControlStatus } from "enums/esusu-fund-control-status"
import { UserType } from "enums/user-type"
import { GetInstitution } from "helpers/authentication/institution"
import { Utils } from "helpers/utility"
import { SEARCHOPTIONS } from "helpers/variables"
import { initial } from "lodash"
import moment from "moment"
import { AccordionOption } from "types/accordion-option"
export type AgentCategory = {
  Name: string
  Description: string
  ParentID: number
  Features: any[]
  FeatureIds: number[]
  EsusuFundsControlStatus: EsusuFundsControlStatus
  UserType: UserType
  ID: number
  IsActive: boolean
  EnableSwitching: boolean
}
const Initiate = (
  details: AgentCategory,
  institutionFeatures: any[],
  isUpdate: boolean
): AgentCategory => {
  const institutionFeature = institutionFeatures ?? []
  const categoryFeature = (details?.Features ?? []).filter(
    feature => feature.IsActive
  )
  const features = isUpdate ? [...categoryFeature] : [...institutionFeature]
  return {
    Name: details?.Name,
    EsusuFundsControlStatus: details?.EsusuFundsControlStatus,
    EnableSwitching: details?.EnableSwitching ?? true,
    ParentID: details?.ParentID,
    Description: details?.Description,
    Features: features,
    UserType: details?.UserType ?? UserType.Agent,
    FeatureIds: (features ?? []).map(feature => feature.ID),
    ID: details?.ID,
    IsActive: details?.IsActive,
  }
}
const HEADERS = [
  { title: "Status", name: "status", entityKeyName: "IsActive", isBadge: true },
  { title: "Name", name: "name", entityKeyName: "Name" },
  { title: "Type", name: "type", entityKeyName: "UserType" },
  {
    title: "The Fund Control Status",
    entityKeyName: "EsusuFundsControlStatus",
    name: "theFundControlStatus",
    isBadge: true,
  },
  { title: "Action", name: "action", isAction: true },
]
const ERRORLIST = {
  name: undefined,
  mainFlow: "",
  parent: "",
  fundControlStatus: "",
  description: undefined,
}
const SearchOptions = (startDate, endDate, institutions) => {
  let options = Object.assign({}, SEARCHOPTIONS)

  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")
  options.range.enabled = false
  options.fields = [
    {
      type: "text",
      label: "Name",
      id: "s-name",
      defaultValue: "",
      name: "name",
    },
    {
      type: "dropdown",
      label: "All Institutions",
      id: "institutionCode",
      defaultValue: "- Select -",
      data: MapToDropDown(institutions),
      name: "institutionCode",
      width: 2,
      isRemote: GetInstitution().code == "*",
    }
  ]
  return options
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({
      id: item.ID,
      key: item.ID,
      value: item.Name,
      name: item.Name,
    }))
  }
  return []
}
const MapToRows = category => {
  return {
    id: category.ID,
    name: category.Name,
    description: category.Description,
    institution: category?.InstitutionName,
    code: category.Code,
    type: category.UserType,
    status: {
      label: category.IsActive ? "Active" : "Inactive",
      badgeClass: category.IsActive ? "success" : "secondary",
    },
    theFundControlStatus: {
      label: category.EsusuFundsControlStatus,
      badgeClass: GetBadgeClass(category.EsusuFundsControlStatus),
    },
  }
}

const GetBadgeClass = status => {
  switch (status) {
    case "Enable":
      return "success"
    case "NotSet":
      return "warning"
    default:
      return "secondary"
  }
}
const SearchCriteria = (parameters, maxSize) => {
  return JSON.stringify({
    Name: parameters["name"],
    InstitutionCode: parameters["institutionCode"] != "*" ? parameters["institutionCode"] : "",
    StartIndex: (parameters["page"] - 1) * maxSize,
    MaxSize: maxSize,
  })
}

const MapToModalState = categoryDetail => {
  return [
    {
      title: "",
      items: [
        {
          id: "name",
          label: "Name",
          name: "name",
          value: categoryDetail.Name,
        },
        {
          id: "description",
          label: "Description",
          name: "description",
          value: categoryDetail.Description,
        },
        {
          id: "code",
          label: "Code",
          name: "code",
          value: categoryDetail.Code,
        },
        {
          id: "userType",
          label: "Type",
          name: "userType",
          value: categoryDetail.UserType,
        },
        {
          id: "theFundsControlStatus",
          label: "Fund Control Status",
          name: "EsusuFundsControlStatus",
          value: categoryDetail.EsusuFundsControlStatus,
        },
      ],
    },
  ]
}

const MapFeatures = (
  institutionFeatures: any[],
  category: any,
  disabled: boolean,
  isUpdate: boolean
): AccordionOption[] => {
  const categoryFeatures = (category?.Features ?? []).filter(
    feature => feature.IsActive
  )
  return institutionFeatures
    ? institutionFeatures
        .filter(feature => feature.IsActive)
        .map(feature => {
          const noFeaturesAssigned =
            Utils.List.isEmpty(categoryFeatures) && !isUpdate
          const isChecked =
            !category ||
            (categoryFeatures && categoryFeatures.some(i => i.ID == feature.ID))
          return {
            id: feature.ID,
            name: feature.Code + feature.ID,
            label: feature.Name,
            value: feature.ID,
            checked: isChecked,
            disabled,
            watch: UserType[feature.UserType],
          }
        })
    : []
}

export default {
  HEADERS,
  ERRORLIST,
  SearchOptions,
  MapToRows,
  SearchCriteria,
  MapToModalState,
  MapToDropDown,
  Initiate,
  MapFeatures,
}
