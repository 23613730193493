import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

import { Row, Col, Card, CardBody, FormGroup, Label } from "reactstrap"
import ClusterDropdown from "components/Common/DropDown/Dropdown"
import ClusterButton from "components/Common/Button/Button"
import ClusterInput from "components/Common/Input/Input"
import Loading from "components/Common/Loading"
import { Validators } from "helpers/validators"
import ClusterForm from "components/Common/Form"
import logForDownloadLogic from "../download"
import withFormValidation from "components/HOCs/WithFormValidation"
import moment from "moment"
const LogForDownloadForm = props => {
  const {
    errorList,
    myError,
    reportTypes,
    networkManagers,
    editLoading,
    setErrorList,
    handleOnChange,
    handleValidSubmit,
    isFormInvalid
  } = props
  const { MapToDropDown, MapTypesToDropDown } = logForDownloadLogic;
  useEffect(() => {
    handleOnChange(
      "FromDate",
      moment()
        .startOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")
    )
    handleOnChange(
      "ToDate",
      moment()
        .endOf("day")
        .format("YYYY-MM-DDTHH:mm:ss")
    )
  }, [])
  return (
    <React.Fragment>
      <ClusterForm handleSubmit={handleValidSubmit}>
        <React.Fragment>
          <Card
            outline
            style={{ background: "transparent", borderColor: "#999999" }}
            className="border"
          >
            <CardBody>
              <Row>
                <Col lg={2} sm={3}>
                  <FormGroup>
                    <Label for="fromDate">From Date</Label>

                    <ClusterInput
                      type="date"
                      onError={err =>
                        setErrorList({ ...errorList, fromDate: err })
                      }

                      defaultValue={moment()
                        .startOf("day")
                        .format("YYYY-MM-DD")}

                      required={true}
                      id="fromDate"
                      name="FromDate"
                      onHandleChange={(key, value) =>
                        handleOnChange(
                          key,
                          moment(value)
                            .startOf("day")
                            .format("YYYY-MM-DDTHH:mm:ss")
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={2} sm={3}>
                  <FormGroup>
                    <Label for="toDate">To Date</Label>
                    <ClusterInput
                      type="date"

                      defaultValue={moment()
                        .endOf("day")
                        .format("YYYY-MM-DD")}

                      onError={err =>
                        setErrorList({ ...errorList, toDate: err })
                      }
                      required={true}
                      id="toDate"
                      name="ToDate"
                      onHandleChange={(key, value) =>
                        handleOnChange(
                          key,
                          moment(value)
                            .endOf("day")
                            .format("YYYY-MM-DDTHH:mm:ss")
                        )
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={4} md={4} sm={6}>
                  <FormGroup>
                    <Label>Report Type</Label>
                    {!reportTypes ? (
                      <Loading></Loading>
                    ) : (
                      <ClusterDropdown
                        name={"ReportType"}
                        items={MapTypesToDropDown(reportTypes)}
                        onChange={(name, value) => {
                          handleOnChange("ReportType", value)
                          setErrorList({ ...errorList, reportType: "" })
                        }}
                      ></ClusterDropdown>
                    )}
                  </FormGroup>
                </Col>

                <Col lg={4} md={4}>
                  <ClusterInput
                    onError={err =>
                      setErrorList({ ...errorList, superAgentPhoneNumber: err })
                    }
                    label={"Super Agent Phone Number"}
                    id={"superAgentPhoneNumber"}
                    name={"superAgentPhoneNumber"}
                    validators={[
                      Validators.phone([
                        "Phone number should contain only numbers",
                        "Phone number must be 11 digits",
                      ]),
                    ]}
                    type="tel"
                    onHandleChange={(key, value) => handleOnChange(key, value)}
                  ></ClusterInput>
                </Col>
                <Col lg={4} md={4} sm={6}>
                  <FormGroup>
                    <Label>Network Manager</Label>
                    {!networkManagers ? (
                      <Loading></Loading>
                    ) : (
                      <ClusterDropdown
                        name={"networkManagerID"}
                        items={MapToDropDown(networkManagers)}
                        onChange={(name, value) => {
                          handleOnChange("networkManagerID", value)
                          setErrorList({ ...errorList, networkManagerID: "" })
                        }}
                      ></ClusterDropdown>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </React.Fragment>
        <Row style={{ paddingBottom: "50px" }}>
          <Col lg={12}>
            <ClusterButton
              loading={editLoading}
              disabled={isFormInvalid}
              color={"primary"}
              addon={"bx bx-save"}
            >
              Log For Download
            </ClusterButton>
          </Col>
        </Row>
      </ClusterForm>
    </React.Fragment>
  )
}
LogForDownloadForm.propTypes = {
  id: PropTypes.string,
  myError: PropTypes.string,
  reportTypes: PropTypes.array,
  networkManagers: PropTypes.array,
  loading: PropTypes.bool,
  isRotating: PropTypes.bool,
  editLoading: PropTypes.bool,
  setErrorList: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleValidSubmit: PropTypes.func,
  redirectUrl: PropTypes.string
}
export default withRouter(withFormValidation(LogForDownloadForm))
