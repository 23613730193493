import {
  GET_MOBILEACCOUNTS,
  GET_MOBILEACCOUNT_DETAIL,
  GET_MOBILEACCOUNT_DETAIL_SUCCESS,
  GET_MOBILEACCOUNT_DETAIL_FAIL,
  GET_MOBILEACCOUNTS_SUCCESS,
  GET_MOBILEACCOUNTS_FAIL,
  CREATE_MOBILEACCOUNT,
  POST_MOBILEACCOUNT_SUCCESS,
  MOBILEACCOUNT_PURGE,
  MOBILEACCOUNT_PURGE_SUCCESS,
  POST_MOBILEACCOUNT_FAIL,
  GET_MOBILEWALLET_DETAIL_FAIL,
  GET_MOBILEWALLET_DETAIL_SUCCESS,
  GET_MOBILEWALLET_DETAIL,
  GET_MOBILEWALLETS_FAIL,
  GET_MOBILEWALLETS_SUCCESS,
  GET_ALL_MOBILEWALLETS,
  GET_MOBILEWALLETS,
  GET_MOBILEACCOUNT_BY_PHONE,
  RESET_PIN,
  RESET_PIN_FAIL,
  RESET_PIN_SUCCESS,
  RESET_PIN_TRIES,
  RESET_PIN_TRIES_FAIL,
  RESET_PIN_TRIES_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_TRIES,
  RESET_PASSWORD_TRIES_FAIL,
  RESET_PASSWORD_TRIES_SUCCESS,
  HOTLIST,
  HOTLIST_FAIL,
  HOTLIST_SUCCESS,
  GENERATE_VERIFICATION_CODE,
  GENERATE_VERIFICATION_CODE_FAIL,
  GENERATE_VERIFICATION_CODE_SUCCESS,
  ENABLE_MOBILEACCOUNT,
  ENABLE_MOBILEACCOUNT_SUCCESS,
  SEND_ACTIVATION_CODE,
  SEND_ACTIVATION_CODE_FAIL,
  SEND_ACTIVATION_CODE_SUCCESS,
} from "./actionTypes"

export const getMobileAccounts = params => ({
  type: GET_MOBILEACCOUNTS,
  params,
})
export const getAllMobileAccounts = () => ({
  type: GET_ALL_MOBILEACCOUNTS,
})
export const getMobileAccountsSuccess = mobileAccounts => ({
  type: GET_MOBILEACCOUNTS_SUCCESS,
  payload: mobileAccounts,
})
export const getMobileAccountsFail = error => ({
  type: GET_MOBILEACCOUNTS_FAIL,
  payload: error,
})
export const getMobileAccountDetail = id => ({
  type: GET_MOBILEACCOUNT_DETAIL,
  id,
})
export const getMobileAccountByPhoneNumber = phoneNumber => ({
  type: GET_MOBILEACCOUNT_BY_PHONE,
  phoneNumber,
})
export const getMobileAccountDetailSuccess = mobileAccounts => ({
  type: GET_MOBILEACCOUNT_DETAIL_SUCCESS,
  payload: mobileAccounts,
})

export const getMobileAccountDetailFail = error => ({
  type: GET_MOBILEACCOUNT_DETAIL_FAIL,
  payload: error,
})

export const getMobileWallets = params => ({
  type: GET_MOBILEWALLETS,
  params,
})
export const getAllMobileWallets = () => ({
  type: GET_ALL_MOBILEWALLETS,
})
export const getMobileWalletsSuccess = mobileWallets => ({
  type: GET_MOBILEWALLETS_SUCCESS,
  payload: mobileWallets,
})
export const getMobileWalletsFail = error => ({
  type: GET_MOBILEWALLETS_FAIL,
  payload: error,
})
export const getMobileWalletDetail = id => ({
  type: GET_MOBILEWALLET_DETAIL,
  id,
})

export const getMobileWalletDetailSuccess = mobileWallets => ({
  type: GET_MOBILEWALLET_DETAIL_SUCCESS,
  payload: mobileWallets,
})

export const getMobileWalletDetailFail = error => ({
  type: GET_MOBILEWALLET_DETAIL_FAIL,
  payload: error,
})

export const createMobileAccount = mobileAccount => {
  return {
    type: CREATE_MOBILEACCOUNT,
    payload: { mobileAccount },
  }
}

export const mobileAccountApiError = error => {
  return {
    type: POST_MOBILEACCOUNT_FAIL,
    payload: error,
  }
}

export const postMobileAccountSuccess = mobileAccount => {
  return {
    type: POST_MOBILEACCOUNT_SUCCESS,
    payload: mobileAccount,
  }
}

export const mobileAccountPurge = () => ({
  type: MOBILEACCOUNT_PURGE,
})

export const mobileAccountPurgeSuccess = () => ({
  type: MOBILEACCOUNT_PURGE_SUCCESS,
})

export const enableMobileAccount = (id, enable) => {
  return {
    type: ENABLE_MOBILEACCOUNT,
    id,
    enable,
  }
}

export const enableMobileAccountSuccess = mobileAccount => {
  return {
    type: ENABLE_MOBILEACCOUNT_SUCCESS,
    payload: mobileAccount,
  }
}

export const updateMobileAccount = mobileAccount => {
  return {
    type: UPDATE_MOBILEACCOUNT,
    payload: { mobileAccount },
  }
}
export const updateMobileAccountSuccess = mobileAccount => {
  return {
    type: UPDATE_MOBILEACCOUNT_SUCCESS,
    payload: mobileAccount,
  }
}
export const purgeMobileAccounts = () => {
  return {
    type: PURGE_MOBILEACCOUNTS,
  }
}

export const purgeMobileAccountSuccess = () => {
  return {
    type: PURGE_MOBILEACCOUNTS_SUCCESS,
  }
}

export const resetPin = id => {
  return {
    type: RESET_PIN,
    payload: { id },
  }
}

export const resetPinError = error => {
  return {
    type: RESET_PIN_FAIL,
    payload: error,
  }
}
export const resetPinSuccess = response => {
  return {
    type: RESET_PIN_SUCCESS,
    payload: response,
  }
}

export const resetPinTries = id => {
  return {
    type: RESET_PIN_TRIES,
    payload: { id },
  }
}

export const resetPinTriesError = error => {
  return {
    type: RESET_PIN_TRIES_FAIL,
    payload: error,
  }
}
export const resetPinTriesSuccess = response => {
  return {
    type: RESET_PIN_TRIES_SUCCESS,
    payload: response,
  }
}



export const resetPassword = id => {
  return {
    type: RESET_PASSWORD,
    payload: { id },
  }
}

export const resetPasswordError = error => {
  return {
    type: RESET_PASSWORD_FAIL,
    payload: error,
  }
}
export const resetPasswordSuccess = response => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: response,
  }
}

export const resetPasswordTries = id => {
  return {
    type: RESET_PASSWORD_TRIES,
    payload: { id },
  }
}

export const resetPasswordTriesError = error => {
  return {
    type: RESET_PASSWORD_TRIES_FAIL,
    payload: error,
  }
}
export const resetPasswordTriesSuccess = response => {
  return {
    type: RESET_PASSWORD_TRIES_SUCCESS,
    payload: response,
  }
}

export const sendActivationCode = id => {
  return {
    type: SEND_ACTIVATION_CODE,
    payload: { id },
  }
}

export const sendActivationCodeError = error => {
  return {
    type: SEND_ACTIVATION_CODE_FAIL,
    payload: error,
  }
}
export const sendActivationCodeSuccess = response => {
  return {
    type: SEND_ACTIVATION_CODE_SUCCESS,
    payload: response,
  }
}

export const hotlist = id => {
  return {
    type: HOTLIST,
    id,
  }
}

export const hotlistError = error => {
  return {
    type: HOTLIST_FAIL,
    payload: error,
  }
}
export const hotlistSuccess = response => {
  return {
    type: HOTLIST_SUCCESS,
    payload: response,
  }
}

export const generateVerificationCode = id => {
  return {
    type: GENERATE_VERIFICATION_CODE,
    id,
  }
}

export const generateVerificationCodeError = error => {
  return {
    type: GENERATE_VERIFICATION_CODE_FAIL,
    payload: error,
  }
}
export const generateVerificationCodeSuccess = response => {
  return {
    type: GENERATE_VERIFICATION_CODE_SUCCESS,
    payload: response,
  }
}
