import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "./report"

//Import Breadcrumb

//i18n
import { withTranslation } from "react-i18next"
import {
  subscriptionPlanPurge,
  removeSubscriptionOption,
} from "store/actions"
import SubscriptionLogic from "../../volume-covenant"
import withDispose from "components/HOCs/WithDispose"


const OptionsList = props => {
  const {
    totalCount,
    loading,
    editLoading,
    subscriptionPlanOptions,
    onRemoveSubscription
  } = props
  const {
    OPTIONSHEADERS,
    UpdateErrorList,
    MapToRowsOptions
  } = SubscriptionLogic
  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([...OPTIONSHEADERS])
  const [range, setRange] = useState(() => [
    moment().subtract(1, "month"),
    moment(),
  ])
  const [isEnabled, setIsEnabled] = useState(false)
  const [startDate, endDate] = range
  const [startDateStr, endDateStr] = useMemo(
    () => [
      startDate.startOf("day").format("YYYY-MM-DDTHH:mm:ss"),
      endDate.endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    ],
    [startDate, endDate]
  )
  const maxSize = 10
  const [pageNumber, setPageNumber] = useState(1)
  const [viewedSubscription, setViewedSubscription] = useState()

  const [params, setParams] = useState({
    fromDate: startDateStr,
    toDate: endDateStr,
    page: pageNumber,
  })
  const [errorList, setErrorList] = useState(UpdateErrorList())
  useEffect(() => {
    populateRows()
  }, [subscriptionPlanOptions])
  const populateRows = () => {
    if (subscriptionPlanOptions) {
      let rws = []
      subscriptionPlanOptions.forEach(option => {
        let row = MapToRowsOptions(option)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => removeOption(option.ID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }
  function removeOption(id) {
    onRemoveSubscription(id)
  }

  const handlePageChange = (page) => {

  }
  return (
    <React.Fragment>
      <Container fluid>

        <Row>
          <Col lg="12">
            <Report
              tableData={{
                columns: columns,
                rows: rows,
                paginationOptions: {
                  totalCount: totalCount,
                  onPageChange: page => handlePageChange(page),
                  pageNumber: pageNumber,
                  maxSize: maxSize,
                },
                pageTitle: undefined,
                isLoading: rows.length == 0 ? false : editLoading,
              }}
            />
          </Col>
        </Row>
      </Container>


    </React.Fragment>
  )
}

OptionsList.propTypes = {
  subscriptionPlans: PropTypes.array,
  subscriptionPlanDetail: PropTypes.object,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  categoryId: PropTypes.number,
}

const mapStateToProps = ({
  subscriptions: {
    subscriptionPlans,
    subscriptionPlanOptions,
    error,
    success,
    subscriptionPlanDetail,
    totalCount,
    deleteLoading,
    loading,
    editLoading,
    statusChanged,
  },
}) => {
  return {
    subscriptionPlans,
    subscriptionPlanOptions,
    error,
    success,
    deleteLoading,
    subscriptionPlanDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
  }
}

const mapDispatchToProps = dispatch => ({
  onPurge: () => dispatch(subscriptionPlanPurge()),
  onRemoveSubscription: payload =>
    dispatch(removeSubscriptionOption(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(OptionsList))))
