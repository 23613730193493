import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { TabPane, Row, Col } from "reactstrap"

import ClusterTabPanel from "components/Common/TabPanel"

import TrendCard from "./card"
import withDispose from "components/HOCs/WithDispose"
import { useDashboardView } from "hooks/useDashboardView"
const TrendList = ({ trxType, amount }) => {
const {data} = useDashboardView();
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <ClusterTabPanel title={"TRANSACTION VALUE"} tabs={data}>
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <TrendTabContent
                    trxType={types.type}
                    field={"trxVolTrend"}
                    color={["#ee4266"]}
                    currency={true}
                  ></TrendTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={6}>
          <ClusterTabPanel title={"TRANSACTION COUNT"} tabs={data}>
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <TrendTabContent
                    trxType={types.type}
                    field={"trxCountTrend"}
                  ></TrendTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <ClusterTabPanel title={"ACTIVE AGENTS"} tabs={data}>
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <TrendTabContent
                    trxType={types.type}
                    field={"activeAgentsTrend"}
                    color={["#f4c95d"]}
                  ></TrendTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
        <Col lg={6}>
          <ClusterTabPanel title={"COMMISSIONS EARNED"} tabs={data}>
            <React.Fragment>
              {data.map((types, key) => (
                <TabPane key={`_tabpane_${key}`} tabId={`${types.id}`}>
                  <TrendTabContent
                    trxType={types.type}
                    field={"commissionsEarnedTrend"}
                    color={["#7e2e84"]}
                  ></TrendTabContent>
                </TabPane>
              ))}
            </React.Fragment>
          </ClusterTabPanel>
        </Col>
      </Row>
    </React.Fragment>
  )
}

function TrendTabContent({ trxType, color, field, currency }) {
  const handleOnChange = value => {}

  return (
    <React.Fragment>
      {/* <Row> */}
      {/* <Col lg={12} md={12} sm={12}> */}
      <TrendCard
        trxType={trxType}
        subtitle="7 Days"
        field={field}
        yValueLabel="value"
        currency={currency}
        trxStatusFilter
        color={color}
      ></TrendCard>
      {/* </Col>  */}

      {/* </Row> */}
    </React.Fragment>
  )
}

TrendList.propTypes = {
  trxType: PropTypes.string,
}
export default withDispose(TrendList)
