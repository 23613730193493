import React, { useState, useEffect, useMemo } from "react"
import PropTypes from "prop-types"

import moment from "moment"
import { Card, Row, Col, CardBody } from "reactstrap"

import ReactApexChart from "react-apexcharts"
//i18n
import { graphqlQuery } from "helpers/api_helper"
import { useGraphql } from "hooks/useGraphql"
import Loading from "components/Common/Loading"
import { formatGraphDataForDisplay, mapColor, toFixedDown, Utils } from "helpers/utility"
import { GetInstitution } from "helpers/authentication/institution"
import withDispose from "components/HOCs/WithDispose"
import NoData from "components/Common/NoData"
import { useDashboardView } from "hooks/useDashboardView"
const ComparativeCard = props => {
  const { field, color, trxType, startDateStr, endDateStr, currency, type = "bar", height = 400 } = props
  const [trxStatus, setTrxStatus] = useState("SUCCESSFUL")
  const institutionCode = GetInstitution().code
  
  const view = useDashboardView();
  const createQuery = () => {
    return `
          {
            analytics {
              ${field}(
                filter: {
                  trxStatus: ${trxStatus}
                  startDate: "${moment(startDateStr).format(
      'YYYY-MM-DDTHH:mm:ss'
    )}"
                  endDate: "${moment(endDateStr)
        .endOf("day")
        .format('YYYY-MM-DDTHH:mm:ss')}"
                  institutionCode: "${institutionCode}"
                }
              ) {
                dataProvider() {
                  nodes {
                    xValue
                    yValue
                    transactionTypeName
                  }
                }
              }
            }
          }
          `
  }
  const query = useMemo(() => {
    return createQuery(trxType)
  }, [trxType, field, endDateStr, startDateStr, trxStatus])

  let [ data,{ loading }, setData, setLoading] = useGraphql(query, {}, [query])

  useEffect(() => {
    let query = createQuery()
    graphqlQuery(query, {}, [query]).then(response => {
      setData(response)
    })
  }, [endDateStr, startDateStr])

  const graphData = useMemo(()=>{
    
    let labels = []
    let group = {}
    let labelSet = new Set();
    let nodes = formatGraphDataForDisplay(
      data,
      field,
      startDateStr,
      endDateStr
    )
    if(Utils.List.isEmpty(view?.data)) return;
    nodes.map(x => {
      if(view.data.find(t=>t.type.toLowerCase()===x.transactionTypeName.toLowerCase())){
      if (!labelSet.has(x.xValue)) {
        labelSet.add(x.xValue)
        labels.push(x.xValue)
      }
      if (x.transactionTypeName) {
        let key = x.transactionTypeName.toLowerCase();
        if (!group[key]) group[key] = {}
        group[key][x.xValue] = x.yValue
      }
    }
    })

    let series = []
    view.data.forEach(transactionType => {
      let values = group[transactionType.type.toLowerCase()]
      let arr = []
      if (values) {
        labels.forEach(l => {
          arr.push(values[l] || 0)
        })
        series.push({ transactionType: transactionType.type.toLowerCase(), value: arr })
      }
    })
    let graph = {
      labels: [...labels],
      series: [...series],
      group
    }
   return graph
  }, [data])

  const reload = () => {
    setLoading(true)
    let query = createQuery()
    graphqlQuery(query, {}, [query]).then(response => {
      setData(response)
      setLoading(false)
    })
  }

  const search = params => {
    for (let key in params) {
      let value = params[key]
      switch (key) {
        case "status":
          setTrxStatus(value.toString().toUpperCase())
          break
        case "fromDate":
          setStartDateStr(value)
          break
        case "toDate":
          setEndDateStr(value)
          break
        default:
          break
      }
    }
  }

  return (
    <Row style={{ textAlign: "center" }}>
      {" "}
      <Col lg={12}>
        {!loading ? (
          <React.Fragment>
            <StackedChart graph={graphData} transactionTypes={view.data??[]} />
          </React.Fragment>
        ) : (
          <Card>
            <CardBody>
              <Loading></Loading>
            </CardBody>
          </Card>
        )}
      </Col>
    </Row>
  )
}

ComparativeCard.propTypes = {
  title: PropTypes.string,
  color: PropTypes.array,
  currency: PropTypes.bool,
  type: PropTypes.string,
  height: PropTypes.number,
}

const StackedChart = ({ graph, transactionTypes }) => {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      stacked: !0,
      toolbar: {
        show: 1
      },
      zoom: {
        enabled: true
      }
    },
    plotOptions: {
      bar: {
        horizontal: !1,
        columnWidth: "15%"
        // endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: !1
    },
    xaxis: {
      show: true,
      categories: [],
      labels: {
        show: true
      }
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return "₦" + formatter(value)
        }
      },
    },
    colors: ["#556ee6", "#f1b44c", "#34c38f", "#556ee6", "#f1b44c", "#34c38f", "#34c38f"],
    legend: {
      position: "bottom"
    },
    fill: {
      opacity: 1
    }
  })

  const format = (value, currency) => {
    return value.toFixed(2).toString().replace(".00", "")
  }
  const formatter = (n) => {
    let currency = "₦"
    if (!n) return n;
    if (n < 1e3) return format(n, currency)
    if (n >= 1e3 && n < 1e6)
      return format(toFixedDown(+(n / 1e3)), currency) + "K"
    if (n >= 1e6 && n < 1e9)
      return format(toFixedDown(+(n / 1e6)), currency) + "M"
    if (n >= 1e9 && n < 1e12)
      return format(toFixedDown(+(n / 1e9)), currency) + "B"
    if (n >= 1e12) return format(toFixedDown(+(n / 1e12)), currency) + "T"
  }
  useEffect(() => {

    if (graph.labels) {
      let opts = Object.assign({}, options);
      opts.xaxis.categories = graph.labels

      opts.colors = []
      for (let key in graph.group) {
        const color = mapColor(transactionTypes.find(t => t.type.toLowerCase() == key)?.color??"blue");
        opts.colors.push(color)
      }

      setOptions(opts);
    }
    if (graph.series) {

      let series = [];
      let i = 0;
      for (let key in graph.group) {
        let serie = graph.series.find(s => s.transactionType == key);
        if (serie) {
          series.push({
            name: serie.transactionType.toLocaleUpperCase(),
            data: serie.value
          });
        }
        i += 1;
      }

      setSeries(series)
    }

  }, [graph])

  return (series.length == 0) ? <NoData /> : <ReactApexChart
    options={options}
    series={series}
    type="bar"
    height="359"
  />


}

export default withDispose(ComparativeCard)
