import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Validators } from "helpers/validators"
import { getMenu } from "../../store/actions"
import { compare, Utils } from "helpers/utility"
import agents from "assets/images/navigation/agents.svg"
import dashboards from "assets/images/navigation/dashboard.svg"
import cases from "assets/images/navigation/cases.svg"
import categories from "assets/images/navigation/categories.svg"
import chargebacks from "assets/images/navigation/charge-backs.svg"
import clustermanagers from "assets/images/navigation/cluster-managers.svg"
import commissions from "assets/images/navigation/commissions.svg"
import downloads from "assets/images/navigation/downloads.svg"
import mobileaccounts from "assets/images/navigation/mobile-accounts.svg"
import networkmanagers from "assets/images/navigation/network-managers.svg"
import reports from "assets/images/navigation/reports.svg"
import userroles from "assets/images/navigation/user-roles.svg"
import users from "assets/images/navigation/users.svg"
import { REPORTTYPE } from "helpers/variables"

const getIcon = key => {
  return undefined
  return {
    agents,
    dashboards,
    cases,
    categories,
    chargebacks,
    clustermanagers,
    commissions,
    downloads,
    mobileaccounts,
    networkmanagers,
    reports,
    userroles,
    users
  }[getKey(key)]
}
const getKey = value => {
  return value.toString().replace(" ", "").toLowerCase()
}

const SidebarContent = props => {
  const ref = useRef()

  const { menus, onGetPermissions } = props

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })
  useEffect(() => {
    onGetPermissions()
  }, [])
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {menus
              .filter(m => m.parent.includes("Dashboard"))
              .sort(compare("parent"))
              .filter(p => {
                if (p.children.every(c => c.isRoute)) return false
                return p.parent
              })
              .map((menuItem, idx) => {
                return (
                  <li key={idx}>
                    <Link to="/#" className="waves-effect">
                      <i className={"bx " + menuItem.icon}></i>
                      <span>{props.t(menuItem.parent)}</span>
                    </Link>
                    {menuItem.children.length > 0 ? (
                      <ul className="sub-menu" aria-expanded="false">
                        {menuItem.children
                          .sort(compare("name"))
                          .filter(p => !p.isRoute)
                          .map((p, idx) => {
                            return (
                              <li key={"sub " + idx}>
                                <Link to={p.url}>{props.t(p.name)}</Link>
                              </li>
                            )
                          })}
                      </ul>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </li>
                )
              })}

            {menus
              .filter(m => !m.parent.includes("Dashboard"))
              .sort(compare("parent"))
              .filter(p => {
                if (p.children.every(c => c.isRoute)) return false
                return p.parent
              })
              .map((menuItem, idx) => {
                return (
                  <li key={idx}>
                    <Link to="/#" className="waves-effect">
                      <i className={"bx " + menuItem.icon}></i>
                      <span>{props.t(menuItem.parent)}</span>
                    </Link>
                    {menuItem.children.length > 0 ? (
                      <ul className="sub-menu" aria-expanded="false">
                        {menuItem.children
                          .filter(
                            m =>
                              !REPORTTYPE.map(item => item.roleName).includes(
                                m.roleName
                              )
                          )
                          .sort(compare("name"))
                          .filter(p => !p.isRoute)
                          .map((p, idx) => {
                            return (
                              <li key={"sub " + idx}>
                                <Link to={p.url}>{props.t(p.name)}</Link>
                              </li>
                            )
                          })}
                      </ul>
                    ) : (
                      <React.Fragment></React.Fragment>
                    )}
                  </li>
                )
              })
              }
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  menus: PropTypes.array,
  error: PropTypes.any,
  onGetPermissions: PropTypes.func,
  t: PropTypes.any,
}

const mapStateToProps = ({ permissions, error }) => {
  const err = Validators.extractError(permissions.error)
  return {
    menus: permissions.menus,
    error: err,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetPermissions: () => dispatch(getMenu()),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(SidebarContent)))
