import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

import moment from "moment"
import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  getTerminalDetail,
  getTerminals,
  enableTerminal,
  purgeTerminals,
} from "store/actions"
import TerminalModal from "components/Common/Modals/Modal"
import withDispose from "components/HOCs/WithDispose"
import TerminalLogic from "../terminal"
import { EDIT_TERMINAL, ENABLE_TERMINALS } from "../permission"

import {
  GET_ALL_TERMINALS,
  GET_ALL_TERMINAL_MANAGERS,
  GET_TERMINALS,
} from "helpers/url_helper"
import { toast } from "react-toastify"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import ClusterButton from "components/Common/Button/Button"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"


const TerminalList = props => {
  const {
    terminals,
    onGetTerminals,
    error,
    success,
    onGetTerminalDetail,
    terminalDetail,
    onPurge,
    totalCount,
    editLoading,
    statusChanged,
    loading,
    onEnableTerminal,
  } = props

  const { HEADERS, MapToModalState, MapToRows, SearchCriteria, SearchOptions } = TerminalLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows, search
  } = useReportData({ onGetData: onGetTerminals, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const { institutionsData, institutions } = useInstitutionsData()
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
  const [viewedTerminal, setViewedManager] = useState()
  const [isEnabled, setIsEnabled] = useState(false)

  useEffect(() => {
    populateRows()
  }, [terminals])

  useEffect(() => {
    if (terminalDetail && Object.keys(terminalDetail).length > 0) {
      setmodal(true)
      setViewedManager(terminalDetail)
      setModalData(MapToModalState(terminalDetail))
    }
  }, [terminalDetail])

  useEffect(() => {
    getIsEnabled()
  }, [statusChanged, terminalDetail])
  useEffect(() => {
    let query = SearchCriteria(params, maxSize)
    search(query)
  }, [statusChanged])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  const populateRows = () => {
    if (terminals) {
      let rws = []
      terminals.forEach(terminal => {
        let row = MapToRows(terminal)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(terminal.TerminalID)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })

      setRows(rws)
    }
  }

  function getIsEnabled() {
    let enabled = false
    if (terminalDetail?.IsActive || statusChanged == true) enabled = true
    setIsEnabled(enabled)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetTerminalDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Terminal"} breadcrumbItem={"View Terminals"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_TERMINALS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <TerminalModal
          badgeClass={viewedTerminal?.IsActive ? "success" : "secondary"}
          status={viewedTerminal?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Terminal Details"}
        >
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_TERMINALS]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableTerminal(viewedTerminal?.ID, !viewedTerminal?.IsActive)
            }
            color="secondary"
          >
            {viewedTerminal?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-terminal/" + viewedTerminal?.TerminalID}>
            <ClusterButton
              permissions={[EDIT_TERMINAL]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </TerminalModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

TerminalList.propTypes = {
  terminals: PropTypes.array,
  terminalDetail: PropTypes.object,
  onGetTerminals: PropTypes.func,
  onGetTerminalDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  terminals: {
    terminals,
    error,
    success,
    terminalDetail,
    totalCount,
    loading,
    statusChanged,
    editLoading,
  },
}) => {
  // console.debug(terminals, error, terminalDetail, totalCount, loading)
  return {
    terminals: [...terminals],
    error,
    terminalDetail: terminalDetail,
    totalCount,
    success,
    loading,
    statusChanged,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetTerminals: query => dispatch(getTerminals(query)),
  onGetTerminalDetail: id => dispatch(getTerminalDetail(id)),
  onPurge: () => dispatch(purgeTerminals()),

  onEnableTerminal: (id, enable) => dispatch(enableTerminal(id, enable)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(TerminalList))))
