import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"

import {
  CardBody,
  Container,
  Card,
  Row,
  Col,
  CardHeader,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  getCaseLogMessages,
  getCaseLogDetail,
  sendMessage,
  resolveCase,
  markAsRead,
} from "store/actions"
import withDispose from "components/HOCs/WithDispose"
import withMinimize from "components/HOCs/WithMinimize"
import ClusterChatBox from "components/Common/ChatBox"
import authHeader from "helpers/authentication/auth-token-header"
import { toast } from "react-toastify"
import ClusterButton from "components/Common/Button/Button"
import Loading from "components/Common/Loading"
import { VIEW_CASELOG_DETAILS } from "../permission"
import GuardedPage from "components/HOCs/GuardedPage"
const CaseLogDetails = props => {
  const {
    match,
    onMessageSend,
    onResolveCase,
    onMarkAsRead,
    onGetCaseLogDetail,
    onGetCaseLogMessages,
    success,
    editLoading,
    loading,
    error,
    caseLogDetail,
    caseLogs,
    messages,
  } = props
  const [pageLoading, setLoading] = useState(true)
  useEffect(() => {
    onMarkAsRead({
      reference: caseLogDetail?.CaseReference,
      agentPhoneNumber: caseLogDetail?.Agent?.PhoneNumber,
    })
    if (caseLogDetail) {
      setTimeout(() => setLoading(false), 1000)
    }
  }, [caseLogDetail])

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    let id = match.params.id
    onGetCaseLogDetail(id)
    onGetCaseLogMessages(id)
  }, [match])

  const handleMessageSending = message => {
    onMessageSend({
      Username: authHeader().AuthUser.userName,
      Comment: message,
      CaseReference: caseLogDetail.CaseReference,
    })
  }
  let Details = withMinimize(CaseDetails)
  return (
    <GuardedPage permissions={[VIEW_CASELOG_DETAILS]}>
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title={"Case Log"}
              breadcrumbItem={caseLogDetail?.CaseReference}
            />
            {pageLoading ? (
              <Loading />
            ) : (
              <React.Fragment>
                <Details caseLogDetail={caseLogDetail} />
                <Row className="animate__animated animate__fadeInUp">
                  <Col lg={12}>
                    <ClusterChatBox
                      messages={messages}
                      onSend={handleMessageSending}
                    >
                      {!caseLogDetail.IsResolvedBySupport ? (
                        <ClusterButton
                          buttonStyle={{ margin: 0 }}
                          loading={editLoading}
                          color="success"
                          outline
                          onHandleClick={() => onResolveCase(caseLogDetail.ID)}
                        >
                          Resolve Case
                        </ClusterButton>
                      ) : (
                        <Button color="success" disabled>
                          Case Resolved
                        </Button>
                      )}
                    </ClusterChatBox>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </Container>
        </div>
      </React.Fragment>
    </GuardedPage>
  )
}

CaseLogDetails.propTypes = {
  error: PropTypes.any,
  success: PropTypes.any,
  onCreateCaseLog: PropTypes.func,
  onGetCaseLogMessages: PropTypes.func,
  onBack: PropTypes.func,
  messages: PropTypes.array,
  caseLogDetail: PropTypes.object,
  caseLogs: PropTypes.array,
  editLoading: PropTypes.bool,
  loading: PropTypes.bool,
  t: PropTypes.any,
}

const mapStateToProps = ({
  caseLogs: {
    caseLogs,
    error,
    success,
    messages,
    editLoading,
    totalCount,
    loading,
    caseLogDetail,
  },
}) => {
  return {
    caseLogs: [...caseLogs],
    error,
    success,
    messages: messages,
    totalCount,
    caseLogDetail,
    loading,
    editLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetCaseLogMessages: id => dispatch(getCaseLogMessages(id)),
  onGetCaseLogDetail: id => dispatch(getCaseLogDetail(id)),
  onMessageSend: payload => dispatch(sendMessage(payload)),
  onResolveCase: id => dispatch(resolveCase(id)),
  onMarkAsRead: payload => dispatch(markAsRead(payload)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(withDispose(CaseLogDetails))))

const CaseDetails = props => {
  let { caseLogDetail } = props
  return (
    <Card>
      <CardHeader title="Case Details" />
      <CardBody color="pink">
        <Row>
          <Col lg={12} style={{ textAlign: "center" }}>
            <h3>{caseLogDetail?.Subject}</h3>
            <p>{caseLogDetail.Description}</p>
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <Row>
          <Col lg={3}>
            <p>Agent Name</p>
            <p>
              <b>
                {caseLogDetail.Agent?.LastName}{" "}
                {caseLogDetail.Agent?.OtherNames}
              </b>
            </p>
          </Col>
          <Col lg={3}>
            <p>Agent Phone Number</p>
            <p>
              <b>{caseLogDetail.Agent?.PhoneNumber}</b>
            </p>
          </Col>
          <Col lg={3}>
            <p>Product</p>
            <p>
              <b>{caseLogDetail.Product}</b>
            </p>
          </Col>
          <Col lg={3}>
            <p>Case Reporter Email</p>
            <p>
              <b>{caseLogDetail.CaseReporterEmail}</b>
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}
