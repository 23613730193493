import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Row, Col, Button, Container } from "reactstrap"
import Report from "../../ReportManagement/report"

import Breadcrumbs from "components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import withDispose from "components/HOCs/WithDispose"
import UnsettledTransactionLogic from "../unsettled-transaction"
import { batchResolveUnsettledTransaction, downloadUnsettledTransaction, downloadUnsettledTransactionReceipt, getUnsettledTransactionDetail, getUnsettledTransactions, purgeUnsettledTransactions, resolveUnsettledTransaction } from "store/actions"
import { toast } from "react-toastify"
import ClusterDataTable from "components/Common/DataTable"
import ClusterModal from "components/Common/Modals/Modal"
import { Utils } from "helpers/utility"
import { APPROVE_UNSETTLED_TRANSACTIONS } from "pages/UnsettledTransactionManagement/permissions"
import { DOWNLOAD_UNSETTLED_TRANSACTIONS, DOWNLOAD_UNSETTLED_TRANSACTIONS_RECEIPTS } from "helpers/url_helper"
import ClusterButton from "components/Common/Button/Button"
import { get } from "helpers/api_helper"
import { ViewedBatchType, downloadReceipt, downloadTransactions } from "./handleFiles"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const ApproveUnsettledTransactionsList = props => {
  const {
    unsettledTransactionApprovals,
    unsettledTransactionApproval,
    onGetTransactions,
    onGetTransactionDetails,
    onApprove,
    onBatchApprove,
    onBatchReject,
    onReject,
    error,
    success,
    totalCount,
    loading,
    editLoading,
    resolveLoading,
    batchResolveLoading
  } = props


  const { APPROVAL_HEADERS, TRANSACTION_HEADERS, HEADERS, MapToModalState, MapApprovalToRows, MapToRows, SearchCriteria, SearchOptions } = UnsettledTransactionLogic;
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetTransactions, HEADERS: APPROVAL_HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [viewedBatch, setViewedBatch] = useState<ViewedBatchType>()

  const { institutionsData, institutions } = useInstitutionsData()
  const [searchOptions, setSearchOptions] = useState(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  useEffect(() => {
    setSearchOptions(SearchOptions(params?.fromDate, params?.toDate, institutionsData))
  }, [institutions])
  const [batchID, setBatchID] = useState<number>()
  const [currentlyResolving, setCurrentlyResolving] = useState();
  useEffect(() => {
    populateRows()
  }, [unsettledTransactionApprovals])

  const getTransaction =(batchID) => {
      const transaction = unsettledTransactionApprovals.find(t => t.ID == batchID);
      if (transaction) {
      transaction["IsApproved"] = (+(transaction?.AmountApproved) + +(transaction?.AmountRejected)) >= +(transaction?.Total);
      setModalData(MapToModalState(transaction))
      return transaction;
    }
  }

  useEffect(() => {

    if (success) {
      toast.success(success);
      setCurrentlyResolving(undefined)
    }
    if (error) {
      toast.error(error);
      if (modal) setmodal(!modal);
      setCurrentlyResolving(undefined)
    }
  }, [error, success])

  const populateRows = () => {
    if (unsettledTransactionApprovals) {
      let rws = []
      unsettledTransactionApprovals.forEach(trx => {
        let row = MapApprovalToRows(trx);
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => {
              setmodal(true)
              onGetTransactionDetails(trx.ID)
              setBatchID(trx.ID)
              setViewedBatch(getTransaction(trx.ID))
            }}
          >
            View Details
          </Button>
        );
        rws.push(row)
      })

      setRows(rws)
    }
  }

  const onResolve = (transactionID: number, approve: boolean) => {
    if (approve) onApprove(batchID, transactionID, true)
    else onReject(batchID, transactionID, false)
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Unsettled Transaction"} breadcrumbItem={"Approve Unsettled Transaction"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: "Upload Validated Batch",
                  isLoading: loading,

                }}

                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}

              />
            </Col>
          </Row>

        </Container>
      </div>
      {modal ? (
        <ClusterModal
          open={modal}
          title={"Unsettled Transactions"}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          success={success}
          error={error}
          append={[
            <UnsettledTransactionReport
              key={1}
              columns={TRANSACTION_HEADERS}
              rows={
                unsettledTransactionApproval.map(trx => {
                  const acc = MapToRows(trx);
                  let actions = {};
                  if (!viewedBatch?.IsApproved && !viewedBatch?.TransactionsApproved?.split(",").some(t=>t==trx?.ID) && !viewedBatch?.TransactionsRejected.split(",").some(t=>t==trx?.ID)) {
                    actions = {
                      approve: (
                        <ClusterButton
                          permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}
                          type="button"
                          loading={resolveLoading && currentlyResolving == trx.ID}
                          className="btn-rounded btn-outline-success btn-sm waves-effect waves-light"
                          onHandleClick={(e) => { e.preventDefault(); setCurrentlyResolving(trx.ID); onResolve(trx.ID, true); }}
                        >
                          Approve
                        </ClusterButton>
                      ),
                      reject: (
                        <ClusterButton
                          permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}
                          type="button"
                          loading={resolveLoading && currentlyResolving == trx.ID}
                          className="btn-rounded btn-outline-danger btn-sm waves-effect waves-light"
                          onHandleClick={(e) => { e.preventDefault(); setCurrentlyResolving(trx.ID); onResolve(trx.ID, false); }}
                        >
                          Reject
                        </ClusterButton>)
                    }
                  }
                  return {
                    ...acc,
                    ...actions
                  }
                })
              }
            />,
          ]}
        >
          <ClusterButton

            permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}
            type="button"
            className="btn-rounded btn-sm btn-outline-primary waves-effect waves-light"
            onHandleClick={() => downloadReceipt(`${DOWNLOAD_UNSETTLED_TRANSACTIONS_RECEIPTS}/${batchID}`,viewedBatch)}
            loading={editLoading}
          >
            Download Receipts
          </ClusterButton>
          <ClusterButton

            permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}
            type="button"
            className="btn-rounded btn-outline-primary btn-sm waves-effect waves-light"
            onHandleClick={() => downloadTransactions(`${DOWNLOAD_UNSETTLED_TRANSACTIONS}/${batchID}`,viewedBatch)}
            loading={editLoading}
          >
            Download Transactions
          </ClusterButton>
          {!viewedBatch.IsApproved && <React.Fragment><ClusterButton
            permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}

            type="button"
            color="success"
            className="btn-rounded btn-sm waves-effect waves-light"
            onHandleClick={() => onBatchApprove(batchID)}
            loading={batchResolveLoading}
          >
            Approve All
          </ClusterButton>

            <ClusterButton
              permissions={[APPROVE_UNSETTLED_TRANSACTIONS]}

              type="button"
              color="danger"
              className="btn-rounded btn-sm waves-effect waves-light"
              onHandleClick={() => onBatchReject(batchID)}
              loading={batchResolveLoading}
            >
              Reject All
            </ClusterButton>
          </React.Fragment>}
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (<React.Fragment></React.Fragment>)}
    </React.Fragment >
  )
}

ApproveUnsettledTransactionsList.propTypes = {
  unsettledTransactions: PropTypes.array,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  statusChanged: PropTypes.bool,
  editLoading: PropTypes.bool,
  totalCount: PropTypes.number,
  onApprove: PropTypes.func,
  onReject: PropTypes.func,
  onBatchApprove: PropTypes.func,
  onBatchReject: PropTypes.func
}

const mapStateToProps = ({
  unsettledTransactions: { loading, editLoading,
    resolveLoading,
    batchResolveLoading, success, error, saved, unsettledTransactionApprovals, unsettledTransactionApproval },
}) => {

  return {
    editLoading,
    loading,
    success,
    error,
    unsettledTransactionApprovals,
    unsettledTransactionApproval,
    saved,
    resolveLoading,
    batchResolveLoading,
  }
}

const mapDispatchToProps = dispatch => ({

  onPurge: () => dispatch(purgeUnsettledTransactions()),
  onGetTransactions: (payload) => dispatch(getUnsettledTransactions(payload)),
  onGetTransactionDetails: (id) => dispatch(getUnsettledTransactionDetail(id)),
  onApprove: (batchId, transactionId) => dispatch(resolveUnsettledTransaction(batchId, transactionId, true)),
  onReject: (batchId, transactionId) => dispatch(resolveUnsettledTransaction(batchId, transactionId, false)),
  onBatchApprove: (id) => dispatch(batchResolveUnsettledTransaction(id, true)),
  onBatchReject: (id) => dispatch(batchResolveUnsettledTransaction(id, false)),
  onDownloadReceipts: (id) => dispatch(downloadUnsettledTransaction(id)),
  onDownloadTransactions: (id) => dispatch(downloadUnsettledTransactionReceipt(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(ApproveUnsettledTransactionsList))))


const UnsettledTransactionReport = props => {
  if (props.rows.length > 0) {
    return (
      <ClusterDataTable
        columns={props.columns}
        loading={false}
        rows={props.rows}
      />
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}