import { useState,  useEffect } from "react";
import PropTypes from "prop-types";
import { Validators } from "helpers/validators";
import { Utils } from "helpers/utility";
import { InputProps } from "reactstrap";
import { DisposeProps } from "./WithDispose";

 const withInputValidation = (InputComponent: React.FC<InputProps & DisposeProps>) => {
  const Wrapper = (props) => {
  const {validators,validationError,type,required,onError,onBlur,onKeyUp, onChange} =props;
  const [error, setError] = useState(validationError);
   
  const handleOnBlur = e => {
    debounceValidate(e,  0);
    if(onBlur) onBlur(e);
  }
  const debounceValidate = (e, timeout = 500) => {
    if (required == true && validationError == undefined) {
      const msg = Validators.required("Please enter a value")(e);
      setError(msg ?? "")
      if (onError) onError(msg ?? "")
    }
    if (!Utils.List.isEmpty(validators)) {
      setTimeout(() => {
        const err = validate(e)
        setError(err)
        if (onError) onError(err)
      }, timeout)
    }
  }
  const handleOnKeyUp = (e) => {
    debounceValidate(e)
    if(onKeyUp) onKeyUp(e);
  }

    useEffect(() => {
    setError(validationError)
  }, [validationError])



  const handleOnChange = ( e:any) => {
    debounceValidate(e)
    if(onChange) onChange(e);
  }

  const validate = e => {
    const msgs = []
    if (!required && (!e.target.value || e.target.value == 0)) {
      setError(undefined)
      return "";
    }
    if (type === "number") validators.push(Validators.amount())
    validators?.forEach(validator => {
      const msg = validator(e)
      msgs.push(msg)
      if (msg && !error) {
        setError(msg)
        if (onError) onError(msg)
      } else {
        if (!msg) {
          setError(undefined)
        }
      }
    })
    if (msgs.every(msg => msg == undefined)) {
      return ""
    } else {
      let msg = msgs.find(msg => msg != undefined)
      return msg
    }
  }
    return <>
              <InputComponent 
                {...props} 
                error={error} 
                validators ={validators}
                onBlur = {handleOnBlur}
                onKeyUp = {handleOnKeyUp}
                onChange = {handleOnChange}
                onMouseDown={handleOnKeyUp}
                />
                {error && (
                  <div className="invalid-feedback" style={{ display: "block" }}>
                    {error}
                  </div>
                )}
            </>;
  };
  Wrapper.propTypes = {
  type: PropTypes.any,
  style: PropTypes.object,
  required: PropTypes.bool,
  validators: PropTypes.array,
  onError: PropTypes.func,
  onChange:PropTypes.func,
  onBlur:PropTypes.func,
  onKeyUp:PropTypes.func,
  onMouseDown:PropTypes.func,
  };
  return Wrapper;
};
export default withInputValidation;

