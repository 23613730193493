import {
  call,
  put,
  takeEvery,
  takeLeading,
  takeLatest,
} from "redux-saga/effects"
import moment from "moment"

// Crypto Redux States
import {
  GET_CLUSTERMANAGERS,
  GET_CLUSTERMANAGER_DETAIL,
  CLUSTERMANAGER_PURGE,
  ENABLE_CLUSTERMANAGER,
  CREATE_CLUSTERMANAGER,
  UPDATE_CLUSTERMANAGER,
  GET_ALL_CLUSTERMANAGERS,
} from "./actionTypes"
import {
  getClusterManagersSuccess,
  getClusterManagersFail,
  getClusterManagerDetailSuccess,
  getClusterManagerDetailFail,
  clusterManagerPurgeSuccess,
  updateClusterManagerSuccess,
  postClusterManagerSuccess,
  clusterManagerApiError,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getClusterManagers,
  getClusterManagerDetail,
  putClusterManager,
  postClusterManager,
  enableClusterManager,
  disableClusterManager,
  getAllClusterManagers,
} from "helpers/backend_helper"
import { enableClusterManagerSuccess } from "store/actions"
const format = "yyyy-MM-DD"
function* fetchClusterManagers({ params }) {
  try {
    const response = yield call(getClusterManagers, JSON.parse(params))
    yield put(getClusterManagersSuccess(response))
  } catch (error) {
    console.debug("Fetching ClusterManagers Error", error)
    yield put(getClusterManagersFail(error))
  }
}
function* fetchAllClusterManagers() {
  try {
    const response = yield call(getAllClusterManagers)
    yield put(getClusterManagersSuccess(response))
  } catch (error) {
    console.debug("Fetching ClusterManagers Error", error)
    yield put(getClusterManagersFail(error))
  }
}

function* fetchClusterManagerDetail({ id }) {
  try {
    const response = yield call(getClusterManagerDetail, id)
    yield put(getClusterManagerDetailSuccess(response))
  } catch (error) {
    yield put(getClusterManagerDetailFail(error))
  }
}

function* createClusterManager({ payload: { clusterManager } }) {
  try {
    const response = yield call(postClusterManager, {
      ...JSON.parse(JSON.stringify(clusterManager)),
    })
    yield put(postClusterManagerSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      clusterManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* updateClusterManager({ payload: { clusterManager } }) {
  try {
    const response = yield call(putClusterManager, {
      ...JSON.parse(JSON.stringify(clusterManager)),
    })

    yield put(updateClusterManagerSuccess(response))
  } catch (error) {
    console.debug(error)
    yield put(
      clusterManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}

function* doEnableClusterManager(payload) {
  try {
    let toEnable = true
    let cta = enableClusterManager
    if (!payload.enable) {
      cta = disableClusterManager
      toEnable = false
    }
    const response = yield call(cta, payload.id)

    yield put(enableClusterManagerSuccess({ ...response, enable: toEnable }))
  } catch (error) {
    console.debug(error)
    yield put(
      clusterManagerApiError(
        error?.data ?? "Sorry an error occurred, Kindly retry"
      )
    )
  }
}
function* doPurge() {
  yield put(clusterManagerPurgeSuccess())
}

function* clusterManagersSaga() {
  yield takeLeading(GET_CLUSTERMANAGERS, fetchClusterManagers)
  yield takeLeading(GET_ALL_CLUSTERMANAGERS, fetchAllClusterManagers)
  yield takeEvery(GET_CLUSTERMANAGER_DETAIL, fetchClusterManagerDetail)
  yield takeEvery(CLUSTERMANAGER_PURGE, doPurge)
  yield takeLeading(ENABLE_CLUSTERMANAGER, doEnableClusterManager)
  yield takeLeading(UPDATE_CLUSTERMANAGER, updateClusterManager)
  yield takeLeading(CREATE_CLUSTERMANAGER, createClusterManager)
}

export default clusterManagersSaga
