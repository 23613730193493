import { defaultObj } from "helpers/utility"
import {
  GET_USERS,
  API_ERROR,
  POST_USER_ERROR,
  GET_USER_DETAILS_SUCCESS,
  USER_DETAILS_ERROR,
  GET_USER_DETAILS,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  PURGE_USERS_SUCCESS,
  POST_SUCCESS,
  ENABLE_USER,
  ENABLE_USER_SUCCESS,
  GET_USERS_SUCCESS,
  CREATE_USER,
} from "./actionTypes"

const initialState = {
  error: "",
  users: [],
  user: {},
  totalCount: 0,
  loading: true,
  success: "",
  editLoading: false,
  statusChanged: undefined,
}

const Users = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        loading: true,
      }

    case GET_USER_DETAILS:
      return {
        ...state,
        loading: true,
      }

    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload?.collection||action.payload,
        totalCount: action.payload?.totalCount,
        loading: false,
      }
    case CREATE_USER:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }
    case POST_SUCCESS:
      return {
        ...state,
        user: defaultObj(state.user), //action.payload,
        error: "",
        success: action.payload?.message,
        editLoading: false,
      }

    case API_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
        success:undefined
      }

    case POST_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        editLoading: false,
      }

    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      }

    case USER_DETAILS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case UPDATE_USER:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }

    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        success: action.payload?.message,
        editLoading: false,
        error: "",
      }
    case PURGE_USERS_SUCCESS:
      return {
        ...state,
        user: {},
        error: "",
        success: undefined,
        loading: true,
      }
    case ENABLE_USER:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
      }

    case ENABLE_USER_SUCCESS:
      let detail = state.user
      let users = state.users

      if (detail) {
        detail.status = action.payload ? "Active" : "Inactive"
        users.map(c => {
          if (c.id == detail.id) {
            c.status = detail.status
          }
          return c
        })
      }
      return {
        ...state,
        users,
        user: detail,
        editLoading: false,
        loading: false,
        error: "",
        success:action.payload?"User activated successfully":"User deactivated successfully",
        statusChanged: action.payload,
      }
    default:
      state = { ...state }
      break
  }
  return state
}

export default Users
