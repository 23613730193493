import {
  GET_AUDIT_TRAILS,
  GET_AUDIT_TRAIL_DETAIL,
  GET_AUDIT_TRAIL_DETAIL_SUCCESS,
  GET_AUDIT_TRAIL_DETAIL_FAIL,
  GET_AUDIT_TRAILS_SUCCESS,
  GET_AUDIT_TRAILS_FAIL,
  GET_ALL_AUDIT_TRAILS,
  CREATE_AUDIT_TRAIL,
  POST_AUDIT_TRAIL_SUCCESS,
  AUDIT_TRAIL_PURGE,
  AUDIT_TRAIL_PURGE_SUCCESS,
  POST_AUDIT_TRAIL_FAIL,
  UPDATE_AUDIT_TRAIL,
  UPDATE_AUDIT_TRAIL_SUCCESS,
  UPDATE_AUDIT_TRAIL_FAIL,
  ENABLE_AUDIT_TRAIL,
  ENABLE_AUDIT_TRAIL_SUCCESS
} from "./actionTypes"

export const getAuditTrails = (params) => ({
  type: GET_AUDIT_TRAILS,
  params
})
export const getAllAuditTrails = () => ({
  type: GET_ALL_AUDIT_TRAILS
})
export const getAuditTrailsSuccess = auditTrails => ({
  type: GET_AUDIT_TRAILS_SUCCESS,
  payload: auditTrails,
})
export const getAuditTrailsFail = error => ({
  type: GET_AUDIT_TRAILS_FAIL,
  payload: error,
})
export const getAuditTrailDetail = id => ({
  type: GET_AUDIT_TRAIL_DETAIL,
  id,
})

export const getAuditTrailDetailSuccess = auditTrails => ({
  type: GET_AUDIT_TRAIL_DETAIL_SUCCESS,
  payload: auditTrails,
})

export const getAuditTrailDetailFail = error => ({
  type: GET_AUDIT_TRAIL_DETAIL_FAIL,
  payload: error,
})


export const createAuditTrail = (auditTrail) => {
  return {
    type: CREATE_AUDIT_TRAIL,
    payload: { auditTrail },
  }
}

export const auditTrailApiError = error => {
  return {
    type: POST_AUDIT_TRAIL_FAIL,
    payload: error,
  }
}

export const postAuditTrailSuccess = auditTrail => {
  return {
    type: POST_AUDIT_TRAIL_SUCCESS,
    payload: auditTrail,
  }
}

export const auditTrailPurge = () => ({
  type: AUDIT_TRAIL_PURGE
})

export const auditTrailPurgeSuccess = () => ({
  type: AUDIT_TRAIL_PURGE_SUCCESS
})


export const enableAuditTrail = (id, enable) => {
  return {
    type: ENABLE_AUDIT_TRAIL,
    id, enable
  }
}

export const enableAuditTrailSuccess = auditTrail => {
  return {
    type: ENABLE_AUDIT_TRAIL_SUCCESS,
    payload: auditTrail,
  }
}

export const updateAuditTrail = (auditTrail) => {
  return {
    type: UPDATE_AUDIT_TRAIL,
    payload: { auditTrail },
  }
}
export const updateAuditTrailSuccess = auditTrail => {
  return {
    type: UPDATE_AUDIT_TRAIL_SUCCESS,
    payload: auditTrail,
  }
}
export const purgeAuditTrails = () => {
  return {
    type: PURGE_AUDIT_TRAILS
  }
}

export const purgeAuditTrailSuccess = () => {
  return {
    type: PURGE_AUDIT_TRAILS_SUCCESS
  }
}