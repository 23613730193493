import { Format } from "helpers/variables"
import moment from "moment"
import React, { useState } from "react"
import { Row, Col, Card, CardBody, Media, Button } from "reactstrap"

//Simple bar
import SimpleBar from "simplebar-react"
import ClusterButton from "./Button/Button"

const ClusterChatBox = props => {
  const { messages, onSend } = props
  const [message, setMessage] = useState<string>()

  const handleOnChange = value => {
    setMessage(value)
  }
  if (!messages)
    return (
      <Col lg={12}>
        <h3 style={{ textAlign: "center" }}>No messages yet</h3>
      </Col>
    )
  return (
    <React.Fragment>
      <Col lg={12}>
        <Card>
          <CardBody className="pb-0">
            <div>
              <div className="chat-conversation">
                <SimpleBar style={{ height: "60vh" }}>
                  <ul className="list-unstyled">
                    {messages.map((message, key) => (
                      <li key={key} className={!message.IsAgent ? "right" : ""}>
                        <div className="conversation-list">
                          {/* <UncontrolledDropdown direction={!message.IsAgent ? "right" : "left"}>
                          <DropdownToggle tag="i">
                            <i className="bx bx-dots-vertical-rounded" />
                          </DropdownToggle>
                          <DropdownMenu direction={!message.IsAgent ? "left" : "right"}>
                            <DropdownItem href="#">Copy</DropdownItem>
                            <DropdownItem href="#">Save</DropdownItem>
                            <DropdownItem href="#">Forward</DropdownItem>
                            <DropdownItem href="#">Delete</DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown> */}
                          <div className="ctext-wrap">
                            <div className="conversation-name">
                              {message.Name}
                            </div>
                            {message.IsAttachment ? (
                              <Media src={message.Message} />
                            ) : (
                              <p>{message.Message}</p>
                            )}
                            <p
                              className="chat-time mb-0"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <i className="bx bx-time-five align-middle mr-1" />{" "}
                              {moment(message.DateLogged).isBetween(
                                moment().startOf("day"),
                                moment().endOf("day")
                              )
                                ? Format.date(message.DateLogged, "time")
                                : Format.date(message.DateLogged)}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </SimpleBar>
              </div>
            </div>
          </CardBody>

          <div className="p-3 chat-input-section">
            <Row>
              <Col>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control rounded chat-input"
                    placeholder="Enter Message..."
                    value={message}
                    onChange={e => handleOnChange(e.target.value)}
                  />
                </div>
              </Col>
              <div className="col-auto">
                <Button
                  type="submit"
                  color="primary"
                  className="chat-send w-md waves-effect waves-light"
                  onClick={e => {
                    e.preventDefault()
                    if (onSend) onSend(message)
                    setMessage("")
                  }}
                >
                  <span className="d-none d-sm-inline-block mr-2">Send</span>{" "}
                  <i className="mdi mdi-send" />
                </Button>
              </div>
              {props.children}
            </Row>
          </div>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default ClusterChatBox
