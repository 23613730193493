import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { connect, useSelector } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap"

import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.svg"
import cluster from "../../assets/images/logo/dark/36x36.png"
import { Validators } from "helpers/validators"
import Loading from "components/Common/Loading"
import ClusterDropdown from "components/Common/DropDown/Dropdown";
import ClusterButton from "components/Common/Button/Button"
import useSWR from "swr"

// action
import { getMenu, getFunctionsByUser } from "../../store/actions"

import { get } from "helpers/api_helper"
import withDispose from "components/HOCs/WithDispose"
import { Icons } from "enums/icons"
import { useRemoting } from "hooks/useRemoting"

const fetcher = (url) => get(url, {}).then(res => res)
const RemoteModal: React.FC<any> = props => {
  let { isOpen, setModal, history } = props
  const [errorList, setErrorList] = useState({
    institutionId: undefined,
  })
  const [myError, setMyError] = useState()
  const [details, setDetails] = useState({})
  const url = "/api/v1/Institutions"

  const { data } = useSWR(url, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    refreshInterval: 300000,
  })
  const { loading, handleSubmit, menuLoading } = useRemoting(history);


  const handleValidSubmit = event => {
    event.preventDefault();
    handleSubmit(details["institutionId"]);
  }
  useEffect(() => {
    setMyError(Validators.validateForm(errorList))
  }, [errorList])

  const handleOnChange = (key, value) => {
    let u = details
    u[key] = value
    setDetails(u)
  }
  const toggleOpen = () => {
    setModal(!isOpen)
  }
  return (
    <Modal
      isOpen={isOpen}
      role="dialog"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      toggle={() => {
        props.history.goBack();
      }}
    >
      <div className="modal-content">
        <ModalHeader
          toggle={() => {
            props.history.goBack();
          }}
        >
          Remote To Institution
        </ModalHeader>
        <ModalBody>
          <Row className="justify-content-center">
            <Col md={12}>
              <Card >
                <div className="bg-soft-primary">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Select an institution to remote to</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <div className="avatar-title rounded-circle bg-light p-2">
                          <img
                            src={cluster}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {menuLoading == true ? (
                      <Loading>Almost there...</Loading>
                    ) : (
                      <></>
                    )}

                    <form
                      className="form-horizontal mt-4"
                      noValidate
                      onSubmit={e => handleValidSubmit(e)}
                    >
                      {!data ? (
                        <Loading></Loading>
                      ) : (
                        <ClusterDropdown
                          placeholder={"- Select an Institution - "}
                          items={
                            !Array.isArray(data)
                              ? []
                              : data?.map(r => ({
                                name: r.shortName,
                                value: r.shortName,
                                id: r.id,
                                key: r.id,
                              }))
                          }
                          name={"institutionId"}
                          onChange={(name, value) => {
                            handleOnChange("institutionId", value)
                            setErrorList({ ...errorList, institutionId: "" })
                          }}
                        />
                      )}

                      <Row className="form-group">
                        <Col className="text-right">
                          <ClusterButton
                            loading={loading}
                            disabled={myError != "" ? true : false}
                            color={"primary"}
                            addon={`bx ${Icons.Select}`}
                            className={"full-width"}
                          >
                            Select
                          </ClusterButton>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>

      </div>
    </Modal>
  )
}
RemoteModal.propTypes = {
  history: PropTypes.object,
  loading: PropTypes.bool,
  getMenu: PropTypes.func,
  menuLoading: PropTypes.bool,
  menus: PropTypes.array,
  getFunctionsByUser: PropTypes.func,
  isOpen: PropTypes.bool,
  setModal: PropTypes.func,
}

const mapStatetoProps = state => {
  const { menus } = state.permissions
  return { menus }
}

export default withRouter(
  connect(mapStatetoProps, { getMenu, getFunctionsByUser })(
    withDispose(RemoteModal)
  )
) as any
