import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col, Button } from "reactstrap"

// Pages Components
import Report from "../../ReportManagement/report"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import {
  mobileAccountPurge,
  getMobileAccounts,
  getMobileAccountByPhoneNumber,
  resetPin,
  resetPinTries,
  generateVerificationCode,
  enableMobileAccount,
  sendActivationCode,
  resetPassword,
  resetPasswordTries,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import { GET_ALL_MOBILEACCOUNTS } from "helpers/url_helper"
import MobileAccountLogic from "../mobileaccount"
import withDispose from "components/HOCs/WithDispose"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import ClusterDataTable from "components/Common/DataTable"
import Actions from "../actions"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"

const MobileAccountList = props => {
  const {
    mobileAccounts,
    onGetMobileAccounts,
    error,
    success,
    onGetMobileAccountDetail,
    mobileAccountDetail,
    onPurge,
    totalCount,
    loading,
    statusChanged,
    onEnableMobileAccount,
    editLoading,
    onResetPin,
    onResetPinTries,
    onResetPassword,
    onResetPasswordTries,
    onHotList,
    onVerification,
    onActivation,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    verificationLoading,
    activationLoading,
  } = props
  const {
    MapToRows,
    MapToRowsBankAccount,
    BANKACCOUNTHEADERS,
    GetAccountStatus,
    SearchCriteria,
    HEADERS,
    SearchOptions,
    MapToModalState,
    GetAccountStatusBadge,
  } = MobileAccountLogic
     const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetMobileAccounts, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [isEnabled, setIsEnabled] = useState(false)

    const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(params?.fromDate, params?.toDate, institutionsData)
  )
    useEffect(() => {
      setSearchOptions(
        SearchOptions(params?.fromDate, params?.toDate, institutionsData)
      )
    }, [institutions])
  const [viewedMobileAccount, setViewedMobileAccount] = useState()

  useEffect(() => {
    if (success) toast.success(success)
    if (error) toast.error(error)
  }, [error, success])
  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {
    if (mobileAccountDetail && Object.keys(mobileAccountDetail).length > 0) {
      setmodal(true)
      setViewedMobileAccount(mobileAccountDetail)
      setModalData(MapToModalState(mobileAccountDetail))
    }
  }, [mobileAccountDetail])

  useEffect(() => {
    if (error) toast.error(error)
  }, [error])
  const populateRows = () => {
    let rws = []
    if (mobileAccounts) {
      mobileAccounts.forEach(mobileAccount => {
        let row = MapToRows(mobileAccount)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => onViewDetails(mobileAccount.MobilePhone)}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }

  function onViewDetails(id) {
    setmodal(true)
    onGetMobileAccountDetail(id)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={"Mobile Account"}
            breadcrumbItem={"View Mobile Accounts"}
          />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_MOBILEACCOUNTS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>
      {modal ? (
        <ClusterModal
          badgeClass={GetAccountStatusBadge(
            GetAccountStatus(viewedMobileAccount)
          )}
          status={GetAccountStatus(viewedMobileAccount)}
          open={modal}
          title={"Mobile Account Details"}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          success={success}
          error={error}
          append={[
            <BankAccountReport
              key={1}
              columns={BANKACCOUNTHEADERS}
              rows={
                ((viewedMobileAccount as any)?.BankAccounts??[]).length == 0
                  ? []
                  : (viewedMobileAccount as any)?.BankAccounts.map(account => {
                      let acc = MapToRowsBankAccount(account)
                      return {
                        ...acc,
                        gender: (viewedMobileAccount as any)?.TheGender,
                        product: (viewedMobileAccount as any)?.ProductName,
                      }
                    }) || []
              }
            />,
          ]}
        >
          <Actions
            account={viewedMobileAccount}
            editLoading={editLoading}
            onGetMobileAccountDetail={onGetMobileAccountDetail}
            onEnableMobileAccount={onEnableMobileAccount}
            onResetPin={onResetPin}
            onResetPinTries={onResetPinTries}
            onResetPassword={onResetPassword}
            onResetPasswordTries={onResetPasswordTries}
            onVerification={onVerification}
            onActivation={onActivation}
            resetPinLoading={resetPinLoading}
            resetPinTriesLoading={resetPinTriesLoading}
            resetPasswordLoading={resetPasswordLoading}
            resetPasswordTriesLoading={resetPasswordTriesLoading}
            verificationLoading={verificationLoading}
            activationLoading={activationLoading}
            isEnabled={isEnabled}
          />
          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
    </React.Fragment>
  )
}

MobileAccountList.propTypes = {
  mobileAccounts: PropTypes.array,
  mobileAccountDetail: PropTypes.object,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetMobileAccounts: PropTypes.func,
  onGetMobileAccountDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  onEnableMobileAccount: PropTypes.func,
  onResetPin: PropTypes.func,
  onResetPinTries: PropTypes.func,
  onResetPassword: PropTypes.func,
  onResetPasswordTries: PropTypes.func,
  onHotList: PropTypes.func,
  onVerification: PropTypes.func,
  onActivation: PropTypes.func,
}

const mapStateToProps = ({
  mobileAccounts: {
    mobileAccounts,
    error,
    success,
    mobileAccountDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    resetPinLoading,
    resetPinTriesLoading,
    verificationLoading,
    activationLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
  },
}) => {
  return {
    mobileAccounts,
    error,
    mobileAccountDetail,
    totalCount,
    loading,
    editLoading,
    statusChanged,
    success,
    resetPinLoading,
    resetPinTriesLoading,
    resetPasswordLoading,
    resetPasswordTriesLoading,
    verificationLoading,
    activationLoading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetMobileAccounts: query => dispatch(getMobileAccounts(query)),
  onGetMobileAccountDetail: phoneNumber =>
    dispatch(getMobileAccountByPhoneNumber(phoneNumber)),
  onPurge: () => dispatch(mobileAccountPurge()),
  onEnableMobileAccount: (id, enable) =>
    dispatch(enableMobileAccount(id, enable)),
  onResetPin: id => dispatch(resetPin(id)),
  onResetPinTries: id => dispatch(resetPinTries(id)),
  onVerification: id => dispatch(generateVerificationCode(id)),
  onActivation: id => dispatch(sendActivationCode(id)),
  onResetPassword: id => dispatch(resetPassword(id)),
  onResetPasswordTries: id => dispatch(resetPasswordTries(id)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(MobileAccountList))))

const BankAccountReport = props => {
  if (props.rows.length > 0) {
    return (
      <ClusterDataTable
        columns={props.columns}
        loading={false}
        rows={props.rows}
      ></ClusterDataTable>
    )
  } else {
    return <React.Fragment></React.Fragment>
  }
}
