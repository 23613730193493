import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import { Container, Row, Col, Button, ModalBody, Modal, ModalHeader, Spinner } from "reactstrap"
import Report from "../../ReportManagement/report"
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import {
  getAgents,
  getAgentDetail,
  agentPurge,
  deviceReset,
  resetLimit,
  linkToPOSTerminal,
  upgradeToSuperAgent,
  enableAgent,
  unlinkParentAgent,
  createPOSAccounts,
} from "store/actions"
import ClusterModal from "components/Common/Modals/Modal"
import {
  GET_ALL_AGENTS,
  GET_ALL_CATEGORIES,
  GET_ALL_CLUSTERS,
  GET_ALL_NETWORK_MANAGERS,
} from "helpers/url_helper"
import AgentLogic from "../agent"
import {
  DEVICE_RESET,
  EDIT_AGENT,
  ENABLE_AGENTS,
  GENERATE_POS_ACCOUNT,
  LINK_TO_POS,
  RESET_AGENT_LIMIT,
  UPGRADE_TO_SUPER_AGENT,
} from "../permission"
import withDispose from "components/HOCs/WithDispose"
import LinkToPOSModal from "./link-to-pos-modal"
import UpgradeToSuperAgentModal from "./upgrade-to-super-agent-modal"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { InstitutionsHelper } from "helpers/institutions"
import UnlinkParentAgentModal from "./unlink-parent-agent-modal"
import ClusterButton from "components/Common/Button/Button"
import useReportData from "hooks/UseReportData"
import { useInstitutionsData } from "hooks/useInstitutionData"
import { GetInstitution } from "helpers/authentication/institution"
import CreatePOSAccountModal from "./create-pos-account-modal"
import Loading from "components/Common/Loading"

const AgentList = props => {
  const {
    agents,
    onGetAgents,
    error,
    success,
    onGetAgentDetail,
    agentDetail,
    totalCount,
    loading,
    modalLoading,
    onEnableAgent,
    editLoading,
    onDeviceReset,
    onResetLimit,
    onCreatePOSAccount,
    onLinkToPos,
    onUpgrade,
    resetLimitLoading,
    deviceResetLoading,
    createPOSAccountLoading,
    linkLoading,
    upgradeLoading
  } = props
  const { MapToRows, SearchCriteria, HEADERS, SearchOptions, MapToModalState } =
    AgentLogic
  const {
    columns, rows, handlePageChange, pageNumber, maxSize, params, setRows, search, setPageNumber, setParams
  } = useReportData({ onGetData: onGetAgents, HEADERS: HEADERS, SearchCriteria: SearchCriteria })

  const [modal, setmodal] = useState(false)
  const [linkModal, setLinkModal] = useState(false)
  const [unlinkParentModal, setUnlinkParentModal] = useState(false)
  const [upgradeModal, setUpgradeModal] = useState(false)
  const [createPOSAccModal, setCreatePOSAccModal] = useState(false)
  const [modalData, setModalData] = useState([])
  const [upgradeErrorList, setUpgradeErrorList] = useState({
    transactionAccount: undefined,
    incomeAccount: undefined,
    agencyName: undefined,
  })
  const [linkErrorList, setLinkErrorList] = useState({ terminalID: undefined })
  const [clusterId, setClusterId] = useState()
  const categories = useSWR(GET_ALL_CATEGORIES, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const clusters = useSWR(GET_ALL_CLUSTERS, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })
  const { institutionsData, institutions } = useInstitutionsData()

  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(
      params?.fromDate,
      params?.toDate,
      (categories?.data as any)?.Data,
      (networkManagers?.data as any)?.Data,
      (clusters?.data as any)?.Data,
      institutionsData
    )
  )
  const [viewedAgent, setViewedAgent] = useState<any>()
  useEffect(() => {
    setSearchOptions(
      SearchOptions(
        params?.fromDate,
        params?.toDate,
        (categories?.data as any)?.Data,
        (networkManagers?.data as any)?.Data,
        (clusters?.data as any)?.Data,
        institutionsData
      )
    )
  }, [categories, networkManagers, clusters, institutions])

  useEffect(() => {
    if (!upgradeModal && !linkModal) {
      //forms messages automatically get notified so this is to prevent double notification
      if (success) toast.success(success)
      if (error) toast.error(error)
    }
  }, [error, success])

  useEffect(() => {
    populateRows()
  }, [props])

  useEffect(() => {
    if (agentDetail && Object.keys(agentDetail).length > 0) {
      setmodal(true)
      setViewedAgent(agentDetail)
      setModalData(MapToModalState(agentDetail))
    }
  }, [agentDetail])

  const populateRows = () => {
    let rws = []
    if (agents) {
      agents.forEach(agent => {
        let row = MapToRows(agent)
        row["action"] = (
          <Button
            type="button"
            color="primary"
            size="sm"
            className="btn-rounded waves-effect waves-light"
            onClick={() => {
              onViewDetails(agent.ID)
            }}
          >
            View Details
          </Button>
        )
        rws.push(row)
      })
    }
    setRows(rws)
  }

  const onAction = p => {
    if (p["page"]) {
      setPageNumber(+p["page"])
    } else {
      p["page"] = pageNumber
    }
    setClusterId(p["clusterId"])
    let prms = { fromDate: params["fromDate"], toDate: params["toDate"], ...p }
    setParams(prms)
    search(SearchCriteria(prms, maxSize))
  }

  function onViewDetails(id) {
    onGetAgentDetail(id)
  }

  const unlinkAgent = {
    id: agentDetail?.ID,
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Agents"} breadcrumbItem={"View Agents"} />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_ALL_AGENTS,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
        </Container>
      </div>

      {modalLoading ? 
      <Modal
        isOpen={true}
        role="dialog"
        autoFocus={true}
        centered={true}
        contentClassName="border-0 bg-transparent"
        modalClassName="modal-backdrop-custom"
      >
          <div className="d-flex justify-content-center align-items-center" style={{ height: '100px' }}>
            <Spinner style={{ width: '3rem', height: '3rem' }} />
          </div>
        </Modal>
       : <></> }
       
       
       {modal ? (
        <ClusterModal
          badgeClass={viewedAgent?.IsActive ? "success" : "secondary"}
          status={viewedAgent?.IsActive ? "Active" : "Inactive"}
          open={modal}
          dataSet={modalData}
          onChangeModal={s => setmodal(s)}
          isBadge
          title={"Agent Details"}
        >
          {viewedAgent?.PayWithTransfer?.IsEligible && GetInstitution().code != '*' &&
            <ClusterButton
              permissions={[GENERATE_POS_ACCOUNT]}
              type="button"
              outline
              onHandleClick={() => { setCreatePOSAccModal(true) }}
              color="secondary"
            >
              Generate POS Account
            </ClusterButton>
          }

          <ClusterButton
            loading={resetLimitLoading}
            permissions={[RESET_AGENT_LIMIT]}
            type="button"
            outline
            onHandleClick={() => onResetLimit(viewedAgent?.ID)}
            color="secondary"
          >
            Reset Limit
          </ClusterButton>
          <ClusterButton
            loading={deviceResetLoading}
            permissions={[DEVICE_RESET]}
            type="button"
            outline
            onHandleClick={() => onDeviceReset(viewedAgent?.ID)}
            color="secondary"
          >
            Device Reset
          </ClusterButton>
          <ClusterButton
            loading={editLoading}
            permissions={[ENABLE_AGENTS]}
            type="button"
            outline
            onHandleClick={() =>
              onEnableAgent(viewedAgent?.ID, !viewedAgent?.IsActive)
            }
            color="secondary"
          >
            {viewedAgent?.IsActive ? "Disable" : "Enable"}
          </ClusterButton>
          <Link to={"/create-agent/" + viewedAgent?.ID}>
            <ClusterButton
              permissions={[EDIT_AGENT]}
              type="button"
              color="primary"
            >
              Edit
            </ClusterButton>
          </Link>
          {(InstitutionsHelper.Validator.isConsolidated ||
            !InstitutionsHelper.Validator.isAccessNg) &&
            !viewedAgent?.IsSubAgent && !viewedAgent?.IsSuperAgent ? (
            <ClusterButton
              loading={upgradeLoading}
              permissions={[UPGRADE_TO_SUPER_AGENT]}
              type="button"
              outline
              onHandleClick={() => setUpgradeModal(true)}
              color="warning"
            >
              Upgrade to Super Agent
            </ClusterButton>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          <ClusterButton
            loading={linkLoading}
            permissions={[LINK_TO_POS]}
            type="button"
            outline
            onHandleClick={() => {
              if (!viewedAgent?.TerminalID) {
                setLinkModal(true)
              } else onLinkToPos({ id: viewedAgent?.ID, link: false })
            }}
            color="pink"
          >
            {!viewedAgent?.TerminalID ? "Link Terminal" : "Unlink Terminal"}
          </ClusterButton>
          {viewedAgent?.IsSubAgent ? (
            <Button
              type="button"
              outline
              onClick={() => {
                setUnlinkParentModal(true)
              }}
              color="pink"
            >
              Unlink Parent Agent
            </Button>
          ) : null}

          <Button
            type="button"
            color="secondary"
            onClick={() => {
              setmodal(!modal)
            }}
          >
            Close
          </Button>
        </ClusterModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {linkModal ? (
        <LinkToPOSModal
          agent={viewedAgent}
          error={error}
          success={success}
          loading={linkLoading}
          onLinkToPos={onLinkToPos}
          errorList={linkErrorList}
          setErrorList={setLinkErrorList}
          open={linkModal}
          onChangeModal={s => setLinkModal(s)}
          redirectUrl={"/view-agents"}
        ></LinkToPOSModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}

      {unlinkParentModal && (
        <UnlinkParentAgentModal
          agent={viewedAgent}
          error={error}
          success={success}
          loading={linkLoading}
          unlinkAgent={unlinkAgent}
          open={unlinkParentModal}
          onChangeModal={s => setUnlinkParentModal(s)}
          redirectUrl={"/view-agents"}
        />
      )}

      {upgradeModal ? (
        <UpgradeToSuperAgentModal
          agent={viewedAgent}
          loading={upgradeLoading}
          error={error}
          success={success}
          errorList={upgradeErrorList}
          setErrorList={setUpgradeErrorList}
          onUpgrade={onUpgrade}
          open={upgradeModal}
          onChangeModal={s => setUpgradeModal(s)}
          redirectUrl={"/view-agents"}
        ></UpgradeToSuperAgentModal>
      ) : (
        <React.Fragment></React.Fragment>
      )}
      {createPOSAccModal && (
        <CreatePOSAccountModal
          agent={viewedAgent}
          loading={createPOSAccountLoading}
          error={error}
          success={success}
          open={createPOSAccModal}
          redirectUrl={"/view-agents"}
          onChangeModal={s => setCreatePOSAccModal(s)}
          onCreatePOSAccount={onCreatePOSAccount}
        />)
      }
    </React.Fragment>
  )
}

AgentList.propTypes = {
  agents: PropTypes.array,
  agentDetail: PropTypes.object,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetAgents: PropTypes.func,
  onGetAgentDetail: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
  statusChanged: PropTypes.bool,
  onEnableAgent: PropTypes.func,
  onUnlinkParentAgent: PropTypes.func,
  onDeviceReset: PropTypes.func,
  onResetLimit: PropTypes.func,
  onLinkToPos: PropTypes.func,
  onUpgrade: PropTypes.func,
  onCreatePOSAccount: PropTypes.func
}

const mapStateToProps = ({
  agents: {
    agents,
    error,
    success,
    agentDetail,
    totalCount,
    loading,
    modalLoading,
    editLoading,
    statusChanged,
    resetLimitLoading,
    deviceResetLoading,
    linkLoading,
    upgradeLoading,
    createPOSAccountLoading
  },
}) => {
  return {
    agents,
    error,
    success,
    agentDetail,
    totalCount,
    loading,
    modalLoading,
    editLoading,
    statusChanged,
    resetLimitLoading,
    deviceResetLoading,
    linkLoading,
    upgradeLoading,
    createPOSAccountLoading
  }
}

const mapDispatchToProps = dispatch => ({
  onGetAgents: query => dispatch(getAgents(query)),
  onGetAgentDetail: id => dispatch(getAgentDetail(id)),
  onPurge: () => dispatch(agentPurge()),
  onEnableAgent: (id, enable) => dispatch(enableAgent(id, enable)),
  onUnlinkParentAgent: payload => dispatch(unlinkParentAgent(payload)),
  onDeviceReset: id => dispatch(deviceReset(id)),
  onResetLimit: id => dispatch(resetLimit(id)),
  onLinkToPos: payload => dispatch(linkToPOSTerminal(payload)),
  onUpgrade: payload => dispatch(upgradeToSuperAgent(payload)),
  onCreatePOSAccount: (payload, id) => dispatch(createPOSAccounts(payload, id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(AgentList))))
