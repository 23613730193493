import React, { ReactChild, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col } from "reactstrap"

type PaginationProps = {
  totalCount: number,
  maxSize: number,
  pageNumber: number,
  children: ReactChild|ReactChild[]
  setPageNumber: (value: number) => void
}
type PaginationItem={
    value: number,
    active: boolean
}
const Pagination:React.FC<PaginationProps> = props => {
  const [pagesList, setPagesList] = useState<PaginationItem[]>([])
  let { totalCount, maxSize, pageNumber, setPageNumber } = props
  let pages = Math.ceil(totalCount / maxSize)
  const [from, setFrom] = useState(0)
  useEffect(() => {
    let list = []
    for (let i = 1; i <= pages; i++) {
      list.push({
        value: i,
        active: i == pageNumber ? true : false,
      })
    }
    setPagesList(list)
  }, [props])

  useEffect(() => {
    setFrom(totalCount == 0 ? 0 : (pageNumber - 1) * maxSize + 1)
  }, [pageNumber, maxSize, totalCount])

  const prev = () => {
    let page = (pageNumber -= 1)
    if (page >= 1) {
      goTo(page)
      return
    }
  }
  const next = () => {
    let page = (pageNumber += 1)
    if (page <= pages) {
      goTo(page)
      return
    }
  }
  const goTo = page => {
    setPageNumber(page)
  }
  return (
    <Row style={{ marginTop: "20px" }}>
      <div className="col-md-2">{props.children}</div>
      <div data-test="datatable-info" className="col-sm-12 col-md-3">
        {" "}
        {totalCount == 0 || totalCount == undefined ? (
          <React.Fragment></React.Fragment>
        ) : (
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {from} to{" "}
            {pageNumber * maxSize > totalCount
              ? totalCount
              : pageNumber * maxSize}{" "}
            of {totalCount} entries
          </div>
        )}
      </div>
      <div data-test="datatable-pagination" className="col-sm-12 col-md-7">
        <div className="dataTables_paginate" style={{ maxWidth: "100%" }}>
          <ul style={{ display: "flex", justifyContent: "center", alignItems: "center" }} data-test="pagination" className="pagination scrolling-wrapper">
            <li
              data-test="page-item scrolling_card"
              className={`${pageNumber <= 1 ? "disabled" : ""} page-item`}
              onClick={() => prev()}
            >
              <a
                data-test="page-link"
                aria-label="Previous"
                className="page-link page-link"
              >
                <span>Previous</span>
              </a>
            </li>
            <li
              onClick={() => goTo(1)}
              data-test="page-item scrolling_card"
              className={`${pageNumber ==1 ? "disabled font-weight-bold" : ""} page-item`}
            >
              <a
                data-test="page-link"
                aria-label="First"
                className="page-link page-link"
              >
                <span>First</span>
              </a>
            </li>
            {pagesList.length > 15 ?
              <li style={{ display: "flex", justifyContent: "center", alignItems: "center", paddingLeft: "2%", paddingRight: "2%" }} className={
                "page-item scrolling_card"
              }>
                <a
                  data-test="page-link"
                  aria-label="Previous"
                  className="page-link page-link"
                >
                  <b style={{ fontSize: "1.2em" }}>{pageNumber}</b> / {pagesList.length}
                </a>
              </li> :
              pagesList.map((l, i) => (

                <li
                  onClick={() => goTo(l.value)}
                  key={l.value}
                  data-test="page-item"
                  className={
                    l.active
                      ? "page-item active scrolling_card"
                      : "page-item scrolling_card"
                  }
                >
                  <a data-test="page-link" className="page-link page-link">
                    {l.value}
                    <span className="sr-only">(current)</span>
                  </a>
                </li>
              ))}
               <li
              onClick={() => goTo(pages)}
              data-test="page-item scrolling_card"
              className={`${pageNumber === pages || totalCount <= maxSize ? "disabled font-weight-bold" : ""
                } page-item`}
            >
              <a
                data-test="page-link"
                aria-label="Last"
                className="page-link page-link"
              >
                <span>Last</span>
              </a>
            </li>
            <li
              onClick={() => next()}
              data-test="page-item scrolling_card"
              className={`${pageNumber === pages || totalCount <= maxSize ? "disabled" : ""
                } page-item`}
            >
              <a
                data-test="page-link"
                aria-label="Next"
                className="page-link page-link"
              >
                <span>Next</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Row>
  )
}

Pagination.propTypes = {
  totalCount: PropTypes.number,
  pageNumber: PropTypes.number,
  maxSize: PropTypes.number,
  setPageNumber: PropTypes.func,
}

export default Pagination
