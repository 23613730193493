import { GetInstitution } from "helpers/authentication/institution"
import { GetTransactionTypeNames, SEARCHOPTIONS } from "helpers/variables"
import moment from "moment"
const SearchOptions = (startDate, endDate, categories) => {
  let options = Object.assign({}, SEARCHOPTIONS)
  options.range.enabled = true
  options.range.from = moment(startDate).format("YYYY-MM-DD")
  options.range.to = moment(endDate).format("YYYY-MM-DD")

  //   options.range.enabled = false
  options.fields = [
    {
      type: "tel",
      label: "Agent Phone Number",
      id: "phoneNumber",
      name: "phoneNumber",
    },
    {
      type: "dropdown",
      label: "Agent Category",
      id: "agentCategory",
      width: 2,
      defaultValue: "- Select -",
      data: MapToDropDown(categories),
      name: "agentCategory",
    },
    {
      type: "dropdown",
      label: "Transaction Type",
      id: "transactionType",
      width: 2,
      defaultValue: "All",
      data: GetTransactionTypeNames(),
      name: "transactionType",
    },
  ]
  return options
}
const SearchCriteria = (params, maxSize) => {
  return JSON.stringify({
    InstitutionCode: GetInstitution()?.code,
    TransactionTypeName: params["transactionType"],
    PhoneNumber: params["phoneNumber"],
    AppName: "CreditClub",
    FromDate: moment(params["fromDate"])
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss"),
    ToDate: moment(params["toDate"]).endOf("day").format("YYYY-MM-DDTHH:mm:ss"),
    StartIndex: (params["page"] - 1) * maxSize || 0,
    MaxResults: maxSize,
    TotalRows: 100000,
  })
}
const MapToDropDown = data => {
  if (data instanceof Array) {
    return data.map(item => ({ id: item.ID, key: item.ID, value: item.Name, name: item.Name }))
  }
  return []
}
export default { SearchCriteria, SearchOptions, MapToDropDown }
