import PropTypes from "prop-types"
import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { Container, Row, Col } from "reactstrap"

// Pages Components
import Report from "../agent-summary-report/report"

//i18n
import { withTranslation } from "react-i18next"
import {
  reportPurge,
  getTransactionReports,
  getSummaryReports,
} from "store/actions"
import {
  GET_ALL_CATEGORIES,
  GET_ALL_CLUSTERS,
  GET_ALL_NETWORK_MANAGERS,
  GET_STATES,
  GET_TRANSACTIONREPORT,
  GET_TRANSACTION_SUMMARY_REPORT,
} from "helpers/url_helper"
import CashoutLogic from "./summary"
import withDispose from "components/HOCs/WithDispose"
import { toast } from "react-toastify"
import useSWR from "swr"
import { fetcher } from "helpers/api_helper"
import { useInstitutionsData } from "hooks/useInstitutionData"
import useReportData from "hooks/UseReportData"
import { Periodicity } from "enums/periodicity"

const AgentSummaryReport = props => {
  const {
    summary,
    onGetReport,
    error,
    onPurge,
    totalCount,
    loading,
  } = props
  const {
    MapToRows,
    SearchCriteria,
    HEADERS,
    SearchOptions,
    MapToModalState,
  } = CashoutLogic
    const {
    columns, rows, handlePageChange, pageNumber, maxSize, onAction, params, setRows
  } = useReportData({ onGetData: onGetReport, HEADERS: HEADERS, SearchCriteria: SearchCriteria, period : 1, periodicity:Periodicity.Day })

  const { institutionsData, institutions } = useInstitutionsData()

  const states = useSWR(GET_STATES, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const networkManagers = useSWR(`${GET_ALL_NETWORK_MANAGERS}/0`, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const clusters = useSWR(GET_ALL_CLUSTERS, fetcher, {
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
  })
  const [searchOptions, setSearchOptions] = useState(
    SearchOptions(
      params?.fromDate,
      params?.toDate,
      (clusters?.data as any)?.Data,
      (states?.data as any)?.Data,
      (networkManagers?.data as any)?.Data,
      institutionsData
    )
  )
  useEffect(() => {
    setSearchOptions(
      SearchOptions(
        params?.fromDate,
        params?.toDate,
        (clusters?.data as any)?.Data,
        (states?.data as any)?.Data,
        (networkManagers?.data as any)?.Data,
        institutionsData
      )
    )
  }, [states, networkManagers, clusters, institutions])


  useEffect(() => {
    populateRows()
  }, [summary])

  const populateRows = () => {
    let rws = []
    if (summary) {
      summary.forEach(transaction => {
        let row = MapToRows(transaction)

        rws.push(row)
      })
    }
    setRows(rws)
  }

  useEffect(() => {
    if (error) {
      toast.error(error)
    }
  }, [error])
  return (
    // <React.Fragment>
    //   <div className="page-content">
    //     <Container fluid>
    //       <Breadcrumbs
    //         title={"Report"}
    //         breadcrumbItem={"Agent Transaction Summary Report"}
    //       />

          <Row>
            <Col lg="12">
              <Report
                tableData={{
                  columns: columns,
                  rows: rows,
                  paginationOptions: {
                    totalCount: totalCount,
                    onPageChange: page => handlePageChange(page),
                    pageNumber: pageNumber,
                    maxSize: maxSize,
                  },
                  pageTitle: undefined,
                  isLoading: loading,
                }}
                searchData={{
                  searchTitle: "Filter",
                  options: searchOptions,
                  onSearch: params => onAction(params),
                }}
                csv={{
                  url: GET_TRANSACTION_SUMMARY_REPORT,
                  query: SearchCriteria(params, maxSize),
                }}
              />
            </Col>
          </Row>
    //     </Container>
    //   </div>
    // </React.Fragment>
  )
}

AgentSummaryReport.propTypes = {
  summary: PropTypes.array,
  error: PropTypes.any,
  success: PropTypes.any,
  onGetReport: PropTypes.func,
  onPurge: PropTypes.func,
  loading: PropTypes.bool,
  totalCount: PropTypes.number,
}

const mapStateToProps = ({
  reports: { summary, error, success, summaryTotalCount, loading },
}) => {
  return {
    summary,
    error,
    success,
    totalCount: summaryTotalCount,
    loading,
  }
}

const mapDispatchToProps = dispatch => ({
  onGetReport: query => dispatch(getSummaryReports(query)),
  onPurge: () => dispatch(reportPurge()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(withDispose(AgentSummaryReport))))
