/* COMMISSIONS */
export const GET_COMMISSIONS = "GET_COMMISSIONS"
export const GET_ALL_COMMISSIONS = "GET_ALL_COMMISSIONS"
export const GET_COMMISSIONS_SUCCESS = "GET_COMMISSIONS_SUCCESS"
export const GET_COMMISSIONS_FAIL = "GET_COMMISSIONS_FAIL"

export const GET_COMMISSION_DETAIL = "GET_COMMISSION_DETAIL"
export const GET_COMMISSION_DETAIL_SUCCESS = "GET_COMMISSION_DETAIL_SUCCESS"
export const GET_COMMISSION_DETAIL_FAIL = "GET_COMMISSION_DETAIL_FAIL"

export const CREATE_COMMISSION = "CREATE_COMMISSION"
export const POST_COMMISSION_SUCCESS = "POST_COMMISSION_SUCCESS"
export const POST_COMMISSION_FAIL = "POST_COMMISSION_FAIL"

export const COMMISSION_PURGE = "COMMISSION_PURGE"
export const COMMISSION_PURGE_SUCCESS = "COMMISSION_PURGE_SUCCESS"

export const UPDATE_COMMISSION = "UPDATE_COMMISSION"
export const UPDATE_COMMISSION_SUCCESS = "UPDATE_COMMISSION_SUCCESS"
export const UPDATE_COMMISSION_FAIL = "UPDATE_COMMISSION_FAIL"

export const ENABLE_COMMISSION = "ENABLE_COMMISSION"
export const ENABLE_COMMISSION_SUCCESS = "ENABLE_COMMISSION_SUCCESS"
