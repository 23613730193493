import {
  POST_DEBIT_REQUESTS,
  POST_DEBIT_REQUESTS_SUCCESS,
  POST_DEBIT_REQUESTS_FAIL,
  GET_DEBIT_REQUESTS_DETAIL,
  GET_DEBIT_REQUESTS_DETAIL_FAIL,
  GET_DEBIT_REQUESTS_DETAIL_SUCCESS,
  GET_DEBIT_REQUESTS,
  GET_DEBIT_REQUESTS_FAIL,
  GET_DEBIT_REQUESTS_SUCCESS,
  PATCH_DEBIT_REQUESTS_APPROVAL,
  PATCH_DEBIT_REQUESTS_APPROVAL_FAIL,
  PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  debitrequests: [],
  single_debit_request: {},
  error: {},
  success: null,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined,
}

const DebitRequests = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_DEBIT_REQUESTS:
      return {
        ...state,
        loading: true,
        editLoading : true,
        error: null,
        success: null,
      }
    case POST_DEBIT_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        editLoading : false,
        success: action.payload,
        error: null,
      }
    case POST_DEBIT_REQUESTS_FAIL:
      return {
        ...state,
        loading: false,
        editLoading : false,
        success: null,
        error: action.payload,
      }
    case GET_DEBIT_REQUESTS_SUCCESS:
      return {
        ...state,
        debitrequests: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false
      }
    case GET_DEBIT_REQUESTS:
      return {
        ...state,
        loading: true,
        error: "",
        success: "",
      }
    case GET_DEBIT_REQUESTS_DETAIL:
      return {
        ...state,
        loading: true,
      }
      case PATCH_DEBIT_REQUESTS_APPROVAL:
      return {
        ...state,
        loading: true,
        statusChanged : false
      }
      case PATCH_DEBIT_REQUESTS_APPROVAL_SUCCESS:
      return {
        ...state,
         error: "",
        statusChanged: true,
        success: action.payload?.Message,
        loading: false,
      }
         case PATCH_DEBIT_REQUESTS_APPROVAL_FAIL:
      return {
        ...state,
        error : action.payload?.Message,
        loading: false,
      }    
    case GET_DEBIT_REQUESTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_DEBIT_REQUESTS_DETAIL_SUCCESS:
      return {
        ...state,
        debitrequests: action.payload?.Data,
        loading: false,
      }
    case GET_DEBIT_REQUESTS_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
  return state
}

export default DebitRequests
