
import { defaultObj } from "helpers/utility"
import {
  GET_TERMINAL_DETAIL_SUCCESS,
  GET_TERMINAL_DETAIL_FAIL,
  GET_TERMINALS_SUCCESS,
  GET_TERMINALS_FAIL,
  PURGE_TERMINALS,
  ENABLE_TERMINAL,
  ENABLE_TERMINAL_SUCCESS,
  PURGE_TERMINALS_SUCCESS,
  GET_TERMINALS,
  POST_TERMINAL_SUCCESS,
  UPDATE_TERMINAL,
  CREATE_TERMINAL,
  UPDATE_TERMINAL_SUCCESS,
  POST_TERMINAL_FAIL,
  GET_ALL_TERMINALS,

  BATCH_UPLOAD_TERMINAL,
  GET_ASSIGNED_TERMINALS_SUCCESS,
  GET_ASSIGNED_TERMINALS_FAIL,
  GET_ASSIGNED_TERMINALS,
  POST_ASSIGNED_TERMINAL_SUCCESS,
  UPDATE_ASSIGNED_TERMINAL,
  SAVE_ASSIGNED_TERMINAL_CONFIGURATION,
  UPDATE_ASSIGNED_TERMINAL_SUCCESS,
  POST_ASSIGNED_TERMINAL_FAIL,
  GET_ALL_ASSIGNED_TERMINALS,
  ADD_ASSIGNED_TERMINAL,
  REMOVE_ASSIGNED_TERMINAL,
  ADD_ASSIGNED_TERMINAL_SUCCESS,
  REMOVE_ASSIGNED_TERMINAL_SUCCESS,
  BATCH_UPLOAD_TERMINAL_SUCCESS,
  PROCESS_UPLOAD_TERMINAL,
  PROCESS_UPLOAD_TERMINAL_SUCCESS,
  TERMINAL_ASSIGNMENT_REPORT,
  TERMINAL_ASSIGNMENT_REPORT_FAIL,
  TERMINAL_ASSIGNMENT_REPORT_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  terminals: [],
  terminalsToModify: [],
  assignedTerminals: [],
  assignmentReport: [],
  reportTotalCount: 0,
  terminalDetail: {},
  assignedTerminalDetail: {},
  processResult: undefined,
  error: {},
  success: undefined,
  saved: false,
  totalCount: 0,
  loading: true,
  editLoading: false,
  statusChanged: undefined
}

const Terminals = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TERMINALS_SUCCESS:
      return {
        ...state,
        terminals: action.payload?.Data?.Collection || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_TERMINALS:
      return {
        ...state,
        loading: true,
        error: "",
        success: ""
      }
    case GET_ALL_TERMINALS:
      return {
        ...state,
        loading: true,
      }
    case GET_TERMINALS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case TERMINAL_ASSIGNMENT_REPORT:
      return {
        ...state,
        loading: true,
      }
    case TERMINAL_ASSIGNMENT_REPORT_SUCCESS:
      return {
        ...state,
        assignmentReport: action.payload?.Data?.Collection || [],
        reportTotalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case TERMINAL_ASSIGNMENT_REPORT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case GET_TERMINAL_DETAIL_SUCCESS:
      return {
        ...state,
        terminalDetail: action.payload?.Data,
        loading: false,
      }

    case GET_TERMINAL_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }
    case POST_TERMINAL_SUCCESS:
      return {
        ...state,
        terminal: defaultObj(state.terminal),//action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false
      }
    case UPDATE_TERMINAL:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }
      break
    case CREATE_TERMINAL:
      return {
        ...state,
        editLoading: true,
        error: "",
        success: ""
      }

    case POST_TERMINAL_FAIL:
      state = { ...state, error: action.payload, success:undefined, loading: false, editLoading: false }
      break


    case UPDATE_TERMINAL_SUCCESS:
      state = {
        ...state,
        success: action.payload?.Message,
        editLoading: false,
        error: ""
      }
      break

    case ENABLE_TERMINAL:
      state = {
        ...state,
        editLoading: true,
        error: "",
        success: "",
      }
      break
    case ENABLE_TERMINAL_SUCCESS:
      let detail = state.terminalDetail;
      detail.IsActive = action.payload?.enable
      return {
        ...state,
        editLoading: false,
        loading: false,
        terminalDetail: detail,
        error: "",
        statusChanged: action.payload?.enable,
        success: action.payload?.Message
      }
    case BATCH_UPLOAD_TERMINAL:
      return {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined
      }

    case BATCH_UPLOAD_TERMINAL_SUCCESS:
      return {
        ...state,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
        processResult: action.payload?.Data
      }
    case PROCESS_UPLOAD_TERMINAL:
      state = {
        ...state,
        editLoading: true,
        error: undefined,
        success: undefined,
        saved: false
      }
      break
    case PROCESS_UPLOAD_TERMINAL_SUCCESS:
      return {
        ...state,
        editLoading: false,
        loading: false,
        error: "",
        success: action.payload?.Message,
        saved: true
      }
    case ADD_ASSIGNED_TERMINAL:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
      }
    case REMOVE_ASSIGNED_TERMINAL:
      return {
        ...state,
        editLoading: true,
        success: undefined,
        error: undefined,
        deleteLoading: true,
      }
    case ADD_ASSIGNED_TERMINAL_SUCCESS:
      let error //= state.assignedTerminals.find(s => s.Terminal.ID == action.payload.Terminal?.ID) ? "A commission structure with the same name already exists" : ""
      let success = !state.assignedTerminals.find(
        s => s.Terminal.ID == action.payload.Terminal?.ID
      )
        ? "Successfully Added"
        : ""
      let terminalsToModify = [...state.terminalsToModify]
      let assignedTerminals = [...state.assignedTerminals]
      if (action.payload) {
        if (!assignedTerminals.find(s => s.Terminal.ID == action.payload.Terminal?.ID)) {
          terminalsToModify.push({ ...action.payload, flag: "add" })
          assignedTerminals.push(action.payload)
        } else {
          success = action.payload.Terminal?.TerminalID + " Updated"
          assignedTerminals.map(c => {
            if (c.Terminal.ID == action.payload.Terminal?.ID) {
              c = action.payload
            }
            return c
          })
          terminalsToModify.map(c => {
            if (c.Terminal.ID == action.payload.Terminal?.ID) {
              c = action.payload
            }
            return c
          })
        }
      }

      return {
        ...state,
        terminalsToModify,
        assignedTerminals,
        editLoading: false,
        success,
        error,
      }
    case REMOVE_ASSIGNED_TERMINAL_SUCCESS:
      let removeError = !state.assignedTerminals.find(
        s => s.Terminal.ID == action.payload
      )
        ? "Already removed"
        : ""
      let removeSuccess = state.assignedTerminals.find(
        s => s.Terminal.ID == action.payload
      )
        ? "Successfully removed"
        : ""
      let modifiedTerminals = [...state.terminalsToModify] ?? []
      let terminals = [...state.assignedTerminals]

      if (action.payload) {
        terminals = terminals.filter(s => s.Terminal.ID != action.payload)
        if (modifiedTerminals.find(s => s.Terminal.ID == action.payload)) {
          modifiedTerminals = modifiedTerminals.filter(
            m => m.Terminal.ID != action.payload
          )
        } else {
          modifiedTerminals.push({ ...action.payload, flag: "delete" })
        }
      }
      return {
        ...state,
        terminalsToModify: modifiedTerminals,
        assignedTerminals: terminals,
        editLoading: false,
        success: removeSuccess,
        error: removeError,
        deleteLoading: false,
      }

    case POST_ASSIGNED_TERMINAL_SUCCESS:
      return {
        ...state,
        assignedTerminal: defaultObj(state.assignedTerminal), //action.payload,
        error: "",
        success: action.payload?.Message,
        editLoading: false,
        saved: true
      }
    case UPDATE_ASSIGNED_TERMINAL:
      return {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
      }

    case SAVE_ASSIGNED_TERMINAL_CONFIGURATION:
      state = {
        ...state,
        editLoading: true,
        deleteLoading: false,
        error: undefined,
        success: undefined,
        saved: false
      }
      break
    case POST_ASSIGNED_TERMINAL_FAIL:
      state = {
        ...state,
        error: action.payload?.Message,
        success: "",
        loading: false,
        deleteLoading: false,
        editLoading: false,
      }
      break

    case UPDATE_ASSIGNED_TERMINAL_SUCCESS:
      let updatedTerminals = state.assignedTerminals
      let updatedTerminalDetail = state.assignedTerminalDetail
      if (action.payload?.Data) {
        updatedTerminals = updatedTerminals.map(s => {
          if (s.ID == action.payload.Data.ID) {
            s = action.payload.Data
          }
          return s
        })
      }
      state = {
        ...state,
        assignedTerminals: updatedTerminals,
        assignedTerminalDetail: updatedTerminalDetail,
        success: action.payload?.Message,
        editLoading: false,
        error: "",
      }
      break
    case GET_ASSIGNED_TERMINALS_SUCCESS:
      return {
        ...state,
        assignedTerminals: action.payload?.Data || [],
        totalCount: action.payload?.Data?.TotalCount || 0,
        loading: false,
      }
    case GET_ASSIGNED_TERMINALS:
      return {
        ...state,
        loading: true,
      }
    case GET_ALL_ASSIGNED_TERMINALS:
      return {
        ...state,
        loading: true,
      }
    case PURGE_TERMINALS_SUCCESS:

      return INIT_STATE
    default:
      return state
  }
  return state
}

export default Terminals
