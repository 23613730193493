import { Validators } from "helpers/validators"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const withFormValidation = BaseComponent => (props) => {
  let {
    match,
    onPurge,
    history,
    success,
    redirectUrl,
    error,
    errorList,
    setErrorList,
    dispatch,
    children,
    ...otherProps
  } = props;
  const [apiError, setApiError] = useState()
  const [apiSuccess, setApiSuccess] = useState()
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [isUpdate, setIsUpdate] = useState<boolean>()
  const [myError, setMyError] = useState()
  const [redirectTo, setRedirectTo] = useState(redirectUrl);
  useEffect(() => {
    resetState();
  }, [])
  useEffect(() => {
    const id = match?.params.id;
    const onUpdate = id && id != '0';
    setIsUpdate(onUpdate)
    if (errorList && setErrorList && onUpdate) {
      setErrorList(UpdateErrorList())
    }
  }, [match])
  const resetState = () => {
    success = undefined;
    error = undefined;
    setApiError(undefined);
    setApiSuccess(undefined);
  }

  useEffect(() => {
    setRedirectTo(redirectUrl)
  }, [redirectUrl])

  useEffect(() => {
    if (success) {
      setApiError(undefined)
      setApiSuccess(success)
      setTimeout(() => resetState(), 300);
      if (redirectTo && history) {
        setTimeout(() => history.push(redirectTo), 1000)
      }

      if (onPurge) {
        onPurge()
      }
    }
  }, [success])

  useEffect(() => {
    if (error && !success) {
      setApiError(Validators.extractError(error))
      setTimeout(() => resetState(), 300);
    }
  }, [error])

  useEffect(() => {
    setApiError(undefined)
    const error = Validators.validateForm(errorList);
    setMyError(error)
    const disable = (error === undefined || error.length != 0) ? true : false;
    setDisableSubmitButton(disable)
  }, [errorList])

  useEffect(() => {
    if (apiSuccess) toast.success(apiSuccess)
  }, [apiSuccess])

  useEffect(() => {
    if (apiError) toast.error(apiError)
  }, [apiError])

  const UpdateErrorList = () => {
    let list = { ...errorList }
    for (let key in list) {
      list[key] = ""
    }
    return list
  }
  return (
    <React.Fragment>
      <BaseComponent
        {...otherProps}
        apiSuccess={apiSuccess}
        apiError={apiError}
        myError={myError}
        isUpdate={isUpdate}
        errorList={errorList}
        setErrorList={setErrorList}
        isFormInvalid={disableSubmitButton}
        history={history}
      >
        {children}
      </BaseComponent>
    </React.Fragment>
  )
}

withFormValidation.PropTypes = {
  error: PropTypes.string,
  success: PropTypes.string,
  errorList: PropTypes.object,
}

export default withFormValidation