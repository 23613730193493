import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { FormGroup, InputGroup, Label } from "reactstrap";

const ClusterFileUploader: React.FC<any> = props => {
    const { id, onHandleChange, name, label, accept=[".csv"] } = props
    const fileRef = useRef(null);
    const [uploadedFile, setUploadedFile] = useState(null);
    const getBase64 = (file) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

            onHandleChange({ FileName: file.name, Base64: (reader.result as string).split(',')[1], File: file });
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };
    }
    return (
        <FormGroup>
            <InputGroup>
                {label && <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <Label for={id}>{label}</Label>
                </div>}


                <div className="file-upload-container">
                    <div className="file-upload">
                        {
                            !uploadedFile ? <div className="file-uploader p-2 pt-3">
                                <div className="mb-3">
                                    <h4 className="mb-1"><b>Upload a file</b></h4>
                                    <small>Only {accept.join(' | ')} files are allowed</small>
                                </div>
                                <div>
                                    <i className="bx bxs-file"></i>
                                </div>
                                <p>Click to select a file or drag and drop file into the box</p>
                            </div> : <div className="file-uploaded p-2 d-flex align-items-center">
                                <div className="mr-3">
                                    <i className="bx bxs-file"></i>
                                </div>
                                <div>
                                    <p><b>{uploadedFile?.name}</b></p>
                                    <small>Click to change a file or drag and drop file into the box</small>
                                </div>
                            </div>
                        }
                    </div>
                    <input onChange={(e) => {
                        const file = fileRef.current.files[0];
                        if (file) {
                            setUploadedFile(file);
                            const fileName = file.name;
                            getBase64(file)
                        }
                    }} ref={fileRef} type="file" accept={accept.join(', ')} id={id} style={{ opacity: 0, position: "absolute", top: 0, zIndex: 2, width: "100%", height: "100%" }} />
                </div>
            </InputGroup>
        </FormGroup>
    )
}


ClusterFileUploader.propTypes = {
    id: PropTypes.string,
    onHandleChange: PropTypes.func,
    name: PropTypes.string,
    accept: PropTypes.array
}

export default ClusterFileUploader
