import React, { Component } from "react"
import { TabPane, Row, Col, Container } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"

//Import Components
import ClusterTabPanel from "components/Common/TabPanel"
import { TRANSACTIONTYPES } from "helpers/variables"
import SummaryList from "./summarylist"
import TrendList from "./trends"
import ComparativeList from "./comparatives"
import withDispose from "components/HOCs/WithDispose"

class DashboardTransaction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reports: [
        {
          icon: "bx bx-copy-alt",
          title: "Orders",
          value: "1,452",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-archive-in",
          title: "Revenue",
          value: "$ 28,452",
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "From previous period",
        },
        {
          icon: "bx bx-purchase-tag-alt",
          title: "Average Price",
          value: "$ 16.2",
          badgeValue: "0%",
          color: "warning",
          desc: "From previous period",
        },
      ],
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs
              title="Dashboard"
              breadcrumbItem="Transaction Summary"
            />
            <Row>
              <Col lg={12}>
                <h4>TRENDS</h4>
              </Col>
            </Row>
            <TrendList></TrendList>

            <Row>
              <Col lg={12}>
                <h4>COMPARATIVES</h4>
              </Col>
            </Row>
            <ComparativeList></ComparativeList>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

export default withDispose(DashboardTransaction)
