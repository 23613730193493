/* FEATURES */
export const GET_FEATURES:any = "GET_FEATURES"
export const GET_ALL_FEATURES = "GET_ALL_FEATURES"
export const GET_FEATURES_SUCCESS = "GET_FEATURES_SUCCESS"
export const GET_FEATURES_FAIL = "GET_FEATURES_FAIL"


export const GET_FEATURES_BY_INSTITUTION:any = "GET_FEATURES_BY_INSTITUTION"
export const GET_INSTITUTION_FEATURES_SUCCESS:any ="GET_INSTITUTION_FEATURES_SUCCESS";
export const GET_FEATURE_DETAIL:any = "GET_FEATURE_DETAIL"
export const GET_FEATURE_DETAIL_SUCCESS = "GET_FEATURE_DETAIL_SUCCESS"
export const GET_FEATURE_DETAIL_FAIL = "GET_FEATURE_DETAIL_FAIL"


export const CREATE_FEATURE= "CREATE_FEATURE";
export const POST_FEATURE_SUCCESS = "POST_FEATURE_SUCCESS"
export const POST_FEATURE_FAIL = "POST_FEATURE_FAIL"

export const PURGE_FEATURES = "PURGE_FEATURES"
export const PURGE_FEATURES_SUCCESS = "PURGE_FEATURES_SUCCESS"


export const UPDATE_FEATURE= "UPDATE_FEATURE";
export const UPDATE_FEATURE_SUCCESS= "UPDATE_FEATURE_SUCCESS";
export const UPDATE_FEATURE_FAIL= "UPDATE_FEATURE_FAIL";

export const ENABLE_FEATURE= "ENABLE_FEATURE";
export const ENABLE_FEATURE_SUCCESS = "ENABLE_FEATURE_SUCCESS"
